import "./PerformTestsStatus.scss";
import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserCheck, faUserTimes } from "@fortawesome/free-solid-svg-icons";

interface IProps {
  id: number;
  performTests: boolean;
}
const PerformTestsStatus: React.FC<IProps> = ({ id, performTests }) => {
  const tooltipText = performTests ? "provádí zkoušky" : "neprovádí zkoušky";
  const type = performTests ? "active" : "inactive";
  return (
    <div id={`user-performtests-icon-${id}`}>
      <OverlayTrigger
        placement="right"
        overlay={
          <Tooltip id={`tooltip-user-icon-${id}`}>{tooltipText}</Tooltip>
        }
      >
        <FontAwesomeIcon
          icon={performTests ? faUserCheck : faUserTimes}
          className={`icon icon-performteststatus--${type}`}
        />
      </OverlayTrigger>
    </div>
  );
};

export default PerformTestsStatus;
