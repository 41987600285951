import { observer } from "mobx-react-lite";
import moment from "moment";
import React, { Fragment, useContext,  useState } from "react";
import { Table, Button, Col, Row, Form, Alert } from "react-bootstrap";
import { Field, Form as FinalForm } from "react-final-form";
import { Link } from "react-router-dom";
import TextInput from "../../app/common/form/TextInput";
import { RootStoreContext } from "../../app/stores/rootStore";
import VipCar from "../icons/VipCar";
import Loader from "../loader/Loader";
import VinCode from "../vin/VinCode";
import { FORM_ERROR } from "final-form";
import "./CarSearchPage.scss";
import {
  getCarsString,
  getMasculinePassiveVerbEnding,
} from "../../app/common/utils/czechDeclension";
import AlertMessage from "../alert/AlertMessage";
import Pages from "../../app/common/utils/Pages";

const CarSearchPage: React.FC = () => {
  const rootStore = useContext(RootStoreContext);
  const {
    carsSorted,
    isLoading,
    carsPage,
    setCarFilter,
    loadCars,
    carSearch
  } = rootStore.carSearchStore;
  const [showSubmitError, setShowSubmitError] = useState(false);

  const handleFinalFormSubmit = (form: any) => {
    if (!form.vin && !form.prodIdentificationNum && !form.carNumber) {
      setShowSubmitError(true);
      return {
        [FORM_ERROR]: "Musí být vyplněno alespoň 1 vyhledávací kritérium",
      };
    }
    console.log(form);
    setCarFilter(form);
  };
  const setPage = (page:number)=>{
    loadCars(page);
  }

  return (
    <Fragment>
      <div className="page-content" id="carsearch">
        <div className="container-full">
          <div className="pageheader">
            <div className="pageheader__left">
              <h1>Vyhledat vůz</h1>
            </div>
          </div>
          <div className="carsearch filter-container">
            <div className="carsearch__filters filter-container__filters">
              <FinalForm
                onSubmit={handleFinalFormSubmit}
                initialValues={carSearch}
                render={({ handleSubmit, submitError }) => {
                  return (
                    <Form onSubmit={handleSubmit} id="carSearchForm">
                      {/* <h4>Možnosti hledání</h4> */}
                      {submitError && (
                        <AlertMessage
                          type="danger"
                          heading="Nastala chyba"
                          show={showSubmitError}
                          setShow={setShowSubmitError}
                          messageList={[submitError as string]}
                        />
                      )}
                      <Row>
                        <Col lg={4}>
                          <Field
                            title="VIN kód"
                            name="vin"
                            component={TextInput}
                            size="sm"
                          />
                        </Col>
                        <Col lg={4}>
                          <Field
                            title="KNR kód"
                            name="prodIdentificationNum"
                            component={TextInput}
                            size="sm"
                          />
                        </Col>
                        <Col lg={4}>
                          <Field
                            title="Číslo vozu"
                            name="carNumber"
                            component={TextInput}
                            size="sm"
                          />
                        </Col>
                      </Row>
                      <Row>
                        <div className="align-right">
                          <Button type="submit" className="btn-wide">
                            Hledej!
                          </Button>
                        </div>
                      </Row>
                    </Form>
                  );
                }}
              />
            </div>
          </div>
          <div className="right" >
            <label >
              Zobrazen{getMasculinePassiveVerbEnding(carsSorted.length)}{" "}
              {carsSorted.length} {getCarsString(carsSorted.length)} | Nalezeno{" "}
              {carsPage.count} {getCarsString(carsPage.count)}
            </label>
          </div>

          <Table hover className="sticky--top" >
            <thead>
              <tr>
                <th>VDS číslo</th>
                <th>VIN kód</th>
                <th>KNR kód</th>
                <th>Logistické číslo</th>
                <th>Stav vozu</th>
                <th>Na zkušebně (od - do)</th>
                <th>Typ vozu</th>
                <th>Odběratel</th>
                <th>Typ zkoušky</th>
                <th>Akce</th>
              </tr>
            </thead>
            <tbody>
              {carsSorted.map((car) => (
                <tr key={car.carId}>
                  <td>
                    {car.vipCar && <VipCar id={car.carId} />}
                    {car.integrationNum}
                  </td>
                  <td>
                    <VinCode vin={car.vin} breakLine={false} />
                  </td>
                  <td>{car.prodIdentificationNum}</td>
                  <td>{car.logisticNum}</td>
                  <td>{car.carState}</td>
                  <td>
                    {car.availableFrom &&
                      moment(car.availableFrom).format("D.M.YY")}
                    {car.availableFrom && " - "}
                    {car.availableTo &&
                      moment(car.availableTo).format("D.M.YY")}
                  </td>

                  <td title={car.testDestinationName}>
                    {car.testDestinationCode}
                  </td>
                  <td>{car.customerCode}</td>
                  <td>{car.testTypeName}</td>
                  <td>
                    <Button
                      as={Link}
                      to={`/car/${car.carId}`}
                      variant="outline-primary"
                    >
                      Detail
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          {!isLoading && !carsSorted.length && (
            <Alert variant="info">Nebyly nalezeny žádné vozy</Alert>
          )}
          {isLoading && <Loader />}
        </div>
      </div>
      <footer className="footer">
        <div className="footer-left">        
        </div>
        <div className="footer-right">
          <Pages
            totalPages={carsPage.totalPages}
            currentPage={carsPage.currentPage}
            onClickEvent={setPage}
            scrollToId={"carsearch"}
          />
        </div>
      </footer>
    </Fragment>
  );
};
export default observer(CarSearchPage);
