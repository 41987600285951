import "./WashArea.scss"
import React from "react";
import {Field} from "react-final-form";
import {Col, Container, Row} from "react-bootstrap";
import TextInput from "../../../../app/common/form/TextInput";
import SwitchInput from "../../../../app/common/form/SwitchInput";

interface IProps {
    isEdit: boolean;
}

const WashArea: React.FC<IProps> = ({isEdit,}) => {
    return (
        <div
            className='wash-area'>
            <Container style={{paddingLeft: 0, paddingRight: 0,}}>
                <Row noGutters>
                    <Col className='pressure-wash'>
                        <Field
                            key='PressureWashing'
                            name={`additionalInfoArea.additionalInfoValue.pressureWashing`}
                            title="Tlakové mytí"
                            options={[
                                {key: 1, value: "ano"},
                                {key: 0, value: " ne "},
                            ]}

                            format={(v) => (v ? 1 : 0)}
                            parse={(v) => (v ? true : false)}
                            component={SwitchInput}
                            disabled={!isEdit}
                        />
                    </Col>
                    <Col>
                        <Field<string>
                            key='ShowerLength'
                            name={`additionalInfoArea.additionalInfoValue.showerLength`}
                            title="Délka sprchování"
                            component={TextInput}
                            disabled={!isEdit}
                            maxLength={10}
                        />
                    </Col>
                </Row>
                <Row noGutters>
                    <Col sm={6}>
                        <Field<string>
                            key='sequenceNumber'
                            name={`additionalInfoArea.additionalInfoValue.engineNumber`}
                            title="Číslo motoru"
                            component={TextInput}
                            disabled={!isEdit}
                            maxLength={40}
                            titleWidth={4}
                            fieldWidth={6}
                        />
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default WashArea;
