export const DATE_TIME_FORMAT = "D.M.YYYY H:mm";
export const DATE_FORMAT = "DD.MM.YYYY";
export const TIME_FORMAT = "H:mm";

export const defectStates = [
  { key: 1, value: "Opraveno" },
  { key: 2, value: "Opravit" },
  { key: 3, value: "Neopraveno" },
];

export const calendarWeeks = [
  { key: 12021, value: "KT 1/2021" },
  { key: 22021, value: "KT 2/2021" },
  { key: 32021, value: "KT 3/2021" },
  { key: 42021, value: "KT 4/2021" },
  { key: 52021, value: "KT 5/2021" },
  { key: 62021, value: "KT 6/2021" },
  { key: 72021, value: "KT 7/2021" },
  { key: 82021, value: "KT 8/2021" },
  { key: 92021, value: "KT 9/2021" },
  { key: 102021, value: "KT 10/2021" },
  { key: 112021, value: "KT 11/2021" },
  { key: 122021, value: "KT 12/2021" },
];