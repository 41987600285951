import React from "react";
import "./FullCalendarTestEvent.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import WorkerBadge from "./WorkerBadge";
import { ICarTest } from "../../app/models/carTest";
import { OverlayTrigger, Popover, Button, Tooltip } from "react-bootstrap";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { ICar } from "../../app/models/car";
import ScheduleCarDetail from "../schedule/dashboard/ScheduleCarDetail";
import VinCode from "../vin/VinCode";
import CarTestCollisions from "../icons/CarTestCollisions";
import MissingWorkers from "../icons/MissingWorkers";
import CarTestStatusIcon from "../icons/CarTestStatusIcon";
import { CarTestStates } from "../../app/models/enum";
import CarColor from "../icons/CarColor";
import VipCar from "../icons/VipCar";

interface IProps {
  event: {
    allDay: boolean;
    start: Date;
    end: Date;
    extendedProps: { carTest: ICarTest; car: ICar; history: any };
  };
}
const FullCalendarEvent: React.FC<IProps> = ({ event }) => {
  const { carTest, car, history } = event.extendedProps;
  const showTestStateIcon = [
    CarTestStates.Unfinished,
    CarTestStates.Completed,
    CarTestStates.Ongoing,
    CarTestStates.Interrupted,
  ].includes(carTest.testStateId);
  const carDetail = (
    <Popover
      id={`popover-cardetail-${carTest.carId}`}
      style={{ minWidth: "40rem" }}
    >
      <Popover.Title as="h3">
        Detail vozu <VinCode vin={car.vin} />
      </Popover.Title>
      <Popover.Content>
        <ScheduleCarDetail car={car} />
      </Popover.Content>
    </Popover>
  );

  const conflictTypes = [
    ...new Set(carTest.carTestConflicts.map((item) => item.conflictName)),
  ];
  const isCarImportant = car.vipCar || !!car.importantCarDesc;

  return (
    <div className="fullcalendar-event ">
      <div>Zkouška</div>
      {!event.allDay && (
        <div className="fullcalendar-event__time">
          {moment(event.start).format("H:mm")}
          {"\u00A0"}- {moment(event.end).format("H:mm")}
        </div>
      )}
      <div className="fullcalendar-event__link">
        <Button
          className="fullcalendar-event__link--btn"
          variant={"link"}
          title={" Detail zkoušky"}
          onClick={() => {
            document.body.click();
            history.push(`/cartest/${carTest.carTestId}`);
          }}
        >
          <FontAwesomeIcon icon={faSearch} />
        </Button>
      </div>
      <div className="fullcalendar-event__car">
        <div className="flex-row">
          {car.vipCar && (
            <VipCar id={car.carId} style={{ marginRight: "4px" }} />
          )}
          {!!car.importantCarDesc && (
            <div
              className={` ${isCarImportant ? "car-important" : ""}`}
              style={{ marginRight: "5px" }}
            >
              {car.importantCarDesc}
            </div>
          )}
          <CarColor
            id={car.carId}
            color={car.colorValue}
            code={car.colorCode}
            name={car.colorName}
          />
        </div>
        <OverlayTrigger
          trigger={["click"]}
          placement="auto"
          overlay={carDetail}
          rootClose
        >
          <span className={`car-identification`}>
            {car.integrationNum}-{car.testDestinationCode}-
            <span className={` ${isCarImportant ? "car-important" : ""}`}>
              {car.carNumber}
            </span>
          </span>
        </OverlayTrigger>
      </div>

      <div className="fullcalendar-event__workers">
        {[
          ...(carTest.assignedWorkers || []),
          ...(carTest.extraWorkers || []),
        ]?.map((w) => (
          <WorkerBadge
            key={w.userId}
            id={w.userId}
            backgroundColor={w.color}
            label={w.shortcut}
            description={w.userFullName}
          />
        ))}
      </div>
      <div className="fullcalendar-event__alerts">
        {showTestStateIcon && (
          <div className="tooltip-icon">
            <OverlayTrigger
              placement="right"
              trigger={"hover"}
              overlay={
                <Tooltip id={`tooltip-state-${carTest.carTestId}`}>
                  {carTest.testStateName} zkouška
                </Tooltip>
              }
            >
              <span>
                {" "}
                <CarTestStatusIcon carTestState={carTest.testStateId} />
              </span>
            </OverlayTrigger>
          </div>
        )}
        {!!carTest.carTestConflicts?.length && (
          <CarTestCollisions
            id={carTest.carTestId}
            conflictTypes={conflictTypes}
          />
        )}
        {(!carTest.assignedWorkers ||
          carTest.assignedWorkers.length < carTest.workerCount) && (
          <MissingWorkers
            id={carTest.carTestId}
            missingCount={
              !carTest.assignedWorkers || !carTest.assignedWorkers.length
                ? undefined
                : carTest.workerCount - carTest.assignedWorkers.length
            }
          />
        )}
      </div>
    </div>
  );
};

export default FullCalendarEvent;
