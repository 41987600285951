import React, {FC} from "react";
import {Col, Container, Row} from "react-bootstrap";
import {Field, useFormState} from "react-final-form";
import CheckboxInput from "../../../../app/common/form/CheckboxInput";
import {IAcousticsProtocol, IProtocolPartGroup} from "../../../../app/models/protocol";
import AssessmentRow from "./AssessmentRow"
import {FormApi} from "final-form";

interface DAProps {
    protocolPartGroup: IProtocolPartGroup;
    isEdit: boolean;
}

export const DrivingAssessment: FC<DAProps> = (
    {
        protocolPartGroup,
        isEdit
    }
) => {
    const formState = useFormState<IAcousticsProtocol>();
    const notPerformArea = formState.values.executedTestArea.notPerform || false;
    const notPerformGroup = formState.values.executedTestArea.drivingAssessmentGroup.notPerform || false;
    const notPerform = notPerformArea || notPerformGroup;

    return <>
        <Row noGutters className={'mt-2 mb-2 border-bottom'}>
            <Col sm={3}>
                {protocolPartGroup.name}
            </Col>
            <Col sm={7} className={'text-center'}>
                <div className={`${ notPerform ? "no-print" : ""}`}>Hodnocení</div>
            </Col>
            <Col>
                <Field
                    name={`executedTestArea.drivingAssessmentGroup.notPerform`}
                    title="Neprovádět"
                    options={[
                        {key: 1, value: "ano"},
                        {key: 0, value: " ne "},
                    ]}
                    format={(v) => (v ? 1 : 0)}
                    parse={(v) => (!!v)}
                    type={"checkbox"}
                    initialValue={protocolPartGroup.notPerform || false}
                    component={CheckboxInput}
                    disabled={!isEdit || notPerformArea}
                    labelWidth={8}
                    valueWidth={4}
                />
            </Col>
        </Row>
        <Container className={'mt-2 mb-2'}>
            {protocolPartGroup.partRules?.map((partRule, index) => {
                const notPerformRule = formState.values.executedTestArea.drivingAssessmentGroup?.partRules?.[index].notPerform || false;
                 return (
                    <AssessmentRow
                        areaName='executedTestArea'
                        groupName="drivingAssessmentGroup"
                        ruleIndex={index}
                        partRule={partRule}
                        isEdit={isEdit}
                        disabledArea={notPerformArea}
                        disabledGroup={notPerformGroup}
                        disabledRule={notPerformRule}
                    />
                )
            }
        )}
        </Container>
    </>
}
