import "./ScheduleEmissionItem.scss";
import React, { Fragment, useContext } from "react";
import "moment/locale/cs";
import { ICar } from "../../../app/models/car";
import moment from "moment";
import { TIME_FORMAT, DATE_FORMAT } from "../../../app/common/utils/constants";
import { Tooltip, OverlayTrigger } from "react-bootstrap";


interface IProps {
  scheduleEmissionItem: ICar;
  dayNumber: Number | undefined;
  day: moment.Moment;
}

const scheduleEmissionItem: React.FC<IProps> = ({ scheduleEmissionItem, dayNumber, day }) => {

  const {
    emissionTestPlanFrom,
    emissionTestPlanTo,
    emissionTestDateFrom,
    emissionTestDateTo,
  } = scheduleEmissionItem;

  const start = !!emissionTestDateFrom ? emissionTestDateFrom : emissionTestPlanFrom;
  const end = !!emissionTestDateFrom ? emissionTestDateTo : emissionTestPlanTo;
  const startMoment = !!start ? moment(start) : undefined;
  const endMoment = !!end ? moment(end) : undefined;
  const firstEmissionItem = dayNumber === 1 || startMoment?.startOf("d").isSame(day);
  const firstEmissionItemCSS = !!start && startMoment?.startOf("d").isSame(day) ? "first" : "";
  const lastEmissionItemCSS = !!end && endMoment?.startOf("d").isSame(day) ? "last" : "";

  const scheduleEmissionItemContent = (
    <OverlayTrigger
      placement="auto"
      trigger="hover"
      delay={{ show: 750, hide: 200 }}
      overlay={
        <Tooltip id="button-tooltip" data-html="true">
          <p>
            Emisí zkouška vozu<br />
            {!!startMoment && "od " + startMoment.format(`${DATE_FORMAT} ${TIME_FORMAT}`)}<br />
            {!!endMoment && 'do ' + endMoment.format(`${DATE_FORMAT} ${TIME_FORMAT}`)}
          </p>
        </Tooltip>
      }
    >
      <div className={`schedule-emission-item ${firstEmissionItemCSS} ${lastEmissionItemCSS}`}>
        <div className="scheduleitem__title">
          {!!firstEmissionItem && <span>EMISE</span>}
          {!firstEmissionItem && <span>&nbsp; &nbsp;</span>}
        </div>
      </div>
    </OverlayTrigger>
  );

  return (
    <div>
      <Fragment>
        {scheduleEmissionItemContent}
      </Fragment>
    </div>
  );
};

export default scheduleEmissionItem;
