import {v4 as uuidv4} from 'uuid';

export interface IPlanningConditionsDTO{
    conditions: IPlanningCondition[];
    expertGroupsAbsoluteSequence: IExpertGroupSequence[];
    expertGroupsDependenceSequence: IExpertGroupDependence[];
}

export interface IPlanningCondition
{
    planningConditionId?: number;
    uid: string;
    testDestinationId?: number;
    testDestinationName: string;
    testPredestinationId?: number;
    testPredestinationName: string;
    testTypeId?: number;
    testTypeName: string;
    customerCode: string;
    carModelId?: number;
    carModelName: string;
    carBodyTypeId?: number;
    carBodyTypeName: string;
    carEquipmentId?: number;
    carEquipmentName: string;
    carEngineId?: number;
    carEngineName: string;
    emissionTest?: boolean;
    planningTypeId?: number;
    planningTypeName: string;
    placeId: number;
    isActive: boolean;
}

export class PlanningCondition implements IPlanningCondition {
    planningConditionId = undefined;
    uid: string = uuidv4();
    testDestinationId?: number = undefined;
    testDestinationName: string = "";
    testPredestinationId?: number = undefined;
    testPredestinationName: string = "";
    testTypeId?: number = undefined;
    testTypeName: string = "";
    customerCode: string = "";
    carModelId?: number = undefined;
    carModelName: string = "";
    carBodyTypeId?: number = undefined;
    carBodyTypeName: string = "";;
    carEquipmentId?: number = undefined;
    carEquipmentName: string = "";;
    carEngineId?: number = undefined;
    carEngineName: string = "";;
    emissionTest?: boolean = undefined;
    planningTypeId?: number = undefined;
    planningTypeName: string = "";
    placeId: number = 1;
    isActive: boolean = true;
}

export interface IExpertGroupSequence {
    expertGroupId: number;
    code: string;
    name: string;
    color: string;
    absoluteOrder?: number;
}

export interface IExpertGroupDependence {
    id?: number;
    code: string;
    text: string;
    color: string;
    children: IExpertGroupDependence[];
}