export interface IEnums {
  testTypes: IEnumItem[];
  places: IEnumItem[];
  expertGroups: IExpertGroupEnumItem[];
  testDestinations: ITestDestinationItem[];
  testPredestinations: IEnumItem[];
  carStates: IEnumItem[];
  testStates: IEnumItem[];
  testCharacteristics: IEnumItem[];
  colors: IEnumItem[];
  carModels: ICarModel[];
  carBodyTypes: ICarBodyTypeItem[];
  carEquipments: ICarModelItem[];
  carTransmissions: ICarModelItem[];
  carEngines: ICarEngineItem[];
  userRoles: IEnumItem[];
  blockingEventTypes: IEnumItem[];
  conflictTypes: IEnumItem[];
  timeLimitTypes: IEnumItem[];
  carColors: ICarColorItem[];
  defectStates: IEnumItem[];
  calendarWeeks: IEnumItem[];
  protocolTypes: IEnumItem[];
  protocolRuleFunctions: IEnumItem[];
  protocolRuleTypes: IEnumItem[];
  analysisTypes: IEnumItem[];
  planningTypes: IEnumItem[];
  prNumberCategories: IPrNumCategory[];
}

export interface IEnumItem {
  key: number;
  value: string;
  code: string;
  isActive: boolean;
}

export interface IExpertGroupEnumItem {
  key: number;
  value: string;
  code: string;
  placeId: number;
  isActive: boolean;
}

export interface ICarModelForm {
  id?: number;
  name: string;
  code: string;
  emissionFree: boolean;
  modelVdsCode: string;
  prProductId?: number;
  prProduct: string;
  isActive: boolean;
}

export interface ITestTypeForm {
  id: number;
  name: string;
  code: string;
  isActive: boolean;
}

export interface ICarBodyTypeItemForm {
  id?: number;
  carModelId: number;
  name: string;
  code: string;
  modelVdsCode: string;
  isActive: boolean;
}

export interface ICarModelItemForm {
  id?: number;
  carModelId: number;
  name: string;
  code: string;
  isActive: boolean;
}

export interface ICarColorForm {
  id?: number;
  code: string;
  name: string;
  value: string;
  isActive: boolean;
}

export interface ICarModel {
  id: number;
  name: string;
  code: string;
  emissionFree: boolean;
  modelVdsCode: string;
  prProductId?: number;
  prProduct: string;
  isActive: boolean;
}

export interface ICarModelItem {
  id: number;
  carModelId: number;
  name: string;
  code: string;
  isActive: boolean;
}

export interface ICarBodyTypeItem {
  id: number;
  carModelId: number;
  name: string;
  code: string;
  modelVdsCode: string;
  isActive: boolean;
}

export interface ICarEngineItem {
  emissionFree: boolean;
  id: number;
  carModelId: number;
  name: string;
  code: string;
  isActive: boolean;
}
export interface ITestDestinationItem {
  key: number;
  value: string;
  group: number;
}

export interface IExpertGroups {
  expertGroups: IExpertGroupEnum[];
}

export interface ICarColorItem {
  id: number;
  code: string;
  name: string;
  value: string;
  isActive: boolean;
}

export interface IExpertGroupEnum {
  expertGroupId: number;
  expertGroupCode: string;
  expertGroupName: string;
  expertGroupColorId: number;
  expertGroupColor: string;
}

export interface IPlace {
  placeId: number;
  placeName: string;
}

export interface IPrNumCategory {
  id: number;
  name: string;
  order: number;
}

export enum CarTestStates {
  Registered = 1,
  Planned,
  Ongoing,
  DoNotPerform,
  Cancelled,
  Unfinished,
  Completed,
  Interrupted,
}

export enum CarStates {
  Registered = 1,
  InTestroom,
  Scheduled,
  InProgress,
  Suspend,
  Canceled,
  Tested,
  OutOfTestroom,
}