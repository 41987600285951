import React, { FC, Fragment } from "react";
import {Col, Container, Row} from "react-bootstrap";
import {Field, useFormState} from "react-final-form";
import CheckboxInput from "../../../../app/common/form/CheckboxInput";
import {IAcousticsProtocol, IProtocolPartGroup, IProtocolCodeTableValues} from "../../../../app/models/protocol";
import {observer} from "mobx-react-lite";
import AssessmentRow from "./AssessmentRow"
import {FormApi} from "final-form";

interface IProps {
    protocolPartGroup: IProtocolPartGroup;
    protocolCodeTableValues: IProtocolCodeTableValues[];
    isEdit: boolean;
}

const OnSiteAssessment: FC<IProps> = (
{
    protocolPartGroup,
    protocolCodeTableValues,
    isEdit
}
) => {
    const formState = useFormState<IAcousticsProtocol>();
    const notPerformArea = formState.values.executedTestArea.notPerform || false;
    const notPerformGroup = formState.values.executedTestArea.onSiteAssessmentGroup.notPerform || false;
    const notPerform = notPerformArea || notPerformGroup;

    return <>
        <Row noGutters className={'mt-2 mb-2 border-bottom'}>
            <Col sm={3}>
                {protocolPartGroup.name}
            </Col>
            <Col sm={7}>
                    <Row className={`text-center ${ notPerform ? "no-print" : ""}`}>
                        <Col>
                            Hodnocení
                        </Col>
                    </Row>
                    <Row noGutters className={`${ notPerform ? "no-print" : ""}`}>
                        <Col>
                            <small className="text-muted">
                                Bez spotřebičů
                            </small>
                        </Col>
                        <Col>
                            <small className="text-muted">
                                Se spotřebiči bez klimatizace
                            </small>
                        </Col>
                        <Col>
                            <small className="text-muted">
                                Se spotřebiči i s klimatizací
                            </small>
                        </Col>
                        {/*    to adjust layout in a "table" manner */}
                        <Col sm={1}/>
                    </Row>
            </Col>
            <Col>
                <Field
                    name={`executedTestArea.onSiteAssessmentGroup.notPerform`}
                    title="Neprovádět"
                    options={[
                        {key: 1, value: "ano"},
                        {key: 0, value: " ne "},
                    ]}
                    format={(v) => (v ? 1 : 0)}
                    parse={(v) => (!!v)}
                    type={"checkbox"}
                    initialValue={protocolPartGroup.notPerform || false}
                    component={CheckboxInput}
                    disabled={!isEdit || notPerformArea}
                    labelWidth={8}
                    valueWidth={4}
                />
            </Col>
        </Row>
        <Container className={'mt-2 mb-2'}>
            {protocolPartGroup.partRules?.map((partRule, index) => {
                    const notPerformRule = formState.values.executedTestArea.onSiteAssessmentGroup?.partRules?.[index].notPerform || false;
                    return (
                        <AssessmentRow
                            areaName='executedTestArea'
                            groupName="onSiteAssessmentGroup"
                            ruleIndex={index}
                            partRule={partRule}
                            protocolCodeTableValues={protocolCodeTableValues}
                            isEdit={isEdit}
                            disabledArea={notPerformArea}
                            disabledGroup={notPerformGroup}
                            disabledRule={notPerformRule}
                        />
                    )
                }
            )}
        </Container>
    </>
}


export default observer(OnSiteAssessment);
