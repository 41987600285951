import { faCommentAlt } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { observer } from "mobx-react-lite";
import React, { Fragment, useState } from "react";
import { Button, Col, OverlayTrigger, Popover, Row, Form } from "react-bootstrap";
import { Field } from "react-final-form";
import { FormApi } from "final-form";
import CheckboxInput from "../../../../app/common/form/CheckboxInput";
import SelectInput from "../../../../app/common/form/SelectInput";
import TextAreaInput from "../../../../app/common/form/TextAreaInput";
import TextInput from "../../../../app/common/form/TextInput";
import SimpleTextInput from "../../../../app/common/form/SimpleTextInput";
import AutocompleteSimpleInput from "../../../../app/common/form/AutocompleteSimpleInput";
import { IProtocolArea, IProtocolPartRule, ICarBodyProtocol, IProtocolPartGroup } from "../../../../app/models/protocol";
//import { calendarWeeks, defectStates } from "../../../../app/common/utils/constants";
import { IEnums } from "../../../../app/models/enum";

interface IProps {
  partRule: IProtocolPartRule;
  area: IProtocolArea;
  group: IProtocolPartGroup | undefined;
  useGroupAddInfo: boolean;
  fieldName: string;
  ruleIndex: number;
  isEdit: boolean;
  isDisabledAreaOrGroup: boolean;
  isDisabledRule: boolean;
  useAreaName: boolean;
  useDoorProtect: boolean;
  form: FormApi<ICarBodyProtocol>;
  values: ICarBodyProtocol;
  handleOpenModelDefectForm: (edit: boolean, protocolRuleId: number, values: ICarBodyProtocol, partName: string | undefined) => void;
}

const ProtocolRule: React.FC<IProps> = ({
  partRule,
  area,
  group,
  useGroupAddInfo,
  fieldName,
  ruleIndex,
  isEdit,
  isDisabledAreaOrGroup,
  isDisabledRule,
  useAreaName,
  useDoorProtect,
  form,
  values,
  handleOpenModelDefectForm,
}) => {
  const [isDefect, setDefect] = useState(!!(partRule.isDefect ?? false));
 
  const GetPartName = (area: IProtocolArea, group: IProtocolPartGroup | undefined, partRule: IProtocolPartRule ) : string => {
    var partName: string = area.name;
    if(group) { partName = partName + " - " + group.name; }
    partName = partName + " - " + partRule.name;
    return partName;
  }

  const inlineInputs = (
    <Form.Group>
      <Fragment>
      {partRule.measuredValues &&  
        partRule.measuredValues?.map((value, ruleValueIndex) => (
          <Field<string>
          name={`${fieldName}.measuredValues[${ruleValueIndex}]`}
          key={`${fieldName}.measuredValues[${ruleValueIndex}]`}
          component={SimpleTextInput}
          initialValue={value}
          // placeholder="Naměřené hodnoty"
          rows={1}
          size="sm"
          //validate={(value) => validateByRules(value, ruleValueIndex)}
          inputOnChange={(e: any) => {
            const validationResult = form.mutators.checkMeasuredValue(e);
            if(validationResult){
              setDefect(true)
            }
            else if(validationResult === false){
              setDefect(false)
            }
          }}
          disabled={!isEdit || isDisabledRule}
          className={`inline-field-${partRule.measuredValues?.length}`}
        />
        ))
      }
      </Fragment>
    </Form.Group>
  );

  return (
    <Fragment>
      {ruleIndex === 0 && (
        <tr
          className={`protocol-rule protocol-rule--header no-print ${
            isDisabledAreaOrGroup ? "protocol-rule--disabled" : ""
          }`}
        >
          <td>Měřený díl a poloha</td>
          <td>
            <h4>Předpis</h4>
            <small className="text-muted">[N;Nm;Ncm;mm;m/s] subj.</small>
          </td>
          <td>
            <h4>Naměřeno</h4>
            <small className="text-muted">[N;Nm;Ncm;mm;m/s] subj.</small>
          </td>
          <td>
            <h4>Hodnocení</h4>
            <small className="text-muted">Vyhovuje / Závada</small>
          </td>
          <td className="no-print"></td>
        </tr>
      )}
      <tr
        className={`protocol-rule ${
          isDisabledRule ? "protocol-rule--disabled" : ""
        }`}
        style={
          useAreaName ? { borderTop: "1px solid grey" } : {}
        }
      >
        <td className="print-only">
          <div>{useAreaName && area.name}</div>
          {useDoorProtect && (<div>Door protect</div>)}
          {group && useGroupAddInfo && (<div>{group.additionalInfo}</div>)}
          <div>{partRule.additionalInfo}</div>
        </td>
        <td>
          {partRule.name}
          <div className="no-print">{group && group.additionalInfo}</div>
          <div className="no-print">{partRule.additionalInfo}</div>
        </td>
        <td>
          {!partRule.showFunctionCode && !partRule.mathEvaluate && <span>{partRule.functionName}</span>}
          {partRule.showFunctionCode && <span>{partRule.functionCode}</span>}{" "} <span> {partRule.rule} {partRule.unit}</span>
        </td>
        <td>
          {partRule.ruleValues && isEdit && (
            <div className="no-print">
              {inlineInputs}
            </div>
          )}

          <Field<string>
            name={`${fieldName}.measuredValue`}
            component={AutocompleteSimpleInput}
            items={["subj.", "funkce", "elektrický"]}
            initialValue={partRule.measuredValue}
            rows={1}
            size="sm"
            disabled={!isEdit || isDisabledRule}
          />

        </td>
        <td>
          <Row>
            <Col sm={5} style={{ paddingRight: 3 }}>
              <Field<string>
                name={`${fieldName}.stringEval`}
                initialValue={partRule.stringEval?.toString()}
                component={AutocompleteSimpleInput}
                items={["OK", "NOK"]}
                rows={1}
                size="sm"
                disabled={!isEdit || isDisabledRule}
              />
            </Col>
            <Col sm={5} style={{ paddingLeft: 3 }}>
              <Field
                name={`${fieldName}.signDefect`}
                inputOnChange={(e: any) => {
                  form.mutators.calculateDefects(e);
                }}
                options={[
                  { key: null, value: "" },
                  { key: 1, value: "1 - A1" },
                  { key: 2, value: "2 - A" },
                  { key: 3, value: "3 - A" },
                  { key: 4, value: "4 - B1" },
                  { key: 5, value: "5 - B" },
                  { key: 6, value: "6 - C1" },
                  { key: 7, value: "7 - C" },
                  { key: 8, value: "8 - C" },
                  { key: 9, value: "9 - C" },
                  { key: 10, value: "10 - C" },
                ]}
                component={SelectInput}
                disabled={!isEdit || isDisabledRule}
              />
            </Col>
            
              <Col sm={1} style={{ paddingLeft: 0, paddingRight: 0 }} className="no-print">
                <Button
                  size="sm"
                  style={{ width: "3em" }}
                  variant="primary"
                  title="Komentáře k závadám"
                  onClick={() => handleOpenModelDefectForm(isEdit, partRule.protocolRuleId, values, GetPartName(area, group, partRule))}
                >
                  <FontAwesomeIcon icon={faCommentAlt} />
                </Button>
              </Col>
            
          </Row>
        </td>
        <td className="no-print">
          <Field
            name={`${fieldName}.notPerform`}
            initialValue={partRule.notPerform || false}
            title="Neprovádět"
            options={[
              { key: 1, value: "ano" },
              { key: 0, value: " ne " },
            ]}
            format={(v) => (v ? 1 : 0)}
            parse={(v) => (v ? true : false)}
            type={"checkbox"}
            // inputOnChange={(v: any) => {
            //   setDisabled(v.target.checked);
            // }}
            component={CheckboxInput}
            disabled={!isEdit}
            labelWidth={8}
            valueWidth={4}
          />
        </td>
      </tr>
    </Fragment>
  );
};

export default observer(ProtocolRule);
