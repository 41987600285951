import React, { Fragment, useContext, useState } from "react";
import { Row, Button } from "react-bootstrap";
import { Table } from "react-bootstrap";
import { observer } from "mobx-react-lite";
import moment from "moment";
import AlertMessage from "../../alert/AlertMessage";
import Loader from "../../loader/Loader";
import {RootStoreContext} from "../../../app/stores/rootStore";
import Can from "../../../authorization/Can";
import {toast} from "react-toastify";
import {usePrNumbersSyncStatesQuery, useStartSyncAllPrNumbersMutation, useStartSyncPrFamiliesMutation, useStartSyncPrNumbersByFamiliesMutation, useStartSyncPrNumbersByProductsMutation, useStartSyncPrProductsMutation, } from "../../../app/api/client";

const PrNumbersSyncStatesTab: React.FC = () => {

    const rootStore = useContext(RootStoreContext);
    const { openDialog } = rootStore.modalStore;
    const { user } = rootStore.authStore;
    const [ errors, setErrors ] = useState([]);
    const [ showSubmitError, setShowSubmitError ] = useState(false);

    const { data: prNumberSyncStates, isFetching: isLoading } = usePrNumbersSyncStatesQuery();
    const { mutateAsync: startSyncAllPrNumbers, isLoading: isExecutingAll } = useStartSyncAllPrNumbersMutation();
    const { mutateAsync: startSyncPrProducts, isLoading: isExecutingPrProducts } = useStartSyncPrProductsMutation();
    const { mutateAsync: startSyncPrFamilies, isLoading: isExecutingPrFamilies } = useStartSyncPrFamiliesMutation();
    const { mutateAsync: startSyncPrNumbersByFamilies, isLoading: isExecutingPrNumbersByFamilies } = useStartSyncPrNumbersByFamiliesMutation();
    const { mutateAsync: startSyncPrNumbersByProducts, isLoading: isExecutingPrNumbersByProducts } = useStartSyncPrNumbersByProductsMutation();


    const handleFullSyncPrNumbers = () => {
        setErrors([]);
        setShowSubmitError(false);

        openDialog(
            <div>
                <p>Komletní synchronizace PR čísel je časově náročná operace. Chcete ji nyní provést?</p>
            </div>,
            {
              title: "Kompletní synchronizace PR čísel",
              confirmButtonLabel: "Ano, provést synchronizaci",
              cancelButtonLabel: "Zpět",
              onConfirm: () => {
                startSyncAllPrNumbers()
                .then(() => {
                    toast.success(`Kompletní synchronizace PR čísel proběhla bez závad`);
                })
                .catch((error: any) => {
                    console.log(error);
                    toast.error("Při kompletní synchronizaci PR čísel nastala chyba!");
                    setErrors(
                    error.response?.data?.errors &&
                    Object.values(error.response?.data?.errors)
                    );
                    setShowSubmitError(true);
                });
              },
              onCancel: () => {},
            }
          );
    };

    const handleStartSyncPrProducts = () => {
        setErrors([]);
        setShowSubmitError(false);

        openDialog(
            <div>
                <p>Synchronizace PR čísel produktů je časově náročná operace. Chcete ji nyní provést?</p>
            </div>,
            {
              title: "Synchronizace PR čísel produktů",
              confirmButtonLabel: "Ano, provést synchronizaci",
              cancelButtonLabel: "Zpět",
              onConfirm: () => {
                startSyncPrProducts()
                    .then(() => {
                        toast.success(`Synchronizace PR čísel produktů proběhla bez závad`);
                    })
                        .catch((error: any) => {
                        console.log(error);
                        toast.error("Při synchronizaci PR čísel produktů nastala chyba!");
                        setErrors(
                            error.response?.data?.errors &&
                            Object.values(error.response?.data?.errors)
                        );
                        setShowSubmitError(true);
                    });
                },
              onCancel: () => {},
            }
          );
    };

    const handleStartSyncPrFamilies = () => {
        setErrors([]);
        setShowSubmitError(false);

        openDialog(
            <div>
                <p>Synchronizace rodin PR čísel je časově náročná operace. Chcete ji nyní provést?</p>
            </div>,
            {
              title: "Synchronizace rodin PR čísel",
              confirmButtonLabel: "Ano, provést synchronizaci",
              cancelButtonLabel: "Zpět",
              onConfirm: () => {
                startSyncPrFamilies()
                    .then(() => {
                        toast.success(`Synchronizace rodin PR čísel proběhla bez závad`);
                    })
                        .catch((error: any) => {
                        console.log(error);
                        toast.error("Při synchronizaci rodin PR čísel nastala chyba!");
                        setErrors(
                            error.response?.data?.errors &&
                            Object.values(error.response?.data?.errors)
                        );
                        setShowSubmitError(true);
                    });
                },
              onCancel: () => {},
            }
          );
    };

    const handleStartSyncPrNumbersByFamilies = () => {
        setErrors([]);
        setShowSubmitError(false);

        openDialog(
            <div>
                <p>Synchronizace PR čísel podle PR rodin je časově náročná operace. Chcete ji nyní provést?</p>
            </div>,
            {
              title: "Synchronizace PR čísel podle PR rodin",
              confirmButtonLabel: "Ano, provést synchronizaci",
              cancelButtonLabel: "Zpět",
              onConfirm: () => {
                startSyncPrNumbersByFamilies()
                    .then(() => {
                        toast.success(`Synchronizace PR čísel proběhla bez závad`);
                    })
                        .catch((error: any) => {
                        console.log(error);
                        toast.error("Při synchronizaci PR čísel nastala chyba!");
                        setErrors(
                            error.response?.data?.errors &&
                            Object.values(error.response?.data?.errors)
                        );
                        setShowSubmitError(true);
                    });
                },
              onCancel: () => {},
            }
          );
    };

    const handleStartSyncPrNumbersByProducts = () => {
        setErrors([]);
        setShowSubmitError(false);

        openDialog(
            <div>
                <p>Synchronizace PR čísel k produktům je časově náročná operace. Chcete ji nyní provést?</p>
            </div>,
            {
              title: "Synchronizace PR čísel k produktům",
              confirmButtonLabel: "Ano, provést synchronizaci",
              cancelButtonLabel: "Zpět",
              onConfirm: () => {
                startSyncPrNumbersByProducts()
                    .then(() => {
                        toast.success(`Synchronizace PR čísel k produktům proběhla bez závad`);
                    })
                        .catch((error: any) => {
                        console.log(error);
                        toast.error("Při synchronizaci PR čísel k produktům nastala chyba!");
                        setErrors(
                            error.response?.data?.errors &&
                            Object.values(error.response?.data?.errors)
                        );
                        setShowSubmitError(true);
                    });
                },
              onCancel: () => {},
            }
          );
    };
    
    if (isLoading ||
        isExecutingAll ||
        isExecutingPrProducts ||
        isExecutingPrFamilies ||
        isExecutingPrNumbersByFamilies ||
        isExecutingPrNumbersByProducts
    ) return <Loader/>;

    return (
        <Fragment>
            <Row className="pageheader">
                <div className="pageheader__left col-sm-8">
                    <h3>Stav synchronizace PR čísel</h3>
                </div>
                <div className="pageheader__right col-sm-4">
                <Can
                    roleId={user?.userRoleId}
                    perform="settings-prNumbers:edit"
                    yes={
                        <Button
                        variant="outline-info"
                        type="button"
                        onClick={() => handleFullSyncPrNumbers()}
                      >
                        Kompletní synchronizace
                      </Button>
                    }
                  />
                </div>
            </Row>
            <div>

                <AlertMessage
                    type="danger"
                    heading="Nastala chyba při ukládání"
                    show={showSubmitError}
                    setShow={setShowSubmitError}
                    messageList={errors}
                />

                <Table>
                    <tbody>
                        <tr key={'prProducts'} className="d-flex">
                            <td colSpan={2} className="col-4">{prNumberSyncStates?.prNumSyncStateProducts.prNumEntityName}</td>
                            <td className="col-2">{prNumberSyncStates?.prNumSyncStateProducts.lastSynced && moment(prNumberSyncStates?.prNumSyncStateProducts.lastSynced).format("D.M.YY HH:mm")}</td>
                            <td className="col-2">{prNumberSyncStates?.prNumSyncStateProducts.countLastSync && prNumberSyncStates?.prNumSyncStateProducts.countLastSync}</td>
                            <td className="col-4">
                                <Can
                                    roleId={user?.userRoleId}
                                    perform="settings-prNumbers:edit"
                                    yes={
                                        <Button
                                        variant="outline-info"
                                        type="button"
                                        onClick={() => handleStartSyncPrProducts()}
                                    >
                                        Synchronizace produktů
                                    </Button>
                                    }
                                />
                            </td>
                        </tr>
                        <tr key={'prFamilies'} className="d-flex">
                            <td colSpan={2} className="col-4">{prNumberSyncStates?.prNumSyncStateFamilies.prNumEntityName}</td>
                            <td className="col-2">{prNumberSyncStates?.prNumSyncStateFamilies.lastSynced && moment(prNumberSyncStates?.prNumSyncStateFamilies.lastSynced).format("D.M.YY HH:mm")}</td>
                            <td className="col-2">{prNumberSyncStates?.prNumSyncStateFamilies.countLastSync && prNumberSyncStates?.prNumSyncStateFamilies.countLastSync}</td>
                            <td className="col-4">
                                <Can
                                    roleId={user?.userRoleId}
                                    perform="settings-prNumbers:edit"
                                    yes={
                                        <Button
                                        variant="outline-info"
                                        type="button"
                                        onClick={() => handleStartSyncPrFamilies()}
                                    >
                                        Synchronizace rodin PR čísel
                                    </Button>
                                    }
                                />
                            </td>
                        </tr>
                        <tr key={'prNumbers'} className="d-flex">
                            <td colSpan={2} className="col-4">{prNumberSyncStates?.prNumSyncStatePrNumbers.prNumEntityName}</td>
                            <td className="col-2">{prNumberSyncStates?.prNumSyncStatePrNumbers.lastSynced && moment(prNumberSyncStates?.prNumSyncStatePrNumbers.lastSynced).format("D.M.YY HH:mm")}</td>
                            <td className="col-2">{prNumberSyncStates?.prNumSyncStatePrNumbers.countLastSync && prNumberSyncStates?.prNumSyncStatePrNumbers.countLastSync}</td>
                            <td className="col-4">
                                <Can
                                    roleId={user?.userRoleId}
                                    perform="settings-prNumbers:edit"
                                    yes={
                                        <Button
                                        variant="outline-info"
                                        type="button"
                                        onClick={() => handleStartSyncPrNumbersByFamilies()}
                                    >
                                        Synchronizace PR čísel podle PR rodin
                                    </Button>
                                    }
                                />
                            </td>
                        </tr>
                        <tr key={'prNumSyncStatePrNumbersByProducts'} className="d-flex">
                            <td colSpan={2} className="col-4">Synchronizace PR čísel uvolněných k produktům</td>
                            <td className="col-2"></td>
                            <td className="col-2"></td>
                            <td className="col-4">
                                <Can
                                    roleId={user?.userRoleId}
                                    perform="settings-prNumbers:edit"
                                    yes={
                                        <Button
                                        variant="outline-info"
                                        type="button"
                                        onClick={() => handleStartSyncPrNumbersByProducts()}
                                    >
                                        Synchronizace PR čísel k produktům
                                    </Button>
                                    }
                                />
                            </td>
                        </tr>

                        {prNumberSyncStates?.prNumSyncStatePrNumbersByProducts.map((syncStatePrNumbersByProduct: any, index: number) => (
                            <tr key={"PrNumbersByProduct_" + syncStatePrNumbersByProduct.prProdCode} className="d-flex">
                                <td className="col-1"></td>
                                <td className="col-3">{syncStatePrNumbersByProduct.carModelName + ' (' + syncStatePrNumbersByProduct.prProdCode  + ')'}</td>
                                <td className="col-2">{syncStatePrNumbersByProduct.lastSynced && moment(syncStatePrNumbersByProduct.lastSynced).format("D.M.YY HH:mm")}</td>
                                <td className="col-2">{syncStatePrNumbersByProduct.countLastSync && syncStatePrNumbersByProduct.countLastSync}</td>
                                <td className="col-4"></td>
                            </tr>

                        ))}

                    </tbody>
                </Table>
            </div>  
            
        </Fragment>

    );
};

export default observer(PrNumbersSyncStatesTab);