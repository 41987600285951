import React, { useContext } from "react";
import "./FullCalendarBlockingEvent.scss";
import WorkerBadge from "./WorkerBadge";
import { IBlockingEvent, IHoliday } from "../../app/models/blockingEvent";
import { Button } from "react-bootstrap";
import { RootStoreContext } from "../../app/stores/rootStore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import FullCalendar from "@fullcalendar/react";
import BlockingEventForm from "../blockingEventForm/BlockingEventForm";

interface IProps {
  event: {
    allDay: boolean;
    start: Date;
    end: Date;
    extendedProps: {
      blockingEvent: IBlockingEvent;
      history: any;
      calendarComponentRef: React.RefObject<FullCalendar>;
    };
  };
}

const FullCalendarBlockingEvent: React.FC<IProps> = ({ event }) => {
  const rootStore = useContext(RootStoreContext);
  const { blockingEvent, calendarComponentRef } = event.extendedProps;
  const { openModal } = rootStore.modalStore;
  const { expertGroupUsers } = rootStore.pageGroupScheduleStore;

  const updateBlockingEvents = () => {
    calendarComponentRef?.current?.getApi()?.refetchEvents();
  };

  // absence + blokace + udalost
  return (
    <div
      className={`fullcalendar-blockingevent fullcalendar-blockingevent--${blockingEvent.blockingEventTypeName
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .toLocaleLowerCase()}`}
    >
      <div className="fullcalendar-blockingevent__row">
        <div className="fullcalendar-blockingevent__type fullcalendar-blockingevent-section">
          {blockingEvent.blockingEventTypeId === 4
            ? blockingEvent.eventName
            : blockingEvent.blockingEventTypeName}
        </div>
        <div className="fullcalendar-blockingevent__time fullcalendar-blockingevent-section">
          {!blockingEvent.wholeDay &&
            ` ${blockingEvent.startTime?.replace(
              /^0+/,
              ""
            )}\u00A0- ${blockingEvent.endTime?.replace(/^0+/, "")}`}
          {blockingEvent.wholeDay && "Celý den"}
        </div>
        <div className="fullcalendar-blockingevent__link fullcalendar-blockingevent-section">
          <Button
            className="fullcalendar-blockingevent__link--btn"
            variant={"link"}
            title={" Detail události"}
            onClick={() => {
              openModal(
                <BlockingEventForm
                  expertGroupId={blockingEvent.expertGroupId!}
                  callback={updateBlockingEvents}
                  workerOptions={expertGroupUsers
                    .filter((w) => w.isActive)
                    .map((w) => ({
                      key: w.userId,
                      value: w.fullName,
                    }))}
                  aBlockingEvent={blockingEvent}
                />,
                {
                  preventClosing: true,
                  title: "Detail absence, blokace, události",
                }
              );
            }}
          >
            <FontAwesomeIcon icon={faSearch} />
          </Button>
        </div>
        <div className="fullcalendar-blockingevent__users fullcalendar-blockingevent-section">
          {blockingEvent.wholeGroup && "Celá skupina"}
          {!blockingEvent.wholeGroup && (
            <WorkerBadge
              id={blockingEvent.userId!}
              label={blockingEvent.shortcut || blockingEvent.fullName || "N/A"}
              backgroundColor={blockingEvent.color!}
              description={blockingEvent.fullName!}
            />
          )}
        </div>
      </div>
      <div className="fullcalendar-blockingevent__row">
        <div className="fullcalendar-blockingevent__description fullcalendar-blockingevent-section">
          {blockingEvent.description}
        </div>
      </div>
    </div>
  );
};
export default FullCalendarBlockingEvent;
