import { IUser } from "./user";
import { ICarTestConflict } from "./carTest";
import { IBlockingEvent } from "./blockingEvent";

export interface IExpertGroupsDTO {
  expertGroups: IExpertGroup[];
  count: number;
}
export interface IExpertGroup {
  expertGroupId: number;
  expertGroupCode: string;
  expertGroupName: string;
  expertGroupColorId: number;
  expertGroupColor: string;
}
export interface IExpertGroupWorkersDTO {
  workers: IWorker[];
  dateFrom: Date;
  dateTo: Date;
  count: number;
}

export interface IExpertGroupDetail {
  expertGroupId: number;
  code: string;
  name: string;
  workplacesCount: number;
  workerCountPerTest: number;
  dayOfExtraTimeStart: Date;
  dayOfExtraTimeEnd: Date;
  workTimeStart: string;
  workTimeEnd: string;
  colorId: number;
  color: string;
  workers: IWorker[];
  blockingEvents: IBlockingEvent[];
  carTestConflicts: ICarTestConflict[];
  isConfirmedSave: boolean;
}

export interface IWorker {
  userId: number;
  login: string;
  rownumber?: number;
  shortcut: string;
  titleBefore?: string;
  firstName: string;
  surname: string;
  titleAfter?: string;
  fullName: string;
  workTimeStart: string;
  workTimeEnd: string;
  isDeleted: boolean;
  isActive: boolean;
  performTests: boolean;
  isAvailableToTest: boolean;
}





export interface IExpertGroupForm {
  expertGroupId?: number;
  code: string;
  name: string;
  workplacesCount: number;
  workerCountPerTest: number;
  dayOfExtraTimeStart?: Date;
  dayOfExtraTimeEnd?: Date;
  workTimeStart?: string;
  workTimeEnd?: string;
  colorId: number;
  workers: IWorkerForm[];
  isConfirmedSave: boolean;
}
export interface IWorkerForm {
  userId: number;
  workTimeStart: string;
  workTimeEnd: string;
  isDeleted: boolean;
}

export interface IBlockingEventForm {
  blockingEventId?: number;
  blockingEventTypeId: number;
  expertGroupId?:number;
  wholeGroup: boolean;
  userId?: number;
  startDate: Date;
  endDate: Date;
  startTime?: string;
  endTime?: string;
  description: string;
  wholeDay: boolean;
  isDeleted: boolean;
}



export interface IAddedWorker {
  rownumber: number;
  userId: number;
  fullName: string;
  login: string;
  workTimeStart: string;
  workTimeEnd: string;
  isDeleted: boolean;
}

export const convertIUserToIAddedWorker = (
  user: IUser,
  rowId: number
): IAddedWorker => {
  const worker: IAddedWorker = (({ userId, login, fullName }) => ({
    userId,
    login,
    fullName,
    rownumber: rowId,
    workTimeStart: "",
    workTimeEnd: "",
    isDeleted: false,
  }))(user);

  return worker;
};

export const convertIWorkerToIWorkerForm = (worker: IWorker): IWorkerForm => {
  const form: IWorkerForm = (({
    userId,
    workTimeStart,
    workTimeEnd,
    isDeleted,
  }) => ({
    userId,
    workTimeStart,
    workTimeEnd,
    isDeleted,
  }))(worker);

  return form;
};

export const convertIAddedWorkerToIWorkerForm = (
  worker: IAddedWorker
): IWorkerForm => {
  const form: IWorkerForm = (({
    userId,
    workTimeStart,
    workTimeEnd,
    isDeleted,
  }) => ({
    userId,
    workTimeStart,
    workTimeEnd,
    isDeleted,
  }))(worker);

  return form;
};

export const convertIExpertGroupToIExpertGroupForm = (
  expertGroup: IExpertGroupDetail
): IExpertGroupForm => {
  const form: IExpertGroupForm = (({
    expertGroupId,
    code,
    name,
    workplacesCount,
    workerCountPerTest,
    dayOfExtraTimeStart,
    dayOfExtraTimeEnd,
    workTimeStart,
    workTimeEnd,
    colorId,
    isConfirmedSave,
  }) => ({
    expertGroupId,
    code,
    name,
    workplacesCount,
    workerCountPerTest,
    dayOfExtraTimeStart,
    dayOfExtraTimeEnd,
    workTimeStart,
    workTimeEnd,
    colorId,
    isConfirmedSave: isConfirmedSave || false,
    workers: [],
    blockingEvents: [],
  }))(expertGroup);

  return form;
};

export const convertIBlockingEventToIBlockingEventForm = (
  event: IBlockingEvent
): IBlockingEventForm => {
  const form: IBlockingEventForm = (({
    blockingEventId,
    expertGroupId,
    blockingEventTypeId,
    wholeGroup,
    userId,
    startDate,
    endDate,
    startTime,
    endTime,
    description,
    wholeDay,
    isDeleted,
  }) => ({
    blockingEventId,
    expertGroupId,
    blockingEventTypeId,
    wholeGroup,
    userId,
    startDate,
    endDate,
    startTime,
    endTime,
    description,
    wholeDay,
    isDeleted,
  }))(event);

  return form;
};

