import React, { useContext, useEffect, useState } from "react";
import { Row, Col, Form } from "react-bootstrap";
import { Form as FinalForm, Field } from "react-final-form";
import DateInput from "../../../app/common/form/DateInput";
import NumberInput from "../../../app/common/form/NumberInput";
import TextInput from "../../../app/common/form/TextInput";
import AlertMessage from "../../alert/AlertMessage";
import EditSaveResetButtons from "../../ButtonGroups/EditSaveResetButtons";
import moment from "moment";
import { RootStoreContext } from "../../../app/stores/rootStore";
import { observer } from "mobx-react-lite";

interface IProps {
  protocolType?: number;
}

const ProtocolTypeTab: React.FC<IProps> = ({ protocolType }) => {
  const rootStore = useContext(RootStoreContext);
  const { loadProtocolTypeInfo, saveProtocolTypeInfo, protocolTypeInfoRegistry } =
    rootStore.protocolStore;

  useEffect(() => {
    if(protocolType){
      loadProtocolTypeInfo(protocolType);
    }
  }, [protocolType, loadProtocolTypeInfo]);

  const [edit, setEdit] = useState(false);
  const [errors, setErrors] = useState([]);
  const [showSubmitError, setShowSubmitError] = useState(false);

  const handleSubmitForm = (values: any) => {
    setErrors([]);
    setShowSubmitError(false);
    console.log(values);
    saveProtocolTypeInfo(values)
      .then(() => {
        setEdit(false);
      })
      .catch((error: any) => {
        console.log(error);
        setErrors(Object.values(error.response?.data?.errors));
        setShowSubmitError(true);
      });
  };

  // Reset all form values to initial values
  const handleReset = (form: any) => {
    setEdit(false);
    form.reset();
  };

  return (
    <div>     
      <FinalForm
        initialValues={protocolTypeInfoRegistry.get(protocolType ?? 0)}
        onSubmit={handleSubmitForm}
        render={({ handleSubmit, form }) => (
          <Form onSubmit={handleSubmit}>
            <div className="pageheader">
              <div className="pageheader__left">
                <h3>Hodnoty protokolu</h3>
              </div>
              <div className="pageheader__right">
                <EditSaveResetButtons
                  edit={edit}
                  setEdit={setEdit}
                  handleReset={handleReset}
                  form={form}
                  isSaving={false}
                />
              </div>
            </div>
            <AlertMessage
              type="danger"
              heading="Nastala chyba při ukládání"
              show={showSubmitError}
              setShow={setShowSubmitError}
              messageList={errors}
            />
            <Row>
              <Col md={12}>
                <Field
                  title={"Název dokumentu"}
                  name={"documentName"}
                  component={TextInput}
                  titleWidth={5}
                  fieldWidth={3}
                  disabled={!edit}
                />
                <Field
                  title={"Evidenční číslo"}
                  name={"registrationNumber"}
                  component={TextInput}
                  titleWidth={5}
                  fieldWidth={3}
                  disabled={!edit}
                />
                <Field
                  title={"Způsob uložení"}
                  name={"storageMethod"}
                  component={TextInput}
                  titleWidth={5}
                  fieldWidth={3}
                  disabled={!edit}
                />
                <Field
                  title={"Místo uložení"}
                  name={"storageLocation"}
                  component={TextInput}
                  titleWidth={5}
                  fieldWidth={3}
                  disabled={!edit}
                />
                <Field
                  title={"Skartační znak"}
                  name={"shreddingSign"}
                  component={TextInput}
                  titleWidth={5}
                  fieldWidth={3}
                  disabled={!edit}
                />
                <Field
                  title={"Skartace"}
                  name={"shreddingDeadlines"}
                  component={NumberInput}
                  rightAppend={"let"}
                  titleWidth={5}
                  fieldWidth={3}
                  disabled={!edit}
                />
                <Field
                  title={"Spouštěcí událost pro skartaci"}
                  name={"shreddingTriggerEvent"}
                  component={TextInput}
                  titleWidth={5}
                  fieldWidth={3}
                  disabled={!edit}
                />
                <Field
                  title={"Klasifikace informací"}
                  name={"classification"}
                  component={TextInput}
                  titleWidth={5}
                  fieldWidth={3}
                  disabled={!edit}
                />
                <Field
                  title={"Číslo revize"}
                  name={"revisionNumber"}
                  component={NumberInput}
                  titleWidth={5}
                  fieldWidth={3}
                  disabled={!edit}
                />
                <Field
                  title={"Datum revize"}
                  name={"revisionDate"}
                  format={(val) => (val ? moment(val).toDate() : undefined)}
                  component={DateInput}
                  titleWidth={5}
                  fieldWidth={3}
                  disabled={!edit}
                />
              </Col>
            </Row>
          </Form>
        )}
      />
    </div>
  );
};

export default observer(ProtocolTypeTab);
