import "./DrivingCharacteristicsProtocol.scss"
import React, {Fragment, useContext, useState} from "react";
import {Button, Col, Form, Row, Table} from "react-bootstrap";
import {Field, Form as FinalForm} from "react-final-form";
import {observer} from "mobx-react-lite";
import {Link, RouteComponentProps} from "react-router-dom";
import {RootStoreContext} from "../../../../app/stores/rootStore";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPrint, faFileExcel} from "@fortawesome/free-solid-svg-icons";
import {FormApi} from "final-form";
import TextAreaInput from "../../../../app/common/form/TextAreaInput";
import EditSaveResetButtons from "../../../ButtonGroups/EditSaveResetButtons";
import Loader from "../../../loader/Loader";
import logo from "../../../../assets/images/Skoda_Wordmark_RGB_Emerald_Green.svg";
import Can from "../../../../authorization/Can";
import {toast} from "react-toastify";
import DefectsArea from "../protocolParts/DefectsArea";
import ProtocolHeaderCarInfo from "../protocolParts/ProtocolHeaderCarInfo";
import AdditionalInfoArea from "./AdditionalInfoArea"
import ExecutedTestArea from "./ExecutedTestArea"
import MeasuredCarArea from "./MeasuredCarArea";
import {drivingCharacteristicsMutators} from "./Mutators";
import {IDrivingCharacteristicsProtocol,} from "../../../../app/models/protocol";
import {
    useDrivingCharacteristicsProtocolMutation,
    useDrivingCharacteristicsProtocolQuery,
    useLoadEnumsQuery
} from "../../../../app/api/client";
import DrivingTestArea from "./DrivingTestArea";
import DefectsCount from "../protocolParts/DefectsCount";
import {defectMutator} from "../protocolParts/DefectMutator";
import AlertMessage from "../../../alert/AlertMessage";
import agent from "../../../../app/api/agent";

interface PartialProtocolParams {
    protocolTypeId: string;
    carId: string;
    carTestId: string;
    carProtocolId: string;
}

const DrivingCharacteristicsProtocol: React.FC<RouteComponentProps<PartialProtocolParams>> = ({
    match,
    history,
  }) => {
    const rootStore = useContext(RootStoreContext);
    const {data: enums, isFetching: loadingEnums} = useLoadEnumsQuery();
    const { user } = rootStore.authStore;

    const [edit, setEdit] = useState(false);

    const [errors, setErrors] = useState([]);
    const [showSubmitError, setShowSubmitError] = useState(false);

    const protocolTypeId = Number(match.params.protocolTypeId);
    const carId = Number(match.params.carId);
    const carTestId = Number(match.params.carTestId);
    const carProtocolId = Number(match.params.carProtocolId);

    const {
        data: drivingCharacteristicsProtocol,
        isFetching: isLoading
    } = useDrivingCharacteristicsProtocolQuery(carId, carTestId, protocolTypeId, carProtocolId);
    const {mutateAsync} = useDrivingCharacteristicsProtocolMutation();

    const handleFinalFormSubmit = async (protocol: IDrivingCharacteristicsProtocol) => {

      setErrors([]);
      setShowSubmitError(false);

      mutateAsync(protocol)
        .then((response) => {
            setEdit(false);
            toast.success(`Protokol byl uložen`);
            if (!match.params.carProtocolId || match.params.carProtocolId == 'null') {
                history.push(
                    `/EngineDrivingCharacteristicsProtocol/${response.protocolTypeId}/${response.carId}/${response.carTestId}/null/${response.carProtocolId}`
                );
            }
        })
        .catch((error) => {
            setErrors(Object.values(error.response?.data?.errors));
            setShowSubmitError(true);
            toast.error(`Vyskytla se chyba při uložení protokolu`);
        });
    };

    const handleReset = (form: FormApi) => {
      form.reset();
      setEdit(false);
    };

    const handleGetDocument = async () => {

        try {
          const protocolTypeId = Number(match.params.protocolTypeId);
          const carId = Number(match.params.carId);
          const carTestId = Number(match.params.carTestId);
          const carProtocolId = Number(match.params.carProtocolId);
    
          var response = await agent.Protocols.getProtocolDocumentXlsx(carId, carTestId, protocolTypeId, carProtocolId);
          const href = URL.createObjectURL(response.data);
          console.log(response.headers);
    
          const link = document.createElement('a');
          link.href = href;
          link.setAttribute('download', 'file.xlsx'); //or any other extension
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          URL.revokeObjectURL(href);
        } catch (error) {
            console.error("Error downloading:", error);
        }
      };

    if (isLoading || loadingEnums) {
        return <Loader/>
    }

    return (
        <div
          id="DrivingCharacteristicsProtocol"
          className="container"
          style={{ marginBottom: "3rem" }}
        >

          <div className="printheader">
            <div className="printheader__left">
                <div className="printheader--image">
                    <img src={logo} alt='logo'/>
                </div>
            </div>
            <div className="printheader__right">
                <div className="text--header">Zkouškový protokol</div>
                <div className="text--text">
                    Pracovní skupina{" "}
                    <span className="text--expert-group">{drivingCharacteristicsProtocol?.carTest.expertGroupName}</span>
                </div>
                <div className="text--text">
                  <span>{drivingCharacteristicsProtocol?.placeCode}</span> {drivingCharacteristicsProtocol?.placeDescription}
                </div>
            </div>
          </div>

          <div className="printfooter">
                <Table>
                    <tbody>
                    <tr>
                        <td colSpan={11}>
                            {drivingCharacteristicsProtocol?.carTest.placeCode}{" "}
                            {drivingCharacteristicsProtocol?.car?.modelVdsCode || ""} / 1_01 &emsp; ŠKODA
                            AUTO a.s., Tř. Václava Klementa 869, 293 01 Mladá Boleslav II.,
                            Česká republika
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={3}>{drivingCharacteristicsProtocol?.documentName}</td>
                        <td>{drivingCharacteristicsProtocol?.registrationNumber}</td>
                        <td>{drivingCharacteristicsProtocol?.storageMethod}</td>
                        <td>{drivingCharacteristicsProtocol?.shreddingSign}</td>
                        <td colSpan={2}>{drivingCharacteristicsProtocol?.shreddingTriggerEvent}</td>
                        <td>{drivingCharacteristicsProtocol?.classification}</td>
                        <td>{drivingCharacteristicsProtocol?.storageLocation}</td>
                    </tr>
                    </tbody>
                </Table>
            </div>

            <table className="table-page-content">
                <thead>
                <tr>
                    <td>
                        {/* <!--placeholder for the fixed-position header--> */}
                        <div className="page-header-space"></div>
                    </td>
                </tr>
                </thead>

                <tbody>
                <tr>
                    <td>
                        <div>
                            <FinalForm
                                onSubmit={handleFinalFormSubmit}
                                initialValues={drivingCharacteristicsProtocol}
                                mutators={{
                                    ...drivingCharacteristicsMutators,
                                    calculateDefects: defectMutator.calculateDefects
                                }}
                                render={({handleSubmit, values, form}) => (
                                    <Form onSubmit={handleSubmit} id="protocol-form">
                                        <div className="pageheader  no-print">
                                            <div className="pageheader__left">
                                                <h1>Zkouškový protokol</h1>
                                                <h3>
                                                    Pracovní skupina{" "}
                                                    <span className="text--expert-group">
                              {drivingCharacteristicsProtocol?.carTest.expertGroupName}
                            </span>{" "}
                                                </h3>
                                            </div>
                                            <div className="pageheader__right">
                                                <Can
                                                    roleId={user?.userRoleId}
                                                    perform="driving-characteristics-protocol:edit"
                                                    data={{
                                                        userExpertGroupId: user?.expertGroupId,
                                                    }}
                                                    yes={
                                                        <EditSaveResetButtons
                                                            edit={edit}
                                                            setEdit={setEdit}
                                                            handleReset={handleReset}
                                                            form={form}
                                                            isSaving={false}
                                                            printButton={true}
                                                        />
                                                    }
                                                    no={
                                                        <Button
                                                            variant="outline-info"
                                                            type="button"
                                                            onClick={() => window.print()}
                                                        >
                                                            <FontAwesomeIcon icon={faPrint} title="Tisk"/>{" "}
                                                            Tisk
                                                        </Button>
                                                    }
                                                />
                                                {!edit && (
                                                    <Fragment>
                                                        <Button
                                                            variant="outline-info"
                                                            type="button"
                                                            style={{
                                                            marginLeft: "1rem",
                                                            }}
                                                            onClick={() => handleGetDocument()}
                                                        >
                                                            <FontAwesomeIcon icon={faFileExcel} title="XLSX" />{" "}
                                                            XLSX
                                                        </Button>

                                                        <Button
                                                            variant="outline-secondary"
                                                            style={{
                                                                marginLeft: "1rem",
                                                            }}
                                                            key={"CarDetail_" + drivingCharacteristicsProtocol?.car.carId}
                                                            as={Link}
                                                            to={`/car/${drivingCharacteristicsProtocol?.car.carId}`}
                                                        >
                                                            Detail vozu
                                                        </Button>
                                                        <Button
                                                            variant="outline-secondary"
                                                            style={{
                                                                marginLeft: "1rem",
                                                            }}
                                                            key={
                                                                "CarTest_" +
                                                                drivingCharacteristicsProtocol?.carTest.carTestId
                                                            }
                                                            as={Link}
                                                            to={`/carTest/${drivingCharacteristicsProtocol?.carTest.carTestId}`}
                                                        >
                                                            Detail zkoušky
                                                        </Button>
                                                    </Fragment>
                                                )}
                                            </div>
                                        </div>

                                        <AlertMessage
                                            type="danger"
                                            heading="Nastala chyba při ukládání protokolu"
                                            show={showSubmitError}
                                            setShow={setShowSubmitError}
                                            messageList={errors}
                                        />

                                        <div className="pagecontent">
                                            {!isLoading && drivingCharacteristicsProtocol && (
                                                <ProtocolHeaderCarInfo
                                                    car={drivingCharacteristicsProtocol.car}
                                                    carTest={drivingCharacteristicsProtocol.carTest}
                                                    measurementDate={undefined}
                                                />
                                            )}
                                            {drivingCharacteristicsProtocol?.additionalInfoArea &&
                                                <AdditionalInfoArea isEdit={edit}
                                                                    additionalInfo={drivingCharacteristicsProtocol?.additionalInfoArea}
                                                />
                                            }

                                            {drivingCharacteristicsProtocol?.measuredCarArea &&
                                                <MeasuredCarArea isEdit={edit}
                                                                 measuredCarArea={drivingCharacteristicsProtocol.measuredCarArea}
                                                                 isDisabled={
                                                                    values.measuredCarArea?.notPerform || false
                                                                 }
                                                />
                                            }

                                            {drivingCharacteristicsProtocol?.executedTestArea &&
                                                <ExecutedTestArea isEdit={edit}
                                                                  testArea={drivingCharacteristicsProtocol.executedTestArea}
                                                                  isDisabled={
                                                                    values.executedTestArea?.notPerform || false
                                                                  }
                                                                  form={form}
                                                />
                                            }

                                            {drivingCharacteristicsProtocol?.drivingTestArea &&
                                                <DrivingTestArea isEdit={edit}
                                                                 drivingTestArea={drivingCharacteristicsProtocol.drivingTestArea}
                                                                 isDisabled={
                                                                    values.drivingTestArea?.notPerform || false
                                                                  }
                                                                 form={form}
                                                />
                                            }

                                            <div className="protocol">
                                                {/*Tabulka zavad*/}
                                                {!isLoading &&
                                                    drivingCharacteristicsProtocol &&
                                                    drivingCharacteristicsProtocol?.defectsArea && (
                                                        <DefectsArea
                                                            isEdit={edit}
                                                            isDisabled={
                                                                values.defectsArea?.notPerform || false
                                                            }
                                                        />
                                                    )}

                                                {/*Poznamka k celkovemu protokolu*/}
                                                {!isLoading && (
                                                    <div style={{paddingTop: "1em"}}>
                                                        <h2>Poznámka</h2>
                                                        <Field<string>
                                                            name="note"
                                                            component={TextAreaInput}
                                                            disabled={!edit}
                                                            rows={5}
                                                        />
                                                    </div>
                                                )}

                                                {!isLoading && drivingCharacteristicsProtocol && (
                                                    <DefectsCount />
                                                )}
                                                {!isLoading && drivingCharacteristicsProtocol && (
                                                    <Table style={{marginTop: "50px"}}>
                                                        <tbody>
                                                        <tr>
                                                            <td style={{border: "0"}}>
                                                                <Form.Group controlId={"defectsCount"}>
                                                                    <Row>
                                                                        <Col xs={3}>
                                                                            <Form.Label>
                                                                                Odpovědná osoba:
                                                                            </Form.Label>
                                                                        </Col>
                                                                        <Col
                                                                            xs={5}
                                                                            style={{
                                                                                textAlign: "center",
                                                                                borderBottom: "1px solid lightgray",
                                                                            }}
                                                                        >
                                                                            <Form.Label>
                                                                                {
                                                                                    drivingCharacteristicsProtocol.workersExecutingTest
                                                                                }
                                                                            </Form.Label>
                                                                        </Col>
                                                                    </Row>
                                                                </Form.Group>
                                                            </td>
                                                        </tr>
                                                        </tbody>
                                                    </Table>
                                                )}
                                            </div>
                                        </div>
                                    </Form>
                                )}
                            />
                        </div>
                    </td>
                </tr>
                </tbody>

                <tfoot>
                <tr>
                    <td>
                        <div className="page-footer-space"></div>
                    </td>
                </tr>
                </tfoot>
            </table>

        </div>
      );
};

export default observer(DrivingCharacteristicsProtocol);
