import React, { useState } from "react";
import { Button, Card, Collapse } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExclamationTriangle,
  faChevronLeft,
  faChevronDown,
} from "@fortawesome/free-solid-svg-icons";
import { ICarTestConflict } from "../../app/models/carTest";
import "./ConflictsPanel.scss";

interface IProps {
  conflicts: ICarTestConflict[];
  title: string
}
const ConflictsPanel: React.FC<IProps> = ({ conflicts,title }) => {
  const [openCollisions, setOpenCollisions] = useState(true);

  return (
    <Card id="collisions-panel" border="warning">
      <Card.Header>
        <FontAwesomeIcon
          icon={faExclamationTriangle}
          className="text-warning icon--header"
          onClick={() => setOpenCollisions(!openCollisions)}
          aria-controls="car-card-body-collisions"
          aria-expanded={openCollisions}
        />{title}
       
        {!openCollisions && (
          <Button
            variant="link"
            className="btn--toggle"
            onClick={() => setOpenCollisions(true)}
            aria-controls="car-card-body-collisions"
            aria-expanded={openCollisions}
          >
            <FontAwesomeIcon icon={faChevronLeft} />
          </Button>
        )}
        {openCollisions && (
          <Button
            variant="link"
            className="btn--toggle"
            onClick={() => setOpenCollisions(false)}
            aria-controls="car-card-body-collisions"
            aria-expanded={openCollisions}
          >
            <FontAwesomeIcon icon={faChevronDown} />
          </Button>
        )}
      </Card.Header>
      <Collapse in={openCollisions}>
        <div>
          <Card.Body id="car-card-body-collisions">
            {/* <Card.Title>Kolidující zkoušky</Card.Title> */}
            {/* <Card.Text>
              </Card.Text> */}
            <ul>
              {conflicts.map((confl) => (
                <li key={confl.carTestConflictId}>{confl.message}</li>
              ))}
            </ul>
          </Card.Body>
        </div>
      </Collapse>
    </Card>
  );
};

export default ConflictsPanel;
