import React, { useEffect, useState, useContext, Fragment } from "react";
import { Form, Button, Col, Row, Table, OverlayTrigger, Tooltip, Collapse, Alert } from "react-bootstrap";
import { Field, Form as FinalForm } from "react-final-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { RootStoreContext } from "../../app/stores/rootStore";
import { observer } from "mobx-react-lite";
import TextInput from "../../app/common/form/TextInput";
import SelectInput from "../../app/common/form/SelectInput";
import VinAutocompleteInput from "../../app/common/form/VinAutocompleteInput";
import KnrAutocompleteInput from "../../app/common/form/KnrAutocompleteInput";
import CarColorSelectInput from "../../app/common/form/CarColorSelectInput";
import MultiSelectInput from "../../app/common/form/MultiSelectInput";
import TextAreaInput from "../../app/common/form/TextAreaInput";
import DateTimeInput from "../../app/common/form/DateTimeInput";
import SwitchInput from "../../app/common/form/SwitchInput";
import CheckboxInput from "../../app/common/form/CheckboxInput";
import AlertMessage from "../alert/AlertMessage";
import ConflictsPanel from "../conflictsPanel/ConflictsPanel";
import moment from "moment";
import { toast } from "react-toastify";
import "./CarAnalysisForm.scss";
import { ICarAnalysis, ICarAnalysisForm, CreateNewCarAnalysisTerm, CopyCarAnalysis, CopyCarAnalysisTerm, ICarAnalysisTerm } from "../../app/models/carAnalysis";
import { ICar } from "../../app/models/car";
import { FormApi } from "final-form";
import { useHistory } from "react-router-dom";

interface IProps {
  aCarId?: number;
  aCarAnalysisId?: number;
  aCarAnalysisTermId?: number;
  aCarAnalysis?: ICarAnalysis;
  aExpertGroupId: number;
  aWorkerOptions: { key: number; value: string }[];
  aCallback?: () => void;
  isFormEditable: boolean;
}

const CarAnalysisForm: React.FC<IProps> = ({
    aCarId,
    aCarAnalysisId,
    aCarAnalysisTermId,
    aCarAnalysis,
    aExpertGroupId,
    aWorkerOptions,
    aCallback,
    isFormEditable
}) => {
  const rootStore = useContext(RootStoreContext);
  const { closeModal, openDialog } = rootStore.modalStore;
  const { enums, loadEnums } = rootStore.enumStore;
  const history = useHistory();

  const { createOrUpdateCarAnalysis } = rootStore.carAnalysisStore;
  const {
    carRegistry,
    loadCar,
    loadCarsByVinOrKnr,
    carsByVinOrKnr: carsByVin,
  } = rootStore.carStore;

  const [submitErrors, setSubmitErrors] = useState([]);
  const [errors, setErrors] = useState<string[]>([]);
  const [showSubmitError, setShowSubmitError] = useState(false);

  const [edit, setEdit] = useState(false);
  const [editVin, setVinEdit] = useState(false);
  const [vinLock, setVinLock] = useState(false);
  const [knrLock, setKnrLock] = useState(false);
  const [emissionFree, setEmissionFree] = useState(false);
  const [carParamsCollapsed, setCarParamsCollapsed] = useState(false);
  const [rowNumber, setRowNumber] = useState(0);

  const [carAnalysis, setCarAnalysis ] = useState<ICarAnalysis | undefined>()
  const [carAnalysisTerms, setCarAnalysisTerms] = useState<ICarAnalysisTerm[]>([]);
  const [confirmed, setConfirmed ] = useState(false);

  const isEmissionFree = React.useCallback(
    (engineId: number | undefined, modelId: number | undefined) => {
      const emissionFree =
        enums?.carEngines.find((c) => c.id === engineId)?.emissionFree ||
        enums?.carModels.find((c) => c.id === modelId)?.emissionFree ||
        false;
      setEmissionFree(emissionFree);
    },
    [enums]
  );

  useEffect(() => {

    loadEnums();

    setCarAnalysis(aCarAnalysis);
    setCarAnalysisTerms(aCarAnalysis?.terms?.map((p, index: number) => {
      return CopyCarAnalysisTerm(p);
    }) || []);

    let maxRowNumber = 0;
    aCarAnalysis?.terms?.forEach(term => {
      if(term.rownumber > maxRowNumber) maxRowNumber = term.rownumber;
    });
    setRowNumber(maxRowNumber);

    if (!aCarAnalysis?.carId) {
      setEdit(false);
      setVinEdit(true);
    }

    if (enums && enums.carEngines && enums.carModels) {
      isEmissionFree(carAnalysis?.car?.engineId, carAnalysis?.car?.modelId);
    }
    
  }, [ enums, carAnalysis, emissionFree ]);

  const handleValidateVinAndKnr = (form: any, vin: string | undefined, prodIdentificationNum: string | undefined) => {
    loadCarsByVinOrKnr(vin, prodIdentificationNum).then((cars) => {
      // car already exists in the database
      if (cars && cars.length === 1 && !cars[0].isDifferentIdentificationParam)
      {
        const carId = cars[0].carId;

        loadCar(carId).then(() => {
          const existingCar: ICar | undefined = carRegistry.get(carId);
          form.mutators.setExistingCarValues(existingCar);
        });
        setVinEdit(false);
        setEdit(false);
      }
      // car already exists in the database but under two different ids
      else if (cars && ((cars.length === 1 && cars[0].isDifferentIdentificationParam) || cars.length > 1))
      {
        alert("Vůz v aplikaci již existuje, ale každý z kódů identifikuje jiný vůz!");
      }
      // car does not exists in the database
      else
      {
        if (vin && vin.length === 17 && carAnalysis && carAnalysis?.car && !carAnalysis.car.vin)
        {
          setVinLock(true);
        }
        if (prodIdentificationNum && prodIdentificationNum.length === 13 && carAnalysis && carAnalysis?.car && !carAnalysis.car.prodIdentificationNum)
        {
          setKnrLock(true);
        }
        
        setVinEdit(false);
        setEdit(true);
      }
    });
  };

  const handleAddTerm = (form: any) => {
    let newRowNumber = rowNumber + 1;
    setRowNumber(newRowNumber);
    const term = CreateNewCarAnalysisTerm(newRowNumber);
    setCarAnalysisTerms([...carAnalysisTerms, term]);
    form.mutators.setToPlannedStateTerm(newRowNumber);
  }

  const deleteTerm = (form: any, rowNumber: number) => {
    var term = carAnalysisTerms.find((t) => t.rownumber == rowNumber);
    if(term)
    {
      var _carAnalysisTerms = carAnalysisTerms.filter((t) => t.rownumber !== rowNumber);
      if(term?.carAnalysisTermId)
      {
        term.termStateId = 5;
        term.isActive = false;
        _carAnalysisTerms.push(term);
        form.mutators.setToCancelStateTerm(rowNumber);
      }
      else
      {
        form.mutators.setToUndefinedStateTerm(rowNumber);
      }
      setCarAnalysisTerms(_carAnalysisTerms);
    }
  }

  const validate = (values: ICarAnalysis, terms: ICarAnalysisTerm[]) : any => {
    //const errors = {} as any;
    let errors: string[] = []
    if (!values.description) {
      //errors.description = "Popis musí být vyplněn";
      errors.push("Popis musí být vyplněn");
    }

    if (!values.analysisTypeId)
    {
      //errors.analysisTypeId = "Typ analýzy musí být vybrán";
      errors.push("Typ analýzy musí být vybrán");
    }

    if (!values.assignedWorkersId || (values.assignedWorkersId && !values.assignedWorkersId.length))
    {
      //errors.assignedWorkersId = "Musí být vybrán alespoň jeden pracovník";
      errors.push("Musí být vybrán alespoň jeden pracovník");
    }

    if(terms && terms.length === 0)
    {
      //errors.terms = "Musí být zadán alespoň jeden termín analýzy";
      errors.push("Musí být zadán alespoň jeden termín analýzy");
    }

    if(terms && terms.length > 0){
      //if (!errors.terms) errors.terms = [];
      terms.forEach((term: ICarAnalysisTerm) => {

        if(Object.keys(term).includes("startAt"))
        {
          if (!term.startAt){
            /* errors.terms[term.rownumber] = {
               ...errors.terms[term.rownumber],
               startAt: "Začátek termínu je povinný",
             };*/
             errors.push("Začátek termínu je povinný");
           }
        }
        else{
          errors.push("Začátek termínu je povinný");
        }

        if(Object.keys(term).includes("endAt"))
        {
          if (!term.endAt && !term.wholeDay){
          /*errors.terms[term.rownumber] = {
            ...errors.terms[term.rownumber],
            endAt: "Konec termínu je povinný",
          };*/
            errors.push("Konec termínu je povinný");
          }
        }
        else{
          errors.push("Konec termínu je povinný");
        }

        if(Object.keys(term).includes("note"))
        {
          if (!term.note){
          /*errors.terms[term.rownumber] = {
            ...errors.terms[term.rownumber],
            note: "Poznámka k termínu je povinná",
          };
          */
            errors.push("Poznámka k termínu je povinná");
          }

          if (term.note && term.note.length > 400) {
            errors.push("Poznámka k termínu nesmí mít víc než 400 znaků");
          }
        }
        else{
          errors.push("Poznámka k termínu je povinná");
        }
      }) 
    }
    //console.log(errors);
    setErrors(errors);
    setSubmitErrors([]);
    return errors;
  }

  const handleRedirectToProtocol = (url: string) => {
    closeModal();
    history.push(url);
  }

  const handleFinalFormSubmit = (values: ICarAnalysis, form: FormApi<ICarAnalysis>) => {
    
    // filtrace termínů
    const terms: ICarAnalysisTerm[] = [];
    if(values && values.terms && values.terms.length > 0)
    {
      values.terms.forEach(term => {
        let termKeys = Object.keys(term);
        if(termKeys.length > 0 && termKeys.includes('termStateId') && term.termStateId !== undefined){
          terms.push(term);
        }
      });
    }

    // validace pri odeslani
    let err = validate(values, terms);
    if(err && err.length > 0) return;

    const analysisForm: ICarAnalysisForm = {carAnalysis: values, isConfirmed: confirmed};
    analysisForm.carAnalysis.terms = terms;
    
    console.log(analysisForm);
    
    createOrUpdateCarAnalysis(analysisForm)
      .then((response) =>
      {
        // nacti kolize z response
        const collisions = response.carTestConflicts;
        if (!!collisions?.length && !confirmed) {
          toast.warn(`Analýza vozu zatím nebyla uložena`);

          openDialog(
            <div>
              <ul>
                {collisions.map((col) => (
                  <li key={col.carTestConflictId}>{col.message}</li>
                ))}
              </ul>
            </div>,
            {
              title: "Uložením analýzy vzniknou kolize",
              confirmButtonLabel: "Pokračovat a uložit",
              cancelButtonLabel: "Zpět",
              onConfirm: () => {
                setConfirmed(true);
                form.submit();
              },
              onCancel: () => {
                setConfirmed(false);
              },
            }
          );
        }

        if (!collisions?.length || confirmed) {
          setConfirmed(false);
          if(aCallback) aCallback();
          toast.success("Analýza vozu byla úspěšně uložena");
          closeModal();
        }
        
      })
      .catch((error) => {
        setSubmitErrors(Object.values(error.response?.data?.errors));
        setShowSubmitError(true);
      });

  };

  return (
    <FinalForm
      onSubmit={handleFinalFormSubmit}
      initialValues={carAnalysis}
      validate={(values) => {
        const errors = {} as any;

        if (!values.description) {
          errors.description = "Popis musí být vyplněn";
        }

        if (values.description && values.description.length > 400) {
          errors.description = "Popis nesmí mít víc než 400 znaků";
        }
    
        if (!values.analysisTypeId)
        {
          errors.analysisTypeId = "Typ analýzy musí být vybrán";
        }
    
        if (!values.assignedWorkersId || (values.assignedWorkersId && !values.assignedWorkersId.length))
        {
          errors.assignedWorkersId = "Musí být vybrán alespoň jeden pracovník";
        }

        if (!errors.cae) errors.car = {};
        if (values.car?.vin && values.car?.vin.length !== 17) {
          errors.car.vin = "VIN kód vozu musí mít přesně 17 znaků";
        }
        if (
          values.car?.prodIdentificationNum &&
          values.car?.prodIdentificationNum.length !== 13
        ) {
          errors.car.prodIdentificationNum =
            "Identifikační číslo výroby musí obsahovat 13 znaků";
        }

        if (values.car?.logisticNum && values.car?.logisticNum.length > 5) {
          errors.car.logisticNum =
            "Logistické číslo nesmí mít víc než 5 znaků";
        }
        if (values.car?.integrationNum && values.car?.integrationNum.length > 15) {
          errors.car.integrationNum = "VDS číslo nesmí mít víc než 15 znaků";
        }
        if (
          values.car?.emissionStandard &&
          values.car?.emissionStandard.length > 50
        ) {
          errors.car.emissionStandard = "Emise nesmí mít víc než 50 znaků";
        }
        if (values.car?.typeOfControl && values.car?.typeOfControl.length > 20) {
          errors.car.typeOfControl = "Typ řízení nesmí mít víc než 20 znaků";
        }
        if (
          values.car?.transmissionCode &&
          values.car?.transmissionCode.length > 50
        ) {
          errors.car.transmissionCode =
            "Převodovka nesmí mít víc než 50 znaků";
        }
        if (values.car?.colorCode && values.car?.colorCode.length > 5) {
          errors.car.colorCode = "Barva nesmí mít víc než 5 znaků";
        }

        return errors;
      }}
      mutators={{
        setCarNumber: (args, state, utils) => {
          utils.changeValue(state, "car.carNumber", () => {
            const vin = args[0];
            if (vin && vin.length > 9) return vin.slice(9).toUpperCase();
            else return undefined;
          });
        },
        carModelReset: (args, state, utils) => {
          utils.changeValue(state, "car.bodyTypeId", () => undefined);
          utils.changeValue(state, "car.equipmentId", () => undefined);
          utils.changeValue(state, "car.engineId", () => undefined);
          utils.changeValue(state, "car.transmissionId", () => undefined);
        },
        setModelVdsCode: (args, state, utils) => {
          let newVdsCode = state.lastFormState?.values.car?.modelVdsCode;
          const fieldName = args[0].currentTarget.name;
          const fieldValue = args[0].target.value;
          if (fieldName && fieldName === "car.modelId") {
            const carModelId = Number(fieldValue);
            const model = enums?.carModels.find((x) => x.id === carModelId);
            newVdsCode = model?.modelVdsCode;
            utils.changeValue(state, "car.modelVdsCode", () => newVdsCode);
          }

          if (fieldName && fieldName === "car.bodyTypeId") {
            const carBodyTypeId = Number(fieldValue);
            const carBodyType = enums?.carBodyTypes.find((c) => c.id === carBodyTypeId);
            newVdsCode = carBodyType?.modelVdsCode;
            if (newVdsCode && newVdsCode.length > 0) utils.changeValue(state, "car.modelVdsCode", () => newVdsCode);
          }
        },
        setCarModelKey: (args, state, utils) => {
          let modelKey = state.lastFormState?.values.car?.modelKey;
          let carModelId = state.lastFormState?.values.car?.modelId;
          let bodyTypeId = state.lastFormState?.values.car?.bodyTypeId;
          let equipmentId = state.lastFormState?.values.car?.equipmentId;
          let engineId = state.lastFormState?.values.car?.engineId;
          let transmissionId = state.lastFormState?.values.car?.transmissionId;
          let carModelCode: string = "  ";
          let bodyTypeCode: string = " ";
          let equipmentCode: string = " ";
          let engineCode: string = " ";
          let transmissionCode: string = " ";
          const fieldName = args[0].currentTarget.name;
          const fieldValue = args[0].target.value;

          if (fieldName && fieldName === "car.modelId")
            carModelId = Number(fieldValue);

          if (fieldName && fieldName === "car.bodyTypeId")
            bodyTypeId = Number(fieldValue);

          if (fieldName && fieldName === "car.equipmentId")
            equipmentId = Number(fieldValue);

          if (fieldName && fieldName === "car.engineId")
            engineId = Number(fieldValue);

          if (fieldName && fieldName === "car.transmissionId")
            transmissionId = Number(fieldValue);

          if (carModelId) {
            const model = enums?.carModels.find((x) => x.id === carModelId);
            if (model && model?.code.length > 0)
              carModelCode = model?.code;
          }
          if (bodyTypeId) {
            const carBodyType = enums?.carBodyTypes.find((c) => c.id === bodyTypeId);
            if (carBodyType && carBodyType?.code.length > 0)
              bodyTypeCode = carBodyType?.code;
          }
          if (equipmentId) {
            const carEquipment = enums?.carEquipments.find((c) => c.id === equipmentId);
            if (carEquipment && carEquipment?.code.length > 0)
              equipmentCode = carEquipment?.code;
          }
          if (engineId) {
            const carEngine = enums?.carEngines.find((c) => c.id === engineId);
            if (carEngine && carEngine?.code.length > 0)
              engineCode = carEngine?.code;
          }
          if (transmissionId) {
            const carTransmission = enums?.carTransmissions.find((c) => c.id === transmissionId);
            if (carTransmission && carTransmission?.code.length > 0)
              transmissionCode = carTransmission?.code;
          }

          modelKey = `${carModelCode}${bodyTypeCode}${equipmentCode}${engineCode}${transmissionCode}`;
          utils.changeValue(state, "car.modelKey", () => modelKey);
        },
        setExistingCarValues: (args, state, utils) => {
          const existingCar: ICar | undefined = args[0];
          utils.changeValue(state, "carAnalysis.carId", () => existingCar?.carId);
          utils.changeValue(state, "car.carId", () => existingCar?.carId);
          utils.changeValue(state, "car.vin", () => existingCar?.vin);
          utils.changeValue(state, "car.prodIdentificationNum", () => existingCar?.prodIdentificationNum);
          utils.changeValue(state, "car.carNumber", () => existingCar?.carNumber);
          utils.changeValue(state, "car.logisticNum", () => existingCar?.logisticNum);
          utils.changeValue(state, "car.integrationNum", () => existingCar?.integrationNum);
          utils.changeValue(state, "car.testDestinationId", () => existingCar?.testDestinationId);
          utils.changeValue(state, "car.modelId", () => existingCar?.modelId);
          utils.changeValue(state, "car.bodyTypeId", () => existingCar?.bodyTypeId);
          utils.changeValue(state, "car.equipmentId", () => existingCar?.equipmentId);
          utils.changeValue(state, "car.engineId", () => existingCar?.engineId);
          utils.changeValue(state, "car.transmissionId", () => existingCar?.transmissionId);
          utils.changeValue(state, "car.modelKey", () => existingCar?.modelKey);
          utils.changeValue(state, "car.modelVdsCode", () => existingCar?.modelVdsCode);
          utils.changeValue(state, "car.transmissionCode", () => existingCar?.transmissionCode);
          utils.changeValue(state, "car.wheelDrive", () => existingCar?.wheelDrive);
          utils.changeValue(state, "car.colorCode", () => existingCar?.colorCode);
          utils.changeValue(state, "car.typeOfControl", () => existingCar?.typeOfControl);
          utils.changeValue(state, "car.emissionStandard", () => existingCar?.emissionStandard);
          utils.changeValue(state, "car.engineNumber", () => existingCar?.engineNumber);
        },
        setToCancelStateTerm: (args, state, utils) => {
          const rowNumber = args[0];
          utils.changeValue(state, `terms[${rowNumber}].termStateId`, () => 5);
          utils.changeValue(state, `terms[${rowNumber}].isActive`, () => false);
        },
        setToPlannedStateTerm: (args, state, utils) => {
          const rowNumber = args[0];
          utils.changeValue(state, `terms[${rowNumber}].termStateId`, () => 2);
        },
        setToUndefinedStateTerm: (args, state, utils) => {
          const rowNumber = args[0];
          utils.changeValue(state, `terms[${rowNumber}].termStateId`, () => undefined);
        },
      }}
      render={({ handleSubmit, values, form }: any) => (
        <Form onSubmit={handleSubmit} id="car-analysis-form">
          <div className="car-analysis-form">
            <AlertMessage
              type="danger"
              heading="Nastala chyba při ukládání"
              show={showSubmitError}
              setShow={setShowSubmitError}
              messageList={submitErrors}
            />
            {errors && errors.length > 0 &&
              <Alert variant={'danger'} onClose={() => setErrors([])} dismissible>
                <Alert.Heading>{"Chyba analýzy vozu"}</Alert.Heading>
                <ul>
                  {errors.map((message: string, index: number) => (
                    <li key={index}>{message}</li>
                  ))}
                </ul>
              </Alert>
            }
            {!!carAnalysis?.carTestConflicts?.length && (
                <ConflictsPanel
                  conflicts={carAnalysis.carTestConflicts}
                  title={" Analýza vozu je v konfliktu"}
                />
              )}
            <div className="pageheader">
              <h2>Analýza</h2>
            </div>
            <Row>
              <Col lg={2} className="u-text-right">
                <Form.Label>Popis</Form.Label>
              </Col>
              <Col lg={10}>
                {values.car?.carId && (
                  <Field<number>
                    name={`carId`}
                    initialValue={values.car?.carId}
                    component="input"
                    hidden
                    disabled
                  />)}
                <Field
                  name="description"
                  rows={2}
                  component={TextAreaInput}
                  disabled={!isFormEditable}
                />
              </Col>
            </Row>
            <Row>
              {enums && (
                <Fragment>
                  <Col lg={2} className="u-text-right">
                    <Form.Label>Typ analýzy</Form.Label>
                  </Col>
                  <Col lg={4}>
                    <Field
                      name="analysisTypeId"
                      options={enums.analysisTypes}
                      component={SelectInput}
                      parse={(value) => (value ? parseInt(value) : undefined)}
                      disabled={!isFormEditable}
                    />
                  </Col>
                </Fragment>
              )}

              <Col lg={2} className="u-text-right">
                <Form.Label>Pracovníci</Form.Label>
              </Col>
              <Col lg={4}>
                <Field
                  name="assignedWorkersId"
                  options={aWorkerOptions.map((w: any) => ({
                    value: w.key,
                    label: w.value,
                  }))}
                  format={(value) =>
                    value &&
                    value.map((v: any) => ({
                      value: v,
                      label: aWorkerOptions.find((w) => w.key === v)?.value,
                    }))
                  }
                  parse={(value) => value && value.map((v: any) => v.value)}
                  placeholder="Vyberte pracovníky"
                  component={MultiSelectInput}
                  disabled={!isFormEditable}
                />
              </Col>
            </Row>
            <div className="pageheader">
              <div className="pageheader__left">
                <h3>Termíny</h3>
              </div>
              <div className="pageheader__right">
                {isFormEditable &&
                 <Fragment>
                  <OverlayTrigger
                    overlay={
                      <Tooltip id={`tooltip-addbutton`}>Přidat termím analýzy</Tooltip>
                    }
                  >
                    <Button
                      style={{ marginRight: "1rem" }}
                      variant="primary"
                      onClick={() => handleAddTerm(form)}
                      className="footer__btn-add"
                    >
                      <FontAwesomeIcon icon={faPlus} />
                    </Button>
                  </OverlayTrigger>
                </Fragment>}
              </div>
            </div>
            <Row>
              <Col lg={12} md={12} sm={12}>
                <Table size="sm">
                  <thead>
                    <tr>
                      <th style={{ width: "10rem" }}>Stav termínu</th>
                      <th style={{ width: "11rem" }}>Začátek termínu</th>
                      <th style={{ width: "11rem" }}>Konec termínu</th>
                      <th style={{ width: "10rem" }}>Celý den</th>
                      <th style={{ width: "16rem" }}>Poznámka k termínu</th>
                      <th style={{ width: "4rem" }}>Akce</th>
                    </tr>
                  </thead>
                  <tbody>
                    {carAnalysisTerms && carAnalysisTerms.length > 0 && carAnalysisTerms.map((term, index) => (
                      <tr key={term.rownumber}>
                        <td>
                            {term.carAnalysisTermId &&
                          <Field<number>
                            name={`terms[${term.rownumber}].carAnalysisTermId`}
                            component="input"
                            hidden
                            disabled
                          />
                            }
                          <Field<number>
                            name={`terms[${term.rownumber}].termStateId`}
                            options={enums?.testStates}
                            parse={(value) => value && parseInt(value)}
                            component={SelectInput}
                            disabled
                          />
                        </td>
                        <td>
                          <Field
                            name={`terms[${term.rownumber}].startAt`}
                            component={DateTimeInput}
                            format={(val) => (val ? moment(val).toDate() : undefined)}
                            parse={(val) => (val ? moment(val).toDate() : undefined)}
                            disabled={!isFormEditable}
                          />
                        </td>
                        <td>
                          <Field
                            name={`terms[${term.rownumber}].endAt`}
                            component={DateTimeInput}
                            format={(val) => (val ? moment(val).toDate() : undefined)}
                            parse={(val) => (val ? moment(val).toDate() : undefined)}
                            disabled={!isFormEditable}
                          />
                        </td>
                        <td>
                          <Field
                            name={`terms[${term.rownumber}].wholeDay`}
                            options={[
                              { key: 1, value: "ano" },
                              { key: 0, value: " ne " },
                            ]}
                            format={(v) => (v ? 1 : 0)}
                            parse={(v) => (v ? true : false)}
                            component={SwitchInput}
                            disabled={!isFormEditable}
                          />
                        </td>
                        <td>
                          <Field<string>
                            name={`terms[${term.rownumber}].note`}
                            component={TextAreaInput}
                            rows={1}
                            disabled={!isFormEditable}
                            size="sm"
                          />
                          <Field
                            name={`terms[${term.rownumber}].isActive`}
                            options={[
                              { key: 1, value: "ano" },
                              { key: 0, value: " ne " },
                            ]}
                            format={(v) => (v ? 1 : 0)}
                            parse={(v) => (v ? true : false)}
                            type={"checkbox"}
                            component={CheckboxInput}
                            disabled
                            hidden
                          />
                        </td>
                        <td>
                          {isFormEditable &&
                          <Button
                            variant="link"
                            size="sm"
                            onClick={() => deleteTerm(form, term.rownumber)}
                            title="Smazat termín analýzy "
                          >
                            <FontAwesomeIcon icon={faTrash} />
                          </Button>
                          }
                        </td>
                    </tr>
                    ))}
                  </tbody>
                </Table>
              </Col>
            </Row>
            {carAnalysis?.protocolList && carAnalysis?.protocolList.length > 0 && (
              <Fragment>
              <div className="pageheader">
                <div className="pageheader__left">
                  <h3>Protokoly</h3>
                </div>
                {isFormEditable &&
                <div className="pageheader__right">
                  {carAnalysis?.protocolList.filter(x => x.carProtocolId == undefined).map((protocol) => 
                    <Button
                      variant="primary"
                      size="sm"
                      key={protocol.protocolTypeId}
                      onClick={() => handleRedirectToProtocol(protocol.url)}
                    >
                      <FontAwesomeIcon icon={faPlus} /> Protokol - {protocol.protocolTypeName}
                    </Button>
                  )}
                </div>    
                }
              </div>
              <div>
                <Table>
                <thead>
                    <tr>
                      <th>Typ protokolu</th>
                      <th>Číslo protokolu</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                  {carAnalysis?.protocolList.filter(x => x.carProtocolId != undefined).map((protocol) => 
                    <tr key={protocol.carProtocolId}>
                      <td>{protocol.protocolTypeName}</td>
                      <td> {protocol.protocolNumber}</td>
                      <td>
                        <Button
                        variant="primary"
                        size="sm"
                        key={protocol.protocolTypeId}
                        onClick={() => handleRedirectToProtocol(protocol.url)}
                      >
                        Zobrazit
                      </Button>
                      </td>
                    </tr>
                  )}
                  </tbody>
                </Table>
              </div>
              </Fragment>
            )}
            <div className="pageheader">
              <h2>Informace o voze</h2>
            </div>
            <Row>
              <Col lg={4} md={6} sm={12}>
                {values.car?.carId && (
                  <Field<number>
                    name={`car.carId`}
                    initialValue={values.car?.carId}
                    component="input"
                    hidden
                    disabled
                  />
                )}
                <Field<string>
                  name="car.vin"
                  title="VIN kód"
                  disabled={(!editVin && (values.car?.vin || !edit)) || vinLock || !isFormEditable}
                  inputOnChange={form.mutators.setCarNumber}
                  items={[]}
                  component={VinAutocompleteInput}
                  warning={
                    editVin &&
                          !!carsByVin.filter((c) => c.vin === values.car?.vin).length &&
                    "Vůz již existuje"
                  }
                />
                <Field
                  name="car.prodIdentificationNum"
                  title="KNR kód"
                  type="number"
                  items={[]}
                  component={KnrAutocompleteInput}
                  warning={
                    editVin &&
                    !!carsByVin.filter(
                            (c) => c.prodIdentificationNum === values.car?.prodIdentificationNum
                    ).length &&
                    "Vůz již existuje"
                  }
                  disabled={
                          (!editVin && (values.car?.prodIdentificationNum || !edit)) || knrLock || !isFormEditable
                  }
                />
                {editVin &&
                  ((values.car?.vin && values.car?.vin.length === 17) ||
                    (values.car?.prodIdentificationNum &&
                      values.car?.prodIdentificationNum.length === 13)) && (
                    <Col xs={{ offset: 4 }}>
                      <Button
                        variant="outline-primary"
                        style={{ marginBottom: "1rem" }}
                        onClick={() =>
                          handleValidateVinAndKnr(
                            form,
                            values.car?.vin,
                            values.car?.prodIdentificationNum
                          )
                        }
                      >
                        Pokračuj
                      </Button>
                    </Col>
                  )}
              </Col>
              <Col lg={4} md={6} sm={12}>
                <Field<string>
                  name="car.carNumber"
                  title="Číslo vozu"
                  value={values.car?.vin && values.car?.vin.slice(9)}
                  component={TextInput}
                  disabled={true}
                />
                <Field
                  name="car.logisticNum"
                  title="Logistické číslo"
                  component={TextInput}
                  disabled={!edit}
                  warning={!values.car?.logisticNum}
                />
              </Col>
              <Col lg={4} md={6} sm={12}>
                <Field<string>
                  name="car.integrationNum"
                  title="VDS číslo"
                  component={TextInput}
                  disabled={!edit}
                  warning={!values.car?.integrationNum}
                />
                <Field
                  name="car.testDestinationId"
                  title="Typ vozu"
                  options={enums?.testDestinations}
                  component={SelectInput}
                  isEmptyOption={true}
                  parse={(value) => (value ? parseInt(value) : undefined)}
                  warning={!values.car?.testDestinationId}
                  disabled={!edit}
                />
              </Col>
            </Row>
            <div id="car-parametres-area">
              <button
                onClick={() => setCarParamsCollapsed(!carParamsCollapsed)}
                type="button"
                aria-controls="car-parametres"
                aria-expanded={carParamsCollapsed}
                className={`btn-link btn-link--primary animate-${
                  carParamsCollapsed ? "up" : "down"
                }`}
              >
                <h3>
                  Parametry vozu{" "}
                  {!carParamsCollapsed && <FontAwesomeIcon icon={faChevronDown} />}
                  {carParamsCollapsed && <FontAwesomeIcon icon={faChevronUp} />}
                </h3>
              </button>

              <Collapse in={carParamsCollapsed}>
                <div id="car-parametres">
                  <Row>
                    <Col lg={4}>
                      <Field
                        name="car.modelId"
                        title="Model"
                        options={enums?.carModels
                          .slice()
                          .filter((a) => a.isActive || a.id === values.car?.modelId)
                          .sort((a, b) => (a.name < b.name ? -1 : 1))
                          .map((model) => ({
                            key: model.id,
                            value: model.name,
                            isDisabled: !model.isActive,
                          }))}
                        inputOnChange={(e: any) => {
                          form.mutators.carModelReset(e);
                          form.mutators.setModelVdsCode(e);
                          form.mutators.setCarModelKey(e);
                        }}
                        component={SelectInput}
                        isEmptyOption={true}
                        warning={!values.car?.modelId}
                        parse={(value) => (value ? parseInt(value) : undefined)}
                        disabled={!edit}
                      />
                      <Field
                        name="car.bodyTypeId"
                        title="Karoserie"
                        options={enums?.carBodyTypes
                          .slice()
                          .filter(
                            (bodyType) =>
                              bodyType.carModelId === values.car?.modelId &&
                              (bodyType.isActive || bodyType.id === values.car?.bodyTypeId)
                          )
                          .sort((a, b) => (a.name < b.name ? -1 : 1))
                          .map((model) => ({
                            key: model.id,
                            value: model.name,
                            isDisabled: !model.isActive,
                          }))}
                        inputOnChange={(e: any) => {
                          form.mutators.setModelVdsCode(e);
                          form.mutators.setCarModelKey(e);
                        }}
                        component={SelectInput}
                        isEmptyOption={true}
                        warning={!values.car?.bodyTypeId}
                        parse={(value) => (value ? parseInt(value) : undefined)}
                        disabled={!edit}
                      />
                      <Field
                        name="car.equipmentId"
                        title="Výbava"
                        options={enums?.carEquipments
                          .filter(
                            (eqp) =>
                              eqp.carModelId === values.car?.modelId &&
                              (eqp.isActive || eqp.id === values.car?.equipmentId)
                          )
                          .slice()
                          .sort((a, b) => (a.name < b.name ? -1 : 1))
                          .map((model) => ({
                            key: model.id,
                            value: model.name,
                            isDisabled: !model.isActive,
                          }))}
                        inputOnChange={(e: any) => {
                          form.mutators.setCarModelKey(e);
                        }}
                        component={SelectInput}
                        isEmptyOption={true}
                        warning={!values.car?.equipmentId}
                        parse={(value) => (value ? parseInt(value) : undefined)}
                        disabled={!edit}
                      />

                      <Field
                        name="car.engineId"
                        title="Motorizace"
                        options={enums?.carEngines
                          .filter(
                            (engine) =>
                              engine.carModelId === values.car?.modelId &&
                              (engine.isActive || engine.id === values.car?.engineId)
                          )
                          .slice()
                          .sort((a, b) => (a.name < b.name ? -1 : 1))
                          .map((model) => ({
                            key: model.id,
                            value: model.name,
                            isDisabled: !model.isActive,
                          }))}
                        inputOnChange={(e: any) => {
                          form.mutators.setCarModelKey(e);
                        }}
                        component={SelectInput}
                        isEmptyOption={true}
                        warning={!values.car?.engineId}
                        parse={(value) => (value ? parseInt(value) : undefined)}
                        disabled={!edit}
                      />
                      <Field
                        name="car.transmissionId"
                        title="Převodovka"
                        options={enums?.carTransmissions
                          .filter(
                            (transmission) =>
                              transmission.carModelId === values.car?.modelId &&
                              (transmission.isActive ||
                                transmission.id === values.car?.transmissionId)
                          )
                          .slice()
                          .sort((a, b) => (a.name < b.name ? -1 : 1))
                          .map((model) => ({
                            key: model.id,
                            value: model.name,
                            isDisabled: !model.isActive,
                          }))}
                        inputOnChange={(e: any) => {
                          form.mutators.setCarModelKey(e);
                        }}
                        component={SelectInput}
                        isEmptyOption={true}
                        parse={(value) => (value ? parseInt(value) : undefined)}
                        disabled={!edit}
                      />
                    </Col>
                    <Col lg={4}>
                      <Field<string>
                        name="car.modelKey"
                        title="Modelový klíč"
                        component={TextInput}
                        disabled={!edit}
                      />
                      <Field<string>
                        name="car.modelVdsCode"
                        title="Vds kód"
                        component={TextInput}
                        disabled={!edit}
                      />
                      <Field<string>
                        name="car.transmissionCode"
                        title="Převodovka kód"
                        warning={!values.car?.transmissionCode}
                        component={TextInput}
                        disabled={!edit}
                      />
                      <Field<string>
                        name="car.wheelDrive"
                        title="Pohon"
                        component={TextInput}
                        disabled={!edit}
                      />
                    </Col>
                    <Col lg={4}>
                      <Field
                        name="car.colorCode"
                        title="Barva"
                        warning={!values.car?.colorCode}
                        items={enums?.carColors
                          .filter((cc) => cc.isActive || cc.code === values.colorCode?.slice(0, 2))
                          .slice()
                          .sort((a, b) => (a.name < b.name ? -1 : 1))}
                        component={CarColorSelectInput}
                        disabled={!edit}
                      />
                      <Field<string>
                        name="car.typeOfControl"
                        title="Typ řízení"
                        warning={!values.car?.typeOfControl}
                        component={TextInput}
                        disabled={!edit}
                      />
                      {!emissionFree && (
                        <Field<string>
                          name="car.emissionStandard"
                          title="Emisní norma"
                          warning={!values.car?.emissionStandard}
                          component={TextInput}
                          disabled={!edit}
                        />
                      )}
                      <Field<string>
                        name="car.engineNumber"
                        title="Číslo motoru"
                        component={TextInput}
                        disabled={!edit}
                      />
                    </Col>
                  </Row>
                </div>
              </Collapse>
            </div>

            <div className="flex-row flex-center">
              {isFormEditable &&
                <Button type="submit" className="btn-modal">
                  Uložit
                </Button>
              }
              <Button
                type="button"
                onClick={closeModal}
                className="btn-modal"
                variant="outline-secondary"
              >
                Zavřít
              </Button>
            </div>
          </div>
        </Form>
      )}
    />
  );
};

export default observer(CarAnalysisForm);