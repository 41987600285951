import React from "react";
import { Field } from "react-final-form";
import CheckboxInput from "../../../../app/common/form/CheckboxInput";
import { IProtocolPartGroup } from "../../../../app/models/protocol";

interface IProps {
  protocolPartGroup: IProtocolPartGroup;
  areaIndex: number;
  groupPartIndex: number;
  isEdit: boolean;
  isDisabled: boolean;
}

const ProtocolGroup: React.FC<IProps> = ({
  protocolPartGroup,
  areaIndex,
  groupPartIndex,
  isEdit,
  isDisabled,
}) => {
  return (
    <tr
      className={`protocol-group no-print ${
        isDisabled ? "protocol-group--disabled" : ""
      }`}
      key={protocolPartGroup.protocolPartGroupId}
    >
      <td className="print-only"></td>
      <td colSpan={4}>
        <h3>{protocolPartGroup.name}</h3>
      </td>
      <td>
        <Field
          name={`areas[${areaIndex}].protocolPartGroups[${groupPartIndex}].notPerform`}
          title="Neprovádět"
          options={[
            { key: 1, value: "ano" },
            { key: 0, value: " ne " },
          ]}
          format={(v) => (v ? 1 : 0)}
          parse={(v) => (v ? true : false)}
          type={"checkbox"}
          // inputOnChange={(v: any) => {
          //   setDisabled(v.target.checked);
          // }}
          initialValue={protocolPartGroup.notPerform || false}
          component={CheckboxInput}
          disabled={!isEdit}
          labelWidth={8}
          valueWidth={4}
        />
      </td>
    </tr>
  );
};

export default ProtocolGroup;
