import "./Schedule.scss";
import React, { useContext, useLayoutEffect } from "react";
import "moment/locale/cs";
import { RootStoreContext } from "../../../app/stores/rootStore";
import { observer } from "mobx-react-lite";
import ScheduleTableHeader from "./ScheduleTableHeader";
import ScheduleTableRow from "./ScheduleTableRow";
import { Alert } from "react-bootstrap";

const Schedule = () => {
  const rootStore = useContext(RootStoreContext);
  const {
    schedullingDates,
    carsSortedAndFiltered: carsSorted,
    carTestsFromStore,
    carAnalysesFromStore,
    carTermsFromStore,
    plannedCarIds,
    tableHeaderWidth, setTableHeaderWidth,
      selectedCarIds
  } = rootStore.pageScheduleStore;


    // resize car calendar table header according to table body has or has not scrollbar
  useLayoutEffect(() => {
    window.addEventListener("resize", setTableHeaderWidth);
    setTableHeaderWidth();
    return () => window.removeEventListener("resize", setTableHeaderWidth);
  }, [document?.getElementById("schedule-body")?.clientWidth,setTableHeaderWidth]);


  return (
    <div className="schedule-container">
      <div
        className="schedule-header"
        id="schedule-header"
        style={{ width: tableHeaderWidth + "px" }}
      >
        <ScheduleTableHeader dates={schedullingDates} />
      </div>
      <div className="schedule-body" id="schedule-body">
        {carsSorted.map((car) => (
          <ScheduleTableRow
            key={car.carId}
            days={schedullingDates}
            car={car}
            carTests={carTestsFromStore}
            carAnalyses={carAnalysesFromStore}
            carTerms={carTermsFromStore}
            isnew={plannedCarIds.includes(car.carId)}
            isSelected={selectedCarIds.includes(car.carId)}
          />
        ))}
        {(!carsSorted || !carsSorted.length) && (
          <Alert variant="info">Nebyly nalezeny žádné vozy</Alert>
        )}
      </div>
    </div>
  );
};

export default observer(Schedule);
