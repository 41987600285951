import React, { Fragment } from "react";
import { Button } from "react-bootstrap";
import { Field, Form as FinalForm } from "react-final-form";
import CheckboxInput from "../../../app/common/form/CheckboxInput";
import SelectInput from "../../../app/common/form/SelectInput";

interface IProps {
  handleEditItem: (form: any) => void;
  copsId: number;
  copsName: string;
  copsCode: string;
  copsIsActive: boolean | undefined;
  editedItemId: string | undefined;
  setEditedItemId: (id: string | undefined) => void;
  copsTableName: string;
  selectName: string;
  selectOptions: { key: number; value: string }[];
  selectedOptionId: number | undefined;
  selectedOptionName: string | undefined;
}

const EditableCopsItemRow: React.FC<IProps> = ({
  handleEditItem,
  copsId,
  copsName,
  copsCode,
  copsIsActive,
  editedItemId,
  setEditedItemId,
  copsTableName,
  selectOptions,
  selectedOptionId,
  selectedOptionName,
  selectName,
}) => {
  const itemIdValue = `${copsTableName}-${copsId}`;
  const copsItemNameId = `${copsTableName}Id`;
  const appItemNameId = `${selectName}Id`;
  return (
    <tr
      key={copsId}
      className={
        editedItemId === itemIdValue
          ? "active show-btn-on-hover"
          : "show-btn-on-hover"
      }
    >
      {editedItemId === itemIdValue && (
        <FinalForm
          onSubmit={handleEditItem}
          render={({ handleSubmit }) => (
            <Fragment>
              <td>{copsCode}</td>
              <td>{copsName}</td>
              <td>
                <Field<number>
                  name={copsItemNameId}
                  initialValue={copsId}
                  component="input"
                  hidden
                  disabled
                />
                <Field
                  defaultValue={selectedOptionId}
                  name={appItemNameId}
                  size="sm"
                  options={selectOptions}
                  isEmptyOption={true}
                  parse={(value) => (value ? parseInt(value) : undefined)}
                  component={SelectInput}
                />
              </td>
              {typeof copsIsActive !== "undefined" && (
                <td>
                  <Field
                    defaultValue={copsIsActive}
                    name="isActive"
                    type="checkbox"
                    size="sm"
                    component={CheckboxInput}
                  />
                </td>
              )}

              <td className="td-fixed-width">
                <Button
                  type="submit"
                  size="sm"
                  onClick={handleSubmit}
                  style={{ marginRight: "0.1rem" }}
                >
                  {"Ulož"}
                </Button>
                <Button
                  type="button"
                  size="sm"
                  onClick={() => setEditedItemId(undefined)}
                  variant="outline-secondary"
                >
                  {"Zpět"}
                </Button>
              </td>
            </Fragment>
          )}
        />
      )}
      {editedItemId !== itemIdValue && (
        <Fragment>
          <td>{copsCode}</td>
          <td>{copsName}</td>
          <td>{selectedOptionName ?? "-\u00A0nepřiřazeno\u00A0-"}</td>
          {typeof copsIsActive !== "undefined" && (
            <td>{copsIsActive ? "Ano" : "Ne"}</td>
          )}
          <td className="td-fixed-width">
            <Button
              className="btn-show-on-hover"
              onClick={() => setEditedItemId(itemIdValue)}
              type="button"
              size="sm"
              variant="outline-primary"
            >
              {"Upravit"}
            </Button>
          </td>
        </Fragment>
      )}
    </tr>
  );
};

export default EditableCopsItemRow;
