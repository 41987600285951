import { RootStore } from "./rootStore";
import { observable, action, runInAction, computed, reaction } from "mobx";
import agent from "../api/agent";
import {
  ICar,
  ICarPagedList,
  IEmissionCarFilter,
  IEmissionCarForm,
} from "../models/car";
import { toast } from "react-toastify";
import {
  getCarsString,
  getMasculinePassiveVerbEnding,
} from "../common/utils/czechDeclension";

export default class PageEmissionCarsStore {
  rootStore: RootStore;
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }
  // flag if data is loading
  @observable isLoading = false;
  @observable isSaving = false;
  // show only decided cars or car not decided yet
  @observable decidedCarsView = false;
  // which page is active
  @observable currentPage = 1;
  // total count of cars to be decided if they are emission
  @observable carsToDecideCount = 0;

  pageSize: number = 20;

  @observable filter: IEmissionCarFilter = {
    vin: undefined,
    logisticNum: undefined,
    carModels: [],
    carEngines: [],
    emissionStandard: undefined
  };

  @observable emissionCarsPage: ICarPagedList = {
    cars: [],
    currentPage: this.currentPage,
    pageSize: this.pageSize,
    count: 0,
    totalPages: 0,
    hasNext: false,
    hasPrevious: false,
  };

  @action setFilter = (filter: IEmissionCarFilter) => {
    this.filter = filter;
  };

  @computed get emissionCars() {
    const cars: ICar[] = [];
    this.emissionCarsPage.cars.forEach((car) => {
      let carFromStore = this.rootStore.carStore.carRegistry.get(car.carId);
      if (carFromStore) cars.push(carFromStore);
    });
    return cars; //this.sortCarsById(cars);
  }

  sortCarsById(cars: ICar[]) {
    const sortedCars = cars.sort((a, b) => b.carId - a.carId);
    return sortedCars;
  }

  @action setDedicedCarsView = (decidedCarsView: boolean) => {
    this.decidedCarsView = decidedCarsView;
    this.currentPage = 1;
  };

  @action setCurrentPage = (page: number) => {
    this.currentPage = page;
  };

  @action loadEmissionCars = async () =>
    {
      this.isLoading = true;
      try {
        const carFilterAsAxiosParams: URLSearchParams = new URLSearchParams();
        if (this.filter.vin)
          carFilterAsAxiosParams.append("vin", this.filter.vin.toString());
        if (this.filter.logisticNum)
          carFilterAsAxiosParams.append(
            "logNum",
            this.filter.logisticNum.toString()
          );
        if (this.filter.carModels)
            this.filter.carModels.forEach((carModel) => {
            carFilterAsAxiosParams.append("carModelIds", carModel.id.toString());
          });
        if (this.filter.carEngines)
          this.filter.carEngines.forEach((carEngine) => {
          carFilterAsAxiosParams.append("carEngineIds", carEngine.id.toString());
          });
        if (this.filter.emissionStandard)
          carFilterAsAxiosParams.append(
            "emissionStandard",
            this.filter.emissionStandard.toString()
          );
        carFilterAsAxiosParams.append(
          "decided",
          this.decidedCarsView.toString()
        );
        carFilterAsAxiosParams.append(
          "pageNumber",
          this.currentPage.toString()
        );
        carFilterAsAxiosParams.append("pageSize", this.pageSize.toString());

        const list = await agent.Cars.listEmissionCars(carFilterAsAxiosParams);
        runInAction("load emision cars", () => {
          list.cars.forEach((car) => {
            // add car to shared car store
            this.rootStore.carStore.carRegistry.set(car.carId, car);
          });         
          this.emissionCarsPage = list;
          this.isLoading = false;
        });
      } catch (error) {
        runInAction("load cars error", () => {
          this.isLoading = false;
        });
        console.log(error);
      }
    };

  @action saveEmissionCars = async (updatedCars: IEmissionCarForm[]) => {
    this.isSaving = true;
    try {
      const response = await agent.Cars.updateEmissionCar(updatedCars);

      runInAction("save emision cars", () => {
        this.isSaving = false;
        toast.success(
          `${updatedCars.length} ${getCarsString(
            updatedCars.length
          )} byl${getMasculinePassiveVerbEnding(
            updatedCars.length
          )} úspěšně uložen${getMasculinePassiveVerbEnding(
            updatedCars.length
          )}.`
        );      
      });
    } catch (error) {
      runInAction("save cars error", () => {
        this.isSaving = false;
        toast.error(`Nastala chyba při ukládání vozů.`);
      });
      throw error;
    }
  };

  @action loadCarsToDecideCount = async () => {
    const carFilterAsAxiosParams: URLSearchParams = new URLSearchParams();
    carFilterAsAxiosParams.append("decided", false.toString());
    carFilterAsAxiosParams.append("pageNumber", (1).toString());
    carFilterAsAxiosParams.append("pageSize", (1).toString());

    const list = await agent.Cars.listEmissionCars(carFilterAsAxiosParams);
    runInAction("refresh emision cars", () => {
      this.carsToDecideCount = list.count;
    });
  };

  // REACTION - if current page, or dedicedCarsView  is changed reload cars list
  updateEmissionCars = reaction(
    () => [this.decidedCarsView, this.currentPage],
    () => {
      this.loadEmissionCars();
    }
  );
}
