import "./ScheduleTableHeader.scss";
import React from "react";
import moment from "moment";
import "moment/locale/cs";
import { IDate } from "../../../app/models/date";
import { isToday } from "../../../app/common/utils/functions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWarehouse, faWrench } from "@fortawesome/free-solid-svg-icons";
import { Badge, OverlayTrigger, Tooltip } from "react-bootstrap";
import { getCarsString } from "../../../app/common/utils/czechDeclension";

interface IProps {
  dates: IDate[];
}

const ScheduleTableHeader: React.FC<IProps> = ({ dates }) => {
  const wideColumns = 5;
  const narrowColumns = 2;
  const columnWidth = (0.55 * 100) / (wideColumns * 5 + narrowColumns * 3);
  const columnWideWidth = columnWidth * 5 + "%";
  const columnNarrowWidth = columnWidth * 3 + "%";
  const columnDayWidth = (0.45 * 100) / (dates.length || 1) + "%";

  const todayClassName = (date: Date) =>
    isToday(date) ? "column-date-header--today" : "";

  const renderDateHeaderColumns = () => {
    return dates.map((day) => {
      let momentDay = moment(day.date);
      const testedCarsCount = day.testedCarsCount || 0;
      const inTestroomCarsCount = day.carsInTestroomCount || 0;
      const inEmissionCount = day.carsInEmissionCount || 0;
      const analyzedCarsCount = day.analyzedCarsCount || 0;
      return (
        <div
          key={momentDay.toISOString()}
          className={`column column-header column-date  ${todayClassName(
            day.date
          )}`}
          style={{ width: columnDayWidth }}
        >
          <div className="flex flex-row flex-center column-date-row-cars ">
            <div
              className="column-date-cars-tested"
            >
              <OverlayTrigger
                delay={{ show: 350, hide: 10 }}
                overlay={
                  <Tooltip id={`tooltip-day-tested-${momentDay.toISOString()}`}>
                    {testedCarsCount} {getCarsString(testedCarsCount)} podstupujících zkoušky dne {momentDay.format("D.M.YYYY")}
                  </Tooltip>
                }
              >
                <Badge>
                  <FontAwesomeIcon
                    icon={faWrench}
                    style={{ fontSize: "0.7rem" }}
                  />{" "}
                  {testedCarsCount}
                </Badge>
              </OverlayTrigger>
            </div>
            <div
              className="column-date-cars-intestroom"
            > <OverlayTrigger
            delay={{ show: 350, hide: 10 }}
            overlay={
              <Tooltip id={`tooltip-testroomcars-${momentDay.toISOString()}`}>
                {inTestroomCarsCount} {getCarsString(inTestroomCarsCount)} na zkušebně dne {momentDay.format("D.M.YYYY")}
              </Tooltip>
            }
          >
              <Badge>
                <FontAwesomeIcon
                  icon={faWarehouse}
                  style={{ fontSize: "0.7rem" }}
                />{" "}
                {inTestroomCarsCount}
              </Badge></OverlayTrigger>
            </div>
            <div
              className="column-date-cars-inemission"
            > <OverlayTrigger
            delay={{ show: 350, hide: 10 }}
            overlay={
              <Tooltip id={`tooltip-emissioncars-${momentDay.toISOString()}`}>
                {inEmissionCount} {getCarsString(inEmissionCount)} na emisní zkoušce dne {momentDay.format("D.M.YYYY")}
              </Tooltip>
            }
              >
                <Badge>
                    <span>E</span> {inEmissionCount}
                </Badge>
              </OverlayTrigger>
            </div>
             <div className="column-date-cars-analyzed">
             <OverlayTrigger
                delay={{ show: 350, hide: 10 }}
                overlay={
                  <Tooltip id={`tooltip-analyzedcars-${momentDay.toISOString()}`}>
                    {analyzedCarsCount} {getCarsString(analyzedCarsCount)} na analýze dne {momentDay.format("D.M.YYYY")}
                  </Tooltip>
                }
              >
              <Badge>
                {"A "}
                {analyzedCarsCount}
              </Badge></OverlayTrigger>
            </div>
          </div>
          <div className="flex flex-row flex-center column-date-row-date">
            <div className="column-date-day">{momentDay.format("ddd")}</div>
            <div className="column-date-date">{momentDay.format("D.M.YY")}</div>
          </div>
        </div>
      );
    });
  };

  return (
    <div className="table-row-header">
      <div className="column column-header" style={{ width: columnWideWidth }}>
        VDS číslo
      </div>
      <div
        className="column column-header column-vin"
        style={{ width: columnWideWidth }}
      >
        VIN kód
      </div>
      <div className="column column-header" style={{ width: columnWideWidth }}>
        Stav vozu
      </div>
      <div
        className="column column-header"
        style={{ width: columnNarrowWidth }}
        title="Typ vozu"
      >
        Typ v.
      </div>
      <div className="column column-header" style={{ width: columnWideWidth }}>
        Typ zkoušky
      </div>
      <div
        className="column column-header"
        style={{ width: columnNarrowWidth }}
      >
        Odběratel
      </div>
      {renderDateHeaderColumns()}
      <div className="column column-header" style={{ width: columnWideWidth }}>
        Poznámka
      </div>
    </div>
  );
};

export default ScheduleTableHeader;
