import React, { Fragment } from "react";
import { Form, Row, Col, InputGroup } from "react-bootstrap";
import { FieldRenderProps } from "react-final-form";

type Props = FieldRenderProps<number, any>;

const NumberInput: React.FC<Props> = ({
  input,
  meta,
  title,
  placeholder,
  rightAppend,
  disabled,
  warning,
  plaintext,
  readOnly,
  inputOnChange,
  required,  
  titleWidth,
  fieldWidth,
  ...rest
}: Props) => {
  const inputField = (
    <Fragment>
      <Form.Control
        type="number"
        {...input}
        value={input.value.toString()}
        isInvalid={meta.touched && !!meta.error}
        placeholder={placeholder}
        onChange={(e:any)=>{input.onChange(e); inputOnChange && inputOnChange(e);}}
        plaintext={plaintext}
        disabled={disabled}
        readOnly={readOnly}
        className={(warning && !(meta.touched && meta.error)) ? "is-warning" :""}
        {...rest}
      />
    </Fragment>
  );

  const rightAppendComponent = (
    rightAppend && (
      <InputGroup.Append><InputGroup.Text id={input.name}>{rightAppend}</InputGroup.Text></InputGroup.Append>
    )
    
  );

  const errorMessage = meta.error && meta.touched && (
    <Form.Control.Feedback type="invalid">{meta.error}</Form.Control.Feedback>
  );

  

  return (
    <Fragment>
      {title && (
        <Form.Group controlId={input.name}>
          <Row>
            <Col xs={titleWidth || 4} className="u-text-right">
              {title && <Form.Label className={required ? "required " : ""}>{title}</Form.Label>}
            </Col>
            <Col xs={fieldWidth || 8}>
              <InputGroup>
                {inputField}
                {rightAppendComponent}
                {errorMessage}
              </InputGroup>
            </Col>
          </Row>
        </Form.Group>
      )}
      {!title && (
        <InputGroup {...rest}>
          {inputField}
          {rightAppendComponent}
          {errorMessage}
        </InputGroup>
      )}
    </Fragment>
  );
};

export default NumberInput;
