import React, {useContext, useState} from "react";
import {Button, Col, Form, Row} from "react-bootstrap";
import {Field, Form as FinalForm} from "react-final-form";

import TextInput from "../../../app/common/form/TextInput";
import SelectInput from "../../../app/common/form/SelectInput";
import SwitchInput from "../../../app/common/form/SwitchInput";
import CheckboxInput from "../../../app/common/form/CheckboxInput";
import NumberInput from "../../../app/common/form/NumberInput";
import MultiSelectInput from "../../../app/common/form/MultiSelectInput";

import {RootStoreContext} from "../../../app/stores/rootStore";
import {AreaEnumDTO, GroupEnumDTO, IRuleSettingDTO, PartEnumDTO} from "../../../app/models/protocol";
import {IEnums} from "../../../app/models/enum";
import {observer} from "mobx-react-lite";

import AlertMessage from "../../alert/AlertMessage";
import {useProtocolRuleSettingsMutation} from "../../../app/api/client";
import {toast} from "react-toastify";

type RuleSettingsErrors = {
    [key in keyof IRuleSettingDTO]?: string;
}
interface IProps {
    protocolRule: IRuleSettingDTO,
    enums: IEnums | undefined;
    areasEnum: AreaEnumDTO[] | undefined;
    groupsEnum: GroupEnumDTO[] | undefined;
    partsEnum: PartEnumDTO[] | undefined;
}

const validate = (values: IRuleSettingDTO) => {
    const errors: RuleSettingsErrors = {};
    // 1 - oblast, 2 - skupina, 3 - pravidlo
    const missingAttribute = 'Chybějící hodnota';
    if (values.protocolRuleType === 1 && !values.protocolAreaId) {
        errors.protocolAreaId = missingAttribute;
    }
    if (values.protocolRuleType === 2 && !values.protocolPartGroupId) {
        errors.protocolPartGroupId = missingAttribute;
    }
    if (values.protocolRuleType === 2 && !values.protocolAreaId) {
        errors.protocolAreaId = missingAttribute;
    }
    if (values.protocolRuleType === 3 && !values.protocolPartId) {
        errors.protocolPartId = missingAttribute;
    }
    if (values.protocolRuleType === 3 && !values.protocolAreaId) {
        errors.protocolAreaId = missingAttribute;
    }
    return errors;
}

const ProtocolRuleForm: React.FC<IProps> = ({protocolRule, enums, areasEnum, groupsEnum, partsEnum}) => {
  const rootStore = useContext(RootStoreContext);
  const {mutateAsync: saveProtocolRuleSetting} = useProtocolRuleSettingsMutation();
  const { closeModal } = rootStore.modalStore;
  const [ editRule ] = useState(protocolRule);
  const [ errors, setErrors] = useState([]);
  const [ showSubmitError, setShowSubmitError] = useState(false);
  const [ selectedAreaId , setSelectedAreaId ] = useState<number>()
  const [ selectedGroupId , setSelectedGroupId ] = useState<number>()

  const handleFinalFormSubmit = (form: IRuleSettingDTO) => {
    setErrors([]);
    setShowSubmitError(false);
    saveProtocolRuleSetting(form)
    .then(() => {
        toast.success(`Konfigurace protokolu byla uložena`);
        closeModal();
    })
    .catch((error: any) => {
      console.log(error);
      toast.error("Při ukládání konfigurace protokolu nastala chyba!");
      setErrors(
        error.response?.data?.errors &&
          Object.values(error.response?.data?.errors)
      );
      setShowSubmitError(true);
    });
  };

  return (
    <div>
    <FinalForm
        onSubmit={handleFinalFormSubmit}
        initialValues={editRule}
        mutators={{
          setRuleFunction: (args, state, utils) => {
            let functionId = state.lastFormState?.values.protocolRuleFunctionId;
            var functionName: string | undefined = undefined;
            var functionCode: string | undefined = undefined;
            if(functionId)
            {
              let enumItem = enums?.protocolRuleFunctions.find(x => x.key == functionId);
              if(enumItem){
                functionCode = enumItem.code;
                functionName = enumItem.value;
              }
            }
            utils.changeValue(state, "protocolRuleFunctionCode", () => functionCode);
            utils.changeValue(state, "protocolRuleFunctionName", () => functionName);
          },
          selectAreaId: (args, state, utils) => {
            setSelectedAreaId(Number.parseInt(args[0].target.value))
          },
          selectGroupId: (args, state, utils) => {
            setSelectedGroupId(Number.parseInt(args[0].target.value))
        },
        }}
        validate={validate}
        render={({ handleSubmit, form }) => (
        <Form onSubmit={handleSubmit}>
            <AlertMessage
              type="danger"
              heading="Nastala chyba při ukládání"
              show={showSubmitError}
              setShow={setShowSubmitError}
              messageList={errors}
            />
            <Row>
              <Col sm={2} className="u-text-right">
                <Form.Label>Typ pravidla</Form.Label>
              </Col>
              <Col sm={6}>
                <Field<number>
                  name={`protocolRuleType`}
                  initialValue={editRule.protocolRuleType}
                  size="sm"
                  options={enums?.protocolRuleTypes}
                  component={SelectInput}
                  isEmptyOption={true}
                  parse={(value) => value && parseInt(value)}
                  disabled={false}
                />
              </Col>
              <Col>
                <Field
                  name={`enableDoorProtect`}
                  initialValue={editRule.enableDoorProtect || false}
                  title="Povolit Door protect"
                  options={[
                    { key: 1, value: "ano" },
                    { key: 0, value: " ne " },
                  ]}
                  format={(v) => (v ? 1 : 0)}
                  parse={(v) => (v ? true : false)}
                  type={"checkbox"}
                  component={CheckboxInput}
                  disabled={editRule.protocolRuleType !== 1}
                  labelWidth={8}
                  valueWidth={2}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={2} className="u-text-right">
                <Form.Label>Oblast</Form.Label>
              </Col>
              <Col sm={10}>
                <Field<number>
                  name={`protocolAreaId`}
                  initialValue={editRule.protocolAreaId}
                  size="sm"
                  inputOnChange={form.mutators.selectAreaId}
                  options={areasEnum?.filter(x => x.isActive)}
                  component={SelectInput}
                  isEmptyOption={true}
                  parse={(value) => value && parseInt(value)}
                  disabled={false}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={2} className="u-text-right"><Form.Label>Skupina</Form.Label></Col>
              <Col sm={10}>
                <Field<number>
                    name={`protocolPartGroupId`}
                    initialValue={editRule.protocolPartGroupId}
                    size="sm"
                    inputOnChange={form.mutators.selectGroupId}
                    options={groupsEnum?.slice()
                                        .filter(x => x.isActive && (selectedAreaId === undefined || (selectedAreaId && x.areaId === selectedAreaId)))
                                      }
                    component={SelectInput}
                    isEmptyOption={true}
                    parse={(value) => value && parseInt(value)}
                    disabled={false}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={2} className="u-text-right"><Form.Label>Díl</Form.Label></Col>
              <Col sm={10}>
                <Field<number>
                    name={`protocolPartId`}
                    initialValue={editRule.protocolPartId}
                    size="sm"
                    options={partsEnum?.slice()
                      .filter(x => x.isActive && ((selectedAreaId === undefined || (selectedAreaId && x.areaId === selectedAreaId)) &&
                                                  (selectedGroupId === undefined || (selectedGroupId && x.groupId === selectedGroupId))))
                                  }
                    component={SelectInput}
                    isEmptyOption={true}
                    parse={(value) => value && parseInt(value)}
                    disabled={false}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={2} className="u-text-right"><Form.Label>Poznámka</Form.Label></Col>
              <Col sm={10}>
                <Field<string>
                  name={`additionalInfo`}
                  initialValue={editRule.additionalInfo}
                  component={TextInput}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={2} className="u-text-right"><Form.Label>Limity</Form.Label></Col>
              <Col sm={4}>
                <Field<string>
                  name={`rule`}
                  initialValue={editRule.rule}
                  component={TextInput}
                  //disabled={editRule.protocolRuleType !== 3}
                  />
              </Col>
              <Col sm={2} className="u-text-right"><Form.Label>Počet hodnot</Form.Label></Col>
              <Col sm={4}>
                <Form.Group>
                  <Field
                    name={`numberOfValuesPerRule`}
                    initialValue={editRule.numberOfValuesPerRule}
                    component={NumberInput}
                 //   disabled={editRule.protocolRuleType !== 3}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
            <Col sm={2} className="u-text-right"><Form.Label>Jednotky</Form.Label></Col>
            <Col sm={4}>
              <Field<string>
                name={`unit`}
                initialValue={editRule.unit}
                component={TextInput}
             //   disabled={editRule.protocolRuleType !== 3}
              />
            </Col>
            <Col sm={2} className="u-text-right"><Form.Label>Funkce</Form.Label></Col>
            <Col sm={4}>
              <Field
                name={`protocolRuleFunctionId`}
                initialValue={editRule.protocolRuleFunctionId}
                inputOnChange={(e: any) => {
                  form.mutators.setRuleFunction(e);
                }}
                isEmptyOption={true}
                options={enums?.protocolRuleFunctions.filter(x => x.isActive)
                  .map((e) => ({
                    key: e.key,
                    value: `${e.code} (${e.value})`
                }))}
                component={SelectInput}
           //     disabled={editRule.protocolRuleType !== 3}
              />
              <Field
                name={`protocolRuleFunctionCode`}
                initialValue={editRule.protocolRuleFunctionCode}
                component="input"
                hidden
                disabled
              />
              <Field
                name={`protocolRuleFunctionName`}
                initialValue={editRule.protocolRuleFunctionName}
                component="input"
                hidden
                disabled
              />
            </Col>
            </Row>
            <Row>
            <Col sm={2} className="u-text-right"><Form.Label>Zkušebna</Form.Label></Col>
            <Col sm={10}>
              <Form.Group>
                <Field
                    name="forPlacesList"
                    options={enums?.places.map((w: any) => ({
                      value: w.key,
                      label: w.value,
                    }))}
                    format={(value) =>
                      value &&
                      value.map((v: any) => ({
                        value: v,
                        label: enums?.places.find((p) => p.key === v)?.value,
                      }))
                    }
                    parse={(value) => value && value.map((v: any) => v.value)}
                    component={MultiSelectInput}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={2} className="u-text-right"><Form.Label>Aktivní</Form.Label></Col>
              <Col sm={10}>
                    <Field
                        name={`isActive`}
                        options={[
                            { key: 1, value: "ano" },
                            { key: 0, value: " ne " },
                        ]}
                        format={(v) => (v ? 1 : 0)}
                        parse={(v) => (v ? true : false)}
                        component={SwitchInput}
                    />
                </Col>
            </Row>
            <hr />
            <div className="flex-row flex-center">
              <Button
                  type="submit"
                  className="btn-modal"
              >
                Uložit
              </Button>

                        <Button
                            type="button"
                            onClick={closeModal}
                            className="btn-modal"
                            variant="outline-secondary"
                        >
                            Zrušit
                        </Button>
                    </div>
                </Form>
            )}
    />
    </div>
  );
};

export default observer(ProtocolRuleForm);
