import React from "react";
import ReactDOM from "react-dom";
//import "./app/layout/styles.scss";
import App from "./app/layout/App";
import * as serviceWorker from "./serviceWorker";
//import "bootstrap/dist/css/bootstrap.min.css";
import './styles/custom.scss';
import {Router} from "react-router-dom";
import {ApplicationInsights} from '@microsoft/applicationinsights-web';
import {ReactPlugin} from '@microsoft/applicationinsights-react-js';
import {QueryClientProvider} from "@tanstack/react-query";
import {queryClient} from "./app/api/client";
import {history} from "./history";

var reactPlugin = new ReactPlugin();
var appInsights = new ApplicationInsights({
    config: {
        instrumentationKey: '52745c2a-3abc-44aa-bbc5-03a4c64d060f',
        extensions: [reactPlugin],
        extensionConfig: {
          [reactPlugin.identifier]: { history: history }
        }
    }
});
appInsights.loadAppInsights();

ReactDOM.render(
    <QueryClientProvider client={queryClient}>
        <Router history={history}>
            <App/>
        </Router>
    </QueryClientProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
