import React, { Fragment, useContext, useEffect, useState } from "react";
import { Form, Button, Col, Row } from "react-bootstrap";
import moment from "moment";
import { Field, Form as FinalForm } from "react-final-form";
import TextInput from "../../app/common/form/TextInput";
import SwitchInput from "../../app/common/form/SwitchInput";
import MultiSelectInput from "../../app/common/form/MultiSelectInput";
import ToggleSwitchInput from "../../app/common/form/ToggleSwitchInput";
import TextAreaInput from "../../app/common/form/TextAreaInput";
import DateInput from "../../app/common/form/DateInput";
import { RootStoreContext } from "../../app/stores/rootStore";
import {
  IBlockingEvent,
  IBlockingEventUpdateForm,
  IBlockingEventDeleteForm,
  UpdateTypes,
} from "../../app/models/blockingEvent";
import { observer } from "mobx-react-lite";
import CheckboxGroupInput from "../../app/common/form/CheckboxGroupInput";
import { getDatesBetween } from "../../app/common/utils/functions";
import { checkAuthRule } from "../../authorization/Can";
import "./BlockingEventForm.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import AlertMessage from "../alert/AlertMessage";

interface IProps {
  expertGroupId: number;
  aBlockingEvent?: IBlockingEvent;
  workerOptions: { key: number; value: string }[];
  callback?: () => void;
}

const BlockingEventForm: React.FC<IProps> = ({
  workerOptions,
  expertGroupId,
  callback,
  aBlockingEvent,
}) => {
  const rootStore = useContext(RootStoreContext);
  const { closeModal } = rootStore.modalStore;
  const { enums, loadEnums } = rootStore.enumStore;
  const { user } = rootStore.authStore;
  const { createBlockingEvents, updateBlockingEvent, deleteBlockingEvents } =
    rootStore.blockingEventStore;
  const [errors, setErrors] = useState([]);
  const [showSubmitError, setShowSubmitError] = useState(false);
  const [blockingEvent, setBlockingEvent] = useState<
    IBlockingEvent | undefined
  >();

  useEffect(() => {
    loadEnums();

    const event = aBlockingEvent;
    if (event)
      event.userIds =
        aBlockingEvent && aBlockingEvent.userId ? [aBlockingEvent?.userId] : [];
    setBlockingEvent(event);
  }, [loadEnums, blockingEvent, setBlockingEvent]);

  const handleFinalFormSubmit = (form: any) => {
    // create new blocking event(s)
    if (!blockingEvent) {
      const createForm = {
        ...form,
        expertGroupId,
        wholeGroup: form.blockingEventTypeId === 1 ? false : form.wholeGroup,
      };
      createBlockingEvents(createForm)
        .then((response) => {
          if (callback) callback();
          closeModal();
        })
        .catch((error) => {
          setErrors(Object.values(error.response?.data?.errors));
          setShowSubmitError(true);
        });
    }
    // delete existing blocking event(s)
    if (blockingEvent && form.isDeleted) {
      const deleteForm: IBlockingEventDeleteForm = {
        blockingEventId: blockingEvent.blockingEventId,
        eventGroupId: blockingEvent.eventGroupId,
        deleteType: form.updateType,
      };
      deleteBlockingEvents(deleteForm).then((response) => {
        if (callback) callback();
        closeModal();
      });
    }
    // update existing blocking event(s)
    if (blockingEvent && !form.isDeleted) {
      const updateForm: IBlockingEventUpdateForm = {
        ...form,
        expertGroupId,
        wholeGroup: form.blockingEventTypeId === 1 ? false : form.wholeGroup,
        updateType: form.updateType,
      };
      updateBlockingEvent(updateForm)
        .then((response) => {
          console.log(response);
          if (callback) callback();
          closeModal();
        })
        .catch((error) => {
          setErrors(Object.values(error.response?.data?.errors));
          setShowSubmitError(true);
        });
    }
  };

  const weekdayNames = moment.weekdays(true);
  const eventsTypes = [1, 2, 4];

  const canEdit =
    (!!blockingEvent &&
      checkAuthRule(user?.userRoleId, "blocking-event:edit")) ||
    (!blockingEvent &&
      checkAuthRule(user?.userRoleId, "blocking-event:create"));

  const getDescription = (blockingEventTypeId: any): any => {
    switch (blockingEventTypeId) {
      case 1:
        return "Důvod absence";
      case 2:
        return "Důvod blokace";
      default:
        return "Popis";
    }
  };

  const getEventName = (blockingEventTypeId: number) => {
    switch (blockingEventTypeId) {
      case 1:
        return "absenci";
      case 2:
        return "blokaci";
      case 4:
        return "událost";

      default:
        break;
    }
  };

  return (
    <div>
      <FinalForm
        onSubmit={handleFinalFormSubmit}
        initialValues={blockingEvent}
        mutators={{
          setOnUpdateTypeChange(params: any, state: any) {
            const editType: number = Number(params[0]);
            if (editType === UpdateTypes.updateSingle) {
              state.fields["startDate"].change(blockingEvent?.startDate);
              state.fields["endDate"].change(blockingEvent?.endDate);
              state.fields["isRepeating"].change(false);
            }
            if (editType === UpdateTypes.updateThisAndFuture) {
              state.fields["startDate"].change(blockingEvent?.startDate);
              state.fields["endDate"].change(blockingEvent?.eventGroupEndDate);
              state.fields["isRepeating"].change(true);
            }
            if (editType === UpdateTypes.updateAll) {
              state.fields["startDate"].change(
                blockingEvent?.eventGroupStartDate
              );
              state.fields["endDate"].change(blockingEvent?.eventGroupEndDate);
              state.fields["isRepeating"].change(true);
            }
          },
          setIsDeleted: (args, state, utils) => {
            utils.changeValue(state, "isDeleted", () => args[0]);
          },
        }}
        validate={(values: any) => {
          const errors = {} as any;
          if (values.blockingEventTypeId !== 4 && !values.description) {
            errors.description = "Důvod musí být vyplněn";
          }
          if (values.blockingEventTypeId === 4 && !values.eventName) {
            errors.eventName = "Název musí být vyplněn";
          }
          if (
            values.blockingEventTypeId === 4 &&
            values.eventName &&
            values.eventName.length > 50
          ) {
            errors.eventName = "Název nesmí mít víc než 50 znaků";
          }
          if (values.description && values.description.length > 250) {
            errors.description = "Důvod nesmí mít víc než 250 znaků";
          }
          if (!values.endDate) {
            errors.endDate = "Datum do musí být vyplněno";
          }
          if (!values.startDate) {
            errors.startDate = "Datum od musí být vyplněno";
          }

          if (new Date(values.startDate) > new Date(values.endDate)) {
            errors.endDate = "Datum do musí být větší než datum od";
            errors.startDate = "Datum od musí být menší než datum do";
          }
          if (!values.wholeDay) {
            if (!values.endTime) {
              errors.endTime = "Čas do musí být vyplněn";
            }
            if (!values.startTime) {
              errors.startTime = "Čas od musí být vyplněn";
            }

            if (
              values.startTime &&
              values.endTime &&
              values.startTime > values.endTime
            ) {
              errors.startTime = "Čas od musí být menší než čas do";
              errors.endTime = "Čas do musí být větší než čas od";
            }
          }

          if (
            (values.blockingEventTypeId === 1 ||
              (values.blockingEventTypeId === 2 && !values.wholeGroup)) &&
            (!values.userIds || (values.userIds && !values.userIds.length))
          ) {
            errors.userIds = "Musí být vybrán alespoň jeden pracovník";
          }
          if (values.isRepeating && values.startDate && values.endDate) {
            const weekdayNumbers = values.repeatingWeekdays;
            const repeatingDates = getDatesBetween(
              values.startDate,
              values.endDate,
              weekdayNumbers
            );
            if (
              weekdayNumbers &&
              weekdayNumbers.length &&
              !repeatingDates.length
            ) {
              errors.endDate = `Mezi zadanými daty není ani jedno opakování`;
              errors.startDate = `Mezi zadanými daty není ani jedno opakování`;
            }
          }

          if (
            values.isRepeating &&
            !values.isDeleted &&
            (!values.repeatingWeekdays || !values.repeatingWeekdays.length)
          ) {
            errors.isRepeating = "Musíte vybrat alespoň 1 den opakování";
          }
          return errors;
        }}
        render={({ handleSubmit, values, form }: any) => (
          <Form onSubmit={handleSubmit} id="absence-form">
            <AlertMessage
              type="danger"
              heading="Nastala chyba při ukládání"
              show={showSubmitError}
              setShow={setShowSubmitError}
              messageList={errors}
            />
            <div className="blocking-event-form">
              {!!blockingEvent && blockingEvent.eventGroupId && (
                <div className="blocking-event-repeating">
                  <FontAwesomeIcon icon={faInfoCircle} className="text-info" />{" "}
                  Opakující se{" "}
                  <span className="blocking-event-repeating__type">
                    {blockingEvent.blockingEventTypeName}
                  </span>{" "}
                  od{" "}
                  {moment(blockingEvent.eventGroupStartDate).format("D.M.YYYY")}{" "}
                  do{" "}
                  {moment(blockingEvent.eventGroupEndDate).format("D.M.YYYY")}
                </div>
              )}
              {!!blockingEvent && blockingEvent.eventGroupId && (
                <div className="blocking-event-repeating">
                  <Field
                    name="updateType"
                    title=""
                    initialValue={UpdateTypes.updateSingle}
                    options={[
                      {
                        key: UpdateTypes.updateSingle,
                        value: "Upravit tento výskyt",
                      },
                      {
                        key: UpdateTypes.updateThisAndFuture,
                        value: "Upravit tento a budoucí výskyty",
                      },
                      {
                        key: UpdateTypes.updateAll,
                        value: "Upravit veškeré výskyty",
                      },
                    ]}
                    component={SwitchInput}
                    inputOnChange={form.mutators.setOnUpdateTypeChange}
                    disabled={!canEdit}
                    size="sm"
                  />
                </div>
              )}
              {enums && (
                <Field
                  name="blockingEventTypeId"
                  title="Typ"
                  initialValue={blockingEvent?.blockingEventTypeId || 1}
                  options={enums.blockingEventTypes.filter((bt) =>
                    eventsTypes.includes(bt.key)
                  )}
                  component={SwitchInput}
                  disabled={!canEdit}
                />
              )}

              {values.blockingEventTypeId === 4 && (
                <Field
                  name="eventName"
                  title="Název"
                  initialValue={blockingEvent?.eventName || undefined}
                  component={TextInput}
                  disabled={!canEdit}
                />
              )}
              {values.blockingEventTypeId !== 1 && (
                <Field
                  name="wholeGroup"
                  title="Celá skupina"
                  type="checkbox"
                  component={ToggleSwitchInput}
                  disabled={!canEdit}
                />
              )}
              {(values.blockingEventTypeId === 1 ||
                (values.blockingEventTypeId === 2 && !values.wholeGroup) ||
                (values.blockingEventTypeId === 4 && !values.wholeGroup)) && (
                <Field
                  title="Pracovníci"
                  name="userIds"
                  maxSelectedOptions={values.workerCount}
                  options={workerOptions.map((w: any) => ({
                    value: w.key,
                    label: w.value,
                  }))}
                  format={(value) =>
                    value &&
                    value.map((v: any) => ({
                      value: v,
                      label: workerOptions.find((w) => w.key === v)?.value,
                    }))
                  }
                  parse={(value) => value && value.map((v: any) => v.value)}
                  placeholder="Vyberte pracovníky"
                  component={MultiSelectInput}
                  disabled={!canEdit}
                />
              )}
              <Field
                name="description"
                title={getDescription(values.blockingEventTypeId)}
                initialValue={blockingEvent?.description || undefined}
                component={TextAreaInput}
                disabled={!canEdit}
              />
              <Field<Date>
                name="startDate"
                title="Datum od"
                initialValue={blockingEvent?.startDate || undefined}
                format={(val) => (val ? moment(val).toDate() : undefined)}
                component={DateInput}
                disabled={!canEdit}
              />
              <Field<Date>
                name="endDate"
                title="Datum do"
                initialValue={blockingEvent?.endDate || undefined}
                format={(val) => (val ? moment(val).toDate() : undefined)}
                component={DateInput}
                disabled={!canEdit}
              />

              {(!blockingEvent ||
                (!!blockingEvent && !!blockingEvent.eventGroupId)) && (
                <Field
                  name="isRepeating"
                  initialValue={false}
                  title="Opakující se"
                  type="checkbox"
                  component={ToggleSwitchInput}
                  disabled={!canEdit || !!blockingEvent}
                />
              )}
              {values.isRepeating && (
                <Row style={{ paddingBottom: "1rem" }}>
                  <Col sm={4} className="text-right">
                    <label className="form-label">Dny opakování</label>
                  </Col>
                  <Col sm={8}>
                    {weekdayNames.slice(0, 5).map((weekday, index) => (
                      <Field
                        key={index}
                        name={`repeatingWeekdays`}
                        id={index + 1}
                        type="checkbox"
                        value={index + 1}
                        title={weekday}
                        component={CheckboxGroupInput}
                        disabled={!canEdit}
                      />
                    ))}
                    <span className={"text-danger"} style={{ fontSize: "80%" }}>
                      {form.getState().errors["isRepeating"]}
                    </span>
                  </Col>
                </Row>
              )}
              {values.isRepeating && (
                <Fragment>
                  <Row style={{ paddingBottom: "1rem" }}>
                    <Col sm={4} className="text-right">
                      <label className="form-label">Týden</label>
                    </Col>
                    <Col sm={8}>
                      <Field
                        name={`repeatingWeeks`}
                        id={0}
                        value={0}
                        type="checkbox"
                        title={"sudý týden"}
                        component={CheckboxGroupInput}
                        disabled={!canEdit}
                      />
                      <Field
                        name={`repeatingWeeks`}
                        id={1}
                        value={1}
                        type="checkbox"
                        title={"lichý týden"}
                        component={CheckboxGroupInput}
                        disabled={!canEdit}
                      />
                    </Col>
                  </Row>
                  <Row style={{ paddingBottom: "1rem" }}>
                    <Col sm={4}></Col>
                    <Col sm={8}></Col>
                  </Row>
                </Fragment>
              )}
              <Field
                name="wholeDay"
                initialValue={blockingEvent?.wholeDay || true}
                title="Celý den"
                type="checkbox"
                component={ToggleSwitchInput}
                disabled={!canEdit}
              />
              {!values.wholeDay && (
                <Fragment>
                  <Field
                    name="startTime"
                    title="Čas od"
                    type="time"
                    initialValue={blockingEvent?.startTime || undefined}
                    component={TextInput}
                    disabled={!canEdit}
                  />

                  <Field
                    name="endTime"
                    title="Čas do"
                    type="time"
                    initialValue={blockingEvent?.endTime || undefined}
                    component={TextInput}
                    disabled={!canEdit}
                  />
                </Fragment>
              )}
            </div>
            <hr />
            <div className="flex-row flex-center">
              {canEdit && (
                <Button type="submit" className="btn-modal">{`${
                  blockingEvent ? "Ulož" : "Vytvoř"
                } ${getEventName(values.blockingEventTypeId)}`}</Button>
              )}
              {blockingEvent && canEdit && (
                <Button
                  type="button"
                  className="btn-modal"
                  variant="outline-danger"
                  onClick={() => {
                    form.mutators.setIsDeleted(true);
                    document
                      ?.getElementById("absence-form")
                      ?.dispatchEvent(
                        new Event("submit", { cancelable: true })
                      );
                  }}
                >
                  Smazat
                </Button>
              )}
              <Button
                type="button"
                onClick={closeModal}
                className="btn-modal"
                variant="outline-secondary"
              >
                {`${blockingEvent ? "Zavřít" : "Zrušit"}`}
              </Button>
            </div>
          </Form>
        )}
      />
    </div>
  );
};
export default observer(BlockingEventForm);
