import {MutableState, Tools} from "final-form";
import {IGenericProtocol} from "../../../../app/models/protocol";

export const defectMutator = {
    calculateDefects: (_: any, state: MutableState<IGenericProtocol>, utils: Tools<any>) => {
        if (
            state.lastFormState?.values.defectsArea?.defects
        ) {
            let defects = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
            (state).lastFormState?.values.defectsArea?.defects
                .filter((value) => !value.isDeleted)
                .forEach(
                (defect) => {
                    if (defect.signDefect) {
                        defects[defect.signDefect - 1] =
                            defects[defect.signDefect - 1] + 1;
                    }
                }
            );
            let count = defects.reduce((a, b) => a + b, 0);
            let countByNumber = `1 - ${defects[0]}; 2 - ${defects[1]}; 3 - ${defects[2]}; 4 - ${defects[3]}; 5 - ${defects[4]}; 6 - ${defects[5]}; 7 - ${defects[6]}; 8 - ${defects[7]}; 9 - ${defects[8]}; 10 - ${defects[9]}`;
            let countByCategory = `A1 - ${defects[0]}; A - ${
                defects[1] + defects[2]
            }; B1 - ${defects[3]}; B - ${defects[4]}; C1 - ${
                defects[5]
            }; C - ${defects[6] + defects[7] + defects[8] + defects[9]};`;
            utils.changeValue(state, "defectsCount", () =>
                count.toString()
            );
            utils.changeValue(
                state,
                "defectsCountByNumber",
                () => countByNumber
            );
            utils.changeValue(
                state,
                "defectsCountByCategory",
                () => countByCategory
            );
        }
    },
};
