import { RootStore } from "./rootStore";
import { observable, action, runInAction } from "mobx";
import agent from "../api/agent";
import { toast } from "react-toastify";
import {
  ICopsProjectType,
  ICopsProjectTypeForm,
  ICopsSeriesType,
  ICopsSeriesTypeForm,
  ICopsUsage,
  ICopsUsageForm,
  ICopsCarConditionsDTO
} from "../models/copsIntegration";

export default class CopsIntegrationStore {
  rootStore: RootStore;
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @observable usages: ICopsUsage[] = [];
  @observable seriesTypes: ICopsSeriesType[] = [];
  @observable projectTypes: ICopsProjectType[] = [];
  @observable isLoading: boolean = false;

  @action loadCopsIntegrations = async () => {
    try {
      this.isLoading = true;
      const usagesPromise = agent.CopsIntegration.usages();
      const seriesTypesPromise = agent.CopsIntegration.seriesTypes();
      const projectTypesPromise = agent.CopsIntegration.projectTypes();
      const data = await Promise.all([
        usagesPromise,
        seriesTypesPromise,
        projectTypesPromise,
      ]);

      runInAction(() => {
        this.usages = data[0].copsUsages;
        this.seriesTypes = data[1].copsSeriesTypes;
        this.projectTypes = data[2].copsProjectTypes;
        this.isLoading = false;
      });
    } catch (error) {
      console.log(error);
      runInAction(() => {
        this.isLoading = false;
      });
    }
  };

  @action updateCopsProjectType = async (
    projectTypeForm: ICopsProjectTypeForm
  ) => {
    try {
       await agent.CopsIntegration.updateProjectTypes(
        projectTypeForm
      );
      runInAction(() => {
        const projectType = this.projectTypes.find(
          (x) => x.copsProjectTypeId === projectTypeForm.copsProjectTypeId
        );
        projectType!.testPredestinationId =
          projectTypeForm.testPredestinationId;
        toast.success(
          "Mapování COPS project type na podurčení zkoušky bylo úspěšně uloženo"
        );
      });
    } catch (error) {
      console.log(error);
      toast.error("Nastala chyba při ukládání mapování COPS project type");
      throw error;
    }
  };

  @action updateCopsSeriesType = async (
    seriesTypeForm: ICopsSeriesTypeForm
  ) => {
    try {
     await agent.CopsIntegration.updateSeriesTypes(
        seriesTypeForm
      );
      runInAction(() => {
        const seriesType = this.seriesTypes.find(
          (x) => x.copsSeriesTypeId === seriesTypeForm.copsSeriesTypeId
        );
        seriesType!.testDestinationId = seriesTypeForm.testDestinationId;
        seriesType!.isActive = seriesTypeForm.isActive;
        toast.success(
          "Mapování COPS series type na typ vozu bylo úspěšně uloženo"
        );
      });
    } catch (error) {
      console.log(error);
      toast.error("Nastala chyba při ukládání mapování COPS series type");
      throw error;
    }
  };

  @action updateCopsUsage = async (usageForm: ICopsUsageForm) => {
    try {
       await agent.CopsIntegration.updateUsage(usageForm);
      runInAction(() => {
        const usage = this.usages.find(
          (x) => x.copsUsageId === usageForm.copsUsageId
        );
        usage!.testTypeId = usageForm.testTypeId;
        usage!.isActive = usageForm.isActive;
        toast.success(
          "Mapování COPS usage na typ zkoušky bylo úspěšně uloženo"
        );
      });
    } catch (error) {
      console.log(error);
      toast.error("Nastala chyba při ukládání mapování COPS usage");
      throw error;
    }
  };

  @action loadCopsCarConditions = async () => {
     
    try {
        this.isLoading = true;
        const conditions = await agent.CopsIntegration.carConditions();
        runInAction(() => {
            this.isLoading = false;
        });
        return conditions;
    } catch (error) {
      console.log(error);
      this.isLoading = false;
    }
  };

  @action saveCopsCarConditions = async (copsCarConditions: ICopsCarConditionsDTO) => {
    try {
      this.isLoading = true;
      const conditions = await agent.CopsIntegration.createsOrUpdates(copsCarConditions);
      runInAction(() => {
        toast.success(`Podmínky synchronizace vozů byly uloženy`);
        this.isLoading = false;
      });
      return conditions;
    } catch (error) {
      console.log(error);
      toast.error("Při ukládání podmínek synchronizace vozů z COPS nastala chyba!");
      this.isLoading = false;
      throw error;
    }
  };
}
