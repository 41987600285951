import React, { useContext } from "react";
import { RootStoreContext } from "../../stores/rootStore";
import { Modal } from "react-bootstrap";
import { observer } from "mobx-react-lite";

const ModalContainer = () => {
  const rootStore = useContext(RootStoreContext);
  const {
    modal: { open, body, title, preventClosing, size },
    closeModal
  } = rootStore.modalStore;


  return (
    <Modal show={open} onHide={closeModal} size={size?size:"lg"} backdrop={preventClosing?"static":true}>
      {title && (
        <Modal.Header closeButton>
          <Modal.Title><h3>{title}</h3></Modal.Title>
        </Modal.Header>
      )}
      <Modal.Body>{body}</Modal.Body>
    </Modal>
  );
};

export default observer(ModalContainer);
