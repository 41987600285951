import React, { Fragment } from "react";
import { Alert } from "react-bootstrap";

interface IProps {
  type:
    | "info"
    | "danger"
    | "success"
    | "primary"
    | "secondary"
    | "warning"
    | "dark"
    | "light";
  heading: string;
  message?: string ;
  messageList?: string[] ;
  show: boolean;
  setShow: (show: boolean) => void;
}

const AlertMessage: React.FC<IProps> = ({
  type,
  heading,
  message,
  messageList,
  show,
  setShow,
}) => {
  return (
    <Fragment>
      {show && (
        <Alert variant={type} onClose={() => setShow(false)} dismissible>
          <Alert.Heading>{heading}</Alert.Heading>
          {message && <p>{message}</p>}
          {messageList && (
            <ul>
              {messageList.map((message, index) => (
                <li key={index}>{message}</li>
              ))}
            </ul>
          )}
        </Alert>
      )}
    </Fragment>
  );
};

export default AlertMessage;
