import React, { useContext, useEffect, useState, Fragment } from "react";
import { Form as FinalForm, Field, FormSpy } from "react-final-form";
import {
  Form,
  Button,
  Col,
  Row,
  Popover,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import SelectInput from "../../app/common/form/SelectInput";
import NumberInput from "../../app/common/form/NumberInput";
import { RootStoreContext } from "../../app/stores/rootStore";
import { observer } from "mobx-react-lite";
import TextAreaInput from "../../app/common/form/TextAreaInput";
import SwitchInput from "../../app/common/form/SwitchInput";
import { RouteComponentProps, Link, Prompt } from "react-router-dom";
import {
  ICarTest,
  convertICarTestToICarTestForm,
  IWorker,
} from "../../app/models/carTest";
import { IWorker as ExpertGroupWorker } from "../../app/models/expertGroup";
import VinCode from "../vin/VinCode";
import LabelValuePair from "../../app/common/utils/LabelValuePair";
import "moment/locale/cs";
import moment from "moment";
import { getHoursString } from "../../app/common/utils/czechDeclension";
import DateTimeInput from "../../app/common/form/DateTimeInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import AlertMessage from "../alert/AlertMessage";
import { ITestDefinition } from "../../app/models/testDefinition";
import { ICar } from "../../app/models/car";
import ScheduleCarDetail from "../schedule/dashboard/ScheduleCarDetail";
import { getTestStateTransitions } from "../../app/common/utils/testStateTransitions";
import { FormApi } from "final-form";
import { toast } from "react-toastify";
import "./TestDetail.scss";
import Can, { checkAuthRule } from "../../authorization/Can";
import ConflictsPanel from "../conflictsPanel/ConflictsPanel";
import { CarStates, CarTestStates, IExpertGroupEnumItem } from "../../app/models/enum";
import EditSaveResetButtons from "../ButtonGroups/EditSaveResetButtons";
import WorkersMultiSelectInput from "../../app/common/form/WorkersMultiSelectInput";
import MultiSelectInput from "../../app/common/form/MultiSelectInput";

interface DetailParams {
  id: string;
}

const TestDetail: React.FC<RouteComponentProps<DetailParams>> = ({ match }) => {
  const rootStore = useContext(RootStoreContext);
  const { loadEnums, enums } = rootStore.enumStore;
  const {
    loadTestDefinitions,
    testDefinitionsLoaded,
  } = rootStore.testDefinitionStore;
  const {
    carTestsRegistry,
    loadCarTest,
    updateCarTest,
    isSaving
  } = rootStore.carTestStore;
  const { getWorkers } = rootStore.expertGroupStore;
  const { carRegistry, loadCar } = rootStore.carStore;
  const { openDialog } = rootStore.modalStore;
  const { user } = rootStore.authStore;

  const [carTest, setCarTest] = useState<ICarTest | undefined>(undefined);
  const [car, setCar] = useState<ICar | undefined>(undefined);
  const [testDefinition, setTestDefinition] = useState<
    ITestDefinition | undefined
  >(undefined);
  const [edit, setEdit] = useState(false);
  const [changed, setChanged] = useState(false);

  const [errors, setErrors] = useState([]);
  const [showSubmitError, setShowSubmitError] = useState(false);

  const [workers, setWorkers] = useState<ExpertGroupWorker[]>([]);

  const labelWidth = 4;
  const valueWidth = 8;

  const loadWorkers = async (
    expertGroupId: number,
    carTestId: number,
    startAt?: Date,
    length?: number
  ) => {
    if (expertGroupId && startAt && length && carTestId) {
      var workers = await getWorkers(expertGroupId, startAt, length, carTestId);
      // console.log(workers);
      setWorkers(workers);
    }
  };

  useEffect(() => {
    const abortController = new AbortController();
    const testId = Number(match.params.id);

    loadEnums();
    loadCarTest(testId).then(() => {
      const carTestL = carTestsRegistry.get(testId);
      setCarTest(carTestL);
      if (carTestL?.testDefinitionId) {
        loadTestDefinitions().then(() => {
          const testDefinition = testDefinitionsLoaded.filter(
            (td) => td.testDefinitionId === carTestL?.testDefinitionId
          );
          setTestDefinition(testDefinition[0]);
        });
      }
      if (carTestL) {
        let carL = carRegistry.get(carTestL.carId);
        if (!carL) {
          loadCar(carTestL.carId).then(() => {
            carL = carRegistry.get(carTestL.carId);
          });
        }
        setCar(carL);
        loadWorkers(
          carTestL.expertGroupId,
          carTestL.carTestId,
          carTestL.startAt,
          carTestL.length
        );
      }
    });
    return function cleanup() {
      abortController.abort();
    };
  }, [
    match.params.id,
    loadEnums,
    loadCarTest,
    setCarTest,
    loadTestDefinitions,
    loadCar,
    setCar,
    carTestsRegistry,
    carRegistry,
    testDefinitionsLoaded,
    //loadWorkers,
  ]);

  const carDetail = carTest && car && (
    <Popover
      id={`popover-cardetail-${carTest!.carId}`}
      style={{ minWidth: "40rem" }}
    >
      <Popover.Title as="h3">
        Detail vozu <VinCode vin={carTest!.carVIN} />
      </Popover.Title>
      <Popover.Content>
        <ScheduleCarDetail car={car!} />
      </Popover.Content>
    </Popover>
  );

  const handleFinalFormSubmit = (values: any, form: FormApi<ICarTest>) => {
    const carTestForm = convertICarTestToICarTestForm(values);

    setErrors([]);
    setShowSubmitError(false);
    if (
      carTestForm.testStateId === CarTestStates.Interrupted &&
      !values.confirmed
    ) {
      openDialog(
        <div>
          <p>
            Nastavením zkoušky do stavu "Přerušená" změníte stav vozu na
            "Přerušený". To znamená, že na voze je závada, která{" "}
            <strong>zabraňuje dalšímu testování</strong> a všechny následující
            zkoušky naplánované po této zkoušce budou vráceny do stavu
            "Registrovaná".
          </p>
        </div>,
        {
          title:
            "Přerušením zkoušky změníte stav vozu a ovlivníte další naplánované zkoušky",
          confirmButtonLabel: "Pokračovat a uložit",
          cancelButtonLabel: "Zpět",
          onConfirm: () => {
            form.change("confirmed", true);
            form.submit();
          },
          onCancel: () => {
            form.change("confirmed", false);
          },
        }
      );
      return;
    }

    updateCarTest(carTestForm, values.confirmed)
      .then((response) => {
        // nacti kolize z response
        const collisions = response.carTest.carTestConflicts;
        const submitWarnings = response.carTest.submitWarnings;
        //pokud pole s konflikty nebude prazdne -> dialog s potvrzenim ulozeni
        if ((!!collisions?.length|| !!submitWarnings?.length) && !values.confirmed) {
          toast.warn(`Zkouška zatím nebyla uložena`);

          openDialog(
            <div>
              <ul>
                {submitWarnings?.map((warn, index) => (
                <li key={index}>{warn}</li>
              ))}
                {collisions.map((col) => (
                  <li key={col.carTestConflictId}>{col.message}</li>
                ))}
              </ul>
            </div>,
            {
              title: "Uložením zkoušky vzniknou kolize",
              confirmButtonLabel: "Pokračovat a uložit",
              cancelButtonLabel: "Zpět",
              onConfirm: () => {
                form.change("confirmed", true);
                form.submit();
              },
              onCancel: () => {
                form.change("confirmed", false);
              },
            }
          );
        }
        // pokud ulozenim nevznikly zadne kolize, nebo je uzivatel potvrdil, finalne uloz
        if ((!collisions?.length && !submitWarnings?.length)|| values.confirmed) {
          setEdit(false);
          form.change("confirmed", false);
          setCarTest(response.carTest);
          form.initialize(response.carTest);
          toast.success(`Zkouška byla uložena`);
        }
      })
      .catch((error) => {
        console.log(error);
        setErrors(Object.values(error.response?.data?.errors));
        setShowSubmitError(true);
      });
  };

  // Reset all form values to initial values
  const handleReset = (form: any) => {
    setEdit(false);
    form.reset();
  };

  const workersToAssignOptions = () => {
    const editOnlySelfAsWorkers = checkAuthRule(
      user?.userRoleId,
      "cartest-detail-page:editWorkersSelfOnly"
    );
    if (!editOnlySelfAsWorkers)
      return workers
        .filter((w) => w.isActive && w.performTests)
        .sort((a, b) => (a.surname < b.surname ? -1 : 1))
        .map((w) => ({
          ...w,
          label: w.fullName,
          value: w.userId,
          isFixed: false,
        }));
    else {
      const userOption = workers
        .filter((w) => w.userId === user?.userId)
        .map((w) => ({
          ...w,
          label: w.fullName,
          value: w.userId,
          isFixed: false,
        }));
      return userOption;
    }
  };

  const extraWorkersOptions = () => {
    const editOnlySelfAsWorkers = checkAuthRule(
      user?.userRoleId,
      "cartest-detail-page:editWorkersSelfOnly"
    );
    if (!editOnlySelfAsWorkers)
      return workers
        .filter((w) => w.isActive)
        .sort((a, b) => (a.surname < b.surname ? -1 : 1))
        .map((w) => ({
          ...w,
          label: w.fullName,
          value: w.userId,
          isFixed: false,
        }));
    else {
      const userOption = workers
        .filter((w) => w.userId === user?.userId)
        .map((w) => ({
          ...w,
          label: w.fullName,
          value: w.userId,
          isFixed: false,
        }));
      return userOption;
    }
  };

  let plannedWorkers: any[] = [];
  if (carTest)
    plannedWorkers = workers
      .filter((w) => carTest!.plannedWorkers?.includes(w.userId))
      .map((w) => ({
        ...w,
        label: w.fullName,
        value: w.userId,
        isFixed: true,
      }));

  return (
    <div className="container" style={{ marginBottom: "3rem" }}>
      <Prompt
        when={edit && changed}
        message="Na stránce byly provedeny změny, které nejsou uloženy. Opravdu chcete odejít?"
      />
      {carTest && enums && (
        <FinalForm
          initialValues={carTest}
          mutators={{
            setLength: (args, state, utils) => {
              testDefinition &&
                utils.changeValue(state, "length", () =>
                  (state.formState.values as ICarTest).shortened
                    ? testDefinition.shortLength
                    : testDefinition.fullLength
                );
              let carTestVals = state.formState.values as ICarTest;
              loadWorkers(
                carTestVals.expertGroupId,
                carTestVals.carTestId,
                carTestVals.startAt,
                carTestVals.length
              );
            },
            setWorkers: (args, state, utils) => {
              let carTestVals = state.formState.values as ICarTest;
              loadWorkers(
                carTestVals.expertGroupId,
                carTestVals.carTestId,
                carTestVals.startAt,
                carTestVals.length
              );
            },
          }}
          onSubmit={handleFinalFormSubmit}
          validate={(values) => {
            const errors = {} as any;
            if (values.length < 0) {
              errors.length = "Délka zkoušky musí být větší nebo rovna 0";
            }
            if (!values.length && values.length !== 0) {
              errors.length = "Délka zkoušky musí být vyplněna";
            }
            // Registrovaná zkouška
            if (values.testStateId === 1) {
              if (!!values.startAt && (!values.length || values.length < 1))
                errors.length =
                  "Zkouška musí mít zadanou délku, pokud má zadaný začátek";
            }
            // Plánovaná zkouška
            if (values.testStateId === 2) {
              if (!values.startAt)
                errors.startAt = "Musí být vyplněn začátek zkoušky";
              if (!values.length || values.length < 1)
                errors.length =
                  "Plánovaná zkouška musí mít zadanou délku větší než 0";
            }
            //Probíhající zkouška
            if (values.testStateId === 3) {
              if (values.workerCount !== values.assignedWorkers?.length)
                errors.assignedWorkers =
                  "Probíhající zkouška nemá přiřazené potřebné pracovníky";
            }
             //Přerušená zkouška, nedokončená zkouška
             if (values.testStateId === CarTestStates.Interrupted || values.testStateId === CarTestStates.Unfinished) {
              if (!values.note)
                errors.note =
                  "Doplňte poznámku se zdůvodněním";
            }
            return errors;
          }}
          render={({ handleSubmit, form, values }) => (
            <Form onSubmit={handleSubmit} id="CarTestForm">
              <div className="pageheader">
                <div className="pageheader__left">
                  <h1>Detail zkoušky automobilu</h1>
                </div>
                <div className="pageheader__right">
                  <Can
                    roleId={user?.userRoleId}
                    perform="cartest-detail-page:editBtn"
                    data={{
                      userExpertGroupId: user?.expertGroupId,
                      expertGroupId: carTest.expertGroupId,
                      managedExpertGroups: user?.managedGroups,
                      placeId: carTest.placeId,
                      userPlaceId: user?.placeId
                    }}
                    yes={
                      <EditSaveResetButtons
                        edit={edit}
                        setEdit={setEdit}
                        handleReset={handleReset}
                        form={form}
                        isSaving={isSaving}
                      />
                    }
                  />
                </div>
              </div>
              <AlertMessage
                type="danger"
                heading="Nastala chyba při ukládání"
                show={showSubmitError}
                setShow={setShowSubmitError}
                messageList={errors}
              />
              {!!carTest.carTestConflicts?.length && (
                <ConflictsPanel
                  conflicts={carTest.carTestConflicts}
                  title={" Zkouška je v konfliktu"}
                />
              )}
              <Row>
                <Col xl={6} lg={6} md={12} sm={12} style={{ padding: "1rem" }}>
                  <h2>
                    Informace o voze
                    <OverlayTrigger
                      trigger={["click"]}
                      placement="auto"
                      overlay={carDetail}
                      rootClose
                    >
                      <Button
                        variant="link"
                        className="btn-link--green btn-link--noverticalpadding"
                      >
                        <FontAwesomeIcon icon={faInfoCircle} />
                      </Button>
                    </OverlayTrigger>
                  </h2>
                  <LabelValuePair
                    label="VIN kód"
                    value={<VinCode vin={carTest.carVIN} />}
                    labelColSize={labelWidth}
                    valueColSize={valueWidth}
                  />
                  <LabelValuePair
                    label="KNR kód"
                    value={car?.prodIdentificationNum}
                    labelColSize={labelWidth}
                    valueColSize={valueWidth}
                  />

                  <LabelValuePair
                    label="Logistické číslo"
                    value={carTest.carLogisticNum}
                    labelColSize={labelWidth}
                    valueColSize={valueWidth}
                  />

                  <Col sm={{ offset: labelWidth }}>
                    <Button
                      variant="outline-info"
                      as={Link}
                      to={`/car/${carTest.carId}`}
                      style={{ marginLeft: "-15px", marginTop: "1.5rem" }}
                    >
                      Detail
                    </Button>
                  </Col>
                </Col>
                <Col xl={6} lg={6} md={12} sm={12} style={{ padding: "1rem" }}>
                  <h2>Identifikace zkoušky</h2>
                  {carTest.testDefinitionId && (
                    <Fragment>
                      <LabelValuePair
                        label="Typ zkoušky"
                        value={carTest.carTestTypeName}
                        labelColSize={labelWidth}
                        valueColSize={valueWidth}
                      />

                      <LabelValuePair
                        label="Určení zkoušky"
                        value={carTest.carTestDestinationName}
                        labelColSize={labelWidth}
                        valueColSize={valueWidth}
                      />
                      <LabelValuePair
                        label="Podurčení zkoušky"
                        value={carTest.carTestPredestinationName}
                        labelColSize={labelWidth}
                        valueColSize={valueWidth}
                      />
                    </Fragment>
                  )}
                  <Field<number>
                    name="expertGroupId"
                    title="Odborná skupina"
                    options={enums?.expertGroups.filter(x => x.placeId == carTest.placeId)}
                    component={SelectInput}
                    parse={(value) => value && parseInt(value)}
                    disabled={
                      !edit ||
                      carTest.testDefinitionId ||
                      !checkAuthRule(
                        user?.userRoleId,
                        "cartest-detail-page:editExpertGroup"
                      ) ||
                      [
                        CarTestStates.Planned,
                        CarTestStates.Ongoing,
                        CarTestStates.DoNotPerform,
                        CarTestStates.Cancelled,
                        CarTestStates.Unfinished,
                        CarTestStates.Completed,
                      ].includes(values.testStateId)
                    }
                  />
                  {!carTest.testDefinitionId && (
                    <Field<string>
                      name="description"
                      title="Popis zkoušky"
                      component={TextAreaInput}
                      disabled={
                        !edit ||
                        carTest.testDefinitionId ||
                        !checkAuthRule(
                          user?.userRoleId,
                          "cartest-detail-page:editDescription",
                          {
                            userExpertGroupId: user?.expertGroupId,
                            expertGroupId: carTest.expertGroupId,
                            managedExpertGroups: user?.managedGroups,
                          }
                        )
                      }
                    />
                  )}
                  <Col sm={{ offset: labelWidth }}>
                  {!!carTest.protocols?.length && (
                    carTest.protocols?.map((protocol) => 
                      <Button
                        variant="outline-info"
                        size="sm"
                        key={protocol.protocolTypeId}
                        as={Link}
                        to={protocol.url}
                      >
                      Protokol - {protocol.protocolTypeName}
                      </Button>
                    ))}
                  </Col>
                </Col>
              </Row>
              <h2 style={{ marginBottom: "1rem" }}>Základní údaje</h2>
              <Row style={{ marginBottom: "2rem" }}>
                <Col xl={6} lg={6} md={12} sm={12}>
                  <Field<number>
                    title="Stav zkoušky"
                    name="testStateId"
                    options={enums.testStates.filter((state) =>
                      getTestStateTransitions(
                        carTest.testStateId,
                        user!.userRoleId
                      ).includes(state.key)
                    )}
                    parse={(value) => value && parseInt(value)}
                    component={SelectInput}
                    disabled={
                      !edit ||
                      !checkAuthRule(
                        user?.userRoleId,
                        "cartest-detail-page:editTestState",
                        {
                          userExpertGroupId: user?.expertGroupId,
                          expertGroupId: carTest.expertGroupId,
                          managedExpertGroups: user?.managedGroups,
                          userId: user?.userId,
                          workerIds: values.assignedWorkers?.map(
                            (w) => w.userId
                          ),
                        }
                      ) ||
                      [CarStates.OutOfTestroom].includes(car?.carStateId || 0)
                    }
                  />
                  <Field<number>
                    title="Povaha zkoušky"
                    name="testCharacteristicsId"
                    options={enums.testCharacteristics}
                    parse={(value) => value && parseInt(value)}
                    component={SelectInput}
                    disabled={
                      !edit ||
                      carTest.testDefinitionId ||
                      [
                        CarTestStates.Planned,
                        CarTestStates.Ongoing,
                        CarTestStates.DoNotPerform,
                        CarTestStates.Cancelled,
                        CarTestStates.Unfinished,
                        CarTestStates.Completed,
                      ].includes(values.testStateId) ||
                      !checkAuthRule(
                        user?.userRoleId,
                        "cartest-detail-page:editTestCharacteristics",
                        {
                          userExpertGroupId: user?.expertGroupId,
                          expertGroupId: carTest.expertGroupId,
                          managedExpertGroups: user?.managedGroups,
                        }
                      )
                    }
                  />
                  <Field<number>
                    name="length"
                    title="Délka zkoušky"
                    min={0}
                    inputOnChange={form.mutators.setWorkers}
                    component={NumberInput}
                    rightAppend={getHoursString(values.length)}
                    parse={(value) => value && parseInt(value)}
                    disabled={
                      !edit ||
                      !checkAuthRule(
                        user?.userRoleId,
                        "cartest-detail-page:editLength",
                        {
                          userExpertGroupId: user?.expertGroupId,
                          expertGroupId: carTest.expertGroupId,
                          managedExpertGroups: user?.managedGroups,
                        }
                      ) ||
                      [
                        CarTestStates.Ongoing,
                        CarTestStates.DoNotPerform,
                        CarTestStates.Cancelled,
                        CarTestStates.Unfinished,
                        CarTestStates.Completed,
                      ].includes(values.testStateId)
                    }
                  />
                  <Row>
                    <Col sm={{ offset: 4 }}>
                      <Field
                        name="shortened"
                        inputOnChange={form.mutators.setLength}
                        options={[
                          { key: 0, value: "standardní" },
                          { key: 1, value: "zkrácená" },
                        ]}
                        format={(v) => (v ? 1 : 0)}
                        parse={(v) => (v ? true : false)}
                        component={SwitchInput}
                        disabled={
                          !edit ||
                          [
                            CarTestStates.Ongoing,
                            CarTestStates.DoNotPerform,
                            CarTestStates.Cancelled,
                            CarTestStates.Unfinished,
                            CarTestStates.Completed,
                          ].includes(values.testStateId) ||
                          (carTest.testDefinitionId &&
                            !testDefinition?.shortLength) ||
                          !checkAuthRule(
                            user?.userRoleId,
                            "cartest-detail-page:editShortened",
                            {
                              userExpertGroupId: user?.expertGroupId,
                              expertGroupId: carTest.expertGroupId,
                              managedExpertGroups: user?.managedGroups,
                            }
                          )
                        }
                      />
                      {edit &&
                        ![
                          CarTestStates.Ongoing,
                          CarTestStates.DoNotPerform,
                          CarTestStates.Cancelled,
                          CarTestStates.Unfinished,
                          CarTestStates.Completed,
                        ].includes(values.testStateId) &&
                        carTest.testDefinitionId &&
                        !testDefinition?.shortLength && (
                          <OverlayTrigger
                            placement="right"
                            overlay={
                              <Tooltip id={`tooltip-shortened`}>
                                Není možné přepnout, protože není definována
                                zkrácená délka zkoušky
                              </Tooltip>
                            }
                          >
                            <Button
                              variant="link"
                              style={{ color: "#0090d7", marginBottom: "1rem" }}
                            >
                              <FontAwesomeIcon icon={faInfoCircle} />
                            </Button>
                          </OverlayTrigger>
                        )}
                    </Col>
                  </Row>
                  <Field
                    validate={(value, allValues, meta) =>
                      meta?.modified &&
                      value < new Date() &&
                      [CarTestStates.Registered, 2].includes(values.testStateId)
                        ? "Začátek zkoušky nesmí být v minulosti"
                        : undefined
                    }
                    name="startAt"
                    title="Začátek zkoušky"
                    component={DateTimeInput}
                    inputOnChange={form.mutators.setWorkers}
                    format={(val) => (val ? moment(val).toDate() : undefined)}
                    parse={(val) => (val ? moment(val).toDate() : undefined)}
                    disabled={
                      !edit ||
                      [
                        CarTestStates.Ongoing,
                        CarTestStates.DoNotPerform,
                        CarTestStates.Cancelled,
                        CarTestStates.Unfinished,
                        CarTestStates.Completed,
                      ].includes(values.testStateId) ||
                      !checkAuthRule(
                        user?.userRoleId,
                        "cartest-detail-page:editStart",
                        {
                          userExpertGroupId: user?.expertGroupId,
                          expertGroupId: carTest.expertGroupId,
                          managedExpertGroups: user?.managedGroups,
                        }
                      )
                    }
                  />
                  <Field<Date>
                    name="endAt"
                    title="Konec zkoušky"
                    component={DateTimeInput}
                    format={() =>
                      values.startAt
                        ? moment(values.startAt)
                            .add(values.length, "hours")
                            .toDate()
                        : undefined
                    }
                    disabled
                  />
                </Col>
                <Col xl={6} lg={6} md={12} sm={12}>
                  <Field<number>
                    name="workerCount"
                    title="Počet pracovníků"
                    component={NumberInput}
                    parse={(value) => value && parseInt(value)}
                    disabled={true}
                  />
                  <Field
                    title="Plánovaní pracovníci"
                    name="plannedWorkers"
                    initialValue={plannedWorkers}
                    placeholder="  "
                    options={workersToAssignOptions()}
                    format={(value) => {
                      const options: any[] = [];
                      if (value) {
                        value.forEach((workerId: number) => {
                          const plannedWorker = carTest.expertGroupWorkers.find(
                            (grw) => grw.userId === workerId
                          );
                          if (plannedWorker)
                            options.push({
                              value: plannedWorker.userId,
                              label: plannedWorker.userFullName,
                              isFixed: true,
                            });
                        });
                      }
                      return options;
                    }}
                    component={WorkersMultiSelectInput}
                    disabled={true}
                  />

                  <Field
                    title="Přiřazení pracovníci"
                    name="assignedWorkers"
                    maxSelectedOptions={values.workerCount}
                    options={workersToAssignOptions()}
                    format={(value) =>
                      value &&
                      value.map((v: IWorker) => ({
                        value: v.userId,
                        label: v.userFullName,
                        isFixed:
                          v.userId !== user?.userId &&
                          checkAuthRule(
                            user?.userRoleId,
                            "cartest-detail-page:editWorkersSelfOnly"
                          ),
                        isAvailableToTest: workers?.find(
                          (w) => w.userId === v.userId
                        )?.isAvailableToTest,
                      }))
                    }
                    parse={(value) =>
                      value &&
                      value.map((v: any) => ({
                        userId: v.value,
                        userFullName: v.label,
                        isAvailableToTest: v.isAvailableToTest,
                      }))
                    }
                    placeholder="Vyberte pracovníky"
                    component={WorkersMultiSelectInput}
                    disabled={
                      !edit ||
                      [
                        CarTestStates.Registered,
                        CarTestStates.DoNotPerform,
                        CarTestStates.Cancelled,
                        CarTestStates.Completed,
                      ].includes(values.testStateId) ||
                      !checkAuthRule(
                        user?.userRoleId,
                        "cartest-detail-page:editWorkers",
                        {
                          userExpertGroupId: user?.expertGroupId,
                          expertGroupId: carTest.expertGroupId,
                          managedExpertGroups: user?.managedGroups,
                        }
                      )
                    }
                  />
                  <Field
                    title="Extra pracovníci"
                    name="extraWorkers"
                    options={extraWorkersOptions()}
                    format={(value) =>
                      value &&
                      value.map((v: IWorker) => ({
                        value: v.userId,
                        label: v.userFullName,
                      }))
                    }
                    parse={(value) =>
                      value &&
                      value.map((v: any) => ({
                        userId: v.value,
                        userFullName: v.label,
                      }))
                    }
                    placeholder="Vyberte pracovníky"
                    component={MultiSelectInput}
                    disabled={
                      !edit ||
                      [
                        CarTestStates.Registered,
                        CarTestStates.DoNotPerform,
                        CarTestStates.Cancelled,
                        CarTestStates.Completed,
                      ].includes(values.testStateId) ||
                      !checkAuthRule(
                        user?.userRoleId,
                        "cartest-detail-page:editWorkers",
                        {
                          userExpertGroupId: user?.expertGroupId,
                          expertGroupId: carTest.expertGroupId,
                          managedExpertGroups: user?.managedGroups,
                        }
                      )
                    }
                  />

                  <Field<string>
                    name="note"
                    title="Poznámka"
                    component={TextAreaInput}
                    disabled={!edit}
                  />
                </Col>
              </Row>

              <FormSpy
                subscription={{ pristine: true }}
                onChange={(props) => {
                  setChanged(!props.pristine);
                }}
              />
            </Form>
          )}
        />
      )}
    </div>
  );
};

export default observer(TestDetail);
