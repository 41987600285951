import React, { Fragment, useContext, useState } from "react";
import { Form, Table, Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { ICarBodyProtocol, IProtocolArea, IDefect } from "../../../../app/models/protocol"
import { IEnums } from "../../../../app/models/enum";
import { createGuid } from "../../../../app/common/utils/functions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faEdit, faTimes } from "@fortawesome/free-solid-svg-icons";
import { Field } from "react-final-form";
import TextInput from "../../../../app/common/form/TextInput";
import TextAreaInput from "../../../../app/common/form/TextAreaInput";
import SelectInput from "../../../../app/common/form/SelectInput";
import CheckboxInput from "../../../../app/common/form/CheckboxInput";
import { FormApi } from "final-form";
import { observer } from "mobx-react-lite";

interface IProps {
  isEdit: boolean;
  defects: IDefect[];
  defectsArea: IProtocolArea;
  enums: IEnums | undefined;
  isDisabled: boolean;
  form: FormApi<ICarBodyProtocol>;
  values: ICarBodyProtocol;
  handleAddDefect: (values: ICarBodyProtocol) => void;
  handleRemoveDefect: (rowNumber: number | undefined, values: ICarBodyProtocol) => void;
}

const DefectsArea: React.FC<IProps> = ({
  isEdit,
  defects,
  defectsArea,
  enums,
  isDisabled,
  form,
  values,
  handleAddDefect,
  handleRemoveDefect,
}) => {
    return (
      <Fragment>
        <Table className="protocol-table">
          <thead>
            <tr>
              <th className="headingrow" style={{borderTop: 0}} colSpan={12}><h2>{defectsArea.name}</h2></th>
            </tr>
          </thead>
        </Table>
        <Table className={`protocol-table ${isDisabled ? "no-print" : ""}`}>
          <thead>
            <tr className={`${isDisabled ? "disabled" : ""}`}>
              <th>Známka</th>
              <th colSpan={2}>Závada</th>
              <th colSpan={4}>Analýza / Opatření / Termín</th>
              <th>Zodpovídá</th>
              <th>Status</th>
              {isEdit && !isDisabled && (<th></th>)}
            </tr>
          </thead>
          <tbody>
            {defects?.filter((d) => !d.isDeleted).map((defect, rowIndex) => (
              <Fragment key={`defect-${rowIndex}`}>
                {defect.protocolRuleId && (
                  <tr className={`${isDisabled ? "disabled" : ""}`}>
                    <td colSpan={isEdit && !isDisabled ? 10 : 9}>
                      <Field<string>
                        name={`defects[${defect.rownumber}].partName`}
                        initialValue={defect?.partName}
                        component={TextInput}
                        rows={1}
                        size="sm"
                        disabled={true}
                      />
                    </td>
                  </tr>
                )}
                <tr className={`${isDisabled ? "disabled" : ""}`}>
                  <td rowSpan={3} style={{verticalAlign: "top"}}>
                    <Form.Group>
                      <Field
                        name={`defects[${defect.rownumber}].rownumber`}
                        initialValue={defect.rownumber}
                        component="input"
                        hidden
                        disabled
                      />
                      <Field
                        name={`defects[${defect.rownumber}].defectId`}
                        initialValue={defect.defectId}
                        component="input"
                        hidden
                        disabled
                      />
                      <Field
                        name={`defects[${defect.rownumber}].protocolRuleId`}
                        initialValue={defect.protocolRuleId}
                        component="input"
                        hidden
                        disabled
                      />
                      <Field
                        name={`defects[${defect.rownumber}].carProtocolValueId`}
                        initialValue={defect.carProtocolValueId}
                        component="input"
                        hidden
                        disabled
                      />
                      <Field
                        name={`defects[${defect.rownumber}].isDeleted`}
                        initialValue={defect.isDeleted}
                        component="input"
                        hidden
                        disabled
                      />
                      <Field
                        name={`defects[${defect.rownumber}].signDefect`}
                        options={[
                          { key: null, value: "" },
                          { key: 1, value: "1 - A1" },
                          { key: 2, value: "2 - A" },
                          { key: 3, value: "3 - A" },
                          { key: 4, value: "4 - B1" },
                          { key: 5, value: "5 - B" },
                          { key: 6, value: "6 - C1" },
                          { key: 7, value: "7 - C" },
                          { key: 8, value: "8 - C" },
                          { key: 9, value: "9 - C" },
                          { key: 10, value: "10 - C" },
                        ]}
                        initialValue={defect.signDefect}
                        inputOnChange={(e: any) => {
                          form.mutators.calculateDefects(e);
                        }}
                        component={SelectInput}
                        rows={1}
                        size="sm"
                        disabled={!isEdit || isDisabled}
                      />
                    </Form.Group>
                  </td>
                  <td colSpan={2} rowSpan={3} style={{verticalAlign: "top"}}>
                    <Field<string>
                      name={`defects[${defect.rownumber}].description`}
                      initialValue={defect?.description}
                      component={TextAreaInput}
                      rows={5}
                      size="sm"
                      disabled={!isEdit || isDisabled}
                    />
                  </td>
                  <td style={{verticalAlign: "top"}}>
                    <span><b>A:</b></span>
                  </td>
                  <td colSpan={3} style={{verticalAlign: "top"}}>
                      <Field<string>
                      name={`defects[${defect.rownumber}].analysis`}
                      initialValue={defect?.analysis}
                      component={TextInput}
                      rows={1}
                      size="sm"
                      disabled={!isEdit || isDisabled}
                    />
                  </td>
                  <td rowSpan={3} style={{verticalAlign: "top"}}>
                    <Field<string>
                      name={`defects[${defect.rownumber}].responsible`}
                      initialValue={defect?.responsible}
                      component={TextInput}
                      rows={1}
                      size="sm"
                      disabled={!isEdit || isDisabled}
                    />
                  </td>
                  <td rowSpan={3} style={{verticalAlign: "top"}}>
                    <Form.Group>
                      <Field
                        name={`defects[${defect.rownumber}].state`}
                        options={enums?.defectStates}
                        initialValue={defect.state}
                        component={SelectInput}
                        isEmptyOption={true}
                        rows={1}
                        size="sm"
                        disabled={!isEdit || isDisabled}
                      />
                    </Form.Group>
                  </td>
                  {isEdit && !isDisabled && (
                  <td rowSpan={3} style={{textAlign: "center", verticalAlign: "top"}}>
                    <Button
                      size="sm"
                      variant="outline-danger"
                      onClick={() => handleRemoveDefect(defect.rownumber, values)}
                    >
                      <FontAwesomeIcon icon={faTimes} /> Odebrat
                    </Button>
                  </td>
                )}
                </tr>
                <tr className={`${isDisabled ? "disabled" : ""}`}>
                  <td style={{verticalAlign: "top"}}><span><b>O:</b></span></td>
                  <td colSpan={3} style={{verticalAlign: "top"}}>
                      <Field<string>
                        name={`defects[${defect.rownumber}].remedy`}
                        initialValue={defect?.remedy}
                        component={TextInput}
                        rows={1}
                        size="sm"
                        disabled={!isEdit || isDisabled}
                      />
                  </td>
                </tr>
                <tr className={`${isDisabled ? "disabled" : ""}`}>
                <td style={{verticalAlign: "top"}}><span><b>T:</b></span></td>
                <td colSpan={3} style={{verticalAlign: "top"}}>
                  <Form.Group>
                    <Field
                      name={`defects[${defect.rownumber}].deadline`}
                      initialValue={defect.deadline}
                      options={enums?.calendarWeeks}
                      component={SelectInput}
                      isEmptyOption={true} 
                      size="sm"
                      disabled={!isEdit || isDisabled}
                    />
                  </Form.Group>
                </td>
                </tr>
              </Fragment>
            ))}
            {isEdit && !isDisabled &&
              <tr className="no-print">
                <td colSpan={9}></td>
                <td style={{textAlign: "center"}}>
                  <OverlayTrigger
                    overlay={
                      <Tooltip id={`tooltip-addbutton`}>Přidat závadu</Tooltip>
                    }
                  >
                    <Button
                      variant="primary"
                      onClick={() => handleAddDefect(values)}
                      className="footer__btn-add"
                    >
                      <FontAwesomeIcon icon={faPlus} />
                    </Button>
                  </OverlayTrigger>
                </td>
              </tr>
            }
          </tbody>
        </Table>
      </Fragment>
  );
};

export default observer(DefectsArea);