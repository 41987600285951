import React, {Fragment} from "react";
import {Col, Form, Row} from "react-bootstrap";
import {FieldRenderProps} from "react-final-form";

type Props = FieldRenderProps<string, any>;

const TextInput: React.FC<Props> = ({
  input,
  meta,
  title,
  placeholder,
  plaintext,
  disabled,
  warning,
  inputOnChange,
  readonly,
  required,
  titleWidth,
  fieldWidth,
  maxLength,
  ...rest
}: Props) => {
  const errorMessage = (meta.error || meta.submitError) && meta.touched && (
    <Form.Control.Feedback type="invalid">
      {meta.error || meta.submitError}
    </Form.Control.Feedback>
  );
  const inputField = (
    <Fragment>
      <Form.Control
        type="text"
        {...input}
        onChange={(e: any) => {
          input.onChange(e);
          inputOnChange && inputOnChange(e);
        }}
        maxLength={maxLength}
        onKeyPress={(e: { key: string; preventDefault: () => any; }) => { e.key === 'Enter' && e.preventDefault(); }}
        isInvalid={meta.touched && !!meta.error}
        placeholder={placeholder}
        plaintext={plaintext}
        disabled={disabled}
        readOnly={readonly}
        className={warning && !(meta.touched && meta.error) ? "is-warning" : ""}
        {...rest}
      />
    </Fragment>
  );

  return (
    <Fragment>
      {title && (
        <Form.Group controlId={input.name}>
          <Row>
            <Col xs={titleWidth || 4} className="u-text-right">
              {title && (
                <Form.Label className={required ? "required " : ""}>
                  {title}
                </Form.Label>
              )}
            </Col>
            <Col xs={fieldWidth || 8}>
              {inputField}
              {errorMessage}
            </Col>
          </Row>
        </Form.Group>
      )}
      {!title && (
        <Form.Group controlId={input.name}>
          <Row noGutters>
            <Col xs={fieldWidth}>
              {inputField}
              {errorMessage}
            </Col>
          </Row>
        </Form.Group>
      )}
    </Fragment>
  );
};

export default TextInput;
