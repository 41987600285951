import React from 'react';
import {Row, Col} from "react-bootstrap";
import {Field, useForm} from "react-final-form";
import {IAcousticsProtocol, IProtocolPartRule, IProtocolCodeTableValues} from "../../../../app/models/protocol";
import AutocompleteSimpleInput from "../../../../app/common/form/AutocompleteSimpleInput";
import CheckboxInput from "../../../../app/common/form/CheckboxInput";
import DefectButton from "../protocolParts/defectForm/DefectButton";
import {defectOptions} from "../protocolParts/defectForm/defectUtils";
import SelectInput from "../../../../app/common/form/SelectInput";

interface IProps {
    areaName: string,
    groupName: string | undefined,
    ruleIndex: number,
    partRule: IProtocolPartRule,
    protocolCodeTableValues: IProtocolCodeTableValues[];
    isEdit: boolean,
    disabledArea: boolean,
    disabledGroup: boolean | undefined,
    disabledRule: boolean,
}

const AssessmentRow: React.FC<IProps> = ({areaName, groupName, ruleIndex, partRule, protocolCodeTableValues, isEdit, disabledArea, disabledGroup, disabledRule}) => {

    var name : string = areaName;
    if(groupName) { name = name + "." + groupName; }

    let disabled = disabledArea || (disabledGroup && disabledGroup === true) || disabledRule;
    const disabledStyle = disabled ? ' protocol-area--disabled no-print' : '';

    const form = useForm<IAcousticsProtocol>();

    const getEmtyColumns = () => {
        var emtyColumnsCount = partRule.measuredValues && partRule.measuredValues.length > 0 ? 3 - partRule.measuredValues.length : 0;
        if(partRule.protocolCodeTableId)
            emtyColumnsCount--;
        
        let columns = [];
        if(emtyColumnsCount > 0){
            for (let i = 0; i < emtyColumnsCount ; i++) {
                columns.push(<Col></Col>);
            }
        }
        return (columns);
    }

    const getColumnsWithImput = () => {
        let columnsCount = partRule.measuredValues && partRule.measuredValues.length > 0 ? partRule.measuredValues.length : 0;
        let columns = [];
        if(columnsCount > 0){
            for (let i = 0; i < columnsCount ; i++) {
                columns.push(
                    <Col>
                        <Field
                            name={`${name}.partRules[${ruleIndex}].measuredValues.[${i}]`}
                            initialValue={partRule.measuredValues?.[i]}
                            component={AutocompleteSimpleInput}
                            items={defectOptions.map(value => value.value)}
                            width={'100px'}
                            disabled={!isEdit || disabled}
                            /*
                            inputOnChange={(e: any) => {
                                form.change("", value );
                                form.mutators.handleChangeAssessment(e)
                            }}
                            */
                        />
                    </Col>
                );
            }
        }
        return (columns);
    }

    return (
        <Row noGutters key={ruleIndex} className={disabledStyle}>
            <Col sm={3}>
                {partRule.name}
                <div className="no-print">{partRule.additionalInfo}</div>
            </Col>
            <Col sm={7}>
                <Row>
        
                    {getEmtyColumns()}

                    {partRule.protocolCodeTableId && protocolCodeTableValues &&
                        <Col>
                            <Field
                                name={`${name}.partRules[${ruleIndex}].selectedValue`}
                                component={SelectInput}
                                options={protocolCodeTableValues
                                    .filter(x => x.protocolCodeTableId === partRule.protocolCodeTableId)
                                    .map(value => ({key: value.id, value: value.codeTableValue}))}
                                isEmptyOption={true}
                                disabled={!isEdit}
                            />
                        </Col>
                    }

                    {getColumnsWithImput()}

                    <Col sm={1} style={{paddingLeft: 0, paddingRight: 0}} className="no-print">
                        <DefectButton
                            partRule={partRule}
                            isEdit={isEdit}
                            isDisabled={!isEdit || disabled}
                        />
                    </Col>
                </Row>
            </Col>

            <Col>
                <Field
                    name={`${name}.partRules[${ruleIndex}].notPerform`}
                    initialValue={partRule.notPerform || false}
                    title="Neprovádět"
                    options={[
                        {key: 1, value: "ano"},
                        {key: 0, value: " ne "},
                    ]}
                    format={(v) => (v ? 1 : 0)}
                    parse={(v) => (!!v)}
                    type={"checkbox"}
                    component={CheckboxInput}
                    disabled={!isEdit || disabledArea || (disabledGroup && disabledGroup === true)}
                    labelWidth={8}
                    valueWidth={4}
                />
            </Col>
        </Row>

    );
};

export default AssessmentRow;