import { RootStore } from "./rootStore";
import { observable, action, runInAction, computed } from "mobx";
import agent from "../api/agent";
import { IUser, IUserForm } from "../models/user";
import { toast } from "react-toastify";
import { sortByStatusAndSurname } from "../common/utils/sort";
import { IPagedList } from "../models/pagedList";

export default class UserStore {
  rootStore: RootStore;
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @observable loadingInitial = false;
  @observable isSaving = false;
  @observable userRegistry = new Map<number, IUser>();
  @observable usersByLogin: IUser[] = [];
  @observable unassignedUsers: IUser[] = [];

  pageSize: number = 10;

  @observable usersPage: IPagedList = {
    items: [],
    currentPage: 1,
    pageSize: this.pageSize,
    totalCount: 0,
    totalPages: 0,
    hasNext: false,
    hasPrevious: false,
  };

  @action loadUsersPage = (page: number) => {
    this.usersPage = {
      items: this.usersSorted.slice(
        this.pageSize * (page - 1),
        this.pageSize * page
      ),
      currentPage: page,
      pageSize: this.pageSize,
      totalCount: this.usersPage.totalCount,
      totalPages: this.usersPage.totalPages,
      hasNext: page < this.usersPage.totalPages,
      hasPrevious: page > 1,
    };
  };

  @action loadUsers = async () => {
    if (this.userRegistry.size > 0) return;
    this.loadingInitial = true;
    try {
      const usersDTO = await agent.Users.list();
      runInAction("load Users", () => {
        usersDTO.users.forEach((user) =>
          this.userRegistry.set(user.userId, user)
        );
        this.usersPage = {
          items: this.usersSorted.slice(0, this.pageSize),
          currentPage: 1,
          pageSize: this.pageSize,
          totalCount: usersDTO.users.length,
          totalPages: Math.ceil(usersDTO.users.length / this.pageSize),
          hasNext: 1 < Math.ceil(usersDTO.users.length / this.pageSize),
          hasPrevious: false,
        };
        this.loadingInitial = false;
      });
    } catch (error) {
      runInAction("load Users error", () => {
        this.loadingInitial = false;
      });
      throw error;
    }
  };

  @computed get usersSorted() {
    return this.sortUsersBySurname(Array.from(this.userRegistry.values()));
  }

  sortUsersBySurname(users: IUser[]) {
    const sortedUsers = users.sort(sortByStatusAndSurname());
    return sortedUsers;
  }

  @action loadUsersByLogin = async (login: string) => {
    try {
      const result = await agent.Users.listByLogin(login);
      runInAction("load users by login", () => {
        this.usersByLogin = result.users;
      });
      return result.users;
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  @action loadUser = async (id: number) => {
    this.loadingInitial = true;
    try {
      const user = await agent.Users.details(id);
      runInAction("load User", () => {
        this.userRegistry.set(user.userId, user);
        this.loadingInitial = false;
      });
    } catch (error) {
      runInAction("load user error", () => {
        this.loadingInitial = false;
      });
      throw error;
    }
    return undefined;
  };

  @action loadUnassignUsers = async (expertGroupId: number) => {
    this.loadingInitial = true;
    try {
      const response = await agent.Users.notAssigned(expertGroupId);
      runInAction("load unassigned User", () => {
        this.unassignedUsers = response.users;
        this.loadingInitial = false;
      });
    } catch (error) {
      runInAction("load unassigned user error", () => {
        this.loadingInitial = false;
      });
      throw error;
    }
    return undefined;
  };

  @action updateUser = async (user: IUserForm) => {
    try {
      this.isSaving = true;
      const response = await agent.Users.update(user);
      runInAction("editing user", () => {
        this.userRegistry.set(response.userId, response);
        this.isSaving = false;
      });
      return response;
    } catch (error) {
      console.log(error);
      this.isSaving = false;
      toast.error("Při ukládání uzivatele nastala chyba!");
      throw error;
    }
  };
}
