import moment from "moment";
import React from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import DateInput from "../../app/common/form/DateInput";
import NullableBooleanSwitchInput from "../../app/common/form/NullableBooleanSwitchInput";
import { ICar, IEmissionCarForm } from "../../app/models/car";
import VinCode from "../vin/VinCode";
import { Form as FinalForm, Field } from "react-final-form";
import { CarStates } from "../../app/models/enum";
interface IProps {
  car: ICar;
  updatedEmissionCars: IEmissionCarForm[];
  setUpdatedEmissionCars: React.Dispatch<
    React.SetStateAction<IEmissionCarForm[]>
  >;
}
const EmissionCarRow: React.FC<IProps> = ({
  car,
  updatedEmissionCars,
  setUpdatedEmissionCars,
}) => {
  const handleFinalFormSubmit = (form: IEmissionCarForm) => {
    const updatedCars = [
      ...updatedEmissionCars.filter((c) => c.carId !== car.carId),
      form,
    ];
    setUpdatedEmissionCars(updatedCars);
  };
  const isEditable =
    car.carStateId === CarStates.Registered ||
    car.carStateId === CarStates.InTestroom;
  const updatedCar = updatedEmissionCars.find((c) => c.carId === car.carId);
  return (
    <FinalForm
      onSubmit={handleFinalFormSubmit}
      initialValues={{
        carId: car.carId,
        emissionTest: updatedCar ? updatedCar.emissionTest : car.emissionTest,
        emissionTestPlanFrom: updatedCar
          ? updatedCar.emissionTestPlanFrom
          : car.emissionTestPlanFrom,
        emissionTestPlanTo: updatedCar
          ? updatedCar.emissionTestPlanTo
          : car.emissionTestPlanTo,
      }}
      mutators={{
        deleteDates: (args, state, utils) => {
          utils.changeValue(state, "emissionTestPlanFrom", () => undefined);
          utils.changeValue(state, "emissionTestPlanTo", () => undefined);
        },
      }}
      validate={(values: IEmissionCarForm) => {
        const errors = {} as any;
        if (values.emissionTestPlanFrom && values.emissionTestPlanTo) {
          if (
            new Date(values.emissionTestPlanFrom) >
            new Date(values.emissionTestPlanTo)
          ) {
            errors.emissionPlannedTo = "Datum do musí být větší než datum od";
            errors.emissionPlannedFrom = "Datum od musí být menší než datum do";
          }
        }

        return errors;
      }}
      render={({ values, form }) => {
        return (
          <tr key={car.carId}>
            <td className="center">
              <div>{car.integrationNum}</div>
              <div className="text-font-light text-small">
                {car.copsVDSNumber}
              </div>
            </td>
            <td>
              <VinCode
                vin={car.vin}
                important={car.vipCar || !!car.importantCarDesc}
                breakLine={true}
              />
            </td>
            <td>{car.logisticNum}</td>
            <td>{car.carState}</td>

            <td>
              <Field
                name={`emissionTest`}
                size="sm"
                inputOnChange={(e: any) => {
                  if (e === false) form.mutators.deleteDates();
                  form.submit();
                }}
                disabled={!isEditable}
                component={NullableBooleanSwitchInput}
              />
            </td>
            <td className="date-col-sm">
              <Field
                name={`emissionTestPlanFrom`}
                format={(val) => (val ? moment(val).toDate() : undefined)}
                parse={(val) => (val ? moment(val).toDate() : undefined)}
                size="sm"
                inputOnChange={form.submit}
                component={DateInput}
                disabled={!isEditable || !values.emissionTest}
              />
            </td>
            <td className="date-col-sm">
              <Field
                name={`emissionTestPlanTo`}
                format={(val) => (val ? moment(val).toDate() : undefined)}
                parse={(val) => (val ? moment(val).toDate() : undefined)}
                size="sm"
                inputOnChange={form.submit}
                component={DateInput}
                disabled={!isEditable || !values.emissionTest}
              />
            </td>
            <td>{car.customerCode}</td>
            <td style={{maxWidth:"5rem"}}>{car.modelName}</td>
            <td  style={{maxWidth:"5rem"}}>{car.engineName}</td>
            <td>{car.emissionStandard}</td>
            <td>{car.transmissionCode}</td>
            <td>
              <Button
                as={Link}
                to={`/car/${car.carId}`}
                variant="outline-primary"
              >
                Detail
              </Button>
            </td>
          </tr>
        );
      }}
    />
  );
};

export default EmissionCarRow;
