import React, { useContext } from "react";
import {
  Table,
  Button,
  OverlayTrigger,
  Tooltip,
  Alert,
} from "react-bootstrap";
import { Field } from "react-final-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import TextInput from "../../app/common/form/TextInput";
import { Link } from "react-router-dom";
import WorkerAssignForm from "./WorkerAssignForm";
import Can from "../../authorization/Can";
import UserStatus from "../icons/UserStatus";
import PerformTestsStatus from "../icons/PerformTestsStatus";
import { sortByStatusAndSurname } from "../../app/common/utils/sort";
import { IAddedWorker, IWorker } from "../../app/models/expertGroup";
import { RootStoreContext } from "../../app/stores/rootStore";
import { IUser } from "../../app/models/user";

interface IProps{
edit:boolean;
expertGroupId: number;
loadedWorkers:IWorker[];
addedWorkers:IAddedWorker[];
removeWorker:(workerId:number)=> void;
addWorkers:(users: IUser[])=>void;
}

const WorkersTab:React.FC<IProps> = ({
    loadedWorkers,
    edit,
    expertGroupId,
    removeWorker,
    addedWorkers,
    addWorkers
}) => {
    const rootStore = useContext(RootStoreContext);
    const { openModal } = rootStore.modalStore; 
    const { user } = rootStore.authStore;

    return (
       
        <div>
          <Table size="sm">
            <thead>
              <tr>
                <th>Celé jméno</th>
                <th>Přihlašovací jméno</th>
                <th style={{ width: "12rem" }}>
                  Začátek pracovní doby
                </th>
                <th style={{ width: "12rem" }}>
                  Konec pracovní doby
                </th>
                <th>Status</th>
                <th>Akce</th>
              </tr>
            </thead>
            <tbody>
              {loadedWorkers
                .filter((w) => !w.isDeleted)
                .sort(sortByStatusAndSurname())
                .map((w) => (
                  <tr key={`loadw-${w.rownumber}`}>
                    <td>
                      <Field<number>
                        name={`workers[${w.rownumber}].userId`}
                        initialValue={w.userId}
                        component="input"
                        hidden
                        disabled
                      />

                      {w.fullName}
                    </td>
                    <td>{w.login}</td>
                    <td>
                      <Field<string>
                        name={`workers[${w.rownumber}].workTimeStart`}
                        type="time"
                        component={TextInput}
                        size="sm"
                        disabled={!edit}
                      />
                    </td>
                    <td>
                      <Field<string>
                        name={`workers[${w.rownumber}].workTimeEnd`}
                        type="time"
                        component={TextInput}
                        disabled={!edit}
                        size="sm"
                      />
                    </td>
                    <td>
                      {!w.isActive && (
                        <UserStatus
                          id={w.userId}
                          isActive={w.isActive}
                        />
                      )}
                      {w.isActive && (
                        <PerformTestsStatus
                          id={w.userId}
                          performTests={w.performTests}
                        />
                      )}
                    </td>
                    <td>
                      {edit && (
                        <Button
                          size="sm"
                          variant="outline-danger"
                          onClick={() => removeWorker(w.userId)}
                        >
                          <FontAwesomeIcon icon={faTimes} /> Odebrat
                        </Button>
                      )}
                      {!edit && (
                        <Can
                          roleId={user?.userRoleId}
                          perform="user-detail-page:visit"
                          yes={
                            <Button
                              size="sm"
                              variant="outline-info"
                              as={Link}
                              to={`/user/${w.userId}`}
                            >
                              Detail
                            </Button>
                          }
                        />
                      )}
                    </td>
                  </tr>
                ))}
              {addedWorkers.map((w) => (
                <tr key={`addw-${w.rownumber}`}>
                  <td>
                    <Field<number>
                      name={`addedWorkers[${w.rownumber}].userId`}
                      initialValue={w.userId}
                      component="input"
                      hidden
                      disabled
                    />
                    <Field<number>
                      name={`addedWorkers[${w.rownumber}].rownumber`}
                      initialValue={w.rownumber}
                      component="input"
                      hidden
                      disabled
                    />
                    {w.fullName}
                  </td>
                  <td>{w.login}</td>
                  <td>
                    <Field<string>
                      name={`addedWorkers[${w.rownumber}].workTimeStart`}
                      type="time"
                      component={TextInput}
                      size="sm"
                      disabled={!edit}
                    />
                  </td>
                  <td>
                    <Field<string>
                      name={`addedWorkers[${w.rownumber}].workTimeEnd`}
                      type="time"
                      component={TextInput}
                      size="sm"
                      disabled={!edit}
                    />
                  </td>
                  <td></td>
                  <td>
                    {edit && (
                      <Button
                        size="sm"
                        variant="outline-danger"
                        onClick={() => removeWorker(w.userId)}
                      >
                        <FontAwesomeIcon icon={faTimes} /> Odebrat
                      </Button>
                    )}
                    {!edit && (
                      <Button
                        size="sm"
                        variant="outline-info"
                        as={Link}
                        to={`/user/${w.userId}`}
                      >
                        Detail
                      </Button>
                    )}
                  </td>
                </tr>
              ))}
              {(!loadedWorkers ||
                !loadedWorkers.filter((w) => !w.isDeleted)
                  .length) &&
                (!addedWorkers || !addedWorkers.length) && (
                  <tr>
                    <td colSpan={5}>
                      <Alert variant="info">
                        Odborná skupina nemá přiřazené žádné
                        pracovníky
                      </Alert>
                    </td>
                  </tr>
                )}
              {edit && (
                <tr>
                  <td colSpan={6}>
                    <OverlayTrigger
                      overlay={
                        <Tooltip id={`tooltip-addbutton`}>
                          Přidat pracovníka
                        </Tooltip>
                      }
                    >
                      <Button
                        variant="primary"
                        className="footer__btn-add"
                        onClick={() =>
                          openModal(
                            <WorkerAssignForm
                              expertGroupId={expertGroupId}
                              addedWorkers={addedWorkers}
                              addWorkers={addWorkers}
                            />,
                            {
                              preventClosing: true,
                              title:
                                "Přidání pracovníků do odborné skupiny",
                            }
                          )
                        }
                      >
                        <FontAwesomeIcon icon={faPlus} />
                      </Button>
                    </OverlayTrigger>
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
    )
}

export default WorkersTab
