import "./NavBar.scss";
import React, { useContext } from "react";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { faUser } from "@fortawesome/free-regular-svg-icons";
import {
  faKey,
  faMapMarkerAlt,
  faSearch,
  faSignOutAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { ReactComponent as LogoSkoda } from "../../assets/icons/logo-skoda.svg";
import { RootStoreContext } from "../../app/stores/rootStore";
import { observer } from "mobx-react-lite";
import Can, { checkAuthRule } from "../../authorization/Can";
import { Fragment } from "react";

const NavBar: React.FC = () => {
  const rootStore = useContext(RootStoreContext);
  const { user, logout } = rootStore.authStore;
  // TODO SignalR
  // const {
  //   createHubConnection,
  //   stopHubConnection,
  //   sayHello,
  //   helloCount,
  // } = rootStore;

  // TODO SignalR
  // useEffect(() => {
  //   if(isLoggedIn){createHubConnection();
  //   return () => {
  //     stopHubConnection();
  //   };}
  // }, [createHubConnection, stopHubConnection,isLoggedIn]);
  const canViewCalendarDropdown =
    checkAuthRule(user?.userRoleId, "car-calendar-page:visit") ||
    checkAuthRule(user?.userRoleId, "expert-group-calendar-page:visit");

  return (
    <Navbar expand="lg" variant="light">
      <Navbar.Brand as={NavLink} to="/">
        <LogoSkoda className="navbar-brand-logo" />
        Q-Space
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mr-auto">
          {canViewCalendarDropdown && (
            <NavDropdown title="Kalendář" id="nav-dropdown-calendar">
              <Can
                roleId={user?.userRoleId}
                perform="car-calendar-page:visit"
                yes={
                  <NavDropdown.Item as={NavLink} to="/schedule">
                    Vozy
                  </NavDropdown.Item>
                }
              />
              <Can
                roleId={user?.userRoleId}
                perform="expert-group-calendar-page:visit"
                data={{ userExpertGroupId: user?.expertGroupId }}
                yes={
                  <NavDropdown.Item as={NavLink} to="/scheduleGroup">
                    Odborné skupiny
                  </NavDropdown.Item>
                }
              />
            </NavDropdown>
          )}
          <Can
            roleId={user?.userRoleId}
            perform="cars-page:visit"
            yes={
              <Nav.Link as={NavLink} to="/cars">
                Správa vozů
              </Nav.Link>
            }
          />
          <Can
            roleId={user?.userRoleId}
            perform="emission-cars-page:visit"
            yes={
              <Nav.Link as={NavLink} to="/emissioncars">
                Emisní vozy
              </Nav.Link>
            }
          />
          <Can
            roleId={user?.userRoleId}
            perform="expert-groups-page:visit"
            yes={
              <Nav.Link as={NavLink} to="/expertgroups">
                Odborné skupiny
              </Nav.Link>
            }
          />
          <Can
            roleId={user?.userRoleId}
            perform="users-page:visit"
            yes={
              <Nav.Link as={NavLink} to="/users">
                Pracovníci
              </Nav.Link>
            }
          />
          <Can
            roleId={user?.userRoleId}
            perform="settings-page:visit"
            yes={
              <Nav.Link as={NavLink} to="/settings">
                Nastavení
              </Nav.Link>
            }
          />
        </Nav>
        <Nav className="mr-right">
          <Can
            roleId={user?.userRoleId}
            perform="car-search:visit"
            yes={
              <Nav.Link as={NavLink} to="/carsearch">
                <span>
                  <FontAwesomeIcon icon={faSearch} /> Vyhledat vůz{" "}
                </span>
              </Nav.Link>
            }
          />

          {/* // TODO SignalR 
          <Nav.Link onClick={sayHello}>
            <FontAwesomeIcon
              icon={faCommentAlt}
              className={"icon"}
              title={"Pozdrav ostatní klienty"}
            />
            {!!helloCount && (
               <sup>
                <Badge pill variant="danger">
                  {helloCount}
                </Badge>
               </sup>
            )}
          </Nav.Link> */}
          <NavDropdown
            title={
              <span>
                <FontAwesomeIcon icon={faUser} title="uživatel" />
                {!user && "  Nepřihlášený uživatel"}
                {user && `  ${user.fullName}  `}
                {/* {user &&
                  ` ${user.fullName} (${user.userRoleName}) - ${user.placeName}`} */}
              </span>
            }
            className="nav-dropdown2"
            id="nav-dropdown2"
          >
            {user && (
              <Fragment>
                <NavDropdown.Item disabled className="nav-user-info">
                  <ul>
                    <li>
                      <FontAwesomeIcon
                        icon={faUser}
                        className="nav-user-info-icon"
                      />{" "}
                      {user.fullName}
                    </li>
                    <li>
                      <FontAwesomeIcon
                        icon={faKey}
                        className="nav-user-info-icon"
                      />{" "}
                      {user.userRoleName}
                    </li>
                    <li>
                      <FontAwesomeIcon
                        icon={faMapMarkerAlt}
                        className="nav-user-info-icon"
                      />{" "}
                      {user.placeName}
                    </li>
                  </ul>
                </NavDropdown.Item>
                <NavDropdown.Divider />
              </Fragment>
            )}
            <NavDropdown.Item as={NavLink} to="/login">
              Přihlásit
            </NavDropdown.Item>
            <NavDropdown.Item onClick={logout}>Odhlásit</NavDropdown.Item>
          </NavDropdown>

          {user && (
            <Nav.Link onClick={logout} title={"Odhlásit se"}>
              <FontAwesomeIcon
                icon={faSignOutAlt}
                className={"icon icon--primary"}
              />
            </Nav.Link>
          )}
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default observer(NavBar);
