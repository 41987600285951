import React, { Fragment, useState, useContext } from "react";
import { Row, Button } from "react-bootstrap";
import { Field, Form as FinalForm } from "react-final-form";
import { Table } from "react-bootstrap";
import { observer } from "mobx-react-lite";
import moment from "moment";
import Loader from "../../loader/Loader";
import Pages from "../../../app/common/utils/Pages";
import AlertMessage from "../../alert/AlertMessage";
import SelectInput from "../../../app/common/form/SelectInput";
import {toast} from "react-toastify";
import Can from "../../../authorization/Can";
import {RootStoreContext} from "../../../app/stores/rootStore";
import {usePrNumFamiliesQuery, usePrNumFamilyMutation, useLoadEnumsQuery} from "../../../app/api/client";
import PrFamiliesSearchBox from "./PrFamiliesSearchBox"
import { IPrFamilyForm } from "../../../app/models/prNumber"

const PrNumbersFamiliesTab: React.FC = () => {

    const rootStore = useContext(RootStoreContext);
    const { user } = rootStore.authStore;
    const [search, setSearch] = useState<string>();
    const [prNumCategoryId, setPrNumCategoryId ] = useState<number | undefined>();
    const [pageNumber, setPageNumber] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(20);
    const cancelSearch = search !== undefined || prNumCategoryId !== undefined;
    const [editedItemId, setEditedItemId] = useState<number | undefined>();
    const [ errors, setErrors ] = useState([]);
    const [ showSubmitError, setShowSubmitError ] = useState(false);

    const {data: enums, isFetching: loadingEnums} = useLoadEnumsQuery();
    const {
        data: prNumFamiliesList,
        isFetching: isLoading,
        refetch: refetchPrNumFamilies
    } = usePrNumFamiliesQuery(pageNumber, pageSize, search, prNumCategoryId);
    const { mutateAsync: prNumFamilyMutation, isLoading: isMutating } = usePrNumFamilyMutation();

    const LoadPrNumFamilies = (page: number)=> {
        setPageNumber(page);
        refetchPrNumFamilies();
    };

    const searchPrNumFamilies = (_search?: string, _prNumCategoryId?: number) => {
        setSearch(_search);
        setPrNumCategoryId(_prNumCategoryId);
        setPageNumber(1);
    };

    const cancelSearchPrNumFamilies = () => {
        setSearch(undefined);
        setPrNumCategoryId(undefined);
        setPageNumber(1);
    }

    const handleEditPrFamily = (form: IPrFamilyForm) => {

        setErrors([]);
        setShowSubmitError(false);
  
        prNumFamilyMutation(form)
            .then((response) => {
                //console.log(form);
                setEditedItemId(undefined);
                LoadPrNumFamilies(pageNumber);
                toast.success(`PR rodina byla uložena`);
            })
            .catch((error) => {
                console.log(error);
                if(error.response !== undefined && error.response.data !== undefined && error.response.data.errors !== undefined){
                    setErrors(Object.values(error.response?.data?.errors));
                    setShowSubmitError(true);
                }
                toast.error(`Vyskytla se chyba při uložení PR rodiny`);
            });
    };

    if (loadingEnums || isLoading || isMutating) return <Loader/>;

    return (
        <Fragment>
            <Row className="pageheader">
                <div className="pageheader__left col-sm-6 vertical-align">
                    <h3>Rodiny PR čísel</h3>
                </div>
                <div className="pageheader__right col-sm-6">
                    <PrFamiliesSearchBox search={search}
                                         searchPrNumCategoryId={prNumCategoryId}
                                         cancelSearch={cancelSearch}
                                         prNumCategories={enums?.prNumberCategories}
                                         handleSearch={searchPrNumFamilies}
                                         handleCancelSearch={cancelSearchPrNumFamilies}
                     />
                </div>
            </Row>
            <div>
                <AlertMessage
                    type="danger"
                    heading="Nastala chyba při ukládání"
                    show={showSubmitError}
                    setShow={setShowSubmitError}
                    messageList={errors}
                />
                <Table>
                    <thead>
                        <tr>
                            <th style={{maxWidth:"4rem"}}>PR rodiny</th>
                            <th style={{maxWidth:"16rem"}}>Název rodiny</th>
                            <th style={{minWidth:"16rem"}}>Kategorie</th>
                            <th colSpan={2}>Aktualizace</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                    {prNumFamiliesList && prNumFamiliesList?.prNumFamilies && prNumFamiliesList.prNumFamilies.map((prNumFamily, index) => 
                    (
                        <tr key={prNumFamily.prFamilyId} className={ editedItemId === prNumFamily.prFamilyId ? "active show-btn-on-hover" : "show-btn-on-hover"}>

                            {editedItemId === prNumFamily.prFamilyId && (
                                    <FinalForm
                                    onSubmit={handleEditPrFamily}
                                    render={({ handleSubmit }) => (
                                        <Fragment>
                                        <td style={{maxWidth:"4rem"}}>
                                            <Field<number>
                                                name={`prFamilyId`}
                                                initialValue={prNumFamily.prFamilyId}
                                                component="input"
                                                hidden
                                                disabled
                                            />
                                            {prNumFamily.prFamilyCode}
                                        </td>
                                        <td style={{maxWidth:"16rem"}}>{prNumFamily.text}</td>
                                        <td style={{minWidth:"16rem"}}>
                                        {enums?.prNumberCategories && 
                                            <Field
                                                name="prNumCategoryId"
                                                options={enums.prNumberCategories.map((e) => ({
                                                    key: e.id,
                                                    value: e.name,
                                                }))}
                                                initialValue={prNumFamily.prNumCategoryId}
                                                isEmptyOption={true}
                                                component={SelectInput}
                                                parse={(value) => (value ? parseInt(value) : undefined)}
                                            />
                                        }
                                        </td>
                                        <td>{moment(prNumFamily.updDateCs).format("DD.MM.YYYY")}</td>

                                        <td colSpan={2} className="td-fixed-width">
                                        <Can
                                            roleId={user?.userRoleId}
                                            perform="settings-prNumbers:edit"
                                            yes={
                                            <Fragment>
                                                <Button
                                                    type="submit"
                                                    size="sm"
                                                    onClick={handleSubmit}
                                                    style={{ marginRight: "0.1rem" }}
                                                    >
                                                    {"Ulož"}
                                                </Button>
                                                <Button
                                                    type="button"
                                                    size="sm"
                                                    onClick={() => setEditedItemId(undefined)}
                                                    variant="outline-secondary"
                                                    >
                                                    {"Zpět"}
                                                </Button>   
                                            </Fragment>
                                            }
                                        />
                                            
                                        </td>
                                        </Fragment>
                                    )}
                                    />
                                )}

                            {editedItemId !== prNumFamily.prFamilyId && (
                                    <Fragment>
                                        <td style={{maxWidth:"4rem"}}>{prNumFamily.prFamilyCode}</td>
                                        <td style={{maxWidth:"16rem"}}>{prNumFamily.text}</td>
                                        <td style={{minWidth:"16rem"}}>{prNumFamily.prNumCategoryName}</td>
                                        <td>{moment(prNumFamily.updDateCs).format("DD.MM.YYYY")}</td>
                                        <td colSpan={2} className="td-fixed-width">
                                            <Can
                                                roleId={user?.userRoleId}
                                                perform="settings-prNumbers:edit"
                                                yes={    
                                                    <Button
                                                        className="btn-show-on-hover"
                                                        onClick={() => setEditedItemId(prNumFamily.prFamilyId)}
                                                        type="button"
                                                        size="sm"
                                                        variant="outline-primary"
                                                    >
                                                        {"Upravit"}
                                                    </Button>
                                                }
                                            />
                                        </td>
                                    </Fragment>
                            )}
                        </tr>
                    ))}
                    </tbody>
                </Table>
            </div>  
            {prNumFamiliesList && 
                <footer className="footer">
                    <div className="footer-left">
                    </div>
                    <div className="footer-right">
                    <Pages
                        totalPages={prNumFamiliesList.totalPages}
                        currentPage={prNumFamiliesList.currentPage}
                        onClickEvent={LoadPrNumFamilies}
                    />
                    </div>
                </footer>
            }
        </Fragment>

    );
};

export default observer(PrNumbersFamiliesTab);