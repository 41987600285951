import "./MainProtocol.scss";
import React, {Fragment, useContext, useEffect, useState} from "react";
import {RouteComponentProps} from "react-router-dom";
import {RootStoreContext} from "../../../app/stores/rootStore";
import {observer} from "mobx-react-lite";
import {IMainProtocol} from "../../../app/models/protocol";
import logo from "../../../assets/images/Skoda_Wordmark_RGB_Emerald_Green.svg";
import moment from "moment";
import {Button, Col, Row, Table} from "react-bootstrap";
import {DATE_FORMAT, defectStates} from "../../../app/common/utils/constants";
import LabelValuePair from "../../../app/common/utils/LabelValuePair";
import VinCode from "../../vin/VinCode";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPrint} from "@fortawesome/free-solid-svg-icons";

interface MainProtocolParams {
  carId: string;
  carProtocolId: string;
}

const MainProtocol: React.FC<RouteComponentProps<MainProtocolParams>> = ({
  match,
}) => {
  const rootStore = useContext(RootStoreContext);
  const { loadMainProtocol, mainProtocolRegistry, isLoading } =
    rootStore.protocolStore;
  const [mainProtocol, setMainProtocol] = useState<IMainProtocol | undefined>(
    undefined
  );

  useEffect(() => {
    const carId = Number(match.params.carId);
    const carProtocolId = Number(match.params.carProtocolId);

    loadMainProtocol(carId, carProtocolId).then(() => {
      setMainProtocol(mainProtocolRegistry.get(carId));
    });
    console.log(mainProtocol);
  }, [
    match.params.carId,
    match.params.carProtocolId,
    loadMainProtocol,
    mainProtocolRegistry,
  ]);

  const labelWidth = 4;
  const valueWidth = 8;
  return (
    <div
      id="MainProtocol"
      className="container"
      style={{ marginBottom: "3rem" }}
    >
      <div className="printheader">
        <div className="printheader__left">
          <div className="printheader--image">
            <img src={logo} height={80} alt='logo'/>
          </div>
        </div>
        <div className="printheader__right">
          <div className="text--header">Celkový zkouškový protokol</div>
          <div className="text--text">
            <span>{mainProtocol?.placeCode} </span> <span>{mainProtocol?.placeDescription}</span>
          </div>
        </div>
      </div>

      <div className="printfooter">
        <table>
          <tr className="printfooter__header">
            <td>Název dokumentu</td>
            <td>Ev. č.</td>
            <td>Způsob uložení</td>
            <td>Skartační znak</td>
            <td>Spouštěcí událost pro skartaci</td>
            <td>Klasifikace informací</td>
            <td>Místo uložení</td>
            <td>Č. stránky</td>
          </tr>
          <tr>
            <td>{mainProtocol?.documentName}</td>
            <td>{mainProtocol?.registrationNumber}</td>
            <td>{mainProtocol?.storageMethod}</td>
            <td>{mainProtocol?.shreddingSign}</td>
            <td>{mainProtocol?.shreddingTriggerEvent}</td>
            <td>{mainProtocol?.classification}</td>
            <td>{mainProtocol?.storageLocation}</td>
            <td>1/1</td>
          </tr>
        </table>
      </div>
      <table className="table-page-content">
        <thead>
          <tr>
            <td>
              {/* <!--place holder for the fixed-position header--> */}
              <div className="page-header-space"></div>
            </td>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td>
              <div className="pageheader  no-print">
                <div className="pageheader__left">
                  <h1>Celkový zkouškový protokol</h1>
                  <h3>{mainProtocol?.placeCode} {mainProtocol?.placeDescription}</h3>
                </div>
                <div className="pageheader__right">
                  <Button variant="outline-info" onClick={() => window.print()}>
                    <FontAwesomeIcon icon={faPrint} title="Tisk" /> Tisk
                  </Button>
                </div>
              </div>
              <div className="main-protocol-header">
                {!isLoading && (
                  <Row>
                    <Col sm={4}>
                      <LabelValuePair
                        label="Typ vozu"
                        value={
                          (mainProtocol?.car.modelVdsCode || "") +
                          " " +
                          (mainProtocol?.car.modelName || "")
                        }
                        labelColSize={labelWidth}
                        valueColSize={valueWidth}
                      />
                      <LabelValuePair
                        label="VIN kód"
                        value={<VinCode vin={mainProtocol?.car.vin} />}
                        labelColSize={labelWidth}
                        valueColSize={valueWidth}
                      />
                      <LabelValuePair
                        label="Log. číslo"
                        value={mainProtocol?.car.logisticNum}
                        labelColSize={labelWidth}
                        valueColSize={valueWidth}
                      />
                      <LabelValuePair
                        label="SOP"
                        value={mainProtocol?.car.startOfProduction}
                        labelColSize={labelWidth}
                        valueColSize={valueWidth}
                      />
                    </Col>
                    <Col sm={4}>
                      <LabelValuePair
                        label="Barva"
                        value={mainProtocol?.car.colorCode}
                        labelColSize={labelWidth}
                        valueColSize={valueWidth}
                      />
                      <LabelValuePair
                        label="Motor"
                        value={mainProtocol?.car.engineName}
                        labelColSize={labelWidth}
                        valueColSize={valueWidth}
                      />
                      <LabelValuePair
                        label="Převodovka"
                        value={mainProtocol?.car.transmissionCode}
                        labelColSize={labelWidth}
                        valueColSize={valueWidth}
                      />
                      <LabelValuePair
                        label="Emisní norma"
                        value={mainProtocol?.car.emissionStandard}
                        labelColSize={labelWidth}
                        valueColSize={valueWidth}
                      />
                    </Col>
                    <Col sm={4}>
                      <LabelValuePair
                        label="Stav vozu"
                        value={mainProtocol?.car.testDestinationCode}
                        labelColSize={labelWidth}
                        valueColSize={valueWidth}
                      />
                      <LabelValuePair
                        label="Odběratel"
                        value={mainProtocol?.car.customerCode}
                        labelColSize={labelWidth}
                        valueColSize={valueWidth}
                      />
                      <LabelValuePair
                        label="Typ zkoušky"
                        value={mainProtocol?.car.testTypeName}
                        labelColSize={labelWidth}
                        valueColSize={valueWidth}
                      />
                      <LabelValuePair
                        label="Datum na zkušebně"
                        value={`${moment(
                          mainProtocol?.car.availableFrom
                        ).format(DATE_FORMAT)} - ${moment(
                          mainProtocol?.car.availableTo
                        ).format(DATE_FORMAT)}`}
                        labelColSize={labelWidth}
                        valueColSize={valueWidth}
                      />
                    </Col>
                  </Row>
                )}
              </div>
              <div className="main-protocol-results">
                <Table size="sm">
                  <thead>
                    <tr>
                      <th>Datum</th>
                      <th>Zkouška</th>
                      <th>Reklamuje</th>
                      <th>Známka</th>
                      <th>Závada</th>
                      <th colSpan={2}>Analýza / Opatření / Termín</th>
                      <th>Zodpovídá</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {mainProtocol?.defects.map((defect) => (
                      <Fragment>
                        <tr>
                          <td rowSpan={3}>
                            {moment(defect.carTestDate).format(DATE_FORMAT)}
                          </td>
                          <td rowSpan={3}>{defect.expertGroupCode}</td>
                          <td rowSpan={3}>{defect.assignedWorkers}</td>
                          <td rowSpan={3}>{defect.signDefect}</td>
                          <td rowSpan={3}>{defect.description}</td>
                          <td>A:</td>
                          <td>{defect.analysis}</td>
                          <td rowSpan={3}>{defect.responsible}</td>
                          <td rowSpan={3}>
                            {
                              defectStates.find((x) => x.key === defect.state)
                                ?.value
                            }
                          </td>
                        </tr>
                        <tr>
                          <td>O:</td>
                          <td>{defect.remedy}</td>
                        </tr>
                        <tr>
                          <td>T:</td>
                          <td>{defect.deadline}</td>
                        </tr>
                      </Fragment>
                    ))}
                  </tbody>
                </Table>
              </div>
              <div className="main-protocol-footer">
                <Table size="sm">
                  <thead>
                    <tr>
                      <th>Provedené zkoušky</th>
                      <th>Vedoucí odborné skupiny</th>
                    </tr>
                  </thead>
                  <tbody>
                    {mainProtocol?.carTests.map((carTest) => (
                      <tr>
                        <td>{carTest.expertGroupName}</td>
                        <td>{carTest.expertGroupLeader}</td>
                      </tr>
                    ))}
                  </tbody>
                  <tfoot>
                    <td colSpan={2}>
                      ŠKODA AUTO a.s., Tř. Václava Klementa 869, 293 01 Mladá
                      Boleslav II., Česká republika
                    </td>
                  </tfoot>
                </Table>
              </div>
            </td>
          </tr>
        </tbody>

        <tfoot>
          <tr>
            <td>
              <div className="page-footer-space"></div>
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  );
};

export default observer(MainProtocol);
