import React, { Fragment, useContext, useState } from "react";
import { Table, Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Field } from "react-final-form";
import {
  IProtocolArea,
  IProtocolExpandingValue,
} from "../../../../app/models/protocol";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTimes } from "@fortawesome/free-solid-svg-icons";
import TextInput from "../../../../app/common/form/TextInput";
import CheckboxInput from "../../../../app/common/form/CheckboxInput";

interface IProps {
  isEdit: boolean;
  flsahParams: IProtocolExpandingValue[];
  flsahParamsArea: IProtocolArea;
  isDisabled: boolean;
  handleAddFlashParam: () => void;
  handleRemoveFlashParam: (rowNumber: number | undefined) => void;
}

const FlashParamsArea: React.FC<IProps> = ({
  isEdit,
  flsahParams,
  flsahParamsArea,
  isDisabled,
  handleAddFlashParam,
  handleRemoveFlashParam,
}) => {
  return (
    <div style={{ pageBreakBefore: "always" }}>
      <Table className="protocol-table flash-break">
        <thead>
          <tr>
            <th className="headingrow" colSpan={10}>
              <h2>{flsahParamsArea.name}</h2>
            </th>
            <th
              className={`headingrow ${isDisabled ? "" : "no-print"}`}
              colSpan={2}
            >
              <Field
                name={`flashParamsArea.notPerform`}
                title="Neprovedeno"
                options={[
                  { key: 1, value: "ano" },
                  { key: 0, value: " ne " },
                ]}
                format={(v) => (v ? 1 : 0)}
                parse={(v) => (v ? true : false)}
                type={"checkbox"}
                initialValue={flsahParamsArea?.notPerform || false}
                component={CheckboxInput}
                disabled={!isEdit}
                labelWidth={8}
                valueWidth={4}
              />
            </th>
          </tr>
        </thead>
      </Table>
      <Table className={`protocol-table ${isDisabled ? "no-print" : ""}`}>
        <thead>
          <tr className={`${isDisabled ? "disabled" : ""}`}>
            <th className="labelrow">
              <h4>Řídící jednotka</h4>
              <small>Adresa/název</small>
            </th>
            <th className="labelrow">
              <h4>Starý stav</h4>
              <small>HW/SW</small>
            </th>
            <th className="labelrow">
              <h4>Nový stav</h4>
              <small>HW/SW</small>
            </th>
            <th className="labelrow">
              <h4>Parametry kódování</h4>
            </th>
            {isEdit && !isDisabled && <th></th>}
          </tr>
        </thead>
        <tbody>
          {flsahParams
            ?.filter((p) => !p.isDeleted)
            .map((param, rowIndex) => (
              <tr
                key={`fp-${rowIndex}`}
                className={`${isDisabled ? "disabled" : ""}`}
              >
                <td>
                  <Field<string>
                    name={`flashParamsArea.flashParams[${param.rownumber}].controlUnit`}
                    initialValue={param.controlUnit}
                    component={TextInput}
                    rows={1}
                    size="sm"
                    disabled={!isEdit || isDisabled}
                  />
                </td>
                <td>
                  <Field<string>
                    name={`flashParamsArea.flashParams[${param.rownumber}].originalState`}
                    initialValue={param.originalState}
                    component={TextInput}
                    rows={1}
                    size="sm"
                    disabled={!isEdit || isDisabled}
                  />
                </td>
                <td>
                  <Field<string>
                    name={`flashParamsArea.flashParams[${param.rownumber}].newState`}
                    initialValue={param.newState}
                    component={TextInput}
                    rows={1}
                    size="sm"
                    disabled={!isEdit || isDisabled}
                  />
                </td>
                <td>
                  <Field<string>
                    name={`flashParamsArea.flashParams[${param.rownumber}].codingParams`}
                    initialValue={param.codingParams}
                    component={TextInput}
                    rows={1}
                    size="sm"
                    disabled={!isEdit || isDisabled}
                  />
                </td>
                {isEdit && !isDisabled && (
                  <td style={{ textAlign: "center" }}>
                    <Button
                      size="sm"
                      variant="outline-danger"
                      onClick={() => handleRemoveFlashParam(param.rownumber)}
                    >
                      <FontAwesomeIcon icon={faTimes} /> Odebrat
                    </Button>
                  </td>
                )}
              </tr>
            ))}
          {isEdit && !isDisabled && (
            <tr>
              <td colSpan={4}></td>
              <td style={{ textAlign: "center" }}>
                <OverlayTrigger
                  overlay={
                    <Tooltip id={`tooltip-addbutton`}>Přidat parametry</Tooltip>
                  }
                >
                  <Button
                    variant="primary"
                    onClick={() => handleAddFlashParam()}
                    className="footer__btn-add"
                  >
                    <FontAwesomeIcon icon={faPlus} />
                  </Button>
                </OverlayTrigger>
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </div>
  );
};

export default FlashParamsArea;
