import React, { useContext } from "react";
import { Field } from "react-final-form";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { IEnumItem, IExpertGroupEnumItem } from "../../../app/models/enum";
import { ICarTestManuallyAdded } from "../../../app/models/carTest";
import SelectInput from "../../../app/common/form/SelectInput";
import NumberInput from "../../../app/common/form/NumberInput";
import TextAreaInput from "../../../app/common/form/TextAreaInput";
import { RootStoreContext } from "../../../app/stores/rootStore";

interface IProps {
  rownumberId: number;
  carTest: ICarTestManuallyAdded;
  edit: boolean;
  expertGroups: IExpertGroupEnumItem[];
  testStates: IEnumItem[];
  deleteTest: (rownumber: number) => void;
}

const CarTestRowForm: React.FC<IProps> = ({
  rownumberId,
  edit,
  deleteTest,
  expertGroups,
  testStates,
}) => {

  const rootStore = useContext(RootStoreContext);
  const { user } = rootStore.authStore;
  const groups = expertGroups.filter(x => x.placeId == user?.placeId);

  return (
    <tr>
      <td>
        <Field<number>
          name={`carTest[${rownumberId}].expertGroupId`}
          initialValue={groups[0] ? groups[0].key : undefined}
          options={groups}
          component={SelectInput}
          isEmptyOption={false}
          parse={(value) => value && parseInt(value)}
          disabled={!edit}
        />
      </td>
      <td>
        <Field<number>
          name={`carTest[${rownumberId}].testStateId`}
          initialValue={1}
          options={testStates}
          component={SelectInput}
          isEmptyOption={false}
          parse={(value) => value && parseInt(value)}
          disabled={true}
        />
      </td>
      <td>
        <Field<number>
          name={`carTest[${rownumberId}].length`}
          initialValue={0}
          rightAppend="hodin"
          min={0}
          size="sm"
          component={NumberInput}
          parse={(value) => value && parseInt(value)}
          disabled={!edit}
        />
      </td>

      <td colSpan={3}>
        <Field<string>
          name={`carTest[${rownumberId}].description`}
          component={TextAreaInput}
          placeholder="Popis zkoušky"
          rows={1}
          size="sm"
          disabled={!edit}
        />
      </td>
      <td>
        <Button
          variant="link"
          size="sm"
          onClick={() => deleteTest(rownumberId)}
          title="Smazat zkoušku"
        >
          <FontAwesomeIcon icon={faTrash} />
        </Button>
      </td>
    </tr>
  );
};

export default CarTestRowForm;
