import React, { useContext } from "react";
import { Table, Alert, Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Field } from "react-final-form";
import { IEnums, CarStates } from "../../../app/models/enum";
import { ICar, NewCar } from "../../../app/models/car";
import {
  ICarTestAutoAssign,
  ICarTestManuallyAdded,
  ICarTest,
} from "../../../app/models/carTest";
import AlertMessage from "../../alert/AlertMessage";
import { getHoursString } from "../../../app/common/utils/czechDeclension";
import SelectInput from "../../../app/common/form/SelectInput";
import { sortByStateAndStartAt } from "../../../app/common/utils/sort";
import CarTestCollisions from "../../icons/CarTestCollisions";
import moment from "moment";
import { DATE_TIME_FORMAT } from "../../../app/common/utils/constants";
import { Link } from "react-router-dom";
import CarTestRowForm from "./CarTestRowForm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import Can from "../../../authorization/Can";
import { RootStoreContext } from "../../../app/stores/rootStore";
import PlaceBadge from "../../icons/PlaceBadge";

interface IProps {
  enums: IEnums | undefined;
  edit: boolean;
  values: ICar | NewCar;
  showAssignCarTestError: boolean;
  setShowAssignCarTestError: (show: boolean) => void;
  autoAssignCarTests: ICarTestAutoAssign[];
  manuallyCreatedCarTests: ICarTestManuallyAdded[];
  loadedCarTests: ICarTest[];
  handleDeleteManuallyAddedCarTest: (rownumber: number) => void;
  handleAddCarTestManually: () => void;
  loadTestsFromTestDefinitions: (
    testTypeId: number | undefined,
    testDestinationId: number | undefined,
    testPredestinationId: number | undefined
  ) => void;
}

const CarTestsTab: React.FC<IProps> = ({
  enums,
  edit,
  values,
  showAssignCarTestError,
  autoAssignCarTests,
  manuallyCreatedCarTests,
  loadedCarTests,
  setShowAssignCarTestError,
  handleDeleteManuallyAddedCarTest,
  handleAddCarTestManually,
  loadTestsFromTestDefinitions,
}) => {
  const rootStore = useContext(RootStoreContext);
  const { user } = rootStore.authStore;
  return (
    <div>
      <AlertMessage
        type="danger"
        heading="Nastala chyba"
        show={showAssignCarTestError}
        setShow={setShowAssignCarTestError}
        message="Nelze doplnit zkoušky. Pro tuto kombinaci typu vozu, typu zkoušky a podurčení zkoušky nejsou žádné zkoušky definovány."
      />

      <Table size="sm">
        <thead>
          <tr>
            <th>Odborná skupina</th>
            <th style={{ width: "10rem" }}>Stav zkoušky</th>
            <th style={{ width: "8rem" }}>Délka zkoušky</th>
            <th style={{ width: "15rem" }}>Popis zkoušky</th>
            <th>Začátek zkoušky</th>
            <th>Konec zkoušky</th>
            <th>Akce</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {(!autoAssignCarTests || autoAssignCarTests.length === 0) &&
            (!manuallyCreatedCarTests ||
              manuallyCreatedCarTests.length === 0) &&
            (!loadedCarTests || loadedCarTests.length === 0) && (
              <tr>
                <td colSpan={7}>
                  <Alert variant="info">
                    Vůz nemá přiřazené žádné zkoušky.
                  </Alert>
                </td>
              </tr>
            )}
          {autoAssignCarTests &&
            autoAssignCarTests.length > 0 &&
            autoAssignCarTests.map((carTest, index) => (
              <tr key={index}>
                <td>{carTest.expertGroupName}</td>
                <td>
                  <Field<number>
                    name={`carTestAutoAsign[${index}].testStateId`}
                    initialValue={carTest.testStateId}
                    options={enums?.testStates.filter((ts) =>
                      [1, 4].includes(ts.key)
                    )}
                    component={SelectInput}
                    isEmptyOption={false}
                    parse={(value) => value && parseInt(value)}
                    disabled={!edit}
                  />
                </td>
                <td>
                  {carTest.length} {getHoursString(carTest.length)}
                </td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            ))}
          {loadedCarTests &&
            loadedCarTests
              .slice()
              .sort(sortByStateAndStartAt())
              .map((loadedCarTest) => (
                <tr key={loadedCarTest.carTestId}>
                  <td>
                    <div className="flex-column">
                      <div>{loadedCarTest.expertGroupName}</div>
                      <div>
                        {loadedCarTest.placeId !== user?.placeId && (
                          <PlaceBadge name={enums?.places.find(
                            (x) => x.key === loadedCarTest.placeId
                          )?.code ||""}
                          description={"Zkouška je evidována na zkušebně "}
                          />                          
                        )}
                      </div>
                    </div>
                  </td>
                  <td>
                    <div>{loadedCarTest.testStateName}</div>{" "}
                    <div style={{ marginLeft: "1.5rem" }}>
                      {!!loadedCarTest.carTestConflicts.length && (
                        <CarTestCollisions
                          id={loadedCarTest.carTestId}
                          conflictTypes={[
                            ...new Set(
                              loadedCarTest.carTestConflicts.map(
                                (item) => item.conflictName
                              )
                            ),
                          ]}
                        />
                      )}
                    </div>
                  </td>
                  <td>
                    {loadedCarTest.length}{" "}
                    {getHoursString(loadedCarTest.length)}
                  </td>
                  <td>{loadedCarTest.description}</td>
                  <td>
                    {loadedCarTest.startAt &&
                      moment(loadedCarTest.startAt).format(DATE_TIME_FORMAT)}
                  </td>
                  <td>
                    {loadedCarTest.endAt &&
                      moment(loadedCarTest.endAt).format(DATE_TIME_FORMAT)}
                  </td>
                  <td>
                    {!edit && (
                      <Can
                        roleId={user?.userRoleId}
                        perform="cartest-detail-page:visit"
                        yes={
                          <Button
                            variant="outline-info"
                            size="sm"
                            key={"CarTest" + loadedCarTest.carTestId}
                            as={Link}
                            to={`/carTest/${loadedCarTest.carTestId}`}
                          >
                            Detail
                          </Button>
                        }
                      />
                    )}
                  </td>
                  <td>
                  {!!loadedCarTest.protocols.length && (
                    loadedCarTest.protocols.map((protocol) => 
                        <Button
                          variant="outline-info"
                          key={"Protocol_" + protocol.protocolTypeId}
                          size="sm"
                          as={Link}
                          to={protocol.url}
                        >
                          Protokol - {protocol.protocolTypeName}
                        </Button>
                    ))}
                  </td>
                </tr>
              ))}
          {manuallyCreatedCarTests.map((carTest) => (
            <CarTestRowForm
              key={carTest.rownumber}
              rownumberId={carTest.rownumber}
              carTest={carTest}
              edit={edit}
              expertGroups={enums!.expertGroups}
              testStates={enums!.testStates}
              deleteTest={handleDeleteManuallyAddedCarTest}
            />
          ))}
          {(edit && ![CarStates.OutOfTestroom].includes(values.carStateId) ) && (
            <tr>
              <td colSpan={7}>
                <OverlayTrigger
                  overlay={
                    <Tooltip id={`tooltip-addbutton`}>Přidat zkoušku</Tooltip>
                  }
                >
                  <Button
                    variant="primary"
                    onClick={() => handleAddCarTestManually()}
                    className="footer__btn-add"
                  >
                    <FontAwesomeIcon icon={faPlus} />
                  </Button>
                </OverlayTrigger>

                {loadedCarTests.length < 1 &&
                autoAssignCarTests.length < 1 &&
                values.testTypeId &&
                values.testDestinationId &&
                values.testPredestinationId ? (
                  <Button
                    variant="info"
                    className="footer__btn-add"
                    style={{ marginLeft: "1rem" }}
                    onClick={() =>
                      loadTestsFromTestDefinitions(
                        values.testTypeId,
                        values.testDestinationId,
                        values.testPredestinationId
                      )
                    }
                    type="button"
                  >
                    Doplň zkoušky
                  </Button>
                ) : (
                  ""
                )}
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </div>
  );
};

export default CarTestsTab;
