import React, {FC} from 'react';
import {IAcousticsProtocol, IAcousticsProtocolExecutedTestArea, IProtocolCodeTableValues} from "../../../../app/models/protocol";
import {Col, Container, Row} from 'react-bootstrap';
import {observer} from "mobx-react-lite";
import {DrivingAssessment} from "./DrivingAssessment";
import OnSiteAssessment from "./OnSiteAssessment";
import {Field, useFormState} from "react-final-form";
import CheckboxInput from "../../../../app/common/form/CheckboxInput";
import {FormApi} from "final-form";

interface IProps {
    executedTestArea: IAcousticsProtocolExecutedTestArea;
    protocolCodeTableValues: IProtocolCodeTableValues[];
    isEdit: boolean;
}

const ExecutedTestArea: FC<IProps> = (
    {
        executedTestArea,
        protocolCodeTableValues,
        isEdit
    }
) => {
    const formState = useFormState<IAcousticsProtocol>();
    const notPerform = formState.values.executedTestArea.notPerform;
    const drivingAssessmentGroup = (formState.values.executedTestArea.drivingAssessmentGroup);
    const onSiteAssessmentLowestGroup = (formState.values.executedTestArea.onSiteAssessmentGroup);
    const disabledStyle = notPerform ? ' protocol-area--disabled' : '';

    return <div className={`protocol-area`}>
        <Row noGutters >
            <Col sm={10}>
                <h4>{executedTestArea.name}</h4>
            </Col>
            <Col className={''}>
                <Field
                    name={`executedTestArea.notPerform`}
                    title="Neprovádět"
                    options={[
                        {key: 1, value: "ano"},
                        {key: 0, value: " ne "},
                    ]}
                    format={(v) => (v ? 1 : 0)}
                    parse={(v) => (!!v)}
                    type={"checkbox"}
                    initialValue={executedTestArea.notPerform || false}
                    component={CheckboxInput}
                    disabled={!isEdit}
                    labelWidth={8}
                    valueWidth={4}
                />
            </Col>
        </Row>
        <Container className={` ${disabledStyle}`}>

            <DrivingAssessment protocolPartGroup={drivingAssessmentGroup} isEdit={isEdit} />
            
            <OnSiteAssessment protocolPartGroup={onSiteAssessmentLowestGroup} protocolCodeTableValues={protocolCodeTableValues} isEdit={isEdit} />

        </Container>
    </div>
}

export default observer(ExecutedTestArea);
