import React from "react";
import Downshift from "downshift";
import "./AutocompleteInput.scss";

import {FieldRenderProps} from "react-final-form";
import {Form} from "react-bootstrap";

type Props = FieldRenderProps<string, any>;
const AutocompleteSimpleInput: React.FC<Props> = ({
  input,
  inputOnChange,
  meta,
  title,
  warning,
  placeholder,
  items,
  disabled,
  required,
  width,
  ...rest
}) => {

  return (
    <Downshift
      onInputValueChange={(inputValue) => {
        input.onChange(inputValue);
      }}
      inputValue={input.value}
      itemToString={(item: any) => (item ? item : input.value)}
      selectedItem={false}
    >
      {({
        openMenu,
        closeMenu,
        getInputProps,
        getItemProps,
        isOpen,
        inputValue,
        highlightedIndex,
        selectedItem,
      }) => {
        return (
          <div className="downshift downshift-autocomplete" style={{width}}>
            <Form.Group controlId={input.name}>
              <input
                className={`form-control form-control-sm ${
                !!meta.error && meta.touched && "is-invalid"
                } ${warning && "is-warning"}`}
                {...getInputProps({
                  placeholder: placeholder,
                  name: input.name,
                  disabled: disabled,
                  onBlur: (event) => {
                    input.onBlur(event);
                    closeMenu();
                  },
                  onFocus: () => {
                  openMenu();
                },
                })}
              />

              {isOpen && (
              <div className="downshift-dropdown">
                {items
                //?.sort((a: any, b: any) => (a < b ? -1 : 1))
                .filter(
                    (item: any) =>
                    !inputValue ||
                    item
                        .toUpperCase()
                        .includes(inputValue.toUpperCase())
                )
                .map((item: any, index: number) => (
                    <div
                    className="dropdown-item"
                    {...getItemProps({
                        key: item.id || index,
                        index,
                        item: item,
                        style: {
                        color: "black",
                        backgroundColor:
                            highlightedIndex === index
                            ? " #cce9f7"
                            : "white",
                        fontWeight:
                            selectedItem === item ? "bold" : "normal",
                        },
                    })}
                    >
                    <div> {item}</div>
                    </div>
                ))}
              </div>
              )}
            </Form.Group>
          </div>
        );
      }}
    </Downshift>
  );
};

export default AutocompleteSimpleInput;
