import React, { useContext, useEffect, Fragment, useState } from "react";
import { Form as FinalForm, Field } from "react-final-form";
import { RootStoreContext } from "../../app/stores/rootStore";
import {
  Col,
  Row,
  Button,
  Badge,
  ListGroup,
  Form,
  Container,
} from "react-bootstrap";
import MultiSelectInput from "../../app/common/form/MultiSelectInput";
import TextInput from "../../app/common/form/TextInput";
import { observer } from "mobx-react-lite";
import Loader from "../loader/Loader";
import { ICarFilter } from "../../app/models/car";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter, faTimes } from "@fortawesome/free-solid-svg-icons";
import UserFilterRow from "./UserFilterRow";
import "./CarFilter.scss";
import ToggleSwitchInput from "../../app/common/form/ToggleSwitchInput";
import CheckboxInput from "../../app/common/form/CheckboxInput";
import {
  ICarFilterDTO,
  IUserDefinedCarFilter,
} from "../../app/models/userDefinedFilter";
import { FormApi } from "final-form";

interface IProps {
  filter: ICarFilter;
  setFilter: (filter: ICarFilter) => void;
}

const CarFilter: React.FC<IProps> = ({ filter, setFilter }) => {
  const rootStore = useContext(RootStoreContext);
  const { loadEnums, enums, isLoading } = rootStore.enumStore;
  const { openModal, closeModal } = rootStore.modalStore;
  const {
    loadFilters,
    carFiltersSorted,
    updateFilter,
    deleteFilter,
    carFilterRegistry,
  } = rootStore.userDefinedFilterStore;

  const [show, setShow] = useState(false);

  let filterToSave: ICarFilterDTO | undefined = undefined;
  let finalFormApi: FormApi<any>;
  const [activeFilterId, setActiveFilterId] = useState<number | undefined>(
    undefined
  );
  useEffect(() => {
    loadEnums();
    loadFilters();
  }, [loadEnums, enums, loadFilters]);

  const activeFilterLength = Object.values(filter)?.filter(
    (v) => v && (v === true || v.length)
  ).length;

  const handleFinalFormSubmit = (form: any) => {
    //console.log(form);
    if (form.action === "save") handleFilterSaveBtn(form);
    else handleFilterCars(form);
  };

  const handleFilterCars = (form: any) => {
   
    const carFilter: ICarFilter = {
      vin: form.vin,
      prodIdentificationNum: form.prodIdentificationNum,
      carNumber: form.carNumber,
      customerCode: form.customerCode,
      inTestroom: form.inTestroom,
      testDestinations: form.testDestinations,
      testPredestinations: form.testPredestinations,
      testTypes: form.testTypes,
      carStates: form.carStates,
      carModels: form.carModels,
      carEngines: form.carEngines,
      timeLimitTypes: form.timeLimitTypes,
      conflictTypes: form.conflictTypes,
    };
    setFilter(carFilter);
  };

  const handleCreateFilter = (form: any) => {
    const carFilter: IUserDefinedCarFilter = {
      userDefinedFilterId: 0,
      name: form.filterName,
      isGeneral: form.isGeneral,
      filter: filterToSave!,
    };
    //  console.log(carFilter);
    updateFilter(carFilter).then((filterId) => setActiveFilterId(filterId));
    closeModal();
  };

  const resetFilter = () => {
    const carFilter: ICarFilter = {
      carStates: [],
      testDestinations: [],
      testPredestinations: [],
      testTypes: [],
      carModels: [],
      carEngines:[],
      timeLimitTypes: [],
      conflictTypes: [],
    };
    setFilter(carFilter);
    setActiveFilterId(undefined);
  };

  const handleRenameFilter = (form: any) => {
    const carFilter = carFilterRegistry.get(form.filterId);
    if (carFilter) {
      carFilter.name = form.filterName;
      updateFilter(carFilter).then((filterId) => setActiveFilterId(filterId));
    }
    closeModal();
  };

  const handleDeleteFilter = (filterId: number) => {
    deleteFilter(filterId).then(() => setActiveFilterId(undefined));
    closeModal();
  };

  const handleRenameBtnClick = (filterId: number) => {
    const filterToRename = carFilterRegistry.get(filterId);

    openModal(
      <div>
        <FinalForm
          onSubmit={handleRenameFilter}
          render={({ handleSubmit }) => (
            <Form onSubmit={handleSubmit}>
              <Container>
                <Field
                  title="Název filtru"
                  name="filterName"
                  initialValue={filterToRename?.name}
                  component={TextInput}
                />
                <Field<number>
                  name="filterId"
                  initialValue={filterId}
                  component="input"
                  hidden
                  disabled
                />
              </Container>
              <hr />
              <div className="flex-row flex-center">
                <Button type="submit" className="btn-modal">
                  Ulož filtr
                </Button>
                <Button
                  type="button"
                  onClick={closeModal}
                  className="btn-modal"
                  variant="outline-secondary"
                >
                  Zrušit
                </Button>
              </div>
            </Form>
          )}
        />
      </div>,
      {
        preventClosing: true,
        title: "Přejmenování filtru",
      }
    );
    return;
  };

  const handleFilterSaveBtn = (form: any) => {
    const carFilterValuesToSave: ICarFilterDTO = {
      vin: form?.vin ?? "",
      prodIdentificationNum: form?.prodIdentificationNum ?? "",
      carNumber: form?.carNumber ?? "",
      customerCode: form?.customerCode ?? "",
      inTestroom: form?.inTestroom ?? false,
      carStateIds: form?.carStates?.map((x: any) => x.id) ?? [],
      carModelIds: form?.carModels?.map((x: any) => x.id) ?? [],
      carEngineIds:form?.carEngines?.map((x: any) => x.id) ?? [],
      carTestTypeIds: form?.testTypes?.map((x: any) => x.id) ?? [],
      destinationTypeIds: form?.testDestinations?.map((x: any) => x.id) ?? [],
      predestinationTypeIds:
        form?.testPredestinations?.map((x: any) => x.id) ?? [],
      conflictTypeIds: form?.conflictTypes?.map((x: any) => x.id) ?? [],
      timeLimitTypeIds: form?.timeLimitTypes?.map((x: any) => x.id) ?? [],
    };
    //console.log(carFilterValuesToSave);
    filterToSave = carFilterValuesToSave;

    openModal(
      <div>
        <FinalForm
          onSubmit={handleCreateFilter}
          initialValues={filter}
          render={({ handleSubmit }) => (
            <Form onSubmit={handleSubmit}>
              <Container>
                <Field
                  title="Název filtru"
                  name="filterName"
                  component={TextInput}
                />
                <Field
                  name="isGeneral"
                  title="Společný filtr"
                  type="checkbox"
                  component={ToggleSwitchInput}
                />
              </Container>
              <hr />
              <div className="flex-row flex-center">
                <Button type="submit" className="btn-modal">
                  Vytvoř filtr
                </Button>
                <Button
                  type="button"
                  onClick={closeModal}
                  className="btn-modal"
                  variant="outline-secondary"
                >
                  Zrušit
                </Button>
              </div>
            </Form>
          )}
        />
      </div>,
      {
        preventClosing: true,
        title: "Uložení uživatelského filtru",
      }
    );
    return;
  };

  const handleFilterClick = (userDefinedFilterId: number) => {
    setActiveFilterId(userDefinedFilterId);
    finalFormApi.mutators.setUserDefinedFilter(userDefinedFilterId);
  };

  const carModelReset = (args: any, state: any, utils: any) => {
    utils.changeValue(state, "carEngines", () => []);
  };

  const setUserDefinedFilter = (params: any, state: any, utils: any) => {
    const userDefinedFilterId = params[0];
    const filterValues = carFilterRegistry.get(userDefinedFilterId);
    if (filterValues) {
      const { filter } = filterValues;
      state.fields["vin"].change(filter.vin);
      state.fields["prodIdentificationNum"].change(
        filter.prodIdentificationNum
      );
      state.fields["carNumber"].change(filter.carNumber);
      state.fields["customerCode"].change(filter.customerCode);
      state.fields["inTestroom"].change(filter.inTestroom);
      state.fields["carStates"].change(
        filter.carStateIds.map((stateId) => {
          var state = enums?.carStates.find((x) => x.key === stateId);
          return { name: state!.value, id: state!.key };
        })
      );
      state.fields["carModels"].change(
        filter.carModelIds.map((modelId) => {
          var model = enums?.carModels.find((x) => x.id === modelId);
          return { name: model!.name, id: model!.id };
        })
      );
      state.fields["carEngines"].change(
        filter.carEngineIds?.map((engineId) => {
          var engine = enums?.carEngines.find((x) => x.id === engineId);
          return { name: engine!.name, id: engine!.id };
        })
      );
      state.fields["testDestinations"].change(
        filter.destinationTypeIds.map((destinationId) => {
          var destination = enums?.testDestinations.find(
            (x) => x.key === destinationId
          );
          return { name: destination!.value, id: destination!.key };
        })
      );
      state.fields["testPredestinations"].change(
        filter.predestinationTypeIds.map((predestinationId) => {
          var predestination = enums?.testPredestinations.find(
            (x) => x.key === predestinationId
          );
          return { name: predestination!.value, id: predestination!.key };
        })
      );
      state.fields["testTypes"].change(
        filter.carTestTypeIds.map((testTypeId) => {
          var testType = enums?.testTypes.find((x) => x.key === testTypeId);
          return { name: testType!.value, id: testType!.key };
        })
      );
      state.fields["conflictTypes"].change(
        filter.conflictTypeIds.map((conflictId) => {
          var conflict = enums?.conflictTypes.find((x) => x.key === conflictId);
          return { name: conflict!.value, id: conflict!.key };
        })
      );
      state.fields["timeLimitTypes"].change(
        filter.timeLimitTypeIds.map((timeLimitId) => {
          var timeLimit = enums?.timeLimitTypes.find(
            (x) => x.key === timeLimitId
          );
          return { name: timeLimit!.value, id: timeLimit!.key };
        })
      );
    }
  };
  return (
    <Fragment>
      <div className="filter-container">
        <div className="filter-container__buttons">
          <Button
            variant="success"
            onClick={() => setShow(!show)}
            title="Zobrazit / skrýt filtr"
          >
            <FontAwesomeIcon icon={faFilter} />
            {filter && Object.values(filter) && (
              <sup>
                <Badge pill variant="danger">
                  {activeFilterLength}
                </Badge>
              </sup>
            )}
          </Button>
          {activeFilterLength > 0 && (
            <Button
              size="sm"
              variant="danger"
              onClick={resetFilter}
              title="Zrušit všechny filtry"
            >
              <FontAwesomeIcon icon={faTimes} />
            </Button>
          )}
        </div>
        {show && (
          <div className="filter-container__filters">
            {isLoading && <Loader />}
            {enums && (
              <FinalForm
                onSubmit={handleFinalFormSubmit}
                mutators={{ carModelReset, setUserDefinedFilter }}
                initialValues={filter}
                render={({ handleSubmit, form, values }) => {
                  finalFormApi = form;
                  return (
                    <form onSubmit={handleSubmit}>
                      <Row>
                        <Col lg={2} className="user-filters">
                          <h4>Uložené filtry</h4>
                          <ListGroup className="user-filters__list">
                            {carFiltersSorted?.map((f) => (
                              <ListGroup.Item
                                key={f.userDefinedFilterId}
                                className="user-filters__row"
                                active={
                                  activeFilterId === f.userDefinedFilterId
                                }
                                onClick={() =>
                                  handleFilterClick(f.userDefinedFilterId)
                                }
                              >
                                <UserFilterRow
                                  id={f.userDefinedFilterId}
                                  name={f.name}
                                  handleRenameBtnClick={handleRenameBtnClick}
                                  handleDeleteBtnClick={handleDeleteFilter}
                                />
                              </ListGroup.Item>
                            ))}
                          </ListGroup>
                        </Col>
                        <Col lg={10}>
                          <h4>Možnosti filtrování</h4>
                          <Row>
                            <Col md={3}>
                              <Field
                                title="VIN"
                                name="vin"
                                component={TextInput}
                                size="sm"
                              />
                              <Field
                                title="KNR"
                                name="prodIdentificationNum"
                                component={TextInput}
                                size="sm"
                              />

                              <Field
                                title="Číslo vozu"
                                name="carNumber"
                                component={TextInput}
                                size="sm"
                              />
                              <Field
                                title="Odběratel"
                                name="customerCode"
                                component={TextInput}
                                size="sm"
                              />
                            </Col>
                            <Col md={4}>
                              <Field
                                title="Typ vozu"
                                name="testDestinations"
                                size="sm"
                                placeholder=""
                                format={(value) =>
                                  value &&
                                  value.map((v: any) => ({
                                    value: v.id,
                                    label: v.name,
                                  }))
                                }
                                parse={(value) =>
                                  value &&
                                  value.map((v: any) => ({
                                    id: v.value,
                                    name: v.label,
                                  }))
                                }
                                options={enums.testDestinations.map((e) => ({
                                  value: e.key,
                                  label: e.value,
                                }))}
                                component={MultiSelectInput}
                              />

                              <Field
                                title="Podurčení zkoušky"
                                name="testPredestinations"
                                size="sm"
                                placeholder=""
                                format={(value) =>
                                  value &&
                                  value.map((v: any) => ({
                                    value: v.id,
                                    label: v.name,
                                  }))
                                }
                                parse={(value) =>
                                  value &&
                                  value.map((v: any) => ({
                                    id: v.value,
                                    name: v.label,
                                  }))
                                }
                                options={enums.testPredestinations.map((e) => ({
                                  value: e.key,
                                  label: e.value,
                                }))}
                                component={MultiSelectInput}
                              />

                              <Field
                                title="Typ zkoušky"
                                name="testTypes"
                                size="sm"
                                placeholder=""
                                format={(value) =>
                                  value &&
                                  value.map((v: any) => ({
                                    value: v.id,
                                    label: v.name,
                                  }))
                                }
                                parse={(value) =>
                                  value &&
                                  value.map((v: any) => ({
                                    id: v.value,
                                    name: v.label,
                                  }))
                                }
                                options={enums.testTypes.map((e) => ({
                                  value: e.key,
                                  label: e.value,
                                }))}
                                component={MultiSelectInput}
                              />
                              <Field
                                title="Model"
                                name="carModels"
                                size="sm"
                                placeholder=""
                                format={(value) =>
                                  value &&
                                  value.map((v: any) => ({
                                    value: v.id,
                                    label: v.name,
                                  }))
                                }
                                parse={(value) =>
                                  value &&
                                  value.map((v: any) => ({
                                    id: v.value,
                                    name: v.label,
                                  }))
                                }
                                inputOnChange={form.mutators.carModelReset}
                                options={enums.carModels
                                  .slice()
                                  .filter((m) => m.isActive)
                                  .sort((a, b) => (a.name < b.name ? -1 : 1))
                                  .map((m) => ({
                                    value: m.id,
                                    label: m.name,
                                  }))}
                                component={MultiSelectInput}
                              />

                              <Field
                                title="Motorizace"
                                name="carEngines"
                                placeholder={
                                  values.carModels &&
                                  values.carModels.length === 1
                                    ? ""
                                    : "Vyberte 1 model vozu"
                                }
                                size="sm"
                                format={(value) =>
                                  value &&
                                  value.map((v: any) => ({
                                    value: v.id,
                                    label: v.name,
                                  }))
                                }
                                parse={(value) =>
                                  value &&
                                  value.map((v: any) => ({
                                    id: v.value,
                                    name: v.label,
                                  }))
                                }
                                options={enums.carEngines
                                  .slice()
                                  .filter(
                                    (m) =>
                                      m.isActive &&
                                      values.carModels &&
                                      values.carModels.length === 1 &&
                                      m.carModelId === values.carModels[0].id
                                  )
                                  .sort((a, b) => (a.name < b.name ? -1 : 1))
                                  .map((m) => ({
                                    value: m.id,
                                    label: m.name,
                                  }))}
                                component={MultiSelectInput}
                              />
                            </Col>
                            <Col md={5}>
                              <Field
                                title="Stav vozu"
                                name="carStates"
                                size="sm"
                                placeholder=""
                                format={(value) =>
                                  value &&
                                  value.map((v: any) => ({
                                    value: v.id,
                                    label: v.name,
                                  }))
                                }
                                parse={(value) =>
                                  value &&
                                  value.map((v: any) => ({
                                    id: v.value,
                                    name: v.label,
                                  }))
                                }
                                options={enums.carStates.map((e) => ({
                                  value: e.key,
                                  label: e.value,
                                }))}
                                component={MultiSelectInput}
                              />
                              <Field
                                name="inTestroom"
                                initialValue={false}
                                title="Na zkušebně"
                                type="checkbox"
                                component={CheckboxInput}
                              />

                              <Field
                                title="Limit zkušebny"
                                name="timeLimitTypes"
                                size="sm"
                                placeholder=""
                                format={(value) =>
                                  value &&
                                  value.map((v: any) => ({
                                    value: v.id,
                                    label: v.name,
                                  }))
                                }
                                parse={(value) =>
                                  value &&
                                  value.map((v: any) => ({
                                    id: v.value,
                                    name: v.label,
                                  }))
                                }
                                options={enums.timeLimitTypes.map((e) => ({
                                  value: e.key,
                                  label: e.value,
                                }))}
                                component={MultiSelectInput}
                              />
                              <Field
                                title="Vůz s konflikty"
                                name="conflictTypes"
                                size="sm"
                                placeholder=""
                                format={(value) =>
                                  value &&
                                  value.map((v: any) => ({
                                    value: v.id,
                                    label: v.name,
                                  }))
                                }
                                parse={(value) =>
                                  value &&
                                  value.map((v: any) => ({
                                    id: v.value,
                                    name: v.label,
                                  }))
                                }
                                options={enums.conflictTypes.map((e) => ({
                                  value: e.key,
                                  label: e.value,
                                }))}
                                component={MultiSelectInput}
                              />
                            </Col>
                          </Row>

                          <Row>
                            <div className="align-right">
                              <Button
                                type="submit"
                                className="filter-container__filters__button"
                                onClick={() => {
                                  form.change("action", "filter");
                                }}
                              >
                                Filtruj!
                              </Button>
                              <Button
                                type="submit"
                                variant="outline-primary"
                                className="filter-container__filters__button"
                                onClick={() => {
                                  form.change("action", "save");
                                }}
                              >
                                Ulož filtr
                              </Button>
                              <Button
                                type="button"
                                variant="outline-danger"
                                className="filter-container__filters__button"
                                onClick={resetFilter}
                              >
                                Zruš filtry
                              </Button>
                            </div>
                          </Row>
                        </Col>
                      </Row>
                    </form>
                  );
                }}
              />
            )}
          </div>
        )}
      </div>
    </Fragment>
  );
};

export default observer(CarFilter);
