import React from "react";
import { Badge, OverlayTrigger, Tooltip } from "react-bootstrap";

interface IProps {
  id: number;
  fromName: string;
  toName: string;
  style?: React.CSSProperties | undefined;
}

const TransportedCarIcon: React.FC<IProps> = ({ id, style, fromName, toName }) => {
  const description = `Vůz byl transportován ze zkušebny ${fromName} na zkušebnu ${toName} `;
  return (
    <div className="icon-emission" style={style}>
      <OverlayTrigger
        placement="right"
        overlay={<Tooltip id={`tooltip-priority-${id}`}>{description}</Tooltip>}
      >
        <Badge pill variant="info">
          T
        </Badge>
      </OverlayTrigger>
    </div>
  );
};

export default TransportedCarIcon;
