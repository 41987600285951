import React, { Fragment, useContext, useEffect, useState } from "react";
import {
  ITestDefinition,
  ITestDefinitionForm,
} from "../../../app/models/testDefinition";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { IEnums, IEnumItem, ITestDestinationItem } from "../../../app/models/enum";
import { IExpertGroup } from "../../../app/models/expertGroup";
import TestDefinitionsEditPopover from "../forms/TestDefinitionsEditPopover";
import { Form, Col, Row } from "react-bootstrap";
import "./TestDefinitionsTab.scss";
import Loader from "../../loader/Loader";
import { observer } from "mobx-react-lite";
import Can from "../../../authorization/Can";
import { RootStoreContext } from "../../../app/stores/rootStore";

interface IProps {
  enums: IEnums | undefined;
}

const TestDefinitionsTab: React.FC<IProps> = ({ enums }) => { 
  const rootStore = useContext(RootStoreContext);
  const { user } = rootStore.authStore;
  const {
    loadTestDefinitions,
    testDefinitions,
    isLoading,
    updateTestDefinition,
  } = rootStore.testDefinitionStore;
  const { expertGroups, loadExpertGroups } = rootStore.expertGroupStore;
  const [testDestinationId, setTestDestinationId] = useState(1);
  const [testPredestinationId, setTestPredestinationId] = useState(1);

  useEffect(() => {
    loadTestDefinitions();
    loadExpertGroups()
  }, [loadTestDefinitions, loadExpertGroups]);

  const expertGroupsHeaderCells: JSX.Element[] = [];
  expertGroups?.forEach((element) => {
    expertGroupsHeaderCells.push(
      <th
        key={element.expertGroupId}
        className="header__cell header__cell--expertgroup"
      >
        {element.expertGroupCode}
      </th>
    );
  });

  // pridani sloupce se sumou hodin na konec tabulky
  expertGroupsHeaderCells.push(
    <th key={"sum"} className="header__cell header__cell--expertgroup">
      ∑ hodin
    </th>
  );

  // table generation for PVS a VFF cars and for 0S cars
  // iterate through test types (table rows) and throught expert groups (columns), and test predestinations (subcolumn)

  const testMatrix: any[] = [];
  let tableRow: any[] = [];
  enums?.testTypes.filter( x => x.isActive).forEach((testType) => {
    // header columns
    tableRow.push(
      <td key={testType.key} className="body__cell--header">
        {testType.value}
      </td>
    );
    // iterate through the rows
    expertGroups?.forEach((expertGroup) => {
      let testDefinition = testDefinitions?.find(
        (td) =>
          td.testTypeId === testType.key &&
          td.testDestinationId === testDestinationId &&
          td.expertGroupId === expertGroup.expertGroupId &&
          testPredestinationId === td.testPredestinationId
      );
      const editTestLength = (
        <Popover
          style={{ width: "25rem" }}
          id={`popover-editcell-${testType.key}-${expertGroup.expertGroupId}-${testPredestinationId}`}
        >
          <TestDefinitionsEditPopover
            expertGroupId={expertGroup.expertGroupId}
            testTypeId={testType.key}
            testPredestinationId={testPredestinationId}
            testDestinationId={testDestinationId}
            testCharacteristicsEnum={enums.testCharacteristics}
            updateTestDefinition={updateTestDefinition}
            testDefinitionId={
              testDefinition ? testDefinition.testDefinitionId : undefined
            }
            fullLength={testDefinition ? testDefinition.fullLength : undefined}
            shortLength={
              testDefinition ? testDefinition.shortLength : undefined
            }
            testCharacteristicsId={
              testDefinition ? testDefinition.testCharacteristicsId : undefined
            }
          />
        </Popover>
      );
      const isCellFilled =
        (testDefinition &&
          (!!testDefinition.fullLength || !!testDefinition.shortLength)) ||
        false;
      let tableCellContent = (
        <div>
          {isCellFilled && (
            <Fragment>
              <div className="cell cell__hours">
                {/* show short length only when it is different from full length */}
                { !testDefinition?.shortLength || testDefinition?.fullLength === testDefinition?.shortLength ? testDefinition!.fullLength:
                 `${testDefinition!.fullLength + "/" + testDefinition!.shortLength}`}
              </div>
              <div className="cell cell__characteristics">
                {
                  enums.testCharacteristics.find(
                    (ch) => ch.key === testDefinition!.testCharacteristicsId
                  )?.value
                }
              </div>
            </Fragment>
          )}
        </div>
      );

      tableRow.push(
        <Can
        key={`popover-${testType.key}-${expertGroup.expertGroupId}-${testPredestinationId}`}
        roleId={user?.userRoleId}
        perform="settings-testDefinitions:edit"
        yes={
          <OverlayTrigger
          trigger="click"
          overlay={editTestLength}
          placement={"left"}
          rootClose
          key={`popover-${testType.key}-${expertGroup.expertGroupId}-${testPredestinationId}`}
        >
          <td
            className={
              isCellFilled
                ? "body__cell body__cell--active"
                : "body__cell body__cell--inactive"
            }
          >
            {tableCellContent}
          </td>
        </OverlayTrigger>
        }
        no={
          <td
          className={
            isCellFilled
              ? "body__cell body__cell--active"
              : "body__cell body__cell--inactive"
          }
        >
          {tableCellContent}
        </td>
        }
      />
        
      );
    });

    // computing value of column with sum of hours of test type for NP test predestination
    // if shortened length is not defined for the test then full length is used instead
    let totalHoursNp = testDefinitions
      ?.filter(
        (td) =>
          td.testTypeId === testType.key &&
          td.testDestinationId === testDestinationId &&
          td.testPredestinationId === testPredestinationId
      )
      .map((td) => ({ fullLength: td.fullLength, shortLength: td.shortLength }))
      .reduce(
        (accum, item) => ({
          fullLength: (accum?.fullLength || 0) + (item?.fullLength || 0),
          shortLength: (accum?.shortLength || 0) + (item?.shortLength ?? item.fullLength ?? 0),
        }),
        { fullLength: 0, shortLength: 0 }
      );

    tableRow.push(
      <td key={`sum-${testType.value}`} className="body__cell--header center">
        <span className="cell cell__hours">
          {totalHoursNp?.fullLength || "-"}/{totalHoursNp?.shortLength || "-"}
        </span>
      </td>
    );

    testMatrix.push(
      <tr key={testType.value} className="body__row">
        {tableRow}
      </tr>
    );
    tableRow = [];
  });

  return (
    <div id="settings__testdefinitionstab">
      <h3>Přehled zkoušek PVS, VFF a 0S vozů</h3>
      <div className="selectform">
        <Row>
          <Col sm={2} className="u-text-right">
            <Form.Label>Typ vozu</Form.Label>
          </Col>
          <Col sm={10} style={{ paddingRight: "20rem" }}>
            {enums && (
              <Form.Control
                as="select"
                name="testDestinationId"
                onChange={(e) =>
                  setTestDestinationId(Number.parseInt(e.currentTarget.value))
                }
                value={testDestinationId.toString()}
              >
                {enums &&
                  enums.testDestinations.map((option: ITestDestinationItem) => (
                    <option key={option.key} value={option.key}>
                      {option.value}
                    </option>
                  ))}
              </Form.Control>
            )}
          </Col>
        </Row>
        <Row>
          <Col sm={2} className="u-text-right">
            <Form.Label>Podurčení zkoušek</Form.Label>
          </Col>
          <Col sm={10} style={{ paddingRight: "20rem" }}>
            {enums && (
              <Form.Control
                as="select"
                name="testPredestinationId"
                onChange={(e) =>
                  setTestPredestinationId(
                    Number.parseInt(e.currentTarget.value)
                  )
                }
                value={testPredestinationId.toString()}
              >
                {enums &&
                  enums.testPredestinations.map((option: IEnumItem) => (
                    <option key={option.key} value={option.key}>
                      {option.value}
                    </option>
                  ))}
              </Form.Control>
            )}
          </Col>
        </Row>
      </div>
      <table className="sticky--top">
        <thead>
          <tr className="header__row header__row--expertgroup">
            <th className="header__cell header__cell--title"></th>
            {expertGroupsHeaderCells}
          </tr>
        </thead>
        <tbody className="body">        
          { !isLoading &&  testMatrix}
        </tbody>
      </table>
        {isLoading && <Loader />}
    </div>
  );
};

export default observer(TestDefinitionsTab);
