import React, { Fragment } from "react";
import { Form } from "react-bootstrap";
import { FieldRenderProps } from "react-final-form";

type Props = FieldRenderProps<any, any>;

const CheckboxGroupInput: React.FC<Props> = ({ input, meta, title, disabled, inputOnChange }: any) => {
  return (
    <Fragment>
      <Form.Check
        {...input}
        name={`${input.name}`}
        type="checkbox"
        id={`${input.name}`}
        className="checkbox"
        label={title}
        disabled={disabled}
        onChange={(e: any) => {
          input.onChange(e);
          inputOnChange && inputOnChange(e);
        }}
      />
    </Fragment>
  );
};

export default CheckboxGroupInput;
