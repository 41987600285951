import React, { useContext } from "react";
import { ICar } from "../../app/models/car";
import moment from "moment";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, OverlayTrigger, Popover } from "react-bootstrap";
import CarColor from "../icons/CarColor";
import VipCar from "../icons/VipCar";
import WorkerBadge from "./WorkerBadge";
import "./FullCalendarAnalysisEvent.scss";
import ScheduleCarDetail from "../schedule/dashboard/ScheduleCarDetail";
import VinCode from "../vin/VinCode";
import { ICarAnalysisTermList } from "../../app/models/schedule";
import { RootStoreContext } from "../../app/stores/rootStore";
import CarAnalysisForm from "../carAnalysis/CarAnalysisForm";
import { ICarAnalysis } from "../../app/models/carAnalysis";
import { IUser } from "../../app/models/user";
import FullCalendar from "@fullcalendar/react";
import CarTestCollisions from "../icons/CarTestCollisions";
import { checkAuthRule } from "../../authorization/Can";

interface IProps {
  event: {
    allDay: boolean;
    start: Date;
    end: Date;
    extendedProps: {
      car: ICar;
      carAnalyses: ICarAnalysis;
      carAnalysisTerm: ICarAnalysisTermList;
      groupWorkers: IUser[];
      history: any;
      calendarComponentRef: React.RefObject<FullCalendar>;
    };
  };
}

const FullCalendarAnalysisEvent: React.FC<IProps> = ({ event }) => {
  const { carAnalysisTerm, calendarComponentRef, carAnalyses, car, groupWorkers } =
    event.extendedProps;
  const rootStore = useContext(RootStoreContext);
  const { openModal } = rootStore.modalStore;
  const { user } = rootStore.authStore;

  const updateAnalysisEvent = () => {
    calendarComponentRef?.current?.getApi()?.refetchEvents();
  };

  const carDetail = (
    <Popover
      id={`popover-cardetail-${carAnalysisTerm.carId}`}
      style={{ minWidth: "40rem" }}
    >
      <Popover.Title as="h3">
        Detail vozu <VinCode vin={car.vin} />
      </Popover.Title>
      <Popover.Content>
        <ScheduleCarDetail car={car} />
      </Popover.Content>
    </Popover>
  );

  const conflictTypes = [
    ...new Set(carAnalyses.carTestConflicts.filter((x) => x.carAnalysisTermId == carAnalysisTerm.carAnalysisTermId).map((item) => item.conflictName)),
  ];

  const isCarImportant = car.vipCar || !!car.importantCarDesc;
  return (
    <div className="fullcalendar-analysis ">
      <div className="fullcalendar-analysis__type">Analýza</div>
      <div className="fullcalendar-analysis__time">
        {!carAnalysisTerm.wholeDay &&
          `${moment(event.start).format("H:mm")}
          ${"\u00A0"}- ${moment(event.end).format("H:mm")}`}
        {carAnalysisTerm.wholeDay && "Celý den"}
      </div>
      <div className="fullcalendar-analysis__link">
        <Button
          className="fullcalendar-analysis__link--btn"
          variant={"link"}
          title={" Detail analýzy"}
          onClick={() => {
            carAnalyses.car = car;
            carAnalyses.terms = carAnalyses?.terms?.map((p, index: number) => {
              p.rownumber = index;
              return p;
            }) || [];
            openModal(
              <CarAnalysisForm
                aCarId={carAnalysisTerm.carId}
                aCarAnalysisId={carAnalysisTerm.carAnalysisId}
                aCarAnalysisTermId={carAnalysisTerm.carAnalysisTermId}
                aCarAnalysis={carAnalyses}
                aExpertGroupId={carAnalysisTerm.expertGroupId}
                aWorkerOptions={groupWorkers.map((w) => ({
                  key: w.userId,
                  value: w.fullName,
                }))}
                aCallback={updateAnalysisEvent}
                isFormEditable={   
                checkAuthRule(
                  user?.userRoleId,
                  "car-analysis:edit",
                  {
                    userExpertGroupId: user?.expertGroupId,
                    expertGroupId: carAnalysisTerm.expertGroupId,
                    managedExpertGroups: user?.managedGroups,
                  }
                )}
              />,
              {
                preventClosing: true,
                title: "Analýza vozu",
                size: "xl",
              }
            );
          }}
        >
          <FontAwesomeIcon icon={faSearch} />
        </Button>
      </div>
      <div className="fullcalendar-analysis__car">
        <div className="flex-row">
          {car.vipCar && (
            <VipCar id={car.carId} style={{ marginRight: "4px" }} />
          )}
          {!!car.importantCarDesc && (
            <div
              className={` ${isCarImportant ? "car-important" : ""}`}
              style={{ marginRight: "5px" }}
            >
              {car.importantCarDesc}
            </div>
          )}
          <CarColor
            id={car.carId}
            color={car.colorValue}
            code={car.colorCode}
            name={car.colorName}
          />
        </div>
        <OverlayTrigger
          trigger={["click"]}
          placement="auto"
          overlay={carDetail}
          rootClose
        >
          <span className={`car-identification`}>
            {car.integrationNum}-{car.testDestinationCode}-
            <span className={` ${isCarImportant ? "car-important" : ""}`}>
              {car.carNumber}
            </span>
          </span>
        </OverlayTrigger>
      </div>

      {!!carAnalyses.carTestConflicts?.filter((x) => x.carAnalysisTermId == carAnalysisTerm.carAnalysisTermId).length && (
          <CarTestCollisions
            id={carAnalyses.carAnalysisId!}
            conflictTypes={conflictTypes}
          />
        )}

      <div className="fullcalendar-analysis__workers">
        {[...(carAnalysisTerm.assignedWorkers || [])]?.map((w) => (
          <WorkerBadge
            key={w.userId}
            id={w.userId}
            backgroundColor={w.color}
            label={w.shortcut}
            description={w.userFullName}
          />
        ))}
      </div>
    </div>
  );
};

export default FullCalendarAnalysisEvent;
