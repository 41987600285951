import React, { Fragment } from "react";
import { Form, Row, Col } from "react-bootstrap";
import { FieldRenderProps } from "react-final-form";

type Props = FieldRenderProps<string, any>;

const SimpleTextInput: React.FC<Props> = ({
  input,
  meta,
  title,
  placeholder,
  plaintext,
  disabled,
  warning,
  inputOnChange,
  readonly,
  required,
  titleWidth,
  fieldWidth,
  ...rest
}: Props) => {
  
  const inputField = (
    <Fragment>
      <Form.Control
        type="text"
        {...input}
        onChange={(e: any) => {
          input.onChange(e);
          inputOnChange && inputOnChange(e);
        }}
        onKeyPress={(e: { key: string; preventDefault: () => any; }) => { e.key === 'Enter' && e.preventDefault(); }}
        isInvalid={meta.touched && !!meta.error}
        placeholder={placeholder}
        plaintext={plaintext}
        disabled={disabled}
        readOnly={readonly}
        className={warning && !(meta.touched && meta.error) ? "is-warning" : ""}
        {...rest}
      />
    </Fragment>
  );

  return (
    <Fragment>
        {inputField}
    </Fragment>
  );
};

export default SimpleTextInput;
