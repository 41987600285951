import React from "react";
import { Row, Col, Button } from "react-bootstrap";
import { Field } from "react-final-form";
import TextInput from "../../app/common/form/TextInput";
import SelectInput from "../../app/common/form/SelectInput";
import VinAutocompleteInput from "../../app/common/form/VinAutocompleteInput";
import KnrAutocompleteInput from "../../app/common/form/KnrAutocompleteInput";
import AutocompleteInput from "../../app/common/form/AutocompleteInput";
import { IEnums, CarStates } from "../../app/models/enum";
import {
  ICar,
  NewCar,
  ICarIdVin,
  canSetEmissionTest,
} from "../../app/models/car";
import { FormApi } from "final-form";
import SwitchInput from "../../app/common/form/SwitchInput";
import { ICarTest } from "../../app/models/carTest";
import NullableBooleanSwitchInput from "../../app/common/form/NullableBooleanSwitchInput";

interface IProps {
  enums: IEnums | undefined;
  edit: boolean;
  editVin: boolean;
  values: ICar | NewCar;
  form: FormApi<ICar | NewCar>;
  loadedCarTests: ICarTest[];
  car: ICar | NewCar;
  carsByVin: ICarIdVin[];
  isVinLock: boolean;
  isKnrLock: boolean;
  handleValidateVinAndKnr: (
    vin: string | undefined,
    prodIdentificationNum: string | undefined
  ) => void;
  hadleFindCarByVinAndKnrInCops: (
    form: any,
    vin: string | undefined,
    prodIdentificationNum: string | undefined
  ) => void;
}

const CarDetailForm: React.FC<IProps> = ({
  enums,
  edit,
  editVin,
  values,
  form,
  loadedCarTests,
  car,
  isVinLock,
  isKnrLock,
  carsByVin,
  handleValidateVinAndKnr,
  hadleFindCarByVinAndKnrInCops,
}) => {
  return (
    <div>
      <Row>
        <Col lg={4} md={6} sm={12}>
          <Field<string>
            name="vin"
            title="VIN kód"
            disabled={(!editVin && (car.vin || !edit)) || isVinLock}
            inputOnChange={form.mutators.setCarNumber}
            items={[]}
            component={VinAutocompleteInput}
            warning={
              editVin &&
              !!carsByVin.filter((c) => c.vin === values.vin).length &&
              "Vůz již existuje"
            }
          />

          <Field
            name="prodIdentificationNum"
            title="KNR kód"
            type="number"
            items={[]}
            component={KnrAutocompleteInput}
            warning={
              editVin &&
              !!carsByVin.filter(
                (c) => c.prodIdentificationNum === values.prodIdentificationNum
              ).length &&
              "Vůz již existuje"
            }
            disabled={
              (!editVin && (car.prodIdentificationNum || !edit)) || isKnrLock
            }
          />

          {editVin &&
            ((values.vin && values.vin.length === 17) ||
              (values.prodIdentificationNum &&
                values.prodIdentificationNum.length === 13)) && (
              <Col xs={{ offset: 4 }}>
                <Button
                  variant="outline-primary"
                  style={{ marginBottom: "1rem" }}
                  onClick={() =>
                    handleValidateVinAndKnr(
                      values.vin,
                      values.prodIdentificationNum
                    )
                  }
                >
                  Pokračuj
                </Button>
                <Button
                  variant="outline-primary"
                  style={{ marginBottom: "1rem" }}
                  onClick={() =>
                    hadleFindCarByVinAndKnrInCops(
                      form,
                      values.vin,
                      values.prodIdentificationNum
                    )
                  }
                >
                  Vyhledat v COPS
                </Button>
              </Col>
            )}

          <Field<string>
            name="carNumber"
            title="Číslo vozu"
            value={values.vin && values.vin.slice(9)}
            component={TextInput}
            disabled={true}
          />

          <Field
            name="logisticNum"
            title="Logistické číslo"
            component={TextInput}
            disabled={
              !edit ||
              (![
                CarStates.Registered,
                CarStates.InProgress,
                CarStates.InTestroom,
                CarStates.Scheduled,
              ].includes(values.carStateId) &&
                car.logisticNum)
            }
            warning={!values.logisticNum}
          />

          <Field<string>
            name="integrationNum"
            title="VDS číslo"
            component={TextInput}
            disabled={
              !edit ||
              (![
                CarStates.Registered,
                CarStates.InProgress,
                CarStates.InTestroom,
                CarStates.Scheduled,
              ].includes(values.carStateId) &&
                car.integrationNum &&
                car.integrationNum.length > 0)
            }
            warning={!values.integrationNum}
          />
        </Col>
        <Col lg={4} md={6} sm={12}>
          <Field
            name="testDestinationId"
            title="Typ vozu"
            options={enums?.testDestinations}
            component={SelectInput}
            isEmptyOption={true}
            parse={(value) => (value ? parseInt(value) : undefined)}
            warning={!values.testDestinationId}
            disabled={
              !edit ||
              [
                CarStates.Scheduled,
                CarStates.InProgress,
                CarStates.Suspend,
                CarStates.Canceled,
                CarStates.Tested,
                CarStates.OutOfTestroom,
              ].includes(values.carStateId) ||
              !loadedCarTests ||
              loadedCarTests?.filter((carTest) => carTest.testDefinitionId)
                .length > 0
            }
          />

          <Field
            name="testTypeId"
            title="Typ zkoušky"
            options={enums?.testTypes
              .filter(
                (testType) =>
                  testType.isActive || testType.key === values.testTypeId
              )
              .slice()
              .sort((a, b) => (a.value < b.value ? -1 : 1))
              .map((testType) => ({
                key: testType.key,
                value: testType.value,
                isDisabled: !testType.isActive,
              }))}
            component={SelectInput}
            isEmptyOption={true}
            warning={!values.analyticsCar && !values.testTypeId}
            parse={(value) => (value ? parseInt(value) : undefined)}
            disabled={
              !edit ||
              [
                CarStates.Scheduled,
                CarStates.InProgress,
                CarStates.Suspend,
                CarStates.Canceled,
                CarStates.Tested,
                CarStates.OutOfTestroom,
              ].includes(values.carStateId) ||
              !loadedCarTests ||
              loadedCarTests?.filter((carTest) => carTest.testDefinitionId)
                .length > 0
            }
          />

          <Field
            name="testPredestinationId"
            title="Podurčení zkoušky"
            options={enums?.testPredestinations}
            component={SelectInput}
            isEmptyOption={true}
            parse={(value) => (value ? parseInt(value) : undefined)}
            warning={!values.analyticsCar && !values.testPredestinationId}
            disabled={
              !edit ||
              [
                CarStates.Scheduled,
                CarStates.InProgress,
                CarStates.Suspend,
                CarStates.Canceled,
                CarStates.Tested,
                CarStates.OutOfTestroom,
              ].includes(values.carStateId) ||
              !loadedCarTests ||
              loadedCarTests?.filter((carTest) => carTest.testDefinitionId)
                .length > 0
            }
          />

          <Field<string>
            name="startOfProduction"
            title="Začátek produkce"
            placeholder="týden/rok př. 36/2020"
            warning={!values.analyticsCar && !values.startOfProduction}
            component={TextInput}
            disabled={
              !edit ||
              (car.startOfProduction?.length > 0 &&
                [
                  CarStates.Scheduled,
                  CarStates.InProgress,
                  CarStates.Suspend,
                  CarStates.Canceled,
                  CarStates.Tested,
                  CarStates.OutOfTestroom,
                ].includes(values.carStateId))
            }
          />

          <Field<string>
            name="modelYear"
            title="Modelový rok"
            type="number"
            warning={!values.analyticsCar && !values.modelYear}
            component={TextInput}
            disabled={
              !edit ||
              (car.modelYear?.length > 0 &&
                [
                  CarStates.Scheduled,
                  CarStates.InProgress,
                  CarStates.Suspend,
                  CarStates.Canceled,
                  CarStates.Tested,
                  CarStates.OutOfTestroom,
                ].includes(values.carStateId))
            }
          />
        </Col>
        <Col lg={4} md={6} sm={12}>
          <Field<number>
            name="carStateId"
            title="Stav vozu"
            options={enums?.carStates}
            component={SelectInput}
            isEmptyOption={false}
            parse={(value) => value && parseInt(value)}
            disabled={true}
          />
          <Field<string>
            name="customerCode"
            title="Odběratel"
            component={TextInput}
            warning={!values.analyticsCar && !values.customerCode}
            disabled={
              !edit ||
              (car.customerCode?.length > 0 &&
                [
                  CarStates.Scheduled,
                  CarStates.InProgress,
                  CarStates.Suspend,
                  CarStates.Canceled,
                  CarStates.Tested,
                  CarStates.OutOfTestroom,
                ].includes(values.carStateId))
            }
          />
          <Field
            name="importantCarDesc"
            title="Důležitost"
            items={["MAF", "KAF", "SF"]}
            component={AutocompleteInput}
            disabled={!edit}
          />
          <Field
            name="vipCar"
            title="VIP vůz"
            options={[
              { key: 1, value: "ano" },
              { key: 0, value: " ne " },
            ]}
            format={(v) => (v ? 1 : 0)}
            parse={(v) => (v ? true : false)}
            component={SwitchInput}
            disabled={
              !edit ||
              [
                CarStates.Scheduled,
                CarStates.InProgress,
                CarStates.Suspend,
                CarStates.Canceled,
                CarStates.Tested,
                CarStates.OutOfTestroom,
              ].includes(values.carStateId)
            }
          />
          {canSetEmissionTest(
            values.testDestinationId,
            values.customerCode
          ) && (
            <Field
              name="emissionTest"
              title="Emisní vůz"
              component={NullableBooleanSwitchInput}
              oneColor="true"
              disabled={
                !edit ||
                [
                  // CarStates.Scheduled,
                  // CarStates.InProgress,
                  CarStates.Suspend,
                  CarStates.Canceled,
                  CarStates.Tested,
                  CarStates.OutOfTestroom,
                ].includes(values.carStateId)
              }
            />
          )}
          <Field
            name="analyticsCar"
            title="Analytický vůz"
            options={[
              { key: 1, value: "ano" },
              { key: 0, value: " ne " },
            ]}
            format={(v) => (v ? 1 : 0)}
            parse={(v) => (v ? true : false)}
            component={SwitchInput}
            disabled={
              !edit ||
              [
                CarStates.Scheduled,
                CarStates.InProgress,
                CarStates.Suspend,
                CarStates.Canceled,
                CarStates.Tested,
                CarStates.OutOfTestroom,
              ].includes(values.carStateId)
            }
          />
        </Col>
      </Row>
      <Col md={7}>
        <Field
          name="mentionIncomplete"
          title="Upozorňovat na chybějící parametry vozu"
          options={[
            { key: 1, value: "ano" },
            { key: 0, value: " ne " },
          ]}
          format={(v) => (v ? 1 : 0)}
          parse={(v) => (v ? true : false)}
          component={SwitchInput}
          disabled={!edit}
        />
      </Col>
    </div>
  );
};

export default CarDetailForm;
