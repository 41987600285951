export const getHoursString = (hours: number): string => {
  return hours === 1 ? "hodina" : hours < 5 && hours > 1 ? "hodiny" : "hodin";
};

export const getDaysString = (days: number): string => {
  return days === 1 ? "den" : days < 5 && days > 1 ? "dny" : "dní";
};

export const getCarsString = (cars: number): string => {
  return cars === 1 ? "vůz" : cars < 5 && cars > 1 ? "vozy" : "vozů";
};

export const getWorkersString = (workers: number): string => {
  return workers === 1
    ? "pracovníka"
    : workers < 5 && workers > 1
    ? "pracovníky"
    : "pracovníků";
};

// spravna koncovka trpnych sloves pred cislovkou pro rod muzsky
// pr. zobrazeno 0 vozů -> o, zobrazen 1 vůz -> "", zobrazeny 3 vozy -> y
export const getMasculinePassiveVerbEnding = (num: number): string => {
  return num === 1 ? "" : num < 5 && num > 1 ? "y" : "o";
};
