import React, { Fragment } from "react";
import { Dropdown } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisH } from "@fortawesome/free-solid-svg-icons";

interface IProps {
  id: number;
  name: string;
  handleRenameBtnClick: (filterId: number) => void;
  handleDeleteBtnClick: (filterId: number) => void;
}
const UserFilterRow: React.FC<IProps> = ({
  name,
  id,
  handleRenameBtnClick,
  handleDeleteBtnClick,
}) => {
  return (
    <Fragment>
      <span>{name}</span>
      <Dropdown>
        <Dropdown.Toggle
          variant="light"
          size="sm"
          id={`dropdown-action-${id}`}
          className="nochevron btn-dropdownmenu"
        >
          <FontAwesomeIcon icon={faEllipsisH} />
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <Dropdown.Item onClick={() => handleRenameBtnClick(id)}>
            Přejmenovat
          </Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item onClick={() => handleDeleteBtnClick(id)}>
            Smazat
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </Fragment>
  );
};

export default UserFilterRow;
