import { RootStore } from "./rootStore";
import { action, observable, runInAction, computed } from "mobx";
import agent from "../api/agent";
import { IPlanCarsForm } from "../models/plan";
import { IDate } from "../models/date";
import { ICar } from "../models/car";
import moment, { Moment } from "moment";
import { ICarTest } from "../models/carTest";
import { toast } from "react-toastify";
import { ICarTerm, IScheduleFilter } from "../models/schedule";
import { convertTypeAcquisitionFromJson } from "typescript";
import { ICarAnalysis } from "../models/carAnalysis";

export default class PageScheduleStore {
  rootStore: RootStore;
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @observable dateFrom: Moment = moment().isoWeekday(1);
  @observable dateTo: Moment = moment().isoWeekday(7);

  // array with days to be rendered in schedule + info if day is working day
  @observable schedullingDates: IDate[] = [];

  // cars ids to be displayed in calendar for selected date range
  @observable carIds: number[] = [];

  // car test ids to be displayed in calendar for selected date range
  @observable carTestIds: number[] = [];

  // flag if data is loading
  @observable loadingInitial = false;

  // ids of cars that were recently planned
  @observable plannedCarIds: number[] = [];

  // status of scheduling
  @observable scheduleStatus = 0;

  // status of scheduling
  @observable isScheduleRunning = false;

  // width of schedule table header
  @observable tableHeaderWidth = window.innerWidth;

  // filter page results for selected week
  @observable scheduleFilter: IScheduleFilter = {
    customerCode: undefined,
    vin: undefined,
    inTestroomNow: false,
    inTestroom: false,
    carStates: [],
    testDestinations: [],
    testPredestinations: [],
    testTypes: [],
    carModels: [],
    carEngines: [],
    timeLimitTypes: [],
  };

  // car analysis ids to be displayed in calendar for selected date range
  @observable carAnalysisIds: number[] = [];

  // car term ids to be displayed in calendar for selected date range
  // it is concatenation of carTestId,carAnalysisId,carAnalysisTermId
  @observable carTermIds: string[] = [];

  // cars id selected on calendar
  @observable selectedCarIds: number[] = [];

  // number of used filters for actual filtering
  @computed get activeFilterLength() {
    return Object.values(this.scheduleFilter)?.filter(
      (v) => v && (v === true || v.length)
    ).length;
  }
  @computed get carsSortedAndFiltered() {
    const cars: ICar[] = [];
    this.carIds.forEach((carId) => {
      let car = this.rootStore.carStore.carRegistry.get(carId);
      if (car) cars.push(car);
    });
    const filteredCars = this.filterCars(cars);
    // return this.sortCarsByAvailable(filteredCars);
    return this.sortCarsByState(filteredCars);
  }

  sortCarsById(cars: ICar[]) {
    const sortedCars = cars.sort((a, b) => b.carId - a.carId);
    return sortedCars;
  }

  filterCars(cars: ICar[]) {
    return cars.filter(
      (c) =>
        (!this.scheduleFilter.vin ||
          (c.vin &&
            c.vin
              .toLowerCase()
              .includes(this.scheduleFilter.vin.toLowerCase()))) &&
        (!this.scheduleFilter.customerCode ||
          c.customerCode
            .toLowerCase()
            .includes(this.scheduleFilter.customerCode.toLowerCase())) &&
        (!this.scheduleFilter.inTestroom ||
          (moment(c.availableFrom) < this.dateTo && !c.availableTo) ||
          moment(c.availableTo) > this.dateFrom) &&
        (!this.scheduleFilter.inTestroomNow ||
          (c.availableFrom && !c.availableTo)) &&
        (!this.scheduleFilter.carStates ||
          !this.scheduleFilter.carStates.length ||
          this.scheduleFilter.carStates
            .map((s) => s.value)
            .includes(c.carStateId)) &&
        (!this.scheduleFilter.testDestinations ||
          !this.scheduleFilter.testDestinations.length ||
          this.scheduleFilter.testDestinations
            .map((s) => s.value)
            .includes(c.testDestinationId)) &&
        (!this.scheduleFilter.testPredestinations ||
          !this.scheduleFilter.testPredestinations.length ||
          this.scheduleFilter.testPredestinations
            .map((s) => s.value)
            .includes(c.testPredestinationId)) &&
        (!this.scheduleFilter.testTypes ||
          !this.scheduleFilter.testTypes.length ||
          this.scheduleFilter.testTypes
            .map((s) => s.value)
            .includes(c.testTypeId)) &&
        (!this.scheduleFilter.carModels ||
          !this.scheduleFilter.carModels.length ||
          this.scheduleFilter.carModels
            .map((s) => s.value)
            .includes(c.modelId)) &&
        (!this.scheduleFilter.carEngines ||
          !this.scheduleFilter.carEngines.length ||
          this.scheduleFilter.carEngines
            .map((s) => s.value)
            .includes(c.engineId)) &&
        (!this.scheduleFilter.timeLimitTypes ||
          !this.scheduleFilter.timeLimitTypes.length ||
          this.scheduleFilter.timeLimitTypes
            .map((s) => s.value)
            .includes(c.timeLimit))
    );
  }

  sortCarsByVin(cars: ICar[]) {
    const sortedCars = cars.sort((a, b) =>
      a.vin && b.vin && b.vin > a.vin ? -1 : 1
    );
    return sortedCars;
  }

  sortCarsByAvailable(cars: ICar[]) {
    const sortedCars = cars.sort((a, b) => {
      if (!a.availableFrom && !b.availableFrom)
        return a.carId > b.carId ? -1 : 1;
      if (!a.availableFrom) return -1;
      if (!b.availableFrom) return 1;
      return b.availableFrom < a.availableFrom
        ? -1
        : b.availableFrom > a.availableFrom
        ? 1
        : a.carId > b.carId
        ? -1
        : 1;
    });
    return sortedCars;
  }

  sortCarsByState(cars: ICar[]) {
    const sortedCars = cars.sort((a, b) => {
      return b.order < a.order ? 1 : -1;
    });
    return sortedCars;
  }

  @computed get carTestsFromStore() {
    const carTests: ICarTest[] = [];
    this.carTestIds.forEach((carTestId) => {
      let carTest = this.rootStore.carTestStore.carTestsRegistry.get(carTestId);
      if (carTest) carTests.push(carTest);
    });
    return carTests;
  }

  @computed get carAnalysesFromStore() {
    const carAnalyses: ICarAnalysis[] = [];
    this.carAnalysisIds.forEach((carAnalysisId) => {
      let carAnalysis =
        this.rootStore.carAnalysisStore.carAnalysesRegistry.get(carAnalysisId);
      if (carAnalysis) carAnalyses.push(carAnalysis);
    });
    return carAnalyses;
  }

  @computed get carTermsFromStore() {
    const carTerms: ICarTerm[] = [];
    this.carTermIds.forEach((key) => {
      let carTerm = this.rootStore.carAnalysisStore.carTermsRegistry.get(key);
      if (carTerm) carTerms.push(carTerm);
    });
    return carTerms;
  }

  @action setDateFromDateTo = (dateFrom: Moment, dateTo: Moment) => {
    this.dateFrom = dateFrom;
    this.dateTo = dateTo;
  };

  @action setTableHeaderWidth = () => {
    const bodyWidth = document?.getElementById("schedule-body")?.clientWidth;
    this.tableHeaderWidth = bodyWidth || 0;
  };
  // run the scheduling on backend for selected cars
  @action startScheduling = async (planCars: IPlanCarsForm) => {
    this.isScheduleRunning = true;
    const toastId = toast.info("Plánování bylo spuštěno!", {
      autoClose: false,
    });
    try {
      var state = await agent.Scheduling.start(planCars);
      runInAction(() => {
        this.scheduleStatus = state;
        this.isScheduleRunning = false;
        this.setPlannedCars(planCars.cars.map((c) => c.carId));
      });
      toast.update(toastId, {
        type: toast.TYPE.SUCCESS,
        render: `Plánování bylo dokončeno! 
        Byly naplánovány vozy: ${planCars.cars
          .map((car) => this.rootStore.carStore.carRegistry.get(car.carId)?.vin)
          .join(", ")}`,
        autoClose: 7500,
      });
    } catch (error) {
      toast.update(toastId, {
        type: toast.TYPE.ERROR,
        render: "Při plánování nastala chyba!",
        autoClose: 5000,
      });

      console.log(error);
      this.isScheduleRunning = false;
      throw error;
    }
  };

  @action setPlannedCars(carIds: number[] | undefined) {
    this.plannedCarIds = carIds || [];
  }

  @action setSelectedCars = (carId: number) => {
    runInAction(() => {
      if(this.selectedCarIds.includes(carId)) {
        this.selectedCarIds = this.selectedCarIds.filter(x => x !== carId);
      }
      else {
        this.selectedCarIds.push(carId);
      }
    });
  }

  // load car tests for selected date range
  @action loadSchedule = async (startDate: Date, endDate: Date) => {
    this.loadingInitial = true;
    this.carIds = [];
    this.carTestIds = [];
    this.carAnalysisIds = [];
    this.carTermIds = [];
    try {
      const response = await agent.Scheduling.loadDateRange(startDate, endDate);
      runInAction(() => {
        const { dates, cars, carTests, carAnalyses, carTerms } = response;
        this.schedullingDates = dates;

        cars.forEach((car) => {
          this.rootStore.carStore.carRegistry.set(car.carId, car);
          this.carIds.push(car.carId);
        });

        carTests.forEach((carTest) => {
          this.rootStore.carTestStore.carTestsRegistry.set(
            carTest.carTestId,
            carTest
          );
          this.carTestIds.push(carTest.carTestId);
        });

        carAnalyses.forEach((carAnalysis) => {
          if (carAnalysis && carAnalysis.carAnalysisId) {
            this.rootStore.carAnalysisStore.carAnalysesRegistry.set(
              carAnalysis.carAnalysisId,
              carAnalysis
            );
            this.carAnalysisIds.push(carAnalysis.carAnalysisId);
          }
        });

        carTerms.forEach((carTerm) => {
          if (carTerm && (carTerm.carTestId || carTerm.carAnalysisId)) {
            this.rootStore.carAnalysisStore.carTermsRegistry.set(
              carTerm.carTestId + "," + carTerm.carAnalysisId + "," + carTerm.carAnalysisTermId,
              carTerm
            );
            this.carTermIds.push(
              carTerm.carTestId + "," + carTerm.carAnalysisId + "," + carTerm.carAnalysisTermId
            );
          }
        });
        this.loadingInitial = false;
      });
    } catch (error) {
      console.log(error);
      this.loadingInitial = false;
    }
  };

  @action setScheduleFilter = (scheduleFilter: IScheduleFilter) => {
    this.scheduleFilter = scheduleFilter;
  };
}
