import { RootStore } from "./rootStore";
import { observable, action, runInAction } from "mobx";
import agent from "../api/agent";
import { toast } from "react-toastify";
import { ICarAnalysisForm, ICarAnalysis } from "../models/carAnalysis";
import { ICarTerm } from "../models/schedule";

export default class CarAnalysisStore {
    rootStore: RootStore;
    constructor(rootStore: RootStore) {
      this.rootStore = rootStore;
    }
  
    @observable isLoading: boolean = false;
    @observable carAnalysesRegistry = new Map<number, ICarAnalysis>();
    @observable carTermsRegistry = new Map<string, ICarTerm>();

    @action loadCarAnalysis = async (carAnalysisId: number) => {
      
      try {
        this.isLoading = true;
        const carAnalysis = await agent.CarAnalysis.get(carAnalysisId);
        runInAction("load car analysis", () => {
          if(carAnalysis && carAnalysis.carAnalysisId){
            this.carAnalysesRegistry.set(carAnalysis.carAnalysisId, carAnalysis);
          }
          this.isLoading = false;
        });
      } catch (error) {
        runInAction("load car analysis error", () => {
          this.isLoading = false;
        });
        throw error;
      }
    }

    @action createOrUpdateCarAnalysis = async (form: ICarAnalysisForm ) : Promise<ICarAnalysis> => {
      try {
        this.isLoading = true;
        const response = await agent.CarAnalysis.createOrUpdate(form);
        runInAction(() => {
          this.isLoading = false;
        });
        return response;
      } catch (error) {
        console.log(error);
        toast.warn("Nastal problém při ukládání analýzy vozu");
        this.isLoading = false;
        throw error;
      }
    };
 }