import React from "react";
import { Badge, OverlayTrigger, Tooltip } from "react-bootstrap";
import "./WorkerBadge.scss";

interface IProps {
  id: number;
  label: string;
  backgroundColor: string;
  description: string;
}

const WorkerBadge: React.FC<IProps> = ({
  id,
  label,
  backgroundColor,
  description,
}) => {
  return (
    <OverlayTrigger
      placement="right"
      overlay={<Tooltip id={`tooltip-worker-${id}`}>{description}</Tooltip>}
    >
      <Badge className={`worker-badge worker-badge--${backgroundColor}`}>
        {label}
      </Badge>
    </OverlayTrigger>
  );
};

export default WorkerBadge;
