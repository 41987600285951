import React, { Fragment }  from "react";
import { Form, Row, Col } from "react-bootstrap";
import { FieldRenderProps } from "react-final-form";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import cs from "date-fns/locale/cs";
import moment from "moment";
import "./DateTimeInput.scss"

type Props = FieldRenderProps<Date, any>;

const DateTimeInput: React.FC<Props> = ({
  input,
  meta,
  title,
  inputOnChange,
  placeholder,
  disabled
}: Props) => {
  registerLocale("cs", cs);

  const errorMessage = !!meta.error && (
    <div className="datepicker-invalid-feedback">{meta.error}</div>
  );

  const inputField = (
    <DatePicker
      autoComplete={"off"}
      disabled={disabled}
      showTimeSelect
      dateFormat="dd.MM.yyyy HH:mm"
      timeInputLabel="Čas"
      timeFormat="HH:mm"
      timeIntervals={60}
      minTime={moment().set("hours",6).set("minutes",0).toDate()}
      maxTime={moment().set("hours",18).set("minutes",0).toDate()}
      selected={input.value}
      onChange={(e:any)=>{input.onChange(e); inputOnChange && inputOnChange(e);}}
      customInput={<Form.Control type="text" isInvalid={!!meta.error} />}
      locale="cs"
    />
  );

  return (
    <Fragment>
      {title &&
        <Form.Group controlId={input.name}>
          <Row>
            <Col xl={4} lg={4} md={4} sm={4} xs={4} className="u-text-right">
              <Form.Label>{title}</Form.Label>
            </Col>
            <Col xl={8} lg={8} md={8} sm={8} xs={8}>
              {inputField}
              {errorMessage}
            </Col>
          </Row>
        </Form.Group>
      }
      {!title && (
        <Form.Group controlId={input.name}>
          {inputField}
          {errorMessage}
        </Form.Group>
      )}
    </Fragment>
  );
};

export default DateTimeInput;
