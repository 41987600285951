import React, { useContext, useState } from "react";
import { Table, Button, OverlayTrigger, Tooltip, Alert } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faChevronUp,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { RootStoreContext } from "../../app/stores/rootStore";
import {
  IWorker,
  IAddedWorker,
} from "../../app/models/expertGroup";
import moment from "moment";
import { DATE_FORMAT } from "../../app/common/utils/constants";
import BlockingEventForm from "../blockingEventForm/BlockingEventForm";
import { faEdit } from "@fortawesome/free-regular-svg-icons";
import { observer } from "mobx-react-lite";

interface IProps {
  editExpertGroup: boolean;
  expertGroupId: number;
  loadedWorkers: IWorker[];
  addedWorkers: IAddedWorker[];
}

const BlockingEventsTab: React.FC<IProps> = ({
  editExpertGroup: edit,
  expertGroupId,
  loadedWorkers,
  addedWorkers,
}) => {
  const rootStore = useContext(RootStoreContext);
  const { openModal } = rootStore.modalStore;
  const { expertGroupDetailedRegistry } = rootStore.expertGroupStore;

  const [showHistory, setShowHistory] = useState(false);

  const blockingEvents = expertGroupDetailedRegistry.get(expertGroupId)
    ?.blockingEvents;
  const workerOptions = [
    ...loadedWorkers
      .filter((w) => !w.isDeleted)
      .map((w) => ({ key: w.userId, value: w.fullName })),
    ...addedWorkers.map((aw) => ({ key: aw.userId, value: aw.fullName })),
  ];

  const currentDate = new Date(new Date().setHours(0, 0, 0, 0));
  return (
    <div>
      <div
        style={{ marginBottom: "1rem", marginRight: "5rem" }}
        className="flex-row flex-end"
      >
        <OverlayTrigger
          overlay={
            <Tooltip id={`tooltip-addbutton`}>
              Přidat absenci nebo blokaci
            </Tooltip>
          }
        >
          <Button
            variant="primary"
            className="footer__btn-add" 
            disabled={edit}
            onClick={() =>
              openModal(
                <BlockingEventForm
                  expertGroupId={expertGroupId}
                  workerOptions={workerOptions}
                />,
                {
                  preventClosing: true,
                  title: "Zadání nové absence nebo blokace",
                }
              )
            }
          >
            <FontAwesomeIcon icon={faPlus} />
          </Button>
        </OverlayTrigger>
      </div>

      <Table size="sm">
        <thead>
          <tr>
            <th>Pracovník</th>
            <th style={{ width: "20%" }}>Popis</th>
            <th>Datum od</th>
            <th>Datum do</th>
            <th>Celý den</th>
            <th>Čas od</th>
            <th>Čas do</th>
            <th>Typ</th>
            <th>Akce</th>
          </tr>
        </thead>
        <tbody>
          {blockingEvents?.filter(
              (la) =>
                !la.isDeleted &&
                (showHistory || new Date(la.startDate) >= currentDate)
            )
            .sort((a, b) => (a.startDate > b.startDate ? -1 : 1))
            .map((blockEvent) => (
              <tr
                key={`loada-${blockEvent.blockingEventId}`}
                className={
                  new Date(blockEvent.startDate) >= currentDate ? "" : "stretch"
                }
              >
                <td>{blockEvent.wholeGroup ? "Celá skupina" : blockEvent.fullName}</td>
                <td>{blockEvent.description}</td>
                <td>
                  {blockEvent.startDate && moment(blockEvent.startDate).format(DATE_FORMAT)}
                </td>
                <td>{blockEvent.endDate && moment(blockEvent.endDate).format(DATE_FORMAT)}</td>
                <td>{blockEvent.wholeDay ? "Ano" : "Ne"}</td>
                <td>{blockEvent.startTime || ""}</td>
                <td>{blockEvent.endTime || ""}</td>
                <td>{blockEvent.blockingEventTypeName}</td>
                <td>
                  {!edit && (
                    <Button
                      onClick={() => {
                        openModal(
                          <BlockingEventForm
                            expertGroupId={expertGroupId}
                            workerOptions={workerOptions}
                            aBlockingEvent={blockEvent}
                          />,
                          {
                            preventClosing: true,
                            title: "Detail absence nebo blokace",
                          }
                        );
                      }}
                      size="sm"
                      variant="outline-primary"
                    >
                      <FontAwesomeIcon icon={faEdit} /> Upravit
                    </Button>
                  )}
                </td>
              </tr>
            ))}
          {(!blockingEvents || !blockingEvents.length) && (
            <tr>
              <td colSpan={9}>
                <Alert variant="info">
                  Odborná skupina nemá žádné blokace ani absence
                </Alert>
              </td>
            </tr>
          )}
          <tr>
            <td
              colSpan={9}
              style={{ textAlign: "center", paddingTop: "1.3rem" }}
            >
              <button
                onClick={() => setShowHistory(!showHistory)}
                type="button"
                className={`btn-link btn-link--primary animate-${
                  showHistory ? "up" : "down"
                }`}
              >
                {!showHistory && (
                  <span>
                    Zobraz historické absence a blokace{" "}
                    <FontAwesomeIcon icon={faChevronDown} />
                  </span>
                )}
                {showHistory && (
                  <span>
                    Skryj historické absence a blokace{" "}
                    <FontAwesomeIcon icon={faChevronUp} />
                  </span>
                )}
              </button>
            </td>
          </tr>
        </tbody>
      </Table>
    </div>
  );
};

export default observer(BlockingEventsTab);
