import React, { useEffect, useContext, Fragment, useState } from "react";
import "./CarList.scss";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faSignOutAlt,
  faSignInAlt,
  faTruckLoading,
} from "@fortawesome/free-solid-svg-icons";
import { RootStoreContext } from "../../../app/stores/rootStore";
import { observer } from "mobx-react-lite";
import VinCode from "../../vin/VinCode";
import { Link } from "react-router-dom";
import CarFilter from "../../carFilter/CarFilter";
import Loader from "../../loader/Loader";
import IncompleteCar from "../../icons/IncompleteCar";
import {
  getCarsString,
  getMasculinePassiveVerbEnding,
} from "../../../app/common/utils/czechDeclension";
import { Alert, ButtonGroup } from "react-bootstrap";
import AlertMessage from "../../alert/AlertMessage";
import Can, { checkAuthRule } from "../../../authorization/Can";
import ExceededLimit from "../../icons/ExceededLimit";
import VipCar from "../../icons/VipCar";
import moment from "moment";
import { isTodayOrPast } from "../../../app/common/utils/functions";
import Pages from "../../../app/common/utils/Pages";
import CarColor from "../../icons/CarColor";
import EmissionCarIcon from "../../icons/EmissionCarIcon";
import EmissionDate from "../../icons/EmissionDate";
import TransportedCarIcon from "../../icons/TransportedCarIcon";
import PlaceBadge from "../../icons/PlaceBadge";
import TextAreaInput from "../../../app/common/form/TextAreaInput";
import { Field, Form as FinalForm } from "react-final-form";
import NumberInput from "../../../app/common/form/NumberInput";

interface ISelectedCar {
  carId: number;
  emissionTest: boolean;
}

const CarList: React.FC = () => {
  const [errors, setErrors] = useState([]);
  const [showSubmitError, setShowSubmitError] = useState(false);

  const rootStore = useContext(RootStoreContext);
  const {
    loadCars,
    carsSorted,
    isLoading,
    setCarFilter,
    carFilter,
    carsPage,
  } = rootStore.pageCarListStore;
  const {
    takeCarsInTestroom,
    takeCarsOutOfTestroom,
    sendCarsToEmission,
    loadCarsFromEmission,
    transportCars,
    cancelCarTransport,
  } = rootStore.carStore;
  const { user } = rootStore.authStore;
  const { openDialog, openModal, closeModal } = rootStore.modalStore;
  useEffect(() => {
    loadCars(carsPage.currentPage);
  }, [loadCars, carsPage.currentPage]);

  const [selectedCars, setSelectedCars] = useState<ISelectedCar[]>([]);

  const changeSelected = (
    carId: number,
    emissionTest: boolean | undefined,
    isChecked: boolean
  ) => {
    if (isChecked)
      setSelectedCars([
        ...selectedCars,
        { carId, emissionTest: emissionTest ? true : false },
      ]);
    if (!isChecked)
      setSelectedCars([...selectedCars.filter((x) => x.carId !== carId)]);
  };

  const takeCarsInTestroomBtn = () => {
    setErrors([]);
    setShowSubmitError(false);
    takeCarsInTestroom(selectedCars.map((x) => x.carId))
      .then(() => setSelectedCars([]))
      .catch((error) => {
        setErrors(Object.values(error.response.data.errors));
        setShowSubmitError(true);
      });
  };

  const takeCarsOutOfTestroomBtn = () => {
    setErrors([]);
    setShowSubmitError(false);
    takeCarsOutOfTestroom(selectedCars.map((x) => x.carId))
      .then(() => setSelectedCars([]))
      .catch((error) => {
        setErrors(Object.values(error.response.data.errors));
        setShowSubmitError(true);
      });
  };

  const sendCarsToEmissionBtn = () => {
    setErrors([]);
    setShowSubmitError(false);
    sendCarsToEmission(selectedCars.map((x) => x.carId))
      .then(() => setSelectedCars([]))
      .catch((error) => {
        setErrors(Object.values(error.response.data.errors));
        setShowSubmitError(true);
      });
  };

  const loadCarsFromEmissionBtn = () => {
    setErrors([]);
    setShowSubmitError(false);
    loadCarsFromEmission(selectedCars.map((x) => x.carId))
      .then(() => setSelectedCars([]))
      .catch((error) => {
        setErrors(Object.values(error.response.data.errors));
        setShowSubmitError(true);
      });
  };

  const transportSelectedCars = () => {
    console.log("confirm");
    setErrors([]);
    setShowSubmitError(false);
    transportCars(selectedCars.map((x) => x.carId))
      .then(() => setSelectedCars([]))
      .catch((error) => {
        setErrors(Object.values(error.response.data.errors));
        setShowSubmitError(true);
      });
  };

  const transportBtn = () => {
    const directionDescription =
      user?.placeId === 1
        ? "z Mladé Boleslavy do Kvasin"
        : "z Kvasin do Mladé Boleslavy";
    openDialog(
      <div>
        <p>
          Tímto krokem odešlete vybrané vozy na druhou zkušebnu (
          {directionDescription}). Jste si jistí, že tento krok chcete udělat?
        </p>
      </div>,
      {
        title: "Transport vozů na druhou zkušebnu",
        confirmButtonLabel: "Ano, transportovat",
        cancelButtonLabel: "Zpět",
        onConfirm: () => {
          transportSelectedCars();
        },
      }
    );
    return;
  };

  const handleCancelCarTransport = async ({ carId, reason }: any) => {
    try {
      await cancelCarTransport(carId, reason);
      closeModal();
    } catch (error) {
      console.log(error);
    }
  };

  const cancelTransportBtn = (carId: number) => {
    const car = carsPage.cars.find((x) => x.carId === carId);
    const directionDescription =
      car?.placeId === 2
        ? "z Mladé Boleslavy do Kvasin"
        : "z Kvasin do Mladé Boleslavy";
    const locationFrom = car?.placeId === 1 ? "Kvasin" : "Mladé Boleslavy";
    let reason: string | undefined = undefined;
    openModal(
      <div>
        <div style={{ width: "90%", margin: "auto" }}>
          <p>
            Tímto krokem zrušíte transport vozu {directionDescription} a vůz
            bude <strong>vrácen do {locationFrom}</strong>. Jste si jistí, že
            tento krok chcete udělat?
          </p>
        </div>
        <FinalForm
          onSubmit={handleCancelCarTransport}
          validate={(values) => {
            const errors = {} as any;

            if (!values.reason || !values.reason.length) {
              errors.reason = "Důvod musí být vyplněn";
            }

            return errors;
          }}
          render={({ handleSubmit }) => (
            <Form onSubmit={handleSubmit}>
              <div style={{ width: "80%", margin: "auto" }}>
                <Field
                  name="carId"
                  initialValue={carId}
                  component="input"
                  hidden
                  disabled
                />

                <div>Důvod:</div>
                <Field name="reason" component={TextAreaInput} />
              </div>
              <hr />
              <div className="flex-row flex-end">
                <Button
                  type="button"
                  onClick={closeModal}
                  className="btn-modal"
                  variant="outline-secondary"
                >
                  Zpět
                </Button>
                <Button
                  type="submit"
                  variant="warning"
                  className="btn-modal"
                >{`Ano, zrušit transport`}</Button>
              </div>
            </Form>
          )}
        />
      </div>,
      {
        title: "Zrušení transportu vozu na druhou zkušebnu",
      }
    );
    return;
  };

  const canSelectCars = checkAuthRule(user?.userRoleId, "cars-page:selectcar");

  return (
    <Fragment>
      <div className="page-content" id="carlist">
        <div className="container-full">
          <div className="pageheader">
            <div className="pageheader__left">
              <h1>Seznam vozů</h1>
            </div>
          </div>
          <div className="carfilter">
            <CarFilter filter={carFilter} setFilter={setCarFilter} />
            <label className="right">
              Zobrazen{getMasculinePassiveVerbEnding(carsSorted.length)}{" "}
              {carsSorted.length} {getCarsString(carsSorted.length)} | Celkem{" "}
              {carsPage.count} {getCarsString(carsPage.count)} | Vybrán
              {getMasculinePassiveVerbEnding(selectedCars.length)}{" "}
              {selectedCars.length} {getCarsString(selectedCars.length)}
            </label>
          </div>
          <AlertMessage
            type="danger"
            heading="Nastala chyba při ukládání"
            show={showSubmitError}
            setShow={setShowSubmitError}
            messageList={errors}
          />
          <Table hover className="sticky--top" size="sm">
            <thead>
              <tr>
                <th>#</th>
                <th>Barva</th>
                <th>VDS číslo</th>
                <th>VIN kód</th>
                <th>KNR kód</th>
                <th title="Logistické číslo">Logistické č.</th>
                <th>Stav vozu</th>
                <th>Na zkušebně (od - do)</th>
                <th>Plánováno (od - do)</th>
                <th>Typ vozu</th>
                <th>Odběratel</th>
                <th>Typ zkoušky</th>
                <th>Akce</th>
              </tr>
            </thead>
            <tbody>
              {!isLoading &&
                carsSorted.map((car) => (
                  <tr
                    key={car.carId}
                    className={`${
                      selectedCars.find((x) => x.carId === car.carId)
                        ? "selected"
                        : ""
                    } ${
                      isTodayOrPast(car.plannedTo) && !car.availableTo
                        ? "row-to-unload"
                        : ""
                    }
                    ${car.placeId !== user?.placeId ? "transported-car" : ""}`}
                  >
                    <td>
                      {canSelectCars && (
                        <Form.Group controlId={`check-${car.carId}`}>
                          <Form.Check
                            type="checkbox"
                            onChange={(
                              event: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              changeSelected(
                                car.carId,
                                car.emissionTest,
                                event.currentTarget.checked
                              );
                            }}
                            checked={selectedCars.find(x => x.carId === car.carId) !== undefined}
                          />
                        </Form.Group>
                      )}
                    </td>
                    <td className="center">
                      {car.vipCar && (
                        <VipCar id={car.carId} style={{ marginLeft: "10px" }} />
                      )}

                      <CarColor
                        id={car.carId}
                        color={car.colorValue}
                        code={car.colorCode}
                        name={car.colorName}
                      />
                      {car.placeId !== user?.placeId && (
                        <PlaceBadge
                          name={car.placeCode}
                          description={"Vůz je na zkušebně "}
                        />
                        //  <div>{car.placeCode}</div>
                      )}
                    </td>
                    <td className="center">
                      <div>{car.integrationNum}</div>
                      <div className="text-font-light text-small">
                        {car.copsVDSNumber}
                      </div>
                    </td>
                    <td>
                      <VinCode
                        vin={car.vin}
                        important={car.vipCar || !!car.importantCarDesc}
                        breakLine={true}
                      />
                    </td>
                    <td>{car.prodIdentificationNum}</td>
                    <td>{car.logisticNum}</td>
                    <td>
                      {car.carState}
                      <div
                        className="plancar-status-icons plancar-status-icons--left"
                        style={{ marginLeft: "1rem" }}
                      >
                        {car.isTransported && (
                          <TransportedCarIcon
                            id={car.carId}
                            fromName={car.beforeTransportPlaceCode || ""}
                            toName={car.placeCode}
                          />
                        )}
                        {car.mentionIncomplete && !car.isComplete && (
                          <IncompleteCar id={car.carId} />
                        )}
                        {car.timeLimit !== 0 && (
                          <ExceededLimit
                            id={car.carId}
                            timeLimitType={car.timeLimit}
                          />
                        )}
                      </div>
                    </td>
                    <td className="flex-column">
                      <div title="Datum naskladnění a vyskladnění vozu.">
                        {car.availableFrom &&
                          moment(car.availableFrom).format("D.M.YY")}
                        {car.availableFrom && " - "}
                        {car.availableTo &&
                          moment(car.availableTo).format("D.M.YY")}
                      </div>
                      {car.emissionTest && (
                        <EmissionDate
                          id={car.carId}
                          planned={false}
                          dateFrom={car.emissionTestDateFrom}
                          dateTo={car.emissionTestDateTo}
                        />
                      )}
                    </td>
                    <td className="flex-column">
                      <div title="Datum první a poslední naplánované zkoušky.">
                        {car.plannedFrom &&
                          moment(car.plannedFrom).format("D.M.YY")}
                        {car.plannedFrom && " - "}
                        {car.plannedTo &&
                          moment(car.plannedTo).format("D.M.YY")}
                      </div>
                      {car.emissionTest && (
                        <EmissionDate
                          id={car.carId}
                          planned={true}
                          dateFrom={car.emissionTestPlanFrom}
                          dateTo={car.emissionTestPlanTo}
                        />
                      )}
                    </td>

                    <td title={car.testDestinationName}>
                      {car.testDestinationCode}
                    </td>
                    <td>{car.customerCode}</td>
                    <td>
                      <div className="flex-column">
                        <div>{car.testTypeName}</div>
                        <div>{car.importantCarDesc}</div>
                        {car.showEmissionTest && (
                          <div>
                            <EmissionCarIcon
                              id={car.carId}
                              emission={car.emissionTest}
                            />
                          </div>
                        )}
                      </div>
                    </td>
                    <td
                      style={{ width: "5rem" }}
                      className="flex-column flex-center"
                    >
                      <Button
                        as={Link}
                        style={{ marginRight: "8px", marginBottom: "2px" }}
                        size="sm"
                        to={`/car/${car.carId}`}
                        variant="outline-primary"
                      >
                        Detail
                      </Button>
                      {car.isTransported && !car.availableFrom && (
                        <Can
                          roleId={user?.userRoleId}
                          perform="cars-page:cancelTransport"
                          yes={
                            <Button
                              variant="outline-danger"
                              size="sm"
                              style={{ whiteSpace: "nowrap" }}
                              onClick={() => cancelTransportBtn(car.carId)}
                            >
                              Zrušit transport
                            </Button>
                          }
                        />
                      )}
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
          {!isLoading && !carsSorted.length && (
            <Alert variant="info">Nebyly nalezeny žádné vozy</Alert>
          )}
          {isLoading && <Loader />}
        </div>
      </div>
      <footer className="footer">
        <div className="footer-left">
          <ButtonGroup className={"btn-group"}>
            <Can
              roleId={user?.userRoleId}
              perform="cars-page:takein"
              yes={
                <Button
                  variant="primary"
                  disabled={selectedCars.length < 1}
                  type="submit"
                  onClick={takeCarsInTestroomBtn}
                >
                  <FontAwesomeIcon icon={faSignInAlt} /> Naskladnit
                  {selectedCars.length > 0 && (
                    <span
                      className="show-on-hover"
                      style={{ marginLeft: "0.4rem" }}
                    >
                      {selectedCars.length} {getCarsString(selectedCars.length)}
                    </span>
                  )}
                </Button>
              }
            />
            <Can
              roleId={user?.userRoleId}
              perform="cars-page:takeout"
              yes={
                <Button
                  variant="danger"
                  disabled={selectedCars.length < 1}
                  type="submit"
                  onClick={takeCarsOutOfTestroomBtn}
                >
                  <FontAwesomeIcon icon={faSignOutAlt} /> Vyskladnit
                  {selectedCars.length > 0 && (
                    <span
                      className="show-on-hover"
                      style={{ marginLeft: "0.4rem" }}
                    >
                      {selectedCars.length} {getCarsString(selectedCars.length)}
                    </span>
                  )}
                </Button>
              }
            />
          </ButtonGroup>
          <ButtonGroup className={"btn-group"} style={{ marginLeft: "20px" }}>
            <Can
              roleId={user?.userRoleId}
              perform="cars-page:emissionSend"
              yes={
                <Button
                  variant="outline-primary"
                  disabled={
                    !selectedCars.length ||
                    selectedCars.some((x) => !x.emissionTest)
                  }
                  type="submit"
                  onClick={sendCarsToEmissionBtn}
                >
                  <FontAwesomeIcon icon={faSignOutAlt} /> Odeslat na emise
                  {selectedCars.length > 0 && (
                    <span
                      className="show-on-hover"
                      style={{ marginLeft: "0.4rem" }}
                    >
                      {selectedCars.length} {getCarsString(selectedCars.length)}
                    </span>
                  )}
                </Button>
              }
            />
            <Can
              roleId={user?.userRoleId}
              perform="cars-page:emissionReceive"
              yes={
                <Button
                  variant="outline-danger"
                  disabled={
                    !selectedCars.length ||
                    selectedCars.some((x) => !x.emissionTest)
                  }
                  type="submit"
                  onClick={loadCarsFromEmissionBtn}
                >
                  <FontAwesomeIcon icon={faSignInAlt} /> Přijmout z emisí
                  {selectedCars.length > 0 && (
                    <span
                      className="show-on-hover"
                      style={{ marginLeft: "0.4rem" }}
                    >
                      {selectedCars.length} {getCarsString(selectedCars.length)}
                    </span>
                  )}
                </Button>
              }
            />
          </ButtonGroup>
          <Can
            roleId={user?.userRoleId}
            perform="cars-page:transport"
            yes={
              <Button
                variant="outline-info"
                disabled={selectedCars.length < 1}
                style={{ marginBottom: "15px", marginLeft: "20px" }}
                type="submit"
                onClick={transportBtn}
              >
                <FontAwesomeIcon icon={faTruckLoading} /> Transportovat
                {selectedCars.length > 0 && (
                  <span
                    className="show-on-hover"
                    style={{ marginLeft: "0.4rem" }}
                  >
                    {selectedCars.length} {getCarsString(selectedCars.length)}
                  </span>
                )}
              </Button>
            }
          />
          <Can
            roleId={user?.userRoleId}
            perform="cars-page:create"
            yes={
              <Button
                as={Link}
                to="/car/new"
                variant="outline-primary"
                style={{ marginBottom: "15px", marginLeft: "20px" }}
              >
                <FontAwesomeIcon icon={faPlus} /> Přidat vůz
              </Button>
            }
          />
        </div>
        <div className="footer-right">
          <Pages
            totalPages={carsPage.totalPages}
            currentPage={carsPage.currentPage}
            onClickEvent={loadCars}
            scrollToId={"carlist"}
          />
        </div>
      </footer>
    </Fragment>
  );
};

export default observer(CarList);
