import React, {FC} from 'react';
import {Col, Container, Form, Row} from "react-bootstrap";
import {Field, useForm} from "react-final-form";
import TextInput from "../../../../app/common/form/TextInput";
import CheckboxGroupInput from "../../../../app/common/form/CheckboxGroupInput";
import {IAcousticsProtocol, IAdditionalInfoArea, IProtocolCodeTableValues} from "../../../../app/models/protocol";
import AutocompleteInput from "../../../../app/common/form/AutocompleteInput";
import SelectInput from "../../../../app/common/form/SelectInput";
import CheckboxInput from "../../../../app/common/form/CheckboxInput";
import './SubHeader.scss'
import { IMaskInput } from 'react-imask';
import { transform } from 'typescript';

interface IProps {
    additionalInfoArea: IAdditionalInfoArea;
    protocolCodeTableValues: IProtocolCodeTableValues[];
    isEdit: boolean;
}

const SubHeader: FC<IProps> = (
    {
        additionalInfoArea,
        protocolCodeTableValues,
        isEdit,
    }
) => {
    
    const form = useForm<IAcousticsProtocol>();
    const isDisabled: boolean = form.getFieldState('')?.value || false;

    const protocolCodeTableAcousticsPacketId = form.getState().values.additionalInfoArea.additionalInfoValue.protocolCodeTableAcousticsPacketId;
    const protocolCodeTablePolygonId = form.getState().values.additionalInfoArea.additionalInfoValue.protocolCodeTablePolygonId;
    
    const  possibleAcousticsPacketValues = protocolCodeTableValues.filter(x => x.protocolCodeTableId === protocolCodeTableAcousticsPacketId)
                                                                  .map(value => value.codeTableValue)

    const acusticsForm = form.getState();
    const notPerformIinternalNoiseMeasurement = acusticsForm.values.additionalInfoArea.additionalInfoValue.internalNoiseMeasurementNotPerform;
    const notPerformeDrivingAssessment = acusticsForm.values.executedTestArea.drivingAssessmentGroup.notPerform || false;

    const resetWeatherRow = () => {
        const formState = form.getState();
        const _notPerformIinternalNoiseMeasurement = formState.values.additionalInfoArea.additionalInfoValue.internalNoiseMeasurementNotPerform;
        const _notPerformeDrivingAssessment = formState.values.executedTestArea.drivingAssessmentGroup.notPerform || false;
        const _internalNoiseMeasurementShowWeather = formState.values.additionalInfoArea.additionalInfoValue.enumInternalNoiseMeasurementValues.filter(x => x.checked && x.condition).length > 0;
        const _assessmentByDrivingShowWeather = formState.values.additionalInfoArea.additionalInfoValue.enumAssessmentByDrivingValues.filter(x => x.checked && x.condition).length > 0
        const result = (!_notPerformIinternalNoiseMeasurement || !_notPerformeDrivingAssessment) && (_internalNoiseMeasurementShowWeather || _assessmentByDrivingShowWeather);
        if(!!!result)
        {
            form.getFieldState(`additionalInfoArea.additionalInfoValue.polygon`)?.change(undefined);
            formState.values
            .additionalInfoArea
            .additionalInfoValue
            .enumWeatherValues
            .map((x, idx) => (
                form.getFieldState(`additionalInfoArea.additionalInfoValue.enumWeatherValues.[${idx}].checked`)?.change(false)
            ));
            form.getFieldState(`additionalInfoArea.additionalInfoValue.outdoorTemperature`)?.change("");
        }
        return result;
    };

    const showWeather = resetWeatherRow();

    return (
        <div className={'subheader'}>
        <Container>
            <Row noGutters>
                <Col xs={4}>
                    <Field
                        name="additionalInfoArea.additionalInfoValue.sequenceNumber"
                        title={'Pořadové číslo'}
                        component={TextInput}
                        titleWidth={4}
                        fieldWidth={5}
                        disabled={!isEdit}
                    />
                </Col>
                <Col xs={5}>
                    <Row noGutters>
                        <Col xs={4}>
                            <Form.Label>Protokol číslo</Form.Label>
                        </Col>
                        <Col style={{paddingLeft: '15px'}}>
                            <Field
                                name="additionalInfoArea.additionalInfoValue.protocolNo"
                                render={({input}) => {
                                    return <IMaskInput
                                        className={'form-control'}
                                        placeholderChar="_"
                                        lazy={false}
                                        mask={"***/000/00"}
                                        overwrite={true}
                                        disabled={!isEdit}
                                        autofix={false}
                                        style={{textTransform:'uppercase'}}
                                        onAccept={(value, mask) => form.change('additionalInfoArea.additionalInfoValue.protocolNo', value)}
                                        {...input}
                                    />
                                }}
                                disabled={!isEdit}
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row noGutters>
                <Col>
                    <Field
                        name="additionalInfoArea.additionalInfoValue.frontEngineNumber"
                        title={'Číslo motoru přední'}
                        titleWidth={4}
                        fieldWidth={8}
                        component={TextInput}
                        disabled={!isEdit}
                    />
                </Col>
                <Col>
                    <Field
                        name="additionalInfoArea.additionalInfoValue.rearEngineNumber"
                        title={'Číslo motoru zadní'}
                        titleWidth={4}
                        fieldWidth={8}
                        component={TextInput}
                        disabled={!isEdit}
                    />
                </Col>
                <Col>
                    <Field
                        name="additionalInfoArea.additionalInfoValue.acousticsPacket"
                        title={'Akustický paket'}
                        items={possibleAcousticsPacketValues}
                        component={AutocompleteInput}
                        disabled={!isEdit}
                    />
                </Col>
            </Row>
            <Row noGutters>
                <Col sm={2}></Col>
                <Col sm={3}>
                    <small className="text-muted">
                        Před zkouškou
                    </small>
                </Col>
                <Col sm={3}>
                    <small className="text-muted">
                        Po zkoušce
                    </small>
                </Col>
                <Col></Col>
            </Row>
            <Row noGutters>
                <Col xs={2}>
                    <Form.Label>
                        Stav tachometru (km)
                    </Form.Label>
                </Col>
                <Col xs={3}>
                    <Field
                        name="additionalInfoArea.additionalInfoValue.speedometerValueBefore"
                        component={TextInput}
                        disabled={!isEdit}
                        fieldWidth={8}
                    />
                </Col>
                <Col xs={3}>
                    <Field
                        name="additionalInfoArea.additionalInfoValue.speedometerValueAfter"
                        component={TextInput}
                        disabled={!isEdit}
                        fieldWidth={8}
                    />
                </Col>
            </Row>
            
            <Row className='mt-2' noGutters>
                <Col sm={2}>
                    <Form.Label>
                        Měření vnitřní hluk
                    </Form.Label>
                </Col>
                <Col sm={8}>
                    <Row>
                    {!notPerformIinternalNoiseMeasurement &&
                        additionalInfoArea.additionalInfoValue.enumInternalNoiseMeasurementValues
                        .map((internalNoiseMeasurement, idx) =>
                        (
                            <Col sm={3} key={idx} className='form-label'>
                                <Field
                                    name={`additionalInfoArea.additionalInfoValue.enumInternalNoiseMeasurementValues[${idx}].checked`}
                                    title={internalNoiseMeasurement.name}
                                    type="checkbox"
                                    component={CheckboxGroupInput}
                                    inputOnChange={() => {
                                        resetWeatherRow();
                                     }}
                                    disabled={!isEdit || isDisabled}
                                />
                            </Col>
                        ))}
                    </Row>
                </Col>
                <Col sm={2}>
                    <Field
                        name={`additionalInfoArea.additionalInfoValue.internalNoiseMeasurementNotPerform`}
                        title="Neprovádět"
                        options={[
                            {key: 1, value: "ano"},
                            {key: 0, value: " ne "},
                        ]}
                        format={(v) => (v ? 1 : 0)}
                        parse={(v) => (!!v)}
                        type={"checkbox"}
                        component={CheckboxInput}
                        inputOnChange={() => {
                            const notPerform = form.getState().values.additionalInfoArea.additionalInfoValue.internalNoiseMeasurementNotPerform;
                            if(notPerform)
                            {
                                form.getState().values
                                    .additionalInfoArea
                                    .additionalInfoValue
                                    .enumInternalNoiseMeasurementValues
                                    .map((x, idx) => (
                                        form.getFieldState(`additionalInfoArea.additionalInfoValue.enumInternalNoiseMeasurementValues[${idx}].checked`)?.change(false)
                                    ));
                                resetWeatherRow();
                            }
                        }}
                        disabled={!isEdit}
                        labelWidth={8}
                        valueWidth={4}
                    />
                </Col>
            </Row>
            <Row className='mt-2' noGutters>
                <Col sm={2}>
                    <Form.Label>
                        Posouzení jízdou
                    </Form.Label>
                </Col>
                <Col sm={8}>
                    <Row>
                    {!notPerformeDrivingAssessment &&
                        additionalInfoArea.additionalInfoValue.enumAssessmentByDrivingValues
                        .map((assessmentByDriving, idx) =>
                        (
                            <Col sm={3} key={idx} className='form-label'>
                                <Field
                                    name={`additionalInfoArea.additionalInfoValue.enumAssessmentByDrivingValues[${idx}].checked`}
                                    title={assessmentByDriving.name}
                                    type="checkbox"
                                    component={CheckboxGroupInput}
                                    inputOnChange={() => {
                                        resetWeatherRow();
                                     }}
                                    disabled={!isEdit || isDisabled}
                                />
                            </Col>
                        ))}
                    </Row>
                </Col>
                <Col sm={2}>
                    <Field
                        name={`executedTestArea.drivingAssessmentGroup.notPerform`}
                        title="Neprovádět"
                        options={[
                            {key: 1, value: "ano"},
                            {key: 0, value: " ne "},
                        ]}
                        format={(v) => (v ? 1 : 0)}
                        parse={(v) => (!!v)}
                        type={"checkbox"}
                        component={CheckboxInput}
                        inputOnChange={() => {
                            const notPerform = form.getState().values.executedTestArea.drivingAssessmentGroup.notPerform;
                            if(notPerform)
                            {
                                form.getState().values
                                    .additionalInfoArea
                                    .additionalInfoValue
                                    .enumAssessmentByDrivingValues
                                    .map((x, idx) => (
                                        form.getFieldState(`additionalInfoArea.additionalInfoValue.enumAssessmentByDrivingValues[${idx}].checked`)?.change(false)
                                    ));
                                resetWeatherRow();
                            }
                        }}
                        disabled={!isEdit}
                        labelWidth={8}
                        valueWidth={4}
                    />
                </Col>
            </Row>
            {showWeather &&
                <Row className='mt-2' noGutters>
                    <Col sm='2'>
                        <Field
                            name={`additionalInfoArea.additionalInfoValue.polygon`}
                            size="sm"
                            placeholder="WeatherPolygon"
                            options={protocolCodeTableValues.filter(x => x.protocolCodeTableId === protocolCodeTablePolygonId).map((value) => {
                                return {
                                    key: value.id,
                                    value: value.codeTableValue,
                                }
                            })}
                            component={SelectInput}
                            isEmptyOption={true}
                            disabled={!isEdit || isDisabled}
                        />
                    </Col>
                    <Col sm={6}>
                        <Row>
                            {additionalInfoArea.additionalInfoValue.enumWeatherValues.map((condition, index) =>
                                (
                                    <Col key={index}>
                                        <Field
                                            name={`additionalInfoArea.additionalInfoValue.enumWeatherValues.[${index}].checked`}
                                            initialValue={false}
                                            title={condition.name}
                                            type="checkbox"
                                            component={CheckboxGroupInput}
                                            disabled={!isEdit || isDisabled}
                                        />
                                    </Col>
                                ))}
                        </Row>
                    </Col>
                    <Col>
                        <Field
                            name="additionalInfoArea.additionalInfoValue.outdoorTemperature"
                            title='Vnější teplota (°C)'
                            component={TextInput}
                            titleWidth={6}
                            fieldWidth={6}
                            maxLength={5}
                            disabled={!isEdit}
                        />
                    </Col>
                </Row>
            }
        </Container>
        </div>
    );
};

export default SubHeader;
