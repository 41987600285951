import React from "react";
import { Pagination } from "react-bootstrap";

interface IProps {
  totalPages: number;
  currentPage: number;
  onClickEvent: (page: number) => void;
  scrollToId?: string;
}
const Pages: React.FC<IProps> = ({
  totalPages,
  currentPage,
  onClickEvent,
  scrollToId,
}) => {
  const pages = [];
  pages.push(
    <Pagination.Prev
      key={0}
      disabled={currentPage <= 1}
      onClick={() => {
        onClickEvent(currentPage - 1);
        if (scrollToId)
          document
            ?.getElementById(scrollToId!)
            ?.scrollTo({ top: 0, behavior: "smooth" });
      }}
    />
  );
  if (totalPages > 10) {
    if (currentPage < 4 || currentPage > totalPages - 3) {
      for (let number = 1; number <= 4; number++) {
        pages.push(
          <Pagination.Item
            key={number}
            active={number === currentPage}
            onClick={() => {
              onClickEvent(number);
              if (scrollToId)
                document
                  ?.getElementById(scrollToId!)
                  ?.scrollTo({ top: 0, behavior: "smooth" });
            }}
          >
            {number}
          </Pagination.Item>
        );
      }
      pages.push(<Pagination.Ellipsis disabled={true} key={"el-middle"} />);

      for (let number = totalPages - 3; number <= totalPages; number++) {
        pages.push(
          <Pagination.Item
            key={number}
            active={number === currentPage}
            onClick={() => {
              onClickEvent(number);
              if (scrollToId)
                document
                  ?.getElementById(scrollToId!)
                  ?.scrollTo({ top: 0, behavior: "smooth" });
            }}
          >
            {number}
          </Pagination.Item>
        );
      }
    } else {
      pages.push(
        <Pagination.Item
          key={1}
          active={1 === currentPage}
          onClick={() => {
            onClickEvent(1);
            if (scrollToId)
              document
                ?.getElementById(scrollToId!)
                ?.scrollTo({ top: 0, behavior: "smooth" });
          }}
        >
          {1}
        </Pagination.Item>
      );
      pages.push(<Pagination.Ellipsis disabled={true} key={"el-left"} />);
      for (let number = currentPage - 2; number <= currentPage + 2; number++) {
        pages.push(
          <Pagination.Item
            key={number}
            active={number === currentPage}
            onClick={() => {
              onClickEvent(number);
              if (scrollToId)
                document
                  ?.getElementById(scrollToId!)
                  ?.scrollTo({ top: 0, behavior: "smooth" });
            }}
          >
            {number}
          </Pagination.Item>
        );
      }
      pages.push(<Pagination.Ellipsis disabled={true} key={"el-right"} />);

      pages.push(
        <Pagination.Item
          key={totalPages}
          active={totalPages === currentPage}
          onClick={() => {
            onClickEvent(totalPages);
            if (scrollToId)
              document
                ?.getElementById(scrollToId!)
                ?.scrollTo({ top: 0, behavior: "smooth" });
          }}
        >
          {totalPages}
        </Pagination.Item>
      );
    }
  } else {
    for (let number = 1; number <= totalPages; number++) {
      pages.push(
        <Pagination.Item
          key={number}
          active={number === currentPage}
          onClick={() => {
            onClickEvent(number);
            if (scrollToId)
              document
                ?.getElementById(scrollToId!)
                ?.scrollTo({ top: 0, behavior: "smooth" });
          }}
        >
          {number}
        </Pagination.Item>
      );
    }
  }
  pages.push(
    <Pagination.Next
      key={totalPages + 1}
      disabled={currentPage >= totalPages}
      onClick={() => {
        onClickEvent(currentPage + 1);
        if (scrollToId)
          document
            ?.getElementById(scrollToId!)
            ?.scrollTo({ top: 0, behavior: "smooth" });
      }}
    />
  );
  return <Pagination>{pages}</Pagination>;
};

export default Pages;
