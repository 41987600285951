import React, { Fragment } from "react";
import { IEnumItem } from "../../models/enum";
import { FieldRenderProps } from "react-final-form";
import { Form, Row, Col } from "react-bootstrap";
import "./ColorPicker.scss";

type Props = FieldRenderProps<number, any>;
const ColorPicker = ({ input, title, options, disabled }: any) => {
  return (
    <Fragment>
      {title && (
        <Form.Group controlId={input.name}>
          <Row>
            <Col xl={4} lg={4} md={4} sm={4} xs={4} className="u-text-right">
              {title && <Form.Label>{title}</Form.Label>}
            </Col>
            <Col xl={8} lg={8} md={8} sm={8} xs={8}>
              <div className="colorpicker">
                {options.map((col: IEnumItem, index: number) => (
                  <Fragment key={`${col.key}`}>
                    <div
                      id={`${col.key}`}
                      className={`colorpicker__option colorpicker__option--${col.value} ${
                        input.value === col.key
                          ? "colorpicker__option--selected"
                          : ""
                      } ${disabled && "colorpicker__option--disabled"}`}
                      onClick={() => {
                        !disabled && input.onChange(col.key);
                      }}
                    ></div>
                    {index + 1 === Math.ceil(options.length / 2) && (
                      <div className="colorpicker__option--break"></div>
                    )}
                  </Fragment>
                ))}
              </div>
            </Col>
          </Row>
        </Form.Group>
      )}
    </Fragment>
  );
};

export default ColorPicker;
