import React, { Fragment, useContext, useEffect, useState } from "react";
import {
  Alert,
  Badge,
  Button,
  ButtonGroup,
  Col,
  Row,
  Table,
} from "react-bootstrap";
import Pages from "../../app/common/utils/Pages";
import { RootStoreContext } from "../../app/stores/rootStore";
import EmissionCarRow from "./EmissionCarRow";
import { Form as FinalForm, Field } from "react-final-form";
import MultiSelectInput from "../../app/common/form/MultiSelectInput";
import { observer } from "mobx-react-lite";
import {
  getMasculinePassiveVerbEnding,
  getCarsString,
} from "../../app/common/utils/czechDeclension";
import "./EmissionCarsPage.scss";
import AlertMessage from "../alert/AlertMessage";
import Loader from "../loader/Loader";
import { IEmissionCarFilter, IEmissionCarForm } from "../../app/models/car";
import { Prompt } from "react-router-dom";
import TextInput from "../../app/common/form/TextInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faTimes, faFilter } from "@fortawesome/free-solid-svg-icons";

const EmissionCarsPage: React.FC = () => {
  const [updatedEmissionCars, setUpdatedEmissionCars] = useState<
    IEmissionCarForm[]
  >([]);

  const [errors, setErrors] = useState([]);
  const [showSubmitError, setShowSubmitError] = useState(false);

  const rootStore = useContext(RootStoreContext);
  const {
    loadEmissionCars,
    loadCarsToDecideCount,
    emissionCars: carsSorted,
    isLoading,
    emissionCarsPage: carsPage,
    saveEmissionCars,
    setDedicedCarsView,
    decidedCarsView,
    carsToDecideCount,
    setCurrentPage,
    isSaving,
    setFilter,
    filter,
  } = rootStore.pageEmissionCarsStore;

  const { loadEnums, enums } = rootStore.enumStore;

  useEffect(() => {
    loadEmissionCars();
    loadEnums();
    loadCarsToDecideCount();
  }, [loadEmissionCars, loadEnums, enums, loadCarsToDecideCount]);

  const saveEmissionCarChanges = async () => {
    try {
      await saveEmissionCars(updatedEmissionCars);
      // reload page
      setCurrentPage(1);
      await loadEmissionCars();
      await loadCarsToDecideCount();
      setUpdatedEmissionCars([]);
    } catch (error) {
      console.log(error);
      setErrors(Object.values(error.response.data.errors));
      setShowSubmitError(true);
    }
  };

  const setCarFilter = async (filter: IEmissionCarFilter) => {
   // console.log(filter);
    setFilter(filter);
    await loadEmissionCars();
  };

  const resetFilter = () => {
    const carFilter: IEmissionCarFilter = {
      vin: undefined,
      logisticNum: undefined,
      carModels: [],
      carEngines:[]
    };
    setCarFilter(carFilter);
  };

  const [show, setShow] = useState(false);

  const activeFilterLength = Object.values(filter)?.filter(
    (v) => v && (v === true || v.length)
  ).length;

  const carModelReset = (args: any, state: any, utils: any) => {
    utils.changeValue(state, "carEngines", () => []);
  };

  return (
    <Fragment>
      <div className="page-content" id="emissionCars">
        <Prompt
          when={!!updatedEmissionCars.length}
          message="Na stránce byly provedeny změny, které nejsou uloženy. Opravdu chcete odejít?"
        />
        <div className="container-full">
          <div className="pageheader">
            <div className="pageheader__left">
              <h1>Správa emisních vozů</h1>
            </div>
            <div className="pageheader__center">
              <ButtonGroup>
                <Button
                  variant={!decidedCarsView ? "primary" : "outline-primary"}
                  onClick={() => setDedicedCarsView(false)}
                >
                  Vozy k rozhodnutí{" "}
                  <Badge pill variant={decidedCarsView ? "primary" : "light"}>
                    {carsToDecideCount}
                  </Badge>
                </Button>
                <Button
                  variant={decidedCarsView ? "primary" : "outline-primary"}
                  onClick={() => setDedicedCarsView(true)}
                >
                  Všechny rozhodnuté vozy
                </Button>
              </ButtonGroup>
            </div>
            <div className="pageheader__right">
            <Button
                variant="success"
                onClick={() => setShow(!show)}
                title="Zobrazit / skrýt filtr"
            >
                <FontAwesomeIcon icon={faFilter} />
                {filter && Object.values(filter) && (
                <sup>
                    <Badge pill variant="danger">
                    {activeFilterLength}
                    </Badge>
                </sup>
                )}
            </Button>
            {activeFilterLength > 0 && (
                <Button
                size="sm"
                variant="danger"
                onClick={resetFilter}
                title="Zrušit všechny filtry"
                >
                <FontAwesomeIcon icon={faTimes} />
                </Button>
            )}
            </div>
          </div>
          
          {show && (
          <div className="filter-container__filters">
            {isLoading && <Loader />}
            {enums && (
              <FinalForm
                onSubmit={setCarFilter}
                mutators={{ carModelReset }}
                initialValues={filter}
                render={({ handleSubmit, form, values }) => {
                  return (
                    <form onSubmit={handleSubmit}>
                      <Row>
                            <Col md={4}>
                              <Field
                                title="VIN"
                                name="vin"
                                component={TextInput}
                                size="sm"
                                />
                              </Col>
                              <Col md={4}>
                                <Field
                                title="Logistické číslo"
                                name="logisticNum"
                                component={TextInput}
                                size="sm"
                                />
                            </Col>
                            <Col md={4}>
                                <Field
                                    title="Emisní norma"
                                    name="emissionStandard"
                                    component={TextInput}
                                    size="sm"
                                />
                            </Col>
                            </Row>
                            <Row>

                            <Col md={4}>
                              <Field
                                title="Model"
                                name="carModels"
                                size="sm"
                                placeholder=""
                                format={(value) =>
                                  value &&
                                  value.map((v: any) => ({
                                    value: v.id,
                                    label: v.name,
                                  }))
                                }
                                parse={(value) =>
                                  value &&
                                  value.map((v: any) => ({
                                    id: v.value,
                                    name: v.label,
                                  }))
                                }
                                inputOnChange={form.mutators.carModelReset}
                                options={enums.carModels
                                  .slice()
                                  .filter((m) => m.isActive)
                                  .sort((a, b) => (a.name < b.name ? -1 : 1))
                                  .map((m) => ({
                                    value: m.id,
                                    label: m.name,
                                  }))}
                                component={MultiSelectInput}
                              />
                            </Col>

                            <Col md={4}>
                              <Field
                                title="Motorizace"
                                name="carEngines"
                                placeholder={
                                  values.carModels &&
                                  values.carModels.length === 1
                                    ? ""
                                    : "Vyberte 1 model vozu"
                                }
                                size="sm"
                                format={(value) =>
                                  value &&
                                  value.map((v: any) => ({
                                    value: v.id,
                                    label: v.name,
                                  }))
                                }
                                parse={(value) =>
                                  value &&
                                  value.map((v: any) => ({
                                    id: v.value,
                                    name: v.label,
                                  }))
                                }
                                options={enums.carEngines
                                  .slice()
                                  .filter(
                                    (m) =>
                                      m.isActive &&
                                      values.carModels &&
                                      values.carModels.length === 1 &&
                                      m.carModelId === values.carModels[0].id
                                  )
                                  .sort((a, b) => (a.name < b.name ? -1 : 1))
                                  .map((m) => ({
                                    value: m.id,
                                    label: m.name,
                                  }))}
                                component={MultiSelectInput}
                              />
                            </Col>

                              <div className="align-right">
                                  <Button
                                      type="submit"
                                      variant="outline-primary"
                                      className=""
                                      size="sm"
                                      onClick={() => {
                                          form.submit();
                                      }}
                                      style={{ minWidth: "3rem", marginRight: "0.5rem" }}
                                  >
                                      <FontAwesomeIcon icon={faSearch} />
                                  </Button>

                                  <Button
                                      type="button"
                                      variant="outline-danger"
                                      className=""
                                      size="sm"
                                      style={{ minWidth: "3rem" }}
                                      onClick={() => {
                                          form.reset();
                                          resetFilter();
                                      }}
                                  >
                                      <FontAwesomeIcon icon={faTimes} />
                                  </Button>
                              </div>
                            
                        
                          </Row>

                    </form>
                  );
                }}
              />
            )}
          </div>
        )}

          <div className="align__right">
            Zobrazen{getMasculinePassiveVerbEnding(carsSorted.length)}{" "}
            {carsSorted.length} {getCarsString(carsSorted.length)} | Celkem{" "}
            {carsPage.count} {getCarsString(carsPage.count)} | Upraven
            {getMasculinePassiveVerbEnding(updatedEmissionCars.length)}{" "}
            {updatedEmissionCars.length}{" "}
            {getCarsString(updatedEmissionCars.length)}
          </div>

          <AlertMessage
            type="danger"
            heading="Nastala chyba při ukládání"
            show={showSubmitError}
            setShow={setShowSubmitError}
            messageList={errors}
          />

          <Table hover className="sticky--top" size="sm">
            <thead>
              <tr>
                <th>VDS číslo</th>
                <th>VIN kód</th>
                <th title="Logistické číslo">Logistické č.</th>
                <th>Stav vozu</th>
                <th>Emisní vůz</th>
                <th>Emise od</th>
                <th>Emise do</th>
                <th>Odběratel</th>
                <th style={{ maxWidth: "5rem" }}>Model</th>
                <th>Motorizace</th>
                <th title="Emisní norma">Em. norma</th>
                <th>Převodovka</th>
                <th>Akce</th>
              </tr>
            </thead>
            <tbody>
              {!isLoading &&
                carsSorted
                  .filter(
                    (c) =>
                      c.customerCode.toUpperCase().startsWith("GQ") &&
                      c.testDestinationId === 2
                  )
                  .map((car) => (
                    <EmissionCarRow
                      car={car}
                      key={car.carId}
                      updatedEmissionCars={updatedEmissionCars}
                      setUpdatedEmissionCars={setUpdatedEmissionCars}
                    />
                  ))}
            </tbody>
          </Table>

          {!isLoading && !carsSorted.length && (
            <Alert variant="info">Nebyly nalezeny žádné vozy</Alert>
          )}
          {isLoading && <Loader />}
        </div>
      </div>
      <footer className="footer">
        <div className="footer-left">
          <Button
            variant="primary"
            type="button"
            disabled={isSaving || !updatedEmissionCars.length}
            style={{ marginRight: "1rem", minWidth: "15rem" }}
            onClick={() => {
              saveEmissionCarChanges();
            }}
          >
            Uložit {updatedEmissionCars.length}{" "}
            {getCarsString(updatedEmissionCars.length)}
          </Button>
          {!!updatedEmissionCars.length && (
            <Button
              variant="outline-secondary"
              type="button"
              disabled={isSaving}
              onClick={() => {
                setUpdatedEmissionCars([]);
              }}
            >
              Zahodit změny
            </Button>
          )}
        </div>
        <div className="footer-right">
          <Pages
            totalPages={carsPage.totalPages}
            currentPage={carsPage.currentPage}
            onClickEvent={setCurrentPage}
            scrollToId={"carlist"}
          />
        </div>
      </footer>
    </Fragment>
  );
};

export default observer(EmissionCarsPage);
