import { ICar, NewCar, ICarProtocolListItem } from "./car";
import { IWorker } from "./carTest";
import { ICarTestConflict } from "./carTest"

export interface ICarAnalysis {
    carAnalysisId?: number;
    carId?: number;
    car: ICar | undefined | NewCar;
    expertGroupId: number;
    expertGroupCode: string;
    expertGroupName: string;
    analysisTypeId: number;
    analysisTypeName: string;
    description: string;
    assignedWorkersId: number[];
    assignedWorkers?: IWorker[];
    terms?: ICarAnalysisTerm[];
    carTestConflicts: ICarTestConflict[];
    protocolList: ICarProtocolListItem[];
}

export interface ICarAnalysisTerm {
    carAnalysisTermId?: number;
    carAnalysisId?: number;
    carId?: number;
    expertGroupId?: number;
    termStateId: number;
    termStateName: string;
    startAt?: Date;
    endAt?: Date;
    length?: number;
    wholeDay: boolean;
    note?: string;
    rownumber: number;
    isActive:boolean;
}

export interface ICarAnalysisForm {
    carAnalysis: ICarAnalysis;
    isConfirmed?: boolean;
}

export const CreateNewCarAnalysis = (aExpertGroupId: number) : ICarAnalysis => {
    const analysis: ICarAnalysis = {
      carAnalysisId: undefined,
      carId: undefined,
      car: new NewCar(),
      expertGroupId: aExpertGroupId,
      expertGroupCode: "",
      expertGroupName: "",
      analysisTypeId: 1,
      analysisTypeName: "",
      description: "",
      assignedWorkersId: [],
      assignedWorkers: [],
      terms: [],
      carTestConflicts: [],
      protocolList: []
    }
    const term = CreateNewCarAnalysisTerm(0);
    analysis.terms?.push(term);
    return analysis;
  }

  export const CreateNewCarAnalysisTerm = (rowNumber: number) : ICarAnalysisTerm =>
  {
    const term: ICarAnalysisTerm = {
      termStateId: 2,
      termStateName: "",
      startAt: undefined,
      endAt: undefined,
      length: undefined,
      wholeDay: false,
      note: undefined,
      rownumber: rowNumber,
      isActive: true
    }
    return term;
  }

  export const CopyCarAnalysis = (origAnalysis: ICarAnalysis) : ICarAnalysis => {
    const analysis: ICarAnalysis = {
      carAnalysisId: origAnalysis.carAnalysisId,
      carId: origAnalysis.carId,
      car: origAnalysis.car,
      expertGroupId: origAnalysis.expertGroupId,
      expertGroupCode: origAnalysis.expertGroupCode,
      expertGroupName: origAnalysis.expertGroupName,
      analysisTypeId: origAnalysis.analysisTypeId,
      analysisTypeName: origAnalysis.analysisTypeName,
      description: origAnalysis.description,
      assignedWorkersId: origAnalysis.assignedWorkersId,
      assignedWorkers: origAnalysis.assignedWorkers,
      terms: [],
      carTestConflicts: [],
      protocolList: []
    }

    return analysis;
  }

  export const CopyCarAnalysisTerm = (t: ICarAnalysisTerm) : ICarAnalysisTerm =>
  {
    const term: ICarAnalysisTerm = {
      carId: t.carId,
      carAnalysisId: t.carAnalysisId,
      carAnalysisTermId: t.carAnalysisTermId,
      termStateId: t.termStateId,
      termStateName: t.termStateName,
      startAt: t.startAt,
      endAt: t.endAt,
      length: t.length,
      wholeDay: t.wholeDay,
      note: t.note,
      rownumber: t.rownumber,
      isActive: t.isActive
    }
    return term;
  }