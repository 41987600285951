import React from "react";
import { Container } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestion,  faUserShield } from "@fortawesome/free-solid-svg-icons";

const Unauthorized = () => {
  return (
    <div>
      <Container style={{ marginTop: "2rem" }}>
        <div>
          <div
            style={{
              position: "absolute",
              left: "10%",
              top: "20%",
              fontSize: "10rem",
            }}
          >
            403
          </div>
          <div>
            <div style={{ position: "relative", left: "50%" }}>
              <FontAwesomeIcon size={"7x"} icon={faQuestion} />
            </div>
            <div style={{ position: "relative", left: "30%" }}>
              <FontAwesomeIcon size={"9x"} icon={faUserShield} color={"green"} />
            </div>
          </div>
          <div style={{ marginTop: "2rem" }}>
            <h1 className={"display-4"}>
            Nedostatečné oprávnění...
            </h1>
            <p className={"lead"}>
              Omlouváme se, ale pro přístup na stránku nemáte potřebná oprávnění. Pro zobrazení se musíte přihlásit jako jiný uživatel.
            </p>
          </div>
          
        </div>
      </Container>
    </div>
  );
};

export default Unauthorized;
