import "./ProtocolsTab.scss";

import React, {Fragment, useContext, useEffect, useState} from "react";
import {Form as FinalForm} from "react-final-form";

import {Col, Form, Row, Tab, Tabs} from "react-bootstrap";
import {IEnumItem, IEnums} from "../../../app/models/enum";
import {AreaEnumDTO, GroupEnumDTO, PartEnumDTO} from "../../../app/models/protocol"
import ProtocolAreaTab from "../protocols/ProtocolAreaTab";
import ProtocolPartGroupTab from "../protocols/ProtocolPartGroupTab";
import ProtocolPartTab from "../protocols/ProtocolPartTab";
import ProtocolRuleTab from "../protocols/ProtocolRuleTab";
import ProtocolTypeTab from "../protocols/ProtocolTypeTab";
import {observer} from "mobx-react-lite";
import {RootStoreContext} from "../../../app/stores/rootStore";
import {userRoles} from "../../../app/models/auth";

interface IProps {
  enums: IEnums | undefined;
}

const ProtocolsTab: React.FC<IProps> = ({ enums }) => {
  // manage tabs selection and set default tab
  const rootStore = useContext(RootStoreContext);
  const {user} = rootStore.authStore;
  const {
    loadProtocolEnums,
    protocolAreaEnumsRegistry,
    protocolGroupEnumsRegistry,
    protocolPartEnumsRegistry,
  } = rootStore.protocolStore;
  const [key, setKey] = useState("protocolType");

  const [selectedProtocolTypeId, setSelectedProtocolTypeId] = useState<number>();
  const [selectedCarModelId, setSelectedCarModelId] = useState<number>();
  const [selectedCarBodyTypeId, setSelectedCarBodyTypelId] = useState<number>();
  const [areaEnums, setAreaEnums] = useState<AreaEnumDTO[]>();
  const [groupEnums, setGroupEnums] = useState<GroupEnumDTO[]>();
  const [partEnums, setPartEnums] = useState<PartEnumDTO[]>();

  const carBodyExpertGroupIds = [ 7, 15 ];
  const electroExpertGroupIds = [ 5, 12 ];
  const waterResistanceExpertGroupIds = [ 6, 11 ];
  const conformityExpertGroupIds = [ 8, 13 ];
  const ridingNoisesExpertGroupIds = [ 4, 10 ];
  const acousticsExpertGroupIds = [ 3, 10 ];
  const drivingCharacteristics = [ 2 ]

  const checkUserGroup = (typeId: number, userExpertGroupId: number | undefined) : boolean => {
    if(!userExpertGroupId) return false;
    if(typeId === 2 && carBodyExpertGroupIds.includes(userExpertGroupId)) return true;
    if(typeId === 3 && electroExpertGroupIds.includes(userExpertGroupId)) return true;
    if(typeId === 4 && electroExpertGroupIds.includes(userExpertGroupId)) return true;
    if(typeId === 5 && waterResistanceExpertGroupIds.includes(userExpertGroupId)) return true;
    if(typeId === 6 && conformityExpertGroupIds.includes(userExpertGroupId)) return true;
    if(typeId === 7 && ridingNoisesExpertGroupIds.includes(userExpertGroupId)) return true;
    if(typeId === 8 && acousticsExpertGroupIds.includes(userExpertGroupId)) return true;
    if(typeId === 9 && drivingCharacteristics.includes(userExpertGroupId)) return true;
    return false;
  };

  const handleOnChangeProtocolType = (protocolTypeId: number) => {
    loadProtocolEnums(protocolTypeId).then(() => {
      const areas = protocolAreaEnumsRegistry.get(protocolTypeId);
      const groups = protocolGroupEnumsRegistry.get(protocolTypeId);
      const parts = protocolPartEnumsRegistry.get(protocolTypeId);
      setAreaEnums(areas);
      setGroupEnums(groups);
      setPartEnums(parts);
    }
    );
  };

  useEffect(() => {
    var protocolTypes: IEnumItem[] = [];

    if(enums?.protocolTypes && user?.userRoleId === userRoles.planAdministrator){
      protocolTypes = enums?.protocolTypes;
    }

    if(enums?.protocolTypes && user?.userRoleId === userRoles.expertGroupLeader){
      const filtredTypes: IEnumItem[] = [];
      enums?.protocolTypes.forEach((item) => {
        const canEditProtocolType: boolean = checkUserGroup(item.key, user?.expertGroupId);
        if (canEditProtocolType) filtredTypes.push(item);
      });
      protocolTypes = filtredTypes;
    }

    if (protocolTypes && protocolTypes.length > 0) {
      setSelectedProtocolTypeId(protocolTypes[0]?.key);
      handleOnChangeProtocolType(protocolTypes[0]?.key);
    }
  }, [enums, setSelectedProtocolTypeId]);

  const handleFinalFormSubmit = () => {
  };

  const handleOnChangeProtocolAreas = (updatedAreas:  AreaEnumDTO[]) => {
    setAreaEnums(updatedAreas);
  };

  const handleOnChangeProtocolGroups = (updatedGroups:  GroupEnumDTO[]) => {
    setGroupEnums(updatedGroups);
  };

  return (
    <div id="protocols-tabs">
      <h2>Konfigurace protokolů</h2>
      <FinalForm
        onSubmit={handleFinalFormSubmit}
        mutators={{
          resetCarModelId: (args, state, utils) => {
            utils.changeValue(state, "carModel", () => undefined);
          },
          resetCarBodyTypeId: (args, state, utils) => {
            utils.changeValue(state, "carBodyType", () => undefined);
          },
        }}
        render={({ form }) => (
          <Form >
            <Row style={{ paddingBottom: "10px" }}>
              <Col sm={2} className="u-text-right">
                <Form.Label>Typ protokolu</Form.Label>
               </Col>
              <Col sm={10}>
              {enums && (
                <Form.Control
                  as="select"
                  style={{ width: "30rem" }}
                  name={"protocolType"}
                  onChange={(e: any) => {
                    const typeId: number = Number.parseInt(e.target.value);
                    setSelectedProtocolTypeId(typeId);
                    handleOnChangeProtocolType(typeId);
                    form.mutators.resetCarModelId(e);
                    form.mutators.resetCarBodyTypeId(e);
                    setSelectedCarModelId(undefined);
                    setSelectedCarBodyTypelId(undefined);
                  }}
                  value={selectedProtocolTypeId?.toString()}
                  //disabled={user?.userRoleId !== userRoles.planAdministrator}
                >
                  {enums.protocolTypes
                  .slice()
                  .filter((m) =>
                      (user?.userRoleId === userRoles.planAdministrator ) ||
                      (user?.userRoleId === userRoles.expertGroupLeader && (checkUserGroup(m.key, user?.expertGroupId)))
                    )
                    .map((option) => (
                    <option key={option.key} value={option.key}>
                      {option.value}
                    </option>
                  ))}
                </Form.Control>
              )}
            </Col>
          </Row>
          {key === "protocolRule" && (
            <Fragment>
              <Row style={{ paddingBottom: "10px" }}>
                <Col sm={2} className="u-text-right">
                  <Form.Label>Model vozu</Form.Label>
                </Col>
                <Col sm={10}>
                  {enums && (
                    <Form.Control
                      as="select"
                      style={{ width: "30rem" }}
                      name={"carModel"}
                      onChange={(e: any) => {
                        setSelectedCarModelId(Number.parseInt(e.target.value))
                        form.mutators.resetCarBodyTypeId(e);
                        setSelectedCarBodyTypelId(undefined);
                      }
                      }
                      value={selectedCarModelId?.toString()}
                      >
                      <option></option>
                      {enums?.carModels
                        .slice()
                        .sort((a, b) => (a.name < b.name ? -1 : 1))
                        .map((option) => (
                          <option key={option.id} value={option.id}>
                            {(option.modelVdsCode !== null ? option.modelVdsCode + ' - ' : "" ) + option.name}
                          </option>
                        ))}
                    </Form.Control>
                  )}
                </Col>
              </Row>
              <Row>
                <Col sm={2} className="u-text-right">
                  <Form.Label>Karoserie vozu</Form.Label>
                </Col>
                <Col sm={10}>
                  {enums && (
                    <Form.Control
                      as="select"
                      style={{ width: "30rem" }}
                      name={"carBodyType"}
                      onChange={(e: any) => {
                        setSelectedCarBodyTypelId(Number.parseInt(e.target.value))
                      }}
                      value={selectedCarBodyTypeId?.toString()}
                      >
                      <option></option>
                      {enums?.carBodyTypes
                        .filter(x => x.carModelId == selectedCarModelId)
                        .slice()
                        .sort((a, b) => (a.name < b.name ? -1 : 1))
                        .map((option) => (
                          <option key={option.id} value={option.id}>
                            {(option.modelVdsCode !== null ? option.modelVdsCode + ' - ' : "" ) + option.name}
                          </option>
                        ))}
                    </Form.Control>
                  )}
                </Col>
              </Row>
            </Fragment>
          )}
        </Form>
        )}
      />
      <Tabs
        id="protocol-config-tabs"
        className="tabs"
        activeKey={key}
        onSelect={(k: string) => setKey(k)}
        mountOnEnter={true}
        unmountOnExit={true}
      >
        <Tab eventKey="protocolType" title="Protokol" className="tabs__tab">
          <ProtocolTypeTab protocolType={selectedProtocolTypeId} />
        </Tab>
        <Tab eventKey="protocolArea" title="Oblasti" className="tabs__tab">
          <ProtocolAreaTab protocolTypeId={selectedProtocolTypeId} handleOnChangeProtocolAreas={handleOnChangeProtocolAreas} />
        </Tab>
        <Tab eventKey="protocolPartGroup" title="Skupiny" className="tabs__tab">
          <ProtocolPartGroupTab protocolTypeId={selectedProtocolTypeId} areaEnums={areaEnums} handleOnChangeProtocolGroups={handleOnChangeProtocolGroups} />
        </Tab>
        <Tab eventKey="protocolPart" title="Díly" className="tabs__tab">
          <ProtocolPartTab protocolType={selectedProtocolTypeId} areaEnums={areaEnums} groupEnums={groupEnums} />
        </Tab>
        <Tab eventKey="protocolRule" title="Pravidla" className="tabs__tab">
          <ProtocolRuleTab protocolType={selectedProtocolTypeId} carModelId={selectedCarModelId} carBodyTypeId={selectedCarBodyTypeId} areaEnums={areaEnums} groupEnums={groupEnums} partEnums={partEnums} />
        </Tab>
      </Tabs>
    </div>
  );
};

export default observer(ProtocolsTab);
