import React, { Fragment, useState, useEffect, useContext } from "react";
import { RootStoreContext } from "../../../app/stores/rootStore";
import { Form as FinalForm, Field } from "react-final-form";
import { Row, Col, Form, Button } from "react-bootstrap";
import { ISetting } from "../../../app/models/settings";
import TextInput from "../../../app/common/form/TextInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faSave } from "@fortawesome/free-solid-svg-icons";
import AlertMessage from "../../alert/AlertMessage";

interface IProps {}

const ParametersTab: React.FC<IProps> = () => {
  const rootStore = useContext(RootStoreContext);
  const { settings, loadSettings, updateSettings } = rootStore.settingsStore;

  const [edit, setEdit] = useState(false);

  const [errors, setErrors] = useState([]);
  const [showSubmitError, setShowSubmitError] = useState(false);

  useEffect(() => {
    loadSettings();
  }, [loadSettings]);

  const handleSubmitForm = (values: any) => {
    setErrors([]);
    setShowSubmitError(false);
    // create deep copy of settings to be modified
    let newSettings: ISetting[] = JSON.parse(JSON.stringify(settings));
    for (const [fieldName, fieldValue] of Object.entries(values)) {
      let settingObj: ISetting | undefined = newSettings?.find(
        (s) => s.code === fieldName
      );
      if (settingObj) {
        settingObj.value = fieldValue as string;
      }
    }
    updateSettings({ settings: newSettings })
      .then(() => {
        setEdit(false);
      })
      .catch((error: any) => {
        console.log(error);
        setErrors(Object.values(error.response?.data?.errors));
        setShowSubmitError(true);
      });
  };

  // Reset all form values to initial values
  const handleReset = (form: any) => {
    setEdit(false);
    form.reset();
  };

  return (
    <div className="settings__parameters">
      <FinalForm
        onSubmit={handleSubmitForm}
        validate={(values) => {
          const errors = {} as any;
          // all form fields are mandatory
          settings?.forEach((setting) => {
            if (!values[setting.code])
              errors[setting.code] = `Pole ${setting.name} je povinné`;
          });
          return errors;
        }}
        render={({ handleSubmit, form }) => (
          <Form onSubmit={handleSubmit}>
            <div className="pageheader">
              <div className="pageheader__left">
                <h2>Parametry zkušebny</h2>
              </div>
              <div className="pageheader__right">
                {!edit && (
                  <Button
                    variant="outline-primary"
                    style={{
                      marginRight: "1rem",
                    }}
                    type="button"
                    onClick={() => setEdit(!edit)}
                  >
                    <FontAwesomeIcon icon={faPen} /> Upravit
                  </Button>
                )}
                {edit && (
                  <Fragment>
                    <Button
                      variant="outline-primary"
                      style={{
                        marginRight: "1rem",
                      }}
                      type="submit"
                    >
                      <FontAwesomeIcon icon={faSave} /> Uložit
                    </Button>
                    <Button
                      variant="outline-secondary"
                      type="reset"
                      onClick={() => handleReset(form)}
                    >
                      Zahodit změny
                    </Button>
                  </Fragment>
                )}
              </div>
            </div>
            <AlertMessage
              type="danger"
              heading="Nastala chyba při ukládání"
              show={showSubmitError}
              setShow={setShowSubmitError}
              messageList={errors}
            />
            <Row>
              <Col md={12}>
                {settings?.map((sett) => {
                  return (
                    <Field
                      key={sett.settingId}
                      title={sett.name}
                      initialValue={sett.value}
                      name={sett.code}
                      type={sett.valueType}
                      component={TextInput}
                      titleWidth={5}
                      fieldWidth={3}
                      disabled={!edit}
                    />
                  );
                })}
              </Col>
            </Row>
          </Form>
        )}
      />
    </div>
  );
};

export default ParametersTab;
