import { RootStoreContext } from "../stores/rootStore";
import { ICarTest, ICarTestCarDetailForm, ICarTestConflict } from "./carTest";
import { ICarAnalysis } from "./carAnalysis"

export const canSetEmissionTest = (
  testDestinationId: number | undefined,
  customerCode: string | undefined
) => {
  if (!testDestinationId || !customerCode) return false;
  return testDestinationId === 2 && customerCode.toLowerCase().startsWith("gq");
};

export interface ICar {
  carId: number;
  vin?: string;
  prodIdentificationNum?: string;
  integrationNum: string;
  copsVDSNumber?: string;
  carNumber: string;
  logisticNum: string;
  carStateId: number;
  carState: string; // stav vozu - naplánovaný, probíhá, ...
  carStateOrder: number;
  testTypeId: number;
  testTypeName: string;
  testPredestinationId: number;
  testPredestinationCode: string;
  testPredestinationName: string;
  testDestinationId: number;
  testDestinationCode: string;
  testDestinationName: string;
  customerCode: string;
  scheduledDelivery: Date;
  availableFrom: Date;
  availableTo: Date;
  daysInTestRoom: number; // počet dnů na zkušebně
  plannedDaysInTestRoom: number; // počet dnů na zkušebně
  note: string;
  priority: boolean;
  priorityValue?: number;
  startOfProduction: string;
  modelYear: string;
  modelId: number;
  modelName: string;
  bodyTypeId: number;
  bodyTypeName: string;
  colorCode: string;
  colorName: string;
  colorValue: string;
  equipmentId: number;
  equipmentName: string;
  transmissionId: number;
  transmissionName: string;
  transmissionCode: string;
  engineId: number;
  engineName: string;
  emissionStandard: string;
  typeOfControl: string;
  manualEntry: boolean;
  isComplete: boolean;
  mentionIncomplete: boolean;
  carTestConflicts: ICarTestConflict[];
  vipCar: boolean;
  emissionFree: boolean;
  timeLimit: number;
  plannedFrom?: Date;
  plannedTo?: Date;
  importantCarDesc?: string;
  showEmissionTest: boolean;
  emissionTest?: boolean;
  emissionTestPlanFrom?: Date;
  emissionTestPlanTo?: Date;
  emissionTestDateFrom?: Date;
  emissionTestDateTo?: Date;
  placeId:number;
  placeCode:string;
  isTransported :boolean;
  beforeTransportPlaceCode?: string;
  carPlaceHistories: ICarPlaceHistory[];
  order: number;
  modelKey: string;
  modelVdsCode?: string;
  wheelDrive?: string;
  engineNumber?: string;
  mainProtocols: ICarProtocolListItem[];
  partialProtocols: ICarProtocolListItem[];
  analyticsCar : boolean;
  searchPrNumeberText?: string;
}

export interface ICarList {
  cars: ICar[];
  count: number;
}

export interface ICarPagedList {
  cars: ICar[];
  currentPage: number;
  totalPages: number;
  pageSize: number;
  count: number;
  hasPrevious: boolean;
  hasNext: boolean;
}

export enum ActionTypes {
  Load,
  Unload,
  Register,
  SendToEmission,
  LoadFromEmission
}

export interface ICarDTO {
  carId: number;
  car: ICar;
  carTests: ICarTest[];
  carAnalysis: ICarAnalysis[];
  carHistories: IHistory[];
}

export interface ICarByVinOrKnrDTO {
  cars: ICarIdVin[];
  count: number;
}

export interface ICarIdVin {
  carId: number;
  vin?: string;
  prodIdentificationNum?: string;
  isSameCar: boolean;
  isDifferentIdentificationParam: boolean;
}

export interface IHistory {
  carHistoryId: number;
  dateOfChange: Date;
  carId: number;
  carState: string;
  carTestId?: number;
  carTestState?: string;
  carTestExpertGroupCode?: string;
  carTestExpertGroupName?: string;
  carTestNote?: string;
  userId?: number;
  userFullName: string;
  description: string;
  carAnalysisId?: number;
  carAnalysisTermId?: number;
}

export interface ICarForm {
  carId?: number;
  vin?: string;
  integrationNum: string;
  carNumber: string;
  logisticNum: string;
  carStateId: number;
  testTypeId?: number;
  testPredestinationId?: number;
  testDestinationId?: number;
  colorCode?: string;
  customerCode: string;
  scheduledDelivery: Date;
  daysInTestRoom: number; // počet dnů na zkušebně
  note: string;
  startOfProduction: string;
  mentionIncomplete: boolean;
  modelId?: number;
  bodyTypeId?: number;
  equipmentId?: number;
  transmissionId?: number;
  prodIdentificationNum?: string;
  modelYear: string;
  transmissionCode?: string;
  engineId?: number;
  emissionStandard?: string;
  typeOfControl?: string;
  vipCar: boolean;
  carTests?: ICarTestCarDetailForm[];
  emissionFree: boolean;
  importantCarDesc?: string;
  emissionTest?: boolean;
  emissionTestPlanFrom?: Date;
  emissionTestPlanTo?: Date;
  modelKey?: string;
  modelVdsCode?: string;
  wheelDrive?: string;
  engineNumber?: string;
  analyticsCar: boolean;
}

export interface ICarFilter {
  vin?: string;
  prodIdentificationNum?: string;
  carNumber?: string;
  customerCode?: string;
  inTestroom?: boolean;
  carStates?: { name: string; id: number }[];
  carModels?: { name: string; id: number }[];
  carEngines?: { name: string; id: number }[];
  testDestinations?: { name: string; id: number }[];
  testPredestinations?: { name: string; id: number }[];
  testTypes?: { name: string; id: number }[];
  timeLimitTypes?: { name: string; id: number }[];
  conflictTypes?: { name: string; id: number }[];
}
export interface IEmissionCarFilter{
  vin?: string;
  logisticNum?: string;
  carModels?: { name: string; id: number }[];
  carEngines?: { name: string; id: number }[];
  emissionStandard?: string;
}

export interface ICarSearch {
  vin?: string;
  prodIdentificationNum?: string;
  carNumber?: string;
}

export const convertICarToICarForm = (car: ICar): ICarForm => {
  const carForm: ICarForm = (({
    carId,
    vin,
    integrationNum,
    carNumber,
    logisticNum,
    carStateId,
    testTypeId,
    testPredestinationId,
    testDestinationId,
    customerCode,
    scheduledDelivery,
    daysInTestRoom,
    note,
    startOfProduction,
    colorCode,
    modelId,
    bodyTypeId,
    equipmentId,
    transmissionId,
    transmissionCode,
    engineId,
    emissionStandard,
    typeOfControl,
    mentionIncomplete,
    vipCar,
    prodIdentificationNum,
    modelYear,
    importantCarDesc,
    emissionTest,
    emissionTestPlanFrom,
    emissionTestPlanTo,
    modelKey,
    modelVdsCode,
    wheelDrive,
    engineNumber,
    analyticsCar
  }) => ({
    carId,
    vin,
    integrationNum,
    carNumber,
    logisticNum,
    carStateId,
    testTypeId,
    testPredestinationId,
    testDestinationId,
    customerCode,
    scheduledDelivery,
    daysInTestRoom,
    note,
    startOfProduction,
    colorCode,
    modelId,
    bodyTypeId,
    equipmentId,
    transmissionId,
    transmissionCode,
    engineId,
    emissionStandard,
    typeOfControl,
    mentionIncomplete,
    vipCar,
    prodIdentificationNum,
    modelYear,
    emissionFree: false,
    importantCarDesc,
    emissionTest: canSetEmissionTest(testDestinationId, customerCode)
      ? emissionTest
      : undefined,
    emissionTestPlanFrom:
      canSetEmissionTest(testDestinationId, customerCode) && emissionTest
        ? emissionTestPlanFrom
        : undefined,
    emissionTestPlanTo:
      canSetEmissionTest(testDestinationId, customerCode) && emissionTest
        ? emissionTestPlanTo
        : undefined,
        modelKey,
        modelVdsCode,
        wheelDrive,
        engineNumber,
        analyticsCar
  }))(car);
  return carForm;
};

export interface ICarUpdateResponse {
  carId: number;
  car: ICar;
  carTests: ICarTest[];
  carAnalysis: ICarAnalysis[];
  carHistories: IHistory[];
}

export class NewCar implements Partial<ICar> {
  carId?: number = undefined;
  vin: string = "";
  integrationNum: string = "";
  carNumber: string = "";
  logisticNum?: string = "";
  carStateId: number = 1;
  carState: string = ""; // stav vozu - naplánovaný, probíhá, ...
  testTypeId?: number = undefined;
  testPredestinationId?: number = undefined;
  testDestinationId?: number = undefined;
  customerCode: string = "";
  daysInTestRoom?: number = undefined; // počet dnů na zkušebně
  plannedDaysInTestRoom?: number = undefined; // počet dnů na zkušebně
  note: string = "";
  priority: boolean = false;
  priorityValue?: number = undefined;
  startOfProduction: string = "";
  modelId?: number;
  manualEntry: boolean = true;
  isComplete: boolean = false;
  mentionIncomplete: boolean = true;
  colorCode?: string = "";
  bodyTypeId?: number = undefined;
  equipmentId?: number = undefined;
  transmissionId?: number = undefined;
  transmissionCode?: string = undefined;
  engineId?: number = undefined;
  emissionStandard?: string = undefined;
  typeOfControl?: string = undefined;
  vipCar: boolean = false;
  prodIdentificationNum?: string = undefined;
  modelYear: string = "";
  importantCarDesc?: string = undefined;
  showEmissionTest: boolean = false;
  emissionTest?: boolean = undefined;
  emissionTestPlanFrom?: Date = undefined;
  emissionTestPlanTo?: Date = undefined;
  emissionTestDateFrom?: Date = undefined;
  emissionTestDateTo?: Date = undefined;
  placeId?:number = undefined;
  isTransported :boolean = false;
  analyticsCar: boolean = false;
  beforeTransportPlaceCode?: string;
  carPlaceHistories: ICarPlaceHistory[] = [];
  modelKey?: string;
  modelVdsCode?: string;
  wheelDrive?: string;
  engineNumber?: string;
  carTestConflicts: ICarTestConflict[] = [];
  mainProtocols: ICarProtocolListItem[] = [];
  partialProtocols: ICarProtocolListItem[] = [];
  constructor(init?: ICar) {
    Object.assign(this, init);
  }
}

export interface IEmissionCarForm {
  carId: number;
  emissionTest: boolean | undefined;
  emissionTestPlanFrom: Date | undefined;
  emissionTestPlanTo: Date | undefined;
}

export interface ICarProtocolListItem{
  carProtocolId?: number;
  protocolTypeId: number;
  protocolTypeCode: string;
  protocolTypeName: string;
  protocolNumber: string;
  url: string;
}

export interface ICarPlaceHistory{
  carId: number;
  availableFrom?: Date;
  availableTo?: Date;
  daysInTestRoom?:number;
  plannedFrom?: Date;
  plannedTo?: Date;
  plannedDaysInTestRoom?:number;
  placeId?:number;
  placeCode?:string;
  placeName?:string;
}

export interface ICarPrNumber {
  prNumCategoryId?: number;
  prNumFamilyId?: number;
  prNumFamilyCode?: string;
  prNumFamilyName?: string;
  prNumberId?: number;
  prNumberCode: string;
  prNumberName: string;
  prNumSpecialText?: string;
}

export interface ICarPrNumbersList{
  carPrNumbers: ICarPrNumber[];
}