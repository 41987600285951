import React, { useContext } from "react";
import { Container } from "react-bootstrap";
import packageJson from "../../../package.json";
import Can from "../../authorization/Can";
import { RootStoreContext } from "../../app/stores/rootStore";
import { observer } from "mobx-react-lite";

const HomePage = () => {
  const rootStore = useContext(RootStoreContext);
  const {user} = rootStore.authStore;
  return (
    <Can
      roleId={user?.userRoleId}
      perform="home-page:visit"
      yes={
        <Container style={{ marginTop: "7em" }}>
          <h1 className={"display-4"}> Q-Space</h1>
          <p className="lead">Verze {packageJson.version}</p>
        </Container>
      }
     
    />
  );
};

export default observer(HomePage);
