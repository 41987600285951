import React, { Fragment } from "react";
import { Table } from "react-bootstrap";
import { Field } from "react-final-form";
import { FormApi } from "final-form";
import { IProtocolArea, IDrivingCharacteristicsProtocol  } from "../../../../app/models/protocol";
import CheckboxInput from "../../../../app/common/form/CheckboxInput";
import AutocompleteSimpleInput from "../../../../app/common/form/AutocompleteSimpleInput";
import TextAreaInput from "../../../../app/common/form/TextAreaInput";

interface IProps {
    isEdit: boolean;
    drivingTestArea: IProtocolArea;
    isDisabled: boolean;
    form: FormApi<IDrivingCharacteristicsProtocol>;
}

const DrivingTestArea: React.FC<IProps> = ({ drivingTestArea, isEdit, isDisabled, form}) => {

    return (

      <Fragment>
        <Table className="protocol-table">
          <thead>
            <tr>
              <th colSpan={10} className={`headingrow`}>
                <h2>{drivingTestArea?.name}</h2>
              </th>
              <th colSpan={2} className={`headingrow`}>
                <Field
                    name={`drivingTestArea.notPerform`}
                    title="Neprovedeno"
                    options={[
                        { key: 1, value: "ano" },
                        { key: 0, value: " ne " },
                    ]}
                    format={(v) => (v ? 1 : 0)}
                    parse={(v) => (v ? true : false)}
                    type={"checkbox"}
                    initialValue={drivingTestArea?.notPerform || false}
                    component={CheckboxInput}
                    disabled={!isEdit}
                    labelWidth={8}
                    valueWidth={4}
                />
              </th>
            </tr>
          </thead>
        </Table>

        <Table className={`protocol-table ${ isDisabled ? "no-print" : ""}`}>
          <thead>
            <tr className={`${ isDisabled ? "disabled" : ""}`}>
              <th colSpan={3}>
                Hodnocení
              </th>
              <th colSpan={9}>
                Poznámka k jízdní zkoušce
              </th>
            </tr>
          </thead>
          <tbody>
            {drivingTestArea.partRules?.map((rule,ruleIndex) => {
              return (
              <tr className={`${ isDisabled ? "disabled" : ""}`}>
                <td colSpan={3} style={{verticalAlign: 'top'}}>
                  <Field<string>
                    name={`drivingTestArea.partRules[${ruleIndex}].stringEval`}
                    initialValue={rule.stringEval}
                    component={AutocompleteSimpleInput}
                    items={["OK", "NOK"]}
                    rows={1}
                    size="sm"
                    disabled={!isEdit || isDisabled}
                  />
                </td>
                <td colSpan={9}>
                  <Field<string>
                      name={`drivingTestArea.partRules[${ruleIndex}].note`}
                      initialValue={rule.note}
                      component={TextAreaInput}
                      disabled={!isEdit || isDisabled}
                  />
                </td>
              </tr>
            )
            })}

          </tbody>
        </Table>
      </Fragment>
  );
};

export default DrivingTestArea;
