import "./ScheduleAnalysisItem.scss";
import React, {useContext, useEffect} from "react";
import "moment/locale/cs";
import moment from "moment";
import {DATE_FORMAT, TIME_FORMAT} from "../../../app/common/utils/constants";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import {RootStoreContext} from "../../../app/stores/rootStore";
import {faExclamationTriangle} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {ICarTerm} from "../../../app/models/schedule";
import {ICarAnalysis} from "../../../app/models/carAnalysis";
import CarAnalysisForm from "../../carAnalysis/CarAnalysisForm";
import {ICar} from "../../../app/models/car";
import {runInAction} from "mobx";

interface IProps {
  analysis: ICarAnalysis;
  term: ICarTerm;
  car: ICar;
}

const ScheduleAnalysisItem: React.FC<IProps> = ({ analysis, term, car }) => {
  const rootStore = useContext(RootStoreContext);
  const { openModal } = rootStore.modalStore;
  const { usersSorted } = rootStore.userStore;
  useEffect(() => {
    runInAction(() => {
      analysis.car = car;
      analysis.terms =
          analysis?.terms?.map((p, index: number) => {
            p.rownumber = index;
            return p;
          }) || [];
    })
  }, []);

  const { carTestConflicts } = analysis;
  const { expertGroupName, expertGroupCode, expertGroupColor, startAt, endAt } = term;


  const startMoment = moment(startAt);
  const endMoment = moment(endAt);

  let className = `schedule-analysis-item schedule-analysis-item--${expertGroupColor} ${
    !!carTestConflicts?.filter((x) => x.carAnalysisTermId == term.carAnalysisTermId).length ? "schedule-analysis-item--conflict" : ""
  }`;

  const conflictTypes = [
    ...new Set(carTestConflicts.filter((x) => x.carAnalysisTermId == term.carAnalysisTermId).map((item) => item.conflictName)),
  ];

  const getExpertGroupUsers = (expertGroupId: number) => {
    return usersSorted
      .filter((x) => x.expertGroupId == expertGroupId && x.isActive)
      .map((w) => ({
        key: w.userId,
        value: w.fullName,
      }));
  };

  const scheduleAnalysisItemContent = (
    <OverlayTrigger
      placement="auto"
      trigger="hover"
      delay={{ show: 750, hide: 200 }}
      overlay={
        <Tooltip id="button-tooltip" data-html="true">
          <p>
            Analýza pro skupinu {expertGroupName} <br />
            od {startMoment.format(`${DATE_FORMAT} ${TIME_FORMAT}`)} <br />
            do {endMoment.format(`${DATE_FORMAT} ${TIME_FORMAT}`)}
          </p>

          {!!carTestConflicts?.filter((x) => x.carAnalysisTermId == term.carAnalysisTermId).length && (
            <h6>
              <FontAwesomeIcon
                icon={faExclamationTriangle}
                className="icon icon--testcollisions"
              />
              <div className="testcollisions__list">
                <ul>
                  {conflictTypes.map((conflictType, index) => (
                    <li key={index}> {conflictType}</li>
                  ))}
                </ul>
              </div>
            </h6>
          )}
        </Tooltip>
      }
    >
      <div
        className={className}
        onClick={() => {
          openModal(
            <CarAnalysisForm
              aCarId={term.carId}
              aCarAnalysisId={term.carAnalysisId}
              aCarAnalysis={analysis}
              aExpertGroupId={term.expertGroupId}
              aWorkerOptions={getExpertGroupUsers(term.expertGroupId)}
              isFormEditable={false}
            />,
            {
              preventClosing: true,
              title: "Analýza vozu",
              size: "xl",
            }
          );
        }}
      >
        <div className="schedule-analysis-item__title">{expertGroupCode}</div>
        <div className="schedule-analysis-item__time">
          {startMoment.format(TIME_FORMAT)}-{endMoment.format(TIME_FORMAT)}
        </div>
      </div>
    </OverlayTrigger>
  );

  return <div>{scheduleAnalysisItemContent}</div>;
};
export default ScheduleAnalysisItem;
