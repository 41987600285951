import React from "react";
import moment from "moment";
import "./MonthCalendar.scss";
import CalendarDay from "./CalendarDay";
import { IHoliday,IBlockingEvent } from "../../../app/models/blockingEvent";

interface IProps {
  year: number;
  month: number;
  holidays: IHoliday[];
  vacations: IBlockingEvent[];
}

const MonthCalendar: React.FC<IProps> = ({
  year,
  month,
  holidays,
  vacations,
}) => {
  // week day names array starting with Sunday
  const weekdayNames = moment.weekdaysShort(true);
 

  // set default date for the calendar
  const calendarDate = moment().set({
    year: year,
    month: month - 1,
    date: 1,
    hour: 8,
    minute: 0,
    second: 0,
    millisecond: 0,
  });
  const monthName = moment(calendarDate).format("MMMM");

  // get date of the first day in month and last day
  let firstDay = moment(calendarDate).startOf("month");
  let lastDay = moment(calendarDate).endOf("month");

  // add blank days before first day
  // 0 is Monday in czech locale
  const blankDaysStart = [];
  for (let i = 0; i < firstDay.weekday(); i++) {
    blankDaysStart.push(
      <td key={`blank-${i}`} className="calendar-day empty"></td>
    );
  }

  // add blank days after the last day
  const blankDaysEnd = [];
  for (let i = lastDay.weekday(); i < 6; i++) {
    blankDaysEnd.push(
      <td key={`blankend-${i}`} className="calendar-day empty"></td>
    );
  }

  const datesInMonth = [];

  while (lastDay.isSameOrAfter(firstDay, "days")) {
    datesInMonth.push(moment(firstDay));
    firstDay.add(1, "days");
  }

  // add cell for each day in month
  const daysInMonth = datesInMonth.map((date) => (
    <CalendarDay
      date={date}
      key={date.toISOString()}
      holidays={holidays}
      vacations={vacations}
    />
  ));

  var calendarCells = [...blankDaysStart, ...daysInMonth, ...blankDaysEnd];
  let calendarRows: JSX.Element[] = [];
  let cells: JSX.Element[] = [];

  calendarCells.forEach((calendarCell, i) => {
    // if index not equal 7 that means not go to next week
    if (i % 7 !== 0) {
      cells.push(calendarCell);
    } // when reach next week we contain all td in last week to rows
    else {
      calendarRows.push(<tr key={`${month}-${i / 7}`}>{cells}</tr>);
      cells = []; // empty container
      cells.push(calendarCell); // in current loop we still push current row to new container
    }
    // when end loop we add remain date
    if (i === calendarCells.length - 1) {
      calendarRows.push(<tr key={`${month}-${i / 7}`}>{cells}</tr>);
    }
  });

  let weekdays = weekdayNames.map((day, i) => (
    <th key={`${month}-${i}`} className="week-day">
      {day}
    </th>
  ));

  return (
    <div className="month-calendar">
      <table>
        <thead>
          <tr className="table-row__month">
            <th className="h1" colSpan={7}>
              {monthName}
            </th>
          </tr>
          <tr className="table-row__weekdays">{weekdays}</tr>
        </thead>
        <tbody>{calendarRows}</tbody>
      </table>
    </div>
  );
};

export default MonthCalendar;
