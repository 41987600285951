import "./Footer.scss";
import React, { useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { observer } from "mobx-react-lite";

import {
  Button,
  OverlayTrigger,
  Popover,
  Tooltip,
} from "react-bootstrap";
import { faInfo, faPlus, faPrint } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { RootStoreContext } from "../../../app/stores/rootStore";
import Can from "../../../authorization/Can";
import {
  getCarsString,
  getMasculinePassiveVerbEnding,
} from "../../../app/common/utils/czechDeclension";

const Footer: React.FC = () => {
  const rootStore = useContext(RootStoreContext);
  const { loadExpertGroups, expertGroups } = rootStore.expertGroupStore;
  const { user } = rootStore.authStore;
  const {
    carsSortedAndFiltered,
    carIds,
    activeFilterLength,
  } = rootStore.pageScheduleStore;
  useEffect(() => {
    if (!expertGroups || !expertGroups.length) {
      loadExpertGroups();
    }
  }, [loadExpertGroups, expertGroups]);

  const popover = (
    <Popover id="popover-legend" className="popover-legend">
      <Popover.Title as="h3">Legenda</Popover.Title>
      <Popover.Content>
        <div className="legend">
          <div className="legend-section legend-expertgroup">
            <h3>Odborné skupiny</h3>
            <div className="list-group list-group--row list-group--wrapped">
              {expertGroups
                .slice()
                .sort((a, b) =>
                  a.expertGroupCode < b.expertGroupCode ? -1 : 1
                )
                .map((expertGroup) => (
                  <div
                    key={expertGroup.expertGroupId}
                    className={`list-group__item list-group__item--${expertGroup.expertGroupColor}`}
                  >
                    <span className="item__code">
                      {expertGroup.expertGroupCode}
                    </span>
                    <span className="item__description">
                      {expertGroup.expertGroupName}
                    </span>
                  </div>
                ))}
            </div>
          </div>
          <hr/>
          <div className="legend-section legend-carrows">
            <h3>Řádek vozu</h3>
            <table>
              <thead style={{display:"none"}}>
                <tr>
                  <th>Barva</th>
                  <th>Význam</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td ><div className="color-square color-square--to-unload"></div></td>
                  <td>
                    Vůz má k dnešnímu dni dokončené všechny zkoušky a bude
                    připraven k vyskladnění
                  </td>
                </tr>
                <tr>
                  <td ><div className="color-square color-square--to-plan"></div></td>
                  <td>
                    Vůz nemá naplánované všechny zkoušky
                  </td>
                </tr>
                <tr>
                  <td ><div className="color-square color-square--analytics"></div></td>
                  <td>
                    Vůz má naplánovanou analýzu
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </Popover.Content>
    </Popover>
  );

  return (
    <footer className="footer">
      <div className="footer-left">
        <Can
          roleId={user?.userRoleId}
          perform="plan-page:visit"
          yes={
            <OverlayTrigger
              placement="right"
              overlay={
                <Tooltip id={`tooltip-addbutton`}>Přidat vozy do plánu</Tooltip>
              }
            >
              <Button
                variant="primary"
                size="lg"
                className="footer__btn-add"
                as={Link}
                to="/plan"
              >
                <FontAwesomeIcon icon={faPlus} />
              </Button>
            </OverlayTrigger>
          }
        />
      </div>
      <div className="footer__rowscount">
        <label>
          Zobrazen{getMasculinePassiveVerbEnding(carsSortedAndFiltered.length)}{" "}
          {carsSortedAndFiltered.length}{" "}
          {getCarsString(carsSortedAndFiltered.length)}
          {!!activeFilterLength &&
            ` z ${carIds.length} ${getCarsString(carIds.length)}`}
        </label>
      </div>
      <div className="footer-right">
        <div className="flex-row">
          <OverlayTrigger
            trigger={["click"]}
            placement="top"
            overlay={popover}
            rootClose
          >
            <div className="footer__icongroup">
              <FontAwesomeIcon icon={faInfo} title="Legenda" />
              Legenda
            </div>
          </OverlayTrigger>
          <div className="footer__icongroup" onClick={() => window.print()}>
            <FontAwesomeIcon icon={faPrint} title="Tisk" />
            Tisk
          </div>
        </div>
      </div>
    </footer>
  );
};

export default observer(Footer);
