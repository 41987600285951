import React from "react";
import "./Loader.scss";

interface IProps {
  label?: string;
  style?:React.CSSProperties | undefined;
}
const Loader: React.FC<IProps> = ({ label,style }) => {
  return (
    <div className="loader" style={style}>
      <div className="loader__spinner"></div>
      <div className="loader__label">{label || "Načítání dat"}</div>
    </div>
  );
};

export default Loader;
