import moment from "moment";

export const isToday = (dateTime: Date | undefined) => {
  //console.log(dateTime);
  if (!dateTime) return false;
  const dateOnly = new Date(dateTime).setHours(0, 0, 0, 0);
  const currentDateOnly = new Date().setHours(0, 0, 0, 0);
  // console.log(dateOnly,currentDateOnly);
  return dateOnly === currentDateOnly;
};

export const isTodayOrPast = (dateTime: Date | undefined) => {
  //console.log(dateTime);
  if (!dateTime) return false;
  const dateOnly = new Date(dateTime).setHours(0, 0, 0, 0);
  const currentDateOnly = new Date().setHours(0, 0, 0, 0);
  // console.log(dateOnly,currentDateOnly);
  return dateOnly <= currentDateOnly;
};

export const isInThePast = (dateTime: Date | undefined, minutesToleration? : number) => {
  //console.log(dateTime);
  if (!dateTime) return false;
  const datetime = moment(dateTime).add((minutesToleration||0), "minutes").toDate();
  const now = new Date();
  // console.log(dateOnly,currentDateOnly);
  return datetime <= now;
};

export const isEmissionCar = (emissionCar: boolean | undefined, plannedFrom: Date | undefined, emissionCarTo: Date | undefined) => {
  return emissionCar && !!plannedFrom && !emissionCarTo;
}

export const combineDateTime = (
  date: Date | number,
  time: string | undefined,
  defaultHours: number,
  defaultMinutes: number
) => {
  const splitTime = time?.split(":");
  const hours = splitTime ? parseInt(splitTime[0]) : defaultHours;
  const minutes = splitTime ? parseInt(splitTime[1]) : defaultMinutes;

  return new Date(date).setHours(hours, minutes);
};

// returning all repeatingWeekdays week days dates between startDate and endDate
// - e.g. all Mondays and Wednesdays between first January and first January
// - startDate=new Date('2020-01-01'), endDate=new Date('2020-02-01'), repeatingWeekdays=[1,3]
export const getDatesBetween = (
  startDate: Date,
  endDate: Date,
  repeatingWeekdays: [number]
) => {
  if (!repeatingWeekdays || !startDate || !endDate) return [];
  const dates: Date[] = [];
  const day = moment(startDate);
  const lastDay = moment(endDate);

  while (lastDay.isSameOrAfter(day, "days")) {
    if (repeatingWeekdays.includes(day.isoWeekday())) {
      dates.push(moment(day).toDate());
    }
    day.add(1, "day");
  }
  return dates;
};
//https://stackoverflow.com/questions/3942878/how-to-decide-font-color-in-white-or-black-depending-on-background-color
export const pickTextColorBasedOnBgColor = (bgColor:string, lightColor:string, darkColor:string)=> {
  if(!bgColor || ! lightColor || !darkColor) return;
  var color = (bgColor.charAt(0) === '#') ? bgColor.substring(1, 7) : bgColor;
  var r = parseInt(color.substring(0, 2), 16); // hexToR
  var g = parseInt(color.substring(2, 4), 16); // hexToG
  var b = parseInt(color.substring(4, 6), 16); // hexToB
  return (((r * 0.299) + (g * 0.587) + (b * 0.114)) > 186) ?
    darkColor : lightColor;
}

export const createGuid = () => 
{  
   return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {  
      var r = Math.random()*16|0, v = c === 'x' ? r : (r&0x3|0x8);  
      return v.toString(16);  
   });  
}  