import "./ScheduleItem.scss";
import React, { Fragment, useContext } from "react";
import "moment/locale/cs";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { ICarTest } from "../../../app/models/carTest";
import moment from "moment";
import { TIME_FORMAT, DATE_FORMAT } from "../../../app/common/utils/constants";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import CarTestStatusIcon from "../../icons/CarTestStatusIcon";
import { RootStoreContext } from "../../../app/stores/rootStore";
import { checkAuthRule } from "../../../authorization/Can";

interface IProps {
  scheduleItem: ICarTest;
}

const ScheduleItem: React.FC<IProps> = ({ scheduleItem }) => {
  const rootStore = useContext(RootStoreContext);
  const { user } = rootStore.authStore;
  const {
    carTestId,
    expertGroupName,
    expertGroupCode,
    expertGroupColor,
    testStateId,
    testStateName,
    startAt,
    endAt,
    testStateInDelay,
    carTestConflicts,
  } = scheduleItem;

  const startMoment = moment(startAt);
  const endMoment = moment(endAt);

  let className = `scheduleitem scheduleitem--${expertGroupColor} ${
    !!carTestConflicts?.length ? "scheduleitem--conflict" : ""
  }`;

  if (testStateInDelay) className += " scheduleitem--late";

  const conflictTypes = [
    ...new Set(carTestConflicts.map((item) => item.conflictName)),
  ];

  const canViewTestDetail = checkAuthRule(
    user?.userRoleId,
    "cartest-detail-page:visit"
  );
  const scheduleItemContent = (
    <OverlayTrigger
      placement="auto"
      trigger="hover"
      delay={{ show: 750, hide: 200 }}
      overlay={
        <Tooltip id="button-tooltip" data-html="true">
          <p>
            {testStateName} zkouška pro skupinu {expertGroupName} <br />
            od {startMoment.format(`${DATE_FORMAT} ${TIME_FORMAT}`)} <br />
            do {endMoment.format(`${DATE_FORMAT} ${TIME_FORMAT}`)}
          </p>

          {!!carTestConflicts?.length && (
            <h6>
              <FontAwesomeIcon
                icon={faExclamationTriangle}
                className="icon icon--testcollisions"
              />
              <div className="testcollisions__list">
                <ul>
                  {conflictTypes.map((conflictType, index) => (
                    <li key={index}> {conflictType}</li>
                  ))}
                </ul>
              </div>
            </h6>
          )}
        </Tooltip>
      }
    >
      <div className={className}>
        <div className="scheduleitem__title">
          <CarTestStatusIcon carTestState={testStateId} />
          {expertGroupCode}
        </div>
        <div className="scheduleitem__time">
          {startMoment.format(TIME_FORMAT)}-{endMoment.format(TIME_FORMAT)}
        </div>
      </div>
    </OverlayTrigger>
  );

  return (
    <div>
      <Fragment>
        {canViewTestDetail && (
          <Link to={`/carTest/${carTestId}`} className={`scheduleitem__link`}>
            {scheduleItemContent}
          </Link>
        )}
        {!canViewTestDetail && scheduleItemContent}
      </Fragment>
    </div>
  );
};

export default ScheduleItem;
