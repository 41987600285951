import {RootStore} from "./rootStore";
import {action, observable, runInAction} from "mobx";
import {IExpertGroup, IExpertGroupDetail, IExpertGroupForm,} from "../models/expertGroup";
import agent from "../api/agent";
import {toast} from "react-toastify";

export default class ExpertGroupStore {
  rootStore: RootStore;
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @observable loadingInitial = false;
  @observable isSaving = false;
  @observable expertGroups: IExpertGroup[] = [];
  @observable expertGroupDetailedRegistry = new Map<
    number,
    IExpertGroupDetail
  >();

  @action loadExpertGroups = async () => {
    if (this.expertGroups.length > 0) return;
    this.loadingInitial = true;
    try {
      const response = await agent.ExpertGroups.list();
      runInAction("load expert groups", () => {
        this.expertGroups = response.expertGroups;
        this.loadingInitial = false;
        this.rootStore.pageGroupScheduleStore.expertGroupId =
            this.rootStore.authStore.user?.expertGroupId ||
            this.rootStore.expertGroupStore.expertGroups[0]?.expertGroupId;
      });
    } catch (error) {
      runInAction("load expert groups error", () => {
        this.loadingInitial = false;
      });
      throw error;
    }
  };

  @action loadExpertGroup = async (id: number) => {
    this.loadingInitial = true;
    try {
      const expertGroup = await agent.ExpertGroups.details(id);
      runInAction("load expert group", () => {
        this.expertGroupDetailedRegistry.set(
          expertGroup.expertGroupId,
          expertGroup
        );
        this.loadingInitial = false;
      });
    } catch (error) {
      runInAction("load expert group error", () => {
        this.loadingInitial = false;
      });
      throw error;
    }
    return undefined;
  };

  @action getWorkers = async (
    id: number,
    dateFromString: Date,
    duration: number,
    carTestId: number
  ) => {
    this.loadingInitial = true;
    let workers = undefined;
    const dateFrom = new Date(dateFromString);
    try {
      const response = await agent.ExpertGroups.getWorkers(
        id,
        dateFrom,
        new Date(dateFrom.getTime() + duration * 60 * 60 * 1000),
        carTestId
      );
      workers = response.workers;
      runInAction("load expert group workers", () => {});
    } catch (error) {
      runInAction("load expert group workers error", () => {
        this.loadingInitial = false;
      });
      throw error;
    }
    return workers;
  };

  @action updateExpertGroup = async (
    expertGroup: IExpertGroupForm
  ): Promise<IExpertGroupDetail> => {
    try {
      this.isSaving = true;
      const response = await agent.ExpertGroups.update(expertGroup);
      runInAction("editing expert group", () => {
        this.expertGroups = [];
        this.isSaving = false;
      });

      return response;
    } catch (error) {
      console.log(error);
      this.isSaving = false;

      toast.error("Při ukládání odborné skupiny nastala chyba!");
      throw error;
    }
  };
}
