import React, { useContext } from "react";
import { RouteProps, RouteComponentProps, Route } from "react-router-dom";
import { RootStoreContext } from "../app/stores/rootStore";
import { observer } from "mobx-react-lite";
import { checkAuthRule } from "./Can";
import Unauthorized from "../app/layout/Unauthorized";
import Unauthenticated from "../app/layout/Unauthenticated";
interface IProps extends RouteProps {
  component: React.ComponentType<RouteComponentProps<any>>;
  action: string;
  checkByExpertGroupId?: boolean;
}

const AuthRoute: React.FC<IProps> = ({
  component: Component,
  action,
  checkByExpertGroupId: checkByParam,
  ...rest
}) => {
  const rootStore = useContext(RootStoreContext);
  const { isLoggedIn, user } = rootStore.authStore;
  const isAllowed = (param: any) => {
    let data = {} as any;
    if (checkByParam) {
      const paramNumeric = Number.parseInt(param);
      data.userExpertGroupId = user?.expertGroupId;
      data.expertGroupId = paramNumeric;
      data.managedExpertGroups = user?.managedGroups;
    }
    return checkAuthRule(user?.userRoleId, action, data);
  };
  return (
    <Route
      {...rest}
      render={(props) => {
        //
        if (isLoggedIn && isAllowed(props.match.params.id))
          return <Component {...props} />;
        else if (isLoggedIn) return <Unauthorized />;
        else {
          // TODO tady je potreba doplnit, co se ma stat 
          // pokud neni uzivatel prihlasen a chce se dostat 
          // na stranku, kam je potreba prihlaseni - ted se jen vykresli komponenta neprihlasen
          return <Unauthenticated />;
        }
        // return isLoggedIn && isAllowed(props.match.params.id) ? (
        //   <Component {...props} />
        // ) : (
        //   // <Redirect to={"/login"} />
        //   <Unauthorized />
        // );
      }}
    />
  );
};

export default observer(AuthRoute);
