import React, { Fragment } from "react";
import { Table } from "react-bootstrap";
import { Field } from "react-final-form";
import { FormApi } from "final-form";
import { IProtocolArea, IEecProtocol } from "../../../../app/models/protocol";
import TextInput from "../../../../app/common/form/TextInput"

interface IProps {
  measuringEquipmentArea: IProtocolArea;
  isEdit: boolean;
  form: FormApi<IEecProtocol>;
}

const MeasuringEquipmentArea: React.FC<IProps> = ({
  measuringEquipmentArea,
  isEdit,
  form
}) => {

    return (
      <Fragment>
        <Table className="protocol-table">
        <thead>
          <tr>
            <th className="headingrow">
              <h2>{measuringEquipmentArea?.name}</h2>
            </th>
          </tr>
        </thead>
      </Table>

      <Table className={`protocol-table`}>
        <tbody>
              {measuringEquipmentArea.partRules?.map((partRule, ruleIndex) => (
                <tr key={partRule.protocolRuleId}>
                  <td><b>{partRule.name} :</b></td>
                  <td className="with-border-bottom">
                      <Field<string>
                        name={`measuringEquipment.partRules[${ruleIndex}].measuredValue`}
                        component={TextInput}
                        initialValue={partRule.measuredValue}
                        rows={1}
                        size="sm"
                        disabled={!isEdit}
                      />
                  </td>
                </tr>
                ))
              }
        </tbody>
      </Table>
    </Fragment>
  );
};

export default MeasuringEquipmentArea;
