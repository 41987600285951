import { RootStore } from "./rootStore";
import { observable, action } from "mobx";

export default class ModalStore {
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }


  @observable.shallow modal = {
    open: false,
    body: null,
    title: undefined,
    preventClosing: false,
    size: undefined
  };

  colors = [ "secondary" , "success" , "danger" , "warning" , "info" ];
  @observable.shallow dialog = {
    open: false,
    title: "",
    body: "",
    cancelButtonLabel:undefined,
    confirmButtonLabel:undefined,
    type: this.colors[0],
    onConfirm: ()=>{},
    onCancel: ()=>{}
  };


  @action openModal = (content: any, param: any = null) => {
    this.modal.open = true;
    this.modal.body = content;
    this.modal.title = param?.title;
    this.modal.preventClosing = param?.preventClosing;
    this.modal.size = param?.size;
  };

  @action closeModal = () => {
    this.modal.open = false;
    this.modal.body = null;
  };

  @action openDialog = (content: any,param:any =null) => {
    this.dialog.open = true;
    this.dialog.body = content;
    this.dialog.title = param?.title;
    this.dialog.type = param?.type;
    this.dialog.confirmButtonLabel = param?.confirmButtonLabel;
    this.dialog.cancelButtonLabel = param?.cancelButtonLabel;
    this.dialog.onConfirm =param?.onConfirm; 
    this.dialog.onCancel =param?.onCancel; 
  };

  @action closeDialog = () => {
    this.dialog.open = false;
    this.dialog.body = "";
  };
}
