import { RootStore } from "./rootStore";
import { observable, action, runInAction } from "mobx";
import agent from "../api/agent";
import { toast } from "react-toastify";

import {
  IBlockingEventCreateForm,
  IBlockingEventUpdateForm,
  IBlockingEventDeleteForm,
  IBlockingEvent,
  IHoliday,
} from "../models/blockingEvent";

export default class BlockingEventStore {
  rootStore: RootStore;
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @observable isLoading: boolean = false;
  @observable holidays: IHoliday[] = [];
  @observable vacations: IBlockingEvent[] = [];

  @action updateBlockingEvent = async (form: IBlockingEventUpdateForm) => {
    try {
      this.isLoading = true;
      const result = await agent.BlockingEvents.update(form);
      toast.success("Absence nebo blokace byla úspěšně upravena");
      runInAction(() => {
        this.isLoading = false;
        const expertGroupId = form.expertGroupId;
        if (expertGroupId) {
          const groupDetail = this.rootStore.expertGroupStore.expertGroupDetailedRegistry.get(
            expertGroupId
          );         
          if (groupDetail) {
            // remove deleted from the store and add new to the store
            groupDetail.blockingEvents = [
              ...groupDetail.blockingEvents.filter(
                (x) =>
                  !result
                    .filter((x) => x.isDeleted)
                    .some((i) => i.blockingEventId === x.blockingEventId)
              ),
              ...result.filter((x) => !x.isDeleted),
            ];
            this.rootStore.expertGroupStore.expertGroupDetailedRegistry.set(
              expertGroupId,
              groupDetail
            );
          }
        }
      });
      return result;
    } catch (error) {
      console.log(error);
      toast.warn("Nastal problém při ukládání absence nebo blokace");
      runInAction(() => {
        this.isLoading = false;
      })
      
      throw error;
    }
  };

  @action createBlockingEvents = async (form: IBlockingEventCreateForm) => {
    try {
      this.isLoading = true;
      const response = await agent.BlockingEvents.create(form);
      toast.success("Absence, blokace, nebo událost byla úspěšně uložena");
      runInAction(() => {
        this.isLoading = false;
        const expertGroupId = form.expertGroupId;
        if (expertGroupId) {
          const groupDetail = this.rootStore.expertGroupStore.expertGroupDetailedRegistry.get(
            expertGroupId
          );
          if (groupDetail) {
            groupDetail.blockingEvents = [
              ...groupDetail.blockingEvents,
              ...response,
            ];
            this.rootStore.expertGroupStore.expertGroupDetailedRegistry.set(
              expertGroupId,
              groupDetail
            );
          }
        }
      });
      return response;
    } catch (error) {
      console.log(error);
      toast.warn("Nastal problém při ukládání absence nebo blokace");
      runInAction(() => {
        this.isLoading = false;
      })
      throw error;
    }
  };

  @action deleteBlockingEvents = async (form: IBlockingEventDeleteForm) => {
    try {
      this.isLoading = true;
      const result = await agent.BlockingEvents.delete(form);
      toast.success("Absence nebo blokace byla úspěšně smazána");
      runInAction(() => {
        this.isLoading = false;
        const expertGroupId = result[0]?.expertGroupId;
        if (expertGroupId) {
          const groupDetail = this.rootStore.expertGroupStore.expertGroupDetailedRegistry.get(
            expertGroupId
          );
          if (groupDetail) {
            groupDetail.blockingEvents = [
              ...groupDetail.blockingEvents.filter(
                (x) =>
                  !result.some((i) => i.blockingEventId === x.blockingEventId)
              ),
            ];
            this.rootStore.expertGroupStore.expertGroupDetailedRegistry.set(
              expertGroupId,
              groupDetail
            );
          }
        }
      });
    } catch (error) {
      console.log(error);
      toast.warn("Nastal problém při mazání absence nebo blokace");
      this.isLoading = false;
      throw error;
    }
  };

  @action loadHolidaysAndVacations = async (year: number) => {
    try {
      this.isLoading = true;
      const response = await agent.BlockingEvents.listVacations(year);
      runInAction(() => {
        this.vacations = response.corporateVacations;
        this.holidays = response.holidays;

        this.isLoading = false;
      });
    } catch (error) {
      console.log(error);
      runInAction(() => {
        this.isLoading = false;
      });
    }
  };

  @action createCompanyVacation = async (form: IBlockingEventCreateForm) => {
    try {
      this.isLoading = true;
      const response = await agent.BlockingEvents.create(form);

      toast.success("Celozávodní dovolená byla úspěšně vytvořena");
      runInAction(() => {
        this.isLoading = false;
        this.vacations = [...this.vacations, ...response];
      });
    } catch (error) {
      console.log(error);
      toast.success("Nastal problém při vytváření celozávodní dovolené");

      runInAction(() => {
        this.isLoading = false;
      });
    }
  };

  @action updateCompanyVacation = async (form: IBlockingEventUpdateForm) => {
    try {
      this.isLoading = true;
      const response = await agent.BlockingEvents.update(form);

      toast.success("Celozávodní dovolená byla úspěšně uložena");
      runInAction(() => {
        this.isLoading = false;
        this.vacations = [
          ...this.vacations.filter(
            (v) => v.blockingEventId !== form.blockingEventId
          ),
          ...response,
        ];
      });
    } catch (error) {
      console.log(error);
      toast.success("Nastal problém při ukládání celozávodní dovolené");

      runInAction(() => {
        this.isLoading = false;
      });
    }
  };

  @action deleteCompanyVacation = async (form: IBlockingEventDeleteForm) => {
    try {
      this.isLoading = true;
      await agent.BlockingEvents.delete(form);

      toast.success("Celozávodní dovolená byla úspěšně smazána");
      runInAction(() => {
        this.isLoading = false;
        // update vacations in the store

        this.vacations = [
          ...this.vacations.filter(
            (v) => v.blockingEventId !== form.blockingEventId
          ),
        ];
      });
    } catch (error) {
      console.log(error);
      toast.success("Nastal problém při mazání celozávodní dovolené");

      runInAction(() => {
        this.isLoading = false;
      });
    }
  };
}
