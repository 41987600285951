import React from "react";
import Downshift from "downshift";
import "./AutocompleteInput.scss";

import { FieldRenderProps } from "react-final-form";
import { Row, Col, Form } from "react-bootstrap";
type Props = FieldRenderProps<string, any>;
const AutocompleteInput: React.FC<Props> = ({
  input,
  inputOnChange,
  meta,
  title,
  warning,
  placeholder,
  items,
  disabled,
  required,
  ...rest
}) => {
  const errorMessage = (meta.error || meta.submitError) && meta.touched && (
    <Form.Control.Feedback type="invalid">
      {meta.error || meta.submitError}
    </Form.Control.Feedback>
  );

  const warningMessage = warning && (
    <div className="warning-feedback">{warning}</div>
  );


  return (
    <Downshift
      onInputValueChange={(inputValue) => {
        input.onChange(inputValue);
      }}
      inputValue={input.value}
      itemToString={(item: any) => (item ? item : input.value)}
      selectedItem={false}
    >
      {({
        openMenu,
        closeMenu,
        getInputProps,
        getItemProps,
        isOpen,
        inputValue,
        highlightedIndex,
        selectedItem,
      }) => {
        return (
          <div className="downshift downshift-autocomplete">
            <Form.Group controlId={input.name}>
              <Row>
                <Col xs={4} className="u-text-right">
                  {title && (
                    <Form.Label className={required ? "required " : ""}>
                      {title}
                    </Form.Label>
                  )}
                </Col>
                <Col xs={8}>
                  <input
                    className={`form-control ${
                      !!meta.error && meta.touched && "is-invalid"
                    } ${warning && "is-warning"}`}
                    {...getInputProps({
                      placeholder: placeholder,
                      name: input.name,
                      disabled: disabled,
                      onBlur: (event) => {
                        input.onBlur(event);
                        closeMenu();
                      },
                      onFocus: () => {
                        openMenu();
                      },
                    })}
                  />

                  {isOpen && (
                    <div className="downshift-dropdown">
                      {items
                        ?.sort((a: any, b: any) => (a < b ? -1 : 1))
                        .filter(
                          (item: any) =>
                            !inputValue ||
                            item
                              .toUpperCase()
                              .includes(inputValue.toUpperCase())
                        )
                        .map((item: any, index: number) => (
                          <div
                            className="dropdown-item"
                            {...getItemProps({
                              key: item.id || index,
                              index,
                              item: item,
                              style: {
                                color: "black",
                                backgroundColor:
                                  highlightedIndex === index
                                    ? " #cce9f7"
                                    : "white",
                                fontWeight:
                                  selectedItem === item ? "bold" : "normal",
                              },
                            })}
                          >
                            <div> {item}</div>
                          </div>
                        ))}
                    </div>
                  )}

                  {errorMessage}
                  {warningMessage}
                </Col>
              </Row>
            </Form.Group>
          </div>
        );
      }}
    </Downshift>
  );
};

export default AutocompleteInput;
