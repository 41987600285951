import React, { useContext, useEffect } from "react";
import { Form, Button } from "react-bootstrap";
import { Field, Form as FinalForm } from "react-final-form";
import MultiSelectInput from "../../app/common/form/MultiSelectInput";
import { RootStoreContext } from "../../app/stores/rootStore";
import { observer } from "mobx-react-lite";
import Loader from "../loader/Loader";
import { IAddedWorker } from "../../app/models/expertGroup";
import { IUser } from "../../app/models/user";

interface IProps {
  expertGroupId: number;
  addWorkers: (workers: IUser[]) => void;
  addedWorkers: IAddedWorker[];
}
const WorkerAssignForm: React.FC<IProps> = ({ expertGroupId, addWorkers, addedWorkers }) => {
  const rootStore = useContext(RootStoreContext);
  const { closeModal } = rootStore.modalStore;
  const {
    loadUnassignUsers,
    unassignedUsers,
    loadingInitial,
  } = rootStore.userStore;

  useEffect(() => {
    loadUnassignUsers(expertGroupId);
  }, [loadUnassignUsers]);

  const handleFinalFormSubmit = (form: any) => {
    const selectedWorkers = unassignedUsers.filter((w) =>
      form.workers.map((f: any) => f.value).includes(w.userId)
    );
    addWorkers(selectedWorkers);
    closeModal();
  };

  return (
    <div>
      {!loadingInitial && (
        <FinalForm
          onSubmit={handleFinalFormSubmit}
          validate={(values) => {
            const errors = {} as any;

            if (!values.workers || !values.workers.length) {
              errors.workers = "Musí být vybrán alespoň 1 pracovník";
            }

            return errors;
          }}
          render={({ handleSubmit }) => (
            <Form onSubmit={handleSubmit}>
              <div style={{ margin: "1rem 5rem 2rem 5rem" }}>
                <Field
                  name="workers"
                  closeMenuOnSelect={false}
                  options={unassignedUsers
                    .filter(
                      (u) =>
                        !addedWorkers.map((w) => w.userId).includes(u.userId)
                    )
                    .map((u) => ({
                      value: u.userId,
                      label: `${u.fullName} (${u.login})`,
                    }))}
                  placeholder="Vyberte pracovníky"
                  component={MultiSelectInput}
                />
              </div>
              <hr />
              <div className="flex-row flex-center">
                <Button
                  type="submit"
                  className="btn-modal"
                >{`Přidej pracovníky`}</Button>
                <Button
                  type="button"
                  onClick={closeModal}
                  className="btn-modal"
                  variant="outline-secondary"
                >
                  Zrušit
                </Button>
              </div>
            </Form>
          )}
        />
      )}
      {loadingInitial && <Loader />}
    </div>
  );
};

export default observer(WorkerAssignForm);
