import React, {Fragment, SyntheticEvent} from 'react';
import {Table} from "react-bootstrap";
import {Field, useForm} from "react-final-form";
import CheckboxInput from "../../../../app/common/form/CheckboxInput";
import CheckboxGroupInput from "../../../../app/common/form/CheckboxGroupInput";
import {IProtocolArea, IProtocolCodeTableValues, IRidingNoisesProtocol} from "../../../../app/models/protocol";
import './RidingNoisesExecutedTestsArea.scss'
import {TrackWeatherConditions} from "./WeatherConditionGroup";

interface IProps {
    testArea: IProtocolArea;
    protocolCodeTableValues: IProtocolCodeTableValues[];
    isEdit: boolean;
}

const RidingNoisesExecutedTestsArea: React.FC<IProps> = (
    {testArea, isEdit, protocolCodeTableValues}
) => {
    const form = useForm<IRidingNoisesProtocol>();
    const isDisabled: boolean = form.getFieldState('executedTestArea.notPerform')?.value;
    return (
        <>
            <Table className="protocol-table">
                <thead>
                <tr>
                    <th colSpan={10} className="headingrow">
                        <h2>{testArea.name}</h2>
                    </th>
                    <th colSpan={2} className={`headingrow ${isDisabled ? "" : "no-print"}`}>
                        <Field
                            name={`executedTestArea.notPerform`}
                            title="Neprovedeno"
                            options={[
                                {key: 1, value: "ano"},
                                {key: 0, value: " ne "},
                            ]}
                            format={(v) => (v ? 1 : 0)}
                            parse={(v) => (!!v)}
                            type={"checkbox"}
                            component={CheckboxInput}
                            disabled={!isEdit}
                            labelWidth={8}
                            valueWidth={4}
                        />
                    </th>
                </tr>
                </thead>
            </Table>

            <Table className={`protocol-table ${isDisabled ? "no-print" : ""}`}>
                <thead>
                <tr className={`${isDisabled ? "disabled" : ""}`}>
                    <th className="labelrow" colSpan={8}>Název</th>
                    <th className="labelrow" colSpan={1} style={{textAlign: "center"}}>Provedeno</th>
                    <th className="labelrow" colSpan={1} style={{textAlign: "center"}}>Neprovedeno</th>
                </tr>
                </thead>
                <tbody>
                {testArea.partRules?.map((partRule, ruleIndex) => {
                    const notPerform = form.getFieldState(`executedTestArea.partRules[${ruleIndex}].notPerform`)?.value;
                    return (
                        <tr key={partRule.protocolRuleId} className={`${isDisabled ? "disabled" : ""}`}>
                            <td colSpan={8} className=''>
                                <div className={'d-flex flex-column'}>
                                {partRule.name}
                                {
                                    (!notPerform && partRule.enumCodeTableValues && !isDisabled) &&
                                    <TrackWeatherConditions enumCodeTableValues={partRule.enumCodeTableValues}
                                                            protocolCodeTableValues={protocolCodeTableValues}
                                                            isEdit={isEdit}
                                    />
                                }
                                </div>
                            </td>
                            <td colSpan={1} style={{textAlign: "center"}}>
                                <Field<boolean>
                                    name={`executedTestArea.partRules[${ruleIndex}].isOK`}
                                    type="checkbox"
                                    inputOnChange={() => {
                                        form.getFieldState(`executedTestArea.partRules[${ruleIndex}].notPerform`)?.change(!form.getFieldState(`executedTestArea.partRules[${ruleIndex}].isOK`)?.value)
                                    }}
                                    component={CheckboxGroupInput}
                                    disabled={!isEdit || isDisabled}
                                />
                            </td>

                            <td colSpan={1} style={{textAlign: "center"}}>
                                <Field<boolean>
                                    name={`executedTestArea.partRules[${ruleIndex}].notPerform`}
                                    type="checkbox"
                                    inputOnChange={(e: SyntheticEvent<HTMLInputElement>) => {
                                        form.getFieldState(`executedTestArea.partRules[${ruleIndex}].isOK`)?.change(!form.getFieldState(`executedTestArea.partRules[${ruleIndex}].notPerform`)?.value)
                                    }}
                                    component={CheckboxGroupInput}
                                    disabled={!isEdit || isDisabled}
                                />
                            </td>
                        </tr>
                    );
                })
                }
                </tbody>
            </Table>
        </>
    );
};

export default RidingNoisesExecutedTestsArea;
