import "./EECProtocol.scss";
import React, {Fragment, useContext, useEffect, useState} from "react";
import {observer} from "mobx-react-lite";
import {Link, RouteComponentProps} from "react-router-dom";
import {Field, Form as FinalForm} from "react-final-form";
import {Button, Col, Form, Row, Table} from "react-bootstrap";
import TextAreaInput from "../../../app/common/form/TextAreaInput";
import TextInput from "../../../app/common/form/TextInput";
import NumberInput from "../../../app/common/form/NumberInput";
import {RootStoreContext} from "../../../app/stores/rootStore";

import logo from "../../../assets/images/Skoda_Wordmark_RGB_Emerald_Green.svg";
import Can from "../../../authorization/Can";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPrint} from "@fortawesome/free-solid-svg-icons";
import ProtocolHeaderCarInfo from "./protocolParts/ProtocolHeaderCarInfo";
import ExtendedInfoMeasuredCarArea from "./protocolParts/ExtendedInfoMeasuredCarArea";
import MeasuringEquipmentArea from "./protocolParts/MeasuringEquipmentArea";
import MethodConductingArea from "./protocolParts/MethodConductingArea";
import ConductedTestsArea from "./protocolParts/ConductedTestsArea";
import {validateRule} from "./protocolFunctions";

import EditSaveResetButtons from "../../ButtonGroups/EditSaveResetButtons";
import {IEecProtocol, IProtocolArea, IProtocolPartRule} from "../../../app/models/protocol";
import {MutableState, Tools} from "final-form";

interface PartialProtocolParams {
  protocolTypeId: string;
  carId: string;
  carTestId: string;
  carAnalysisId: string;
  carProtocolId: string;
}

const EECProtocol: React.FC<RouteComponentProps<PartialProtocolParams>> = ({
    match,
    history,
  }) => {
    const rootStore = useContext(RootStoreContext);
    const { loadEnums, enums } = rootStore.enumStore;
    const { user } = rootStore.authStore;
    const [edit, setEdit] = useState(false);
    const { loadEHKProtocol, saveEHKProtocol, ehkProtocolRegistry, isLoading } = rootStore.protocolStore;
    const [eccProtocol, setEHKProtocol] = useState<IEecProtocol | undefined>(undefined);

    useEffect(() => {
      const protocolTypeId = Number(match.params.protocolTypeId);
      const carId = Number(match.params.carId);
      const carTestId : number | undefined = Number(match.params.carTestId);
      const carAnalysisId : number | undefined = Number(match.params.carAnalysisId);
      const carProtocolId = Number(match.params.carProtocolId);

      loadEnums();
      loadEHKProtocol(carId, protocolTypeId, carTestId, carAnalysisId, carProtocolId).then((response) =>
      {
       setEHKProtocol(response);
      });
    }, [
      match.params.carId,
      match.params.carTestId,
      match.params.carAnalysisId,
      match.params.protocolTypeId,
      loadEHKProtocol,
      loadEnums,
      ehkProtocolRegistry
    ]);

    const SetEvaluationResult = (args: any, state: MutableState<IEecProtocol>, utils: Tools<IEecProtocol>, defect : boolean | undefined) => {
      var isDefects : boolean = false;
      var evalResult : string = "vyhovuje";
      const activeFieldName : string | undefined = state.formState.active;
      const testArea: IProtocolArea | undefined = state.lastFormState?.values?.conductedTests;
      if(testArea && testArea.partRules)
      {
        testArea.partRules?.forEach((rule) => {
        if(rule && rule.isDefect === true)
        {
          isDefects = true;
          if(rule.isDefect === true && rule.isOK === true && activeFieldName?.includes("isOK") && evalResult == "vyhovuje"){
            isDefects = false;
          }
          if(isDefects){ evalResult = "nevyhovuje" }
        }
        });
      }
      if(defect)
      {
        evalResult = "nevyhovuje"
      }
      const result : string = `Zkoušené vozidlo  ${evalResult} ustanovení předpisu EHK č. R116-00.`;
      utils.changeValue(state, `evaluation`, () => result);
    };

    const handleFinalFormSubmit = (values: IEecProtocol) => {
      setEdit(false);
      ehkProtocolRegistry.set(`PT${values.protocolTypeId}-C${values.carId}-CT${values.carTestId}-CA${values.carAnalysisId}`, values);
      saveEHKProtocol(values).then((response) => {
        if (!match.params.carProtocolId || match.params.carProtocolId == 'null')
        {
          history.push(`/AnalysisEECProtocol/${response.protocolTypeId}/${response.carId}/${response.carTestId}/${response.carAnalysisId}/${response.carProtocolId}`);
        }
        setEHKProtocol(response);
      });
    };

    const handleReset = (form: any) => {
      form.reset();
      const carId = Number(match.params.carId);
      const carTestId = Number(match.params.carTestId);
      const protocolTypeId = Number(match.params.protocolTypeId);

      setEdit(false);
    };

    return (
      <div
        id="EHKProtocol"
        className="container"
        style={{ marginBottom: "3rem" }}
      >
        <div className="printheader">
          <div className="printheader__left">
            <div className="printheader--image">
              <img src={logo} height={80} alt='logo'/>
            </div>
          </div>
          <div style={{textAlign: "center", paddingTop: "15px"}}>
            <div className="text--text" style={{paddingTop: "5px"}}>Varovné zařízení proti odcizení vozidla</div>
            <div className="text--text" style={{paddingTop: "5px"}}>Zabezpečovací zařízení proti nastartování</div>
          </div>
          <div className="printheader__right">
            <div className="text--header">Zkouškový protokol</div>
            <div className="text--text">
              Pracovní skupina{" "}
              <span className="text--expert-group">
                {eccProtocol?.expertGroupName}
              </span>
            </div>
            <div className="text--text">
              <span>{eccProtocol?.placeCode}</span> {eccProtocol?.placeDescription}
            </div>
          </div>
        </div>

        <div className="printfooter">
          <Table>
            <tbody>
              <tr>
                <td colSpan={11}>
                  {eccProtocol?.placeCode} {eccProtocol?.car?.modelVdsCode || ""} / 1_01 &emsp; ŠKODA AUTO a.s., Tř. Václava Klementa 869, 293
                  01 Mladá Boleslav II., Česká republika
                </td>
              </tr>
              <tr>
                <td colSpan={3}>{eccProtocol?.documentName}</td>
                <td>{eccProtocol?.registrationNumber}</td>
                <td>{eccProtocol?.storageMethod}</td>
                <td>{eccProtocol?.shreddingSign}</td>
                <td colSpan={2}>{eccProtocol?.shreddingTriggerEvent}</td>
                <td>{eccProtocol?.classification}</td>
                <td>{eccProtocol?.storageLocation}</td>
              </tr>
            </tbody>
          </Table>
        </div>

        <table className="table-page-content">
          <thead>
            <tr>
              <td>
                {/* <!--place holder for the fixed-position header--> */}
                <div className="page-header-space"></div>
              </td>
            </tr>
          </thead>

          <tbody>
            <tr>
              <td>
                <div>
                  <FinalForm
                    onSubmit={handleFinalFormSubmit}
                    initialValues={eccProtocol}
                    mutators={{
                      handleChangeExecutedTest: (args, state, utils) => {
                        const fieldValue : boolean = args[0].target.checked;
                        const fieldName = args[0].currentTarget.name;
                        var name: string = "";
                        if(fieldValue === true)
                        {
                          var ruleIndex: number = -1;
                          fieldName.split('.').map((objName: string, i: number) => {
                            if(objName.includes("partRules")) { ruleIndex = Number.parseInt(objName.replace("partRules", "").replace("[", "").replace("]", "") || "-1"); }
                            name = objName;
                          })

                          if(name == 'isOK')
                          {
                            utils.changeValue(state, `conductedTests.partRules[${ruleIndex}].isDefect`, () => false);
                          }
                          if(name == 'isDefect')
                          {
                            utils.changeValue(state, `conductedTests.partRules[${ruleIndex}].isOK`, () => false);
                          }
                        }
                        SetEvaluationResult(args, state, utils, undefined);
                      },
                      checkMeasuredValue: (args, state, utils): boolean | undefined => {
                        const fieldName = args[0].currentTarget.name;
                        var ruleIndex: number = -1;

                        fieldName.split('.').map((objName: string, i: number) => {
                          if(objName.includes("partRules")) { ruleIndex = Number.parseInt(objName.replace("partRules", "").replace("[", "").replace("]", "") || "-1"); }
                        })

                        utils.changeValue(state, `conductedTests.partRules[${ruleIndex}].isOK`, () => false);
                        utils.changeValue(state, `conductedTests.partRules[${ruleIndex}].isDefect`, () => false);
                        SetEvaluationResult(args, state, utils, false);

                        var partRule: IProtocolPartRule | undefined;
                        if(state.lastFormState?.values?.conductedTests)
                        {
                          const area: IProtocolArea = state.lastFormState?.values?.conductedTests;
                          if(area.partRules && ruleIndex >= 0)
                          {
                            partRule = area.partRules[ruleIndex];
                          }
                          if(partRule)
                          {
                            if(partRule.measuredValue){
                              let validationResult = validateRule(partRule, partRule.measuredValue, 0);
                              var val: string = "undefined";
                              if(validationResult === true){
                                val = "NOK";
                                utils.changeValue(state, `conductedTests.partRules[${ruleIndex}].isOK`, () => false);
                                utils.changeValue(state, `conductedTests.partRules[${ruleIndex}].isDefect`, () => true);
                                SetEvaluationResult(args, state, utils, true);
                              }
                              else if(validationResult === false){
                                val = "OK";
                                utils.changeValue(state, `conductedTests.partRules[${ruleIndex}].isOK`, () => true);
                                utils.changeValue(state, `conductedTests.partRules[${ruleIndex}].isDefect`, () => false);
                                SetEvaluationResult(args, state, utils, false);
                              }

                            }
                          }
                        }
                        return undefined;
                      },
                      textAreaChange: (args, state, utils) => {
                        args[0].target.style.height = "15px";
                        const scHeight = args[0].target.scrollHeight;
                        args[0].target.style.height = `${scHeight}px`;

                        const fieldName = args[0].currentTarget.name;
                        utils.changeValue(state, `${fieldName}_textAreaHeight`, () => scHeight);
                      },
                      changeParamsProtocolNumber: (args, state, utils) => {

                        let order = state.lastFormState?.values.order;
                        let Year = state.lastFormState?.values.orderYear;
                        var orderYear: string = "";

                        if(Year){
                          if(Year?.toString().length == 4)
                          {
                            orderYear = Year?.toString().slice(-2)
                          }
                          else
                          {
                            orderYear = Year?.toString();
                          }
                        }
                        let protocolNumber = `${order}/M${orderYear}/${eccProtocol?.placeCostCenter}/E`
                        utils.changeValue(state, `protocolNumber`, () => protocolNumber);
                      },
                    }}
                    render={({ handleSubmit, values, form }) => (
                      <Form onSubmit={handleSubmit} id="protocol-form">
                        <div className="pageheader  no-print">
                          <div className="pageheader__left">
                            <h1>Zkouškový protokol</h1>
                            <h3>
                              Pracovní skupina{" "}
                              <span className="text--expert-group">
                                {eccProtocol?.expertGroupName}
                              </span>{" "}
                            </h3>
                          </div>
                          <div className="pageheader__right">
                            <Can
                              roleId={user?.userRoleId}
                              perform="electro-protocol:edit"
                              data={{
                                userExpertGroupId: user?.expertGroupId,
                              }}
                              yes={
                                <EditSaveResetButtons
                                edit={edit}
                                setEdit={setEdit}
                                handleReset={handleReset}
                                form={form}
                                isSaving={false}
                                printButton={true}
                                />
                              }
                              no={
                                <Button
                                  variant="outline-info"
                                  type="button"
                                  onClick={() => window.print()}>
                                  <FontAwesomeIcon icon={faPrint} title="Tisk" /> Tisk
                                </Button>
                              }
                            />
                            {!edit && (
                                <Fragment>
                                  <Button
                                    variant="outline-secondary"
                                    style={{
                                      marginLeft: "1rem",
                                    }}
                                    key={"CarDetail_" + eccProtocol?.car.carId}
                                    as={Link}
                                    to={`/car/${eccProtocol?.car.carId}`}
                                  >
                                    Detail vozu
                                  </Button>
                                  {eccProtocol?.carTest && (
                                    <Button
                                      variant="outline-secondary"
                                      style={{
                                        marginLeft: "1rem"
                                      }}
                                      key={
                                        "CarTest_" +
                                        eccProtocol?.carTest.carTestId
                                      }
                                      as={Link}
                                      to={`/carTest/${eccProtocol?.carTest.carTestId}`}
                                    >
                                      Detail zkoušky
                                  </Button>
                                  )}
                                </Fragment>
                              )}
                          </div>
                        </div>

                        <div className="pagecontent">
                          {!isLoading && eccProtocol && (
                            <ProtocolHeaderCarInfo
                              car={eccProtocol.car}
                              carTest={eccProtocol.carTest}
                              measurementDate={eccProtocol.measurementDate}
                            />
                          )}

                          <div className="protocol">
                            <Row className="no-print carinfo" style={{marginTop: "10px"}}>
                              <Col xs={4}>
                                <Row>
                                  <Col xs={4} className="">
                                    <div style={{paddingLeft: "10px"}}>Pořadové číslo</div>
                                  </Col>
                                  <Col xs={8}>
                                    <div style={{paddingRight:"20px"}}>
                                      <Field<number>
                                        name="order"
                                        initialValue={eccProtocol?.order}
                                        style={{textAlign: "right"}}
                                        inputOnChange={(e: any) => {
                                          form.mutators.changeParamsProtocolNumber(e);
                                        }}
                                        size="sm"
                                        component={NumberInput}
                                        parse={(value) => value && parseInt(value)}
                                        disabled={!edit}
                                      />
                                    </div>
                                  </Col>
                                </Row>
                              </Col>
                              <Col xs={4}>
                                <Row>
                                  <Col xs={4}>
                                    <div style={{paddingLeft: "10px"}}>Rok</div>
                                  </Col>
                                  <Col xs={8}>
                                    <div style={{paddingRight:"20px"}}>
                                      <Field<number>
                                        name={`orderYear`}
                                        initialValue={eccProtocol?.orderYear}
                                        style={{textAlign: "right"}}
                                        inputOnChange={(e: any) => {
                                          form.mutators.changeParamsProtocolNumber(e);
                                        }}
                                        size="sm"
                                        component={NumberInput}
                                        parse={(value) => value && parseInt(value)}
                                        disabled={!edit}
                                      />
                                    </div>
                                  </Col>
                                </Row>
                              </Col>
                              <Col xs={4}>
                                <Row>
                                  <Col xs={4}>
                                    <div style={{paddingLeft: "10px"}}>Protokol číslo</div>
                                  </Col>
                                  <Col xs={8}>
                                    <div>
                                      <Field<string>
                                        name="protocolNumber"
                                        initialValue={eccProtocol?.protocolNumber}
                                        component={TextInput}
                                        rows={1}
                                        size="sm"
                                        disabled={!edit}
                                      />
                                    </div>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>

                            {!isLoading && eccProtocol?.infoMeasuredCar && (
                              <ExtendedInfoMeasuredCarArea infoMeasuredCarArea={eccProtocol?.infoMeasuredCar} protocolNumber={eccProtocol?.protocolNumber} isEdit={edit} form={form} />
                            )}

                            <Row>
                              <Col xs={6}>
                                {!isLoading && eccProtocol?.measuringEquipment && (
                                  <MeasuringEquipmentArea measuringEquipmentArea={eccProtocol?.measuringEquipment} isEdit={edit} form={form} />
                                )}
                              </Col>
                              <Col xs={6}>
                                {!isLoading && eccProtocol?.methodConducting && (
                                  <MethodConductingArea methodConductingArea={eccProtocol?.methodConducting} />
                                )}
                              </Col>
                            </Row>

                            {!isLoading && eccProtocol?.conductedTests && (
                              <ConductedTestsArea conductedTestsArea={eccProtocol?.conductedTests} isEdit={edit} form={form} />
                            )}

                            {/* Vyhodnocení protokolu */}
                            {!isLoading && (
                              <div style={{paddingTop:"1em"}}>
                                <h2>Závěrečný výsledek</h2>
                                <Field<string>
                                  name="evaluation"
                                  rows={1}
                                  style={{height: values.evaluation_textAreaHeight && `${values.evaluation_textAreaHeight}px`}}
                                  inputOnChange={(e: any) => {
                                    form.mutators.textAreaChange(e);
                                  }}
                                  initialValue={eccProtocol?.evaluation}
                                  component={TextAreaInput}
                                  disabled={!edit}
                                />
                                <Field<number>
                                  name={`evaluation_textAreaHeight`}
                                  initialValue={eccProtocol?.evaluation_textAreaHeight}
                                  component="input"
                                  hidden
                                  disabled
                                />
                              </div>
                            )}

                            {/*Poznamka k celkovemu protokolu*/}
                            {!isLoading && (
                              <div style={{paddingTop:"1em"}}>
                                <h2>Poznámka</h2>
                                <Field<string>
                                  name="note"
                                  rows={5}
                                  style={{height: values.note_textAreaHeight && `${values.note_textAreaHeight}px`}}
                                  inputOnChange={(e: any) => {
                                    form.mutators.textAreaChange(e);
                                  }}
                                  initialValue={eccProtocol?.note}
                                  component={TextAreaInput}
                                  disabled={!edit}
                                />
                                <Field<number>
                                  name={`note_textAreaHeight`}
                                  initialValue={eccProtocol?.note_textAreaHeight}
                                  component="input"
                                  hidden
                                  disabled
                                />
                              </div>
                            )}
                            {!isLoading && eccProtocol  && (
                              <Table style={{marginTop: "50px"}}>
                                <tbody>
                                  <tr>
                                    <td style={{border: "0"}}>
                                    <Form.Group>
                                      <Row>
                                        <Col xs={3}>
                                          <Form.Label>Protokol zpracoval:</Form.Label>
                                        </Col>
                                        <Col xs={5} style={{textAlign: 'center', borderBottom: "1px solid lightgray"}}>
                                          <Form.Label>{eccProtocol.workersExecutingTest}</Form.Label>
                                        </Col>
                                      </Row>
                                    </Form.Group>
                                    </td>
                                  </tr>
                                </tbody>
                              </Table>
                            )}
                          </div>
                        </div>
                      </Form>
                    )}
                  />
                </div>
              </td>
            </tr>
          </tbody>

          <tfoot>
            <tr>
              <td>
                <div className="page-footer-space"></div>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    );
  };

  export default observer(EECProtocol);
