import React, { Fragment, useState } from "react";
import { Form, Row } from "react-bootstrap";
import { Table } from "react-bootstrap";
import { observer } from "mobx-react-lite";
import moment from "moment";
import Loader from "../../loader/Loader";
import Pages from "../../../app/common/utils/Pages";
import PrNumbersSearchBox from "./PrNumbersSearchBox"
import { usePrProductsCarModelsListQuery, usePrNumbersByProductQuery, useLoadEnumsQuery } from "../../../app/api/client";
import { IPrProductCarModel } from "../../../app/models/prNumber"

const PrNumbersByProductTab: React.FC = () => {

    const [ productId, setProductId ] = useState<number | undefined>();
    const [ searchFamily, setSearchFamily ] = useState<string>();
    const [ prNumCategoryId, setPrNumCategoryId ] = useState<number | undefined>();
    const [ searchPrNumber, setSearchPrNumber ] = useState<string | undefined>()
    
    const [pageNumber, setPageNumber] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(20);
    const cancelSearch = searchFamily !== undefined || prNumCategoryId !== undefined || searchPrNumber !== undefined;

    const { data: prProductsList, isFetching: isLoadingProducts } = usePrProductsCarModelsListQuery();
    const {
        data: prNumbersList,
        isFetching: isLoading
    } = usePrNumbersByProductQuery(pageNumber, pageSize, productId, searchFamily, searchPrNumber, prNumCategoryId);
    const {data: enums, isFetching: loadingEnums} = useLoadEnumsQuery();

    const LoadPrNumbers = (page: number)=> {
        setPageNumber(page);
    };

    const searchPrNumbers = (searchFamily?: string, searchPrNumber?: string, prNumCategoryId?: number | undefined) => {
        setSearchFamily(searchFamily);
        setPrNumCategoryId(prNumCategoryId);
        setSearchPrNumber(searchPrNumber);
        setPageNumber(1);
    };

    const cancelSearchPrNumbers = () => {
        setSearchFamily(undefined);
        setPrNumCategoryId(undefined);
        setSearchPrNumber(undefined);
        setPageNumber(1);
    }

    if (isLoading) return <Loader/>;

    return (
        <Fragment>
            <Row className="pageheader">
                <div className="pageheader__left col-sm-3 vertical-align">
                    <h3>PR čísla k produktu</h3>
                    <div>
                        <Form.Control
                            as="select"
                            name="prProductId"
                            onChange={(e: any) => {
                                const id = Number.parseInt(e.target.value);
                                setProductId(id);
                            }}
                            value={productId?.toString()}
                        >
                            <option></option>
                            {prProductsList?.prProductsCarModelsList.map((product: IPrProductCarModel) => (
                                <option key={product.prProductId} value={product.prProductId} >
                                    {'(' + product.prProductCode + ') ' + product.productName}
                                </option>
                            ))}
                        </Form.Control>
                    </div>
                </div>
                <div className="pageheader__right col-sm-9">
                    <PrNumbersSearchBox searchFamily={searchFamily}
                                        searchPrNumber={searchPrNumber}
                                        searchPrNumCategoryId={prNumCategoryId}
                                        cancelSearch={cancelSearch}
                                        prNumCategories={enums?.prNumberCategories}
                                        handleSearch={searchPrNumbers}
                                        handleCancelSearch={cancelSearchPrNumbers} />
                </div>
            </Row>
            <div>
                <Table>
                    <thead>
                        <tr>
                            <th>Kód rodiny</th>
                            <th>Název rodiny</th>
                            <th>Kategorie</th>
                            <th>PR číslo</th>
                            <th>Popis</th>
                            <th>Speciální text</th>
                            <th>Datum uvolnění</th>
                            <th>Datum stažení</th>
                        </tr>
                    </thead>
                    <tbody>
                    {prNumbersList && prNumbersList?.prNumbers && prNumbersList.prNumbers.map((prNumber, index) => 
                    (
                        <tr key={prNumber.prNumberId}>
                            <td>{prNumber.prFamilyCode}</td>
                            <td>{prNumber.prFamilyName}</td>
                            <td>{prNumber.prCategoryName}</td>
                            <td>{prNumber.prNumberCode}</td>
                            <td>{prNumber.prNumberDesc}</td>
                            <td>{prNumber.specialText}</td>
                            <td>{moment(prNumber.deploymentDate).format("DD.MM.YYYY")}</td>
                            <td>{moment(prNumber.withdrawalDate).format("DD.MM.YYYY")}</td>
                        </tr>
                    ))}
                    </tbody>
                </Table>
            </div>  
            {prNumbersList && 
                <footer className="footer">
                    <div className="footer-left">
                    </div>
                    <div className="footer-right">
                    <Pages
                        totalPages={prNumbersList.totalPages}
                        currentPage={prNumbersList.currentPage}
                        onClickEvent={LoadPrNumbers}
                    />
                    </div>
                </footer>
            }
        </Fragment>

    );
};

export default observer(PrNumbersByProductTab);