import {MutationCache, QueryClient, useMutation, useQuery} from "@tanstack/react-query";
import agent from "./agent";
import {
    IAcousticsProtocol,
    IConformityProtocol,
    IDrivingCharacteristicsProtocol,
    IPartSettingDTO,
    IRidingNoisesProtocol,
    IRuleSettingDTO,
    IWaterResistanceProtocol,
} from "../models/protocol";
import { IPrCategoryForm, IPrFamilyForm } from "../models/prNumber"
/*
import {toast} from "react-toastify";
import {AxiosError} from "axios";

const mutationCache = new MutationCache({
    onError: (error: unknown) => {
        const errors = (error as AxiosError).response?.data?.errors;
        toast.error(errors?.join("\n") || 'Vyskytla se chyba');
    },
})
*/

export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            refetchOnMount: true,
        },
    },
    //mutationCache
})


export const useWaterResistanceProtocolQuery = (carId: number, carTestId: number, protocolTypeId: number, carProtocolId?: number) => {
    return useQuery({
        queryKey: ['WaterResistanceProtocol', {carId, carTestId, protocolTypeId, carProtocolId}],
        queryFn: () => {
            return agent.Protocols.waterResistanceProtocol(carId, carTestId, protocolTypeId, carProtocolId);
        },
    });
}
export function useWaterResistanceProtocolMutation() {
    return useMutation({
        mutationFn: (protocol: IWaterResistanceProtocol) => {
            return agent.Protocols.updateWaterResistanceProtocol(protocol);
        },
        onSuccess: () => queryClient.invalidateQueries(['WaterResistanceProtocol']),
    });
}

export const useConformityProtocolQuery = (carId: number, carTestId: number, protocolTypeId: number, carProtocolId?: number) => {
    return useQuery({
        queryKey: ['ConformityProtocol', {carId, carTestId, protocolTypeId, carProtocolId}],
        queryFn: () => {
            return agent.Protocols.conformityProtocol(carId, carTestId, protocolTypeId, carProtocolId);
        },
    });
}
export function useConformityProtocolMutation() {
    return useMutation({
        mutationFn: (protocol: IConformityProtocol) => {
            return agent.Protocols.updateConformityProtocol(protocol);
        },
        onSuccess: () => queryClient.invalidateQueries(['ConformityProtocol'])
    });
}

export const useRidingNoisesProtocolQuery = (carId: number, carTestId: number, protocolTypeId: number, carProtocolId?: number) => {
    return useQuery({
        queryKey: ['RidingNoisesProtocol', {carId, carTestId, protocolTypeId, carProtocolId}],
        queryFn: () => {
            return agent.Protocols.ridingNoisesProtocol(carId, carTestId, protocolTypeId, carProtocolId);
        },
    });
}
export function useRidingNoisesProtocolMutation() {
    return useMutation({
        mutationFn: (protocol: IRidingNoisesProtocol) => {
            return agent.Protocols.updateRidingNoisesProtocol(protocol);
        },
        onSuccess: () => queryClient.invalidateQueries(['RidingNoisesProtocol'])
    });
}

export const useAcousticsProtocolQuery = (carId: number, carTestId: number, protocolTypeId: number, carProtocolId?: number) => {
    return useQuery({
        queryKey: ['AcousticsProtocol', {carId, carTestId, protocolTypeId, carProtocolId}],
        queryFn: () => {
            return agent.Protocols.acousticsProtocol(carId, carTestId, protocolTypeId, carProtocolId);
        },
    });
}
export function useAcousticsProtocolMutation() {
    return useMutation({
        mutationFn: (protocol: IAcousticsProtocol) => {
            return agent.Protocols.updateAcousticsProtocol(protocol);
        },
        onSuccess: () => queryClient.invalidateQueries(['AcousticsProtocol'])
    });
}

export const useLoadEnumsQuery = () => {
    return useQuery({
        queryKey: ['Enums'],
        queryFn: () => {
            return agent.Enums.list();
        },
        refetchOnMount: false,
    });
}

export const useProtocolCodeTableValuesQuery = (protocolTypeId: number, carTestId: number, protocolCodeTableId?: number) => {
    return useQuery({
        queryKey: ['ProtocolCodeTableValues', {protocolTypeId, carTestId, protocolCodeTableId}],
        queryFn: () => {
            return agent.Protocols.getProtocolCodeTableValues(protocolTypeId, carTestId, protocolCodeTableId);
        },
        refetchOnMount: false,
      //  enabled: !!protocolCodeTableId
    });
}

export const useDrivingCharacteristicsProtocolQuery = (carId: number, carTestId: number, protocolTypeId: number, carProtocolId?: number) => {
    return useQuery({
        queryKey: ['EngineDrivingCharacteristicsProtocol', {carId, carTestId, protocolTypeId, carProtocolId}],
        queryFn: () => {
            return agent.Protocols.drivingCharacteristicsProtocol(carId, carTestId, protocolTypeId, carProtocolId);
        },
    });
}
export function useDrivingCharacteristicsProtocolMutation() {
    return useMutation({
        mutationFn: (protocol: IDrivingCharacteristicsProtocol) => {
            return agent.Protocols.updateDrivingCharacteristicsProtocol(protocol);
        },
        onSuccess: () => queryClient.invalidateQueries(['EngineDrivingCharacteristicsProtocol'])
    });
}

export const createGroupScheduleOptions = (expertGroupId: number, start: Date, end: Date, userId?: number) => {
    return {
        queryKey: [
            'GroupSchedule',
            {start, end, expertGroupId, userId}
        ],
        queryFn: () => {
            return agent.Scheduling.loadDateRangeByExpertGroupAndUser(expertGroupId, start, end,  userId);
        },
        staleTime: 30 * 1000
    }
}

export const useProtocolRuleSettingsQuery = (protocolTypeId: number, carModelId?: number, carBodyTypeId?: number) => {
    return useQuery({
        queryKey: ['ProtocolRuleSettings', {protocolTypeId, carModelId, carBodyTypeId}],
        queryFn: () => {
            return agent.Protocols.getProtocolRules(protocolTypeId, carModelId, carBodyTypeId);
        },
        refetchOnMount: false,
        enabled: !!protocolTypeId
    });
}
export function useProtocolRuleSettingsMutation() {
    return useMutation({
        mutationFn: (ruleSetting: IRuleSettingDTO) => {
            return agent.Protocols.updateProtocolRule(ruleSetting);
        },
        onSuccess: () => queryClient.invalidateQueries(['ProtocolRuleSettings'])
    });
}

export const useProtocolPartSettingsQuery = (protocolTypeId: number) => {
    return useQuery({
        queryKey: ['ProtocolPartSettings', {protocolTypeId}],
        queryFn: () => {
            return agent.Protocols.getProtocolParts(protocolTypeId);
        },
        refetchOnMount: false,
        enabled: !!protocolTypeId
    });
}

export function useProtocolPartSettingsMutation() {
    return useMutation({
        mutationFn: (partSetting: IPartSettingDTO) => {
            return agent.Protocols.updateProtocolPart(partSetting);
        },
        onSuccess: () => queryClient.invalidateQueries(['ProtocolPartSettings'])
    });
}

export const useCarPrNumbersQuery = (carId: number) => {
    return useQuery({
        queryKey: ['CarPrNumbers', {carId}],
        queryFn: () => {
            return agent.Cars.getCarPrNumbers(carId);
        },
    });
}

export const usePrNumbersSyncStatesQuery = () => {
    return useQuery({
        queryKey: ['PrNumbersSyncStates'],
        queryFn: () => {
            return agent.PrNumbers.getPrNumbersSyncStates();
        },
        refetchOnMount: false
    });
}

export const usePrNumCategoriesQuery = (pageNumber: number, pageSize: number) => {
    return useQuery({
        queryKey: ['PrNumCategories', {pageNumber, pageSize}],
        queryFn: () => {
            return agent.PrNumbers.getPrNumCategories(pageNumber, pageSize);
        },
        refetchOnMount: false,
    });
}
export function useUpsertPrNumCategoryMutation() {
    return useMutation({
        mutationFn: (category: IPrCategoryForm) => {
            return agent.PrNumbers.upsertPrNumCategory(category);
        },
        onSuccess: () => {
            queryClient.invalidateQueries(['PrNumCategories'])
            queryClient.invalidateQueries(['Enums'])
        }
    });
}
export function useDeletePrNumCategoryMutation() {
    return useMutation({
        mutationFn: (prNumCategoryId: number) => {
            console.log(prNumCategoryId);
            return agent.PrNumbers.deletePrNumCategory(prNumCategoryId);
        },
        onSuccess: () => {
            queryClient.invalidateQueries(['PrNumCategories'])
            queryClient.invalidateQueries(['Enums'])
        }
    });
}

export const usePrNumProductsQuery = (pageNumber: number, pageSize: number, search?: string) => {
    return useQuery({
        queryKey: ['PrNumProducts', {pageNumber, pageSize, search}],
        queryFn: () => {
            return agent.PrNumbers.getPrNumProducts(pageNumber, pageSize, search);
        },
        refetchOnMount: false,
    });
}

export const usePrNumFamiliesQuery = (pageNumber: number, pageSize: number, search?: string, prNumCategoryId?: number) => {
    return useQuery({
        queryKey: ['PrNumFamilies', {pageNumber, pageSize, search, prNumCategoryId}],
        queryFn: () => {
            return agent.PrNumbers.getPrNumFamilies(pageNumber, pageSize, search, prNumCategoryId);
        },
        refetchOnMount: false,
    });
}
export function usePrNumFamilyMutation() {
    return useMutation({
        mutationFn: (family: IPrFamilyForm) => {
            return agent.PrNumbers.updatePrNumFamily(family);
        },
        onSuccess: () => queryClient.invalidateQueries(['PrNumFamilies'])
    });
}

export function usePrNumbersQuery(pageNumber: number, pageSize: number, searchFamily?: string, searchPrNumber?: string, prNumCategoryId?: number) {
    return useQuery({
        queryKey: ['PrNumbers', {pageNumber, pageSize, searchFamily, searchPrNumber, prNumCategoryId}],
        queryFn: () => {
            return agent.PrNumbers.getPrNumbers(pageNumber, pageSize, searchFamily, searchPrNumber, prNumCategoryId);
        },
        refetchOnMount: false,
    });
}

export function usePrProductsCarModelsListQuery() {
    return useQuery({
        queryKey: ['PrProductsCarModelsList'],
        queryFn: () => {
            return agent.PrNumbers.getPrProductsCarModelsList();
        },
        refetchOnMount: false,
    });
}

export function usePrNumbersByProductQuery(pageNumber: number, pageSize: number, prProductId?: number, searchFamily?: string, searchPrNumber?: string, prNumCategoryId?: number){
    return useQuery({
        queryKey: ['ProtocolCodeTableValues', {pageNumber, pageSize, prProductId, searchFamily, searchPrNumber, prNumCategoryId}],
        queryFn: () => {
            return agent.PrNumbers.getPrNumbersByProduct(pageNumber, pageSize, prProductId, searchFamily, searchPrNumber, prNumCategoryId);
        },
        refetchOnMount: false,
        enabled: !!prProductId
    })
}

export function useStartSyncAllPrNumbersMutation() {
    return useMutation({
        mutationFn: () => {
            return agent.PrNumbers.StartSyncAll();
        },
        onSuccess: () => queryClient.invalidateQueries(['PrNumbersSyncStates'])
    });
}
export function useStartSyncPrProductsMutation() {
    return useMutation({
        mutationFn: () => {
            return agent.PrNumbers.StartSyncPrProducts();
        },
        onSuccess: () => queryClient.invalidateQueries(['PrNumbersSyncStates'])
    });
}
export function useStartSyncPrFamiliesMutation() {
    return useMutation({
        mutationFn: () => {
            return agent.PrNumbers.StartSyncPrFamilies();
        },
        onSuccess: () => queryClient.invalidateQueries(['PrNumbersSyncStates'])
    });
}
export function useStartSyncPrNumbersByFamiliesMutation() {
    return useMutation({
        mutationFn: () => {
            return agent.PrNumbers.StartSyncPrNumbersByFamilies();
        },
        onSuccess: () => queryClient.invalidateQueries(['PrNumbersSyncStates'])
    });
}
export function useStartSyncPrNumbersByProductsMutation() {
    return useMutation({
        mutationFn: () => {
            return agent.PrNumbers.StartSyncPrNumbersByProducts();
        },
        onSuccess: () => queryClient.invalidateQueries(['PrNumbersSyncStates'])
    });
}