import { RootStore } from "./rootStore";
import { observable, action, runInAction, computed } from "mobx";
import agent from "../api/agent";
import { IUserDefinedCarFilter } from "../models/userDefinedFilter";
import { toast } from "react-toastify";

export default class UserDefinedFilterStore {
  rootStore: RootStore;
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @observable loadingInitial = false;
  // @observable carFilters: IUserDefinedCarFilter[] = [];
  @observable carFilterRegistry = new Map<number, IUserDefinedCarFilter>();

  @action loadFilters = async () => {
    this.carFilterRegistry.clear();
    this.loadingInitial = true;
    try {
      const filters = await agent.UserDefinedFilters.list();
      runInAction("load filters", () => {
        // this.carFilters = filters;
        filters.forEach((filter) =>
          this.carFilterRegistry.set(filter.userDefinedFilterId, filter)
        );
        this.loadingInitial = false;
      });
    } catch (error) {
      runInAction("load filters error", () => {
        this.loadingInitial = false;
      });
      throw error;
    }
  };

  @computed get carFiltersSorted() {
    return this.sortByName(Array.from(this.carFilterRegistry.values()));
  }

  sortByName(filters: IUserDefinedCarFilter[]) {
    const sorted = filters.sort((a, b) => (a.name < b.name ? -1 : 1));
    return sorted;
  }

  @action updateFilter = async (filter: IUserDefinedCarFilter) => {
    try {
      const filterId = await agent.UserDefinedFilters.update(filter);
      runInAction("editing filter", () => {
        // add/update filter in the filter registry
        filter.userDefinedFilterId = filterId;
        this.carFilterRegistry.set(filterId, filter);
        toast.success("Filtr byl úspěšně uložen!");
      });
      return filterId;
    } catch (error) {
      console.log(error);
      toast.error("Při ukládání filtru nastala chyba!");
      throw error;
    }
  };

  @action deleteFilter = async (id: number) => {
    try {
       await agent.UserDefinedFilters.delete(id);
      runInAction("delete filter", () => {
        //  remove filter from the registry
        this.carFilterRegistry.delete(id);
      });
    } catch (error) {
      console.log(error);
      toast.error("Při mazání filtru nastala chyba!");
      throw error;
    }
  };
}
