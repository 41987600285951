import React, { Fragment } from "react";
import { Table } from "react-bootstrap";
import { Field } from "react-final-form";
import { FormApi } from "final-form";
import { IProtocolArea, IEecProtocol } from "../../../../app/models/protocol";
import TextInput from "../../../../app/common/form/TextInput"

interface IProps {
  infoMeasuredCarArea: IProtocolArea;
  protocolNumber: string;
  isEdit: boolean;
  form: FormApi<IEecProtocol>;
}

const ExtendedInfoMeasuredCarArea: React.FC<IProps> = ({
  infoMeasuredCarArea,
  protocolNumber,
  isEdit,
  form
}) => {

    return (
      <Fragment>
        <Table className="protocol-table">
        <thead>
          <tr>
            <th className="headingrow" colSpan={8}>
              <h2>{infoMeasuredCarArea?.name}</h2>
            </th>
            <th className="headingrow" style={{ textAlign: "right" }} colSpan={4}>
              <div className="field-print-only"><h3>Protokol číslo : {protocolNumber}</h3></div>
            </th>
          </tr>
        </thead>
      </Table>

      <Table className={`protocol-table`}>
        <tbody>
              {infoMeasuredCarArea.partRules?.map((partRule, ruleIndex) => (
                <tr key={partRule.protocolRuleId}>
                  <td><b>{partRule.name} :</b></td>
                  <td className="with-border-bottom">
                      <Field<string>
                        name={`infoMeasuredCar.partRules[${ruleIndex}].controlUnitCode`}
                        component={TextInput}
                        initialValue={partRule.controlUnitCode}
                        rows={1}
                        size="sm"
                        disabled={!isEdit}
                      />
                  </td>
                  <td className="with-border-bottom">
                      <Field<string>
                        name={`infoMeasuredCar.partRules[${ruleIndex}].controlUnitSw`}
                        component={TextInput}
                        initialValue={partRule.controlUnitSw}
                        rows={1}
                        size="sm"
                        disabled={!isEdit}
                      />
                  </td>
                  <td className="with-border-bottom">
                      <Field<string>
                        name={`infoMeasuredCar.partRules[${ruleIndex}].controlUnitHw`}
                        component={TextInput}
                        initialValue={partRule.controlUnitHw}
                        rows={1}
                        size="sm"
                        disabled={!isEdit}
                      />
                  </td>
                </tr>
                ))
              }
          </tbody>
        </Table>
      </Fragment>
  );
};

export default ExtendedInfoMeasuredCarArea;
