import "./VinCode.scss";
import React from "react";

interface IProps {
  vin: string | undefined;
  important?: boolean;
  breakLine?: boolean;
}
const VinCode: React.FC<IProps> = ({ vin, breakLine, important }) => {
  const vinPart = vin && vin.slice(0, 9);
  const carNumberPart = vin && vin.slice(9, vin.length);
  const display = breakLine ? "block" : "inline";
  return (
    <span className={`vin ${important ? "vin--important" : ""}`}>
      <span className="vin__vinpart" style={{ display: display }}>
        {vinPart}
      </span>
      <span className="vin__carnumber" style={{ display: display }}>
        {carNumberPart}
      </span>
    </span>
  );
};

export default VinCode;
