import "./CopsConditionsTab.scss";

import React, {useContext, Fragment, useEffect, useState} from "react";
import { Row, Col, Form, Table, Button, ButtonGroup, OverlayTrigger, Tooltip, Container, Card } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faPen, faTrash, faSave } from "@fortawesome/free-solid-svg-icons";
import { observer } from "mobx-react-lite";
import { IEnums } from "../../../app/models/enum";
import { RootStoreContext } from "../../../app/stores/rootStore";
import {v4 as uuidv4} from 'uuid';

import AlertMessage from "../../alert/AlertMessage";
import 'react-nestable/dist/styles/index.css';

import { ICopsCarCondition, ICopsCarConditionsDTO, CopsCarCondition, IPlaceDTO, ITestDestination } from "../../../app/models/copsIntegration";
import CopsCarConditionForm from "./CopsCarConditionForm";
import { TIME_FORMAT, DATE_FORMAT } from "../../../app/common/utils/constants";
import moment from "moment";

interface IProps {
  enums: IEnums | undefined;
}

const CopsConditionsTab: React.FC<IProps> = ({ enums }) => {

  const rootStore = useContext(RootStoreContext);
  const { loadCopsCarConditions, saveCopsCarConditions, isLoading } = rootStore.copsIntegrationStore
  const { openModal, closeModal } = rootStore.modalStore;
  const [ errors, setErrors ] = useState([]);
  const [ showSubmitError, setShowSubmitError ] = useState(false);
  const [ conditions, setConditions ] = useState<ICopsCarCondition[]>([]);
  const [ places, setPlaces ] = useState<IPlaceDTO[]>([]);
  const [ testDestinations, setTestDestinations ] = useState<ITestDestination[]>([]);
  const [ existsChanges, setExistsChanges ] = useState<boolean>(false);

  useEffect(() => {

    loadCopsCarConditions().then(result => {
      if(result && result.copsCarConditions)
      {
        setConditions(result.copsCarConditions.map((c) => {
          c.uid = uuidv4();
          return c;
        }) || []);
      }
      if(result && result.places)
      {
        setPlaces(result.places);
      }
      if(result && result.testDestinations)
      {
        setTestDestinations(result.testDestinations);
      }
    })
    .catch((error: any) => {
      console.log(error);
      setErrors(
        error.response?.data?.errors &&
          Object.values(error.response?.data?.errors)
      );
      setShowSubmitError(true);
    });
  }, [loadCopsCarConditions, setConditions]);

  const handleAddNewCopsCarCondition = () => {
    var copsCondition: ICopsCarCondition = new CopsCarCondition();
    openModal(
      <CopsCarConditionForm copsCarCondition={copsCondition} enums={enums} places={places} testDestinations={testDestinations} handleCopsCarConditionForm={handleCopsCarCondition} />,
      {
        preventClosing: true,
        title: "Přidat podmínku synchronizace vozu z COPS"
      }
    );
  }

  const handleEditCopsCarCondition = (uid: string) => {
    var condition = conditions.find((c) => c.uid == uid);
    if(condition){
      openModal(
        <CopsCarConditionForm copsCarCondition={condition} enums={enums} places={places} testDestinations={testDestinations} handleCopsCarConditionForm={handleCopsCarCondition} />,
        {
          preventClosing: true,
          title: "Upravit podmínku synchronizace vozu z COPS"
        }
      );
    }
  }

  const handleCopsCarCondition = (copsCarConditionForm: ICopsCarCondition) => {
    setConditions([ ...conditions.filter((x) => x.uid !== copsCarConditionForm.uid), copsCarConditionForm]);
    setExistsChanges(true);
    closeModal();
  };

  const deleteCondition = (uid: string) => {
    var condition = conditions.find((c) => c.uid == uid);
    if(condition){
      var _conditions = conditions.filter((t) => t.uid !== uid);
      condition.isActive = false;
      condition.isModified = true;
      _conditions.push(condition);
      setConditions(_conditions);
      setExistsChanges(true);
    }
  }

  const SaveChanges = () => {
    const _conditions: ICopsCarConditionsDTO = { copsCarConditions: conditions }
    saveCopsCarConditions(_conditions)
    .then(result => {
     if(result && result.length > 0)
     {
      console.log(result);
      console.log("Uloženo")
       setConditions(result.map((c) => {
         c.uid = uuidv4();
         return c;
       }) || []);
     }
   })
    .catch((error: any) => {
      console.log(error);
      setErrors(
        error.response?.data?.errors &&
          Object.values(error.response?.data?.errors)
      );
      setShowSubmitError(true);
    });
    setExistsChanges(false);
  }

  return (
    <div>

      <div className="pageheader">
        <div className="pageheader__left">
          <h2>Podmínky synchronizace vozů z COPS</h2>
        </div>
        <div className="pageheader__right">
        {existsChanges && (
          <Button
          variant="outline-primary"
          style={{
            marginRight: "1rem",
          }}
          type="button"
          onClick={() => SaveChanges()}
        >
          <FontAwesomeIcon icon={faSave} /> Uložit
        </Button>
        )}
        </div>
      </div>

      <AlertMessage
        type="danger"
        heading="Nastala chyba při ukládání"
        show={showSubmitError}
        setShow={setShowSubmitError}
        messageList={errors}
      />

      <Row>
        <Col lg={12}>
          <Card>
            <Card.Body>
            <Table>
                <thead>
                  <tr>
                    <th>Zkušebna</th>
                    <th>VDS Kód</th>
                    <th>Modelový klíč</th>
                    <th>Model</th>
                    <th>Série</th>
                    <th>Odběratel</th>
                    <th>Počet</th>
                    <th>Datum a čas</th>
                    <th>
                    <OverlayTrigger overlay={<Tooltip id={`tooltip-addbutton`}>Přidat pravidlo</Tooltip>}>
                      <Button
                        style={{ marginRight: "1rem" }}
                        variant="primary"
                        onClick={() => handleAddNewCopsCarCondition()}
                        className="footer__btn-add"
                      >
                        <FontAwesomeIcon icon={faPlus} />
                      </Button>
                    </OverlayTrigger>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {conditions.filter( x => x.isActive).map((condition, index) => (
                    <tr key={condition.uid} className="condition_row">
                      <td>{condition.placeFullCode}</td>
                      <td>{condition.carVdsCode} </td>
                      <td>{condition.carModelCode} {condition.carBodyTypeId && <span>- {condition.carBodyTypeCode}</span>}</td>
                      <td>{condition.carModelName} {condition.carBodyTypeId && <span>- {condition.carBodyTypeName}</span>}</td>
                      <td>{condition.testDestinationCopsCode} - {condition.testDestinationCode} {condition.testDestinationName}</td>
                      <td>{condition.customerCode}</td>
                      <td>{condition.countCarLastSync}</td>
                      <td>{condition.lastModified && (moment(condition.lastModified).format(`${DATE_FORMAT} ${TIME_FORMAT}`))}</td>
                      <td>
                      <Button
                          size="sm"
                          style={{ width: "3em" }}
                          variant="link"
                          title="Upravit podmínku"
                          type="button"
                          onClick={() => handleEditCopsCarCondition(condition.uid)}
                        >
                          <FontAwesomeIcon icon={faPen} />
                        </Button>
                        <Button
                          variant="link"
                          size="sm"
                          onClick={() => deleteCondition(condition.uid)}
                          title="Smazat podmínku"
                        >
                          <FontAwesomeIcon icon={faTrash} />
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
      </Row>

    </div>
  );
};

export default observer(CopsConditionsTab);
