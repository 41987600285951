import "./SwitchInput.scss";
import React, { Fragment } from "react";
import { Form, Row, Col, ButtonGroup, Button } from "react-bootstrap";
import { FieldRenderProps } from "react-final-form";
type Props = FieldRenderProps<any, HTMLElement>;

const NullableBooleanSwitchInput: React.FC<Props> = ({
  input,
  inputOnChange,
  title,
  disabled,
  oneColor,
  size,
}: Props) => {
  const buttonGroup = (
    <ButtonGroup>
      <Button
        disabled={disabled}
        size={size}
        onClick={(e: any) => {
          input.onChange(true);
          inputOnChange && inputOnChange(true);
        }}
        variant={
          input.value && input.value === true ? "primary" : "outline-primary"
        }
      >
        {"ano"}
      </Button>
      <Button
        disabled={disabled}
        size={size}
        onClick={(e: any) => {
          input.onChange(false);
          inputOnChange && inputOnChange(false);
        }}
        variant={
          typeof input.value === "boolean" && input.value === false
            ? (oneColor?"primary": "danger")
            : (oneColor?"outline-primary": "outline-danger")
        }
      >
        {"ne"}
      </Button>
    </ButtonGroup>
  );

  return (
    <Fragment>
      {title && (
        <Row>
          <Col sm={4} className="u-text-right">
            {title && <Form.Label>{title}</Form.Label>}
          </Col>
          <Col sm={8}>{buttonGroup}</Col>
        </Row>
      )}
      {!title && buttonGroup}
    </Fragment>
  );
};

export default NullableBooleanSwitchInput;
