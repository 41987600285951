import React, { useEffect, useContext } from "react";
import { Tab, Row, Col, Nav } from "react-bootstrap";
import { observer } from "mobx-react-lite";
import "./SettingsPage.scss";
import { RootStoreContext } from "../../app/stores/rootStore";
import EnumsTab from "./tabs/EnumsTab";
import CarModelsAndTestTypesTab from "./tabs/CarModelsAndTestTypesTab";
import ParametersTab from "./tabs/ParametersTab";
import TestDefinitionsTab from "./tabs/TestDefinitionsTab";
import CarModelParamsTab from "./tabs/CarModelParamsTab";
import CopsMappingTab from "./tabs/CopsMappingTab";
import { ISettingsForm } from "../../app/models/settings";
import CompanyVacationTab from "./tabs/CompanyVacationTab";
import Can from "../../authorization/Can";
import ProtocolsTab from "./tabs/ProtocolsTab";
import PlanningConditionsTab from "./tabs/PlanningConditionsTab";
import CopsConditionsTab from "./tabs/CopsConditionsTab"
import PrNumbersSettingsTab from "./tabs/PrNumbersSettingsTab"

const SettingsPage = () => {
  const rootStore = useContext(RootStoreContext);
  const { enums, loadEnums } = rootStore.enumStore;
  const { user } = rootStore.authStore;

  useEffect(() => {
    loadEnums();
  }, [loadEnums]);

  const defaultTab = () : string => {
    const tabKey = window.location.hash.replace("#", "");
    if(tabKey) return tabKey;
    else if (user?.userRoleId === 2) return "protocols";
    else if (user?.userRoleId === 3) return "prNumbers";
    else if (user?.userRoleId === 4) return "testDefinitions";
    else return "parameters";
  };

  return (
    <div className="page-content">
      <div className="container-full">
        <div className="pageheader">
          <div className="pageheader__left">
            <h1>Nastavení aplikace Q-Space {user?.placeName}</h1>
          </div>
        </div>
        <div id="settings">
          <Tab.Container
            id="settings-container"
            mountOnEnter={true}
            unmountOnExit={true}
            defaultActiveKey={defaultTab()}
          >
            <Row>
              <Col sm={2} className="settings-sidemenu-container no-print">
                <Nav variant="pills" className="flex-column settings-sidemenu">
                  <Can
                    roleId={user?.userRoleId}
                    perform="settings-parameters:visit"
                    yes={
                      <Nav.Item>
                        <Nav.Link eventKey="parameters" href="#parameters">
                          Parametry aplikace
                        </Nav.Link>
                      </Nav.Item>
                    }
                  />
                  <Can
                    roleId={user?.userRoleId}
                    perform="settings-enums:visit"
                    yes={
                      <Nav.Item>
                        <Nav.Link eventKey="enums" href="#enums">
                          Číselníky aplikace
                        </Nav.Link>
                      </Nav.Item>
                    }
                  />
                  <Can
                    roleId={user?.userRoleId}
                    perform="settings-editableEnums:visit"
                    yes={
                      <Nav.Item>
                        <Nav.Link
                          eventKey="editableEnums"
                          href="#editableEnums"
                        >
                          Modely vozů a typy zkoušek
                        </Nav.Link>
                      </Nav.Item>
                    }
                  />
                  <Can
                    roleId={user?.userRoleId}
                    perform="settings-carModelParams:visit"
                    yes={
                      <Nav.Item>
                        <Nav.Link
                          eventKey="carModelParams"
                          href="#carModelParams"
                        >
                          Parametry modelů vozů
                        </Nav.Link>
                      </Nav.Item>
                    }
                  />
                  <Can
                    roleId={user?.userRoleId}
                    perform="settings-copsMapping:visit"
                    yes={
                      <Nav.Item>
                        <Nav.Link eventKey="copsMapping" href="#copsMapping">
                          Mapování datového skladu
                        </Nav.Link>
                      </Nav.Item>
                    }
                  />
                  <Can
                    roleId={user?.userRoleId}
                    perform="settings-testDefinitions:visit"
                    yes={
                      <Nav.Item>
                        <Nav.Link
                          eventKey="testDefinitions"
                          href="#testDefinitions"
                        >
                          Definice zkoušek
                        </Nav.Link>
                      </Nav.Item>
                    }
                  />
                  <Can
                    roleId={user?.userRoleId}
                    perform="settings-companyVacation:visit"
                    yes={
                      <Nav.Item>
                        <Nav.Link
                          eventKey="companyVacation"
                          href="#companyVacation"
                        >
                          Svátky a dovolené
                        </Nav.Link>
                      </Nav.Item>
                    }
                  />
                  <Can
                    roleId={user?.userRoleId}
                    perform="settings-protocols:visit"
                    yes={
                      <Nav.Item>
                        <Nav.Link eventKey="protocols" href="#protocols">
                          Konfigurace protokolů
                        </Nav.Link>
                      </Nav.Item>
                    }
                  />
                  <Can
                    roleId={user?.userRoleId}
                    perform="settings-planning:visit"
                    yes={
                      <Nav.Item>
                        <Nav.Link eventKey="planning" href="#planning">
                          Podmínky plánování
                        </Nav.Link>
                      </Nav.Item>
                    }
                  />
                  <Can
                    roleId={user?.userRoleId}
                    perform="settings-copsConditions:visit"
                    yes={
                      <Nav.Item>
                        <Nav.Link eventKey="copsConditions" href="#copsConditions">
                          Podmínky synchronizace vozů z COPS
                        </Nav.Link>
                      </Nav.Item>
                    }
                  />
                  <Can
                    roleId={user?.userRoleId}
                    perform="settings-prNumbers:visit"
                    yes={
                      <Nav.Item>
                        <Nav.Link eventKey="prNumbers" href="#prNumbers">
                          PR popis
                        </Nav.Link>
                      </Nav.Item>
                    }
                  />
                </Nav>
              </Col>
              <Col sm={10} className="settings-pane-container">
                <Tab.Content>
                  <Can
                    roleId={user?.userRoleId}
                    perform="settings-parameters:visit"
                    yes={
                      <Tab.Pane
                        eventKey="parameters"
                        className="settings settings-pane"
                      >
                        <ParametersTab />
                      </Tab.Pane>
                    }
                  />
                  <Can
                    roleId={user?.userRoleId}
                    perform="settings-testDefinitions:visit"
                    yes={
                      <Tab.Pane
                        eventKey="testDefinitions"
                        className="settings settings-pane"
                      >
                        <TestDefinitionsTab enums={enums} />
                      </Tab.Pane>
                    }
                  />
                  <Can
                    roleId={user?.userRoleId}
                    perform="settings-enums:visit"
                    yes={
                      <Tab.Pane
                        eventKey="enums"
                        className="settings settings-pane"
                      >
                        <EnumsTab enums={enums} />
                      </Tab.Pane>
                    }
                  />
                  <Can
                    roleId={user?.userRoleId}
                    perform="settings-editableEnums:visit"
                    yes={
                      <Tab.Pane
                        eventKey="editableEnums"
                        className="settings settings-pane"
                      >
                        <CarModelsAndTestTypesTab enums={enums} />
                      </Tab.Pane>
                    }
                  />
                  <Can
                    roleId={user?.userRoleId}
                    perform="settings-carModelParams:visit"
                    yes={
                      <Tab.Pane
                        eventKey="carModelParams"
                        className="settings settings-pane"
                      >
                        <CarModelParamsTab enums={enums} />
                      </Tab.Pane>
                    }
                  />

                  <Can
                    roleId={user?.userRoleId}
                    perform="settings-copsMapping:visit"
                    yes={
                      <Tab.Pane
                        eventKey="copsMapping"
                        className="settings settings-pane"
                      >
                        <CopsMappingTab enums={enums} />
                      </Tab.Pane>
                    }
                  />

                  <Can
                    roleId={user?.userRoleId}
                    perform="settings-companyVacation:visit"
                    yes={
                      <Tab.Pane
                        eventKey="companyVacation"
                        className="settings settings-pane"
                      >
                        <CompanyVacationTab />
                      </Tab.Pane>
                    }
                  />

                  <Can
                    roleId={user?.userRoleId}
                    perform="settings-protocols:visit"
                    yes={
                      <Tab.Pane
                        eventKey="protocols"
                        className="settings settings-pane"
                      >
                        <ProtocolsTab enums={enums} />
                      </Tab.Pane>
                    }
                  />
                  <Can
                    roleId={user?.userRoleId}
                    perform="settings-planning:visit"
                    yes={
                      <Tab.Pane
                        eventKey="planning"
                        className="settings settings-pane"
                      >
                        <PlanningConditionsTab enums={enums} />
                      </Tab.Pane>
                    }
                  />
                  <Can
                    roleId={user?.userRoleId}
                    perform="settings-copsConditions:visit"
                    yes={
                      <Tab.Pane
                        eventKey="copsConditions"
                        className="settings settings-pane"
                      >
                        <CopsConditionsTab enums={enums} />
                      </Tab.Pane>
                    }
                  />
                  <Can
                    roleId={user?.userRoleId}
                    perform="settings-prNumbers:visit"
                    yes={
                      <Tab.Pane
                        eventKey="prNumbers"
                        className="settings settings-pane"
                      >
                        <PrNumbersSettingsTab enums={enums} />
                      </Tab.Pane>
                    }
                  />
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </div>
      </div>
    </div>
  );
};

export default observer(SettingsPage);
