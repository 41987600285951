import React, { useContext, useEffect, useState } from "react";
import { Form, Col, Row, Button, Container } from "react-bootstrap";
import { Field, Form as FinalForm, FormSpy } from "react-final-form";
import TextInput from "../../app/common/form/TextInput";
import SwitchInput from "../../app/common/form/SwitchInput";
import ColorPicker from "../../app/common/form/ColorPicker";
import MultiSelectInput from "../../app/common/form/MultiSelectInput";
import { RootStoreContext } from "../../app/stores/rootStore";
import { observer } from "mobx-react-lite";
import { RouteComponentProps, Prompt, Link } from "react-router-dom";
import SelectInput from "../../app/common/form/SelectInput";
import AlertMessage from "../alert/AlertMessage";
import { NewUser, IUser, IUserForm } from "../../app/models/user";
import { FormApi } from "final-form";
import ToggleSwitchInput from "../../app/common/form/ToggleSwitchInput";
import LoginAutocompleteInput from "../../app/common/form/LoginAutocompleteInput";
import Can from "../../authorization/Can";
import EditSaveResetButtons from "../ButtonGroups/EditSaveResetButtons";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import { userRoles } from "../../app/models/auth";

interface DetailParams {
  id: string;
}

const UserDetailPage: React.FC<RouteComponentProps<DetailParams>> = ({
  match,
  history,
}) => {
  const rootStore = useContext(RootStoreContext);
  const { enums, loadEnums } = rootStore.enumStore;
  const {
    loadUser,
    userRegistry,
    updateUser,
    loadUsersByLogin,
    usersByLogin,
    isSaving
  } = rootStore.userStore;
  const { user: authUser } = rootStore.authStore;
  const { openDialog } = rootStore.modalStore;

  const [edit, setEdit] = useState(false);
  const [editLogin, setLoginEdit] = useState(false);
  const [changed, setChanged] = useState(false);

  const [user, setUser] = useState<IUser | undefined | NewUser>(new NewUser());

  const [errors, setErrors] = useState([]);
  const [showSubmitError, setShowSubmitError] = useState(false);

  const [fullName, setFullName] = useState("");

  useEffect(() => {
    const userId = Number(match.params.id);
    if (!userId && match.params.id) history.push("/notfound");
    loadEnums();

    if (!userId) {
      setEdit(false);
      setLoginEdit(true);
      setUser(new NewUser());
    }

    if (userId) {
      setLoginEdit(false);
      loadUser(userId).then(() => {
        setUser(userRegistry.get(userId));
      });
    }
  }, [loadEnums, match.params.id, loadUser, history, userRegistry]);

  const getFullName = (
    titleBefore: string | undefined,
    firstName: string | undefined,
    surname: string | undefined,
    titleAfter: string | undefined
  ) => {
    return `${titleBefore ? titleBefore + " " : ""}${
      firstName ? firstName + " " : ""
    }${surname ? surname + " " : ""}${titleAfter ? titleAfter : ""}`;
  };

  // NEW USER FORM - if login does not exist unlock rest of form fields and lock login field
  // -- if login exists redirect to user detail page
  const handleValidateLogin = (login: string) => {
    loadUsersByLogin(login).then((logins) => {
      if (logins && logins.length > 0) {
        history.push(`/user/${logins[0].userId}`);
        setLoginEdit(false);
        setEdit(false);
      } else {
        setLoginEdit(false);
        setEdit(true);
      }
    });
  };

  const handleFinalFormSubmit = (values: any, form: FormApi<IUserForm>) => {
    setErrors([]);
    setShowSubmitError(false);

    updateUser(values)
      .then((response) => {
        // nacti kolize z response
        const collisions = response.carTestConflicts;

        //pokud pole s konflikty nebude prazdne -> dialog s potvrzenim ulozeni
        if (!!collisions?.length && !values.isConfirmed) {
          toast.warn(`Uživatel zatím nebyl uložen`);
          openDialog(
            <div>
              <ul>
                {collisions.map((col) => (
                  <li key={col.carTestConflictId}>
                    {col.message}{" "}
                    {col.carTestId && (
                      <Link to={`/carTest/${col.carTestId}`} target="_blank">
                        <FontAwesomeIcon
                          title="Otevři detail zkoušky"
                          icon={faExternalLinkAlt}
                        />
                      </Link>
                    )}
                  </li>
                ))}
              </ul>
            </div>,
            {
              title: "Uložením změn u uživatele vzniknou kolize",
              confirmButtonLabel: "Pokračovat a uložit",
              cancelButtonLabel: "Zpět",
              onConfirm: () => {
                form.change("isConfirmed", true);
                form.submit();
              },
              onCancel: () => {
                form.change("isConfirmed", false);
              },
            }
          );
        }
        // pokud ulozenim nevznikly zadne kolize, nebo je uzivatel potvrdil, finalne uloz
        if (!collisions?.length || values.isConfirmed) {
          setEdit(false);
          setChanged(false);
          // pouze pro nove vytvoreneho uzivatele
          if (!match.params.id) history.push(`/user/${response.userId}`);
          form.change("isConfirmed", false);
          const user = { ...response, isConfirmed: false };
          form.initialize(user);
          toast.success(`Uživatel byl uložen`);
        }
      })
      .catch((error) => {
        console.log(error);
        setErrors(Object.values(error.response?.data?.errors));
        setShowSubmitError(true);
      });
    // };
  };

  // Reset all form values to initial values
  const handleReset = (form: any) => {
    form.reset();
    if (!user?.userId) setLoginEdit(true);
    setEdit(false);
    setChanged(false);
  };

  return (
    <Container style={{ marginTop: "1rem" }}>
      <Prompt
        when={edit && changed}
        message="Na stránce byly provedeny změny, které nejsou uloženy. Opravdu chcete odejít?"
      />

      <div>
        {enums && (
          <FinalForm
            onSubmit={handleFinalFormSubmit}
            initialValues={user}
            validate={(values) => {
              const errors = {} as any;
              if (!values.login) {
                errors.login = "Přihlašovací jméno musí být vyplněno";
              }
              if (!values.firstName) {
                errors.firstName = "Jméno musí být vyplněno";
              }
              if (!values.surname) {
                errors.surname = "Příjmení musí být vyplněno";
              }
              if (!values.shortcut) {
                errors.shortcut = "Zkratka musí být vyplněna";
              }
              if (values.shortcut && values.shortcut.length > 4) {
                errors.shortcut = "Zkratka může mít maximálně 4 znaky";
              }
              if (!values.userRoleId) {
                errors.userRoleId = "Musí být vybrána role";
              }

              return errors;
            }}
            render={({ handleSubmit, values, form }) => (
              <Form onSubmit={handleSubmit}>
                <div className="pageheader">
                  <div className="pageheader__left">
                    {!user?.userId ? (
                      <h1>Založení nového uživatele</h1>
                    ) : (
                      <h1>Uživatel {fullName}</h1>
                    )}
                  </div>
                  <div className="pageheader__right">
                    {!editLogin && (
                      <Can
                        roleId={authUser?.userRoleId}
                        perform="user-detail-page:edit"
                        yes={
                          <EditSaveResetButtons
                            edit={edit}
                            setEdit={setEdit}
                            handleReset={handleReset}
                            form={form}
                            isSaving={isSaving}
                          />
                        }
                      />
                    )}
                  </div>
                </div>
                <AlertMessage
                  type="danger"
                  heading="Nastala chyba při ukládání"
                  show={showSubmitError}
                  setShow={setShowSubmitError}
                  messageList={errors}
                />
                <h2>Základní údaje</h2>
                <Row>
                  <Col md={6}>
                    <Field<string>
                      name="login"
                      title="Přihlašovací jméno"
                      disabled={!editLogin}
                      items={[]}
                      component={LoginAutocompleteInput}
                      required
                      warning={
                        editLogin &&
                        !!usersByLogin.filter((c) => c.login === values.login)
                          .length &&
                        "Uživatel již existuje"
                      }
                    />

                    {editLogin && values.login && values.login.length > 6 && (
                      <Col xs={{ offset: 4 }}>
                        <Button
                          variant="outline-primary"
                          style={{ marginBottom: "1rem" }}
                          onClick={() => handleValidateLogin(values.login)}
                        >
                          Pokračuj
                        </Button>
                      </Col>
                    )}

                    <Field<string>
                      name="titleBefore"
                      title="Titul před jménem"
                      component={TextInput}
                      disabled={!edit}
                    />
                    <Field<string>
                      name="firstName"
                      title="Jméno"
                      component={TextInput}
                      disabled={!edit}
                      required
                    />
                    <Field<string>
                      name="surname"
                      title="Příjmení"
                      component={TextInput}
                      disabled={!edit}
                      required
                    />
                    <Field<string>
                      name="titleAfter"
                      title="Titul za jménem"
                      component={TextInput}
                      disabled={!edit}
                    />

                    <Form.Group controlId={"fullName"}>
                      <Row>
                        <Col xs={4} className="u-text-right">
                          <Form.Label>Celé jméno</Form.Label>
                        </Col>
                        <Col xs={8}>
                          <Form.Control
                            type="text"
                            value={getFullName(
                              values.titleBefore,
                              values.firstName,
                              values.surname,
                              values.titleAfter
                            )}
                            disabled={true}
                          />
                        </Col>
                      </Row>
                    </Form.Group>
                    <Field<string>
                      name="shortcut"
                      title="Zkratka"
                      component={TextInput}
                      disabled={!edit}
                      required
                    />
                    <Col md={{ offset: 4 }}>
                      <Field
                        name="isActive"
                        title=""
                        options={[
                          { key: 1, value: "aktivní" },
                          { key: 0, value: "neaktivní" },
                        ]}
                        format={(v) => (v ? 1 : 0)}
                        parse={(v) => (v ? true : false)}
                        component={SwitchInput}
                        disabled={!edit}
                      />
                    </Col>
                  </Col>
                  <Col md={6}>
                    <Field<string>
                      name="email"
                      type="email"
                      title="Email"
                      component={TextInput}
                      disabled={!edit}
                    />
                    <Field<string>
                      name="phone"
                      title="Telefon"
                      component={TextInput}
                      disabled={!edit}
                    />
                    <Field
                      name="colorId"
                      title="Barva"
                      initialValue={3}
                      options={enums.colors}
                      component={ColorPicker}
                      disabled={!edit}
                    />
                    <Field
                      title="Role"
                      name="userRoleId"
                      options={enums.userRoles}
                      component={SelectInput}
                      parse={(value) => (value ? parseInt(value) : undefined)}
                      disabled={!edit}
                    />
                    <Field
                      title="Odborná skupina"
                      name="expertGroupId"
                      options={enums.expertGroups}
                      isEmptyOption={true}
                      component={SelectInput}
                      parse={(value) => (value ? parseInt(value) : undefined)}
                      disabled={true}
                    />

                    <Field
                      name="performTests"
                      title="Provádí zkoušky"
                      type="checkbox"
                      initialValue={true}
                      component={ToggleSwitchInput}
                      disabled={!edit}
                    />
                    {values.userRoleId === userRoles.expertGroupLeader && (
                      <Field
                        title="Spravované skupiny"
                        name="managedGroups"
                        options={enums.expertGroups.filter(x => x.placeId == authUser?.placeId).map((g: any) => ({
                          value: g.key,
                          label: g.value,
                        }))}
                        format={(value) =>
                          value &&
                          value.map((v: any) => ({
                            value: v,
                            label: enums.expertGroups.find((g) => g.key === v)?.value,
                          }))
                        }
                        parse={(value) => value && value.map((v: any) => v.value)}
                        placeholder="Vyberte odborné skupiny"
                        component={MultiSelectInput}
                        disabled={!edit}
                      />
                    )}
                  </Col>
                </Row>
                
                <FormSpy
                  subscription={{ pristine: true, values: true }}
                  onChange={(state) => {
                    setChanged(!state.pristine);
                    setFullName(
                      getFullName(
                        state.values.titleBefore,
                        state.values.firstName,
                        state.values.surname,
                        state.values.titleAfter
                      )
                    );
                  }}
                />
              </Form>
            )}
          />
        )}
      </div>
    </Container>
  );
};

export default observer(UserDetailPage);
