import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { Field } from "react-final-form";
import TextInput from "../../../app/common/form/TextInput";
import SelectInput from "../../../app/common/form/SelectInput";
import CarColorSelectInput from "../../../app/common/form/CarColorSelectInput";
import { IEnums, CarStates } from "../../../app/models/enum";
import { ICar, NewCar } from "../../../app/models/car";
import { FormApi } from "final-form";

interface IProps {
  enums: IEnums | undefined;
  edit: boolean;
  values: ICar | NewCar;
  form: FormApi<ICar | NewCar>;
}

const CarParametersTab: React.FC<IProps> = ({ enums, edit, values, form }) => {
  const [emissionFree, setEmissionFree] = useState(false);

  const isEmissionFree = React.useCallback(
    (engineId: number | undefined, modelId: number | undefined) => {
      const emissionFree =
        enums?.carEngines.find((c) => c.id === engineId)?.emissionFree ||
        enums?.carModels.find((c) => c.id === modelId)?.emissionFree ||
        false;
      setEmissionFree(emissionFree);
    },
    [enums]
  );

  useEffect(() => {
    // set if selected car engine is emission free, when page is loaded
    // has to be done when enums and value of car engine are loaded
    if (enums && enums.carEngines && enums.carModels) {
      isEmissionFree(values.engineId, values.modelId);
    }
  }, [enums, values.engineId, isEmissionFree, values.modelId]);

  return (
    <div>
      <Row>
        <Col lg={4}>
          <Field
            name="modelId"
            title="Model"
            options={enums?.carModels
              .slice()
              .filter((a) => a.isActive || a.id === values.modelId)
              .sort((a, b) => (a.name < b.name ? -1 : 1))
              .map((model) => ({
                key: model.id,
                value: model.name,
                isDisabled: !model.isActive,
              }))}
            inputOnChange={(e: any) => {
              form.mutators.carModelReset(e);
              form.mutators.setModelVdsCode(e);
              form.mutators.setCarModelKey(e);
            }}
            component={SelectInput}
            isEmptyOption={true}
            warning={!values.modelId}
            parse={(value) => (value ? parseInt(value) : undefined)}
            disabled={
              !edit || [CarStates.OutOfTestroom].includes(values.carStateId)
            }
          />
          <Field
            name="bodyTypeId"
            title="Karoserie"
            options={enums?.carBodyTypes
              .slice()
              .filter(
                (bodyType) =>
                  bodyType.carModelId === values.modelId &&
                  (bodyType.isActive || bodyType.id === values.bodyTypeId)
              )
              .sort((a, b) => (a.name < b.name ? -1 : 1))
              .map((model) => ({
                key: model.id,
                value: model.name,
                isDisabled: !model.isActive,
              }))}
            inputOnChange={(e: any) => {
              form.mutators.setModelVdsCode(e);
              form.mutators.setCarModelKey(e);
            }}
            component={SelectInput}
            isEmptyOption={true}
            warning={!values.bodyTypeId}
            parse={(value) => (value ? parseInt(value) : undefined)}
            disabled={
              !edit || [CarStates.OutOfTestroom].includes(values.carStateId)
            }
          />
          <Field
            name="equipmentId"
            title="Výbava"
            options={enums?.carEquipments
              .filter(
                (eqp) =>
                  eqp.carModelId === values.modelId &&
                  (eqp.isActive || eqp.id === values.equipmentId)
              )
              .slice()
              .sort((a, b) => (a.name < b.name ? -1 : 1))
              .map((model) => ({
                key: model.id,
                value: model.name,
                isDisabled: !model.isActive,
              }))}
            inputOnChange={(e: any) => {
              form.mutators.setCarModelKey(e);
            }}
            component={SelectInput}
            isEmptyOption={true}
            warning={!values.equipmentId}
            parse={(value) => (value ? parseInt(value) : undefined)}
            disabled={
              !edit || [CarStates.OutOfTestroom].includes(values.carStateId)
            }
          />

          <Field
            name="engineId"
            title="Motorizace"
            options={enums?.carEngines
              .filter(
                (engine) =>
                  engine.carModelId === values.modelId &&
                  (engine.isActive || engine.id === values.engineId)
              )
              .slice()
              .sort((a, b) => (a.name < b.name ? -1 : 1))
              .map((model) => ({
                key: model.id,
                value: model.name,
                isDisabled: !model.isActive,
              }))}
            inputOnChange={(e: any) => {
              form.mutators.setCarModelKey(e);
            }}
            component={SelectInput}
            isEmptyOption={true}
            warning={!values.engineId}
            parse={(value) => (value ? parseInt(value) : undefined)}
            disabled={
              !edit || [CarStates.OutOfTestroom].includes(values.carStateId)
            }
          />
          <Field
            name="transmissionId"
            title="Převodovka"
            options={enums?.carTransmissions
              .filter(
                (transmission) =>
                  transmission.carModelId === values.modelId &&
                  (transmission.isActive ||
                    transmission.id === values.transmissionId)
              )
              .slice()
              .sort((a, b) => (a.name < b.name ? -1 : 1))
              .map((model) => ({
                key: model.id,
                value: model.name,
                isDisabled: !model.isActive,
              }))}
            inputOnChange={(e: any) => {
              form.mutators.setCarModelKey(e);
            }}
            component={SelectInput}
            isEmptyOption={true}
            parse={(value) => (value ? parseInt(value) : undefined)}
            disabled={
              !edit || [CarStates.OutOfTestroom].includes(values.carStateId)
            }
          />
        </Col>
        <Col lg={4}>
          <Field<string>
            name="modelKey"
            title="Modelový klíč"
            component={TextInput}
            disabled={
              !edit || [CarStates.OutOfTestroom].includes(values.carStateId)
            }
          />
          <Field<string>
            name="modelVdsCode"
            title="Vds kód"
            component={TextInput}
            disabled={
              !edit || [CarStates.OutOfTestroom].includes(values.carStateId)
            }
          />
          <Field<string>
            name="transmissionCode"
            title="Převodovka kód"
            warning={!values.transmissionCode}
            component={TextInput}
            disabled={
              !edit || [CarStates.OutOfTestroom].includes(values.carStateId)
            }
          />
          <Field<string>
            name="wheelDrive"
            title="Pohon"
            component={TextInput}
            disabled={
              !edit || [CarStates.OutOfTestroom].includes(values.carStateId)
            }
          />
        </Col>
        <Col lg={4}>
          <Field
            name="colorCode"
            title="Barva"
            warning={!values.colorCode}
            items={enums?.carColors
              .filter((cc) => cc.isActive || cc.code === values.colorCode?.slice(0, 2))
              .slice()
              .sort((a, b) => (a.name < b.name ? -1 : 1))}
            component={CarColorSelectInput}
            disabled={
              !edit || [CarStates.OutOfTestroom].includes(values.carStateId)
            }
          />
          <Field<string>
            name="typeOfControl"
            title="Typ řízení"
            warning={!values.typeOfControl}
            component={TextInput}
            disabled={
              !edit || [CarStates.OutOfTestroom].includes(values.carStateId)
            }
          />
          {!emissionFree && (
            <Field<string>
              name="emissionStandard"
              title="Emisní norma"
              warning={!values.emissionStandard}
              component={TextInput}
              disabled={
                !edit || [CarStates.OutOfTestroom].includes(values.carStateId)
              }
            />
          )}
          <Field<string>
            name="engineNumber"
            title="Číslo motoru"
            component={TextInput}
            disabled={
              !edit || [CarStates.OutOfTestroom].includes(values.carStateId)
            }
          />
        </Col>
      </Row>
    </div>
  );
};

export default CarParametersTab;
