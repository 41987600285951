import React, { Fragment, useState, useEffect } from "react";
import { Row, Button } from "react-bootstrap";
import { Table } from "react-bootstrap";
import { observer } from "mobx-react-lite";
import moment from "moment";
import Loader from "../../loader/Loader";
import Pages from "../../../app/common/utils/Pages";
import {usePrNumProductsQuery} from "../../../app/api/client";
import PrProductsSearchBox from "./PrProductsSearchBox"

const PrNumbersProductsTab: React.FC = () => {

    const [search, setSearch] = useState<string>()
    const [pageNumber, setPageNumber] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(20);
    const cancelSearch = search !== undefined;

    const {
        data: prNumProductsList,
        isFetching: isLoading
    } = usePrNumProductsQuery(pageNumber, pageSize, search);

    const LoadPrNumProducts = (page: number)=> {
        setPageNumber(page);
    };

    const searchPrNumProducts = (search?: string) => {
        setSearch(search);
        setPageNumber(1);
    };

    const cancelSearchPrNumProducts = () => {
        setSearch(undefined);
        setPageNumber(1);
    }

//    useEffect( () => console.log("pr products mount"), [] );

    if (isLoading) return <Loader/>;

    return (
        <Fragment>
            <Row className="pageheader">
                <div className="pageheader__left col-sm-6 vertical-align">
                    <h3>PR čísla produktů</h3>
                </div>
                <div className="pageheader__right col-sm-6">
                    <PrProductsSearchBox search={search} cancelSearch={cancelSearch} handleSearch={searchPrNumProducts} handleCancelSearch={cancelSearchPrNumProducts} />
                </div>
            </Row>
            <div>
                <Table>
                    <thead>
                        <tr>
                            <th>PR produktu</th>
                            <th>PR nadřazený produkt</th>
                            <th>Aktualizace</th>
                            <th>Název produktu</th>
                        </tr>
                    </thead>
                    <tbody>
                    {prNumProductsList && prNumProductsList?.prNumProducts && prNumProductsList.prNumProducts.map((prNumProduct, index) => 
                    (
                        <tr key={prNumProduct.prNumProductId}>
                            <td>{prNumProduct.pkz}</td>
                            <td>{prNumProduct.ueberPkz}</td>
                            <td>{moment(prNumProduct.updDate).format("DD.MM.YYYY")}</td>
                            <td>{prNumProduct.text}</td>
                        </tr>
                    ))}
                    </tbody>
                </Table>
            </div>  
            {prNumProductsList && 
                <footer className="footer">
                    <div className="footer-left">
                    </div>
                    <div className="footer-right">
                    <Pages
                        totalPages={prNumProductsList.totalPages}
                        currentPage={prNumProductsList.currentPage}
                        onClickEvent={LoadPrNumProducts}
                    />
                    </div>
                </footer>
            }
        </Fragment>

    );
};

export default observer(PrNumbersProductsTab);