import React from 'react';
import {IAcousticsProtocol, IProtocolArea, IProtocolCodeTableValues, IProtocolPartRule} from "../../../../app/models/protocol";
import TextInput from "../../../../app/common/form/TextInput";
import {Field, useFormState} from "react-final-form";
import {Col, Container, Form, Row} from "react-bootstrap";
import CheckboxInput from "../../../../app/common/form/CheckboxInput";
import SelectInput from "../../../../app/common/form/SelectInput";

interface IProps {
    measuredCarArea: IProtocolArea;
    protocolCodeTableValues: IProtocolCodeTableValues[];
    isEdit: boolean;
}

const CheckBoxField = (partRule: IProtocolPartRule, index: number, isDisabled: boolean) => <>
    <Field
        name={`measuredCarArea.partRules.${index}.isOK`}
        checked={partRule.isOK !== undefined && !!partRule.isOK}
        component={CheckboxInput}
        type={"checkbox"}
        options={[
            { key: 1, value: "ano" },
            { key: 0, value: " ne " },
          ]}
        format={(v) => (v ? 1 : 0)}
        parse={(v) => (v ? true : false)}
        disabled={isDisabled}
    />
</>

const TextInputField = (index: number, isDisabled: boolean) => <>
    <Field
        name={`measuredCarArea.partRules.${index}.measuredValue`}
        component={TextInput}
        parse={(value: string) => value?.toUpperCase()}
        disabled={isDisabled}
    />
</>
const SelectInputField = (index: number, protocolCodeTableValues: IProtocolCodeTableValues[], protocolCodeTableId: number, isDisabled: boolean) => <>
    <Field
        name={`measuredCarArea.partRules.${index}.selectedValue`}
        component={SelectInput}
        options={protocolCodeTableValues
            .filter(x => x.protocolCodeTableId === protocolCodeTableId)
            .map(value => ({key: value.id, value: value.codeTableValue}))}
        isEmptyOption={true}
        disabled={isDisabled}
    />
</>

const MeasuredCarArea: React.FC<IProps> = (
    {
        measuredCarArea,
        protocolCodeTableValues,
        isEdit
    }
) => {
    const formState = useFormState<IAcousticsProtocol>();
    const notPerform = formState.values.measuredCarArea.notPerform || false;

    const disabledStyle = notPerform ? ' protocol-area--disabled' : '';
    const isDisabled = !isEdit || notPerform

    return (
        <div className={'protocol-area'}>
            <Row noGutters>
                <Col sm={10}>
                    <h4>{measuredCarArea.name}</h4>
                </Col>
                <Col>
                    <Field
                        name={`measuredCarArea.notPerform`}
                        title="Neprovádět"
                        options={[
                            {key: 1, value: "ano"},
                            {key: 0, value: " ne "},
                        ]}
                        format={(v) => (v ? 1 : 0)}
                        parse={(v) => (!!v)}
                        type={"checkbox"}
                        // initialValue={executedTestArea.notPerform || false}
                        component={CheckboxInput}
                        disabled={!isEdit}
                        labelWidth={8}
                        valueWidth={4}
                    />
                </Col>
            </Row>
            <Container className={`mt-2 ${disabledStyle}`}>
                {measuredCarArea.partRules?.map((value, index) => {
                    let field = TextInputField(index, isDisabled);
                    if (value.functionCode.toUpperCase() === 'Check'.toUpperCase()) {
                        field = CheckBoxField(value, index, isDisabled);
                    } else if (value.functionCode.toUpperCase() === 'SelectValue'.toUpperCase()) {
                        field = SelectInputField(index, protocolCodeTableValues, value.protocolCodeTableId, isDisabled);
                    }
                    return (
                        <Row key={index} noGutters className='border-bottom mb-2'>
                            <Col sm={3}>
                                <Form.Label>
                                    {value.name}
                                </Form.Label>
                            </Col>
                            <Col sm={6}>
                                {field}
                            </Col>
                        </Row>
                    )
                })}
            </Container>
        </div>
    );
};

export default MeasuredCarArea;
