import React, { useContext } from "react";
import { Row, Col, Form, Container, Button } from "react-bootstrap";
import { Field, Form as FinalForm } from "react-final-form";
import { IEnums, IPlace } from "../../../app/models/enum";
import { observer } from "mobx-react-lite";
import { RootStoreContext } from "../../../app/stores/rootStore";
import SelectInput from "../../../app/common/form/SelectInput";
import TextInput from "../../../app/common/form/TextInput";
import NullableBooleanSwitchInput from "../../../app/common/form/NullableBooleanSwitchInput";
import { ICopsCarCondition, IPlaceDTO, ITestDestination } from "../../../app/models/copsIntegration";

interface IProps { 
    copsCarCondition: ICopsCarCondition,
    enums: IEnums | undefined;
    places: IPlaceDTO[] | undefined;
    testDestinations: ITestDestination[] | undefined;
    handleCopsCarConditionForm: (copsCarConditionForm: ICopsCarCondition) => void,
}

const CopsCarConditionForm: React.FC<IProps> = ({copsCarCondition, enums, places, testDestinations, handleCopsCarConditionForm}) => {
  const rootStore = useContext(RootStoreContext);
  const { closeModal } = rootStore.modalStore;

  const handleFinalFormSubmit = (form: ICopsCarCondition) => {
    if(form.placeId){
        var place = places?.find((x) => x.placeId === form.placeId)
        if(place){ form.placeFullCode = place.fullCode; }
    }
    if(form.carModelId){
        var model = enums?.carModels?.find((x) => x.id === form.carModelId)
        if(model) {
            form.carModelCode = model.code;
            form.carModelName = model.name;
            form.carVdsCode = model.modelVdsCode;
        }
    }
    if(form.carBodyTypeId){
        var bodyType = enums?.carBodyTypes?.find((x) => x.id === form.carBodyTypeId)
        if(bodyType) {
            form.carBodyTypeCode = bodyType.code;
            form.carBodyTypeName = bodyType.name;
            form.carVdsCode = bodyType.modelVdsCode;
        }
    }
    if(form.testDestinationId){
        var testDestination = testDestinations?.find((x) => x.testDestinationId === form.testDestinationId)
        if(testDestination) {
            form.testDestinationCode = testDestination.code;
            form.testDestinationName = testDestination.name;
            form.testDestinationCopsCode = testDestination.copsCode;
        }
    }
    form.isModified = true;
    handleCopsCarConditionForm(form);
  };


  return (
    <div>
        <FinalForm
        onSubmit={handleFinalFormSubmit}
        initialValues={copsCarCondition}
        render={({ handleSubmit, values, form }) => (
            <Form onSubmit={handleSubmit}>
            <Container>
            <Row>
                <Col sm={10}>
                    <Field
                        name="placeId"
                        title="Závod"
                        options={places?.slice()
                            .map((place) => ({
                                key: place.placeId,
                                value: place.fullCode
                            }))}
                        component={SelectInput}
                        isEmptyOption={true}
                        initialValue={copsCarCondition.placeId}
                        parse={(value) => (value ? parseInt(value) : undefined)}
                    />  

                    <Field
                        name="carModelId"
                        title="Model"
                        initialValue={copsCarCondition.carModelId}
                        options={enums?.carModels
                        .slice()
                        .filter((a) => a.isActive || a.id === values.carModelId)
                        .sort((a, b) => (a.name < b.name ? -1 : 1))
                        .map((model) => ({
                            key: model.id,
                            value: model.code + " " + model.name,
                            isDisabled: !model.isActive,
                        }))}
                        component={SelectInput}
                        isEmptyOption={true}
                        parse={(value) => (value ? parseInt(value) : undefined)}
                    />
                    <Field
                        name="carBodyTypeId"
                        title="Karoserie"
                        options={enums?.carBodyTypes
                        .slice()
                        .filter(
                            (bodyType) =>
                            bodyType.carModelId === values.carModelId &&
                            (bodyType.isActive || bodyType.id === values.carBodyTypeId)
                        )
                        .sort((a, b) => (a.name < b.name ? -1 : 1))
                        .map((model) => ({
                            key: model.id,
                            value: model.code + " " + model.name,
                            isDisabled: !model.isActive,
                        }))}
                        component={SelectInput}
                        isEmptyOption={true}
                        parse={(value) => (value ? parseInt(value) : undefined)}
                    />

                    <Field
                        name="testDestinationId"
                        title="Série"
                        options={testDestinations?.slice()
                            .map((serie) => ({
                                key: serie.testDestinationId,
                                value: serie.fullName
                            }))}
                        component={SelectInput}
                        isEmptyOption={true}
                        initialValue={copsCarCondition.testDestinationId}
                        parse={(value) => (value ? parseInt(value) : undefined)}
                    />
            
                    <Field<string>
                        name="customerCode"
                        title="Odběratel"
                        initialValue={copsCarCondition.customerCode}
                        component={TextInput}
                    />


                  
                </Col>
            </Row>
            </Container>
            <hr />
            <div className="flex-row flex-center">
                <Button type="submit" className="btn-modal">
                    Potvrdit
                </Button>
                <Button
                    type="button"
                    onClick={closeModal}
                    className="btn-modal"
                    variant="outline-secondary"
                >
                Zrušit
                </Button>
            </div>
            </Form>
      )}
    />
  </div>
    
 
  );
};

export default observer(CopsCarConditionForm);
