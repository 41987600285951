import "./ConformityArea.scss"
import React from "react";
import {Field} from "react-final-form";
import {Col, Container, Row} from "react-bootstrap";
import TextInput from "../../../../app/common/form/TextInput";
import formatString from "format-string-by-pattern";

interface IProps {
    isEdit: boolean;
}

const maskDoc = { name: "doc-1", parse: 'XX/X000/00' };

const validate = (value:string) => {
    let errorMessage = '';
    if (!value || value === null || value === '') {
        return errorMessage;
    }
    errorMessage = 'Kontrolní list č. musí být ve formátu ' + maskDoc.parse;
    if (value.length < 10) {
        return errorMessage;
    }
    //TODO - nahradit tuhle kontrolu regex vyrazem na format XX/X000/00
    let result = [];
    const doc = Array.from(value)
    if (!doc[0].match(/[A-Z]/)) {
        result.push(false);
    }
    if (!doc[1].match(/[A-Z]/)) {
        result.push(false);
    }
    if (doc[2] !== '/') {
        result.push(false);
    }
    if (!doc[3].match(/[A-Z]/)) {
        result.push(false);
    }
    if (!doc[4].match(/[0-9]/)) {
        result.push(false);
    }
    if (!doc[5].match(/[0-9]/)) {
        result.push(false);
    }
    if (!doc[6].match(/[0-9]/)) {
        result.push(false);
    }
    if (doc[7] !== '/') {
        result.push(false);
    }
    if (!doc[8].match(/[0-9]/)) {
        result.push(false);
    }
    if (!doc[9].match(/[0-9]/)) {
        result.push(false);
    }
    if (result.length === 0) {
      errorMessage = '';
    }
    return errorMessage;
};

const ConformityArea: React.FC<IProps> = ({isEdit,}) => {
    return (
        <div
            className='conformity-area'>
            <Container fluid style={{paddingLeft: 0, paddingRight: 0,}}>
                <Row noGutters>
                    <Col>
                        <Field<string>
                            key='CheckDocumentNo'
                            name={`additionalInfoArea.additionalInfoValue.checkDocumentNo`}
                            title="Kontrolní list č."
                            component={TextInput}
                            disabled={!isEdit}
                            maxLength={10}
                            parse={formatString(maskDoc.parse)}
                            validate={validate}
                            titleWidth={4}
                            fieldWidth={6}
                            //placeholder={maskDoc.parse}
                        />
                    </Col>
                    <Col>
                        <Field<string>
                            key='GearType'
                            name={`additionalInfoArea.additionalInfoValue.gearType`}
                            title="Typ převodovky"
                            component={TextInput}
                            disabled={!isEdit}
                            maxLength={3}
                            titleWidth={4}
                            fieldWidth={6}
                        />
                    </Col>
                </Row>
                <Row noGutters>
                    <Col sm={6}>
                        <Field<string>
                            key='sequenceNumber'
                            name={`additionalInfoArea.additionalInfoValue.engineNumber`}
                            title="Číslo motoru"
                            component={TextInput}
                            disabled={!isEdit}
                            maxLength={40}
                            titleWidth={4}
                            fieldWidth={6}
                        />
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default ConformityArea;
