import { createContext } from "react";
import EnumStore from "./enumStore";
import TestDefinitionStore from "./testDefinitionStore";
import ModalStore from "./modalStore";
import CarStore from "./carStore";
import PageScheduleStore from "./pageScheduleStore";
import PageGroupScheduleStore from "./pageGroupScheduleStore";
import PageEmissionCarsStore from "./pageEmissionCarsStore";
import CarTestStore from "./carTestStore";
import PagePlanCarStore from "./pagePlanCarStore";
import PageCarListStore from "./pageCarListStore";
import UserStore from "./userStore";
import ExpertGroupStore from "./expertGroupStore";
import SettingsStore from "./settingsStore";
import AuthStore from "./authStore";
import BlockingEventStore from "./blockingEventStore";
import UserDefinedFilterStore from "./userDefinedFilterStore";
import CarSearchStore from "./carSearchStore";
import ProtocolStore from "./protocolStore";
import CarAnalysisStore from "./carAnalysisStore";
import PlanningConditionsStore from "./planningConditionsStore";
import { action, observable } from "mobx";
import {
  HubConnection,
  HubConnectionBuilder,
  LogLevel,
} from "@microsoft/signalr";
import { toast } from "react-toastify";
import CopsIntegrationStore from "./copsIntegrationStore";

export class RootStore {
  blockingEventStore: BlockingEventStore;
  enumStore: EnumStore;
  testDefinitionStore: TestDefinitionStore;
  modalStore: ModalStore;
  carStore: CarStore;
  pageScheduleStore: PageScheduleStore;
  pageGroupScheduleStore: PageGroupScheduleStore;
  carTestStore: CarTestStore;
  pagePlanCarStore: PagePlanCarStore;
  pageCarListStore: PageCarListStore;
  pageEmissionCarsStore: PageEmissionCarsStore;
  expertGroupStore: ExpertGroupStore;
  userStore: UserStore;
  userDefinedFilterStore: UserDefinedFilterStore;
  settingsStore: SettingsStore;
  authStore: AuthStore;
  copsIntegrationStore: CopsIntegrationStore;
  carSearchStore: CarSearchStore;
  protocolStore: ProtocolStore;
  carAnalysisStore: CarAnalysisStore;
  planningConditionsStore: PlanningConditionsStore;

  constructor() {
    this.authStore = new AuthStore(this);
    this.enumStore = new EnumStore(this);
    this.modalStore = new ModalStore(this);
    this.carStore = new CarStore(this);
    this.pageScheduleStore = new PageScheduleStore(this);
    this.carTestStore = new CarTestStore(this);
    this.pagePlanCarStore = new PagePlanCarStore(this);
    this.pageCarListStore = new PageCarListStore(this);
    this.testDefinitionStore = new TestDefinitionStore(this);
    this.expertGroupStore = new ExpertGroupStore(this);
    this.userStore = new UserStore(this);
    this.userDefinedFilterStore = new UserDefinedFilterStore(this);
    this.settingsStore = new SettingsStore(this);
    this.pageGroupScheduleStore = new PageGroupScheduleStore(this);
    this.copsIntegrationStore = new CopsIntegrationStore(this);
    this.blockingEventStore = new BlockingEventStore(this);
    this.carSearchStore = new CarSearchStore(this);
    this.pageEmissionCarsStore = new PageEmissionCarsStore(this);
    this.protocolStore = new ProtocolStore(this);
    this.carAnalysisStore = new CarAnalysisStore(this);
    this.planningConditionsStore = new PlanningConditionsStore(this);
  }
  // // TODO SignalR 
  // @observable.ref hubConnection: HubConnection | null = null;
  // @observable helloCount: number = 0;

  // @action createHubConnection = () => {
  //   this.hubConnection = new HubConnectionBuilder()
  //     .withUrl("http://localhost:5000/notifications", {
  //       accessTokenFactory: () => this.authStore.token!,
  //     })
  //     .configureLogging(LogLevel.Information)
  //     .build();

  //   this.hubConnection
  //     .start()
  //     .then(() => console.log(this.hubConnection!.state))
  //     .then(() => {
  //       console.log("Attempting to join group");
  //       this.hubConnection!.invoke("AddToGroup", this.authStore.user?.userRoleId);
  //     })
  //     .catch((error) => console.log("Chyba navazani signalR spojeni: ", error));

  //   this.hubConnection.on("ReceiveHello", (comment) => {
  //     console.log(comment);
  //     this.helloCount++;
  //     toast.info(`Nova notifikace: ${comment}`);
  //   });

  //   this.hubConnection.on("Send", (comment) => {
  //     console.log(comment);
  //     toast.info(`Nova notifikace: ${comment}`);
  //   });
  // };
 
  // @action stopHubConnection = () => {
  //   this.hubConnection
  //     ?.invoke("RemoveFromGroup", 1)
  //     .then(() => {
  //       this.hubConnection!.stop();
  //     })
  //     .catch((error) => console.log("Chyba zruseni signalR spojeni: ", error));
  // };

  // @action sayHello = async () => {
  //   try {
  //     await this.hubConnection!.invoke(
  //       "SendHello",
  //       "Zdravi te jiny klient pomoci SignalR :)!"
  //     );
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  // @action refreshScheduleStore = () => {
  //   this.pageScheduleStore.loadSchedule(
  //     this.pageScheduleStore.dateFrom.toDate(),
  //     this.pageScheduleStore.dateTo.toDate()
  //   );
  // };
}

export const RootStoreContext = createContext(new RootStore());
