import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { Card, Table, Col, Row } from "react-bootstrap";
import { sortByStatusAndCode } from "../../../app/common/utils/sort";
import {
  ICopsProjectTypeForm,
  ICopsSeriesTypeForm,
  ICopsUsageForm,
} from "../../../app/models/copsIntegration";
import { IEnums } from "../../../app/models/enum";
import { RootStoreContext } from "../../../app/stores/rootStore";
import FilterActiveButtonGroup from "../../ButtonGroups/FilterActiveButtonGroup";
import EditableCopsItemRow from "../forms/EditableCopsItemRow";

interface IProps {
  enums: IEnums | undefined;
}

const CopsMappingTab: React.FC<IProps> = ({ enums }) => {
  const rootStore = useContext(RootStoreContext);
  const {
    loadCopsIntegrations,
    updateCopsProjectType,
    updateCopsSeriesType,
    updateCopsUsage,
    usages,
    projectTypes,
    seriesTypes,
  } = rootStore.copsIntegrationStore;

  useEffect(() => {
    loadCopsIntegrations();
  }, [loadCopsIntegrations]);

  const [editItemId, setEditItemId] = useState<string | undefined>(undefined);
  const [allSeriesTypesEnabled, setAllSeriesTypesEnabled] = useState<boolean>(false);
  const [allUsagesEnabled, setAllUsagesEnabled] = useState<boolean>(false);

  const handleEditCopsUsageItem = (form: ICopsUsageForm) => {
    updateCopsUsage(form).then(() => {
      setEditItemId(undefined);
    });
  };

  const handleEditCopsProjectTypeItem = (form: ICopsProjectTypeForm) => {
    updateCopsProjectType(form).then(() => {
      setEditItemId(undefined);
    });
  };

  const handleEditCopsSeriesTypeItem = (form: ICopsSeriesTypeForm) => {
    updateCopsSeriesType(form).then(() => {
      setEditItemId(undefined);
    });
  };

  return (
    <div>
      <Row>
        <Col lg={6}>
          <Card>
            <Card.Header className="flex-row flex-space-between">
              <div>Mapování Series Types - Typ vozu</div>
              <div>
                <FilterActiveButtonGroup
                  allEnabled={allSeriesTypesEnabled}
                  setAllEnabled={setAllSeriesTypesEnabled}
                /> 
              </div>
            </Card.Header>
            <Card.Body>
              <Table size="sm">
                <thead>
                  <tr>
                    <th>Kód</th>
                    <th>Series type</th>
                    <th>Typ vozu</th>
                    <th>Aktivní</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {seriesTypes
                    ?.slice()
                    .filter((item) => allSeriesTypesEnabled || item.isActive)
                    .sort(sortByStatusAndCode())
                    .map((item) => (
                      <EditableCopsItemRow
                        key={item.copsSeriesTypeId}
                        copsId={item.copsSeriesTypeId}
                        copsCode={item.code}
                        copsName={item.name}
                        copsIsActive={item.isActive}
                        copsTableName="copsSeriesType"
                        editedItemId={editItemId}
                        setEditedItemId={setEditItemId}
                        selectName="testDestination"
                        selectOptions={enums?.testDestinations || []}
                        selectedOptionId={item.testDestinationId}
                        selectedOptionName={
                          enums?.testDestinations.find(
                            (x) => x.key === item.testDestinationId
                          )?.value
                        }
                        handleEditItem={handleEditCopsSeriesTypeItem}
                      />
                    ))}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
          <Card>
            <Card.Header>Mapování Project type - Podurčení zkoušky</Card.Header>
            <Card.Body>
              <Table size="sm">
                <thead>
                  <tr>
                    <th>Kód</th>
                    <th>Project type</th>
                    <th>Podurčení zkoušky</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {projectTypes
                    ?.slice()
                    .sort((a, b) => (a.code < b.code ? -1 : 1))
                    .map((item) => (
                      <EditableCopsItemRow
                        key={item.copsProjectTypeId}
                        copsId={item.copsProjectTypeId}
                        copsCode={item.code}
                        copsName={item.name}
                        copsIsActive={undefined}
                        copsTableName="copsProjectType"
                        editedItemId={editItemId}
                        setEditedItemId={setEditItemId}
                        selectName="testPredestination"
                        selectOptions={enums?.testPredestinations || []}
                        selectedOptionId={item.testPredestinationId}
                        selectedOptionName={
                          enums?.testPredestinations.find(
                            (x) => x.key === item.testPredestinationId
                          )?.value
                        }
                        handleEditItem={handleEditCopsProjectTypeItem}
                      />
                    ))}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>

        <Col lg={6}>
          <Card>
            <Card.Header className="flex-row flex-space-between">
              <div>Mapování Usage - Typ zkoušky</div>
              <div>
                <FilterActiveButtonGroup
                  allEnabled={allUsagesEnabled}
                  setAllEnabled={setAllUsagesEnabled}
                /> 
              </div>
            </Card.Header>
            <Card.Body>
              <Table size="sm">
                <thead>
                  <tr>
                    <th>Kód</th>
                    <th>Usage</th>
                    <th>Typ zkoušky</th>
                    <th>Aktivní</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {usages
                    ?.slice()
                    .filter((item) => allUsagesEnabled || item.isActive)
                    .sort(sortByStatusAndCode())
                    .map((item) => (
                      <EditableCopsItemRow
                        key={item.copsUsageId}
                        copsId={item.copsUsageId}
                        copsCode={item.code}
                        copsName={item.name}
                        copsIsActive={item.isActive}
                        copsTableName="copsUsage"
                        editedItemId={editItemId}
                        setEditedItemId={setEditItemId}
                        selectName="testType"
                        selectOptions={
                          enums?.testTypes
                            .slice()
                            .sort((a, b) => (a.value < b.value ? -1 : 1)) || []
                        }
                        selectedOptionId={item.testTypeId}
                        selectedOptionName={
                          enums?.testTypes.find(
                            (x) => x.key === item.testTypeId
                          )?.value
                        }
                        handleEditItem={handleEditCopsUsageItem}
                      />
                    ))}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default observer(CopsMappingTab);
