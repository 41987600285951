import React, { Fragment } from "react";
import { Table } from "react-bootstrap";
import { Field } from "react-final-form";
import { FormApi } from "final-form";
import { IProtocolArea, IDrivingCharacteristicsProtocol  } from "../../../../app/models/protocol";
import CheckboxGroupInput from "../../../../app/common/form/CheckboxGroupInput";
import CheckboxInput from "../../../../app/common/form/CheckboxInput";

interface IProps {
    isEdit: boolean;
    testArea: IProtocolArea;
    isDisabled: boolean;
    form: FormApi<IDrivingCharacteristicsProtocol>;
}

const ExecutedTestArea: React.FC<IProps> = ({ testArea, isEdit, isDisabled, form}) => {

    return (
      <Fragment>
        <Table className="protocol-table">
        <thead>
          <tr>
            <th colSpan={10} className={`headingrow`}>
              <h2>{testArea?.name}</h2>
            </th>
            <th colSpan={2} className={`headingrow`}>
              <Field
                name={`executedTestArea.notPerform`}
                title="Neprovedeno"
                options={[
                    { key: 1, value: "ano" },
                    { key: 0, value: " ne " },
                ]}
                format={(v) => (v ? 1 : 0)}
                parse={(v) => (v ? true : false)}
                type={"checkbox"}
                initialValue={testArea?.notPerform || false}
                component={CheckboxInput}
                disabled={!isEdit}
                labelWidth={8}
                valueWidth={4}
              />
            </th>
          </tr>
        </thead>
      </Table>

      <Table className={`protocol-table ${ isDisabled ? "no-print" : ""}`}>
        <thead>
          <tr className={`${ isDisabled ? "disabled" : ""}`}>
              <th className="labelrow" colSpan={6}>Název</th>
              <th className="labelrow" colSpan={2} style={{textAlign: "center"}}>Vyhovuje</th>
              <th className="labelrow" colSpan={2} style={{textAlign: "center"}}>Nevyhovuje</th>
              <th className="labelrow" colSpan={2} style={{textAlign: "center"}}>Neprovedeno</th>
            </tr>
          </thead>
        <tbody>
              {testArea.partRules?.map((partRule, ruleIndex) => (
                <tr key={partRule.protocolRuleId} className={`${ isDisabled ? "disabled" : ""}`}>
                  <td colSpan={6}>{partRule.name}</td>
                  <td colSpan={2} style={{textAlign: "center"}}>
                    <Field
                      name={`executedTestArea.partRules[${ruleIndex}].isOK`}
                      checked={partRule.isOK != undefined && !!partRule.isOK}
                      type="checkbox"
                      options={[
                        { key: 1, value: "ano" },
                        { key: 0, value: " ne " },
                      ]}
                      format={(v) => (v ? 1 : 0)}
                      parse={(v) => (v ? true : false)}
                      inputOnChange={(e: any) => {
                        form.mutators.handleChangeExecutedTest(e);
                      }}
                      component={CheckboxGroupInput}
                      disabled={!isEdit || isDisabled}
                    />
                  </td>
                  <td colSpan={2} style={{textAlign: "center"}}>
                    <Field
                      name={`executedTestArea.partRules[${ruleIndex}].isDefect`}
                      checked={partRule.isDefect != undefined && !!partRule.isDefect}
                      type="checkbox"
                      options={[
                        { key: 1, value: "ano" },
                        { key: 0, value: " ne " },
                      ]}
                      format={(v) => (v ? 1 : 0)}
                      parse={(v) => (v ? true : false)}
                      inputOnChange={(e: any) => {
                        form.mutators.handleChangeExecutedTest(e);
                      }}
                      component={CheckboxGroupInput}
                      disabled={!isEdit || isDisabled}
                    />
                  </td>
                  <td colSpan={2} style={{textAlign: "center"}}>
                    <Field
                      name={`executedTestArea.partRules[${ruleIndex}].notPerform`}
                      checked={partRule.notPerform}
                      type="checkbox"
                      options={[
                        { key: 1, value: "ano" },
                        { key: 0, value: " ne " },
                      ]}
                      format={(v) => (v ? 1 : 0)}
                      parse={(v) => (v ? true : false)}
                      inputOnChange={(e: any) => {
                        form.mutators.handleChangeExecutedTest(e);
                      }}
                      component={CheckboxGroupInput}
                      disabled={!isEdit || isDisabled}
                    />
                  </td>
                </tr>
                ))
              }
          </tbody>
        </Table>
      </Fragment>
  );
};

export default ExecutedTestArea;
