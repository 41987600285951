import React, { Fragment } from "react";
import { Table } from "react-bootstrap";
import { Field } from "react-final-form";
import CheckboxGroupInput from "../../../../app/common/form/CheckboxGroupInput";
import { FormApi } from "final-form";
import { IProtocolArea, IEecProtocol } from "../../../../app/models/protocol";
import TextInput from "../../../../app/common/form/TextInput"

interface IProps {
  conductedTestsArea: IProtocolArea;
  isEdit: boolean;
  form: FormApi<IEecProtocol>;
}

const ConductedTestsArea: React.FC<IProps> = ({
  conductedTestsArea,
  isEdit,
  form
}) => {

    return (
      <Fragment>
        <Table className="protocol-table">
        <thead>
          <tr>
            <th colSpan={3} className="headingrow">
              <h2>{conductedTestsArea?.name}</h2>
            </th>
            <th className="headingrow" style={{textAlign: "right"}}>
              <h4>{conductedTestsArea?.additionalInfo}</h4>
            </th>
          </tr>
        </thead>
      </Table>

      <Table className={`protocol-table`}>
        <thead>
          <tr>
              <th className="labelrow" colSpan={4}>Název</th>
              <th className="labelrow"></th>
              <th className="labelrow"></th>
              <th className="labelrow" style={{textAlign: "center"}}>Vyhovuje</th>
              <th className="labelrow" style={{textAlign: "center"}}>Nevyhovuje</th>
              <th className="labelrow" style={{textAlign: "center"}}></th>
            </tr>
        </thead>
        <tbody>
              {conductedTestsArea.partRules?.map((partRule, ruleIndex) => (
                <tr key={partRule.protocolRuleId}>
                  <td colSpan={4}><b>{partRule.name} :</b></td>
                  <td style={{textAlign: "center"}}>{partRule.showFunctionCode && <span>{partRule.functionCode}</span>}{" "} <span> {partRule.rule} {partRule.unit}</span></td>
                  <td>
                    {partRule.protocolRuleFunctionId && (
                      <Field<string>
                        name={`conductedTests.partRules[${ruleIndex}].measuredValue`}
                        component={TextInput}
                        initialValue={partRule.measuredValue}
                        rows={1}
                        size="sm"
                        inputOnChange={(e: any) => {
                          form.mutators.checkMeasuredValue(e);
                        }}
                        disabled={!isEdit}
                      />
                    )}
                  </td>
                  <td style={{textAlign: "center"}}>
                    <Field
                      name={`conductedTests.partRules[${ruleIndex}].isOK`}
                      checked={partRule.isOK != undefined && !!partRule.isOK}
                      type="checkbox"
                      options={[
                        { key: 1, value: "ano" },
                        { key: 0, value: " ne " },
                      ]}
                      format={(v) => (v ? 1 : 0)}
                      parse={(v) => (v ? true : false)}
                      inputOnChange={(e: any) => {
                      form.mutators.handleChangeExecutedTest(e);
                      }}
                        component={CheckboxGroupInput}
                        disabled={!isEdit}
                    />
                  </td>
                  <td style={{textAlign: "center"}}>
                    <Field
                      name={`conductedTests.partRules[${ruleIndex}].isDefect`}
                      checked={partRule.isDefect != undefined && !!partRule.isDefect}
                      type="checkbox"
                      options={[
                        { key: 1, value: "ano" },
                        { key: 0, value: " ne " },
                      ]}
                      format={(v) => (v ? 1 : 0)}
                      parse={(v) => (v ? true : false)}
                      inputOnChange={(e: any) => {
                        form.mutators.handleChangeExecutedTest(e);
                      }}
                      component={CheckboxGroupInput}
                      disabled={!isEdit}
                    />
                  </td>
                  <td style={{paddingLeft: "50px"}}>{partRule.additionalInfo}</td>
                </tr>
                ))
              }
        </tbody>
      </Table>
    </Fragment>
  );
};

export default ConductedTestsArea;
