import React, { Fragment } from "react";
import { Button } from "react-bootstrap";
import { faPen, faPrint, faSave } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { observer } from "mobx-react-lite";

interface IProps {
  edit: boolean;
  setEdit: (editable: boolean) => void;
  form: any;
  handleReset: (form: any) => void;
  isSaving: boolean;
  printButton?: boolean;
}
const EditSaveResetButtons: React.FC<IProps> = ({
  edit,
  setEdit,
  form,
  handleReset,
  isSaving,
  printButton,
}) => {
  return (
    <Fragment>
      {!edit && (
        <Fragment>
          <Button
            variant="outline-primary"
            style={{
              marginRight: "1rem",
            }}
            type="button"
            onClick={() => setEdit(!edit)}
          >
            <FontAwesomeIcon icon={faPen} /> Upravit
          </Button>
          {printButton && (
            <Button
              variant="outline-info"
              type="button"
              onClick={() => window.print()}
              disabled={isSaving}
            >
              <FontAwesomeIcon icon={faPrint} title="Tisk" /> Tisk
            </Button>
          )}
        </Fragment>
      )}
      {edit && (
        <Fragment>
          <Button
            variant="outline-primary"
            style={{
              marginRight: "1rem",
            }}
            type="submit"
            disabled={isSaving}
          >
            <FontAwesomeIcon icon={faSave} /> Uložit
          </Button>
          <Button
            variant="outline-secondary"
            type="reset"
            onClick={() => handleReset(form)}
            disabled={isSaving}
          >
            Zahodit změny
          </Button>
        </Fragment>
      )}
    </Fragment>
  );
};

export default observer(EditSaveResetButtons);
