import { RootStore } from "./rootStore";
import { observable, action, runInAction } from "mobx";
import agent from "../api/agent";
import { ISetting, ISettingsForm } from "../models/settings";
import { toast } from "react-toastify";

export default class SettingsStore {
  rootStore: RootStore;
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @observable settings: ISetting[] = [];

  @action loadSettings = async () => {
    if (this.settings.length < 1) {
      try {
        const settingsList = await agent.Settings.list();

        runInAction(() => {
          this.settings = settingsList.settings;
        });
      } catch (error) {
        console.log(error);
      }
    }
  };

  @action updateSettings = async (form: ISettingsForm): Promise<void> => {
    try {
      await agent.Settings.update(form);

      runInAction("update Settings ", () => {
        this.settings = form.settings;
      });
      toast.success("Změny byly úspěšně uloženy!");
    } catch (error) {
      runInAction("update Settings error", () => {
        console.log(error);
      });
      toast.error("Při ukládání nastala chyba!");
      throw error;
    }
  };
}
