import React, { Fragment } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { FieldRenderProps } from "react-final-form";
import "./CheckboxInput.scss";

type Props = FieldRenderProps<boolean, any>;

const CheckboxInput: React.FC<Props> = ({
  input,
  title,
  meta,
  titleAfter,
  inputOnChange,
  disabled,
  labelWidth,
  valueWidth,
  hidden
}: any) => {
  const errorMessage = meta.error && (
    <span className={"text-danger"} style={{ fontSize: "80%" }}>
      {" "}
      {meta.error}
    </span>
  );

  const inputField = (
    <Form.Check
      {...input}
      onChange={(e: any) => {
        input.onChange(e);
        inputOnChange && inputOnChange(e);
      }}
      name={input.name}
      type={input.type || "checkbox"}
      id={`${input.name}`}
      className="checkbox"
      label={titleAfter ? title : ""}
      disabled={disabled}
    />
  );
  return (
    <Fragment>
      {title && (
        <Form.Group controlId={input.name} className="checkbox-group">
          <Row>
            <Col xs={labelWidth || 4} className="u-text-right">
              {title && <Form.Label>{titleAfter ? "" : title}</Form.Label>}
            </Col>
            <Col xs={valueWidth || 8}>
              {inputField}
              {errorMessage}
            </Col>
          </Row>
        </Form.Group>
      )}
      {!title && (
        <Form.Group controlId={input.name} className="checkbox-group" style={hidden && {display: "none" }} >
          {inputField}
          {errorMessage}
        </Form.Group>
      )}
    </Fragment>
  );
};

export default CheckboxInput;
