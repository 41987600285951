import { RootStore } from "./rootStore";
import { observable, action, runInAction } from "mobx";
import agent from "../api/agent";
import { IPlanningConditionsDTO, IPlanningCondition } from "../models/planningCondition";
import { toast } from "react-toastify";

export default class PlanningConditionsStore {


    rootStore: RootStore;
    constructor(rootStore: RootStore) {
      this.rootStore = rootStore;
    }

    @observable isLoading: boolean = false;
  
    @action loadPlanningConditions = async () => {
     
        try {
            this.isLoading = true;
            const conditions = await agent.PlanningConditions.get();
            runInAction(() => {
                this.isLoading = false;
            });
            return conditions;
        } catch (error) {
          console.log(error);
          this.isLoading = false;
        }
      
    };
 
    @action savePlanningConditions = async (planningConditions: IPlanningConditionsDTO) => {
      try {
        this.isLoading = true;
        const conditions = await agent.PlanningConditions.createOrUpdate(planningConditions);
        runInAction(() => {
            this.isLoading = false;
        });
        return conditions;
      } catch (error) {
        console.log(error);
        toast.error("Při ukládání podmínek plánování nastala chyba!");
        this.isLoading = false;
        throw error;
      }
    };

  }