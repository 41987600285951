import "./CarDetailPage.scss";
import React, { useEffect, useState, useContext } from "react";
import { Container, Form, Tabs, Tab, Button } from "react-bootstrap";
import { RouteComponentProps, Prompt, Link } from "react-router-dom";
import { Form as FinalForm, Field, FormSpy } from "react-final-form";
import { RootStoreContext } from "../../app/stores/rootStore";
import AlertMessage from "../alert/AlertMessage";
import { observer } from "mobx-react-lite";
import VinCode from "../vin/VinCode";
import CarTestsTab from "./carDetailTabs/CarTestsTab";
import CarHistoryTab from "./carDetailTabs/CarHistoryTab";
import CarParametersTab from "./carDetailTabs/CarParametersTab";
import CarProtocolsTab from "./carDetailTabs/CarProtocolsTab";
import {
  ICar,
  convertICarToICarForm,
  NewCar,
  IHistory,
  canSetEmissionTest,
} from "../../app/models/car";
import CarDetailForm from "./CarDetailForm";
import {
  ICarTest,
  ICarTestAutoAssign,
  CarTestAutoAssignForm,
  convertICarTestToICarTestCarDetailForm,
  convertICarTestAutoAssignToICarTestCarDetailForm,
  ICarTestManuallyAdded,
  convertICarTestManuallyAddedToICarTestCarDetailForm,
} from "../../app/models/carTest";
import TextAreaInput from "../../app/common/form/TextAreaInput";
import Can, { checkAuthRule } from "../../authorization/Can";
import ConflictsPanel from "../conflictsPanel/ConflictsPanel";
import EditSaveResetButtons from "../ButtonGroups/EditSaveResetButtons";
import TestRoomTab from "./carDetailTabs/TestRoomTab";
import moment from "moment";
import { ICarAnalysis } from "../../app/models/carAnalysis";
import CarAnalysisTab from "./carDetailTabs/CarAnalysisTab";
import Loader from "../loader/Loader";
import { useCarPrNumbersQuery } from "../../app/api/client";
import CarPrNumbersTab from "./carDetailTabs/CarPrNumbersTab";

interface DetailParams {
  id: string;
}

const CarDetailPage: React.FC<RouteComponentProps<DetailParams>> = ({
  match,
  history,
}) => {
  const rootStore = useContext(RootStoreContext);
  const { loadEnums, enums } = rootStore.enumStore;
  const {
    carRegistry,
    carTestsRegistry,
    carHistoryRegistry,
    carAnalysisRegistry,
    loadCar,
    updateCar,
    loadCarsByVinOrKnr,
    getCarByVinOrKnrFromCops,
    //carCops: copsCar,
    carsByVinOrKnr: carsByVin,
    loadingInitial,
    isSaving
  } = rootStore.carStore;
  const { testDefinitionsLoaded, loadTestDefinitions } =
    rootStore.testDefinitionStore;
  const { user } = rootStore.authStore;
  const { loadUsers } = rootStore.userStore;

  const [car, setCar] = useState<ICar | undefined | NewCar>(new NewCar());

  const [carHistory, setCarHistory] = useState<IHistory[]>([]);

  // array with car tests loaded from database
  const [loadedCarTests, setLoadedCarTests] = useState<ICarTest[]>([]);

  const [ carAnalysis, setCarAnalysis ] = useState<ICarAnalysis[]>([]);

  // array with car tests automaticaly added by test definition
  const [autoAssignCarTests, setAutoAssignCarTests] = useState<
    ICarTestAutoAssign[]
  >([]);

  // array with car tests manually created in the application
  const [manuallyCreatedCarTests, setManuallyCreatedCarTests] = useState<
    ICarTestManuallyAdded[]
  >([]);

  // manage tabs selection and set default tab
  const [key, setKey] = useState("parameters");
  const [edit, setEdit] = useState(false);
  const [editVin, setVinEdit] = useState(false);
  const [vinLock, setVinLock] = useState(false);
  const [knrLock, setKnrLock] = useState(false);
  const [changed, setChanged] = useState(false);

  const [showAssignCarTestError, setShowAssignCarTestError] = useState(false);
  const [submitErrors, setSubmitErrors] = useState([]);
  const [showSubmitError, setShowSubmitError] = useState(false);

  const [rownumber, setRownumber] = useState(0);
  const canEditCarTests = checkAuthRule(
    user?.userRoleId,
    "car-detail-page:editCarTests"
  );

    const carId = Number(match.params.id);

  const { data: carPrNumbers } = useCarPrNumbersQuery(carId);

  useEffect(() => {
    
    if (!carId && match.params.id) history.push("/notfound");

    loadEnums();
    loadUsers();
    if (canEditCarTests) loadTestDefinitions();
    if (!carId) {
      setEdit(false);
      setVinEdit(true);
      setCar(new NewCar());
      setLoadedCarTests([]);
      setCarAnalysis([]);
    }

    if (carId) {
      setVinEdit(false);
      loadCar(carId).then(() => {
        setCar(carRegistry.get(carId));
        setLoadedCarTests(carTestsRegistry.get(carId) || []);
        setCarAnalysis(carAnalysisRegistry.get(carId) || []);
        setCarHistory(carHistoryRegistry.get(carId) || []);
      });
    }

    setAutoAssignCarTests([]);
    setManuallyCreatedCarTests([]);
  }, [
    history,
    carHistoryRegistry,
    match.params.id,
    carTestsRegistry,
    carRegistry,
    loadEnums,
    loadUsers,
    loadCar,
    loadTestDefinitions,
    setVinEdit,
    canEditCarTests,
  ]);

  const handleFinalFormSubmit = (form: any) => {
    // return { [FORM_ERROR]: { vin: "Login Failed" } };
    const carForm = convertICarToICarForm(form);
    carForm.emissionFree =
      enums?.carEngines.find((c) => c.id === form.engineId)?.emissionFree ||
      false;
    if (carForm.emissionFree) carForm.emissionStandard = undefined;

    // convert automatically added car tests by test definition to required form
    const assignCarTests = autoAssignCarTests
      ? autoAssignCarTests.map((test, index: number) => {
          test.testStateId =
            form.carTestAutoAsign[index]?.testStateId || test.testStateId;
          const carTestCarDetailForm =
            convertICarTestAutoAssignToICarTestCarDetailForm(test);
          return carTestCarDetailForm;
        })
      : [];

    // convert already saved car tests to required form
    const loadCarTests = loadedCarTests
      ? loadedCarTests.map((test) =>
          convertICarTestToICarTestCarDetailForm(test)
        )
      : [];

    // convert manually added car tests by user to required form
    const rowNumbers = manuallyCreatedCarTests.map((a) => a.rownumber);
    const manualCarTests = form.carTest
      ? form.carTest
          .filter((test: ICarTestManuallyAdded, index: number) =>
            rowNumbers.includes(index)
          )
          .map((test: ICarTestManuallyAdded) =>
            convertICarTestManuallyAddedToICarTestCarDetailForm(test)
          )
      : [];

    // join all types of tests to a single array
    carForm.carTests = [...assignCarTests, ...loadCarTests, ...manualCarTests];
    setSubmitErrors([]);
    setShowSubmitError(false);

    updateCar(carForm)
      .then((response) => {
        setEdit(false);
        setVinEdit(false);
        setManuallyCreatedCarTests([]);
        setAutoAssignCarTests([]);
        setCar(response.car);
        setLoadedCarTests(response.carTests);
        setCarAnalysis(response.carAnalysis);
        setCarHistory(response.carHistories);
        // form.initialize(response.car);
        // pouze pro nove vytvoreny vuz
        if (!match.params.id) history.push(`/car/${response.carId}`);
      })
      .catch((error) => {
        setSubmitErrors(Object.values(error.response.data.errors));
        setShowSubmitError(true);
      });
  };

  // automatically add all tests by test definition
  const loadTestsFromTestDefinitions = (
    testTypeId: number | undefined,
    testDestionationId: number | undefined,
    testPredestinationId: number | undefined
  ) => {
    setShowAssignCarTestError(false);
    const testDestination = enums?.testDestinations.filter(
      (tdest) => tdest.key === testDestionationId
    )[0];
    const carTestDefinitions = testDefinitionsLoaded.filter(
      (td) =>
        td.testDestinationId === testDestination?.group &&
        td.testPredestinationId === testPredestinationId &&
        td.testTypeId === testTypeId
    );
    setAutoAssignCarTests(
      carTestDefinitions.map(
        (definition) => new CarTestAutoAssignForm(definition)
      )
    );
    if (!carTestDefinitions || carTestDefinitions.length < 1)
      setShowAssignCarTestError(true);
    // switch active tab to Zkousky vozu
    else setKey("carTests");
  };

  // add new row with car test
  const handleAddCarTestManually = () => {
    const carTest: ICarTestManuallyAdded = {
      description: "",
      expertGroupId: 1,
      length: 0,
      testStateId: 1,
      rownumber: rownumber,
    };
    setRownumber(rownumber + 1);
    setManuallyCreatedCarTests([...manuallyCreatedCarTests, carTest]);
  };

  // delete manually added car test before it was saved
  const handleDeleteManuallyAddedCarTest = (rownumber: number) => {
    setRownumber(rownumber + 1);
    setManuallyCreatedCarTests(
      manuallyCreatedCarTests.filter((ct) => ct.rownumber !== rownumber)
    );
  };

  // Reset all form values to initial values
  const handleReset = (form: any) => {
    form.reset();
    if (!car?.carId) {
      setCar(new NewCar());
      setVinEdit(true);
      setVinLock(false);
      setKnrLock(false);
    }
    setEdit(false);
    setAutoAssignCarTests([]);
    setManuallyCreatedCarTests([]);
  };

  // NEW CAR FORM - if vin does not exist unlock rest of form fields and lock vin field
  // -- if vin exists redirect to car detail page
  const handleValidateVinAndKnr = (
    vin: string | undefined,
    prodIdentificationNum: string | undefined
  ) => {
    // console.log(vin, prodIdentificationNum);
    setSubmitErrors([]);
    setShowSubmitError(false);
    loadCarsByVinOrKnr(vin, prodIdentificationNum).then((cars) => {
      //  console.log(cars);
      // car already exists in the database
      if (
        cars &&
        cars.length === 1 &&
        !cars[0].isDifferentIdentificationParam
      ) {
        history.push(`/car/${cars[0].carId}`);
        setVinEdit(false);
        setEdit(false);
      }
      // car already exists in the database but under two different ids
      else if (
        cars &&
        ((cars.length === 1 && cars[0].isDifferentIdentificationParam) ||
          cars.length > 1)
      ) {
        alert(
          "Vůz v aplikaci již existuje, ale každý z kódů identifikuje jiný vůz!"
        );
      }
      // car does not exists in the database
      else {
        if (vin && vin.length === 17 && car && !car.vin) setVinLock(true);
        if (
          prodIdentificationNum &&
          prodIdentificationNum.length === 13 &&
          car &&
          !car.prodIdentificationNum
        )
          setKnrLock(true);

        setVinEdit(false);
        setEdit(true);
      }
    });
  };

  const hadleFindCarByVinAndKnrInCops = (
    form: any,
    vin: string | undefined,
    prodIdentificationNum: string | undefined
  ) => {
    setSubmitErrors([]);
    setShowSubmitError(false);
    getCarByVinOrKnrFromCops(vin, prodIdentificationNum).then((copsCar) => {
      setCar(copsCar);
      form.mutators.setModelVdsCodeFromCOPS(copsCar);
      if (vin && vin.length === 17 && car && !car.vin) setVinLock(true);
      if (
        prodIdentificationNum &&
        prodIdentificationNum.length === 13 &&
        car &&
        !car.prodIdentificationNum
      )
        setKnrLock(true);

      setVinEdit(false);
      setEdit(true);
    })
    .catch((error) => {
      setSubmitErrors(Object.values(error.response.data.errors));
      setShowSubmitError(true);
    });
  };

  return (
    <Container style={{ marginTop: "1rem" }}>
      <Prompt
        when={edit && changed}
        message="Na stránce byly provedeny změny, které nejsou uloženy. Opravdu chcete odejít?"
      />
      {loadingInitial && <Loader />}
      {!loadingInitial && car && (
        <div>
          <FinalForm
            initialValues={car}
            mutators={{
              setCarNumber: (args, state, utils) => {
                utils.changeValue(state, "carNumber", () => {
                  const vin = args[0];
                  if (vin && vin.length > 9) return vin.slice(9).toUpperCase();
                  else return undefined;
                });
              },
              carModelReset: (args, state, utils) => {
                utils.changeValue(state, "bodyTypeId", () => undefined);
                utils.changeValue(state, "equipmentId", () => undefined);
                utils.changeValue(state, "engineId", () => undefined);
                utils.changeValue(state, "transmissionId", () => undefined);
              },
              setModelVdsCode: (args, state, utils) => {
                let newVdsCode = state.lastFormState?.values.modelVdsCode;
                const fieldName = args[0].currentTarget.name;
                const fieldValue = args[0].target.value;
                if (fieldName && fieldName === "modelId") {
                  const carModelId = Number(fieldValue);
                  const model = enums?.carModels.find(
                    (x) => x.id === carModelId
                  );
                  newVdsCode = model?.modelVdsCode;
                  utils.changeValue(state, "modelVdsCode", () => newVdsCode);
                }

                if (fieldName && fieldName === "bodyTypeId") {
                  const carBodyTypeId = Number(fieldValue);
                  const carBodyType = enums?.carBodyTypes.find(
                    (c) => c.id === carBodyTypeId
                  );
                  newVdsCode = carBodyType?.modelVdsCode;
                  if (newVdsCode && newVdsCode.length > 0)
                    utils.changeValue(state, "modelVdsCode", () => newVdsCode);
                }
              },
              setModelVdsCodeFromCOPS: (args, state, utils) => {
                const copsCar: ICar | undefined = args[0];
                if(copsCar){

                  const carBodyType = enums?.carBodyTypes.find((c) => c.id === copsCar.bodyTypeId);
                  let newVdsCodeByBodyType = carBodyType?.modelVdsCode;
                  if (newVdsCodeByBodyType && newVdsCodeByBodyType.length > 0){
                    utils.changeValue(state, "modelVdsCode", () => newVdsCodeByBodyType);
                    return;
                  }

                  const model = enums?.carModels.find((x) => x.id === copsCar.modelId);
                  let newVdsCodeByModel = model?.modelVdsCode;
                  if (newVdsCodeByModel && newVdsCodeByModel.length > 0){
                    utils.changeValue(state, "modelVdsCode", () => newVdsCodeByModel);
                    return;
                  }

                }
              },
              setCarModelKey: (args, state, utils) => {
                let modelKey = state.lastFormState?.values.modelKey;
                let carModelId = state.lastFormState?.values.modelId;
                let bodyTypeId = state.lastFormState?.values.bodyTypeId;
                let equipmentId = state.lastFormState?.values.equipmentId;
                let engineId = state.lastFormState?.values.engineId;
                let transmissionId = state.lastFormState?.values.transmissionId;
                let carModelCode: string = "  ";
                let bodyTypeCode: string = " ";
                let equipmentCode: string = " ";
                let engineCode: string = " ";
                let transmissionCode: string = " ";
                const fieldName = args[0].currentTarget.name;
                const fieldValue = args[0].target.value;

                if (fieldName && fieldName === "modelId")
                  carModelId = Number(fieldValue);

                if (fieldName && fieldName === "bodyTypeId")
                  bodyTypeId = Number(fieldValue);

                if (fieldName && fieldName === "equipmentId")
                  equipmentId = Number(fieldValue);

                if (fieldName && fieldName === "engineId")
                  engineId = Number(fieldValue);

                if (fieldName && fieldName === "transmissionId")
                  transmissionId = Number(fieldValue);

                if (carModelId) {
                  const model = enums?.carModels.find(
                    (x) => x.id === carModelId
                  );
                  if (model && model?.code.length > 0)
                    carModelCode = model?.code;
                }
                if (bodyTypeId) {
                  const carBodyType = enums?.carBodyTypes.find(
                    (c) => c.id === bodyTypeId
                  );
                  if (carBodyType && carBodyType?.code.length > 0)
                    bodyTypeCode = carBodyType?.code;
                }
                if (equipmentId) {
                  const carEquipment = enums?.carEquipments.find(
                    (c) => c.id === equipmentId
                  );
                  if (carEquipment && carEquipment?.code.length > 0)
                    equipmentCode = carEquipment?.code;
                }
                if (engineId) {
                  const carEngine = enums?.carEngines.find(
                    (c) => c.id === engineId
                  );
                  if (carEngine && carEngine?.code.length > 0)
                    engineCode = carEngine?.code;
                }
                if (transmissionId) {
                  const carTransmission = enums?.carTransmissions.find(
                    (c) => c.id === transmissionId
                  );
                  if (carTransmission && carTransmission?.code.length > 0)
                    transmissionCode = carTransmission?.code;
                }

                modelKey = `${carModelCode}${bodyTypeCode}${equipmentCode}${engineCode}${transmissionCode}`;
                utils.changeValue(state, "modelKey", () => modelKey);
              },
            }}
            onSubmit={handleFinalFormSubmit}
            validate={(values) => {
              const errors = {} as any;
              if (values.vin && values.vin.length !== 17) {
                errors.vin = "VIN kód vozu musí mít přesně 17 znaků";
              }
              if (
                values.prodIdentificationNum &&
                values.prodIdentificationNum.length !== 13
              ) {
                errors.prodIdentificationNum =
                  "Identifikační číslo výroby musí obsahovat 13 znaků";
              }

              if (values.logisticNum && values.logisticNum.length > 5) {
                errors.logisticNum =
                  "Logistické číslo nesmí mít víc než 5 znaků";
              }
              if (values.integrationNum && values.integrationNum.length > 15) {
                errors.integrationNum = "VDS číslo nesmí mít víc než 15 znaků";
              }
              if (
                values.importantCarDesc &&
                values.importantCarDesc.length > 50
              ) {
                errors.importantCarDesc =
                  "Důležitost vozu nesmí mít víc než 50 znaků.";
              }
              if (
                values.startOfProduction &&
                values.startOfProduction.length > 10
              ) {
                errors.startOfProduction =
                  "Začátek produkce nesmí mít víc než 10 znaků";
              }
              if (values.modelYear && values.modelYear.length > 4) {
                errors.modelYear = "Modelový rok nesmí mít víc než 4 znaky";
              }
              if (values.customerCode && values.customerCode.length > 20) {
                errors.customerCode = "Odběratel nesmí mít víc než 20 znaků";
              }
              if (
                values.emissionStandard &&
                values.emissionStandard.length > 50
              ) {
                errors.emissionStandard = "Emise nesmí mít víc než 50 znaků";
              }
              if (values.typeOfControl && values.typeOfControl.length > 20) {
                errors.typeOfControl = "Typ řízení nesmí mít víc než 20 znaků";
              }
              if (
                values.transmissionCode &&
                values.transmissionCode.length > 50
              ) {
                errors.transmissionCode =
                  "Převodovka nesmí mít víc než 50 znaků";
              }
              if (values.colorCode && values.colorCode.length > 5) {
                errors.colorCode = "Barva nesmí mít víc než 5 znaků";
              }

              if (
                values.plannedDaysInTestRoom &&
                values.plannedDaysInTestRoom < 0
              ) {
                errors.plannedDaysInTestRoom = "Počet dní nesmí být záporný";
              }
              if (values.daysInTestRoom && values.daysInTestRoom < 0) {
                errors.daysInTestRoom = "Počet dní nesmí být záporný";
              }
              if (
                values.emissionTest &&
                canSetEmissionTest(
                  values.testDestinationId,
                  values.customerCode
                ) &&
                moment(values.emissionTestPlanFrom) >
                  moment(values.emissionTestPlanTo)
              ) {
                errors.emissionTestPlanFrom =
                  "Datum od musí být menší než datum do";
                errors.emissionTestPlanTo =
                  "Datum do musí být větší než datum od";
              }
              if ((values as any).carTest) {
                if (!errors.carTest) errors.carTest = [];
                manuallyCreatedCarTests.forEach(
                  (test: ICarTestManuallyAdded) => {
                    if (!(values as any).carTest[test.rownumber].description)
                      errors.carTest[test.rownumber] = {
                        ...errors.carTest[test.rownumber],
                        description: "Popis zkoušky je povinný",
                      };
                    if ((values as any).carTest[test.rownumber].length < 0) {
                      errors.carTest[test.rownumber] = {
                        ...errors.carTest[test.rownumber],
                        length: "Délka zkoušky nesmí být záporná",
                      };
                    }

                    if (
                      !(values as any).carTest[test.rownumber].length &&
                      (values as any).carTest[test.rownumber].length !== 0
                    )
                      errors.carTest[test.rownumber] = {
                        ...errors.carTest[test.rownumber],
                        length: "Délka zkoušky je povinná",
                      };
                  }
                );
              }
              return errors;
            }}
            render={({ handleSubmit, values, form }) => (
              <Form onSubmit={handleSubmit}>
                <div className="pageheader">
                  <div className="pageheader__left">
                    {!car.carId ? (
                      <h2>Založení nového vozu</h2>
                    ) : (
                      <h2>
                        Detail vozu <VinCode vin={car.vin} />
                      </h2>
                    )}
                  </div>
                  {!editVin && (
                    <div className="pageheader__right">
                      <Can
                        roleId={user?.userRoleId}
                        perform="car-detail-page:editBtn"
                        data={{
                          userPlaceId: user?.placeId,
                          placeId: car.placeId ? car.placeId : user?.placeId,
                        }}
                        yes={
                          <EditSaveResetButtons
                            edit={edit}
                            setEdit={setEdit}
                            handleReset={handleReset}
                            form={form}
                            isSaving={isSaving}
                          />
                        }
                      />
                    </div>
                  )}
                </div>
                <AlertMessage
                  type="danger"
                  heading="Nastala chyba"
                  show={showSubmitError}
                  setShow={setShowSubmitError}
                  messageList={submitErrors}
                />
                {!!car.carTestConflicts?.length && (
                  <ConflictsPanel
                    conflicts={car.carTestConflicts}
                    title={"Vůz nebo jeho zkoušky způsobují konflikt"}
                  />
                )}
                <div>
                  <CarDetailForm
                    enums={enums}
                    form={form}
                    values={values}
                    edit={
                      edit &&
                      checkAuthRule(user?.userRoleId, "car-detail-page:editCar")
                    }
                    editVin={editVin}
                    loadedCarTests={loadedCarTests}
                    car={car}
                    carsByVin={carsByVin}
                    isVinLock={vinLock}
                    isKnrLock={knrLock}
                    handleValidateVinAndKnr={handleValidateVinAndKnr}
                    hadleFindCarByVinAndKnrInCops={hadleFindCarByVinAndKnrInCops}
                  />
                </div>
                <Tabs
                  id="cardetail-tab"
                  className="tabs"
                  activeKey={key}
                  onSelect={(k: string) => setKey(k)}
                >
                  <Tab
                    eventKey="parameters"
                    title="Parametry vozu"
                    className="tabs__tab"
                  >
                    <CarParametersTab
                      enums={enums}
                      form={form}
                      values={values}
                      edit={
                        edit &&
                        checkAuthRule(
                          user?.userRoleId,
                          "car-detail-page:editParams"
                        )
                      }
                    />
                  </Tab>
                  <Tab 
                    eventKey='prNumbers'
                    title="PR popis vozu"
                    className="tabs__tab"
                  >
                    <CarPrNumbersTab enums={enums} prNumbers={carPrNumbers?.carPrNumbers} />
                  </Tab>
                  <Tab
                    eventKey="testroom"
                    title="Data zkušebny"
                    className="tabs__tab"
                  >
                    <TestRoomTab
                      enums={enums}
                      form={form}
                      values={values}
                      edit={
                        edit &&
                        checkAuthRule(
                          user?.userRoleId,
                          "car-detail-page:editParams"
                        )
                      }
                    />
                  </Tab>
                  <Tab eventKey="carTests" title="Zkoušky vozu">
                    <CarTestsTab
                      enums={enums}
                      values={values}
                      edit={
                        edit &&
                        checkAuthRule(
                          user?.userRoleId,
                          "car-detail-page:editCarTests"
                        )
                      }
                      showAssignCarTestError={showAssignCarTestError}
                      setShowAssignCarTestError={setShowAssignCarTestError}
                      autoAssignCarTests={autoAssignCarTests}
                      manuallyCreatedCarTests={manuallyCreatedCarTests}
                      loadedCarTests={loadedCarTests}
                      handleDeleteManuallyAddedCarTest={
                        handleDeleteManuallyAddedCarTest
                      }
                      handleAddCarTestManually={handleAddCarTestManually}
                      loadTestsFromTestDefinitions={
                        loadTestsFromTestDefinitions
                      }
                    />
                  </Tab>
                  {carAnalysis && carAnalysis.length > 0 && (
                    <Tab eventKey="analysis" title="Analýzy vozu">
                      <CarAnalysisTab enums={enums} values={values} carAnalysis={carAnalysis} />
                    </Tab>
                  )}
                  <Tab eventKey="history" title="Historie vozu">
                    <CarHistoryTab enums={enums} history={carHistory} />
                  </Tab>
                  <Tab eventKey="Protocols" title="Protokoly a přílohy">
                    <CarProtocolsTab values={values} />
                  </Tab>
                  <Tab eventKey="note" title="Poznámky">
                    <div>
                      <Field<string>
                        name="note"
                        component={TextAreaInput}
                        disabled={
                          !edit ||
                          !checkAuthRule(
                            user?.userRoleId,
                            "car-detail-page:editNote"
                          )
                        }
                      />
                    </div>
                  </Tab>
                </Tabs>
                <FormSpy
                  subscription={{ pristine: true }}
                  onChange={(props) => {
                    setChanged(!props.pristine);
                  }}
                />
              </Form>
            )}
          />
        </div>
      )}
      {/* {loadingInitial &&  <CarLoader label="Načítání detailu vozu" /> } */}
    </Container>
  );
};

export default observer(CarDetailPage);
