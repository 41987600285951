import React, { useContext } from "react";
import { Modal, Button } from "react-bootstrap";
import { RootStoreContext } from "../../stores/rootStore";
import { observer } from "mobx-react-lite";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ConfirmationDialog = () => {
  const rootStore = useContext(RootStoreContext);
  const {
    dialog: {
      open,
      body,
      title,
      cancelButtonLabel,
      confirmButtonLabel,
      onConfirm,
      onCancel,
    },
    closeDialog,
  } = rootStore.modalStore;
  return (
    <Modal show={open} onHide={closeDialog} backdrop="static" size="lg" centered>
      <Modal.Header closeButton>
        <Modal.Title>
          <FontAwesomeIcon
            icon={faExclamationTriangle}
            className="text-warning"
            style={{ fontSize: "2.5rem", marginRight: "20px" }}
          />
          {title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>{body}</Modal.Body>
      <Modal.Footer>
        <Button
          variant="outline-secondary"
          onClick={() => {
            onCancel && onCancel();
            closeDialog();
          }}
        >
          {cancelButtonLabel || "Zrušit"}
        </Button>
        <Button
          variant="warning"
          onClick={() => {
            onConfirm && onConfirm();
            closeDialog();
          }}
        >
          {confirmButtonLabel || "Ano"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default observer(ConfirmationDialog);
