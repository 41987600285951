import React, { Fragment, useEffect, useContext } from "react";
import { Table, Button, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./ExpertGroupList.scss";
import { RootStoreContext } from "../../app/stores/rootStore";
import Loader from "../loader/Loader";
import { observer } from "mobx-react-lite";
import Can from "../../authorization/Can";

const ExpertGroupList = () => {
  const rootStore = useContext(RootStoreContext);
  const {
    loadExpertGroups,
    expertGroups,
    loadingInitial,
  } = rootStore.expertGroupStore;
  const { user } = rootStore.authStore;

  useEffect(() => {
    loadExpertGroups();
  }, [loadExpertGroups]);

  return (
    <Fragment>
      <div className="page-content">
        <Container>
          <div className="pageheader">
            <div className="pageheader__left">
              <h1>Seznam odborných skupin</h1>
            </div>
          </div>
          <Table hover>
            <thead>
              <tr>
                <th>Název skupiny</th>
                <th>Zkratka</th>
                <th>Barva</th>
                <th>Akce</th>
              </tr>
            </thead>
            {!loadingInitial && (
              <tbody>
                {expertGroups
                  .slice()
                  .sort((a, b) =>
                    a.expertGroupName < b.expertGroupName ? -1 : 1
                  )
                  .map((group) => (
                    <tr key={group.expertGroupId}>
                      <td>{group.expertGroupName}</td>
                      <td>{group.expertGroupCode}</td>
                      <td>
                        <div
                          className={`circle circle--${group.expertGroupColor}`}
                        ></div>
                      </td>
                      <td>
                        <Can
                          roleId={user?.userRoleId}
                          perform="expert-group-detail-page:visit"
                          yes={
                            <Button
                              as={Link}
                              to={`/expertgroup/${group.expertGroupId}`}
                              variant="outline-primary"
                            >
                              Detail
                            </Button>
                          }
                          data={{
                            userExpertGroupId: user?.expertGroupId,
                            expertGroupId: group.expertGroupId,
                            managedExpertGroups: user?.managedGroups,
                          }}
                        />
                      </td>
                    </tr>
                  ))}
              </tbody>
            )}
          </Table>
          {loadingInitial && <Loader />}
        </Container>
      </div>
      <footer>
        <Container className="footer">
          <div className="footer-left">
            <Can
              roleId={user?.userRoleId}
              perform="expert-groups-page:create"
              yes={
                <Button
                  as={Link}
                  to="/expertgroup/new"
                  variant="outline-primary"
                  style={{ marginBottom: "10px", marginRight: "20px" }}
                >
                  <FontAwesomeIcon icon={faPlus} /> Přidat skupinu
                </Button>
              }
            />
          </div>
          <div className="footer-right"></div>
        </Container>
      </footer>
    </Fragment>
  );
};

export default observer(ExpertGroupList);
