import { CarTestStates } from "../../models/enum";
import { userRoles } from "../../models/auth";

//    Povolené přechody mezi stavy a kdo je smí provádět:
//    Změna z „Registrovaná“ na „Neprovádět“ – smí provést Plánovací administrátor.
//    Změna z „Neprovádět“ na „Registrovaná“ – smí provést Plánovací administrátor.
//    Změna z „Nedokončená“ na „Plánovaná“ – smí provést  Plánovací administrátor.
//    Změna z „Plánovaná“ na „Probíhá“ – smí provést Pracovník odborné skupiny.
//    Změna z „Plánovaná“ na „Zrušená“ – smí provést Vedoucí odborné skupiny.
//    Změna ze „Zrušená“ na „Registrovaná“ – smí provést Plánovací administrátor.
//    Změna z „Probíhá“ na „Nedokončená“ – smí provést Pracovník odborné skupiny.
//    Změna z „Probíhá“ na „Dokončená“ – smí provést Pracovník odborné skupiny.

// TestStateId	Name
// 1	Registrovaná
// 2	Plánovaná
// 3	Probíhající
// 4	Neprovádět
// 5	Zrušená
// 6	Nedokončená
// 7	Dokončená
// 	Prerusena

export const getTestStateTransitions = (
  initState: CarTestStates,
  userRoleId: userRoles
): number[] => {
  userRoleId = parseInt(userRoleId.toString());
  switch (initState) {
    // „Registrovaná“
    case CarTestStates.Registered:
      if (userRoleId === userRoles.planAdministrator)
        return [CarTestStates.Registered, CarTestStates.DoNotPerform];
      else return [CarTestStates.Registered];

    // „Plánovaná“
    case CarTestStates.Planned:
      if (userRoleId === userRoles.expertGroupWorker)
        return [CarTestStates.Planned, CarTestStates.Ongoing];
      else if (
        userRoleId === userRoles.expertGroupLeader ||
        userRoleId === userRoles.planAdministrator
      )
        return [
          CarTestStates.Ongoing,
          CarTestStates.Planned,
          CarTestStates.Cancelled,
        ];
      else return [CarTestStates.Planned];

    // „Probíhá“
    case CarTestStates.Ongoing:
      return [
        CarTestStates.Ongoing,
        CarTestStates.Unfinished,
        CarTestStates.Interrupted,
        CarTestStates.Completed,
      ];

    // „Neprovádět“
    case CarTestStates.DoNotPerform:
      if (userRoleId === userRoles.planAdministrator)
        return [CarTestStates.Registered, CarTestStates.DoNotPerform];
      else return [CarTestStates.DoNotPerform];

    // „Zrušená“
    case CarTestStates.Cancelled:
      if (userRoleId === userRoles.planAdministrator)
        return [
          CarTestStates.Registered,
          CarTestStates.Planned,
          CarTestStates.Cancelled,
        ];
      if (userRoleId === userRoles.expertGroupLeader)
        return [CarTestStates.Planned, CarTestStates.Cancelled];
      else return [CarTestStates.Cancelled];

    // „Nedokončená“
    case CarTestStates.Unfinished:
      if (userRoleId === userRoles.planAdministrator)
        return [
          CarTestStates.Unfinished,
          CarTestStates.Completed,
          CarTestStates.Planned,
        ];
      else return [CarTestStates.Unfinished, CarTestStates.Completed];

    // „Dokončená“
    case CarTestStates.Completed:
      return [CarTestStates.Completed];

    // „Přerušená“
    case CarTestStates.Interrupted:
      return [CarTestStates.Interrupted, CarTestStates.Cancelled];

    default:
      return [];
  }
};
