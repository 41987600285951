import React from 'react';
import {Col, Form, Row, Table} from "react-bootstrap";
import {Field} from "react-final-form";
import TextInput from "../../../../app/common/form/TextInput";

interface IProps {
}

const DefectsCount: React.FC<IProps> = () => {
    return (
        <>
            {/*Počet závad*/}
            <Table
                className="ProtocolAddInfo"
                style={{marginTop: "0", fontSize: "0.9em"}}
            >
                <tbody>
                <tr className={`protocol-group`}>
                    <td>
                        <Form.Group controlId={"defectsCount"}>
                            <Row>
                                <Col xs={3}>
                                    <Form.Label>Počet závad</Form.Label>
                                </Col>
                                <Col xs={5}>
                                    <Field<string>
                                        name="defectsCount"
                                        component={TextInput}
                                        readonly
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={3}>
                                    <Form.Label>z toho -</Form.Label>
                                </Col>
                                <Col xs={5}>
                                    <Field<string>
                                        name="defectsCountByNumber"
                                        component={TextInput}
                                        readonly
                                    />
                                    <Field<string>
                                        name="defectsCountByCategory"
                                        component={TextInput}
                                        readonly
                                    />
                                </Col>
                            </Row>
                        </Form.Group>
                    </td>
                </tr>
                </tbody>
            </Table>
        </>
    );
};

export default DefectsCount;
