import { RootStore } from "./rootStore";
import { observable, action, runInAction } from "mobx";
import agent from "../api/agent";
import {
  ICarEngineItem,
  ICarModelForm,
  ICarModelItem,
  ICarModelItemForm,
  ICarBodyTypeItem,
  ICarBodyTypeItemForm,
  IEnumItem,
  IEnums,
  ICarModel,
  ITestTypeForm,
  ICarColorItem,
  ICarColorForm,
} from "../models/enum";
import { toast } from "react-toastify";

export default class EnumStore {
  rootStore: RootStore;
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @observable enums: IEnums | undefined = undefined;
  @observable isLoading: boolean = false;

  @action loadEnums = async () => {
    if (!this.enums) {
      try {
        this.isLoading = true;
        const enums = await agent.Enums.list();

        runInAction(() => {
          this.enums = enums;
          this.isLoading = false;
        });
      } catch (error) {
        console.log(error);
        runInAction(() => {
          this.isLoading = false;
        });
      }
    }
  };

  @action updateCarModel = async (carModel: ICarModelForm) => {
    try {
      const carModelId = await agent.Enums.updateCarModel(carModel);
      runInAction(() => {
        const newCarModel: ICarModel = {
          id: carModelId,
          isActive: carModel.isActive,
          name: carModel.name,
          emissionFree:carModel.emissionFree,
          modelVdsCode:carModel.modelVdsCode,
          code: carModel.code,
        };
        this.enums!.carModels = [
          ...this.enums!.carModels.filter((x) => x.id !== carModelId),
          newCarModel,
        ];
        toast.success("Model vozu byl úspěšně uložen");
      });
    } catch (error) {
      console.log(error);
      toast.error("Nastala chyba při ukládání modelu vozu");
      throw error;
    }
  };

  @action updateTestType = async (testType: ITestTypeForm) => {
    try {
      const testTypeId = await agent.Enums.updateTestType(testType);
      runInAction(() => {
        const newTestTyp: IEnumItem = {
          key: testType.id,
          value: testType.name,
          code: testType.code, 
          isActive: testType.isActive
        };
        this.enums!.testTypes = [
          ...this.enums!.testTypes.filter((x) => x.key !== testTypeId),
          newTestTyp,
        ];
        toast.success("Typ zkoušky byl úspěšně uložen");
      });
    } catch (error) {
      console.log(error);
      toast.error("Nastala chyba při ukládání typu zkoušky");
      throw(error);
    }
  };

  @action updateCarEquipment = async (carEquipment: ICarModelItemForm) => {
    try {
      const carEquipmentId = await agent.Enums.updateCarEquipment(carEquipment);
      runInAction(() => {
        const newCarEquipment: ICarModelItem = {
          id: carEquipmentId,
          name: carEquipment.name,
          code: carEquipment.code,
          isActive: carEquipment.isActive,
          carModelId: carEquipment.carModelId,
        };
        this.enums!.carEquipments = [
          ...this.enums!.carEquipments.filter((x) => x.id !== carEquipmentId),
          newCarEquipment,
        ];
        toast.success("Výbava vozu byla úspěšně uložena");
      });
    } catch (error) {
      console.log(error);
      toast.error("Nastala chyba při ukládání Výbavy vozu");
      throw(error);
    }
  };

  @action updateCarEngine = async (carEngine: ICarEngineItem) => {
    try {
      const carEngineId = await agent.Enums.updateCarEngine(carEngine);
      runInAction(() => {
        const newCarEngine: ICarEngineItem = {
          id: carEngineId,
          name: carEngine.name,
          code: carEngine.code,
          isActive: carEngine.isActive,
          emissionFree: carEngine.emissionFree,
          carModelId: carEngine.carModelId,
        };
        this.enums!.carEngines = [
          ...this.enums!.carEngines.filter((x) => x.id !== carEngineId),
          newCarEngine,
        ];
        toast.success("Motorizace vozu byla úspěšně uložena");
      });
    } catch (error) {
      console.log(error);
      toast.error("Nastala chyba při ukládání motorizace vozu");
      throw(error);
    }
  };
  
  @action updateCarBodyType = async (carBodyType: ICarBodyTypeItemForm) => {
    try {
      const carBodyTypeId = await agent.Enums.updateCarBodyType(carBodyType);
      runInAction(() => {
        const newCarBodyType: ICarBodyTypeItem = {
          id: carBodyTypeId,
          name: carBodyType.name,
          code: carBodyType.code,
          isActive: carBodyType.isActive,
          modelVdsCode: carBodyType.modelVdsCode,
          carModelId: carBodyType.carModelId,
        };
        this.enums!.carBodyTypes = [
          ...this.enums!.carBodyTypes.filter((x) => x.id !== carBodyTypeId),
          newCarBodyType,
        ];
        toast.success("Karoserie vozu byla úspěšně uložena");
      });
    } catch (error) {
      console.log(error);
      toast.error("Nastala chyba při ukládání karoserie vozu");
      throw(error);
    }
  };

  @action updateCarColor = async (carColor: ICarColorForm) => {
    try {
      const carColorId = await agent.Enums.updateCarColor(carColor);
      runInAction(() => {
        const newcarColor: ICarColorItem = {
          id: carColorId!,
          value: carColor.value,
          code: carColor.code, 
          name: carColor.name,
          isActive: carColor.isActive
        };
        this.enums!.carColors = [
          ...this.enums!.carColors.filter((x) => x.id !== carColorId),
          newcarColor,
        ];
        toast.success("Barva byla úspěšně uložena");
      });
    } catch (error) {
      console.log(error);
      toast.error("Nastala chyba při ukládání typu barvy");
      throw(error);
    }
  };
}
