import "./ScheduleTableRow.scss";

import React, {Fragment, useContext} from "react";
import {Field, Form as FinalForm} from "react-final-form";
import {observer} from "mobx-react-lite";
import {Link} from "react-router-dom";
import moment from "moment";
import {Button, Form, OverlayTrigger, Popover} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPen} from "@fortawesome/free-solid-svg-icons";
import {faEdit} from "@fortawesome/free-regular-svg-icons";

import {RootStoreContext} from "../../../app/stores/rootStore";
import TextAreaInput from "../../../app/common/form/TextAreaInput";
import VinCode from "../../vin/VinCode";
import ScheduleCarDetail from "./ScheduleCarDetail";
import ScheduleItem from "./ScheduleItem";
import ScheduleEmissionItem from "./ScheduleEmissionItem";
import VipCar from "../../icons/VipCar";
import IncompleteCar from "../../icons/IncompleteCar";
import {ICar} from "../../../app/models/car";
import {IDate} from "../../../app/models/date";
import {ICarTest} from "../../../app/models/carTest";
import Can from "../../../authorization/Can";
import ExceededLimit from "../../icons/ExceededLimit";
import CarColor from "../../icons/CarColor";
import CarNotLoaded from "../../icons/CarNotLoaded";
import {isEmissionCar, isToday, isTodayOrPast,} from "../../../app/common/utils/functions";
import TransportedCarIcon from "../../icons/TransportedCarIcon";
import EmissionDate from "../../icons/EmissionDate";
import {ICarTerm} from "../../../app/models/schedule";
import {ICarAnalysis} from "../../../app/models/carAnalysis";
import ScheduleAnalysisItem from "./ScheduleAnalysisItem";

interface IProps {
  car: ICar;
  days: IDate[];
  carTests: ICarTest[];
  carAnalyses: ICarAnalysis[];
  carTerms: ICarTerm[];
  isnew: boolean;
  isSelected: boolean;
}

const ScheduleTableRow: React.FC<IProps> = ({
  car,
  days,
  carTests,
  isnew,
  carTerms,
  carAnalyses,
  isSelected,
}) => {
  const rootStore = useContext(RootStoreContext);
  const { editCarNote } = rootStore.carStore;
  const { user } = rootStore.authStore;
  const setSelectedCar = rootStore.pageScheduleStore.setSelectedCars;
  const handleEditNote = (form: any) => {
    editCarNote(car.carId, form.note).then((car.note = form.note));

    // to close overlay popover workaround
    document.body.click();
  };

  const carDetail = (
    <Popover
      id={`popover-cardetail-${car.carId}`}
      style={{ minWidth: "40rem" }}
    >
      <Popover.Title as="h3">
        Detail vozu <VinCode vin={car.vin} />
      </Popover.Title>
      <Popover.Content>
        <ScheduleCarDetail car={car} />
      </Popover.Content>
    </Popover>
  );

  const noteEditor = (
    <Popover id={`popover-noteeditor-${car.carId}`}>
      <Popover.Title as="h3">
        Poznámka k vozu <VinCode vin={car.vin} />
      </Popover.Title>
      <Popover.Content style={{ width: "30rem" }}>
        <FinalForm
          onSubmit={handleEditNote}
          render={({ handleSubmit }) => (
            <Form onSubmit={handleSubmit}>
              <Can
                roleId={user?.userRoleId}
                perform="car-calendar-page:editNote"
                yes={
                  <Field
                    name="note"
                    defaultValue={car.note}
                    component={TextAreaInput}
                  />
                }
                no={
                  <Field
                    name="note"
                    defaultValue={car.note}
                    component={TextAreaInput}
                    disabled
                  />
                }
              />
              <Can
                roleId={user?.userRoleId}
                perform="car-calendar-page:editNote"
                yes={
                  <Button
                    type="submit"
                    variant="primary"
                    style={{ margin: "0.5rem 0.5rem 0rem 0.5rem" }}
                  >
                    Uložit
                  </Button>
                }
              />
            </Form>
          )}
        />
      </Popover.Content>
    </Popover>
  );

  const noteSlice = (note: string | undefined, length: number) => {
    return note
      ? note.length > length
        ? `${note.slice(0, length - 2)}...`
        : note
      : "";
  };

  const toUnload =
    isTodayOrPast(car.plannedTo) &&
    !isEmissionCar(
      car.emissionTest,
      car.emissionTestPlanFrom,
      car.emissionTestDateTo
    ) &&
    !car.availableTo
      ? "row-to-unload"
      : "";

  const emissionCarClass =
    isEmissionCar(
      car.emissionTest,
      car.emissionTestPlanFrom,
      car.emissionTestDateTo
    ) && !!car.emissionTestDateFrom
      ? "row-emission-car"
      : "";

  const analyticsCarClass = carAnalyses.find((x) => x.carId == car.carId)
    ? "row-analytics-car"
    : "";

  const renderEmissionCarTestItem = (
    car: ICar | undefined,
    date: IDate | undefined
  ) => {
    const day = moment(date?.date);
    var emissionStart = !!car?.emissionTestDateFrom
      ? car?.emissionTestDateFrom
      : car?.emissionTestPlanFrom;
    var emissionEnd = !!car?.emissionTestDateFrom
      ? car?.emissionTestDateTo
      : car?.emissionTestPlanTo;
    if (!emissionEnd) emissionEnd = date?.date;
    return (
      <Fragment>
        {car?.emissionTest &&
          !!emissionStart &&
          moment(emissionStart).startOf("d").isSameOrBefore(day) &&
          moment(emissionEnd).startOf("d").isSameOrAfter(day) && (
            <ScheduleEmissionItem
              key={"CarId_" + car.carId + "_" + day}
              scheduleEmissionItem={car}
              dayNumber={date?.dayNumber}
              day={day}
            />
          )}
      </Fragment>
    );
  };

  const isNew = isnew ? "new" : "";

  const isSelectedCar = isSelected ? "selected-car" : "";

  //TODO zvyrazneni nedoplanovaneho vozu
  const testsToPlan = ""; // !car.isComplete?"row-to-plan":"";

  const icons = [];
  if (car.mentionIncomplete && !car.isComplete)
    icons.push(
      <IncompleteCar id={car.carId} key={`incomplete-${car.carId}`} />
    );
  // if (!!car.carTestConflicts?.length)
  //   icons.push(
  //     <CarTestCollisions id={car.carId} key={`conflicts-${car.carId}`} />
  //   );
  if (car.timeLimit !== 0)
    icons.push(
      <ExceededLimit
        id={car.carId}
        timeLimitType={car.timeLimit}
        key={`timelimit-${car.carId}`}
      />
    );
  if (!car.availableFrom)
    icons.push(<CarNotLoaded id={car.carId} key={`notloaded-${car.carId}`} />);
  if (car.isTransported)
    icons.push(
      <TransportedCarIcon
        key={car.carId}
        id={car.carId}
        fromName={car.beforeTransportPlaceCode || ""}
        toName={car.placeCode}
      />
    );

  const wideColumns = 5;
  const narrowColumns = 2;
  const columnWidth = (0.55 * 100) / (wideColumns * 5 + narrowColumns * 3);
  const columnWideWidth = columnWidth * 5 + "%";
  const columnNarrowWidth = columnWidth * 3 + "%";
  const columnDayWidth = (0.45 * 100) / (days.length || 1) + "%";

  return (
    <div
      className={`table-row ${isNew} ${toUnload} ${testsToPlan} ${emissionCarClass} ${analyticsCarClass} ${isSelectedCar}`}
    >
      <div className="column column-group" style={{ width: columnWideWidth }}>
        {car.vipCar && <VipCar id={car.carId} />}
        <CarColor
          id={car.carId}
          color={car.colorValue}
          code={car.colorCode}
          name={car.colorName}
        />
        <div>{car.integrationNum}</div>
        <div>
          <Button
            className="no-print"
            variant="link"
            size="sm"
            title="Detail vozu"
            as={Link}
            to={`/car/${car.carId}`}
          >
            <FontAwesomeIcon icon={faPen} />
          </Button>
        </div>
      </div>

      <div className="column column-vin" style={{ width: columnWideWidth }}>
        <OverlayTrigger
          trigger={["click"]}
          placement="auto"
          overlay={carDetail}
          rootClose
        >
          <Button variant="link">
            <VinCode
              vin={car.vin}
              important={car.vipCar || !!car.importantCarDesc}
              breakLine={true}
            />
          </Button>
        </OverlayTrigger>
      </div>
      <div className="column" style={{ width: columnWideWidth }}>
        <div>
          <Button
            variant="link"
            onClick={() => setSelectedCar(car.carId)}
          >
            {car.carState}
          </Button>
          </div>
        <div className="plancar-status-icons plancar-status-icons--center">
          {icons}
        </div>
      </div>
      <div
        className="column"
        title={car.testDestinationName}
        style={{ width: columnNarrowWidth }}
      >
        {car.testDestinationCode}
      </div>
      <div className="column" style={{ width: columnWideWidth }}>
        <div className="flex-column">
          <div>{car.testTypeName}</div>
          <div>{car.importantCarDesc}</div>
          {car.emissionTest && <div>EMISE</div>}
          {car.emissionTest && (
            <EmissionDate
              id={car.carId}
              planned={true}
              dateFrom={car.emissionTestPlanFrom}
              dateTo={car.emissionTestPlanTo}
              cssClass="emission-planned-dates"
            />
          )}
          {car.emissionTest && (
            <EmissionDate
              id={car.carId}
              planned={false}
              dateFrom={car.emissionTestDateFrom}
              dateTo={car.emissionTestDateTo}
            />
          )}
          {car.analyticsCar && <div>Analytický vůz</div>}
        </div>
      </div>
      <div className="column" style={{ width: columnNarrowWidth }}>
        {car.customerCode}
      </div>

      {days.map((date) => {
        const day = moment(date.date);
        const classNameToday = isToday(date.date) ? "column-date--today" : "";

        return (
          <div
            key={day.toISOString()}
            className={`column column-group-items column-date ${classNameToday}`}
            style={{ width: columnDayWidth }}
          >
            {renderEmissionCarTestItem(car, date)}

            {carTerms
              .filter(
                (carTerm) =>
                  carTerm.carId === car.carId &&
                  carTerm.startAt &&
                  moment(carTerm.startAt).startOf("d").isSameOrBefore(day) &&
                  moment(carTerm.endAt).startOf("d").isSameOrAfter(day)
              )
              .sort((a, b) => (a.startAt! < b.startAt! ? -1 : 1))
              .map((scheduleItem) => {
                if (scheduleItem.termTypeId === 1) {
                  let carTest = carTests.find(
                    (x) => x.carTestId === scheduleItem.carTestId
                  );
                  return (
                    <ScheduleItem
                      key={`t-${scheduleItem.carTestId}`}
                      scheduleItem={carTest!}
                    />
                  );
                } else {
                  let analysis = carAnalyses.find(
                    (x) => x.carAnalysisId == scheduleItem.carAnalysisId
                  );
                  if (!analysis) return <></>

                  return (
                    <ScheduleAnalysisItem
                      key={`a-${scheduleItem.carAnalysisId}`}
                      term={scheduleItem}
                      analysis={analysis}
                      car={car}
                    />
                  );
                }
              })}
          </div>
        );
      })}

      <div className="column" style={{ width: columnWideWidth }}>
        <div className="print-only">{car.note}</div>
        <OverlayTrigger
          trigger={["click"]}
          placement="left"
          overlay={noteEditor}
          rootClose
        >
          <Button
            variant="link"
            size="sm"
            title={car.note}
            className="no-print"
          >
            {noteSlice(car.note, 8)}
            <FontAwesomeIcon icon={faEdit} style={{ marginLeft: "0.3rem" }} />
          </Button>
        </OverlayTrigger>
      </div>
    </div>
  );
};

export default observer(ScheduleTableRow);
