import React from "react";
import { Row, Col } from "react-bootstrap";
import "./LabelValuePairs.scss";

interface IProps {
  label: string;
  value: any;
  labelColSize: number;
  valueColSize: number;
  labelStyle?: React.CSSProperties;
  valueStyle?: React.CSSProperties;
}
const LabelValuePair: React.FC<IProps> = ({
  label,
  value,
  labelColSize,
  valueColSize,
  labelStyle,
  valueStyle
}) => {
  return (
    <Row className="lvrow">
      <Col xs={labelColSize}>
        <div className="lvrow__label" style={labelStyle}>{label}</div>
      </Col>
      <Col xs={valueColSize}>
        <div className="lvrow__value" style={valueStyle}>{value}</div>
      </Col>
    </Row>
  );
};

export default LabelValuePair;
