import React, { Fragment, useEffect, useState } from "react";
import { Form, Row, Col, Button, Alert } from "react-bootstrap";
import { Field, useForm } from "react-final-form";
import TextInput from "../../../app/common/form/TextInput";
import { ICar, ICarPrNumber } from "../../../app/models/car";
import { IEnums } from "../../../app/models/enum";

import { Card, Table } from "react-bootstrap";
import Accordion from 'react-bootstrap/Accordion';


interface IProps {
    enums: IEnums | undefined;
    prNumbers: ICarPrNumber[] | undefined;
}

const CarPrNumbersTab: React.FC<IProps> = ({
    enums,
    prNumbers
  }) => {

    const form = useForm<ICar>();

    const [localPrNumbers, setLocalPrNumbers] = useState(prNumbers);
    const [categories, setCategories] = useState(enums?.prNumberCategories);
    const [cancelSearch, setCancelSearch] = useState(false);

    const searchPrNumbers = () => {
        
        const searchPrNumeberText: string | undefined = form.getFieldState('searchPrNumeberText')?.value;
        if(searchPrNumeberText){

            const prNums = prNumbers?.filter(x => x.prNumFamilyCode?.includes(searchPrNumeberText) ||
                                                  x.prNumFamilyName?.includes(searchPrNumeberText) ||
                                                  x.prNumberCode.includes(searchPrNumeberText) ||
                                                  x.prNumberName.includes(searchPrNumeberText));

            const categoryIds = prNums?.map(x => x.prNumCategoryId);
            const filteredCategories = categories?.filter(x => categoryIds?.includes(x.id));

            setCategories(filteredCategories);
            setLocalPrNumbers(prNums);
            setCancelSearch(true);
        }
        else
        {
            setCategories(enums?.prNumberCategories);
            setLocalPrNumbers(prNumbers);
            setCancelSearch(false);
        }
    };

    const cancelSearchPrNumbers = () => {
        setCategories(enums?.prNumberCategories);
        setLocalPrNumbers(prNumbers);
        setCancelSearch(false);
        form.getFieldState(`searchPrNumeberText`)?.change('');
    }

    const SerachPanel = () => {
        return (
            <Row>
                <Col lg={9}>
                    <Field<string>
                        name="searchPrNumeberText"
                        title="Vyhledat"
                        titleWidth={2}
                        fieldWidth={10}
                        component={TextInput}
                        disabled={false}
                    />
                </Col>
                <Col lg={3}>
                    <Button className="btn-wide" onClick={() => searchPrNumbers()} >
                        Hledej!
                    </Button>
                    {cancelSearch &&
                        <Button
                            variant="outline-secondary"
                            type="reset"
                            style={{ marginLeft: 10 }}
                            onClick={() => cancelSearchPrNumbers()}
                            >
                                Zrušit hledání 
                        </Button>
                    }
                </Col>
            </Row>
        )
    }

    if(!categories)
        return  (
            <Fragment>
                {SerachPanel()}
                <Row>
                    <Col>
                        <Alert variant="info">
                            Neexistují kategorie PR čísel.
                        </Alert>
                    </Col>
                </Row>
            </Fragment>
    )

    if (!localPrNumbers || (localPrNumbers && localPrNumbers.length === 0))
        return  (
            <Fragment>
                {SerachPanel()}
                <Row>
                    <Col>
                        <Alert variant="info">
                            U vozu nebyla vyhledána PR čísla.
                        </Alert>
                    </Col>
                </Row>
            </Fragment>
    )
    
    return (
        <Fragment>
            {SerachPanel()}
            <Accordion defaultActiveKey="0">
                {categories
                    .sort((a, b) => a.order > b.order ? 1 : -1)
                    .map((prCategory, index) =>
                    (
                        <Fragment>
                            
                        <Card>
                            <Accordion.Toggle as={Card.Header} eventKey={`${index}`}>
                                {prCategory.name}
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey={`${index}`}>
                                <Card.Body>
                                    <Table size="sm">
                                        <thead>
                                            <tr>
                                                <th>Kód rodiny</th>
                                                <th>Název rodiny</th>
                                                <th>Kód PR čísla</th>
                                                <th>Název PR čísla</th>
                                                <th>Speciální test</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {localPrNumbers.filter(x => x.prNumCategoryId === prCategory.id)
                                                .map(prNumber => (
                                                    <tr>
                                                        <td>{prNumber.prNumFamilyCode}</td>
                                                        <td>{prNumber.prNumFamilyName}</td>
                                                        <td>{prNumber.prNumberCode}</td>
                                                        <td>{prNumber.prNumberName}</td>
                                                        <td>{prNumber.prNumSpecialText}</td>
                                                    </tr>
                                                ))}
                                        </tbody>
                                    </Table>    
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                        </Fragment>
                    ))}
            </Accordion>
        </Fragment>
    );
  };
  
  export default CarPrNumbersTab;