export interface IBlockingEvent {
  blockingEventId: number;
  eventGroupId?: string;
  expertGroupId?: number;
  blockingEventTypeId: number;
  blockingEventTypeName: string;
  wholeGroup: boolean;
  userId?: number;
  userIds: number[];
  titleBefore?: string;
  firstName?: string;
  surname?: string;
  titleAfter?: string;
  fullName?: string;
  shortcut?: string;
  color?: string;
  startDate: Date;
  endDate: Date;
  startTime?: string;
  endTime?: string;
  description: string;
  wholeDay: boolean;
  isDeleted: boolean;
  eventGroupStartDate?: Date;
  eventGroupEndDate?: Date;
  eventName?:string;
}

export interface IBlockingEventCreateForm {
  blockingEventTypeId: number;
  expertGroupId?: number;
  wholeGroup: boolean;
  userIds: number[];
  isRepeating: boolean;
  repeatingWeekdays: number[];
  repeatingWeeks: number[];
  startDate: Date;
  endDate: Date;
  startTime?: string;
  endTime?: string;
  description: string;
  wholeDay: boolean;
}

export interface IBlockingEventDeleteForm {
  blockingEventId: number;
  eventGroupId?: string;
  deleteType: UpdateTypes;
}

export interface IBlockingEventUpdateForm {
  blockingEventId: number;
  eventGroupId?: string;
  blockingEventTypeId: number;
  expertGroupId?: number;
  wholeGroup: boolean;
  userIds: number[];
  isRepeating: boolean;
  repeatingWeekdays: number[];
  repeatingWeeks: number[];
  startDate: Date;
  endDate: Date;
  startTime?: string;
  endTime?: string;
  description: string;
  wholeDay: boolean;
  updateType: UpdateTypes;
}

export enum UpdateTypes {
  updateSingle = 1,
  updateThisAndFuture = 2,
  updateAll = 3,
}


export interface IHolidaysAndCorporateVacation{
    corporateVacations: IBlockingEvent[];
    holidays: IHoliday[];
  }
  
  export interface IHoliday{
    date: string; //"yyyy-MM-dd"
    name: string;
  }

// export const convertIBlockingEventToIBlockingEventForm = (
//     event: IBlockingEvent
//   ): IBlockingEventForm => {
//     const form: IBlockingEventForm = (({
//       blockingEventId,
//       expertGroupId,
//       blockingEventTypeId,
//       wholeGroup,
//       userId,
//       startDate,
//       endDate,
//       startTime,
//       endTime,
//       description,
//       wholeDay,
//       isDeleted,
//     }) => ({
//       blockingEventId,
//       expertGroupId,
//       blockingEventTypeId,
//       wholeGroup,
//       userId,
//       startDate,
//       endDate,
//       startTime,
//       endTime,
//       description,
//       wholeDay,
//       isDeleted,
//     }))(event);
  
//     return form;
//   };
  
  