import React, { Fragment, useContext, useState } from "react";
import { Popover, Form, Button } from "react-bootstrap";
import { Form as FinalForm, Field } from "react-final-form";
import TextAreaInput from "../../../app/common/form/TextAreaInput";
import DateInput from "../../../app/common/form/DateInput";
import AlertMessage from "../../alert/AlertMessage";
import moment from "moment";
import { RootStoreContext } from "../../../app/stores/rootStore";
import {
  IBlockingEventCreateForm,
  IBlockingEventDeleteForm,
  IBlockingEventUpdateForm,
  UpdateTypes,
} from "../../../app/models/blockingEvent";

interface IProps {
  blockingEventId: number | undefined;
  startDate: Date | string | undefined;
  endDate: Date | string | undefined;
  description: string;
}

const CompanyVacationEditPopover: React.FC<IProps> = ({
  blockingEventId,
  startDate,
  endDate,
  description,
}) => {
  const [errors, setErrors] = useState([]);
  const [showSubmitError, setShowSubmitError] = useState(false);
  const rootStore = useContext(RootStoreContext);
  const {
    updateCompanyVacation,
    deleteCompanyVacation,
    createCompanyVacation,
  } = rootStore.blockingEventStore;
  const handleSubmitForm = (form: any) => {
    setErrors([]);
    setShowSubmitError(false);

    // zalozeni celozavodni dovolene
    if (!blockingEventId) {
      const blockingEventForm: IBlockingEventCreateForm = {
        blockingEventTypeId: 3,
        startDate: form.startDate,
        endDate: form.endDate,
        description: form.description,
        wholeDay: true,
        wholeGroup: true,
        userIds: [],
        isRepeating: false,
        repeatingWeekdays: [],
        repeatingWeeks: [],
        endTime: undefined,
        expertGroupId: undefined,
        startTime: undefined,
      };

      createCompanyVacation(blockingEventForm)
        .then(() => {
          // to close overlay popover workaround
          document.body.click();
        })
        .catch((error) => {
          console.log(error);
          setErrors(Object.values(error.response?.data?.errors));
          setShowSubmitError(true);
        });
    }
    // uprava celozavodni dovolene
    if (blockingEventId && !form.isDeleted) {
      const blockingEventForm: IBlockingEventUpdateForm = {
        blockingEventId: blockingEventId!,
        blockingEventTypeId: 3,
        startDate: form.startDate,
        endDate: form.endDate,
        description: form.description,
        wholeDay: true,
        wholeGroup: true,
        userIds: [],
        isRepeating: false,
        repeatingWeekdays: [],
        repeatingWeeks: [],
        endTime: undefined,
        expertGroupId: undefined,
        startTime: undefined,
        eventGroupId: undefined,
        updateType: UpdateTypes.updateSingle,
      };

      updateCompanyVacation(blockingEventForm)
        .then(() => {
          // to close overlay popover workaround
          document.body.click();
        })
        .catch((error) => {
          console.log(error);
          setErrors(Object.values(error.response?.data?.errors));
          setShowSubmitError(true);
        });
    }
    // smazani celozavodni dovolene
    if (blockingEventId && form.isDeleted) {
      const blockingEventForm: IBlockingEventDeleteForm = {
        blockingEventId: blockingEventId!,
        eventGroupId: undefined,
        deleteType: UpdateTypes.updateSingle,
      };

      deleteCompanyVacation(blockingEventForm)
        .then(() => {
          // to close overlay popover workaround
          document.body.click();
        })
        .catch((error) => {
          console.log(error);
          setErrors(Object.values(error.response?.data?.errors));
          setShowSubmitError(true);
        });
    }
  };

  return (
    <Fragment>
      <Popover.Title as="h3">
        {blockingEventId && "Celozávodní dovolená"}
        {!blockingEventId && "Nová celozávodní dovolená"}
      </Popover.Title>
      <Popover.Content className="calendarday__popover calendarday__popover--vacation">
        <AlertMessage
          type="danger"
          heading="Nastala chyba při ukládání"
          show={showSubmitError}
          setShow={setShowSubmitError}
          messageList={errors}
        />
        <FinalForm
          onSubmit={handleSubmitForm}
          mutators={{
            setIsDeleted: (args, state, utils) => {
              utils.changeValue(state, "isDeleted", () => args[0]);
            },
          }}
          validate={(values) => {
            const errors = {} as any;
            if (!values.startDate) {
              errors.startDate = "Začátek musí být vyplněn";
            }
            if (!values.endDate) {
              errors.endDate = "Konec musí být vyplněn";
            }
            if (
              values.startDate &&
              values.endDate &&
              values.startDate > values.endDate
            ) {
              errors.startDate = "Začátek nesmí být po konci";
              errors.endDate = "Konec nesmí být před začátek";
            }
            if (!values.description) {
              errors.description = "Popis musí být vyplněn";
            }
            return errors;
          }}
          render={({ handleSubmit, values, form }: any) => (
            <Form onSubmit={handleSubmit} id="vacationedit-form">
              <Field
                name="startDate"
                title="Začátek"
                // inialValue={startDate}
                defaultValue={startDate}
                format={(val) => (val ? moment(val).toDate() : undefined)}
                parse={(val: any) => (val ? moment(val).toDate() : undefined)}
                component={DateInput}
              />
              <Field
                name="endDate"
                title="Konec"
                defaultValue={endDate}
                format={(val) => (val ? moment(val).toDate() : undefined)}
                parse={(val: any) => (val ? moment(val).toDate() : undefined)}
                component={DateInput}
              />
              <Field<string>
                name="description"
                title="Popis"
                initialValue={description}
                component={TextAreaInput}
              />
              <hr />
              <div className="editpopover__footer flex-row flex-center">
                <Button className="editpopover__button" type="submit">
                  Uložit
                </Button>
                {blockingEventId && (
                  <Button
                    className="editpopover__button"
                    variant="outline-danger"
                    type="button"
                    onClick={() => {
                      form.mutators.setIsDeleted(true);
                      document
                        ?.getElementById("vacationedit-form")
                        ?.dispatchEvent(
                          new Event("submit", { cancelable: true })
                        );
                    }}
                  >
                    Smazat
                  </Button>
                )}
              </div>
            </Form>
          )}
        />
      </Popover.Content>
    </Fragment>
  );
};

export default CompanyVacationEditPopover;
