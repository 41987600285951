import React, { Fragment } from "react";
import { Row, Col } from "react-bootstrap";
import { Field } from "react-final-form";
import NumberInput from "../../../app/common/form/NumberInput";
import DateTimeInput from "../../../app/common/form/DateTimeInput";
import { IEnums, CarStates } from "../../../app/models/enum";
import { canSetEmissionTest, ICar, NewCar } from "../../../app/models/car";
import { FormApi } from "final-form";
import moment from "moment";
import { getDaysString } from "../../../app/common/utils/czechDeclension";
import "./TestRoomTab.scss";
import DateInput from "../../../app/common/form/DateInput";

interface IProps {
  enums: IEnums | undefined;
  edit: boolean;
  values: ICar | NewCar;
  form: FormApi<ICar | NewCar>;
}

const TestRoomTab: React.FC<IProps> = ({ enums, edit, values }) => {
  return (
    <div id="testroom-tab">
      {values.carPlaceHistories && values.carPlaceHistories.map((placeHitory, index) => (
        <Fragment>
          { index > 0 && ( <hr /> )}
          <div className="testroom">
            <h3 style={{ marginLeft: "4rem" }}>Zkušebna {placeHitory.placeName}</h3>
            <Row>
              <Col md={6}>
                <Field<Date>
                  name={`carPlaceHistories[${index}].availableFrom`}
                  title="Dispozice od"
                  initialValue={placeHitory.availableFrom}
                  format={(val) => (val ? moment(val).toDate() : undefined)}
                  component={DateTimeInput}
                  disabled={true}
                />
                <Field<Date>
                  name={`carPlaceHistories[${index}].availableTo`}
                  title="Dispozice do"
                  initialValue={placeHitory.availableTo}
                  format={(val) => (val ? moment(val).toDate() : undefined)}
                  component={DateTimeInput}
                  disabled={true}
                />
                <Field<number>
                  name={`carPlaceHistories[${index}].daysInTestRoom`}
                  title="Na zkušebně"
                  initialValue={placeHitory.daysInTestRoom}
                  component={NumberInput}
                  min={0}
                  parse={(value) => value && parseInt(value)}
                  rightAppend={getDaysString(placeHitory.daysInTestRoom || 0)}
                  disabled={true}
                />
              </Col>

              <Col md={6}>
                <Field
                  name={`carPlaceHistories[${index}].plannedFrom`}
                  title="Plánováno od"
                  initialValue={placeHitory.plannedFrom}
                  format={(val) => (val ? moment(val).toDate() : undefined)}
                  component={DateTimeInput}
                  disabled={true}
                />
                <Field
                  name={`carPlaceHistories[${index}].plannedTo`}
                  title="Plánováno do"
                  initialValue={placeHitory.plannedTo}
                  format={(val) => (val ? moment(val).toDate() : undefined)}
                  component={DateTimeInput}
                  disabled={true}
                />
                <Field<number>
                  name={`carPlaceHistories[${index}].plannedDaysInTestRoom`}
                  title="Plánováno na zkušebně"
                  initialValue={placeHitory.plannedDaysInTestRoom}
                  component={NumberInput}
                  min={0}
                  rightAppend={getDaysString(placeHitory.plannedDaysInTestRoom || 0)}
                  parse={(value) => value && parseInt(value)}
                  disabled={true}
                />
              </Col>
            </Row>
          </div>
          
        </Fragment>
      ))}
      
      
      {canSetEmissionTest(values.testDestinationId, values.customerCode) &&
        values.emissionTest && (
          <Fragment>
            <hr />
            <div className="testroom">
              <h3 style={{ marginLeft: "4rem" }}>Emise</h3>
              <Row>
                <Col md={6}>
                  <Field<Date>
                    name="emissionTestDateFrom"
                    title="Emise od"
                    format={(val) => (val ? moment(val).toDate() : undefined)}
                    component={DateTimeInput}
                    disabled={true}
                  />
                  <Field<Date>
                    name="emissionTestDateTo"
                    title="Emise do"
                    format={(val) => (val ? moment(val).toDate() : undefined)}
                    component={DateTimeInput}
                    disabled={true}
                  />
                </Col>
                <Col md={6}>
                  <Field
                    name="emissionTestPlanFrom"
                    title="Plán emisí od"
                    format={(val) => (val ? moment(val).toDate() : undefined)}
                    component={DateInput}
                    disabled={!edit}
                  />
                  <Field
                    name="emissionTestPlanTo"
                    title="Plán emisí do"
                    format={(val) => (val ? moment(val).toDate() : undefined)}
                    component={DateInput}
                    disabled={!edit}
                  />
                </Col>
              </Row>
            </div>
          </Fragment>
        )}
    </div>
  );
};

export default TestRoomTab;
