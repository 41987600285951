import "./UserStatus.scss";
import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faUserSlash } from "@fortawesome/free-solid-svg-icons";

interface IProps {
  id: number;
  isActive: boolean;
}

const UserStatus: React.FC<IProps> = ({ id, isActive }) => {
  const tooltipText = isActive ? "aktivní" : "neaktivní";
  const type = isActive ? "active" : "inactive";

  return (
    <div id={`user-icon-${id}`}>
      <OverlayTrigger
        placement="right"
        overlay={
          <Tooltip id={`tooltip-user-icon-${id}`}>{tooltipText}</Tooltip>
        }
      >
        <FontAwesomeIcon
          icon={isActive ? faUser : faUserSlash}
          className={`icon icon-userstatus--${type}`}
        />
      </OverlayTrigger>
    </div>
  );
};

export default UserStatus;
