import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { observer } from "mobx-react-lite";
import moment from "moment";
import React, { useContext, useEffect } from "react";
import { useState } from "react";
import { Button, Col } from "react-bootstrap";
import { RootStoreContext } from "../../../app/stores/rootStore";
import MonthCalendar from "../calendar/MonthCalendar";
import "./CompanyVacationTab.scss";

interface IProps {}

const CompanyVacationTab: React.FC<IProps> = () => {
  let currentDate = new Date();
  let currentYear = currentDate.getFullYear();

  const months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  const [year, setYear] = useState(currentYear);

  const rootStore = useContext(RootStoreContext);
  const { loadHolidaysAndVacations } = rootStore.blockingEventStore;
  const { vacations, holidays } = rootStore.blockingEventStore;

  useEffect(() => {
    loadHolidaysAndVacations(year);
  }, [year,loadHolidaysAndVacations]);

  return (
    <div>
      <div className=" vacation-header flex flex-row flex-wrap">
       <Col xl={9} lg={8} md={12}><h1>Přehled státních svátků a celozávodních dovolených</h1></Col> 
        <Col xl={3}  lg={4} md={12} className="h1">
            <Button
              variant="link"
              size="sm"
              type="button"
              onClick={() => setYear(year - 1)}
              className="no-print"
            >
              <FontAwesomeIcon icon={faChevronLeft} className="" />
            </Button>
            <span className="text-primary" style={{ padding: "0rem 1.1rem" }}>
              {year}
            </span>
            <Button
              variant="link"
              type="button"
              size="sm"
              className="no-print"
              onClick={() => setYear(year + 1)}
            >
              <FontAwesomeIcon icon={faChevronRight} className="" />
            </Button>
        </Col>
      </div>
      <div className="calendars-container">
        {months.map((month) => {
          // generate twelve month calendars
          const date = moment(`${year}-${("0" + month).slice(-2)}-01`);
          return (
            <Col xl={4} md={6} sm={12} key={month}>
              <MonthCalendar
                year={year}
                month={month}
                vacations={vacations.filter(
                  (v) =>
                    date.isSameOrBefore(moment(v.endDate), "month") &&
                    date.isSameOrAfter(moment(v.startDate), "month")
                )}
                holidays={holidays.filter((h) =>
                  moment(h.date).isSame(date, "month")
                )}
              />
            </Col>
          );
        })}
      </div>
    </div>
  );
};

export default observer(CompanyVacationTab);
