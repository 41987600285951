import React from "react";
import "./FullCalendarBlockingEvent.scss";
import { IBlockingEvent, IHoliday } from "../../app/models/blockingEvent";

interface IProps {
  event: {
    allDay: boolean;
    start: Date;
    end: Date;
    extendedProps: {
      blockingEvent: IBlockingEvent | undefined;
      holiday: IHoliday | undefined;
      history: any;
    };
  };
}

const FullCalendarVacationEvent: React.FC<IProps> = ({ event }) => {
  const { blockingEvent, holiday } = event.extendedProps;

  return (
    <div
      className={`fullcalendar-blockingevent fullcalendar-blockingevent--vacation`}
    >
      <div className="fullcalendar-blockingevent__row">
        <div className="fullcalendar-blockingevent__type fullcalendar-blockingevent-section">
          {blockingEvent && blockingEvent.blockingEventTypeName}
          {holiday && "Státní svátek"}
        </div>
        <div className="fullcalendar-blockingevent__time fullcalendar-blockingevent-section">
          Celý den
        </div>
      </div>
      <div className="fullcalendar-blockingevent__row">
        <div className="fullcalendar-blockingevent__description fullcalendar-blockingevent-section">
          {blockingEvent && blockingEvent.description}
          {holiday && holiday.name}
        </div>
      </div>
    </div>
  );
};

export default FullCalendarVacationEvent;
