import React from 'react';
import {Field, useFormState} from "react-final-form";
import CheckboxGroupInput from "../../../../app/common/form/CheckboxGroupInput";
import {IDefect, IRidingNoisesProtocol} from "../../../../app/models/protocol";
import {Col, Row} from "react-bootstrap";
import {useLocation} from "react-router-dom";

interface IProps {
    isEdit: boolean,
    isDisabled: boolean,
    defect: IDefect
}

const RidingNoisesDefectsExtension: React.FC<IProps> = ({isDisabled, isEdit, defect}) => {
    const state = useFormState<IRidingNoisesProtocol>();
    const partRules = state.values.executedTestArea?.partRules;
    const {pathname} = useLocation();
    const isRidingNoises = pathname.toLowerCase().includes('RidingNoisesProtocol'.toLowerCase())
    if (!isRidingNoises) {
        return <></>
    }
    return (
        <tr className={`${isDisabled ? "disabled" : ""}`}>
            <td colSpan={9} style={{verticalAlign: "top"}}>
                <Row noGutters>
                    {partRules?.map((partRule, index) =>
                        <Col sm={2} key={index}>
                            <Field
                                key={`defectsArea.defects.${defect.rownumber}.selectedProtocolPartIds.${index}`}
                                name={`defectsArea.defects.${defect.rownumber}.selectedProtocolPartIds`}
                                type="checkbox"
                                id={partRule.protocolPartId}
                                value={partRule.protocolPartId}
                                //initialValue={defect.selectedProtocolPartIds.includes(partRule.protocolPartId || 0)}
                                title={partRule.name}
                                component={CheckboxGroupInput}
                                disabled={!isEdit || isDisabled}
                            />
                        </Col>
                    )}
                </Row>
                <Row noGutters>
                    <Field<boolean>
                        name={`defectsArea.defects.${defect.rownumber}.systemKKV`}
                        type="checkbox"
                        title={'KKV'}
                        component={CheckboxGroupInput}
                        disabled={!isEdit || isDisabled}
                    />
                </Row>
            </td>
        </tr>
    );
};

export default RidingNoisesDefectsExtension;
