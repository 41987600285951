import React, { Fragment, useState } from "react";
import { Row } from "react-bootstrap";
import { Table } from "react-bootstrap";
import { observer } from "mobx-react-lite";
import moment from "moment";
import Loader from "../../loader/Loader";
import Pages from "../../../app/common/utils/Pages";
import PrNumbersSearchBox from "./PrNumbersSearchBox"
import { usePrNumbersQuery, useLoadEnumsQuery } from "../../../app/api/client";

const PrNumbersTab: React.FC = () => {

    const [searchFamily, setSearchFamily] = useState<string>();
    const [prNumCategoryId, setPrNumCategoryId ] = useState<number | undefined>();
    const [searchPrNumber, setSearchPrNumber] = useState<string | undefined>()
    
    const [pageNumber, setPageNumber] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(20);
    const cancelSearch = searchFamily !== undefined || prNumCategoryId !== undefined || searchPrNumber !== undefined;

    const {
        data: prNumbersList,
        isFetching: isLoading
    } = usePrNumbersQuery(pageNumber, pageSize, searchFamily, searchPrNumber, prNumCategoryId);
    const {data: enums, isFetching: loadingEnums} = useLoadEnumsQuery();

    const LoadPrNumbers = (page: number)=> {
        setPageNumber(page);
    };

    const searchPrNumbers = (searchFamily?: string, searchPrNumber?: string, prNumCategoryId?: number | undefined) => {
        setSearchFamily(searchFamily);
        setPrNumCategoryId(prNumCategoryId);
        setSearchPrNumber(searchPrNumber);
        setPageNumber(1);
    };

    const cancelSearchPrNumbers = () => {
        setSearchFamily(undefined);
        setPrNumCategoryId(undefined);
        setSearchPrNumber(undefined);
        setPageNumber(1);
    }

    if (isLoading) return <Loader/>;

    return (
        <Fragment>
            <Row className="pageheader">
                <div className="pageheader__left col-sm-3 vertical-align">
                    <h3>PR čísla</h3>
                </div>
                <div className="pageheader__right col-sm-9">
                    <PrNumbersSearchBox searchFamily={searchFamily}
                                        searchPrNumber={searchPrNumber}
                                        searchPrNumCategoryId={prNumCategoryId}
                                        cancelSearch={cancelSearch}
                                        prNumCategories={enums?.prNumberCategories}
                                        handleSearch={searchPrNumbers}
                                        handleCancelSearch={cancelSearchPrNumbers} />
                </div>
            </Row>
            <div>
                <Table>
                    <thead>
                        <tr>
                            <th>Kód rodiny</th>
                            <th>Název rodiny</th>
                            <th>Kategorie</th>
                            <th>PR číslo</th>
                            <th>Popis</th>
                            <th>Aktualizace</th>
                        </tr>
                    </thead>
                    <tbody>
                    {prNumbersList && prNumbersList?.prNumbers && prNumbersList.prNumbers.map((prNumber, index) => 
                    (
                        <tr key={prNumber.prNumberId}>
                            <td>{prNumber.prFamilyCode}</td>
                            <td>{prNumber.prFamilyName}</td>
                            <td>{prNumber.prCategoryName}</td>
                            <td>{prNumber.prNumberCode}</td>
                            <td>{prNumber.prNumberDesc}</td>
                            <td>{moment(prNumber.updDate).format("DD.MM.YYYY")}</td>
                        </tr>
                    ))}
                    </tbody>
                </Table>
            </div>  
            {prNumbersList && 
                <footer className="footer">
                    <div className="footer-left">
                    </div>
                    <div className="footer-right">
                    <Pages
                        totalPages={prNumbersList.totalPages}
                        currentPage={prNumbersList.currentPage}
                        onClickEvent={LoadPrNumbers}
                    />
                    </div>
                </footer>
            }
        </Fragment>

    );
};

export default observer(PrNumbersTab);