import { observer } from "mobx-react-lite";
import React, { Fragment, useState } from "react";
import { Col, Row } from "react-bootstrap";
import LabelValuePair from "../../../../app/common/utils/LabelValuePair";
import VinCode from "../../../vin/VinCode";
import { DATE_FORMAT } from "../../../../app/common/utils/constants";
import moment from "moment";
import { ICar } from "../../../../app/models/car";
import { ICarTest } from "../../../../app/models/carTest";
import "./ProtocolHeaderCarInfo.scss"

interface IProps {
    car: ICar | undefined;
    carTest: ICarTest | undefined;
    measurementDate: Date | undefined;
}

const ProtocolHeaderCarInfo: React.FC<IProps> = ({
    car, carTest, measurementDate
}) => {
    const labelWidth = 4;
    const valueWidth = 8;
    var measureDate : Date | undefined = undefined;
    if(carTest) measureDate = carTest?.startAt
    else measureDate = measurementDate;
    return (
        <Row className="carinfo" id="car-info-header">
            <Col sm={4}>
                <LabelValuePair
                    label="Model vozu"
                    value={
                        (car?.modelVdsCode || "") +
                        " " +
                        (car?.modelName || "")
                    }
                    labelColSize={labelWidth}
                    valueColSize={valueWidth}
                />
                <LabelValuePair
                    label="VIN kód"
                    value={<VinCode vin={car?.vin} />}
                    labelColSize={labelWidth}
                    valueColSize={valueWidth}
                />
                <LabelValuePair
                    label="VDS"
                    value={car?.integrationNum}
                    labelColSize={labelWidth}
                    valueColSize={valueWidth}
                />
                <LabelValuePair
                    label="Log. číslo"
                    value={car?.logisticNum}
                    labelColSize={labelWidth}
                    valueColSize={valueWidth}
                />
                <LabelValuePair
                    label="Modelový klíč"
                    value={car?.modelKey}
                    labelColSize={labelWidth}
                    valueColSize={valueWidth}
                />
                <LabelValuePair
                    label="Barva"
                    value={car?.colorCode}
                    labelColSize={labelWidth}
                    valueColSize={valueWidth}
                />
            </Col>
            <Col sm={4}>
                <LabelValuePair
                    label="Motor"
                    value={car?.engineName}
                    labelColSize={labelWidth}
                    valueColSize={valueWidth}
                />
                <LabelValuePair
                    label="KNR kód"
                    value={car?.prodIdentificationNum}
                    labelColSize={labelWidth}
                    valueColSize={valueWidth}
                />
                <LabelValuePair
                    label="Emisní norma"
                    value={car?.emissionStandard}
                    labelColSize={labelWidth}
                    valueColSize={valueWidth}
                />
                <LabelValuePair
                    label="Převodovka"
                    value={car?.transmissionCode}
                    labelColSize={labelWidth}
                    valueColSize={valueWidth}
                />
                <LabelValuePair
                    label="Pohon"
                    value={car?.wheelDrive}
                    labelColSize={labelWidth}
                    valueColSize={valueWidth}
                />
                <LabelValuePair
                    label="Řízení"
                    value={car?.typeOfControl}
                    labelColSize={labelWidth}
                    valueColSize={valueWidth}
                />
            </Col>
            <Col sm={4}>
                <LabelValuePair
                    label="Typ vozu"
                    value={car?.testDestinationCode}
                    labelColSize={labelWidth}
                    valueColSize={valueWidth}
                />
                <LabelValuePair
                    label="Odběratel"
                    value={car?.customerCode}
                    labelColSize={labelWidth}
                    valueColSize={valueWidth}
                />
                <LabelValuePair
                    label="Typ zkoušky"
                    value={car?.testTypeName}
                    labelColSize={labelWidth}
                    valueColSize={valueWidth}
                />
                <LabelValuePair
                    label="SOP"
                    value={car?.startOfProduction}
                    labelColSize={labelWidth}
                    valueColSize={valueWidth}
                />
                <LabelValuePair
                    label="Modelový rok"
                    value={car?.modelYear}
                    labelColSize={labelWidth}
                    valueColSize={valueWidth}
                />
                <LabelValuePair
                    label="Datum měření"
                    value={moment(
                        measureDate
                    ).format(DATE_FORMAT)}
                    labelColSize={labelWidth}
                    valueColSize={valueWidth}
                />
            </Col>
        </Row>
    )
};

export default observer(ProtocolHeaderCarInfo);
