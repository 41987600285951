import React, {FC, useContext} from 'react';
import {addOrUpdateDefect, handleModal} from "./defectUtils";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCommentAlt} from "@fortawesome/free-regular-svg-icons";
import {Button} from "react-bootstrap";
import {IDefect, IGenericProtocol, IProtocolPartRule} from "../../../../../app/models/protocol";
import {useForm, useFormState} from "react-final-form";
import {observer} from "mobx-react-lite";
import {RootStoreContext} from "../../../../../app/stores/rootStore";
import {useLoadEnumsQuery} from "../../../../../app/api/client";

interface IProps {
    partRule: IProtocolPartRule;
    isEdit: boolean;
    isDisabled: boolean
}

const DefectButton: FC<IProps> = (
    {
        partRule,
        isEdit,
        isDisabled
    }
) => {
    const {data: enums,} = useLoadEnumsQuery();
    const {modalStore} = useContext(RootStoreContext);
    const {openModal} = modalStore;
    const protocol = useFormState<IGenericProtocol>().values;
    const defects = protocol.defectsArea?.defects || [];
    const form = useForm<IGenericProtocol>();
    const change = form.change;
    const setDefects = (defects: IDefect[]) => {
        change('defectsArea.defects', defects)
    }
    const handleDefect = (defect: IDefect) => {
        addOrUpdateDefect(defect, defects, setDefects)
        form.mutators.calculateDefects();
    }
    if (!enums) return <></>;
    return (
        <>
            <Button
                size="sm"
                style={{width: "3em"}}
                variant="primary"
                title="Komentáře k závadám"
                onClick={() => handleModal(
                    partRule,
                    defects,
                    protocol.carTest.expertGroupCode,
                    protocol.carTest.startAt || new Date(),
                    openModal,
                    isEdit,
                    handleDefect,
                    enums,
                )}
                disabled={isDisabled}

            >
                <FontAwesomeIcon icon={faCommentAlt}/>
            </Button>
        </>
    );
};

export default observer(DefectButton);
