import { IProtocolPartRule } from "../../../app/models/protocol";

export const validatePartRules = (ruleValues: IProtocolPartRule) : boolean | undefined => {
    let results: boolean[] = [];
    var result: boolean | undefined = undefined;
    ruleValues.measuredValues?.map((value, index) => {
      var result = validateRule(ruleValues, value, index);
      if(result !== undefined){
        results.push(!!result);
      }
    });
    if(ruleValues.ruleValues && results.length < ruleValues.ruleValues?.length)
    {
      return undefined;
    }

    if(results.includes(true)){
      result = true;
    }
    else{
      result = false;  
    }
    return result;
}

export const validateRule = (partRule: IProtocolPartRule, value: string, ruleValueIndex: number ): Boolean | undefined => {

    // bez hodnoty netestovat
    if (!value) {
      return undefined;
    }
    // pravidlo se nevyhodnocuje
    if(!partRule.mathEvaluate) {
      return undefined;
    }

    // text misto cislice
    var regExp = /[a-zA-Z]/g;
    var numericValue = Number.parseFloat(value.replace(',','.').replace(' ','') || "0.00");
    if (numericValue === undefined || regExp.test(value)) {
        return undefined;
    }
    
    if(partRule.ruleValues && partRule.ruleValues.length > 0){
      let rule = partRule.ruleValues[ruleValueIndex];
      let min = rule.min ? rule.min : 0;
      let max = rule.max ? rule.max : 0;
      if (partRule.functionCode === 'min.' && min > numericValue) {
        return true;
      }
      if (partRule.functionCode === 'max.' && max < numericValue) {
        return true;
      }
      if (partRule.functionCode === '<' && max <= numericValue) {
        return true;
      }
      if (partRule.functionCode === '>' && min >= numericValue) {
        return true;
      }
      if ((partRule.functionCode === 'X – Y' || partRule.functionCode === 'X ± Y') && (min > numericValue || max < numericValue))
      {
        return true;
      }
    }
    return false;
};

export const CheckAllItemsEmpty = (array: string[] | undefined ): Boolean => {
    if(array){
      for(var i=0; i<array.length; i++) {
        if(array[i] !== undefined && array[i].trim() !== "") return false;
      }
      return true;
    }
    return true;
};