import React, { Fragment } from "react";
import { Form, Row, Col } from "react-bootstrap";
import { FieldRenderProps } from "react-final-form";

type Props = FieldRenderProps<any, any>;

interface IOption {
  key: number | string;
  value: string;
  isDisabled?: boolean;
}

const SelectInput: React.FC<Props> = ({
  input,
  meta,
  title,
  options,
  warning,
  placeholder,
  isEmptyOption,
  inputOnChange,
  ...rest
}: Props) => {

  const errorMessage = (meta.error || meta.submitError) && meta.touched && (
    <Form.Control.Feedback type="invalid">
      {meta.error || meta.submitError}
    </Form.Control.Feedback>
  );

  return (
    <Fragment>
      {title &&  <Form.Group controlId={input.name}>
      <Row>
        <Col xl={4} lg={4} md={4} sm={4} xs={4} className="u-text-right">
          {title && <Form.Label>{title}</Form.Label>}
        </Col>
        <Col xl={8} lg={8} md={8} sm={8} xs={8}>
          <Form.Control
            as="select"
            placeholder={placeholder}
            {...input}
            onChange={(e:any)=>{input.onChange(e); inputOnChange && inputOnChange(e);}}
            className={warning && !(meta.touched && meta.error) ? "is-warning" : ""}
            isInvalid={meta.touched && !!meta.error}
            value={input.value.toString()}
            {...rest}
          >
            {isEmptyOption && <option></option>}
            {options &&
              options.map((option: IOption) => (
                <option key={option.key} value={option.key} disabled={option.isDisabled}>
                  {option.value}
                </option>
              ))}
          </Form.Control>
        </Col>
      </Row>
      {errorMessage}
    </Form.Group>}
    {!title && (
      <Form.Group controlId={input.name}>
      <Form.Control
      as="select"
      size="sm"
      {...input}
      onChange={(e:any)=>{input.onChange(e); inputOnChange && inputOnChange(e);}}
      className={warning && !(meta.touched && meta.error) ? "is-warning" : ""}
      isInvalid={meta.touched && !!meta.error}
      value={input.value.toString()}
      {...rest}
    >
      {isEmptyOption && <option></option>}
      {options &&
        options.map((option: IOption) => (
          <option key={option.key} value={option.key} disabled={option.isDisabled}>
            {option.value}
          </option>
        ))}
    </Form.Control>
    {errorMessage}
    </Form.Group>
    )}
    </Fragment>
   
  );
};

export default SelectInput;
