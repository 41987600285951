import React, { Fragment } from "react";
import { Table } from "react-bootstrap";
import { Field } from "react-final-form";
import { FormApi } from "final-form";
import { IProtocolArea, IEecProtocol } from "../../../../app/models/protocol";
import TextInput from "../../../../app/common/form/TextInput"

interface IProps {
  methodConductingArea: IProtocolArea;
}

const MethodConductingArea: React.FC<IProps> = ({
  methodConductingArea
}) => {

    return (
      <Fragment>
        <Table className="protocol-table">
        <thead>
          <tr>
            <th className="headingrow">
              <h2>{methodConductingArea?.name}</h2>
            </th>
          </tr>
        </thead>
      </Table>
      <p>{methodConductingArea?.additionalInfo}</p>
      </Fragment>
  );
};

export default MethodConductingArea;
