import React, { Fragment, useState, useContext } from "react";
import { Row, Button } from "react-bootstrap";
import { Field, Form as FinalForm } from "react-final-form";
import { Table } from "react-bootstrap";
import { observer } from "mobx-react-lite";
import Loader from "../../loader/Loader";
import Pages from "../../../app/common/utils/Pages";
import AlertMessage from "../../alert/AlertMessage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import TextInput from "../../../app/common/form/TextInput";
import NumberInput from "../../../app/common/form/NumberInput";
import { toast } from "react-toastify";
import Can from "../../../authorization/Can";
import { RootStoreContext } from "../../../app/stores/rootStore";
import { IPrCategoryForm } from "../../../app/models/prNumber"
import NewPrCategoryForm from "./NewPrCategoryForm"
import { usePrNumCategoriesQuery, useUpsertPrNumCategoryMutation, useDeletePrNumCategoryMutation, useLoadEnumsQuery } from "../../../app/api/client";

const PrNumbersCategoriesTab: React.FC = () => {

    const rootStore = useContext(RootStoreContext);
    const { user } = rootStore.authStore;
    const { openModal, openDialog } = rootStore.modalStore;
  
    const [pageNumber, setPageNumber] = useState<number>(1);
    const [pageSize ] = useState<number>(20);
  
    const [ editedItemId, setEditedItemId ] = useState<number | undefined>();
    const [ errors, setErrors ] = useState([]);
    const [ showSubmitError, setShowSubmitError ] = useState(false);

    const {isFetching: loadingEnums, refetch: refetchEnums } = useLoadEnumsQuery();
    const {
        data: prNumCategoriesList,
        isFetching: isLoading,
        refetch: refetchPrNumCategories
    } = usePrNumCategoriesQuery(pageNumber, pageSize);
    const { mutateAsync: upsetrPrNumCategoryMutation, isLoading: isMutating } = useUpsertPrNumCategoryMutation();
    const { mutateAsync: deletePrNumCategoryMutation, isLoading: isDeleteMutating } = useDeletePrNumCategoryMutation();

    const LoadPrNumCategories = (page: number)=> {
        setPageNumber(page);
        refetchPrNumCategories();
    };

    const handleAddPrCategoryClick = () => {
        openModal(<NewPrCategoryForm handleCreatePrCategory={handleEditPrCategory} />, {
          preventClosing: true,
          title: "Přidání kategorie PR čísel",
        });
        return;
      };

    const handleEditPrCategory = (form: IPrCategoryForm) => {

        setErrors([]);
        setShowSubmitError(false);
  
        upsetrPrNumCategoryMutation(form)
            .then((response) => {
                //console.log(form);
                setEditedItemId(undefined);
                LoadPrNumCategories(pageNumber);
                refetchEnums();
                toast.success(`Kategorie byla uložena`);
            })
            .catch((error) => {
                console.log(error);
                if(error.response !== undefined && error.response.data !== undefined && error.response.data.errors !== undefined){
                    setErrors(Object.values(error.response?.data?.errors));
                    setShowSubmitError(true);
                }
                toast.error(`Vyskytla se chyba při uložení kategorie PR čísla`);
            });
    };

    const handleDeletePrNumCategory = (prNumCategoryId: number, prNumCategoryName: string) => {
        setErrors([]);
        setShowSubmitError(false);

        openDialog(
            <div>
                <p>{`Při odstranění kategorie ${prNumCategoryName} dojde k odstranění vazby s PR rodinami. Chcete opravdu odstranit kategorii PR čísel?`}</p>
            </div>,
            {
              title: "Odstranění kategorie PR čísel",
              confirmButtonLabel: "Ano, odstranit",
              cancelButtonLabel: "Zpět",
              onConfirm: () => {
                //console.log(prNumCategoryId);
                
                deletePrNumCategoryMutation(prNumCategoryId)
                .then(() => {
                    LoadPrNumCategories(pageNumber);
                    refetchEnums();
                    toast.success(`Odstranění kategorie PR čísel proběhlo úspěšně.`);
                })
                .catch((error: any) => {
                    toast.error("Při odstranění kategorie PR čísel nastala chyba!");
                    if(error.response !== undefined && error.response.data !== undefined && error.response.data.errors !== undefined){
                        setErrors(Object.values(error.response?.data?.errors));
                        setShowSubmitError(true);
                    }
                });
                
              },
              onCancel: () => {},
            }
          );
    };

    if (loadingEnums || isLoading || isMutating || isDeleteMutating) return <Loader/>;

    return (
        <Fragment>
            <Row className="pageheader">
                <div className="pageheader__left col-sm-10">
                    <h3>Kategorie PR čísel</h3>
                </div>
                <div className="pageheader__right col-sm-2">
                    <Button
                        variant="primary"
                        className="footer__btn-add"
                        onClick={handleAddPrCategoryClick}
                    >
                        <FontAwesomeIcon icon={faPlus} />
                    </Button>
                </div>
            </Row>
            <div>
                <AlertMessage
                    type="danger"
                    heading="Nastala chyba při ukládání"
                    show={showSubmitError}
                    setShow={setShowSubmitError}
                    messageList={errors}
                />
                <Table>
                    <thead>
                        <tr>
                            <th>Název</th>
                            <th>Popis</th>
                            <th>Pořadí</th>
                            <th className="td-fixed-width" style={{width: "20rem"}}></th>
                        </tr>
                    </thead>
                    <tbody>
                    {prNumCategoriesList && prNumCategoriesList?.prNumCategories && prNumCategoriesList.prNumCategories.map((prNumCategory, index) => 
                    (
                        <tr key={prNumCategory.prCategoryId} className={ editedItemId === prNumCategory.prCategoryId ? "active show-btn-on-hover" : "show-btn-on-hover"}>

                            {editedItemId === prNumCategory.prCategoryId && (
                                    <FinalForm
                                    onSubmit={handleEditPrCategory}
                                    render={({ handleSubmit }) => (
                                        <Fragment>
                                        <td>
                                            <Field<number>
                                                name={`prCategoryId`}
                                                initialValue={prNumCategory.prCategoryId}
                                                component="input"
                                                hidden
                                                disabled
                                            />
                                            <Field
                                                name={`name`}
                                                initialValue={prNumCategory.name}
                                                component={TextInput}
                                                size={"sm"}
                                            />
                                        </td>
                                        <td>
                                            <Field
                                                name={`description`}
                                                initialValue={prNumCategory.description}
                                                component={TextInput}
                                                size={"sm"}
                                            />
                                        </td>
                                        <td>
                                            <Field
                                                name={`order`}
                                                initialValue={prNumCategory.order}
                                                component={NumberInput}
                                                size={"sm"}
                                            />
                                        </td>
                                        <td className="td-fixed-width" style={{width: "20rem"}}>
                                        <Can
                                            roleId={user?.userRoleId}
                                            perform="settings-prNumbers:edit"
                                            yes={
                                            <Fragment>
                                                <Button
                                                    type="submit"
                                                    size="sm"
                                                    onClick={handleSubmit}
                                                    style={{ marginRight: "0.1rem" }}
                                                    variant="primary"
                                                    >
                                                    {"Ulož"}
                                                </Button>
                                                <Button
                                                    type="button"
                                                    size="sm"
                                                    onClick={() => setEditedItemId(undefined)}
                                                    variant="outline-secondary"
                                                    >
                                                    {"Zpět"}
                                                </Button>   
                                            </Fragment>
                                            }
                                        />
                                            
                                        </td>
                                        </Fragment>
                                    )}
                                    />
                                )}

                            {editedItemId !== prNumCategory.prCategoryId && (
                                    <Fragment>
                                        <td>{prNumCategory.name}</td>
                                        <td>{prNumCategory.description}</td>
                                        <td>{prNumCategory.order}</td>
                                        <td className="td-fixed-width" style={{width: "20rem"}}>
                                            <Can
                                                roleId={user?.userRoleId}
                                                perform="settings-prNumbers:edit"
                                                yes={    
                                                    <Fragment>
                                                        <Button
                                                            className="btn-show-on-hover"
                                                            onClick={() => setEditedItemId(prNumCategory.prCategoryId)}
                                                            type="button"
                                                            size="sm"
                                                            variant="primary"
                                                            style={{ marginRight: "0.1rem" }}
                                                        >
                                                            {"Upravit"}
                                                        </Button>
                                                        <Button
                                                            className="btn-show-on-hover"
                                                            onClick={() => handleDeletePrNumCategory(prNumCategory.prCategoryId, prNumCategory.name)}
                                                            type="button"
                                                            size="sm"
                                                            variant="outline-secondary"
                                                        >
                                                            Smazat
                                                        </Button>
                                                    </Fragment>                    
                                                }
                                            />
                                        </td>
                                    </Fragment>
                            )}
                        </tr>
                    ))}
                    </tbody>
                </Table>
            </div>  
            {prNumCategoriesList && 
                <footer className="footer">
                    <div className="footer-left">
                    </div>
                    <div className="footer-right">
                    <Pages
                        totalPages={prNumCategoriesList.totalPages}
                        currentPage={prNumCategoriesList.currentPage}
                        onClickEvent={LoadPrNumCategories}
                    />
                    </div>
                </footer>
            }
        </Fragment>

    );
};

export default observer(PrNumbersCategoriesTab);