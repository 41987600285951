import React from "react";
import { Container } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMeteor, faCarCrash } from "@fortawesome/free-solid-svg-icons";

const NotFound = () => {
  return (
    <div>
      <Container style={{ marginTop: "2rem" }}>
        <div>
          <div style={{position:"absolute", left:"10%", top:"20%", fontSize:"10rem"}}>500</div>
          <div>
            <div style={{ position: "relative", left: "50%" }}>
              <FontAwesomeIcon size={"7x"} icon={faMeteor} />
            </div>
            <div style={{ position: "relative", left: "30%" }}>
              <FontAwesomeIcon size={"9x"} icon={faCarCrash} color={"green"} />
            </div>
          </div>
          <div style={{ marginTop: "2rem" }}>
            <h1 className={"display-4"}>A jéje. Zdá se, že máme problém..</h1>
            <p className={"lead"}>
              Omlouváme se, ale na stránkách se vyskytla interní chyba systému. Pokud potíže přetrvávají, obraťte se, prosím, na správce sytému.
            </p>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default NotFound;
