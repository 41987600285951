import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import "./IncompleteCar.scss"

interface IProps {
  id: number;
  style?: React.CSSProperties;
}

const IncompleteCar: React.FC<IProps> = ({ id,style }) => {
  return (
    <div style={style} >
      <OverlayTrigger
        placement="right"
        overlay={
          <Tooltip id={`tooltip-missing-${id}`}>
            Vůz nemá vyplněny všechny parametry
          </Tooltip>
        }
      >
        <FontAwesomeIcon
          icon={faExclamationTriangle}
          className="icon icon--incomplete"
        />
      </OverlayTrigger>
    </div>
  );
};

export default IncompleteCar;
