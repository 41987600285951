import React, { useState, useContext, useEffect, Fragment } from "react";
import { Form, Button, Row, Col, Table, Alert } from "react-bootstrap";
import { Form as FinalForm, Field, FormSpy } from "react-final-form";
import CheckboxInput from "../../app/common/form/CheckboxInput";
import { RootStoreContext } from "../../app/stores/rootStore";
import { IPlanCarsForm, PlanCarsValues } from "../../app/models/plan";
import { observer } from "mobx-react-lite";
import {
  getCarsString,
  getMasculinePassiveVerbEnding,
} from "../../app/common/utils/czechDeclension";
import VinCode from "../vin/VinCode";
import moment from "moment";
import { Link, RouteComponentProps } from "react-router-dom";
import NumberInput from "../../app/common/form/NumberInput";
import AlertMessage from "../alert/AlertMessage";
import DateTimeInput from "../../app/common/form/DateTimeInput";
import "./PlanPage.scss";
import IncompleteCar from "../icons/IncompleteCar";
import Loader from "../loader/Loader";
import CarFilter from "../carFilter/CarFilter";
import Can from "../../authorization/Can";
import VipCar from "../icons/VipCar";
import Pages from "../../app/common/utils/Pages";
import EmissionCarIcon from "../icons/EmissionCarIcon";
import EmissionDate from "../icons/EmissionDate";
import PlaceBadge from "../icons/PlaceBadge";
import TransportedCarIcon from "../icons/TransportedCarIcon";

const PlanPage: React.FC<RouteComponentProps> = ({ history }) => {
  const [errors, setErrors] = useState([]);
  const [planCars] = useState(new PlanCarsValues());
  const [showError, setShowError] = useState(false);
  const rootStore = useContext(RootStoreContext);
  const { startScheduling } = rootStore.pageScheduleStore;
  const {
    loadCars,
    carsSorted,
    loadingInitial,
    setCarFilter,
    carFilter,
    carsPage,
  } = rootStore.pagePlanCarStore;
  const { user } = rootStore.authStore;
  const [carsCount, setCarsCount] = useState(0);

  useEffect(() => {
    // load all cars
    loadCars(carsPage.currentPage);
  }, [loadCars, carsPage.currentPage]);

  const handleFinalFormSubmit = (values: IPlanCarsForm) => {
    //remove empty object from cars
    var cars = values.cars.filter((car) => (car.carId ? car : null));

    const scheduleData = { ...values, cars };

    setErrors([]);
    startScheduling(scheduleData)
      .then(() => {
        history.push("/schedule");
      })
      .catch((error) => {
        console.log(error);
        setErrors(Object.values(error.response?.data?.errors));
        setShowError(true);
      });
  };

  return (
    <Fragment>
      <div className="page-content" id="plan-page">
        <div className="container-full">
          <div className="pageheader">
            <div className="pageheader__left">
              <h1>Výběr vozů k zaplánování</h1>
            </div>
          </div>
          <FinalForm
            initialValues={planCars}
            onSubmit={handleFinalFormSubmit}
            validate={(values) => {
              let errors: {
                PlanFrom?: string;
                cars?: { priority?: string; carId?: string }[];
              } = { cars: [] };
              if (!values.PlanFrom) {
                errors.PlanFrom = "Datum od kdy se plánuje je povinné";
              } else if (values.PlanFrom < new Date()) {
                errors.PlanFrom = "Nelze plánovat do minulosti";
              }

              if (values.cars) {
                let sortedCarPriorities = values.cars
                  .filter((c) => c.carId && c.priority)
                  .map((c) => c.priority)
                  .sort();

                let priorityDuplicities: number[] = [];
                for (let i = 0; i < sortedCarPriorities.length - 1; i++) {
                  if (sortedCarPriorities[i + 1] === sortedCarPriorities[i]) {
                    priorityDuplicities.push(sortedCarPriorities[i]!);
                  }
                }

                const isSelectedVipCar = values.cars.find(
                  (c) => c && c.vipCar && c.carId
                );
                const selectedCarsCount = values.cars.filter((c) => c.carId)
                  .length;

                values.cars.forEach((car, index) => {
                  if (
                    car &&
                    car.priority &&
                    car.carId &&
                    priorityDuplicities.includes(car.priority)
                  ) {
                    if (errors && errors.cars)
                      errors.cars[index] = {
                        ...errors.cars[index],
                        priority: "Duplicitní hodnota",
                      };
                  }
                  if (
                    car &&
                    car.carId &&
                    selectedCarsCount > 1 &&
                    isSelectedVipCar
                  )
                    if (errors && errors.cars)
                      errors.cars[index] = {
                        ...errors.cars[index],
                        carId: "VIP vůz je nutné plánovat samostatně",
                      };
                });
              }

              return errors;
            }}
            render={({ handleSubmit, values, errors: validationErrors }) => (
              <Form onSubmit={handleSubmit} id="plancar">
                <Row className="show-grid">
                  <Col xl={12}>
                    <AlertMessage
                      type="danger"
                      heading="Plánování nelze provést!"
                      show={showError}
                      setShow={setShowError}
                      messageList={errors}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={6} sm={12} id="planfrom-field">
                    <Field
                      name="PlanFrom"
                      title="Plánovat od:"
                      format={(val) => (val ? moment(val).toDate() : undefined)}
                      parse={(val) => (val ? moment(val).toDate() : undefined)}
                      component={DateTimeInput}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xl={12}>
                    <div className="carfilter">
                      <CarFilter filter={carFilter} setFilter={setCarFilter} />
                      <label className="right">
                        Zobrazen
                        {getMasculinePassiveVerbEnding(carsSorted.length)}{" "}
                        {carsSorted.length} {getCarsString(carsSorted.length)} |
                        Celkem {carsPage.count} {getCarsString(carsPage.count)}{" "}
                        | Vybrán
                        {getMasculinePassiveVerbEnding(carsCount)} {carsCount}{" "}
                        {getCarsString(carsCount)}
                      </label>
                    </div>

                    <Table
                      size="sm"
                      hover
                      className="table-fixed sticky--top"
                      id="mapping_table"
                    >
                      <thead>
                        <tr>
                          <th>#</th>
                          <th></th>
                          <th>VDS číslo</th>
                          <th>VIN kód</th>
                          <th>KNR kód</th>
                          <th>Priorita</th>
                          <th>Stav vozu</th>
                          <th>Dispozice (od - do)</th>
                          <th>Plánováno (od - do)</th>
                          <th>Logistické číslo</th>
                          <th>Typ vozu</th>
                          <th>Typ zkoušky</th>
                          <th>Odběratel</th>
                          <th>Akce</th>
                        </tr>
                      </thead>
                      <tbody>
                        {!loadingInitial &&
                          carsSorted &&
                          carsSorted.length > 0 &&
                          carsSorted.map((car) => (
                            <tr
                              key={car.carId}
                              className={`${
                                values.cars[car.carId]?.carId ? "selected" : ""
                              } ${
                                car.placeId !== user?.placeId
                                  ? "transported-car"
                                  : ""
                              }`}
                            >
                              <td>
                                <Field
                                  name={`cars[${car.carId}].vipCar`}
                                  initialValue={car.vipCar || false}
                                  type="checkbox"
                                  component="input"
                                  hidden
                                  disabled
                                />
                                {car.placeId === user?.placeId && (
                                  <Field
                                    name={`cars[${car.carId}].carId`}
                                    type="checkbox"
                                    component={CheckboxInput}
                                    format={(v) => v === car.carId}
                                    parse={(val) =>
                                      val ? car.carId : undefined
                                    }
                                  />
                                )}
                              </td>
                              <td className="flex-column">
                                {car.vipCar && <VipCar id={car.carId} />}{" "}
                                {car.placeId !== user?.placeId && (
                                  <PlaceBadge
                                    name={car.placeCode}
                                    description={"Vůz je na zkušebně "}
                                  />
                                  //  <div>{car.placeCode}</div>
                                )}
                              </td>
                              <td>{car.integrationNum}</td>
                              <td>
                                <VinCode
                                  vin={car.vin}
                                  breakLine={true}
                                  important={
                                    car.vipCar || !!car.importantCarDesc
                                  }
                                />
                              </td>
                              <td>{car.prodIdentificationNum}</td>
                              <td>
                                <Field
                                  name={`cars[${car.carId}].priority`}
                                  component={NumberInput}
                                  size="sm"
                                  min={0}
                                  style={{ maxWidth: "5rem" }}
                                  parse={(value) =>
                                    (value && parseInt(value)) || undefined
                                  }
                                  disabled={car.placeId !== user?.placeId}
                                />
                              </td>
                              <td className="flex-column flex-center">
                                <div>{car.carState}</div>
                                <div className="flex-row">
                                  {car.isTransported && (
                                  <TransportedCarIcon
                                    id={car.carId}
                                    fromName={
                                      car.beforeTransportPlaceCode || ""
                                    }
                                    toName={car.placeCode}
                                  />
                                  )} 
                                  {car.mentionIncomplete && !car.isComplete && (
                                    <IncompleteCar
                                      id={car.carId}
                                      style={{ marginLeft: "0.5rem", marginTop:"3px" }}
                                    />
                                  )}
                                </div>
                              </td>
                              <td className="flex-column">
                                <div title="Datum naskladnění a vyskladnění vozu.">
                                  {car.availableFrom &&
                                    moment(car.availableFrom).format("D.M.YY")}
                                  {car.availableFrom && " - "}
                                  {car.availableTo &&
                                    moment(car.availableTo).format("D.M.YY")}
                                </div>
                                {car.emissionTest && (
                                  <EmissionDate
                                    id={car.carId}
                                    planned={false}
                                    dateFrom={car.emissionTestDateFrom}
                                    dateTo={car.emissionTestDateTo}
                                  />
                                )}
                              </td>
                              <td className="flex-column">
                                <div title="Datum první a poslední naplánované zkoušky.">
                                  {car.plannedFrom &&
                                    moment(car.plannedFrom).format("D.M.YY")}
                                  {car.plannedFrom && " - "}
                                  {car.plannedTo &&
                                    moment(car.plannedTo).format("D.M.YY")}
                                </div>
                                {car.emissionTest && (
                                  <EmissionDate
                                    id={car.carId}
                                    planned={true}
                                    dateFrom={car.emissionTestPlanFrom}
                                    dateTo={car.emissionTestPlanTo}
                                  />
                                )}
                              </td>
                              <td>{car.logisticNum}</td>
                              <td>{car.testDestinationCode}</td>
                              <td>
                                <div className="flex-column">
                                  <div>{car.testTypeName}</div>
                                  <div>{car.importantCarDesc}</div>
                                  {car.showEmissionTest && (
                                    <div>
                                      <EmissionCarIcon
                                        id={car.carId}
                                        emission={car.emissionTest}
                                      />
                                    </div>
                                  )}
                                </div>
                              </td>
                              <td>{car.customerCode}</td>
                              <td>
                                <Button
                                  size="sm"
                                  as={Link}
                                  to={`/car/${car.carId}`}
                                  variant="outline-primary"
                                >
                                  Detail
                                  {/* <FontAwesomeIcon icon={faListAlt} />  */}
                                </Button>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </Table>
                    {!loadingInitial && !carsSorted.length && (
                      <Alert variant="info">Nebyly nalezeny žádné vozy</Alert>
                    )}
                    {loadingInitial && <Loader />}
                  </Col>
                </Row>

                <FormSpy subscription={{ values: true }}>
                  {({ values }) => {
                    setCarsCount(
                      values?.cars?.filter((c: any) => c.carId).length || 0
                    );
                    return null;
                  }}
                </FormSpy>
              </Form>
            )}
          />
        </div>
      </div>
      <footer className="footer">
        <div className="footer-left">
          <Can
            roleId={user?.userRoleId}
            perform="plan-page:plan"
            yes={
              <Button
                type="button"
                onClick={() =>
                  document
                    .getElementById("plancar")!
                    .dispatchEvent(new Event("submit", { cancelable: true }))
                }
                variant="primary"
                style={{ marginBottom: "10px", marginRight: "20px" }}
              >
                Naplánovat {`${carsCount} ${getCarsString(carsCount)}`}
              </Button>
            }
            no={
              <Button
                type="button"
                variant="primary"
                style={{ marginBottom: "10px", marginRight: "20px" }}
                disabled
              >
                Nemáte práva plánovat
              </Button>
            }
          />
        </div>

        <div className="footer-right">
          <Pages
            totalPages={carsPage.totalPages}
            currentPage={carsPage.currentPage}
            onClickEvent={loadCars}
            scrollToId={"plan-page"}
          />
        </div>
      </footer>
    </Fragment>
  );
};

export default observer(PlanPage);
