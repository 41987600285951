import React, { useContext, useState } from "react";
import { Button, Form, Container } from "react-bootstrap";
import { Field, Form as FinalForm } from "react-final-form";
import TextInput from "../../../app/common/form/TextInput";
import ToggleSwitchInput from "../../../app/common/form/ToggleSwitchInput";
import { RootStoreContext } from "../../../app/stores/rootStore";
import AlertMessage from "../../alert/AlertMessage";

interface IProps {
  handleCreateItem: (form: any) => Promise<void>;
  submitErrors?: any[];
}

const NewCarColorForm: React.FC<IProps> = ({ handleCreateItem }) => {
  const rootStore = useContext(RootStoreContext);
  const { closeModal } = rootStore.modalStore;

  const [submitErrors, setSubmitErrors] = useState([]);
  const [showSubmitError, setShowSubmitError] = useState(false);

  return (
    <div>
      <AlertMessage
        type="danger"
        heading="Nastala chyba při ukládání"
        show={showSubmitError}
        setShow={setShowSubmitError}
        messageList={submitErrors}
      />
      <FinalForm
        onSubmit={(form: any) => {
          handleCreateItem(form)
            .then(() => closeModal())
            .catch((error) => {
              setSubmitErrors(Object.values(error.response.data.errors));
              setShowSubmitError(true);
            });
        }}
        validate={(values) => {
          const errors = {} as any;
          if (!values.code) {
            errors.code = "Kód nesmí být prázdný";
          }      
          if (values.code && values.code.length !== 2) {
            errors.code = "Kód musí mít 2 znaky";
          }
          if (!values.name) {
            errors.name = "Název nesmí být prázdný";
          }
          if (!values.value) {
            errors.value = "Barva musí být vybrána";
          }
          return errors;
        }}
        render={({ handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <Container style={{paddingRight:"10rem"}}>
              <Field
                title="Kód"
                name="code"
                initialValue={""}
                component={TextInput}
              />
                <Field
                  name="value"
                  title="Barva"
                  initialValue={"#000000"}
                  type="color"
                  fieldWidth={2}
                  component={TextInput}
                />
              <Field
                title="Název"
                name="name"
                initialValue={""}
                component={TextInput}
              />
              <Field
                name="isActive"
                title="Aktivní"
                initialValue={true}
                type="checkbox"
                component={ToggleSwitchInput}
              />
            </Container>
            <hr />
            <div className="flex-row flex-center">
              <Button type="submit" className="btn-modal">
                Uložit
              </Button>
              <Button
                className="btn-modal"
                type="button"
                onClick={closeModal}
                variant="outline-secondary"
              >
                Zrušit
              </Button>
            </div>
          </Form>
        )}
      />
    </div>
  );
};

export default NewCarColorForm;
