import { RootStore } from "./rootStore";
import { observable, action, runInAction, computed } from "mobx";
import {
  ICarTest,
  ICarTestForm,
  ICarTestDetail,
  ICarTestFormDTO,
} from "../models/carTest";
import agent from "../api/agent";
import { toast } from "react-toastify";

export default class CarTestStore {
  rootStore: RootStore;
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  // keys by car test Id
  @observable carTestsRegistry = new Map<number, ICarTest>();
  @observable isLoading = false;
  @observable isSaving = false;


  @computed get plannedCarTests() {
    const result = Array.from(this.carTestsRegistry.values()).filter(t=>t.startAt && t.endAt);
    return result;
  }

  
  
  @action loadCarTest = async (id: number) => {
    this.isLoading = true;
    try {
      const response = await agent.CarTests.details(id);
      runInAction("load car test details", () => {
        this.carTestsRegistry.set(response.carTest.carTestId, response.carTest);
        this.rootStore.carStore.carRegistry.set(
          response.car.carId,
          response.car
        );
        this.isLoading = false;
      });
    } catch (error) {
      runInAction("load car test details error", () => {
        console.log(error);
        this.isLoading = false;
      });
      throw error;
    }
  };


  @action updateCarTest = async (
    carTest: ICarTestForm,
    isConfirmed?: boolean
  ): Promise<ICarTestDetail> => {
    this.isSaving = true;
    const carTestDTO: ICarTestFormDTO = {
      carTest: carTest,
      isConfirmedSave: isConfirmed || false,
    };
    try {
      const result = await agent.CarTests.update(carTestDTO);
      runInAction("update car test", () => {
        this.carTestsRegistry.set(result.carTest.carTestId, result.carTest);
        this.rootStore.carStore.carRegistry.set(result.car.carId, result.car);
        this.isSaving = false;
      });
      return result;
    } catch (error) {
      runInAction("update car test error", () => {
        console.log(error);
        this.isSaving = false;
      });
      toast.error("Při ukládání zkoušky nastala chyba!");
      throw error;
    }
  };
}
