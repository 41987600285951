import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFire } from "@fortawesome/free-solid-svg-icons";
import "./VipCar.scss"

interface IProps {
  id: number;
  style?: React.CSSProperties | undefined;
}

const VipCar: React.FC<IProps> = ({ id, style }) => {
  return (
    <div className="icon-priority" style={style}>
      <OverlayTrigger
        placement="right"
        overlay={<Tooltip id={`tooltip-priority-${id}`}>VIP vůz</Tooltip>}
      >
        <FontAwesomeIcon icon={faFire} className="icon icon--warning" />
      </OverlayTrigger>
    </div>
  );
};

export default VipCar;
