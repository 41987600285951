import React, { Fragment } from "react";
import Select from "react-select";

import "./MultiSelectInput.scss";

import { FieldRenderProps } from "react-final-form";
import { Row, Col, Form } from "react-bootstrap";

type Props = FieldRenderProps<any, any>;

interface IOption {
  value: number;
  label: string;
}

const MultiSelectInput: React.FC<Props> = ({
  input,
  meta,
  title,
  options,
  placeholder,
  isEmptyOption,
  maxSelectedOptions,
  disabled,
  closeMenuOnSelect,
  inputOnChange,
  ...rest
}: Props) => {
  // see https://react-select.com
  const errorMessage = (meta.error || meta.submitError) && meta.touched && (
    <div className="multiselectinput-invalid-feedback">
      {" "}
      {meta.error || meta.submitError}
    </div>
  );
  const styles = {
    multiValue: (base: any, state: any) => {
      return state.data.isFixed
        ? { ...base, backgroundColor: "lightgrey" }
        : base;
    },
    // multiValueLabel: (base:any, state:any) => {
    //   return state.data.isFixed
    //     ? { ...base, fontWeight: "bold", color: "white", paddingRight: 6 }
    //     : base;
    // },
    multiValueRemove: (base: any, state: any) => {
      return state.data.isFixed ? { ...base, display: "none" } : base;
    },
  };
  const inputField = (
    <Fragment>
      <Select
        isMulti
        isDisabled={disabled}
        isClearable={!options.some((option: any) => option.isFixed) && (!input.value||!input.value.some((value: any) => value && value.isFixed)) }
        placeholder={placeholder}
        closeMenuOnSelect={closeMenuOnSelect}
        className={"multiselect"}
        classNamePrefix="select"
        noOptionsMessage={(inputValue) => "Žádné další možnosti"}
        {...input}
        {...rest}
        onChange={(e: any, option: any) => {
          if (option.removedValue && option.removedValue.isFixed) return;
          input.onChange(e);
          inputOnChange && inputOnChange(e);
        }}
        options={input.value && input.value.length === maxSelectedOptions ? [] : options}
        styles={styles}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary: "#92db7b",
            neutral0: "#ffffff",
            neutral5: "#e9ecef",
            neutral10: "#ced4da",
            neutral20:
              (meta.error || meta.submitError) && meta.touched
                ? "#e62336"
                : "#ced4da",
            neutral30:
              (meta.error || meta.submitError) && meta.touched
                ? "#e62336"
                : "#ced4da",
            neutral40: "#495057",
            neutral50: "#7f8489",
            neutral60: "#495057",
          },
        })}
      />
    </Fragment>
  );

  return (
    <Fragment>
      {title && (
        <Form.Group controlId={input.name}>
          <Row style={{ marginBottom: "1rem" }}>
            <Col xl={4} lg={4} md={4} sm={4} xs={4} className="u-text-right">
              {title && <Form.Label>{title}</Form.Label>}
            </Col>
            <Col xl={8} lg={8} md={8} sm={8} xs={8}>
              {inputField}
              {errorMessage}
            </Col>
          </Row>
        </Form.Group>
      )}
      {!title && (
        <Form.Group controlId={input.name} style={{ marginBottom: "1rem" }}>
          {inputField}
          {errorMessage}
        </Form.Group>
      )}
    </Fragment>
  );
};

export default MultiSelectInput;
