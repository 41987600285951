import React, { useContext, useEffect, useState } from "react";
import { Card, Button, Form, Table, Col, Row } from "react-bootstrap";
import { ICarEngineItem, ICarModelItemForm, ICarBodyTypeItemForm, IEnums } from "../../../app/models/enum";
import EditableEnumTableRow from "../forms/EditableEnumTableRow";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { RootStoreContext } from "../../../app/stores/rootStore";
import NewEnumItemForm from "../forms/NewEnumItemForm";
import { observer } from "mobx-react-lite";
import FilterActiveButtonGroup from "../../ButtonGroups/FilterActiveButtonGroup";
import { sortByStatusAndCode } from "../../../app/common/utils/sort";

interface IProps {
  enums: IEnums | undefined;
}
const CarModelParamsTab: React.FC<IProps> = ({ enums }) => {
  const rootStore = useContext(RootStoreContext);
  const {
    updateCarEquipment,
    updateCarEngine,
    updateCarBodyType,
  } = rootStore.enumStore;
  const { openModal } = rootStore.modalStore;

  const [editedItemId, setEditedItemId] = useState<string | undefined>(
    undefined
  );

  const [allEquipmentsEnabled, setAllEquipmentsEnabled] = useState<boolean>(false);
  const [allEnginesEnabled, setAllEnginesEnabled] = useState<boolean>(false);
  const [allBodyTypesEnabled, setAllBodyTypesEnabled] = useState<boolean>(false);

  useEffect(() => {
    if(enums?.carModels){
      setSelectedCarModelId(enums?.carModels.slice().filter(x=>x.isActive).sort((a, b) => (a.name < b.name ? -1 : 1))[0]?.id || 1)
    }
    
  }, [enums])
  const [selectedCarModelId, setSelectedCarModelId] = useState<number>(1);

  const handleEditCarEquipmentItem = (form: ICarModelItemForm) => {
    updateCarEquipment(form).then(() => {
      setEditedItemId(undefined);
    });
  };

  const handleEditCarEngineItem = (form: ICarEngineItem) => {
    updateCarEngine(form).then(() => {
      setEditedItemId(undefined);
    });
  };

  const handleEditCarBodyTypeItem = (form: ICarBodyTypeItemForm) => {
    updateCarBodyType(form).then(() => {
      setEditedItemId(undefined);
    });
  };

  const handleAddCarEquipmentItem = async (form: ICarModelItemForm) => {
    return updateCarEquipment(form);
  };

  const handleAddCarEquipmentClick = () => {
    openModal(
      <NewEnumItemForm
        handleCreateItem={handleAddCarEquipmentItem}
        carModelId={selectedCarModelId}
      />,
      {
        preventClosing: true,
        title: "Přidání výbavy vozu",
      }
    );
    return;
  };

  const handleAddCarEngineItem = async (form: ICarEngineItem) => {
    return updateCarEngine(form);
  };

  const handleAddCarEngineClick = () => {
    openModal(
      <NewEnumItemForm
        handleCreateItem={handleAddCarEngineItem}
        carModelId={selectedCarModelId}
        emissionFreeParam={true}
      />,
      {
        preventClosing: true,
        title: "Přidání motorizace vozu",
      }
    );
    return;
  };

  const handleAddCarBodyTypeItem = (form: ICarBodyTypeItemForm) => {
    return updateCarBodyType(form);
  };

  const handleAddCarBodyTypeClick = () => {
    openModal(
      <NewEnumItemForm
        handleCreateItem={handleAddCarBodyTypeItem}
        modelVdsCodeParam={true}
        carModelId={selectedCarModelId}
      />,
      {
        preventClosing: true,
        title: "Přidání karoserie vozu",
      }
    );
    return;
  };

  return (
    <div>
      <h2>Parametry modelů vozů</h2>
      <Form.Group controlId={"modelId"}>
        <Row>
          <Col sm={2} className="u-text-right">
            <Form.Label style={{ fontSize: "1.3rem" }}>Model vozu</Form.Label>
          </Col>
          <Col sm={10}>
            <Form.Control
              as="select"
              style={{ width: "30rem" }} 
              size="lg"
              name={"modelId"}
              onChange={(e: any) =>
                setSelectedCarModelId(Number.parseInt(e.target.value))
              }
              value={selectedCarModelId!.toString()}
            >
              {enums?.carModels
                .slice()
                .filter(x=>x.isActive)
                .sort((a, b) => (a.name < b.name ? -1 : 1))
                .map((option) => (
                  <option key={option.id} value={option.id}>
                    {option.name}
                  </option>
                ))}
            </Form.Control>
          </Col>
        </Row>
      </Form.Group>

      <Row>
        <Col lg={6}>
          <Card>
            <Card.Header className="flex-row flex-space-between">
              <div>Karoserie vozů</div>
              <div>
                <FilterActiveButtonGroup
                  allEnabled={allBodyTypesEnabled}
                  setAllEnabled={setAllBodyTypesEnabled}
                />
                <Button
                  variant="primary"
                  className="footer__btn-add"
                  onClick={handleAddCarBodyTypeClick}
                >
                  <FontAwesomeIcon icon={faPlus} />
                </Button>
              </div>
            </Card.Header>
            <Card.Body>
              <Table size="sm">
                <thead>
                  <tr>
                    <th>Kód</th>
                    <th>Název</th>
                    <th>VDS kód</th>
                    <th>Aktivní</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {enums?.carBodyTypes
                    .slice()
                    .filter((x) => x.carModelId === selectedCarModelId)
                    .filter((item) => allBodyTypesEnabled || item.isActive)
                    .sort(sortByStatusAndCode())
                    .map((item) => (
                      <EditableEnumTableRow
                        key={item.id}
                        editedItemId={editedItemId}
                        setEditedItemId={setEditedItemId}
                        handleEditItem={handleEditCarBodyTypeItem}
                        id={item.id}
                        name={item.name}
                        code={item.code}
                        vdsCodeColumn={true}
                        vdsCode={item.modelVdsCode}
                        isActive={item.isActive}
                        enumName="carBodyTypes"
                        carModelId={item.carModelId}
                      />
                    ))}
                </tbody>
              </Table>
            </Card.Body>
          </Card>

          <Card>
            <Card.Header className="flex-row flex-space-between">
              <div>Výbavy vozů</div>
              <div>
                <FilterActiveButtonGroup
                  allEnabled={allEquipmentsEnabled}
                  setAllEnabled={setAllEquipmentsEnabled}
                />
                <Button
                  variant="primary"
                  className="footer__btn-add"
                  onClick={handleAddCarEquipmentClick}
                >
                  <FontAwesomeIcon icon={faPlus} />
                </Button>
              </div>
            </Card.Header>
            <Card.Body>
              <Table size="sm">
                <thead>
                  <tr>
                    <th>Kód</th>
                    <th>Název</th>
                    <th>Aktivní</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {enums?.carEquipments
                    .slice()
                    .filter((x) => x.carModelId === selectedCarModelId)
                    .filter((item) => allEquipmentsEnabled || item.isActive)
                    .sort(sortByStatusAndCode())
                    .map((item) => (
                      <EditableEnumTableRow
                        key={item.id}
                        enumName="carEquipments"
                        editedItemId={editedItemId}
                        setEditedItemId={setEditedItemId}
                        handleEditItem={handleEditCarEquipmentItem}
                        id={item.id}
                        name={item.name}
                        code={item.code}
                        isActive={item.isActive}
                        carModelId={item.carModelId}
                      />
                    ))}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>

        <Col lg={6}>
          <Card>
            <Card.Header className="flex-row flex-space-between">
              <div>Motorizace vozů</div>
              <div>
                <FilterActiveButtonGroup
                  allEnabled={allEnginesEnabled}
                  setAllEnabled={setAllEnginesEnabled}
                />
                <Button
                  variant="primary"
                  className="footer__btn-add"
                  onClick={handleAddCarEngineClick}
                >
                  <FontAwesomeIcon icon={faPlus} />
                </Button>
              </div>
            </Card.Header>
            <Card.Body>
              <Table size="sm">
                <thead>
                  <tr>
                    <th>Kód</th>
                    <th>Název</th>
                    <th>Bezemisní</th>
                    <th>Aktivní</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {enums?.carEngines
                    .slice()
                    .filter((x) => x.carModelId === selectedCarModelId)
                    .filter((item) => allEnginesEnabled|| item.isActive)
                    .sort(sortByStatusAndCode())
                    .map((item) => (
                      <EditableEnumTableRow
                        key={item.id}
                        enumName="carEngines"
                        editedItemId={editedItemId}
                        setEditedItemId={setEditedItemId}
                        handleEditItem={handleEditCarEngineItem}
                        id={item.id}
                        name={item.name}
                        code={item.code}
                        isActive={item.isActive}
                        carModelId={item.carModelId}
                        emissionFree={item.emissionFree}
                        emissionColumn={true}
                      />
                    ))}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default observer(CarModelParamsTab);
