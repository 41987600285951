import React, { useContext } from "react";
import moment from "moment";
import { OverlayTrigger, Popover } from "react-bootstrap";
import {  IHoliday,IBlockingEvent } from "../../../app/models/blockingEvent";
import CompanyVacationEditPopover from "../forms/CompanyVacationEditPopover";
import { RootStoreContext } from "../../../app/stores/rootStore";
import Can from "../../../authorization/Can";

interface IProps {
  date: moment.Moment;
  holidays: IHoliday[];
  vacations: IBlockingEvent[];
}

const CalendarDay: React.FC<IProps> = ({ date, holidays, vacations }) => {
  const rootStore = useContext(RootStoreContext);
  const { user } = rootStore.authStore;

  const dateString = date.format("YYYY-MM-DD");

  const holiday = holidays.find((h) => h.date === dateString);
  const vacation = vacations.find(
    (v) =>
      moment(v.startDate).isSameOrBefore(date) &&
      moment(v.endDate).isSameOrAfter(date)
  );
  const isHoliday = !!holiday;
  const isVacation = !!vacation;
  const isEmpty = !isHoliday && !isVacation;

  const dayClassName = isHoliday
    ? "calendar-day--holiday"
    : isVacation
    ? "calendar-day--vacation"
    : "";

  const popover = (
    <Popover id="popover-calendar-day">
      {isHoliday && (
        <Popover.Content className="calendarday__popover calendarday__popover--holiday">
          <ul>
            <li>Státní svátek </li>
            <li>{holiday?.name}</li>
            <li>{date.format("dddd D.M.YYYY")}</li>
          </ul>
        </Popover.Content>
      )}
      {isVacation && (
        <Can
          roleId={user?.userRoleId}
          perform="settings-companyVacation:edit"
          yes={
            <CompanyVacationEditPopover
              blockingEventId={vacation!.blockingEventId}
              startDate={moment(vacation!.startDate).toDate()}
              endDate={moment(vacation!.endDate).toDate()}
              description={vacation!.description}
            />
          }
          no={
            <Popover.Content className="calendarday__popover calendarday__popover--holiday">
              <ul>
                <li>Celozávodní dovolená </li>
                <li>{vacation!.description}</li>
                <li>{`${moment(vacation!.startDate).format(
                  "dddd D.M.YYYY"
                )} - ${moment(vacation!.endDate).format("dddd D.M.YYYY")}`}</li>
              </ul>
            </Popover.Content>
          }
        />
      )}
      {isEmpty && (
        <Can
          roleId={user?.userRoleId}
          perform="settings-companyVacation:edit"
          yes={
            <CompanyVacationEditPopover
              blockingEventId={undefined}
              startDate={date.toDate()}
              endDate={date.toDate()}
              description={""}
            />
          }
        />
      )}
    </Popover>
  );

  return (
    <OverlayTrigger
      trigger="click"
      placement="auto"
      rootClose
      overlay={popover}
    >
      <td className={`calendar-day ${dayClassName}`}>
        <span>{date.date()}</span>
      </td>
    </OverlayTrigger>
  );
};

export default CalendarDay;
