import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { observer } from "mobx-react-lite";
import React, { useContext, useState } from "react";
import { ListGroup, Card, Button, Table, Col, Row } from "react-bootstrap";
import { sortByStatusAndCode } from "../../../app/common/utils/sort";
import { ICarColorForm, IEnums } from "../../../app/models/enum";
import { RootStoreContext } from "../../../app/stores/rootStore";
import FilterActiveButtonGroup from "../../ButtonGroups/FilterActiveButtonGroup";
import EditableCarColorRow from "../forms/EditableCarColorRow";
import NewCarColorForm from "../forms/NewCarColorForm";

interface IProps {
  enums: IEnums | undefined;
}

const EnumsTab: React.FC<IProps> = ({ enums }) => {
  const [editedColorId, setEditedColorId] = useState<string | undefined>(
    undefined
  );
  const [allColorsEnabled, setAllColorsEnabled] = useState<boolean>(false);

  const rootStore = useContext(RootStoreContext);
  const { updateCarColor } = rootStore.enumStore;
  const { openModal } = rootStore.modalStore;

  const handleEditColor = (form: ICarColorForm) => {
    updateCarColor(form).then(() => {
      setEditedColorId(undefined);
    });
  };

  const handleAddCarColor = async (form: ICarColorForm) => {
    return updateCarColor(form);
  };

  const handleAddCarColorClick = () => {
    openModal(<NewCarColorForm handleCreateItem={handleAddCarColor} />, {
      preventClosing: true,
      title: "Přidání barvy vozu",
    });
    return;
  };

  return (
    <div>
      <Row>
        <Col lg={5}>
          <Card>
            <Card.Header className="flex-row flex-space-between">
              <div>Barvy vozů</div>
              <div>
                <FilterActiveButtonGroup
                  allEnabled={allColorsEnabled}
                  setAllEnabled={setAllColorsEnabled}
                /> 
                <Button
                  variant="primary"
                  className="footer__btn-add"
                  onClick={handleAddCarColorClick}
                >
                  <FontAwesomeIcon icon={faPlus} />
                </Button>
              </div>
            </Card.Header>
            <Card.Body>
              <Table size="sm">
                <thead>
                  <tr>
                    <th>Kód</th>
                    <th>Barva</th>
                    <th>Název</th>
                    <th>Aktivní</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {enums?.carColors
                    .slice()
                    .filter((item) => allColorsEnabled || item.isActive)
                    .sort(sortByStatusAndCode())
                    .map((item) => (
                      <EditableCarColorRow
                        key={item.id}
                        handleEditItem={handleEditColor}
                        id={item.id}
                        name={item.name}
                        code={item.code}
                        value={item.value}
                        isActive={item.isActive}
                        editedItemId={editedColorId}
                        setEditedItemId={setEditedColorId}
                      />
                    ))}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
        <Col lg={3}>
          <Card>
            <Card.Header>Stavy vozů</Card.Header>
            <ListGroup variant="flush">
              {enums?.carStates.map((item) => (
                <ListGroup.Item key={item.key}>{item.value}</ListGroup.Item>
              ))}
            </ListGroup>
          </Card>

          <Card>
            <Card.Header>Stavy zkoušek</Card.Header>
            <ListGroup variant="flush">
              {enums?.testStates.map((item) => (
                <ListGroup.Item key={item.key}>{item.value}</ListGroup.Item>
              ))}
            </ListGroup>
          </Card>
        </Col>
        <Col lg={4}>
          <Card>
            <Card.Header>Typy vozů</Card.Header>
            <ListGroup variant="flush">
              {enums?.testDestinations.map((item) => (
                <ListGroup.Item key={item.key}>{item.value}</ListGroup.Item>
              ))}
            </ListGroup>
          </Card>

          <Card>
            <Card.Header>Podurčení zkoušek</Card.Header>
            <ListGroup variant="flush">
              {enums?.testPredestinations.map((item) => (
                <ListGroup.Item key={item.key}>{item.value}</ListGroup.Item>
              ))}
            </ListGroup>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default observer(EnumsTab);
