import React from "react";
import { Form, Row, Col } from "react-bootstrap";
import { FieldRenderProps } from "react-final-form";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import cs from "date-fns/locale/cs";

type Props = FieldRenderProps<Date, any>;

const DateInput: React.FC<Props> = ({
  input,
  meta,
  title,
  placeholder,
  size,
  inputOnChange,
  disabled,
  titleWidth,
  fieldWidth,
}: Props) => {
  registerLocale("cs", cs);

  const errorMessage = !!meta.error && meta.touched && (
    <div className="datepicker-invalid-feedback">{meta.error}</div>
  );

  return (
    <Form.Group controlId={title?input.name:undefined}>
      <Row>
        {title && (
          <Col xs={titleWidth || 4} className="u-text-right">
            <Form.Label>{title}</Form.Label>
          </Col>
        )}
        <Col xs={fieldWidth || (title?8:12)}>
          <DatePicker
            onClickOutside={input.onBlur}
            onCalendarClose={input.onBlur}
            onBlur={input.onBlur}
            onInputClick={input.onFocus}
            autoComplete={"off"}
            disabled={disabled}
            dateFormat="dd.MM.yyyy"
            selected={input.value}
            onChange={(e:any)=>{input.onChange(e); inputOnChange&& inputOnChange(e)}}
            customInput={
              <Form.Control
                type="text"
                size={size}
                isInvalid={!!meta.error && meta.touched}
              />
            }
            locale="cs"
          />
          {errorMessage}
        </Col>
      </Row>
    </Form.Group>
  );
};

export default DateInput;
