import React, { Fragment, useState } from "react";
import { Form, Row, Col, Button } from "react-bootstrap";
import { observer } from "mobx-react-lite";
import { IPrNumCategory } from "../../../app/models/enum";

interface IProps {
    searchFamily?: string | undefined,
    searchPrNumber?: string | undefined,
    searchPrNumCategoryId?: number | undefined;
    cancelSearch: boolean,
    prNumCategories: IPrNumCategory[] | undefined,
    handleSearch: (searchFamily?: string, searchPrNumber?: string, prNumCategoryId?: number | undefined) => void,
    handleCancelSearch: () => void,
}

const PrNumbersSearchBox: React.FC<IProps> = ({ searchFamily, searchPrNumber, searchPrNumCategoryId, cancelSearch, prNumCategories, handleSearch, handleCancelSearch}) => {

    const [searchFamilyText, setSearchFamily] = useState<string | undefined>(searchFamily)
    const [searchPrNumberText, setSearchPrNumber] = useState<string | undefined>(searchPrNumber)
    const [prNumCategoryId, setPrNumCategoryId ] = useState<number | undefined>(searchPrNumCategoryId);

    const handleSearchButton = () => {
        handleSearch(searchFamilyText, searchPrNumberText, prNumCategoryId);
    };

    const handleCancelButton = () => {
        handleCancelSearch();
    };

    return (
         <Fragment>
            <Row>
                <Col xs={2} className="vertical-align">
                    <Form.Label>Vyhledat:</Form.Label>
                </Col>
                <Col xs={2}>
                    <Form.Group className="mb-3" controlId="formGroupFamily">
                        <Form.Label>Rodina kód / název</Form.Label>
                        <Form.Control
                            type="text"
                            value={searchFamilyText}
                            onChange={(e: any) => { setSearchFamily(e.target.value); }}
                            name="searchFamily"
                        />
                    </Form.Group>
                </Col>
                <Col xs={2}>
                    <Form.Group className="mb-3" controlId="formGroupPrNumber">
                        <Form.Label>PR číslo kód / název</Form.Label>
                        <Form.Control
                            type="text"
                            value={searchPrNumberText}
                            onChange={(e: any) => { setSearchPrNumber(e.target.value); }}
                            name="searchPrNumber"
                        />
                    </Form.Group>
                </Col>
                <Col xs={2}>
                    {prNumCategories && 
                        <Form.Group className="mb-3" controlId="formGroupCategory">
                            <Form.Label>Kategorie</Form.Label>
                            <Form.Control
                                as="select"
                                name="prNumCategoryId"
                                onChange={(e: any) => {
                                    const id = Number.parseInt(e.target.value);
                                    setPrNumCategoryId(id);
                                }}
                                value={prNumCategoryId?.toString()}
                            >
                                <option></option>
                                <option key={-1} value={-1}>Nezařazené</option>
                                {prNumCategories.map((category: IPrNumCategory) => (
                                    <option key={category.id} value={category.id} >
                                        {category.name}
                                    </option>
                                ))}
                            </Form.Control>
                        </Form.Group>
                    }
                </Col>
                <Col xs={4} className="vertical-align">
                    <Button className="btn-wide" onClick={() => handleSearchButton()} >
                        Hledat!
                    </Button>
                    {cancelSearch &&
                        <Button
                            variant="outline-secondary"
                            type="reset"
                            style={{ marginLeft: 10 }}
                            onClick={() => handleCancelButton()}
                            >
                                Zrušit hledání
                        </Button>
                    }
                </Col>
            </Row>
        </Fragment>
    );

};

export default observer(PrNumbersSearchBox);