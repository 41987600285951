import React from 'react'
import { ButtonGroup, Button } from 'react-bootstrap';

interface IProps {
    allEnabled: boolean;
    setAllEnabled: (activeEnabled: boolean) => void;
  }

const FilterActiveButtonGroup: React.FC<IProps> = ({
  allEnabled,
  setAllEnabled
}) => {
  return (
    <ButtonGroup size="sm" className="buttongroup buttongroup-filter">
    <Button
      onClick={() => setAllEnabled(!allEnabled)}
      variant={allEnabled ? "primary" : "outline-primary"}
    >
      Vše
    </Button>
    <Button
      onClick={() => setAllEnabled(!allEnabled)}
      variant={!allEnabled ? "primary" : "outline-primary"}
    >
      Aktivní
    </Button>
  </ButtonGroup>
  )
}

export default FilterActiveButtonGroup