import React, { useContext } from "react";
import { Button, Form, Container } from "react-bootstrap";
import { Field, Form as FinalForm } from "react-final-form";
import TextInput from "../../../app/common/form/TextInput";
import NumberInput from "../../../app/common/form/NumberInput";
import { RootStoreContext } from "../../../app/stores/rootStore";
import { IPrCategoryForm } from "../../../app/models/prNumber"


interface IProps {
  handleCreatePrCategory: (form: IPrCategoryForm) => void;
}

const NewPrCategoryForm: React.FC<IProps> = ({ handleCreatePrCategory }) => {
  const rootStore = useContext(RootStoreContext);
  const { closeModal } = rootStore.modalStore;

  return (
    <div>

      <FinalForm
        onSubmit={(form: any) => {
          handleCreatePrCategory(form);
          closeModal();
        }}

        render={({ handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <Container style={{paddingRight:"10rem"}}>
          
              <Field
                title="Název"
                name="name"
                initialValue={""}
                component={TextInput}
              />
              <Field
                title="Popis"
                name="description"
                initialValue={""}
                component={TextInput}
              />
              <Field
                  title="Pořadí"
                  name={`order`}
                  component={NumberInput}
                  size={"sm"}
              />
            </Container>
            <hr />
            <div className="flex-row flex-center">
              <Button type="submit" className="btn-modal">
                Uložit
              </Button>
              <Button
                className="btn-modal"
                type="button"
                onClick={closeModal}
                variant="outline-secondary"
              >
                Zrušit
              </Button>
            </div>
          </Form>
        )}
      />
    </div>
  );
};

export default NewPrCategoryForm;
