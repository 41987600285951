import React from 'react';
import {calculateAverage} from "./acousticsUtils";
import {Field, useFormState} from "react-final-form";
import {IAcousticsProtocol} from "../../../../app/models/protocol";
import {Col, Row} from "react-bootstrap";
import {defectOptions} from "../protocolParts/defectForm/defectUtils";
import TextInput from "../../../../app/common/form/TextInput";

interface IProps {
    isEdit: boolean
}

const AverageScore: React.FC<IProps> = ({isEdit}) => {
    const formState = useFormState<IAcousticsProtocol>();
    const notPerform = formState.values.executedTestArea.notPerform && formState.values.auxiliaryAggregatesArea.notPerform;

    const drivingAssessmentGroup = (formState.values.executedTestArea.drivingAssessmentGroup)
    const drivingAssessmentScores = (drivingAssessmentGroup.partRules || [])
        .flatMap(value => value)
        .map(value => value.measuredValue || '')
        .filter(value => value)
        .map(value => parseInt(value));

    const onSiteAssessmentLowestGroup = (formState.values.executedTestArea.onSiteAssessmentGroup)
    const onSiteAssessmentLowestScores = (onSiteAssessmentLowestGroup.partRules || [])
        .flatMap(value => value)
        .map(value => value.measuredValues || [])
        .filter(value => value.length > 0)
        .map(value => value
            .filter(value => !!value)
            .map(value => parseInt(value)))
        .filter(value => value.length > 0)
        .map(value => Math.min(...value));

    const auxiliaryAggregatesScores = (formState.values.auxiliaryAggregatesArea.partRules || [])
        .map(value => value.measuredValue || '')
        .filter(value => value)
        .map(value => parseInt(value));
        
    const averageScore = calculateAverage([...drivingAssessmentScores,
        ...onSiteAssessmentLowestScores,
        ...auxiliaryAggregatesScores
    ]);
    return (
        <div className={`protocol-area`}>
            <Row noGutters className={'mt-2 pt-2'}>
                <Col sm={3}>Celkové hodnocení</Col>
                <Col sm={1}>
                    <Field<string>
                        name={`executedTestArea.stringEval`}
                        placeholder={averageScore}
                        items={defectOptions.map(value => value.value)}
                        component={TextInput}
                        disabled={!isEdit || notPerform}
                    />
                </Col>
            </Row>
        </div>
    );
};

export default AverageScore;
