import "./CarColor.scss";
import React from "react";
import { Badge, OverlayTrigger, Tooltip } from "react-bootstrap";
import { pickTextColorBasedOnBgColor } from "../../app/common/utils/functions";

interface IProps {
  id: number;
  code: string;
  name: string;
  color: string;
  style?: React.CSSProperties | undefined;
}

const CarColor: React.FC<IProps> = ({ id, code, name, color, style }) => {
  const textColor = pickTextColorBasedOnBgColor(color, "light", "dark");

  const colorIcon = (
    <Badge
      className={`car-color-icon text--${textColor}`}
      style={{
        ...style,
        backgroundColor: color,
        borderColor: textColor === "light" ? color : "#808080",
      }}
    >
      {code}
    </Badge>
  );

  let tooltipText = <span>{name}</span>;
  if (!!name) {
    const nameparts = name.split(";");
    if (nameparts.length === 2) {
      tooltipText = (
        <span>
          {nameparts[0]}
          <br />
          {nameparts[1]}
        </span>
      );
    }
  }

  return (
    <div id={`car-color-icon-${id}`}>
      {!!name && (
        <OverlayTrigger
          placement="right"
          overlay={
            <Tooltip
              id={`tooltip-car-color-icon-${id}`}
              className="tooltip-color"
            >
              {tooltipText}
            </Tooltip>
          }
        >
          {colorIcon}
        </OverlayTrigger>
      )}
      {!name && !!code && colorIcon}
    </div>
  );
};

export default CarColor;
