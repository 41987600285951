import React, { useContext, useEffect, Fragment, useState } from "react";
import { Form as FinalForm, Field } from "react-final-form";
import { RootStoreContext } from "../../../app/stores/rootStore";
import { Col, Row, Button, Badge } from "react-bootstrap";
import MultiSelectInput from "../../../app/common/form/MultiSelectInput";
import TextInput from "../../../app/common/form/TextInput";
import { observer } from "mobx-react-lite";
import Loader from "../../loader/Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter, faTimes } from "@fortawesome/free-solid-svg-icons";
import "./ScheduleFilter.scss";
import CheckboxInput from "../../../app/common/form/CheckboxInput";
import { IScheduleFilter } from "../../../app/models/schedule";

interface IProps {
  filter: IScheduleFilter;
  setFilter: (filter: IScheduleFilter) => void;
  activeFilterLength: number;
}

const ScheduleFilter: React.FC<IProps> = ({
  filter,
  setFilter,
  activeFilterLength,
}) => {
  const rootStore = useContext(RootStoreContext);
  const { loadEnums, enums, isLoading } = rootStore.enumStore;
  const { setTableHeaderWidth } = rootStore.pageScheduleStore;
  const [show, setShow] = useState(false);

  useEffect(() => {
    loadEnums();
  }, [loadEnums, enums]);

  useEffect(() => {
    setTableHeaderWidth();
  }, [show, setTableHeaderWidth]);

  const handleFilterSchedule = (form: any) => {
    const carFilter: IScheduleFilter = {
      vin: form.vin,
      //  prodIdentificationNum: form.prodIdentificationNum,
      //  carNumber: form.carNumber,
      customerCode: form.customerCode,
      inTestroomNow: form.inTestroomNow,
      inTestroom: form.inTestroom,
      testDestinations: form.testDestinations,
      testPredestinations: form.testPredestinations,
      testTypes: form.testTypes,
      carStates: form.carStates,
      carModels: form.carModels,
      carEngines: form.carEngines,
      timeLimitTypes: form.timeLimitTypes,
      //  conflictTypes: form.conflictTypes,
    };
    setFilter(carFilter);
  };

  const resetFilter = () => {
    const scheduleFilter: IScheduleFilter = {
      customerCode: "",
      vin: "",
      inTestroom: false,
      inTestroomNow: false,
      carStates: [],
      testDestinations: [],
      testPredestinations: [],
      testTypes: [],
      carModels: [],
      carEngines: [],
      timeLimitTypes: [],
      //conflictTypes: [],
    };
    setFilter(scheduleFilter);
  };

  const carModelReset = (args: any, state: any, utils: any) => {
    utils.changeValue(state, "carEngines", () => []);
  };

  const inTestroomReset = (args: any, state: any, utils: any) => {
    utils.changeValue(state, "inTestroom", () => false);
  };

  const inTestroomNowReset = (args: any, state: any, utils: any) => {
    utils.changeValue(state, "inTestroomNow", () => false);
  };

  return (
    <Fragment>
      {/* <div className="filter-container"> */}
      <div className="filter-container__buttons no-print">
        <Button
          variant="success"
          onClick={() => {
            setShow(!show);
          }}
          title="Zobrazit / skrýt filtr"
          size="sm"
        >
          <FontAwesomeIcon icon={faFilter} />
          {filter && Object.values(filter) && (
            <sup>
              <Badge pill variant="danger">
                {activeFilterLength}
              </Badge>
            </sup>
          )}
        </Button>
        {activeFilterLength > 0 && (
          <Button
            size="sm"
            variant="danger"
            onClick={resetFilter}
            title="Zrušit všechny filtry"
          >
            <FontAwesomeIcon icon={faTimes} />
          </Button>
        )}
      </div>
      <div className="break"></div>
      {show && (
        <div className="filter-container__filters">
          {isLoading && <Loader />}
          {enums && (
            <FinalForm
              onSubmit={handleFilterSchedule}
              mutators={{ carModelReset, inTestroomNowReset, inTestroomReset }}
              initialValues={filter}
              render={({ handleSubmit, form, values }) => {
                return (
                  <form onSubmit={handleSubmit}>
                    <h4>Možnosti filtrování</h4>
                    <Row>
                      <Col md={3}>
                        <Field
                          title="VIN"
                          name="vin"
                          component={TextInput}
                          size="sm"
                        />
                        {/* <Field
                                title="KNR"
                                name="prodIdentificationNum"
                                component={TextInput}
                                size="sm"
                              /> */}

                        {/* <Field
                                title="Číslo vozu"
                                name="carNumber"
                                component={TextInput}
                                size="sm"
                              /> */}
                        <Field
                          title="Odběratel"
                          name="customerCode"
                          component={TextInput}
                          size="sm"
                        />
                        <Field
                          title="Model"
                          name="carModels"
                          size="sm"
                          placeholder=""
                          inputOnChange={form.mutators.carModelReset}
                          options={enums.carModels
                            .slice()
                            .filter((m) => m.isActive)
                            .sort((a, b) => (a.name < b.name ? -1 : 1))
                            .map((m) => ({
                              value: m.id,
                              label: m.name,
                            }))}
                          component={MultiSelectInput}
                        />

                        <Field
                          title="Motorizace"
                          name="carEngines"
                          placeholder={
                            values.carModels && values.carModels.length === 1
                              ? ""
                              : "Vyberte 1 model vozu"
                          }
                          size="sm"
                          options={enums.carEngines
                            .slice()
                            .filter(
                              (m) =>
                                m.isActive &&
                                values.carModels &&
                                values.carModels.length === 1 &&
                                m.carModelId === values.carModels[0].value
                            )
                            .sort((a, b) => (a.name < b.name ? -1 : 1))
                            .map((m) => ({
                              value: m.id,
                              label: m.name,
                            }))}
                          component={MultiSelectInput}
                        />
                      </Col>
                      <Col md={4}>
                        <Field
                          title="Typ vozu"
                          name="testDestinations"
                          size="sm"
                          placeholder=""
                          options={enums.testDestinations.map((e) => ({
                            value: e.key,
                            label: e.value,
                          }))}
                          component={MultiSelectInput}
                        />

                        <Field
                          title="Podurčení zkoušky"
                          name="testPredestinations"
                          size="sm"
                          placeholder=""
                          options={enums.testPredestinations.map((e) => ({
                            value: e.key,
                            label: e.value,
                          }))}
                          component={MultiSelectInput}
                        />

                        <Field
                          title="Typ zkoušky"
                          name="testTypes"
                          size="sm"
                          placeholder=""
                          options={enums.testTypes.map((e) => ({
                            value: e.key,
                            label: e.value,
                          }))}
                          component={MultiSelectInput}
                        />
                      </Col>
                      <Col md={5}>
                        <Field
                          title="Stav vozu"
                          name="carStates"
                          size="sm"
                          placeholder=""
                          options={enums.carStates.map((e) => ({
                            value: e.key,
                            label: e.value,
                          }))}
                          component={MultiSelectInput}
                        />
                        <Field
                          name="inTestroomNow"
                          initialValue={false}
                          title="Na zkušebně právě teď"
                          type="checkbox"
                          inputOnChange={form.mutators.inTestroomReset}
                          titleAfter={true}
                          component={CheckboxInput}
                        />
                        <Field
                          name="inTestroom"
                          initialValue={false}
                          title="Na zkušebně v daném týdnu"
                          type="checkbox"
                          inputOnChange={form.mutators.inTestroomNowReset}
                          component={CheckboxInput}
                          titleAfter={true}
                        />
                        <Field
                          title="Limit zkušebny"
                          name="timeLimitTypes"
                          size="sm"
                          placeholder=""
                          options={enums.timeLimitTypes.map((e) => ({
                            value: e.key,
                            label: e.value,
                          }))}
                          component={MultiSelectInput}
                        />
                        {/* <Field
                            title="Vůz s konflikty"
                            name="conflictTypes"
                            size="sm"
                            placeholder=""                         
                            options={enums.conflictTypes.map((e) => ({
                              value: e.key,
                              label: e.value,
                            }))}
                            component={MultiSelectInput}
                          /> */}
                      </Col>
                    </Row>

                    <Row>
                      <div className="align-right">
                        <Button
                          type="submit"
                          className="filter-container__filters__button"
                        >
                          Filtruj!
                        </Button>

                        <Button
                          type="button"
                          variant="outline-danger"
                          className="filter-container__filters__button"
                          onClick={resetFilter}
                        >
                          Zruš filtry
                        </Button>
                      </div>
                    </Row>
                  </form>
                );
              }}
            />
          )}
        </div>
      )}
      {/* </div> */}
    </Fragment>
  );
};

export default observer(ScheduleFilter);
