import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock } from "@fortawesome/free-solid-svg-icons";
import "./ExceededLimit.scss";

interface IProps {
  id: number;
  timeLimitType: number;
  style?: React.CSSProperties;
}
const ExceededLimit: React.FC<IProps> = ({ id, style, timeLimitType }) => {
  const type = timeLimitType === 1 ? "warning" : "danger";
  const tooltipText =
    type === "warning"
      ? "Plánovaný čas nad limit"
      : "Skutečný čas nad limit";
  return (
    <div style={style} id="exceeded-limit-icon">
      <OverlayTrigger
        placement="right"
        overlay={
          <Tooltip id={`tooltip-exceedingLimit-${id}`}>{tooltipText}</Tooltip>
        }
      >
        <FontAwesomeIcon
          icon={faClock}
          className={`icon icon-exceeded-limit icon-exceeded-limit--${type}`}
        />
      </OverlayTrigger>
    </div>
  );
};

export default ExceededLimit;
