import React, {useContext} from "react";
import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";
import csLocale from "@fullcalendar/core/locales/cs";
import momentPlugin from "@fullcalendar/moment";
import "@fullcalendar/core/main.css";
import "@fullcalendar/timegrid/main.css";
import {CarTestStates} from "../../app/models/enum";

import ReactDOM from "react-dom";
import FullCalendarEvent from "./FullCalendarTestEvent";
import FullCalendarBlockingEvent from "./FullCalendarBlockingEvent";
import "./FullCalendar.scss";
import {DateInput} from "@fullcalendar/core/datelib/env";
import moment from "moment";
import ReactDOMServer from "react-dom/server";
import {ICarTest} from "../../app/models/carTest";
import {ICarAnalysisTermList,} from "../../app/models/schedule";
import {useHistory} from "react-router-dom";
import {RootStoreContext} from "../../app/stores/rootStore";
import {IBlockingEvent, IHoliday} from "../../app/models/blockingEvent";
import {combineDateTime} from "../../app/common/utils/functions";
import {EventInput} from "@fullcalendar/core/structs/event";
import {checkAuthRule} from "../../authorization/Can";
import BlockingEventForm from "../blockingEventForm/BlockingEventForm";
import CarAnalysisForm from "../carAnalysis/CarAnalysisForm";
import FullCalendarAnalysisEvent from "./FullCalendarAnalysisEvent";
import {CreateNewCarAnalysis, ICarAnalysis,} from "../../app/models/carAnalysis";
import FullCalendarVacationEvent from "./FullCalendarVacationEvent";
import {createGroupScheduleOptions, queryClient,} from "../../app/api/client";

interface IProps {
  calendarComponentRef: React.RefObject<FullCalendar>;
  defaultDateFrom: string | undefined;
}

enum CalendarEventType {
  CarTest,
  BlockingEvent,
  Analysis,
  Vacation,
}

const GroupFullCalendar: React.FC<IProps> = ({
  calendarComponentRef,
  defaultDateFrom,
}) => {
  let history = useHistory();

  const rootStore = useContext(RootStoreContext);
  const { openModal } = rootStore.modalStore;
  const { expertGroupUsers, expertGroupId, userId,} = rootStore.pageGroupScheduleStore;
  const { user } = rootStore.authStore;
  if (!expertGroupId || expertGroupUsers.length === 0) return <></>
  const addAbsence = async () => {
    calendarComponentRef?.current?.getApi()?.refetchEvents();
  };

  const eventRender = ({ event, el }: any) => {
    let content = undefined;
    switch (event.extendedProps.eventType) {
      case CalendarEventType.CarTest:
        content = <FullCalendarEvent event={event} />;
        break;
      case CalendarEventType.BlockingEvent:
        content = <FullCalendarBlockingEvent event={event} />;
        break;
      case CalendarEventType.Analysis:
        content = <FullCalendarAnalysisEvent event={event} />;
        break;
      case CalendarEventType.Vacation:
        content = <FullCalendarVacationEvent event={event} />;
        break;
      default:
        content = <div></div>;
    }
    ReactDOM.render(content, el);
    return el;
  };

  const getColumnHeader = (date: DateInput): string => {
    const momentDay = moment(date);
    const result = (
      <div className="column column-header column-date column-lg">
        <div className="column-date-day">{momentDay.format("ddd")}</div>
        <div className="column-date-date">{momentDay.format("D.M.YYYY")}</div>
      </div>
    );
    return ReactDOMServer.renderToString(result);
  };

  const getCarTestClassNames = (carTest: ICarTest): string => {
    const eventClasses = [];

    // carTest causes conflicts
    if (carTest.carTestConflicts && carTest.carTestConflicts.length)
      eventClasses.push("fullcalendar-event--conflict");
    // carTest has not assigned enough workers
    if (
      !carTest.assignedWorkers ||
      carTest.workerCount > carTest.assignedWorkers?.length
    )
      eventClasses.push("fullcalendar-event--missing-worker");
    if (carTest.testStateId === CarTestStates.Completed)
      eventClasses.push("fullcalendar-event--completed");
    if (carTest.testStateInDelay) {
      eventClasses.push("fullcalendar-event--late");
    }
    if (carTest.testStateId === CarTestStates.Ongoing) {
      eventClasses.push("fullcalendar-event--ongoing");
      // if (isInThePast(carTest.endAt, TEST_TOLERATION_MINUTES))
      // eventClasses.push("fullcalendar-event--late");
    }
    if (
      carTest.testStateId === CarTestStates.Planned &&
      carTest.assignedWorkers &&
      carTest.assignedWorkers.length === carTest.workerCount
    ) {
      eventClasses.push("fullcalendar-event--planned");
      // if (isInThePast(carTest.startAt, TEST_TOLERATION_MINUTES))
      //   eventClasses.push("fullcalendar-event--late");
    }
    return eventClasses.join(" ");
  };

  const getAnalysisClassNames = (
    analysis: ICarAnalysis,
    term: ICarAnalysisTermList
  ): string => {
    const eventClasses = ["fullcalendar-analysis"];

    // analysis term causes conflicts
    if (
      analysis.carTestConflicts?.find(
        (x) => x.carAnalysisTermId == term.carAnalysisTermId
      )
    )
      eventClasses.push("fullcalendar-analysis--conflict");

    return eventClasses.join(" ");
  };

  const transformToBlockEventForm = (
    blockEvents: IBlockingEvent[],
    className: string
  ): EventInput[] => {
    let blockEventsTransformed: EventInput[] = [];

    for (const b of blockEvents) {
      const startDate = new Date(b.startDate);
      const endDate = new Date(b.endDate);
      for (
        let date = startDate;
        date <= endDate;
        date.setDate(date.getDate() + 1)
      ) {
        blockEventsTransformed.push({
          // it is nessessary to set start and end so the event will be positioned properly
          title: b.description,
          start: combineDateTime(date, b.startTime, 0, 0),
          end: combineDateTime(date, b.endTime, 23, 59),
          allDay: false,
          className: `fullcalendar-blockingevent--${className}`,
          // predani dodatecnych props pro vykresleni v eventu
          extendedProps: {
            eventType: CalendarEventType.BlockingEvent,
            blockingEvent: b,
            history: history,
            calendarComponentRef: calendarComponentRef,
          },
        });
      }
    }
    return blockEventsTransformed;
  };

  const transformToVacationForm = (
    blockEvents: IBlockingEvent[]
  ): EventInput[] => {
    let blockEventsTransformed: EventInput[] = [];

    for (const b of blockEvents) {
      // u vicedennich absenci nutno pridat ke konci 1 den, jinak se v kalendari vykresli o jeden den kratsi
      let endDate = new Date(b.endDate);
      if (b.startDate !== b.endDate) endDate.setDate(endDate.getDate() + 1);
      else endDate.setDate(combineDateTime(endDate, b.endTime, 23, 59));
      blockEventsTransformed.push({
        // it is nessessary to set start and end so the event will be positioned properly
        title: b.description,
        start: combineDateTime(b.startDate, b.startTime, 0, 0),
        end: endDate,
        allDay: true,
        className: "fullcalendar-blockingevent--vacation",
        // predani dodatecnych props pro vykresleni v eventu
        extendedProps: {
          eventType: CalendarEventType.Vacation,
          blockingEvent: b,
          history: history,
        },
      });
    }
    return blockEventsTransformed;
  };

  const transformToHolidayForm = (holidays: IHoliday[]): EventInput[] => {
    let holidaysTransformed: EventInput[] = [];

    for (const h of holidays) {
      // u vicedennich absenci nutno pridat ke konci 1 den, jinak se v kalendari vykresli o jeden den kratsi
      let date = new Date(h.date);

      holidaysTransformed.push({
        // it is nessessary to set start and end so the event will be positioned properly
        title: h.name,
        start: combineDateTime(date, undefined, 0, 0),
        end: combineDateTime(date, undefined, 23, 59),
        allDay: true,
        className: "fullcalendar-blockingevent--vacation",
        // predani dodatecnych props pro vykresleni v eventu
        extendedProps: {
          eventType: CalendarEventType.Vacation,
          holiday: h,
          history: history,
        },
      });
    }
    return holidaysTransformed;
  };

  const transformToAnalysisForm = (
    analysis: ICarAnalysisTermList[],
    result: any
  ): EventInput[] => {
    let analysisTransformed: EventInput[] = [];

    let activeAnalysis = analysis.filter(
      (x) =>
        x.isActive ||
        (x.termStateId != CarTestStates.Cancelled &&
          x.termStateId != CarTestStates.DoNotPerform)
    );
    for (const analysisTerm of activeAnalysis) {
      let car = result.cars.filter(
        (c: any) => c.carId === analysisTerm.carId
      )[0];
      let analysis = result.carAnalyses.find(
        (ca: ICarAnalysis) => ca.carAnalysisId == analysisTerm.carAnalysisId
      );
      analysisTransformed.push({
        // it is nessessary to set start and end so the event will be positioned properly
        title: car.vin,
        start: analysisTerm.startAt,
        end: analysisTerm.endAt,
        allDay: false,
        className: getAnalysisClassNames(analysis, analysisTerm),
        // predani dodatecnych props pro vykresleni v eventu
        extendedProps: {
          eventType: CalendarEventType.Analysis,
          car: car,
          carAnalyses: analysis,
          carAnalysisTerm: analysisTerm,
          history: history,
          groupWorkers: expertGroupUsers.filter((w) => w.isActive),
          calendarComponentRef: calendarComponentRef,
        },
      });
    }
    return analysisTransformed;
  };

  return (
    <div>
      <FullCalendar
        ref={calendarComponentRef}
        defaultView="timeGridWeek"
        plugins={[timeGridPlugin, momentPlugin]}
        locale={csLocale}
        nowIndicator={true}
        minTime={"06:00"}
        maxTime={"19:00"}
        eventLimit={true}
        allDayText={`Absence`}
        navLinks={true}
        eventRender={eventRender}
        contentHeight={"auto"}
        defaultDate={defaultDateFrom}
        // determines if timed events in TimeGrid view should visually overlap.
        slotEventOverlap={false}
        columnHeaderHtml={(date: DateInput) => getColumnHeader(date)}
        weekNumbers={true}
        customButtons={{
          toggleAbsences: {
            text: "Absence & Blokace",
            click: function () {
              openModal(
                <BlockingEventForm
                  expertGroupId={expertGroupId || 1}
                  callback={addAbsence}
                  workerOptions={expertGroupUsers
                    .filter((w) => w.isActive)
                    .map((w) => ({
                      key: w.userId,
                      value: w.fullName,
                    }))}
                />,
                {
                  preventClosing: true,
                  title: "Zadání nové absence nebo blokace",
                }
              );
            },
          },
          toggleAnalysis: {
            text: "Analýza vozu",
            click: function () {
              const carAnalysis = CreateNewCarAnalysis(expertGroupId || 1);
              openModal(
                <CarAnalysisForm
                  aExpertGroupId={expertGroupId || 1}
                  aCarAnalysis={carAnalysis}
                  aCallback={addAbsence}
                  aWorkerOptions={expertGroupUsers
                    .filter((w) => w.isActive)
                    .map((w) => ({
                      key: w.userId,
                      value: w.fullName,
                    }))}
                  isFormEditable={checkAuthRule(
                    user?.userRoleId,
                    "car-analysis:edit",
                    {
                      userExpertGroupId: user?.expertGroupId,
                      expertGroupId: carAnalysis.expertGroupId,
                      managedExpertGroups: user?.managedGroups,
                    }
                  )}
                />,
                {
                  preventClosing: true,
                  title: "Zadání nové analýzy vozu",
                  size: "xl",
                }
              );
            },
          },
        }}
        header={{
          left: "title",
          center: "",
          right: `prev,today,next ${
            checkAuthRule(user?.userRoleId, "blocking-event:create")
              ? "toggleAbsences"
              : ""
          } ${
            checkAuthRule(user?.userRoleId, "car-analysis:edit",
            {
              userExpertGroupId: user?.expertGroupId,
              expertGroupId: expertGroupId || 1,
              managedExpertGroups: user?.managedGroups,
            })
              ? "toggleAnalysis"
              : ""
          } timeGridWeek,timeGridDay`,
        }}
        views={{
          week: {
            titleFormat: "[KT] W/YYYY",
          },
          day: {
            titleFormat: "dddd D.M.YYYY",
          },
        }}
        events={async function (fetchInfo, successCallback) {
          const { start, end } = fetchInfo;
          const result= await queryClient.fetchQuery(createGroupScheduleOptions(expertGroupId, start, end, userId))
          if (!result) return;
          successCallback([
            ...result.carTests.map((t) => ({
              // it is nessessary to set start and end to be properly so event will be positioned properly
              title: t.carVIN,
              start: t.startAt,
              end: t.endAt,
              // classes related to events must be passed here
              className: getCarTestClassNames(t),
              // additional props nessessary for event rendering
              extendedProps: {
                eventType: CalendarEventType.CarTest,
                carTest: t,
                car: result.cars.filter((c) => c.carId === t.carId)[0],
                history: history,
              },
            })),
            //absence
            ...result.blockingEvents
              .filter((b) => b.blockingEventTypeId === 1)
              .map((b) => {
                // u vicedennich absenci nutno pridat ke konci 1 den, jinak se v kalendari vykresli o jeden den kratsi
                let endDate = new Date(b.endDate);
                if (b.startDate !== b.endDate)
                  endDate.setDate(endDate.getDate() + 1);
                else
                  endDate.setDate(combineDateTime(endDate, b.endTime, 23, 59));
                return {
                  // it is nessessary to set start and end so the event will be positioned properly
                  title: b.description,
                  start: combineDateTime(b.startDate, b.startTime, 0, 0),
                  end: endDate,
                  allDay: true,
                  // predani dodatecnych props pro vykresleni v eventu
                  extendedProps: {
                    eventType: CalendarEventType.BlockingEvent,
                    blockingEvent: b,
                    history: history,
                    calendarComponentRef: calendarComponentRef,
                  },
                };
              }),
            //blokace
            ...transformToBlockEventForm(
              result.blockingEvents.filter((b) => b.blockingEventTypeId === 2),
              "blokace"
            ),
            //celozavodni dovolena
            ...transformToVacationForm(
              result.blockingEvents.filter((b) => b.blockingEventTypeId === 3)
            ),
            //udalosti
            ...transformToBlockEventForm(
              result.blockingEvents.filter((b) => b.blockingEventTypeId === 4),
              "udalost"
            ),
            //svatky
            ...transformToHolidayForm(result.holidays),
            //analyzy
            ...transformToAnalysisForm(result.carAnalysisTerms, result),
          ]);
        }}
      />
    </div>
  );
};

export default GroupFullCalendar;
