import "./PrNumbersTab.scss";

import React, { useContext, useState} from "react";

import { Tab, Tabs } from "react-bootstrap";
import {IEnums} from "../../../app/models/enum";

import {observer} from "mobx-react-lite";

import {RootStoreContext} from "../../../app/stores/rootStore";

import PrNumbersSyncStatesTab from "../prNumbers/PrNumbersSyncStatesTab"
import PrNumbersCategoriesTab from "../prNumbers/PrNumbersCategoriesTab"
import PrNumbersProductsTab from "../prNumbers/PrNumbersProductsTab"
import PrNumbersFamiliesTab from "../prNumbers/PrNumbersFamiliesTab";
import PrNumbersTab from "../prNumbers/PrNumbersTab"
import PrNumbersByProductTab from "../prNumbers/PrNumbersByProductTab"

interface IProps {
  enums: IEnums | undefined;
}

const PrNumbersSettingsTab: React.FC<IProps> = ({ enums }) => {
  
  const rootStore = useContext(RootStoreContext);
  const {user} = rootStore.authStore;
 
  const [key, setKey] = useState("prNumberSyncStates");

  return (
    <div>
      <h2>PR popis vozů</h2>
      <div id="prNumbers-tabs">
        <Tabs
          id="prNumbers-config-tabs"
          className="tabs"
          activeKey={key}
          onSelect={(k: string) => setKey(k)}
          mountOnEnter={true}
          unmountOnExit={true}
        >
          <Tab eventKey="prNumberSyncStates" title="Stav synchronizace" className="tabs__tab">
            <PrNumbersSyncStatesTab />
          </Tab>
          <Tab eventKey="prNumberCategories" title="Kategorie PR čísel" className="tabs__tab">
            <PrNumbersCategoriesTab />
          </Tab>
          <Tab eventKey="prNumberProducts" title="PR čísla Produktů" className="tabs__tab">
            <PrNumbersProductsTab />
          </Tab>
          <Tab eventKey="prNumberFamilies" title="Rodiny PR čísel" className="tabs__tab">
            <PrNumbersFamiliesTab />
          </Tab>
          <Tab eventKey="prNumbers" title="PR čísla" className="tabs__tab">
            <PrNumbersTab />
          </Tab>
          <Tab eventKey="prNumbersByProduct" title="PR čísla k produktu" className="tabs__tab">
            <PrNumbersByProductTab />
          </Tab>
        </Tabs>
      </div>
    </div>
  );
};

export default observer(PrNumbersSettingsTab);
