import {IDefect, IProtocolPartRule} from "../../../../../app/models/protocol";
import moment from "moment";
import DefectForm from "./DefectForm";
import React from 'react';
import {IEnums} from "../../../../../app/models/enum";

export const defectOptions = [
    { key: null, value: "" },
    { key: 1, value: "1 - A1" },
    { key: 2, value: "2 - A" },
    { key: 3, value: "3 - A" },
    { key: 4, value: "4 - B1" },
    { key: 5, value: "5 - B" },
    { key: 6, value: "6 - C1" },
    { key: 7, value: "7 - C" },
    { key: 8, value: "8 - C" },
    { key: 9, value: "9 - C" },
    { key: 10, value: "10 - C" },
]

export const addOrUpdateDefect = (defect: IDefect, defects: IDefect[], setDefects: (defects: IDefect[]) => void) => {
    let existingDefect = defects?.find(
        (x) => x.rownumber == defect.rownumber
    );
    if (existingDefect) {
        const index = defects.findIndex(
            (x) => x.rownumber == defect.rownumber
        );
        if (defects && index) {
            defects[index] = defect;
            setDefects([...defects]);
        }
    } else {
        setDefects([...defects, defect]);
    }
};

export const handleModal = (
    partRule: IProtocolPartRule,
    defects: IDefect[],
    expertGroupCode: string,
    startAt: Date,
    openModal: (...args: any) => void,
    isEdit: boolean,
    handleDefect: (defect: IDefect) => void,
    enums: IEnums,
) => {
    const protocolRuleId = partRule.protocolRuleId;
    let defect: IDefect | undefined = defects.find(
        (x) => x.protocolRuleId == protocolRuleId && !x.isDeleted
    );
    if (!defect) {
        let rowNumber = 0;
        if (defects.length > 0) {
            const filteredArray = defects
                .filter((x) => x.rownumber !== undefined)
                .map((x) => (x.rownumber ? x.rownumber : 0));
            if (filteredArray && filteredArray.length > 0) {
                rowNumber = Math.max.apply(
                    Math,
                    filteredArray.map((x) => x)
                );
                rowNumber = rowNumber + 1;
            }
        }
        const testDate = moment(startAt);
        defect = {
            protocolRuleId: protocolRuleId,
            signDefect: undefined,
            assignedWorkers: [],
            expertGroupCode: expertGroupCode,
            carTestDate: testDate.toDate(),
            description: undefined,
            analysis: undefined,
            remedy: undefined,
            deadline: undefined,
            responsible: undefined,
            state: undefined,
            rownumber: rowNumber,
            partName: partRule.name,
            isDeleted: false,
        };
    }
    openModal(
        <DefectForm
            isEdit={isEdit}
            defect={defect}
            enums={enums}
            handleDefect={handleDefect}
        />,
        {
            preventClosing: true,
            title: "Komentáře k závadám",
        }
    );
};
