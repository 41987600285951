import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWarehouse } from "@fortawesome/free-solid-svg-icons";

interface IProps {
  id: number;
  style?: React.CSSProperties;
}
const CarNotLoaded: React.FC<IProps> = ({ id, style }) => {
  return (
    <div style={style}>
      <OverlayTrigger
        placement="right"
        overlay={
          <Tooltip id={`tooltip-not-loaded-${id}`}>Vůz není naskladněn</Tooltip>
        }
      >
        <FontAwesomeIcon icon={faWarehouse} className="icon icon--incomplete" />
      </OverlayTrigger>
    </div>
  );
};

export default CarNotLoaded;
