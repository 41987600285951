import React from "react";
import {ICheckBoxValues, IRidingNoisesProtocol, IProtocolCodeTableValues} from "../../../../app/models/protocol";
import {Field, useForm} from "react-final-form";
import {Col, Row} from "react-bootstrap";
import SelectInput from "../../../../app/common/form/SelectInput";
import CheckboxGroupInput from "../../../../app/common/form/CheckboxGroupInput";

interface IProps {
    enumCodeTableValues: ICheckBoxValues[];
    protocolCodeTableValues: IProtocolCodeTableValues[];
    isEdit: boolean;
}

export const TrackWeatherConditions: React.FC<IProps> =
    ({enumCodeTableValues, protocolCodeTableValues, isEdit}) => {
        const form = useForm<IRidingNoisesProtocol>();
        const isDisabled: boolean = form.getFieldState('executedTestArea.notPerform')?.value;
        return (
            <Row className='mt-2'>
                <Col sm='3'>
                    <Field
                        name={`executedTestArea.partRules[0].selectedValue`}
                        size="sm"
                        placeholder="Trať"
                        options={protocolCodeTableValues.map((value, index) => {
                            return {
                                key: value.id,
                                value: value.codeTableValue,
                            }
                        })}
                        component={SelectInput}
                        isEmptyOption={true}
                        disabled={!isEdit || isDisabled}
                    />
                </Col>
                {enumCodeTableValues.map((condition, weatherConditionIndex) =>
                    (
                        <Col className={'col-sm-auto'} key={weatherConditionIndex}>
                            <Field
                                name={`executedTestArea.partRules[0].enumCodeTableValues[${weatherConditionIndex}].checked`}
                                initialValue={false}
                                title={condition.name}
                                type="checkbox"
                                component={CheckboxGroupInput}
                                disabled={!isEdit || isDisabled}
                            />
                        </Col>
                    ))}
            </Row>
        );
    }
