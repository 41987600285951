import React, { useContext, useEffect, useState, Fragment } from "react";
import { Form, Table, Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Field, Form as FinalForm } from "react-final-form";
import { RootStoreContext } from "../../../app/stores/rootStore";
import TextInput from "../../../app/common/form/TextInput";
import SwitchInput from "../../../app/common/form/SwitchInput";
import AlertMessage from "../../alert/AlertMessage";
import EditSaveResetButtons from "../../ButtonGroups/EditSaveResetButtons";
import { IProtocolAreasSetting, IAreaSettingDTO, AreaEnumDTO } from "../../../app/models/protocol";
import NumberInput from "../../../app/common/form/NumberInput";
import { observer } from "mobx-react-lite";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

interface IProps {
  protocolTypeId?: number;
  handleOnChangeProtocolAreas: (updatedAreas:  AreaEnumDTO[]) => void
}

const ProtocolAreaTab: React.FC<IProps> = ({ protocolTypeId, handleOnChangeProtocolAreas }) => {
  const rootStore = useContext(RootStoreContext);
  const {
    loadProtocolAreaSettings,
    saveProtocolAreaSettings,
    protocolAreaRegistry
  } = rootStore.protocolStore;
  const [protocolAreaSetting, setProtocolAreaSetting] = useState<IProtocolAreasSetting>();
  const [protocolCurrentAreas, setCurrentAreas] = useState<IAreaSettingDTO[]>([]);
  const [protocolNewAreas, setNewAreas] = useState<IAreaSettingDTO[]>([]);

  useEffect(() => {
    if(protocolTypeId){
      loadProtocolAreaSettings(protocolTypeId).then(() => {
        const protocolAreaSetting = protocolAreaRegistry.get(protocolTypeId);
        if(protocolAreaSetting){
          setCurrentAreas(protocolAreaSetting?.areasSetting.map(
          (p, index: number) => {
            p.rownumber = index;
            return p;
          }
        ) || []);
        setProtocolAreaSetting(protocolAreaSetting);
        }
      });
    }
    
  }, [loadProtocolAreaSettings, protocolTypeId]);

  const [edit, setEdit] = useState(false);
  const [errors, setErrors] = useState([]);
  const [showSubmitError, setShowSubmitError] = useState(false);

  const handleSubmitForm = (values: IProtocolAreasSetting) => {
    setErrors([]);
    setShowSubmitError(false);
    if(protocolTypeId){
    values.protocolTypeId = protocolTypeId;
    saveProtocolAreaSettings(values)
      .then(() => {
        const protocolAreaSetting = protocolAreaRegistry.get(protocolTypeId);
        if(protocolAreaSetting){
          setProtocolAreaSetting(protocolAreaSetting);
          setCurrentAreas(protocolAreaSetting?.areasSetting.map(
          (p, index: number) => {
            p.rownumber = index;
            return p;
          }
        ) || []);
        setEdit(false);
        const updatedAreas: AreaEnumDTO[] = protocolAreaSetting?.areasSetting.map((area) => ({ 
            key: area.protocolAreaId,
            value: area.protocolAreaName,
            isActive: area.isActive
          }));
        handleOnChangeProtocolAreas(updatedAreas);
        setNewAreas([]);
      }})
      .catch((error: any) => {
        console.log(error);
        setErrors(
          error.response?.data?.errors &&
            Object.values(error.response?.data?.errors)
        );
        setShowSubmitError(true);
      });
    }
  };

  // Reset all form values to initial values
  const handleReset = (form: any) => {
    setEdit(false);
    setNewAreas([]);
    form.reset();
  };

  const handleAddAreaManually = () => {
    var maxOrder: number = 0;
    var currentOrder: number = 0;
    var newOrder: number = 0;
    var maxRownumber: number = 0;
    var currentRownumber: number = 0;
    var newRownumber: number = 0;
    if(protocolCurrentAreas && protocolCurrentAreas.length > 0)
    {
      var localCurrentOrder = Math.max.apply(Math, protocolCurrentAreas.map(function(o) { return o.protocolAreaOrder; }));
      if (isFinite(localCurrentOrder)) {
        currentOrder = localCurrentOrder;
      }
      var localCurrentRownumber = Math.max.apply(Math, protocolCurrentAreas.map(function(o) { return o.rownumber; }));
      if (isFinite(localCurrentRownumber)) {
        currentRownumber = localCurrentRownumber;
      }
    }
    if(protocolNewAreas && protocolNewAreas.length > 0)
    {
      var localNewOrder = Math.max.apply(Math, protocolNewAreas.map(function(o) { return o.protocolAreaOrder; }))
      if (isFinite(localNewOrder)) {
        newOrder = localNewOrder;
      }
      var localNewRownumber = Math.max.apply(Math, protocolNewAreas.map(function(o) { return o.rownumber; }));
      if (isFinite(localNewRownumber)) {
        newRownumber = localNewRownumber;
      }
    }

    maxOrder = currentOrder > newOrder ? currentOrder : newOrder;
    maxRownumber = currentRownumber > newRownumber ? currentRownumber : newRownumber;

    const newArea: IAreaSettingDTO = {
      protocolAreaId: 0,
      protocolAreaName: "",
      isActive: true,
      protocolAreaOrder: maxOrder + 10,
      rownumber: maxRownumber + 1
    };
    setNewAreas([...protocolNewAreas, newArea]);
  };

  return (
    <div>
      <FinalForm
        onSubmit={handleSubmitForm}
        initialValues={protocolAreaSetting}
        render={({ handleSubmit, form }) => (
          <Form onSubmit={handleSubmit}>
            <div className="pageheader">
              <div className="pageheader__left">
                <h3>Konfigurace oblastí na protokolech</h3>
              </div>
              <div className="pageheader__right">
                {edit && (
                  <Fragment>
                  <OverlayTrigger
                    overlay={
                      <Tooltip id={`tooltip-addbutton`}>Přidat oblast</Tooltip>
                      }
                      >
                      <Button
                        style={{ marginRight: "1rem" }}
                        variant="primary"
                        onClick={() => handleAddAreaManually()}
                        className="footer__btn-add"
                      >
                        <FontAwesomeIcon icon={faPlus} />
                      </Button>
                    </OverlayTrigger>
                    </Fragment>
                )}
                {!!protocolAreaSetting && protocolAreaSetting.areasSetting && (
                  <EditSaveResetButtons
                    edit={edit}
                    setEdit={setEdit}
                    handleReset={handleReset}
                    form={form}
                    isSaving={false}
                  />
                )}
              </div>
            </div>
            <AlertMessage
              type="danger"
              heading="Nastala chyba při ukládání"
              show={showSubmitError}
              setShow={setShowSubmitError}
              messageList={errors}
            />
            <div className="protocol-table">
              <Table>
                <thead>
                  <tr>
                    <th>Název oblasti</th>
                    <th>Pořadí</th>
                    <th>Aktivní</th>
                  </tr>
                </thead>
                <tbody>
                   {protocolCurrentAreas
                    .map((area, index) => (
                      <tr key={area.protocolAreaId}>
                        <td>
                          <Field
                            name={`areasSetting[${area.rownumber}].protocolAreaId`}
                            initialValue={area.protocolAreaId}
                            component="input"
                            hidden
                            disabled
                          />
                          <Field
                            name={`areasSetting[${area.rownumber}].protocolAreaName`}
                            initialValue={area.protocolAreaName}
                            component={TextInput}
                            titleWidth={0}
                            fieldWidth={12}
                            disabled={!edit}
                            size={"sm"}
                            validate={(value) =>
                              value ? undefined : "Název nesmí být prázdný"
                            }
                          />
                        </td>
                        <td className="protocol-table--order-column">
                          <Field
                            name={`areasSetting[${area.rownumber}].protocolAreaOrder`}
                            initialValue={area.protocolAreaOrder}
                            component={NumberInput}
                            titleWidth={0}
                            fieldWidth={12}
                            disabled={!edit}
                            size={"sm"}
                            validate={(value) =>
                              value ? undefined : "Pořadí nesmí být prázdné"
                            }
                          />
                        </td>
                        <td className="protocol-table--order-column">
                          <Field
                            name={`areasSetting[${area.rownumber}].isActive`}
                            options={[
                              { key: 1, value: "ano" },
                              { key: 0, value: " ne " },
                            ]}
                            format={(v) => (v ? 1 : 0)}
                            parse={(v) => (v ? true : false)}
                            component={SwitchInput}
                            disabled={!edit}
                          />
                        </td>
                      </tr>
                    ))}
                    {edit && protocolNewAreas && protocolNewAreas?.length > 0 && (
                      protocolNewAreas?.map((newArea, index) => (
                        <tr key={newArea.protocolAreaId}>
                          <td>
                            <Field
                              name={`areasSetting[${newArea.rownumber}].protocolAreaId`}
                              initialValue={newArea.protocolAreaId}
                              component="input"
                              hidden
                              disabled
                            />
                            <Field
                              name={`areasSetting[${newArea.rownumber}].protocolAreaName`}
                              initialValue={newArea.protocolAreaName}
                              component={TextInput}
                              titleWidth={0}
                              fieldWidth={12}
                              disabled={!edit}
                              size={"sm"}
                              validate={(value) =>
                                value ? undefined : "Název nesmí být prázdný"
                              }
                            />
                          </td>
                          <td className="protocol-table--order-column">
                            <Field
                              name={`areasSetting[${newArea.rownumber}].protocolAreaOrder`}
                              initialValue={newArea.protocolAreaOrder}
                              component={NumberInput}
                              titleWidth={0}
                              fieldWidth={12}
                              disabled={!edit}
                              size={"sm"}
                              validate={(value) =>
                                value ? undefined : "Pořadí nesmí být prázdné"
                              }
                            />
                          </td>
                        </tr>
                    )))}
                </tbody>
              </Table>
            </div>
            <div className="pageheader">
              <div className="pageheader__left">
              </div>
              <div className="pageheader__right">
                {edit && (
                  <Fragment>
                  <OverlayTrigger
                    overlay={
                      <Tooltip id={`tooltip-addbutton`}>Přidat oblast</Tooltip>
                      }
                      >
                      <Button
                        style={{ marginRight: "1rem" }}
                        variant="primary"
                        onClick={() => handleAddAreaManually()}
                        className="footer__btn-add"
                      >
                        <FontAwesomeIcon icon={faPlus} />
                      </Button>
                    </OverlayTrigger>
                    </Fragment>
                )}
                {!!protocolAreaSetting && protocolAreaSetting.areasSetting && protocolAreaSetting.areasSetting.length > 0 && (
                  <EditSaveResetButtons
                    edit={edit}
                    setEdit={setEdit}
                    handleReset={handleReset}
                    form={form}
                    isSaving={false}
                  />
                )}
              </div>
            </div>
          </Form>
        )}
      />
    </div>
  );
};

export default observer(ProtocolAreaTab);