import React from "react";
import {  OverlayTrigger, Tooltip } from "react-bootstrap";
import moment from "moment";
import "./EmissionDate.scss";

interface IProps {
  id: number;
  planned: boolean;
  dateFrom: Date | undefined;
  dateTo: Date | undefined;
  style?: React.CSSProperties | undefined;
  cssClass?: string | undefined;
}

const EmissionDate: React.FC<IProps> = ({
  id,
  planned,
  dateFrom,
  dateTo,
  style,
  cssClass
}) => {

  let cssClassEmissionDate = `emission-date ${ !!cssClass ? `${cssClass}` : "" }`;

  const description = planned
    ? "Termín plánované emisní zkoušky"
    : "Termín emisní zkoušky";
  return (
    <div className={cssClassEmissionDate} style={style}>
      <OverlayTrigger
        placement="auto"
        overlay={<Tooltip id={`tooltip-priority-${id}`}>{description}</Tooltip>}
      >
        <span >
          {/* <Badge pill variant="success">
            E
          </Badge> */}
          {dateFrom || dateTo? "E ":""}
          {dateFrom && moment(dateFrom).format("D.M.YY")}
          {dateFrom && " - "}
          {dateTo && moment(dateTo).format("D.M.YY")}
          {!dateTo && !!dateFrom && <span>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span> }
        </span>
      </OverlayTrigger>
    </div>
  );
};

export default EmissionDate;
