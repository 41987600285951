import React from "react";
import {Field} from "react-final-form";
import {Col, Container, Form, Row} from "react-bootstrap";
import TextInput from "../../../../app/common/form/TextInput";
import './MileageArea.scss'
import DateInput from "../../../../app/common/form/DateInput";
import moment from "moment/moment";

interface IProps {
    isEdit: boolean;
    engineNumber: string;
}

const MileageArea: React.FC<IProps> = ({isEdit, engineNumber}) => {
    return (
        <div
            className='mileage'>
            <Container style={{paddingLeft: 0, paddingRight: 0,}}>
                <Row noGutters>
                    <Col sm={2}></Col>
                    <Col sm={3}>
                        <small className="text-muted">
                            Před zkouškou
                        </small>
                    </Col>
                    <Col sm={3}>
                        <small className="text-muted">
                            Po zkoušce
                        </small>
                    </Col>
                    <Col></Col>
                </Row>
                <Row noGutters>
                    <Col sm={2}>
                        <Form.Label>
                            Stav tachometru (km)
                        </Form.Label>
                    </Col>
                    <Col sm={3}>
                        <Field
                            name="additionalInfoArea.additionalInfoValue.speedometerValueBefore"
                            component={TextInput}
                            disabled={!isEdit}
                            fieldWidth={8}
                        />
                    </Col>
                    <Col sm={3}>
                        <Field
                            name="additionalInfoArea.additionalInfoValue.speedometerValueAfter"
                            component={TextInput}
                            disabled={!isEdit}
                            fieldWidth={8}
                        />
                    </Col>
                    <Col>
                        <Field<string>
                            key='sequenceNumber'
                            name={`additionalInfoArea.additionalInfoValue.sequenceNumber`}
                            title="Pořadové číslo"
                            component={TextInput}
                            disabled={!isEdit}
                            maxLength={40}
                            titleWidth={4}
                            fieldWidth={4}
                        />
                    </Col>
                </Row>
                <Row noGutters>
                    <Col sm={2}>
                        <Form.Label>
                            Číslo motoru
                        </Form.Label>
                    </Col>
                    <Col sm={3}>
                        <Field<string>
                            key='sequenceNumber'
                            name={`additionalInfoArea.additionalInfoValue.engineNumber`}
                            component={TextInput}
                            disabled={!isEdit}
                            maxLength={40}
                            fieldWidth={12}
                        />
                    </Col>
                    <Col sm={3}></Col>
                    <Col>
                        <Field
                            name={`additionalInfoArea.additionalInfoValue.dateM200`}
                            title="Datum M200"
                            component={DateInput}
                            format={(val) => (val ? moment(val).toDate() : undefined)}
                            disabled={!isEdit}
                            titleWidth={4}
                            fieldWidth={4}
                        />
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default MileageArea;
