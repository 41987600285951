import {v4 as uuidv4} from 'uuid';

export interface ICopsUsage {
  copsUsageId: number;
  testTypeId: number | undefined;
  code: string;
  name: string;
  isActive: boolean;
}

export interface ICopsProjectType {
  copsProjectTypeId: number;
  testPredestinationId: number | undefined;
  code: string;
  name: string;
}

export interface ICopsSeriesType {
  copsSeriesTypeId: number;
  testDestinationId: number | undefined;
  code: string;
  name: string;
  isActive: boolean;
}

export interface ICopsUsageList {
  copsUsages: ICopsUsage[];
  count: number;
}

export interface ICopsProjectTypeList {
  copsProjectTypes: ICopsProjectType[];
  count: number;
}

export interface ICopsSeriesTypeList {
  copsSeriesTypes: ICopsSeriesType[];
  count: number;
}

export interface ICopsUsageForm {
  copsUsageId: number;
  testTypeId: number | undefined;
  isActive: boolean;
}

export interface ICopsProjectTypeForm {
  copsProjectTypeId: number;
  testPredestinationId: number | undefined;
}

export interface ICopsSeriesTypeForm {
  copsSeriesTypeId: number;
  testDestinationId: number | undefined;
  isActive: boolean;
}

export interface ICopsCarConditionDTO {
  places: IPlaceDTO[];
  testDestinations: ITestDestination[];
  copsCarConditions: ICopsCarCondition[];
}

export interface IPlaceDTO {
  placeId: number;
  placeCode: string;
  placeName: string;
  carKNRMarking: string;
  fullCode: string;
}

export interface ITestDestination {
  testDestinationId: number;
  code: string;
  copsCode: string;
  name: string;
  fullName: string;
}

export interface ICopsCarCondition {
  copsCarConditionId?: number;
  uid: string;
  placeId: number;
  placeFullCode: string;
  carVdsCode: string;
  carModelId?: number;
  carModelCode: string;
  carModelName: string;
  carBodyTypeId?: number;
  carBodyTypeCode: string;
  carBodyTypeName: string;
  testDestinationId?: number;
  testDestinationCode: string;
  testDestinationCopsCode: string;
  testDestinationName: string;
  customerCode: string;
  countCarLastSync: number;
  lastModified?: Date;
  isModified?: boolean;
  isActive: boolean;
}

export interface ICopsCarConditionsDTO {
  copsCarConditions: ICopsCarCondition[];
}

export class CopsCarCondition implements ICopsCarCondition {
  copsCarConditionId = undefined;
  uid: string = uuidv4();
  placeId: number = 1;
  placeFullCode: string = "";
  carVdsCode: string = "";
  carModelId?: number = undefined;
  carModelCode: string = "";
  carModelName: string = "";
  carBodyTypeId?: number = undefined;
  carBodyTypeCode: string = "";
  carBodyTypeName: string = "";
  testDestinationId?: number = undefined;
  testDestinationCode: string = "";
  testDestinationCopsCode: string = "";
  testDestinationName: string = "";
  customerCode: string = "";
  countCarLastSync: number = 0;
  lastModified?: Date | undefined = undefined;
  isModified?: boolean | undefined = undefined;
  isActive: boolean = true;
}
