import { RootStore } from "./rootStore";
import { observable, action, runInAction, computed } from "mobx";
import agent from "../api/agent";
import { ICar, ICarFilter, ICarPagedList } from "../models/car";

export default class PageCarListStore {
  rootStore: RootStore;
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }
  // ids of cars to be shown on Plan car page
  //@observable carIds: number[] = [];

  // flag if data is loading
  @observable isLoading = false;

  pageSize: number = 20;

  @observable carsPage: ICarPagedList = {
    cars: [],
    currentPage: 1,
    pageSize: this.pageSize,
    count: 0,
    totalPages: 0,
    hasNext: false,
    hasPrevious: false,
  };

  // car filters
  @observable carFilter: ICarFilter = {
    vin: "",
    carNumber: "",
    carStates: [], //[{ name: "Registrovaný", id: 1 }],
    testDestinations: [],
    testPredestinations: [],
    testTypes: [],
  };

  @computed get carsSorted() {     
    const cars: ICar[] = [];
    this.carsPage.cars.forEach((car) => {
      let carFromStore = this.rootStore.carStore.carRegistry.get(car.carId);
      if (carFromStore) cars.push(carFromStore);
    });
    return this.sortCarsById(cars);
  }

  sortCarsById(cars: ICar[]) {
    const sortedCars = cars.sort((a, b) => b.carId - a.carId);
    return sortedCars;
  }

  @action loadCars = async (pageNumber:number) => {
    this.isLoading = true;
    this.carsPage.currentPage = pageNumber;
    try {
      const carFilterAsAxiosParams = this.rootStore.carStore.getAxiosCarFilterParams(this.carFilter);
      carFilterAsAxiosParams.append("pageNumber", this.carsPage.currentPage.toString());
      carFilterAsAxiosParams.append("pageSize", this.carsPage.pageSize.toString());
    
      const list = await agent.Cars.list(carFilterAsAxiosParams);
      runInAction("load plan cars", () => {
        list.cars.forEach((car) => {
          // add car to shared car store
          this.rootStore.carStore.carRegistry.set(car.carId, car);

          // add carId to array with cars ids to be shown on the page
        //  this.carIds.push(car.carId);
        });
        this.carsPage = list;
        this.isLoading = false;
      });
      //  this.carIds =this.rootStore.carStore.filterCars( this.carFilter);
    } catch (error) {
      runInAction("load cars error", () => {
        this.isLoading = false;
      });
      throw error;
    }
  };

  @action setCarFilter = (filter: ICarFilter) => {
    this.carFilter = filter;
    this.loadCars(1);
    // this.carIds =this.rootStore.carStore.filterCars( this.carFilter);
  };
}
