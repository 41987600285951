import React, { Fragment, useEffect, useContext, useState } from "react";
import { Table, Form, Row, Col } from "react-bootstrap";
import { Field } from "react-final-form";
import { FormApi } from "final-form";
import TextInput from "../../../../app/common/form/TextInput";
import SimpleTextInput from "../../../../app/common/form/SimpleTextInput";
import { IProtocolArea, IProtocolPartGroup, IProtocolPartRule, IElectroProtocol } from "../../../../app/models/protocol"
import CheckboxInput from "../../../../app/common/form/CheckboxInput";
import PhevHvFunctions  from "./PhevHvFunctions";

interface IProps {
  phevArea: IProtocolArea;  
  isEdit: boolean;
  form: FormApi<IElectroProtocol>;
  values: IElectroProtocol;
}

const PhevArea: React.FC<IProps> = ({
  phevArea,
  isEdit,
  form,
  values,
}) => {
    const [bateryGroup, setBateryGroup] = useState<IProtocolPartGroup | undefined>(undefined);
    const [bateryGroupRowIndex, setBateryGroupRowIndex] = useState(0);
    const [insulationGroup, setInsulationGroup] = useState<IProtocolPartGroup | undefined>(undefined);
    const [insulationGroupRowIndex, setInsulationGroupRowIndex] = useState(0);
    const [disconnectionGroup, setDisconnectionGroup] = useState<IProtocolPartGroup | undefined>(undefined);
    const [disconnectionGroupRowIndex, setDisconnectionGroupRowIndex] = useState(0);
    const [potentialGroup, setPotentialGroup] = useState<IProtocolPartGroup | undefined>(undefined);
    const [potentialGroupRowIndex, setPotentialGroupRowIndex] = useState(0);
    const [functionsGroup, setFunctionsGroup] = useState<IProtocolPartGroup | undefined>(undefined);
    const [functionsGroupRowIndex, setFunctionsGroupRowIndex] = useState(0);
    useEffect(() => {
        
        const bateryGroup = findAreaGroup(56);
        const bateryGroupRowIndex = getRowIndexAreaGroup(56);
        setBateryGroup(bateryGroup);
        setBateryGroupRowIndex(bateryGroupRowIndex);

        const insulationGroup = findAreaGroup(57);
        const insulationGroupRowIndex = getRowIndexAreaGroup(57);
        setInsulationGroup(insulationGroup);
        setInsulationGroupRowIndex(insulationGroupRowIndex);

        const disconnectionGroup = findAreaGroup(58);
        const disconnectionGroupRowIndex = getRowIndexAreaGroup(58);
        setDisconnectionGroup(disconnectionGroup);
        setDisconnectionGroupRowIndex(disconnectionGroupRowIndex);

        const potentialGroup = findAreaGroup(59);
        const potentialGroupRowIndex = getRowIndexAreaGroup(59);
        setPotentialGroup(potentialGroup);
        setPotentialGroupRowIndex(potentialGroupRowIndex);

        const functionsGroup = findAreaGroup(60);
        functionsGroup?.partRules?.map((rule, index) => ( rule.rowNumber = index ));
        const functionsGroupRowIndex = getRowIndexAreaGroup(60);
        setFunctionsGroup(functionsGroup);
        setFunctionsGroupRowIndex(functionsGroupRowIndex);

      },[bateryGroup, bateryGroupRowIndex,
         insulationGroup, insulationGroupRowIndex,
         disconnectionGroup, disconnectionGroupRowIndex,
         potentialGroup, potentialGroupRowIndex,
         functionsGroup, functionsGroupRowIndex]);

    const findAreaGroup = (id: number) : IProtocolPartGroup | undefined =>  {
      const group = phevArea.protocolPartGroups?.find( (x) => x.protocolPartGroupId === id );
      return group;
    };

    const getRowIndexAreaGroup = (id: number) : number =>  {
      var rowIndex = -1;
      phevArea.protocolPartGroups?.map( (x, index) => {
        if(x.protocolPartGroupId === id)
        {
          rowIndex = index;
          return rowIndex;
        }
      });
      return rowIndex;
    };

    const isDisabledPhev = () => {
      return values.phevArea?.notPerform || false;
    }

    const isDisabledBateryGroup = () => {
      return values.phevArea?.notPerform || (values.phevArea && values.phevArea.protocolPartGroups && values.phevArea.protocolPartGroups[bateryGroupRowIndex].notPerform || false)
    }
    const isDisabledPotentialGroup = () => {
      return values.phevArea?.notPerform || (values.phevArea && values.phevArea.protocolPartGroups && values.phevArea.protocolPartGroups[potentialGroupRowIndex].notPerform || false)
    }
    const isDisabledInsulationGroup = () => {
      return values.phevArea?.notPerform || (values.phevArea && values.phevArea.protocolPartGroups && values.phevArea.protocolPartGroups[insulationGroupRowIndex].notPerform || false)
    }
    const isDisabledDisconnectionGroup = () => {
      return values.phevArea?.notPerform || (values.phevArea && values.phevArea.protocolPartGroups && values.phevArea.protocolPartGroups[disconnectionGroupRowIndex].notPerform || false)
    }

    const inlineInputs = (partRule: IProtocolPartRule, ruleIndex: number, isDisabled: boolean ) => (
      <Form.Group>
        <Fragment>
        {partRule.measuredValues &&  
        partRule.measuredValues?.map((value, ruleValueIndex) => (
          <Field<string>
            name={`phevArea.protocolPartGroups[${bateryGroupRowIndex}].partRules[${ruleIndex}].measuredValues[${ruleValueIndex}]`}
            key={`phevArea.protocolPartGroups[${bateryGroupRowIndex}].partRules[${ruleIndex}].measuredValues[${ruleValueIndex}]`}
            component={SimpleTextInput}
            initialValue={value}
            rows={1}
            size="sm"
            inputOnChange={(e: any) => {
              form.mutators.changeMeasuredValues(e);
            }}
            disabled={!isEdit || isDisabled}
            className={`inline-field-${partRule.measuredValues?.length}`}
          />
        ))}
        </Fragment>
      </Form.Group>
    );

    return (
      <div style={ isDisabledPhev() === false ? {pageBreakBefore: "always", pageBreakInside: "avoid"} : undefined }>
        <Table className="protocol-table">
          <thead>
            <tr>
              <th className="headingrow" colSpan={10}><h2>{phevArea.name}</h2></th>        
              <th className={`headingrow ${isDisabledPhev() ? "" : "no-print"}`} colSpan={2}>
                <Field
                  name={`phevArea.notPerform`}
                  title="Neprovedeno"
                  options={[
                      { key: 1, value: "ano" },
                      { key: 0, value: " ne " },
                  ]}
                  format={(v) => (v ? 1 : 0)}
                  parse={(v) => (v ? true : false)}
                  type={"checkbox"}
                  initialValue={phevArea?.notPerform || false}
                  component={CheckboxInput}
                  disabled={!isEdit}
                  labelWidth={8}
                  valueWidth={4}
                />
              </th>
            </tr>
          </thead>
        </Table>

        {bateryGroup && bateryGroup?.partRules &&
          <Fragment>
            <Table className={`protocol-table ${isDisabledPhev() ? "no-print" : ""}`}>
              <thead>
                <tr>
                  <th colSpan={10}><h3>{bateryGroup?.name}</h3></th>
                  <th colSpan={2} className={`${isDisabledBateryGroup() ? "" : "no-print"}`}>
                  <Field
                    name={`phevArea.protocolPartGroups[${bateryGroupRowIndex}].notPerform`}
                    title="Neprovedeno"
                    options={[
                        { key: 1, value: "ano" },
                        { key: 0, value: " ne " },
                    ]}
                    format={(v) => (v ? 1 : 0)}
                    parse={(v) => (v ? true : false)}
                    type={"checkbox"}
                    initialValue={bateryGroup?.notPerform || false}
                    component={CheckboxInput}
                    disabled={!isEdit}
                    labelWidth={8}
                    valueWidth={4}
                  />
                </th>
                </tr>
              </thead>
            </Table>
            <Table className={`protocol-table ${isDisabledBateryGroup() ? "no-print" : ""}`}>
              <thead>
                <tr className={`${isDisabledBateryGroup() ? "disabled" : ""}`}>
                  {bateryGroup?.partRules?.map(
                    (rule) => ( <td key={rule.protocolRuleId}> {rule.name} </td> )
                  )}
                </tr>
              </thead>
              <tbody>
                <tr className={`${isDisabledBateryGroup() ? "disabled" : ""}`}>
                  {bateryGroup?.partRules?.map((rule, ruleIndex) => (
                    <td key={rule.protocolRuleId}>
                    {rule.measuredValues && (
                      <div className="no-print">
                        {inlineInputs(rule, ruleIndex, isDisabledBateryGroup())}
                      </div>
                    )}
                    <Field<string>
                      name={`phevArea.protocolPartGroups[${bateryGroupRowIndex}].partRules[${ruleIndex}].measuredValue`}
                      className={rule.measuredValues && "field-print-only"}
                      component={TextInput}
                      initialValue={rule.measuredValue}
                      rows={1}
                      size="sm"
                      disabled={!isEdit || isDisabledBateryGroup()}
                    />
                  </td>
                  ))}
              </tr>
            </tbody>
          </Table>
          </Fragment>
        }
        
        <Row>
          <Col xs={6}>
          {insulationGroup && 
          <Fragment>
            <Table className={`protocol-table ${isDisabledPhev() ? "no-print" : ""}`}>
              <thead>
                <tr>
                  <th colSpan={3}><h3>{insulationGroup?.name}</h3></th>
                  <th className={`${isDisabledInsulationGroup() ? "" : "no-print"}`}>
                    <Field
                      name={`phevArea.protocolPartGroups[${insulationGroupRowIndex}].notPerform`}
                      title="Neprovedeno"
                      options={[
                          { key: 1, value: "ano" },
                          { key: 0, value: " ne " },
                      ]}
                      format={(v) => (v ? 1 : 0)}
                      parse={(v) => (v ? true : false)}
                      type={"checkbox"}
                      initialValue={insulationGroup?.notPerform || false}
                      component={CheckboxInput}
                      disabled={!isEdit}
                      labelWidth={9}
                      valueWidth={1}
                    />
                  </th>
                </tr>
              </thead>
            </Table>
            <table className={`protocol-table ${isDisabledInsulationGroup() ? "no-print" : ""}`}>
              <tbody>
              {insulationGroup?.partRules?.map((rule, ruleIndex) => (
              <tr key={rule.protocolRuleId}  className={`${isDisabledInsulationGroup() ? "disabled" : ""}`}>
                <td style={{width: "50%"}}>{rule.name}</td>
                <td style={{width: "45%"}}>
                  <Field<string>
                    name={`phevArea.protocolPartGroups[${insulationGroupRowIndex}].partRules[${ruleIndex}].measuredValue`}
                    className={rule.measuredValues && "field-print-only"}
                    component={TextInput}
                    initialValue={rule.measuredValue}
                    rows={1}
                    size="sm"
                    disabled={!isEdit || isDisabledInsulationGroup()}
                  />
                </td>
                <td style={{width: "5%"}}><span>M{String.fromCodePoint(0x02126)}</span></td>
              </tr>
              ))}
              </tbody>
            </table>
            </Fragment>
          }
          {disconnectionGroup && 
          <Fragment>
            <Table className={`protocol-table ${isDisabledPhev() ? "no-print" : ""}`}>
              <thead>
                <tr>
                  <th colSpan={3}><h3>{disconnectionGroup?.name}</h3></th>
                  <th className={`${isDisabledDisconnectionGroup() ? "" : "no-print"}`}>
                    <Field
                      name={`phevArea.protocolPartGroups[${disconnectionGroupRowIndex}].notPerform`}
                      title="Neprovedeno"
                      options={[
                          { key: 1, value: "ano" },
                          { key: 0, value: " ne " },
                      ]}
                      format={(v) => (v ? 1 : 0)}
                      parse={(v) => (v ? true : false)}
                      type={"checkbox"}
                      initialValue={disconnectionGroup?.notPerform || false}
                      component={CheckboxInput}
                      disabled={!isEdit}
                      labelWidth={9}
                      valueWidth={1}
                    />
                  </th>
                </tr>
              </thead>
              </Table>
              <Table className={`protocol-table ${isDisabledDisconnectionGroup() ? "no-print" : ""}`}>
              <tbody>
              {disconnectionGroup?.partRules?.map((rule, ruleIndex) => (
              <tr key={rule.protocolRuleId} className={`${isDisabledDisconnectionGroup() ? "disabled" : ""}`}>
                <td colSpan={2}>{rule.name}</td>
                <td colSpan={2}>
                  <Field<string>
                    name={`phevArea.protocolPartGroups[${disconnectionGroupRowIndex}].partRules[${ruleIndex}].measuredValue`}
                    className={rule.measuredValues && "field-print-only"}
                    component={TextInput}
                    initialValue={rule.measuredValue}
                    rows={1}
                    size="sm"
                    disabled={!isEdit || isDisabledDisconnectionGroup()}
                  />
                </td>
              </tr>
              ))}
              </tbody>
            </Table>
            </Fragment>
          }
          </Col>
          <Col xs={6}>
          {potentialGroup && 
            <Fragment>
            <Table className={`protocol-table ${isDisabledPhev() ? "no-print" : ""}`}>
              <thead>
                <tr>
                  <th colSpan={3}><h3>{potentialGroup?.name}</h3></th>
                  <th className={`${isDisabledPotentialGroup() ? "" : "no-print"}`}>
                    <Field
                      name={`phevArea.protocolPartGroups[${potentialGroupRowIndex}].notPerform`}
                      title="Neprovedeno"
                      options={[
                          { key: 1, value: "ano" },
                          { key: 0, value: " ne " },
                      ]}
                      format={(v) => (v ? 1 : 0)}
                      parse={(v) => (v ? true : false)}
                      type={"checkbox"}
                      initialValue={disconnectionGroup?.notPerform || false}
                      component={CheckboxInput}
                      disabled={!isEdit}
                      labelWidth={9}
                      valueWidth={1}
                    />
                  </th>
                </tr>
              </thead>
            </Table>
            <table className={`protocol-table ${isDisabledPotentialGroup() ? "no-print" : ""}`}>
              <tbody>
              {potentialGroup?.partRules?.map((rule, ruleIndex) => (
              <tr key={rule.protocolRuleId} className={`${isDisabledPotentialGroup() ? "disabled" : ""}`}>
                <td style={{width: "50%"}}>{rule.name}</td>
                <td style={{width: "45%"}}>
                  <Field<string>
                    name={`phevArea.protocolPartGroups[${potentialGroupRowIndex}].partRules[${ruleIndex}].measuredValue`}
                    className={rule.measuredValues && "field-print-only"}
                    component={TextInput}
                    initialValue={rule.measuredValue}
                    rows={1}
                    size="sm"
                    disabled={!isEdit || isDisabledPotentialGroup()}
                  />
                </td>
                <td style={{width: "5%"}}><span>m{String.fromCodePoint(0x02126)}</span></td>
              </tr>
              ))}
              </tbody>
            </table>
            </Fragment>
          }
          </Col>
        </Row>
        <PhevHvFunctions phevFunctionGroup={functionsGroup} phevFunctionGroupIndex={functionsGroupRowIndex} isEdit={isEdit} isDisabledArea={values.phevArea?.notPerform || false} isDisabledGroup={values.phevArea?.notPerform || (values.phevArea && values.phevArea.protocolPartGroups && values.phevArea.protocolPartGroups[functionsGroupRowIndex].notPerform || false)} />
      </div>
  );
};

export default PhevArea;
