import React, { Fragment } from "react";
import { Table } from "react-bootstrap";
import { Field } from "react-final-form";
import TextInput from "../../../../app/common/form/TextInput";
import { IProtocolPartGroup, IProtocolPartRule, IElectroProtocol } from "../../../../app/models/protocol"
import CheckboxInput from "../../../../app/common/form/CheckboxInput";
import { observer } from "mobx-react-lite";

interface IProps {
  phevFunctionGroup: IProtocolPartGroup | undefined;
  phevFunctionGroupIndex: number;
  isEdit: boolean;
  isDisabledArea: boolean;
  isDisabledGroup: boolean;
}

const PhevHvFunctions: React.FC<IProps> = ({
  phevFunctionGroup,
  phevFunctionGroupIndex,
  isEdit,
  isDisabledArea,
  isDisabledGroup,
}) => {

    const findFunctionPartRule = (index: number) : IProtocolPartRule | undefined =>  {
        const rule = phevFunctionGroup?.partRules?.find( (x) => x.rowNumber === index );
        return rule;
    };

    const renderHvFunctionRows = () => {
      let content = [];
      var halfLengthfunctions: number = 0
      if(phevFunctionGroup && phevFunctionGroup.partRules) halfLengthfunctions = Math.ceil(phevFunctionGroup.partRules.length / 2);
      for (let i = 0; i < halfLengthfunctions; i++) {
        var ruleFirst: IProtocolPartRule | undefined = findFunctionPartRule(i);
        var ruleSecond: IProtocolPartRule | undefined = findFunctionPartRule(i + halfLengthfunctions);
        content.push(
        <tr key={i} className={`${isDisabledGroup ? "disabled no-print" : ""}`}>
          <td style={{width: "35%"}}>{ruleFirst?.name}</td>
          <td style={{width: "15%"}}>
            <Field<string>
              name={`phevArea.protocolPartGroups[${phevFunctionGroupIndex}].partRules[${ruleFirst?.rowNumber}].measuredValue`}
              className={ruleFirst?.measuredValues && "field-print-only"}
              component={TextInput}
              initialValue={ruleFirst?.measuredValue}
              rows={1}
              size="sm"
              disabled={!isEdit || isDisabledGroup}
            />
          </td>

          <td style={{width: "35%"}}>{ruleSecond?.name}</td>
          <td style={{width: "15%"}}>{ruleSecond &&
            <Field<string>
              name={`phevArea.protocolPartGroups[${phevFunctionGroupIndex}].partRules[${ruleSecond?.rowNumber}].measuredValue`}
              className={ruleSecond?.measuredValues && "field-print-only"}
              component={TextInput}
              initialValue={ruleSecond?.measuredValue}
              rows={1}
              size="sm"
              disabled={!isEdit || isDisabledGroup}
            />
           }
          </td>
        </tr>
        );
      }
      return content
    }

    return (
      <Fragment>
        {phevFunctionGroup &&
        <Fragment>
          <Table className={`protocol-table ${isDisabledArea ? "no-print" : ""}`}>
            <thead>
              <tr>
                <th colSpan={3}>
                  <h3>{phevFunctionGroup?.name}</h3>
                </th>
                <th className={`${isDisabledGroup ? "" : "no-print"}`}>
                  <Field
                    name={`phevArea.protocolPartGroups[${phevFunctionGroupIndex}].notPerform`}
                    title="Neprovedeno"
                    options={[
                        { key: 1, value: "ano" },
                        { key: 0, value: " ne " },
                    ]}
                    format={(v) => (v ? 1 : 0)}
                    parse={(v) => (v ? true : false)}
                    type={"checkbox"}
                    initialValue={phevFunctionGroup?.notPerform || false}
                    component={CheckboxInput}
                    disabled={!isEdit}
                    labelWidth={9}
                    valueWidth={1}
                  />
                </th>
              </tr>
            </thead>
            </Table>
            <Table className={`protocol-table ${isDisabledArea ? "no-print" : ""}`}>
            <tbody>
            {
              renderHvFunctionRows()
            }
            </tbody>
          </Table>
          </Fragment>
        }
      </Fragment>
  );
};

export default PhevHvFunctions;
