import {RootStore} from "./rootStore";
import {action, observable, reaction, runInAction} from "mobx";
import moment from "moment";
import agent from "../api/agent";
import {IUser} from "../models/user";
import {ICar} from "../models/car";
import {ICarTest} from "../models/carTest";
import {IBlockingEvent} from "../models/blockingEvent";
import {checkAuthRule} from "../../authorization/Can";

export default class PageGroupScheduleStore {
  rootStore: RootStore;
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    if (
      !checkAuthRule(
        this.rootStore.authStore.user?.userRoleId,
        "expert-group-calendar-page:changeExpertGroup"
      ) &&
      !checkAuthRule(
        this.rootStore.authStore.user?.userRoleId,
        "expert-group-calendar-page:changeWorker"
      )
    )
    return;
  }

  @observable defaultDateFrom: string | undefined = moment()
    .isoWeekday(1)
    .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
    .toISOString();

  // flag if data is loading
  @observable loadingInitial = false;

  @observable expertGroupId: number | undefined = undefined;

  @observable expertGroupUsers: IUser[] = [];

  @observable userId: undefined | number = undefined; // this.rootStore.authStore.user?.userId!;
  @observable cars: ICar[] = [];
  @observable carTests: ICarTest[] = [];
  @observable blockingEvents: IBlockingEvent[] = [];
  // if expert group is changed trigger loading workers for this expert group
  updateUsers = reaction(
    () => this.expertGroupId,
    (expertGroupId) => {
      if (!expertGroupId) return;
      if (
        !checkAuthRule(
          this.rootStore.authStore.user?.userRoleId,
          "expert-group-calendar-page:changeWorker"
        )
      )
        return;

      agent.Users.list(expertGroupId)
        .then((response) => {
          runInAction(() => {
            this.expertGroupUsers = response.users;
            this.loadingInitial = false;
            if (
              response.users.find(
                (u) => u.userId === this.rootStore.authStore.user?.userId
              )
            )
              this.userId = this.rootStore.authStore.user?.userId;
            else this.userId = undefined;
          });
        })
        .catch((error) => {
          console.log(error);
          this.loadingInitial = false;
          throw error;
        });
    }
  );

  @action setExpertGroupId = async (expertGroupId: number, userId?: number) => {
    this.userId = undefined;
    this.expertGroupId = expertGroupId;
  };

  @action setUserId = (userId?: number) => {
    this.userId = userId;
  };

  @action setDefaultDateFrom = (dateFrom: Date | undefined) => {
    this.defaultDateFrom = dateFrom?.toISOString();
  };
}
