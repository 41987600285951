import { IPlace } from "./enum";
import { IUser } from "./user";

export interface IAuthenticateResponse {
  authUser: IAuthUser;
  issuedAt: Date;
  expirationTime: Date;
  token: string;
}
export interface IAuthPlacesUsersResponse {
  places: IPlace[];
  users: IUser[];
}

export interface IAuthUser {
  userId: number;
  login: string;
  shortcut: string;
  fullName: string;
  email: string;
  phone: string;
  expertGroupId: number;
  expertGroupCode: string;
  expertGroupName: string;
  managedGroups: string;
  userRoleId: number;
  userRoleName: string;
  placeId: number;
  placeName: string;
  placeCode: string;
}

export interface IJwtToken {
  primarysid: string;
  givenname: string;
  usershortcut: string;
  name: string;
  emailaddress: string;
  phone: string;
  expertgroupid: string;
  expertgroupcode: string;
  expertgroupname: string;
  managedexpertgroups: string;
  role: string;
  rolename: string;
  placeid: string;
  placename: string;
  placecode: string;
}

export const convertIJwtTokenToIAuthUser = (token: IJwtToken): IAuthUser => {
  const user: IAuthUser = (({
    primarysid,
    givenname,
    usershortcut,
    name,
    emailaddress,
    phone,
    expertgroupid,
    expertgroupcode,
    expertgroupname,
    managedexpertgroups,
    role,
    rolename,
    placeid,
    placename,
    placecode,
  }) => ({
    userId: parseInt(primarysid),
    login: givenname,
    shortcut: usershortcut,
    fullName: name,
    email: emailaddress,
    phone,
    expertGroupId: parseInt(expertgroupid),
    expertGroupCode: expertgroupcode,
    expertGroupName: expertgroupname,
    managedGroups: managedexpertgroups,
    userRoleId: parseInt(role),
    userRoleName: rolename,
    placeId: parseInt(placeid),
    placeName: placename,
    placeCode: placecode,
  }))(token);
  return user;
};

export enum userRoles {
  planAdministrator = 1,
  expertGroupLeader = 2,
  expertGroupWorker = 3,
  coordinator = 4,
  emissionManager = 5,
}
