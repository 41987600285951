import React, {FC} from 'react';
import {IAcousticsProtocol, IProtocolArea} from "../../../../app/models/protocol";
import {Col, Container, Row} from 'react-bootstrap';
import {observer} from "mobx-react-lite";
import {Field, useFormState} from "react-final-form";
import CheckboxInput from "../../../../app/common/form/CheckboxInput";
import DefectButton from "../protocolParts/defectForm/DefectButton";
import AutocompleteSimpleInput from "../../../../app/common/form/AutocompleteSimpleInput";
import {defectOptions} from "../protocolParts/defectForm/defectUtils";
import AssessmentRow from "./AssessmentRow"
import {FormApi} from "final-form";

interface IProps {
    auxiliaryAggregatesArea: IProtocolArea;
    isEdit: boolean;
    form: FormApi<IAcousticsProtocol>;
}

const AuxiliaryAggregatesArea: FC<IProps> = (
    {
        auxiliaryAggregatesArea,
        isEdit,
        form
    }
) => {
    const formState = useFormState<IAcousticsProtocol>();
    const notPerform = formState.values.auxiliaryAggregatesArea.notPerform || false;
    const disabledStyle = notPerform ? ' protocol-area--disabled' : '';

    return <div className={`protocol-area`}>
        <Row noGutters>
            <Col sm={10}>
                <h4>{auxiliaryAggregatesArea.name}</h4>
            </Col>
            <Col>
                <Field
                    name={`auxiliaryAggregatesArea.notPerform`}
                    title="Neprovádět"
                    options={[
                        {key: 1, value: "ano"},
                        {key: 0, value: " ne "},
                    ]}
                    format={(v) => (v ? 1 : 0)}
                    parse={(v) => (!!v)}
                    type={"checkbox"}
                    initialValue={auxiliaryAggregatesArea.notPerform || false}
                    component={CheckboxInput}
                    disabled={!isEdit}
                    labelWidth={8}
                    valueWidth={4}
                />
            </Col>
        </Row>
        <Container className={`protocol-group ${disabledStyle}`}>
            <Container className={'mt-2 mb-2'}>
                {auxiliaryAggregatesArea.partRules?.map((partRule, index) => {
                        const disabledRow = formState.values.auxiliaryAggregatesArea.partRules?.[index].notPerform || false;

                        return (
                            <AssessmentRow
                                areaName='auxiliaryAggregatesArea'
                                groupName={undefined}
                                ruleIndex={index}
                                partRule={partRule}
                                isEdit={isEdit}
                                disabledArea={notPerform}
                                disabledGroup={undefined}
                                disabledRule={disabledRow}
                            />)
                    }
                )}
            </Container>
        </Container>
    </div>
}

export default observer(AuxiliaryAggregatesArea);
