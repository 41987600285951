import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import "./CarTestCollisions.scss";

interface IProps {
  id: number;
  conflictTypes?: string[];
  style?: React.CSSProperties;
}

const CarTestCollisions: React.FC<IProps> = ({ id, style, conflictTypes }) => {
  return (
    <div style={style} className="tooltip-icon">
      <OverlayTrigger
        placement="right"
        overlay={
          <Tooltip id={`tooltip-collisions-${id}`}>
            {(!conflictTypes || !conflictTypes.length) &&
              "Kolize termínů zkoušek vozu!"}
            {!!conflictTypes?.length && (
              <h6>
                <FontAwesomeIcon
                  icon={faExclamationTriangle}
                  className="icon icon--testcollisions"
                />
                <div className="testcollisions__list">
                  <ul>
                    {conflictTypes.map((conflictType, index) => (
                      <li key={index}> {conflictType}</li>
                    ))}
                  </ul>
                </div>
              </h6>
            )}
          </Tooltip>
        }
      >
        <FontAwesomeIcon
          icon={faExclamationTriangle}
          className="icon icon--testcollisions"
        />
      </OverlayTrigger>
    </div>
  );
};

export default CarTestCollisions;
