import { ICarTestConflict } from "./carTest";

export interface IUser {
  userId: number;
  login: string;
  shortcut: string;
  titleBefore?: string;
  titleAfter?: string;
  firstName: string;
  surname: string;
  fullName: string;
  email: string;
  phone: string;
  userRoleId: number;
  userRoleName: string;
  colorId: number;
  color: string;
  expertGroupId?: number;
  expertGroupCode: string;
  expertGroupName: string;
  isActive: boolean;
  placeName: string;
  placeId: number;
  performTests:boolean;
  carTestConflicts?: ICarTestConflict[];
  isConfirmed: boolean;
  managedGroups?: number[];
}

export interface IUserForm {
  userId?: number;
  login: string;
  shortcut: string;
  titleBefore?: string;
  titleAfter?: string;
  firstName: string;
  surname: string;
  email: string;
  phone: string;
  userRoleId: number;
  expertGroupId?: number;
  colorId: number;
  isActive: boolean;
  performTests:boolean;
  isConfirmed: boolean;
  managedGroups?: number[];
}

export class NewUser implements Partial<IUser> {
  userId?: number = undefined;
  login: string = "";
  shortcut: string = "";
  titleBefore?: string = undefined;
  titleAfter?: string = undefined;
  firstName: string = "";
  surname: string = "";
  email: string = "";
  phone: string = "";
  userRoleId: number = 3; // pracovnik odborne skupiny
  expertGroupId?: number;
  colorId: number = 1;
  isActive: boolean = true;
  performTests:boolean = true;
  isConfirmed: boolean = false;
  managedGroups?: number[] = undefined;


  constructor(init?: IUser) {
    Object.assign(this, init);
  }
}


export interface IUserListDTO{
    users: IUser[];
    count: number;
}