import React, { Fragment } from "react";
import { FieldRenderProps } from "react-final-form";
import { Form, Row, Col } from "react-bootstrap";

type Props = FieldRenderProps<string, any>;

const TextAreaInput: React.FC<Props> = ({
  input,
  title,
  rows,
  placeholder,
  disabled,
  plaintext,
  inputOnChange,
  readonly,
  meta,
  ...rest
}) => {
  const errorMessage = (meta.error || meta.submitError) && meta.touched && (
    <Form.Control.Feedback type="invalid">
      {meta.error || meta.submitError}
    </Form.Control.Feedback>
  );
  const inputField = (
    <Fragment>
      <Form.Control
        {...input}
        onChange={(e: any) => {
          input.onChange(e);
          inputOnChange && inputOnChange(e);
        }}
        as="textarea"
        rows={rows || 3}
        isInvalid={meta.touched && !!meta.error}
        disabled={disabled}
        plaintext={plaintext}
        placeholder={placeholder}
        readOnly={readonly}
        {...rest}
      />
    </Fragment>
  );

  return (
    <Fragment>
      {!title && (
        <Form.Group controlId={input.name}>
          {inputField}
          {errorMessage}
        </Form.Group>
      )}
      {title && (
        <Form.Group controlId={input.name}>
          <Row>
            <Col xl={4} lg={4} md={4} sm={4} xs={4} className="u-text-right">
              {title && <Form.Label>{title}</Form.Label>}
            </Col>
            <Col xl={8} lg={8} md={8} sm={8} xs={8}>
              {inputField}
              {errorMessage}
            </Col>
          </Row>
        </Form.Group>
      )}
    </Fragment>
  );
};

export default TextAreaInput;
