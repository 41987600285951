import React, { Fragment, useState } from "react";
import { Popover, Form, Button } from "react-bootstrap";
import { Form as FinalForm, Field } from "react-final-form";
import NumberInput from "../../../app/common/form/NumberInput";
import { IEnumItem } from "../../../app/models/enum";
import SelectInput from "../../../app/common/form/SelectInput";
import { ITestDefinition } from "../../../app/models/testDefinition";
import { getHoursString } from "../../../app/common/utils/czechDeclension";
import AlertMessage from "../../alert/AlertMessage";

interface IProps {
  expertGroupId: number;
  testTypeId: number;
  testDestinationId: number;
  testPredestinationId: number;
  testDefinitionId: number | undefined;
  fullLength: number | undefined;
  shortLength: number | undefined;
  testCharacteristicsId: number | undefined;
  testCharacteristicsEnum: IEnumItem[];
  updateTestDefinition: (testDefinition: ITestDefinition) => Promise<void>;
}

const TestDefinitionsEditPopover: React.FC<IProps> = ({
  expertGroupId,
  testCharacteristicsEnum,
  testCharacteristicsId,
  testDefinitionId,
  testDestinationId,
  testPredestinationId,
  testTypeId,
  fullLength,
  shortLength,
  updateTestDefinition,
}) => {
  const [errors, setErrors] = useState([]);
  const [showSubmitError, setShowSubmitError] = useState(false);
  const handleSubmitForm = (form: any) => {
    setErrors([]);
    setShowSubmitError(false);

    updateTestDefinition({
      ...form,
      expertGroupId,
      testDefinitionId,
      testPredestinationId,
      testTypeId,
      testDestinationId,
    })
      .then(() => {
        // to close overlay popover workaround
        document.body.click();
      })
      .catch((error) => {
        console.log(error);
        setErrors(Object.values(error.response?.data?.errors));
        setShowSubmitError(true);
      });
  };
  return (
    <Fragment>
      <Popover.Title as="h3">Úprava hodnot</Popover.Title>
      <Popover.Content>
        <AlertMessage
          type="danger"
          heading="Nastala chyba při ukládání"
          show={showSubmitError}
          setShow={setShowSubmitError}
          messageList={errors}
        />
        <FinalForm
          onSubmit={handleSubmitForm}
          validate={(values) => {
            const errors = {} as any;
            if (!values.fullLength && values.shortLength) {
              errors.fullLength =
                "Pokud je zadána zkrácená doba, musí být zadána i standartní";
            }
            if (values.fullLength && values.fullLength < 0) {
              errors.fullLength = "Standartní doba musí být kladné číslo";
            }
            if (values.fullLength && values.fullLength > 8) {
              errors.fullLength = "Standartní doba nesmí překročit 8 hodin";
            }
            if (values.shortLength && values.shortLength < 0) {
              errors.shortLength = "Zkrácená doba musí být kladné číslo";
            }
            if (values.shortLength && values.shortLength > 8) {
              errors.shortLength = "Zkrácená doba nesmí překročit 8 hodin";
            }
            return errors;
          }}
          render={({ handleSubmit, values }) => (
            <Form onSubmit={handleSubmit}>
              <Field
                title={"Standartní doba"}
                initialValue={fullLength}
                name={"fullLength"}
                min={0}
                parse={(value) => (value ? parseInt(value) : undefined)}
                rightAppend={getHoursString(values.fullLength)}
                component={NumberInput}
              />
              <Field
                title={"Zkrácená doba"}
                initialValue={shortLength}
                name={"shortLength"}
                min={0}
                parse={(value) => (value ? parseInt(value) : undefined)}
                rightAppend={getHoursString(values.shortLength)}
                component={NumberInput}
              />
              <Field<number>
                title="Povaha zkoušky"
                name="testCharacteristicsId"
                initialValue={testCharacteristicsId || 1}
                options={testCharacteristicsEnum}
                parse={(value) => value && parseInt(value)}
                component={SelectInput}
              />
              <hr />
              <div className="editpopover__footer flex-row flex-center">
                <Button className="editpopover__button" type="submit">
                  Uložit
                </Button>
              </div>
            </Form>
          )}
        />
      </Popover.Content>
    </Fragment>
  );
};

export default TestDefinitionsEditPopover;
