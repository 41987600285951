import { RootStore } from "./rootStore";
import { observable, action, runInAction, reaction, computed } from "mobx";
import agent from "../api/agent";
import { IAuthUser, convertIJwtTokenToIAuthUser } from "../models/auth";
import { IPlace } from "../models/enum";
import { IUser } from "../models/user";
import jwt_decode from "jwt-decode";

export default class AuthStore {
  rootStore: RootStore;
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;

    reaction(
      () => this.token,
      (token) => {
        // console.log(window.localStorage.getItem("jwt"));
        // console.log("Set auth user token");
        if (token) {
          window.localStorage.setItem("jwt", token);
          this.user = convertIJwtTokenToIAuthUser(jwt_decode(token));
        } else {
          window.localStorage.removeItem("jwt");
        }
      }
    );

    reaction(
      () => this.user,
      (user) => {
        //    console.log(user);
        if (user) {
          window.localStorage.setItem("user", JSON.stringify(user));
        } else {
          window.localStorage.removeItem("user");
        }
      }
    );
  }

  @observable token: string | null = window.localStorage.getItem("jwt");

  @observable user: IAuthUser | null = window.localStorage.getItem("user")
    ? JSON.parse(window.localStorage.getItem("user")!)
    : null;

  @observable places: IPlace[] = [];
  @observable users: IUser[] = [];

  @action setToken = (token: string | null) => {
    this.token = token;
  };

  @computed get isLoggedIn() {
    return !!this.user;
  }

  @action login = async (userId: number) => {
    const previousPlaceId = this.user?.placeId;
    this.logout();
    try {
      const authResponse = await agent.Auths.login(userId);
      runInAction(() => {
        this.user = authResponse.authUser;

        this.setToken(authResponse.token);
        // po prihlaseni nastav jako defaultni skupinu, skupinu do ktere je uzivatel zarazen, 
        // pokud nema skupinu a je planovaci administrator, zvol defaultne Agregat/Podvozek, 
        // u ostatnich nastav na undefined
        this.rootStore.pageGroupScheduleStore.expertGroupId = authResponse
          .authUser.expertGroupId
          ? authResponse.authUser.expertGroupId
          : (authResponse.authUser.userRoleId !== 1 && authResponse.authUser.userRoleId !== 4)
          ? undefined
          : authResponse.authUser?.placeId === 1
          ? 1
          : 9;

        const currentPlaceId = authResponse.authUser?.placeId;
        if (previousPlaceId !== currentPlaceId) this.resetPlaceRelatedStores();
      });
    } catch (error) {
      throw error;
    }
  };

  @action resetPlaceRelatedStores = () => {
    this.rootStore.testDefinitionStore.testDefinitions = [];
    this.rootStore.enumStore.enums = undefined;
    this.rootStore.expertGroupStore.expertGroups = [];
  };

  @action logout = () => {
    this.setToken(null);
    this.user = null;
    this.rootStore.pageGroupScheduleStore.expertGroupId = undefined;
  };

  @action getUsersAndPlaces = async () => {
    try {
      const response = await agent.Auths.listPlacesAndUsers();
      runInAction(() => {
        this.users = response.users;
        this.places = response.places;
      });
    } catch (error) {
      throw error;
    }
  };
}
