import React, { Fragment, useContext } from "react";
import { Table, Alert, Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Field } from "react-final-form";
import { IEnums, CarStates } from "../../../app/models/enum";
import { ICar, NewCar } from "../../../app/models/car";
import { ICarAnalysis } from "../../../app/models/carAnalysis";
import { RootStoreContext } from "../../../app/stores/rootStore";
import moment from "moment";
import { DATE_TIME_FORMAT } from "../../../app/common/utils/constants";
import "./CarAnalysisTab.scss";
import CarAnalysisForm from "../../carAnalysis/CarAnalysisForm";

interface IProps {
  enums: IEnums | undefined;
  values: ICar | NewCar;
  carAnalysis: ICarAnalysis[];
}

const CarAnalysisTab: React.FC<IProps> = ({ enums, values, carAnalysis }) => {
  
  const rootStore = useContext(RootStoreContext);
  const { openModal } = rootStore.modalStore;
  const { user } = rootStore.authStore;
  const { usersSorted } = rootStore.userStore;
  const { carAnalysesRegistry } = rootStore.carAnalysisStore;


  const getExpertGroupUsers = (expertGroupId: number) => {
    return usersSorted
      .filter((x) => x.expertGroupId == expertGroupId && x.isActive)
      .map((w) => ({
        key: w.userId,
        value: w.fullName,
      }));
  };

  const openCarAnalysisForm = (carAnalysisId: number) => {
    const analysis = carAnalysesRegistry.get(carAnalysisId);
    if(analysis){
      analysis.car = values;
      analysis.terms = analysis?.terms?.map((p, index: number) => {
        p.rownumber = index;
        return p;
      }) || [];
      openModal(
        <CarAnalysisForm
          aCarId={values.carId}
          aCarAnalysisId={analysis.carAnalysisId}
          aExpertGroupId={analysis.expertGroupId}
          aCarAnalysis={analysis}
          aWorkerOptions={getExpertGroupUsers(analysis.expertGroupId)}
          isFormEditable={false}
        />,
        {
          preventClosing: true,
          title: "Analýza vozu",
          size: "xl",
        }
      );
    }
  }

  return (
    <div id="carAnalysis-list">
      <Table size="sm">
        <thead>
          <tr>
            <th>Odborná skupina</th>
            <th>Typ</th>
            <th>Popis analýzy</th>
            <th>Stav termínu</th>
            <th>Začátek termínu</th>
            <th>Konec termínu</th>
            <th>Poznámka k termínu</th>
            <th>Akce</th>
          </tr>
        </thead>
        <tbody>
          {carAnalysis &&
            carAnalysis.length > 0 &&
            carAnalysis.map(
              (analysis, analysisIndex) =>
                analysis.terms &&
                analysis.terms.length > 0 &&
                analysis.terms.map((term, termIndex) => (
                  <tr key={`${analysisIndex}-${termIndex}`} className={analysis.terms!.length - 1 == termIndex ? "last-term-border" : "term-border"}>
                    {
                      <Fragment>
                        {termIndex == 0 && (
                          <td rowSpan={analysis.terms?.length}>
                            {analysis.expertGroupName}
                          </td>
                        )}

                        {termIndex == 0 && (
                          <td rowSpan={analysis.terms?.length}>
                            {analysis.analysisTypeName}
                          </td>
                        )}

                        {termIndex == 0 && (
                          <td rowSpan={analysis.terms?.length}>
                            {analysis.description}
                          </td>
                        )}

                        <td>{term.termStateName}</td>
                        <td>{moment(term.startAt).format(DATE_TIME_FORMAT)}</td>
                        <td>{moment(term.endAt).format(DATE_TIME_FORMAT)}</td>
                        <td>{term.note}</td>
                        {termIndex == 0 && (
                          <td rowSpan={analysis.terms?.length}>
                            <Button
                              variant="outline-info"
                              size="sm"
                              onClick={() => {
                                openCarAnalysisForm(term!.carAnalysisId!)
                              }}
                            >
                              Detail
                            </Button>
                          </td>
                        )}
                      </Fragment>
                    }
                  </tr>
                ))
            )}
        </tbody>
      </Table>
    </div>
  );
};

export default CarAnalysisTab;