import React, {Fragment, useEffect} from "react";
import {Button, Form, OverlayTrigger, Table, Tooltip} from "react-bootstrap";
import {IDefect, IGenericProtocol,} from "../../../../app/models/protocol";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus, faTimes} from "@fortawesome/free-solid-svg-icons";
import {Field, useForm, useFormState} from "react-final-form";
import TextInput from "../../../../app/common/form/TextInput";
import TextAreaInput from "../../../../app/common/form/TextAreaInput";
import SelectInput from "../../../../app/common/form/SelectInput";
import CheckboxInput from "../../../../app/common/form/CheckboxInput";
import moment from "moment/moment";
import {useLoadEnumsQuery} from "../../../../app/api/client";
import {defectOptions} from "./defectForm/defectUtils";
import RidingNoisesDefectsExtension from "./RidingNoisesDefectsExtension";

interface IProps {
  isEdit: boolean;
  isDisabled: boolean;
}

const DefectsArea: React.FC<IProps> = ({
  isEdit,
  isDisabled,
}) => {
  const form = useForm();
  const {data: enums} = useLoadEnumsQuery();

  const protocol = useFormState<IGenericProtocol>().values;
  const defects = protocol.defectsArea?.defects || [];
  const defectsArea = protocol.defectsArea;
  const change = useForm<IGenericProtocol>().change;
  const setDefects = (defects: IDefect[]) => {
    change('defectsArea.defects', defects)
  }
  useEffect(() => {
    if ((defects?.[0]?.rownumber === undefined)) {
      setDefects(
          defects.map((d, index: number) => {
            d.rownumber = index;
            return d;
          }) || [],
      );
    }
  }, [defects]);
  const handleAddDefect = () => {
    let rowNumber: number = 0;
    if (defects && defects.length > 0) {
      const filteredArray = defects
          .filter((x) => x.rownumber !== undefined)
          .map((x) => (x.rownumber ? x.rownumber : 0));
      if (filteredArray && filteredArray.length > 0) {
        rowNumber = Math.max.apply(
            Math,
            filteredArray.map((x) => x),
        );
        rowNumber = rowNumber + 1;
      }
    }
    const testDate = moment(protocol?.carTest.startAt);
    const defect: IDefect = {
      signDefect: undefined,
      assignedWorkers: [],
      expertGroupCode: protocol?.carTest.expertGroupCode,
      carTestDate: testDate.toDate(),
      description: undefined,
      analysis: undefined,
      remedy: undefined,
      deadline: undefined,
      responsible: undefined,
      state: undefined,
      rownumber: rowNumber,
      isDeleted: false,
      selectedProtocolPartIds: [],
      systemKKV: false
    };
    setDefects([...defects, defect]);
  };
  const handleRemoveDefect = (rowNumber: number | undefined) => {
    if (rowNumber === undefined) return;
    const newDefects = defects.map(defect => {
      if (rowNumber === defect.rownumber) {
        defect.isDeleted = true;
        return defect;
      }
      return defect;
    })
    setDefects(newDefects);
  };
  if (!defectsArea) return <></>
  if (defects?.[0]?.rownumber === undefined) return <></>
  return (
    <div style={{ pageBreakBefore: "always" }}>
      <Table className="protocol-table">
        <thead>
          <tr>
            <th className="headingrow" colSpan={10}>
              <h2>{defectsArea.name}</h2>
            </th>
            <th className="headingrow" colSpan={2}>
              <Field
                name={`defectsArea.notPerform`}
                title="Neprovedeno"
                options={[
                  { key: 1, value: "ano" },
                  { key: 0, value: " ne " },
                ]}
                format={(v) => (v ? 1 : 0)}
                parse={(v) => (!!v)}
                type={"checkbox"}
                initialValue={defectsArea?.notPerform || false}
                component={CheckboxInput}
                disabled={!isEdit}
                labelWidth={8}
                valueWidth={4}
              />
            </th>
          </tr>
        </thead>
      </Table>
      <Table className={`protocol-table ${isDisabled ? "no-print" : ""}`}>
        <thead>
          <tr className={`${isDisabled ? "disabled" : ""}`}>
            <th>Známka</th>
            <th colSpan={2}>Závada</th>
            <th colSpan={4}>Analýza / Opatření / Termín</th>
            <th>Zodpovídá</th>
            <th>Status</th>
            {isEdit && !isDisabled && <th></th>}
          </tr>
        </thead>
        <tbody>
          {defects
            ?.filter((d) => !d.isDeleted)
            .map((defect, rowIndex) => (
              <Fragment key={`defect-${rowIndex}`}>
                <tr className={`${isDisabled ? "disabled" : ""}`}>
                  <td rowSpan={3} style={{ verticalAlign: "top" }}>
                    <Form.Group>
                      <Field
                        name={`defectsArea.defects[${defect.rownumber}].signDefect`}
                        options={defectOptions}
                        initialValue={defect.signDefect}
                        inputOnChange={(e: any) => {
                          form.mutators.calculateDefects(e);
                        }}
                        component={SelectInput}
                        rows={1}
                        size="sm"
                        disabled={!isEdit || isDisabled}
                      />
                    </Form.Group>
                  </td>
                  <td colSpan={2} rowSpan={3} style={{ verticalAlign: "top" }}>
                    <Field<string>
                      name={`defectsArea.defects[${defect.rownumber}].description`}
                      initialValue={defect?.description}
                      component={TextAreaInput}
                      rows={5}
                      size="sm"
                      disabled={!isEdit || isDisabled}
                    />
                  </td>
                  <td style={{ verticalAlign: "top" }}>
                    <span>
                      <b>A:</b>
                    </span>
                  </td>
                  <td colSpan={3} style={{ verticalAlign: "top" }}>
                    <Field<string>
                      name={`defectsArea.defects[${defect.rownumber}].analysis`}
                      initialValue={defect?.analysis}
                      component={TextInput}
                      rows={1}
                      size="sm"
                      disabled={!isEdit || isDisabled}
                    />
                  </td>
                  <td rowSpan={3} style={{ verticalAlign: "top" }}>
                    <Field<string>
                      name={`defectsArea.defects[${defect.rownumber}].responsible`}
                      initialValue={defect?.responsible}
                      component={TextInput}
                      rows={1}
                      size="sm"
                      disabled={!isEdit || isDisabled}
                    />
                  </td>
                  <td rowSpan={3} style={{ verticalAlign: "top" }}>
                    <Form.Group>
                      <Field<number>
                        name={`defectsArea.defects[${defect.rownumber}].state`}
                        options={enums?.defectStates}
                        parse={(value) => value && parseInt(value)}
                        component={SelectInput}
                        isEmptyOption={true}
                        rows={1}
                        size="sm"
                        disabled={!isEdit || isDisabled}
                      />
                    </Form.Group>
                  </td>
                  {isEdit && !isDisabled && (
                    <td
                      rowSpan={3}
                      style={{ textAlign: "center", verticalAlign: "top" }}
                    >
                      <Button
                        size="sm"
                        variant="outline-danger"
                        onClick={() => {
                          handleRemoveDefect(defect.rownumber);
                          form.mutators.calculateDefects();
                        }}
                      >
                        <FontAwesomeIcon icon={faTimes} /> Odebrat
                      </Button>
                    </td>
                  )}
                </tr>
                <tr className={`${isDisabled ? "disabled" : ""}`}>
                  <td style={{ verticalAlign: "top" }}>
                    <span>
                      <b>O:</b>
                    </span>
                  </td>
                  <td colSpan={3} style={{ verticalAlign: "top" }}>
                    <Field<string>
                      name={`defectsArea.defects[${defect.rownumber}].remedy`}
                      initialValue={defect?.remedy}
                      component={TextInput}
                      rows={1}
                      size="sm"
                      disabled={!isEdit || isDisabled}
                    />
                  </td>
                </tr>
                <tr className={`${isDisabled ? "disabled" : ""}`}>
                  <td style={{ verticalAlign: "top" }}>
                    <span>
                      <b>T:</b>
                    </span>
                  </td>
                  <td colSpan={3} style={{ verticalAlign: "top" }}>
                    <Form.Group>
                      <Field
                        name={`defectsArea.defects[${defect.rownumber}].deadline`}
                        options={enums?.calendarWeeks}
                        component={SelectInput}
                        isEmptyOption={true}
                        size="sm"
                        disabled={!isEdit || isDisabled}
                      />
                    </Form.Group>
                  </td>
                </tr>
                <RidingNoisesDefectsExtension isEdit={isEdit} isDisabled={isDisabled} defect={defect}/>
              </Fragment>
            ))}
          {isEdit && !isDisabled && (
            <tr className="no-print">
              <td colSpan={9}></td>
              <td style={{ textAlign: "center" }}>
                <OverlayTrigger
                  overlay={
                    <Tooltip id={`tooltip-addbutton`}>Přidat závadu</Tooltip>
                  }
                >
                  <Button
                    variant="primary"
                    onClick={() => handleAddDefect()}
                    className="footer__btn-add"
                  >
                    <FontAwesomeIcon icon={faPlus} />
                  </Button>
                </OverlayTrigger>
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </div>
  );
};

export default DefectsArea;
