import { RootStore } from "./rootStore";
import { observable, action, runInAction } from "mobx";
import agent from "../api/agent";
import { ITestDefinition, ITestDefinitionForm } from "../models/testDefinition";
import { toast } from "react-toastify";

export default class TestDefinitionStore {
  rootStore: RootStore;
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @observable testDefinitionsLoaded: ITestDefinition[] = [];
  @observable testDefinitions: ITestDefinitionForm[] = [];
  @observable isLoading: boolean = false;

  @action loadTestDefinitions = async () => {
    if (this.testDefinitions.length < 1) {
      this.isLoading = true;
      try {
        const testDefinitions = await agent.TestDefinitions.list();

        runInAction(() => {
          this.testDefinitionsLoaded = testDefinitions;
          this.testDefinitions = testDefinitions.map((td) => ({
            testDefinitionId: td.testDefinitionId,
            testDestinationId: td.testDestinationId,
            testPredestinationId: td.testPredestinationId,
            testTypeId: td.testTypeId,
            expertGroupId: td.expertGroupId,
            fullLength: td.fullLength,
            shortLength: td.shortLength,
            testCharacteristicsId: td.testCharacteristicsId,
          }));
          this.isLoading = false;
        });
      } catch (error) {
        console.log(error);
        this.isLoading = false;
      }
    }
  };

  @action updateTestDefinition = async (
    testDefinition: ITestDefinitionForm
  ) => {
    try {
      const testDefinitionId = await agent.TestDefinitions.update(
        testDefinition
      );

      runInAction(() => {
        if (testDefinition.fullLength)
          testDefinition.testDefinitionId = testDefinitionId;
        else testDefinition.testDefinitionId = undefined;
        const oldTestDefinition = this.testDefinitions.find(
          (td) =>
            td.expertGroupId === testDefinition.expertGroupId &&
            td.testPredestinationId === testDefinition.testPredestinationId &&
            td.testDestinationId === testDefinition.testDestinationId &&
            td.testTypeId === testDefinition.testTypeId
        );
        if (oldTestDefinition) {
          oldTestDefinition.testDefinitionId = testDefinition.testDefinitionId;
          oldTestDefinition.fullLength = testDefinition.fullLength;
          oldTestDefinition.shortLength = testDefinition.shortLength;
          oldTestDefinition.testCharacteristicsId =
            testDefinition.testCharacteristicsId;
          this.testDefinitions = [...this.testDefinitions];
        } else this.testDefinitions = [...this.testDefinitions, testDefinition];
      });
    } catch (error) {
      toast.error("Při ukládání definice zkoušek nastala chyba!");
      console.log(error);
      throw error;
    }
    return;
  };
}
