import React from "react";
import { CarTestStates } from "../../app/models/enum";
import {
    faCheck,
    faExclamation,
    faPause,
    faPlay,
  } from "@fortawesome/free-solid-svg-icons";
  import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
  import "./CarTestStatusIcon.scss"

interface IProps {
  carTestState: CarTestStates;
}
const CarTestStatusIcon:React.FC<IProps> = ({carTestState}) => {
    if (!carTestState) return null;
    switch (carTestState) {
      case CarTestStates.Unfinished: // nedokončená
        return (
          <FontAwesomeIcon
            icon={faPause}
            className="carstatusicon carstatusicon--warning"
          />
        );
      case CarTestStates.Completed: // dokončená
        return (
          <FontAwesomeIcon
            icon={faCheck}
            className="carstatusicon carstatusicon--success"
          />
        );
        case CarTestStates.Ongoing: // probiha
        return (
          <FontAwesomeIcon
            icon={faPlay}
            style={{paddingLeft:"3px"}}
            className="carstatusicon carstatusicon--success"
          />
        );
        case CarTestStates.Interrupted: // prerusena
        return (
          <FontAwesomeIcon
            icon={faExclamation}
            className="carstatusicon carstatusicon--danger"
          />
        );
      default:
        return null;
    }
};

export default CarTestStatusIcon;
