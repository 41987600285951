import React, { Fragment, useContext, useEffect } from "react";
import { Table, Button, Container, Pagination } from "react-bootstrap";
import { Link } from "react-router-dom";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { RootStoreContext } from "../../app/stores/rootStore";
import UserStatus from "../icons/UserStatus";
import Loader from "../loader/Loader";
import { observer } from "mobx-react-lite";
import Can from "../../authorization/Can";

const UserList = () => {
  const rootStore = useContext(RootStoreContext);
  const {
    loadUsers,
    loadingInitial,
    usersPage,
    loadUsersPage,
  } = rootStore.userStore;
  const { user: authUser } = rootStore.authStore;

  useEffect(() => {
    loadUsers();
  }, [loadUsers]);

  const pages = [];
  pages.push(
    <Pagination.Prev
      key={0}
      disabled={!usersPage.hasPrevious}
      onClick={() => {
        loadUsersPage(usersPage.currentPage - 1);
        document
          ?.getElementById("users-content")
          ?.scrollTo({ top: 0, behavior: "smooth" });
      }}
    />
  );
  for (let number = 1; number <= usersPage.totalPages; number++) {
    pages.push(
      <Pagination.Item
        key={number}
        active={number === usersPage.currentPage}
        onClick={() => {
          loadUsersPage(number);
          document
            ?.getElementById("users-content")
            ?.scrollTo({ top: 0, behavior: "smooth" });
        }}
      >
        {number}
      </Pagination.Item>
    );
  }
  pages.push(
    <Pagination.Next
      key={usersPage.totalPages + 1}
      disabled={!usersPage.hasNext}
      onClick={() => {
        loadUsersPage(usersPage.currentPage + 1);
        document
          ?.getElementById("users-content")
          ?.scrollTo({ top: 0, behavior: "smooth" });
      }}
    />
  );

  return (
    <Fragment>
      <div className="page-content" id="users-content">
        <Container>
          <div className="pageheader">
            <div className="pageheader__left">
              <h1>Seznam uživatelů</h1>
            </div>
          </div>

          <Table hover className="sticky--top">
            <thead>
              <tr>
                <th>Celé jméno</th>
                <th>Přihlašovací jméno</th>
                <th>Status</th>
                <th>Odborná skupina</th>
                <th>Akce</th>
              </tr>
            </thead>
            {!loadingInitial && (
              <tbody>
                {usersPage.items
                  //.slice(pageSize * (activePage - 1), pageSize * activePage)
                  .map((user) => (
                    <tr key={user.userId}>
                      <td>{user.fullName}</td>
                      <td>{user.login}</td>
                      <td>
                        <UserStatus id={user.userId} isActive={user.isActive} />
                      </td>
                      <td>{user.expertGroupName}</td>
                      <td>
                        <Button
                          as={Link}
                          to={`/user/${user.userId}`}
                          variant="outline-primary"
                        >
                          Detail
                        </Button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            )}
          </Table>

          {loadingInitial && <Loader />}
        </Container>
      </div>
      <footer>
        <Container className="footer">
          <div className="footer-left">
            <Can
              roleId={authUser?.userRoleId}
              perform="users-page:create"
              yes={
                <Button
                  as={Link}
                  to="/user/new"
                  variant="outline-primary"
                  style={{ marginBottom: "10px", marginRight: "20px" }}
                >
                  <FontAwesomeIcon icon={faPlus} /> Přidat uživatele
                </Button>
              }
            />
          </div>
          <div className="footer-right">
            <div className="sticky--bottom">
              <Pagination>{pages}</Pagination>
            </div>
          </div>
        </Container>
      </footer>
    </Fragment>
  );
};

export default observer(UserList);
