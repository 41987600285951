import React, { useContext, useEffect, useState } from "react";
import { Row, Col, Form, Container, Button } from "react-bootstrap";
import { Field, Form as FinalForm } from "react-final-form";
import { IEnums } from "../../../app/models/enum";
import { observer } from "mobx-react-lite";
import { RootStoreContext } from "../../../app/stores/rootStore";
import SelectInput from "../../../app/common/form/SelectInput";
import TextInput from "../../../app/common/form/TextInput";
import NullableBooleanSwitchInput from "../../../app/common/form/NullableBooleanSwitchInput";
import { IPlanningCondition } from "../../../app/models/planningCondition";

interface IProps { 
    planningCondition: IPlanningCondition,
    enums: IEnums | undefined;
    handlePlanningConditionForm: (PlanningConditionForm: IPlanningCondition) => void,
}

const PlanningConditionForm: React.FC<IProps> = ({planningCondition, enums, handlePlanningConditionForm}) => {
  const rootStore = useContext(RootStoreContext);
  const { closeModal } = rootStore.modalStore;

  const handleFinalFormSubmit = (form: IPlanningCondition) => {
    if(form.testDestinationId){
        var testDestinationEmumItem = enums?.testDestinations.find(x => x.key == form.testDestinationId);
        if(testDestinationEmumItem) form.testDestinationName = testDestinationEmumItem.value;
    }
    if(form.testPredestinationId){
        var testPredestinationEmumItem = enums?.testPredestinations.find(x => x.key == form.testPredestinationId);
        if(testPredestinationEmumItem) form.testPredestinationName = testPredestinationEmumItem.value;
    }
    if(form.testTypeId){
        var testTypeEmumItem = enums?.testTypes.find(x => x.key == form.testPredestinationId);
        if(testTypeEmumItem) form.testTypeName = testTypeEmumItem.value;
    }
    if(form.carModelId){
        var carModelEmumItem = enums?.carModels.find(x => x.id == form.carModelId);
        if(carModelEmumItem) form.carModelName = carModelEmumItem.name;
    }
    if(form.carBodyTypeId){
        var carBodyTypeEmumItem = enums?.carBodyTypes.find(x => x.id == form.carBodyTypeId);
        if(carBodyTypeEmumItem) form.carBodyTypeName = carBodyTypeEmumItem.name;
    }
    if(form.carEquipmentId){
        var carEquipmentEmumItem = enums?.carEquipments.find(x => x.id == form.carEquipmentId);
        if(carEquipmentEmumItem) form.carEquipmentName = carEquipmentEmumItem.name;
    }
    if(form.carEngineId){
        var carEngineEmumItem = enums?.carEngines.find(x => x.id == form.carEngineId);
        if(carEngineEmumItem) form.carEngineName = carEngineEmumItem.name;
    }
    if(form.planningTypeId){
        var carPlannigTypeEmumItem = enums?.planningTypes.find(x => x.key == form.planningTypeId);
        if(carPlannigTypeEmumItem) form.planningTypeName = carPlannigTypeEmumItem.value;
    }
    handlePlanningConditionForm(form);
  };


  return (
    <div>
        <FinalForm
        onSubmit={handleFinalFormSubmit}
        initialValues={planningCondition}
        render={({ handleSubmit, values, form }) => (
            <Form onSubmit={handleSubmit}>
            <Container>
            <Row>
                <Col sm={6}>
                    <Field
                        name="testDestinationId"
                        title="Typ vozu"
                        options={enums?.testDestinations}
                        component={SelectInput}
                        isEmptyOption={true}
                        initialValue={planningCondition.testDestinationId}
                        parse={(value) => (value ? parseInt(value) : undefined)}
                    />
                    <Field
                        name="testPredestinationId"
                        title="Podurčení zkoušky"
                        options={enums?.testPredestinations}
                        component={SelectInput}
                        isEmptyOption={true}
                        initialValue={planningCondition.testPredestinationId}
                        parse={(value) => (value ? parseInt(value) : undefined)}
                    />
                    <Field
                        name="testTypeId"
                        title="Typ zkoušky"
                        initialValue={planningCondition.testTypeId}
                        options={enums?.testTypes
                        .filter(
                            (testType) =>
                            testType.isActive
                        )
                        .slice()
                        .sort((a, b) => (a.value < b.value ? -1 : 1))
                        .map((testType) => ({
                            key: testType.key,
                            value: testType.value,
                            isDisabled: !testType.isActive,
                        }))}
                        component={SelectInput}
                        isEmptyOption={true}
                    />
                    <Field<string>
                        name="customerCode"
                        title="Odběratel"
                        initialValue={planningCondition.customerCode}
                        component={TextInput}
                    />
                    </Col>

                <Col sm={6}>

                    <Field
                        name="carModelId"
                        title="Model"
                        initialValue={planningCondition.carModelId}
                        options={enums?.carModels
                        .slice()
                        .filter((a) => a.isActive || a.id === values.carModelId)
                        .sort((a, b) => (a.name < b.name ? -1 : 1))
                        .map((model) => ({
                            key: model.id,
                            value: model.name,
                            isDisabled: !model.isActive,
                        }))}
                        component={SelectInput}
                        isEmptyOption={true}
                        parse={(value) => (value ? parseInt(value) : undefined)}
                    />
                    <Field
                        name="carBodyTypeId"
                        title="Karoserie"
                        options={enums?.carBodyTypes
                        .slice()
                        .filter(
                            (bodyType) =>
                            bodyType.carModelId === values.carModelId &&
                            (bodyType.isActive || bodyType.id === values.carBodyTypeId)
                        )
                        .sort((a, b) => (a.name < b.name ? -1 : 1))
                        .map((model) => ({
                            key: model.id,
                            value: model.name,
                            isDisabled: !model.isActive,
                        }))}
                        component={SelectInput}
                        isEmptyOption={true}
                        parse={(value) => (value ? parseInt(value) : undefined)}
                    />
                    <Field
                        name="carEquipmentId"
                        title="Výbava"
                        options={enums?.carEquipments
                        .filter(
                            (eqp) =>
                            eqp.carModelId === values.carModelId &&
                            (eqp.isActive || eqp.id === values.carEquipmentId)
                        )
                        .slice()
                        .sort((a, b) => (a.name < b.name ? -1 : 1))
                        .map((model) => ({
                            key: model.id,
                            value: model.name,
                            isDisabled: !model.isActive,
                        }))}
                        component={SelectInput}
                        isEmptyOption={true}
                        parse={(value) => (value ? parseInt(value) : undefined)}
                    />

                    <Field
                        name="carEngineId"
                        title="Motorizace"
                        options={enums?.carEngines
                        .filter(
                            (engine) =>
                            engine.carModelId === values.carModelId &&
                            (engine.isActive || engine.id === values.carEngineId)
                        )
                        .slice()
                        .sort((a, b) => (a.name < b.name ? -1 : 1))
                        .map((model) => ({
                            key: model.id,
                            value: model.name,
                            isDisabled: !model.isActive,
                        }))}
                        component={SelectInput}
                        isEmptyOption={true}
                        parse={(value) => (value ? parseInt(value) : undefined)}
                        
                    />
                    <Field
                        name={`emissionTest`}
                        size="sm"
                        title="Emisní vůz"
                        initialValue={planningCondition.emissionTest}
                        component={NullableBooleanSwitchInput}
                    />
                    <Field
                        name="planningTypeId"
                        title="Plánování"
                        options={enums?.planningTypes}
                        component={SelectInput}
                        isEmptyOption={false}
                        initialValue={planningCondition.planningTypeId}
                        parse={(value) => (value ? parseInt(value) : undefined)}
                    />
                </Col>
            </Row>
            </Container>
            <hr />
            <div className="flex-row flex-center">
                <Button type="submit" className="btn-modal">
                    Přidat podmínku
                </Button>
                <Button
                    type="button"
                    onClick={closeModal}
                    className="btn-modal"
                    variant="outline-secondary"
                >
                Zrušit
                </Button>
            </div>
            </Form>
      )}
    />
  </div>
    
 
  );
};

export default observer(PlanningConditionForm);
