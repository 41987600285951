import { RootStore } from "./rootStore";
import { observable, action, runInAction, computed } from "mobx";
import agent from "../api/agent";
import { ICar, ICarSearch, ICarPagedList } from "../models/car";

export default class CarSearchStore {
  rootStore: RootStore;
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  // flag if data is loading
  @observable isLoading = false;

  pageSize: number = 20;

  @observable carsPage: ICarPagedList = {
    cars: [],
    currentPage: 1,
    pageSize: this.pageSize,
    count: 0,
    totalPages: 0,
    hasNext: false,
    hasPrevious: false,
  };

  // car filters
  @observable carSearch: ICarSearch = {
    vin: "",
    carNumber: "",
    prodIdentificationNum: "",
  };

  @computed get carsSorted() {
    const cars: ICar[] = [];
    this.carsPage.cars.forEach((car) => {
      let carFromStore = this.rootStore.carStore.carRegistry.get(car.carId);
      if (carFromStore) cars.push(carFromStore);
    });
    return this.sortCarsById(cars);
  }

  sortCarsById(cars: ICar[]) {
    const sortedCars = cars.sort((a, b) => b.carId - a.carId);
    return sortedCars;
  }

  @action loadCars = async (pageNumber: number) => {
    this.isLoading = true;
    this.carsPage.currentPage = pageNumber;
    try {
      const carSearchAsAxiosParams = this.rootStore.carStore.getAxiosCarFilterParams(
        this.carSearch
      );

      carSearchAsAxiosParams.append(
        "pageNumber",
        this.carsPage.currentPage.toString()
      );
      carSearchAsAxiosParams.append(
        "pageSize",
        this.carsPage.pageSize.toString()
      );

      const list = await agent.Cars.search(carSearchAsAxiosParams);
      runInAction("load plan cars", () => {
        list.cars.forEach((car) => {
          // add car to shared car store
          this.rootStore.carStore.carRegistry.set(car.carId, car);

          // add carId to array with cars ids to be shown on the page
          //  this.carIds.push(car.carId);
        });
        this.carsPage = list;
        this.isLoading = false;
      });
      //  this.carIds =this.rootStore.carStore.filterCars( this.carFilter);
    } catch (error) {
      runInAction("load cars error", () => {
        this.isLoading = false;
      });
      throw error;
    }
  };

  @action setCarFilter = (filter: ICarSearch) => {
    this.carSearch = filter;
    this.loadCars(1);
    // this.carIds =this.rootStore.carStore.filterCars( this.carFilter);
  };
}
