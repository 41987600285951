import "./ScheduleDashboardPage.scss";
import React, { useContext, useEffect, Fragment } from "react";
import Schedule from "./Schedule";
import { RootStoreContext } from "../../../app/stores/rootStore";
import Loader from "../../loader/Loader";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { observer } from "mobx-react-lite";
import Footer from "../footer/Footer";
import ScheduleFilter from "../filter/ScheduleFilter";
import moment from "moment";
import Can from "../../../authorization/Can";

const ScheduleDashboardPage = () => {
  const rootStore = useContext(RootStoreContext);
  const {
    loadSchedule,
    loadingInitial,
    dateFrom,
    dateTo,
    setDateFromDateTo,
    setScheduleFilter,
    activeFilterLength,
    scheduleFilter,
  } = rootStore.pageScheduleStore;
  const { user } = rootStore.authStore;
  const { loadUsers } = rootStore.userStore;

  useEffect(() => {
    // load planned car tests
    loadSchedule(dateFrom.toDate(), dateTo.toDate());
    loadUsers();
    return () => {
      // remove cars from newly planned cars list, we already notify them
      rootStore.pageScheduleStore.setPlannedCars([]);
    };
  }, [loadSchedule, dateFrom, dateTo, rootStore.pageScheduleStore, loadUsers]);

  const handleNextWeek = () => {
    const nextFrom = moment(dateFrom).add(7, "days");
    const nextTo = moment(dateTo).add(7, "days");
    setDateFromDateTo(nextFrom, nextTo);
  };

  const handlePreviousWeek = () => {
    const nextFrom = moment(dateFrom).add(-7, "days");
    const nextTo = moment(dateTo).add(-7, "days");
    setDateFromDateTo(nextFrom, nextTo);
  };

  const handleCurrentWeek = () => {
    const nextFrom = moment().isoWeekday(1);
    const nextTo = moment().isoWeekday(7);
    setDateFromDateTo(nextFrom, nextTo);
  };

  function setPageSize(cssPageSize: string) {
    const style = document.createElement("style");
    style.innerHTML = `@page {size: ${cssPageSize} A4}`;
    style.id = "page-orientation";
    document.head.appendChild(style);
  }

  const orientation = "landscape";
  useEffect(() => {
    setPageSize(orientation);
    return () => {
      const child = document.getElementById("page-orientation");
      child?.parentNode?.removeChild(child);
    };
  }, [orientation]);

  return (
    <Can
      roleId={user?.userRoleId}
      perform="car-calendar-page:visit"
      yes={
        <Fragment>
          <div className="page-content plandashboard">
            <div className="plandashboard-header">
              <h1 className="plandashboard-header-name">
                Plánování zkoušek {user?.placeCode} - vozy KT{" "}
                {dateFrom.format("w/YYYY")}
              </h1>

              <div className="plandashboard-calendar  no-print">
                <Button
                  variant="link"
                  size="sm"
                  onClick={() => handlePreviousWeek()}
                >
                  <FontAwesomeIcon
                    icon={faChevronLeft}
                    className="plandashboard-icon plandashboard-icon-left "
                  />
                </Button>
                <Button
                  variant="link"
                  size="sm"
                  onClick={() => handleCurrentWeek()}
                >
                  <span className="plandashboard-calendar plandashboard-calendar-selector">
                    KT {dateFrom.format("w/YYYY")}{" "}
                    {/* <FontAwesomeIcon icon={faChevronDown} /> */}
                  </span>
                </Button>

                <Button
                  variant="link"
                  size="sm"
                  onClick={() => handleNextWeek()}
                >
                  <FontAwesomeIcon
                    icon={faChevronRight}
                    className="plandashboard-icon plandashboard-icon-right "
                  />
                </Button>
              </div>

              <ScheduleFilter
                filter={scheduleFilter}
                setFilter={setScheduleFilter}
                activeFilterLength={activeFilterLength}
              />
            </div>
            {/* <div className="schedule-filter-container"> */}
            {/* </div> */}
            {loadingInitial ? (
              <Loader label="Načítání dat z databáze" />
            ) : (
              <Schedule />
            )}
            {/* <Timeline /> */}
          </div>

          <Footer />
        </Fragment>
      }
    />
  );
};

export default observer(ScheduleDashboardPage);
