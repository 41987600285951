import React, { useContext, useState } from "react";
import Downshift from "downshift";
import { FieldRenderProps } from "react-final-form";
import "./KnrAutocompleteInput.scss";
import { RootStoreContext } from "../../stores/rootStore";
import { Form, Row, Col } from "react-bootstrap";

type Props = FieldRenderProps<string, any>;

const KnrAutocompleteInput: React.FC<Props> = ({
  input,
  inputOnChange,
  meta,
  title,
  warning,
  placeholder,
  items,
  disabled,
  required,
  ...rest
}) => {
  const rootStore = useContext(RootStoreContext);
  const { loadCarsByVinOrKnr, carsByVinOrKnr } = rootStore.carStore;
  const [lastSearchedValue, setLastSearchedValue] = useState<
    string | undefined
  >(undefined);

  const makeAutocompleteLookup = (query: string) => {
    loadCarsByVinOrKnr(undefined, query);
  };

  // Function that allows to enter only numeric characters => [0-9]
  const preventNonNum = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const re = /[0-9]+/g;
    if (!re.test(e.key)) {
      e.preventDefault();
    }
  };

  const SHOW_LIMIT = 5;
  const MIN_INPUT_LENGTH = 5;

  const errorMessage = (meta.error || meta.submitError) && meta.touched && (
    <Form.Control.Feedback type="invalid">
      {meta.error || meta.submitError}
    </Form.Control.Feedback>
  );

  const warningMessage = warning && (
    <div className="warning-feedback">{warning}</div>
  );

  return (
    <Downshift
      {...input}
      onInputValueChange={(inputValue) => {
        // fetch data from database when user input has defined length
        // and the request for same string start has not been run yet
        if (!disabled &&
          inputValue &&
          inputValue.length > MIN_INPUT_LENGTH &&
          ((lastSearchedValue && !inputValue.startsWith(lastSearchedValue)) ||
            !lastSearchedValue)
        ) {
          makeAutocompleteLookup(inputValue);
          setLastSearchedValue(inputValue);
        }
        // set all characters to UPPER CASE and replace all non alphanumeric characters
       input.onChange(inputValue);
      }}
      itemToString={(item: string | null | undefined) => (item ? item : "")}
      selectedItem={input.value}
    >
      {({
        getInputProps,
        getItemProps,
        getLabelProps,
        isOpen,
        inputValue,
        highlightedIndex,
        selectedItem,
      }) => {
        const filteredItems: string[] = carsByVinOrKnr.filter((c)=>c.prodIdentificationNum)
          .map((c) => c.prodIdentificationNum)
          .filter((item: string|undefined) => item && item.startsWith(inputValue || "")) as string[];
        return (
          <div className="downshift">
            <Form.Group controlId={input.name}>
              <Row>
                <Col xs={4} className="u-text-right">
                  {title && <Form.Label className={required?"required ":""}>{title}</Form.Label>}
                </Col>
                <Col xs={8}>
                  <input
                    className={`form-control ${
                      !!meta.error && meta.touched && "is-invalid"
                    } ${warning && "is-warning"}`}
                    {...getInputProps({
                      placeholder: placeholder,
                      name: input.name,
                      disabled: disabled,
                      onBlur: (event) => {
                        input.onBlur(event);
                      },
                      onChange: (e) => {
                        inputOnChange && inputOnChange(e.target.value);
                      },
                      onKeyPress: (e) => {
                        preventNonNum(e);
                      },
                    })}
                  />

                  {isOpen && !!filteredItems.length && (
                    <div className="downshift-dropdown">
                      {filteredItems
                        .slice(0, SHOW_LIMIT)
                        .map((item: string, index: number) => (
                          <div
                            className="dropdown-item"
                            {...getItemProps({
                              key: item,
                              index,
                              item: item,
                              style: {
                                backgroundColor:
                                  highlightedIndex === index
                                    ? " #cce9f7"
                                    : "white",
                                fontWeight:
                                  selectedItem === item ? "bold" : "normal",
                              },
                            })}
                          >
                            {item}
                          </div>
                        ))}
                    </div>
                  )}
                  {errorMessage}
                  {warningMessage}
                </Col>
              </Row>
            </Form.Group>
          </div>
        );
      }}
    </Downshift>
  );
};

export default KnrAutocompleteInput;
