import React, {Fragment, useContext} from "react";
import {Route, Switch} from "react-router-dom";
import NavBar from "../../features/nav/NavBar";
import HomePage from "../../features/home/HomePage";
import CarList from "../../features/cars/dashboard/CarList";
import UserList from "../../features/users/UserList";
import ExpertGroupList from "../../features/expertGroups/ExpertGroupList";
import ModalContainer from "../common/modals/ModalContainer";
import ScheduleDashboardPage from "../../features/schedule/dashboard/ScheduleDashboardPage";
import TestDetail from "../../features/carTest/TestDetail";
import PlanPage from "../../features/plan/PlanPage";
import CarDetailPage from "../../features/carDetail/CarDetailPage";
import UserDetailPage from "../../features/userDetail/UserDetailPage";
import ExpertGroupDetailPage from "../../features/expertGroupDetail/ExpertGroupDetailPage";
import GroupSchedulePage from "../../features/groupSchedule/GroupSchedulePage";
import CarSearchPage from "../../features/carSearch/CarSearchPage";
import EmissionCarsPage from "../../features/emissionCars/EmissionCarsPage";
import CarBodyProtocol from "../../features/protocols/partialProtocols/CarBodyProtocol";
import ElectroProtocol from "../../features/protocols/partialProtocols/ElectroProtocol";
import EECProtocol from "../../features/protocols/partialProtocols/EECProtocol";
import MainProtocol from "../../features/protocols/mainProtocol/MainProtocol";
import Popper from "popper.js";
import {ToastContainer} from "react-toastify";
import "../../styles/reactToastify.scss";
import ConfirmationDialog from "../common/modals/ConfirmationDialog";
import NotFound from "./NotFound";
import InternalServerError from "./InternalServerError";
import SettingsPage from "../../features/settings/SettingsPage";
import LoginPage from "../../features/login/LoginPage";
import AuthRoute from "../../authorization/AuthRoute";
import Unauthorized from "./Unauthorized";
import Unauthenticated from "./Unauthenticated";
import {useCookies} from "react-cookie";
import {RootStoreContext} from "../stores/rootStore";
import WaterResistanceProtocol from "../../features/protocols/partialProtocols/waterResistance/WaterResistanceProtocol";
import ConformityProtocol from "../../features/protocols/partialProtocols/conformity/ConformityProtocol";
import RidingNoisesProtocol from "../../features/protocols/partialProtocols/ridingNoises/RidingNoisesProtocol";
import AcousticsProtocol from "../../features/protocols/partialProtocols/acoustics/AcousticsProtocol";
import DrivingCharacteristicsProtocol from "../../features/protocols/partialProtocols/drivingCharacteristics/DrivingCharacteristicsProtocol";

//Fixing blury text in tooltips and popovers
// see https://github.com/twbs/bootstrap/issues/22610
Popper.Defaults.modifiers!.computeStyle!.gpuAcceleration = !(
  window.devicePixelRatio < 1.5 && /Win/.test(navigator.platform)
);

const App: React.FC = () => {
  const rootStore = useContext(RootStoreContext);
  const { setToken } = rootStore.authStore;
  const [cookies, , removeCookie] = useCookies(["QSpace_Authorization_Token"]);

  const token = cookies["QSpace_Authorization_Token"];
  if (token) {
    setToken(token);
    // console.log("Proběhlo nastavení tokenu");
    removeCookie("QSpace_Authorization_Token");
  }

  return (
    <Fragment>
      <ModalContainer />
      <ConfirmationDialog />
      <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} draggable={false} pauseOnHover />
      <NavBar />
      <AuthRoute exact path="/" component={HomePage} action="home-page:visit" />
      <Route
        path={"/(.+)"}
        render={() => (
          <Switch>
            <Route exact path="/login" component={LoginPage} />
            <AuthRoute
              exact
              path="/cars"
              component={CarList}
              action="cars-page:visit"
            />
            <AuthRoute
              exact
              path="/users"
              component={UserList}
              action="users-page:visit"
            />
            <AuthRoute
              exact
              path="/expertgroups"
              component={ExpertGroupList}
              action="expert-groups-page:visit"
            />
            <AuthRoute
              exact
              path="/settings"
              component={SettingsPage}
              action="settings-page:visit"
            />
            <AuthRoute
              exact
              path="/schedule"
              component={ScheduleDashboardPage}
              action="car-calendar-page:visit"
            />
            <AuthRoute
              exact
              path="/scheduleGroup"
              component={GroupSchedulePage}
              action="expert-group-calendar-page:visit"
              checkByExpertGroupId
            />
            <AuthRoute
              exact
              path="/plan"
              component={PlanPage}
              action="plan-page:visit"
            />
            <AuthRoute
              exact
              path="/carTest/:id"
              component={TestDetail}
              action="cartest-detail-page:visit"
            />
            <AuthRoute
              exact
              path={["/car/new", "/car/:id"]}
              component={CarDetailPage}
              action="car-detail-page:visit"
            />
            <AuthRoute
              exact
              path={["/user/new", "/user/:id"]}
              component={UserDetailPage}
              action="user-detail-page:visit"
            />
            <AuthRoute
              exact
              path={"/expertgroup/new"}
              component={ExpertGroupDetailPage}
              action="expert-groups-page:create"
            />
            <AuthRoute
              exact
              path={"/expertgroup/:id"}
              component={ExpertGroupDetailPage}
              action="expert-group-detail-page:visit"
              checkByExpertGroupId
            />
            <AuthRoute
              exact
              path="/emissionCars"
              component={EmissionCarsPage}
              action="emission-cars-page:visit"
            />
            <AuthRoute
              exact
              path={["/carsearch"]}
              component={CarSearchPage}
              action="car-search:visit"
            />
            <AuthRoute
              exact
              path={["/CarBodyProtocol/:protocolTypeId/:carId/:carTestId?/:carAnalysisId?/:carProtocolId?"]}
              component={CarBodyProtocol}
              action="car-body-protocol:visit"
            />
            <AuthRoute
              exact
              path={["/ElectroProtocol/:protocolTypeId/:carId/:carTestId?/:carAnalysisId?/:carProtocolId?"]}
              component={ElectroProtocol}
              action="electro-protocol:visit"
            />
            <AuthRoute
                exact
                path={["/WaterResistanceProtocol/:protocolTypeId/:carId/:carTestId?/:carAnalysisId?/:carProtocolId?"]}
                component={WaterResistanceProtocol}
                action="water-resistance-protocol:visit"
            />
            <AuthRoute
                exact
                path={["/RidingNoisesProtocol/:protocolTypeId/:carId/:carTestId?/:carAnalysisId?/:carProtocolId?"]}
                component={RidingNoisesProtocol}
                action="riding-noises-protocol:visit"
            />
              <AuthRoute
                  exact
                  path={["/AcousticsProtocol/:protocolTypeId/:carId/:carTestId?/:carAnalysisId?/:carProtocolId?"]}
                  component={AcousticsProtocol}
                  action="acoustics-protocol:visit"
              />
            <AuthRoute
              exact
              path={["/AnalysisEECProtocol/:protocolTypeId/:carId/:carTestId?/:carAnalysisId?/:carProtocolId?"]}
              component={EECProtocol}
              action="analysisEEC-protocol:visit"
            />
             <AuthRoute
              exact
              path={["/MainProtocol/:carProtocolId/:carId"]}
              component={MainProtocol}
              action="main-protocol:visit"
            />
            <AuthRoute
                exact
                path={["/ConformityProtocol/:protocolTypeId/:carId/:carTestId?/:carAnalysisId?/:carProtocolId?"]}
                component={ConformityProtocol}
                action="water-resistance-protocol:visit"
            />
            <AuthRoute
              exact
              path={"/EngineDrivingCharacteristicsProtocol/:protocolTypeId/:carId/:carTestId?/:carAnalysisId?/:carProtocolId?"}
              component={DrivingCharacteristicsProtocol}
              action="engine-driving-characteristics-protocol:visit"
            />
            <Route
              exact
              path="/internalServerError"
              component={InternalServerError}
            />
            <Route exact path="/unauthorized" component={Unauthorized} />
            <Route exact path="/unauthenticated" component={Unauthenticated} />
            <Route component={NotFound} />
          </Switch>
        )}
      />
    </Fragment>
  );
};

export default App;
