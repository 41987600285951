import React, { Fragment } from 'react';
import { Table } from "react-bootstrap";
import {IProtocolArea} from "../../../../app/models/protocol";
import TextInput from "../../../../app/common/form/TextInput";
import {Field} from "react-final-form";
import {Col, Container, Form, Row} from "react-bootstrap";
import CheckboxInput from "../../../../app/common/form/CheckboxInput";
import SelectInput from "../../../../app/common/form/SelectInput";

interface IProps {
    measuredCarArea: IProtocolArea;
    isEdit: boolean;
    isDisabled: boolean;
}

const MeasuredCarArea: React.FC<IProps> = (
    {
        measuredCarArea,
        isEdit,
        isDisabled
    }
) => {
    
    return (
        <Fragment>

            <Table className="protocol-table">
                <thead>
                    <tr>
                        <th colSpan={10} className={`headingrow`}>
                        <h2>{measuredCarArea?.name}</h2>
                        </th>
                        <th colSpan={2} className={`headingrow`}>
                        <Field
                            name={`measuredCarArea.notPerform`}
                            title="Neprovedeno"
                            options={[
                                { key: 1, value: "ano" },
                                { key: 0, value: " ne " },
                            ]}
                            format={(v) => (v ? 1 : 0)}
                            parse={(v) => (v ? true : false)}
                            type={"checkbox"}
                            initialValue={measuredCarArea?.notPerform || false}
                            component={CheckboxInput}
                            disabled={!isEdit}
                            labelWidth={8}
                            valueWidth={4}
                        />
                        </th>
                    </tr>
                </thead>
            </Table>
            
            <Container>
                {measuredCarArea.protocolPartGroups?.map((group, groupIndex) => {
                    return (
                        <Row noGutters className='mb-2' key={`Group_${groupIndex}`}>
                            <Table className={`protocol-table ${ isDisabled ? "no-print" : ""}`}>
                                <tbody>
                                {group.partRules?.map((rule,ruleIndex) => {
                                    if(rule.protocolRuleFunctionId && rule.protocolRuleFunctionId === 12) {
                                        return (
                                                <tr key={`${groupIndex}-${ruleIndex}`} className={`${ isDisabled ? "disabled" : ""}`}>
                                                    <td>{rule.name} :</td>
                                                    <td>
                                                        <Field
                                                            name={`measuredCarArea.protocolPartGroups[${groupIndex}].partRules[${ruleIndex}].controlUnitSw`}
                                                            component={TextInput}
                                                            disabled={!isEdit || isDisabled}
                                                            placeholder={'verze sw'}
                                                        />
                                                    </td>
                                                    <td>
                                                        <Field
                                                            name={`measuredCarArea.protocolPartGroups[${groupIndex}].partRules[${ruleIndex}].controlUnitHw`}
                                                            component={TextInput}
                                                            disabled={!isEdit || isDisabled}
                                                            placeholder={'data'}
                                                        />
                                                    </td>
                                                </tr>
                                            )
                                    }
                                    else {
                                        return (
                                            <tr key={`${groupIndex}-${ruleIndex}`} className={`${ isDisabled ? "disabled" : ""}`}>
                                                <td>{rule.name} :</td>
                                                <td colSpan={2}>
                                                    <Field
                                                        name={`measuredCarArea.protocolPartGroups[${groupIndex}].partRules[${ruleIndex}].controlUnitHw`}
                                                        component={TextInput}
                                                        disabled={!isEdit || isDisabled}
                                                        placeholder={'data'}
                                                    />
                                                </td>
                                            </tr>
                                        )
                                    }
                                })}
                                </tbody>
                            </Table>
                        </Row>
                    )
                })}
            </Container>
        </Fragment>
    );
};

export default MeasuredCarArea;
