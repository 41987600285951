import React from "react";
import { Badge, OverlayTrigger, Tooltip } from "react-bootstrap";

interface IProps {
  id: number;
  emission: boolean | undefined;
  style?: React.CSSProperties | undefined;
}

const EmissionCarIcon: React.FC<IProps> = ({ id, style, emission }) => {
  const description = emission ? "Vůz podstupuje emisní zkoušku" : emission === false ? "Vůz nepodstupuje emisní zkoušku" : "Zatím nerozhodnuto, zda vůz podstoupí emisní zkoušku";
  return (
    <div className="icon-emission" style={style}>
      <OverlayTrigger
        placement="right"
        overlay={<Tooltip id={`tooltip-priority-${id}`}>{description}</Tooltip>}
      >
        <Badge
          pill
          variant={
            emission ? "success" : emission === false ? "danger" : "secondary"
          }
        >
          E
        </Badge>
      </OverlayTrigger>
    </div>
  );
};

export default EmissionCarIcon;
