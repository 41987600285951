import React, { Fragment } from "react";
import { Button } from "react-bootstrap";
import { Field, Form as FinalForm } from "react-final-form";
import CheckboxInput from "../../../app/common/form/CheckboxInput";
import TextInput from "../../../app/common/form/TextInput";

interface IProps {
  handleEditItem: (form: any) => void;
  id: number;
  name: string;
  code: string;
  value: string;
  isActive: boolean;
  editedItemId: string | undefined;
  setEditedItemId: (id: string | undefined) => void;
}

const EditableCarColorRow: React.FC<IProps> = ({
  handleEditItem,
  id,
  name,
  code,
  value,
  isActive,
  editedItemId,
  setEditedItemId,
}) => {
  const itemId = `carcolor-${id}`;
  return (
    <tr
      key={id}
      className={
        editedItemId === itemId
          ? "active show-btn-on-hover"
          : "show-btn-on-hover"
      }
    >
      {editedItemId === itemId && (
        <FinalForm
          onSubmit={handleEditItem}
          validate={(values) => {
            const errors = {} as any;
            if (!values.name) {
              errors.name = "Název nesmí být prázdný";
            }
            return errors;
          }}
          render={({ handleSubmit }) => (
            <Fragment>
              <td>{code}</td>
              <td>
                <Field
                  defaultValue={value}
                  name="value"
                  size="sm"
                  component={TextInput}
                  type="color"
                />
              </td>
              <td>
                <Field<number>
                  name={`id`}
                  initialValue={id}
                  component="input"
                  hidden
                  disabled
                />
                <Field<string>
                  name={`code`}
                  initialValue={code}
                  component="input"
                  hidden
                  disabled
                />
                <Field
                  defaultValue={name}
                  name="name"
                  size="sm"
                  component={TextInput}
                  style={{ maxWidth: "14rem" }}
                />
              </td>

              <td>
                <Field
                  defaultValue={isActive}
                  name="isActive"
                  type="checkbox"
                  size="sm"
                  component={CheckboxInput}
                />
              </td>

              <td className="td-fixed-width">
                <Button
                  type="submit"
                  size="sm"
                  onClick={handleSubmit}
                  style={{ marginRight: "0.1rem" }}
                >
                  {"Ulož"}
                </Button>
                <Button
                  type="button"
                  size="sm"
                  onClick={() => setEditedItemId(undefined)}
                  variant="outline-secondary"
                >
                  {"Zpět"}
                </Button>
              </td>
            </Fragment>
          )}
        />
      )}
      {editedItemId !== itemId && (
        <Fragment>
          <td>{code}</td>
          <td>
            <div className="car-color-preview" style={{ backgroundColor: value }}></div>
          </td>
          <td>{name}</td>
          <td>{isActive ? "Ano" : "Ne"}</td>

          <td className="td-fixed-width">
            <Button
              className="btn-show-on-hover"
              onClick={() => setEditedItemId(itemId)}
              type="button"
              size="sm"
              variant="outline-primary"
            >
              {"Upravit"}
            </Button>
          </td>
        </Fragment>
      )}
    </tr>
  );
};

export default EditableCarColorRow;
