import React from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import "./PlaceBadge.scss"
interface IProps {
    name: string;
    description?:string;
    style?: React.CSSProperties | undefined;
  }
  
const PlaceBadge: React.FC<IProps> = ({  name,  style , description}) =>{
    return (
        <OverlayTrigger
        placement="right"
        overlay={
          <Tooltip
          id={`tooltip-place-${name}`}
            className="tooltip-place"          >
            {`${description}${name}`}
          </Tooltip>
        }
      >
      <div className="place-badge" style={style}>
            {name}
        </div>
      </OverlayTrigger>
        
    )
}

export default PlaceBadge
