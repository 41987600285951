export function calculateAverage(arr: number[] | undefined) {

    if(arr === undefined) return 0;

    // Check if the array is empty
    if (!arr || arr.length === 0) {
        return 0;
    }
    // Convert strings to integers and calculate sum using reduce
    const sum = arr
        .reduce((acc, curr) => acc + curr, 0);

    // Calculate average and round it to the nearest integer
    return Math.round(sum / arr.length);
}