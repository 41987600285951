import React, { useContext, useEffect } from "react";
import { RootStoreContext } from "../../app/stores/rootStore";
import { Form, Button, Row, Col } from "react-bootstrap";
import { Form as FinalForm, Field } from "react-final-form";
import SelectInput from "../../app/common/form/SelectInput";
import { observer } from "mobx-react-lite";

const LoginPage = () => {
  const rootStore = useContext(RootStoreContext);
  const {login, getUsersAndPlaces, users, places} = rootStore.authStore;
  useEffect(() => {
    getUsersAndPlaces();
  }, [getUsersAndPlaces]);

  const handleFinalFormSubmit = (form: any) => {
    login(form.userId);
  };

  return (
    <div style={{paddingTop:"2rem"}}>
      <FinalForm
        onSubmit={handleFinalFormSubmit}
        render={({ handleSubmit,values }) => (
          <Form onSubmit={handleSubmit}>
            <Row style={{paddingLeft:"10rem",paddingRight:"10rem"}}>
            <Col sm={3}>
                <Field
                  name="placeId"
                  title="Místo"
                  options={places
                    .filter((place) => place.placeId < 100) 
                    .map((place) => ({
                      key: place.placeId,
                      value: `${place.placeName}`,
                    }))}
                  component={SelectInput}
                  isEmptyOption={true}
                  parse={(value) => (value ? parseInt(value) : undefined)}
                />
              </Col>
              <Col sm={7}>
                <Field
                  name="userId"
                  title="Uživatel"
                  options={users.filter(u=> u.placeId === values.placeId).map((user) => ({
                    key: user.userId,
                    value: `${user.fullName} (${user.userRoleName}) - ${user.placeName}`,
                  }))}
                  component={SelectInput}
                  isEmptyOption={true}
                  parse={(value) => (value ? parseInt(value) : undefined)}
                />
              </Col>
              <Col sm={2}>
                <Button variant="primary" type="submit">
                  Přihlásit
                </Button>
              </Col>
            </Row>
          </Form>
        )}
      />
    </div>
  );
};

export default observer(LoginPage);
