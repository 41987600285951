import { ICarTest } from "../../models/carTest";
import { ICarBodyTypeItem, ICarModel, ICarModelItem, IEnumItem } from "../../models/enum";
import { IWorker } from "../../models/expertGroup";
import { IUser } from "../../models/user";

export const sortByStateAndStartAt = () => {
  return (a: ICarTest, b: ICarTest) => {
    // equal items sort equally
    if (a.testStateId === b.testStateId) {
      // equal items sort equally
      if (a.startAt === b.startAt) {
        return 0;
      }
      // nulls sort before anything else
      else if (a.startAt === null) {
        return -1;
      } else if (b.startAt === null) {
        return 1;
      }
      // otherwise, if we're ascending, lowest sorts first
      else {
        return a.startAt! < b.startAt! ? -1 : 1;
      }
    }
    // if we're ascending, lowest sorts first
    else {
      return a.testStateId < b.testStateId ? -1 : 1;
    }
  };
};

export const sortByStatusAndSurname = () => {
  return (a: IWorker | IUser, b: IWorker| IUser) => {
    // equal items sort equally
    if (a.isActive === b.isActive) {
      // equal items sort equally
      if (a.surname === b.surname) {
        return 0;
      }

      // otherwise, if we're ascending, lowest sorts first
      else {
        return a.surname.localeCompare(b.surname, "cs", {
          sensitivity: "base",
        });
      }
    }

    // if we're ascending, active sorts first
    else {
      return a.isActive ? -1 : 1;
    }
  };
};

export const sortByStatusAndCode = () => {
  return (a: any, b: any) => {
    // equal items sort equally
    if (a.isActive === b.isActive) {
      // equal items sort equally
      if (a.code === b.code) {
        return 0;
      }

      // otherwise, if we're ascending, lowest sorts first
      else {
        return a.code.localeCompare(b.code, "cs", {
          sensitivity: "base",
        });
      }
    }

    // if we're ascending, active sorts first
    else {
      return a.isActive ? -1 : 1;
    }
  };
};
