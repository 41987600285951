import React, { Fragment } from "react";
import { IEnums } from "../../../app/models/enum";
import { IHistory } from "../../../app/models/car";
import moment from "moment";
import { DATE_TIME_FORMAT } from "../../../app/common/utils/constants";
import { Badge, Alert } from "react-bootstrap";
import "./CarHistoryTab.scss";

interface IProps {
  enums: IEnums | undefined;
  history: IHistory[];
}
const CarHistoryTab: React.FC<IProps> = ({ history }) => {
  const formatedCarHistoryRecord = (h: IHistory) => {
    if (!h) return;
    const isReason = h.description.lastIndexOf("<reason>") !== -1;
    const reasonPart = isReason
      ? h.description.substring(
          h.description.lastIndexOf("<reason>") + 8,
          h.description.lastIndexOf("</reason>")
        )
      : undefined;
    const descriptionPart = isReason
      ? h.description.substring(0, h.description.lastIndexOf("<reason>"))
      : h.description;
    const isCanceledTransport =
      h.description.indexOf("Zrušení transportu vozu") !== -1;

    const historyRecord = (
      <Fragment>
        <Badge variant={isCanceledTransport ? "danger" : "primary"}>
          {h.carState}
        </Badge>{" "}
        {moment(h.dateOfChange).format(DATE_TIME_FORMAT)}
        {" - "}
        {h.userFullName}
        {" - "}
        {descriptionPart}
        {reasonPart && <br />}
        {reasonPart && <em>{reasonPart}</em>}
      </Fragment>
    );
    return historyRecord;
  };

  const formatedCarTestHistoryRecord = (h: IHistory) => {
    if (!h) return;
    const historyRecord = (
      <Fragment>
        <Badge variant="secondary">{h.carTestState}</Badge>{" "}
        {moment(h.dateOfChange).format(DATE_TIME_FORMAT)}
        {" - "}
        {/* <FontAwesomeIcon icon={faUser}/>*/}
        {h.userFullName}
        {" - "}
        {h.description} {h.carTestExpertGroupName} <br />
        <em>{h.carTestNote}</em>
      </Fragment>
    );
    return historyRecord;
  };

  return (
    <div id="cardetail-history">
      {(!history || !history.length) && (
        <Alert variant="info">Vůz nemá zaznamenánu žádnou historii.</Alert>
      )}
      {!!history.length && (
        <ul className="history-list">
          {history.map((h) => {
            // car test history record
            if (h.carTestId || h.carAnalysisTermId)
              return (
                <ul key={h.carHistoryId}>
                  <li
                    key={h.carHistoryId}
                    className="history-item history-item--test"
                  >
                    {formatedCarTestHistoryRecord(h)}
                  </li>
                </ul>
              );
            // car history record
            else
              return (
                <li
                  key={h.carHistoryId}
                  className="history-item history-item--car"
                >
                  {formatedCarHistoryRecord(h)}
                </li>
              );
          })}
        </ul>
      )}
    </div>
  );
};

export default CarHistoryTab;
