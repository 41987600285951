import React, {useContext, useEffect, useRef} from "react";
import FullCalendar from "@fullcalendar/react";
import "@fullcalendar/core/main.css";
import "@fullcalendar/timegrid/main.css";
import "./FullCalendar.scss";
import {RootStoreContext} from "../../app/stores/rootStore";
import {observer} from "mobx-react-lite";
import {IEnumItem} from "../../app/models/enum";
import {Form} from "react-bootstrap";
import {IUser} from "../../app/models/user";
import GroupFullCalendar from "./GroupFullCalendar";
import {checkAuthRule} from "../../authorization/Can";
import {userRoles} from "../../app/models/auth";
import {useLoadEnumsQuery} from "../../app/api/client";

const GroupSchedulePage = () => {
  const rootStore = useContext(RootStoreContext);
  const {
    expertGroupId,
    setExpertGroupId,
    userId,
    setUserId,
    expertGroupUsers,
    defaultDateFrom,
    setDefaultDateFrom,
  } = rootStore.pageGroupScheduleStore;
    const {data: enums} = useLoadEnumsQuery();

    const {user} = rootStore.authStore;
    const {loadExpertGroups, expertGroups} = rootStore.expertGroupStore;

  useEffect(() => {
    return function cleanup() {
      // remember which dates were loaded before leaving the page
      setDefaultDateFrom(
        calendarComponentRef?.current?.getApi()?.view.currentStart
      );
    };
  }, [setDefaultDateFrom]);

  useEffect(() => {
        loadExpertGroups();
    }, [loadExpertGroups]);

  const calendarComponentRef = useRef<FullCalendar>(null);

  if (!enums || expertGroups.length === 0) return <></>;

  const experGroupSelect = (
    <Form.Control
      className="center center--first"
      as="select"
      name={"ExperGroupId"}
      disabled={!checkAuthRule(user?.userRoleId,  "expert-group-calendar-page:changeExpertGroup", {
        userExpertGroupId: user?.expertGroupId,
        expertGroupId: expertGroupId,
        managedExpertGroups: user?.managedGroups,
      })}
      onChange={(e: any) => {
        const expertGroupId = Number.parseInt(e.target.value);
        setExpertGroupId(expertGroupId);
      }}
      value={expertGroupId?.toString()}
    >
      {enums &&
        enums.expertGroups
          .slice()
          .filter(
            (m) =>
              m.isActive && m.placeId == user?.placeId &&
              (user?.userRoleId === userRoles.planAdministrator || user?.userRoleId === userRoles.expertGroupWorker || user?.userRoleId === userRoles.coordinator || user?.userRoleId === userRoles.emissionManager) ||
              (user?.userRoleId === userRoles.expertGroupLeader && (user?.expertGroupId === m.key ||
                checkAuthRule(user?.userRoleId,  "expert-group-calendar-page:changeExpertGroup", {
                  userExpertGroupId: user?.expertGroupId,
                  expertGroupId: m.key,
                  managedExpertGroups: user?.managedGroups,
              })))
          )
          .map((option: IEnumItem) => (
          <option key={option.key} value={option.key}>
            {option.value}
          </option>
        ))}
    </Form.Control>
  );

  const userSelect = (
    <Form.Control
      className="center center--second"
      as="select"
      name={"UserId"}
      onChange={(e: any) => {
        const userId = e.target.value
          ? Number.parseInt(e.target.value)
          : undefined;
        setUserId(userId);
      }}
      value={userId?.toString()}
      disabled={!checkAuthRule(user?.userRoleId,  "expert-group-calendar-page:changeWorker")}
    >
      <option>Všichni pracovníci skupiny</option>
      {expertGroupUsers &&
        expertGroupUsers.map((user: IUser) => (
          <option key={user.userId} value={user.userId}>
            {user.fullName} {!user.isActive && "- neaktivní"}
          </option>
        ))}
    </Form.Control>
  );

  return (
    <div className="container-full" id="fullcalendar-page">
      <div className="absolute">
        {experGroupSelect}
        {userSelect}
      </div>
      {/* {isLoading &&<div>Loader </div>} */}
      <GroupFullCalendar
        calendarComponentRef={calendarComponentRef}
        defaultDateFrom={defaultDateFrom}
      />
    </div>
  );
};

export default observer(GroupSchedulePage);
