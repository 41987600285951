import React, { Fragment } from "react";
import { Field } from "react-final-form";
import { Table } from "react-bootstrap";
import CheckboxInput from "../../../../app/common/form/CheckboxInput";
import { IProtocolArea } from "../../../../app/models/protocol";
import TextInput from "../../../../app/common/form/TextInput";

interface IProps {
  bateryArea: IProtocolArea;
  isEdit: boolean;
  isDisabled: boolean;
}

const BateryArea: React.FC<IProps> = ({ bateryArea, isEdit, isDisabled }) => {
  function totalRules(area: IProtocolArea): number {
    let count = 0;
    area.protocolPartGroups?.forEach((group) => {
      group.partRules?.forEach((rule) => {
        count++;
      });
    });
    return count;
  }

  return (
    <Fragment>
      <Table className="protocol-table">
        <thead>
          <tr>
            <th className="headingrow" colSpan={10}>
              <h2>{bateryArea?.name}</h2>
            </th>
            <th className={`headingrow ${isDisabled ? "" : "no-print"}`} colSpan={2}>
              <Field
                name={`bateryArea.notPerform`}
                title="Neprovedeno"
                options={[
                  { key: 1, value: "ano" },
                  { key: 0, value: " ne " },
                ]}
                format={(v) => (v ? 1 : 0)}
                parse={(v) => (v ? true : false)}
                type={"checkbox"}
                initialValue={bateryArea?.notPerform || false}
                component={CheckboxInput}
                disabled={!isEdit}
                labelWidth={8}
                valueWidth={4}
              />
            </th>
          </tr>
        </thead>
      </Table>
      <Table className={`protocol-table ${isDisabled ? "no-print" : ""}`}>
        <tbody>
          <tr className={`${isDisabled ? "disabled" : ""}`}>
            {bateryArea?.protocolPartGroups?.map((partGroup) => (
              <td
                key={partGroup.protocolPartGroupId}
                style={{
                  width: `${
                    partGroup.renderRowSpanLabel
                      ? 100 / (bateryArea?.protocolPartGroups?.length || 1) / 2
                      : 100 / (bateryArea?.protocolPartGroups?.length || 1)
                  }%`,
                }}
                colSpan={partGroup.partRules?.length}
                rowSpan={
                  partGroup.renderRowSpanLabel &&
                  partGroup.renderRowSpanLabel > 1
                    ? partGroup.renderRowSpanLabel
                    : 1
                }
              >
                {partGroup.name}
              </td>
            ))}
          </tr>
          {/* Radek se pravidlem = part rule */}
          <tr className={`${isDisabled ? "disabled" : ""}`}>
            {bateryArea?.protocolPartGroups?.map((partGroup) =>
              partGroup.partRules?.map(
                (partRule) =>
                  !partGroup.renderRowSpanLabel && (
                    <td key={partRule.protocolRuleId}>{partRule.name}</td>
                  )
              )
            )}
          </tr>
          {/* Radek s namerenou hodnotou = part rule value */}
          <tr className={`batery-value-row ${isDisabled ? "disabled" : ""}`}>
            {bateryArea?.protocolPartGroups?.map((partGroup, groupIndex) =>
              partGroup.partRules?.map((partRule, ruleIndex) => (
                <td key={partRule.protocolRuleId}>
                  <Field<string>
                    name={`bateryArea.protocolPartGroups[${groupIndex}].partRules[${ruleIndex}].measuredValue`}
                    initialValue={partRule.measuredValue?.toString()}
                    component={TextInput}
                    size="sm"
                    disabled={!isEdit || isDisabled}
                  />
                </td>
              ))
            )}
          </tr>
        </tbody>
      </Table>
    </Fragment>
  );
};

export default BateryArea;
