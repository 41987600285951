import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserPlus } from "@fortawesome/free-solid-svg-icons";
import { getWorkersString } from "../../app/common/utils/czechDeclension";
interface IProps {
  id: number;
  missingCount?: number;
  style?: React.CSSProperties;
}
const MissingWorkers: React.FC<IProps> = ({ missingCount, id, style }) => {
  return (
    <div style={style} className="tooltip-icon">
      <OverlayTrigger
        placement="right"
        trigger={"hover"}
        overlay={
          <Tooltip id={`tooltip-missingworker-${id}`}>
            {!missingCount
              ? "Ke zkoušce není nikdo přiřazen!"
              : `Je nutné přiřadit ještě ${missingCount} ${getWorkersString(
                  missingCount
                )}`}
          </Tooltip>
        }
      >
        <span>
          <FontAwesomeIcon icon={faUserPlus} style={{ padding: "2px" }} />
        </span>
      </OverlayTrigger>
    </div>
  );
};

export default MissingWorkers;
