import React, {useContext} from "react";
import {Button, Col, Form, Row} from "react-bootstrap";
import {Field, Form as FinalForm} from "react-final-form";
import TextInput from "../../../../../app/common/form/TextInput";
import TextAreaInput from "../../../../../app/common/form/TextAreaInput";
import SelectInput from "../../../../../app/common/form/SelectInput";
import {RootStoreContext} from "../../../../../app/stores/rootStore";
import {IDefect,} from "../../../../../app/models/protocol"
import {IEnums} from "../../../../../app/models/enum";
import {observer} from "mobx-react-lite";
import {defectOptions} from "./defectUtils";


interface IProps {
    defect: IDefect,
    enums: IEnums | undefined;
    isEdit: boolean;
    handleDefect: (defect: IDefect) => void
}

const DefectForm: React.FC<IProps> = (
    {
        defect,
        isEdit,
        enums,
        handleDefect,
    }) => {
    const rootStore = useContext(RootStoreContext);
    const {closeModal} = rootStore.modalStore;

    const handleFinalFormSubmit = (defect: IDefect) => {
        handleDefect(defect)
        closeModal();
    };

    return (
        <>
            <FinalForm<IDefect>
                onSubmit={handleFinalFormSubmit}
                initialValues={defect}
                render={({handleSubmit}) => (
                    <Form onSubmit={handleSubmit}>
                        {defect.protocolRuleId && (
                            <Row>
                                <Col sm={2} className="u-text-right">Název části</Col>
                                <Col sm={10}>
                                    <Field<string>
                                        name={`partName`}
                                        initialValue={defect?.partName}
                                        component={TextInput}
                                        disabled={true}
                                    />
                                </Col>
                            </Row>
                        )}
                        <Row>
                            <Col sm={2} className="u-text-right">Popis závady</Col>
                            <Col sm={10}>
                                <Field<string>
                                    name={`description`}
                                    initialValue={defect?.description}
                                    component={TextAreaInput}
                                    disabled={!isEdit}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={2} className="u-text-right">Analýza</Col>
                            <Col sm={10}>
                                <Field<string>
                                    name={`analysis`}
                                    initialValue={defect?.analysis}
                                    component={TextAreaInput}
                                    disabled={!isEdit}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={2} className="u-text-right">Opatření</Col>
                            <Col sm={10}>
                                <Field<string>
                                    name={`remedy`}
                                    initialValue={defect?.remedy}
                                    component={TextInput}
                                    disabled={!isEdit}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={2} className="u-text-right">Známka</Col>
                            <Col sm={4}>
                                <Form.Group>
                                    <Field
                                        name={`signDefect`}
                                        options={defectOptions}
                                        component={SelectInput}
                                        disabled={!isEdit}
                                    />
                                </Form.Group>
                            </Col>
                            <Col sm={2} className="u-text-right">Termín</Col>
                            <Col sm={4}>
                                <Form.Group>
                                    <Field
                                        name={`deadline`}
                                        options={enums?.calendarWeeks}
                                        component={SelectInput}
                                        isEmptyOption={true}
                                        disabled={!isEdit}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={2} className="u-text-right">Zodpovídá</Col>
                            <Col sm={4}>
                                <Field<string>
                                    name={`responsible`}
                                    initialValue={defect?.responsible}
                                    component={TextInput}
                                    disabled={!isEdit}
                                />
                            </Col>
                            <Col sm={2} className="u-text-right">Status</Col>
                            <Col sm={4}>
                                <Field<number>
                                    name={`state`}
                                    options={enums?.defectStates}
                                    parse={(value) => value && parseInt(value)}
                                    component={SelectInput}
                                    isEmptyOption={true}
                                    disabled={!isEdit}
                                />
                            </Col>
                        </Row>
                        <hr/>
                        <div className="flex-row flex-center">
                            {isEdit && (
                                <Button
                                    type="submit"
                                    className="btn-modal"
                                >
                                    {`Vložit`}
                                </Button>
                            )}
                            <Button
                                type="button"
                                onClick={closeModal}
                                className="btn-modal"
                                variant="outline-secondary"
                            >
                                Zrušit
                            </Button>
                        </div>
                    </Form>
                )}
            />
        </>
    );
};

export default observer(DefectForm);
