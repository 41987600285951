import "./AcousticsProtocol.scss";
import React, {Fragment, useContext, useState} from "react";
import {Field, Form as FinalForm} from "react-final-form";
import {Link, RouteComponentProps, useParams} from "react-router-dom";
import {RootStoreContext} from "../../../../app/stores/rootStore";
import {observer} from "mobx-react-lite";
import {IAcousticsProtocol} from "../../../../app/models/protocol";
import {Button, Col, Form, Row, Table} from "react-bootstrap";
import EditSaveResetButtons from "../../../ButtonGroups/EditSaveResetButtons";
import logo from "../../../../assets/images/Skoda_Wordmark_RGB_Emerald_Green.svg";
import TextAreaInput from "../../../../app/common/form/TextAreaInput";

import Can from "../../../../authorization/Can";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPrint, faFileExcel} from "@fortawesome/free-solid-svg-icons";
import DefectsArea from "../protocolParts/DefectsArea";
import ProtocolHeaderCarInfo from "../../partialProtocols/protocolParts/ProtocolHeaderCarInfo";
import {FormApi} from "final-form";
import {
    useAcousticsProtocolMutation,
    useAcousticsProtocolQuery,
    useLoadEnumsQuery,
    useProtocolCodeTableValuesQuery,
} from "../../../../app/api/client";
import {toast} from "react-toastify";
import Loader from "../../../loader/Loader";
import {defectMutator} from "../protocolParts/DefectMutator";
import SubHeader from "./SubHeader";
import MeasuredCarArea from "./MeasuredCarArea";
import ExecutedTestArea from "./ExecutedTestArea";
import AuxiliaryAggregatesArea from "./AuxiliaryAggregatesArea";
import DefectsCount from "../protocolParts/DefectsCount";
import AverageScore from "./AverageScore";
import AlertMessage from "../../../alert/AlertMessage";
import agent from "../../../../app/api/agent";

interface PartialProtocolParams {
    protocolTypeId: string;
    carId: string;
    carTestId: string;
    carProtocolId: string;
}

const AcousticsProtocol: React.FC<
    RouteComponentProps<PartialProtocolParams>
> = ({ history}) => {
    const params = useParams<PartialProtocolParams>();
    const protocolTypeId = Number(params.protocolTypeId);
    const carId = Number(params.carId);
    const carTestId = Number(params.carTestId);
    const carProtocolId = Number(params.carProtocolId);

    const rootStore = useContext(RootStoreContext);
    const {user} = rootStore.authStore;
    const [edit, setEdit] = useState(false);

    const [errors, setErrors] = useState([]);
    const [showSubmitError, setShowSubmitError] = useState(false);

    const {data: enums, isFetching: loadingEnums} = useLoadEnumsQuery();

    const {
        data: acousticsProtocol,
        isFetching: isLoading
    } = useAcousticsProtocolQuery(carId, carTestId, protocolTypeId, carProtocolId);

    const {
        data: protocolCodeTableValues
    } = useProtocolCodeTableValuesQuery(protocolTypeId, carTestId, undefined);

    const {mutateAsync} = useAcousticsProtocolMutation();

    const handleFinalFormSubmit = async (protocol: IAcousticsProtocol) => {

        setErrors([]);
        setShowSubmitError(false);

        mutateAsync(protocol)
        .then((response) => {
            setEdit(false);
            toast.success(`Protokol byl uložen`);
            if (!params.carProtocolId || params.carProtocolId == "null") {
                history.push(
                    `/AcousticsProtocol/${response.protocolTypeId}/${response.carId}/${response.carTestId}/null/${response.carProtocolId}`,
                );
            }
        })
        .catch((error) => {
            setErrors(Object.values(error.response?.data?.errors));
            setShowSubmitError(true);
            toast.error(`Vyskytla se chyba při uložení protokolu`);
        });
    };

    // Reset all form values to initial values
    const handleReset = (form: FormApi<IAcousticsProtocol>) => {
        form.reset();
        setEdit(false);
    };

    const handleGetDocument = async () => {

        try {
          const protocolTypeId = Number(params.protocolTypeId);
          const carId = Number(params.carId);
          const carTestId = Number(params.carTestId);
          const carProtocolId = Number(params.carProtocolId);
    
          var response = await agent.Protocols.getProtocolDocumentXlsx(carId, carTestId, protocolTypeId, carProtocolId);
          const href = URL.createObjectURL(response.data);
          console.log(response.headers);
    
          const link = document.createElement('a');
          link.href = href;
          link.setAttribute('download', 'file.xlsx'); //or any other extension
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          URL.revokeObjectURL(href);
        } catch (error) {
            console.error("Error downloading:", error);
        }
      };

    if (isLoading || !acousticsProtocol || loadingEnums || !protocolCodeTableValues) {
        return <Loader/>;
    }

    return (
        <div
            id="AcousticsProtocol"
            className="container"
            style={{marginBottom: "3rem"}}
        >
            <div className="printheader">
                <div className="printheader__left">
                    <div className="printheader--image">
                        <img src={logo} alt="logo"/>
                    </div>
                </div>
                <div className="printheader__right">
                    <div className="text--header">Zkouškový protokol</div>
                    <div className="text--text">
                        Pracovní skupina{" "}
                        <span className="text--expert-group">
                        {acousticsProtocol?.carTest.expertGroupName}
                        </span>
                    </div>
                    <div className="text--text">
                        <span>{acousticsProtocol?.placeCode}</span>{" "}
                        {acousticsProtocol?.placeDescription}
                    </div>
                </div>
            </div>

            <div className="printfooter">
                <Table>
                    <tbody>
                    <tr>
                        <td colSpan={11}>
                            {acousticsProtocol?.carTest.placeCode}{" "}
                            {acousticsProtocol?.car?.modelVdsCode || ""} / 1_01 &emsp; ŠKODA
                            AUTO a.s., Tř. Václava Klementa 869, 293 01 Mladá Boleslav II.,
                            Česká republika
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={3}>{acousticsProtocol?.documentName}</td>
                        <td>{acousticsProtocol?.registrationNumber}</td>
                        <td>{acousticsProtocol?.storageMethod}</td>
                        <td>{acousticsProtocol?.shreddingSign}</td>
                        <td colSpan={2}>{acousticsProtocol?.shreddingTriggerEvent}</td>
                        <td>{acousticsProtocol?.classification}</td>
                        <td>{acousticsProtocol?.storageLocation}</td>
                    </tr>
                    </tbody>
                </Table>
            </div>

            <table className="table-page-content">
                <thead>
                <tr>
                    <td>
                        {/* <!--placeholder for the fixed-position header--> */}
                        <div className="page-header-space"></div>
                    </td>
                </tr>
                </thead>

                <tbody>
                <tr>
                    <td>
                        <div>
                            <FinalForm<IAcousticsProtocol>
                                onSubmit={handleFinalFormSubmit}
                                initialValues={acousticsProtocol}
                                mutators={{
                                    calculateDefects: defectMutator.calculateDefects
                                }}
                                render={({handleSubmit, values, form}) => (
                                    <Form onSubmit={handleSubmit} id="protocol-form">
                                        <div className="pageheader  no-print">
                                            <div className="pageheader__left">
                                                <h1>Zkouškový protokol</h1>
                                                <h3>
                                                    Pracovní skupina{" "}
                                                    <span className="text--expert-group">
                                                    {acousticsProtocol?.carTest.expertGroupName}
                                                    </span>{" "}
                                                </h3>
                                            </div>
                                            <div className="pageheader__right">
                                                <Can
                                                    roleId={user?.userRoleId}
                                                    perform="acoustics-protocol:edit"
                                                    data={{
                                                        userExpertGroupId: user?.expertGroupId,
                                                    }}
                                                    yes={
                                                        <EditSaveResetButtons
                                                            edit={edit}
                                                            setEdit={setEdit}
                                                            handleReset={handleReset}
                                                            form={form}
                                                            isSaving={false}
                                                            printButton={true}
                                                        />
                                                    }
                                                    no={
                                                        <Button
                                                            variant="outline-info"
                                                            type="button"
                                                            onClick={() => window.print()}
                                                        >
                                                            <FontAwesomeIcon icon={faPrint} title="Tisk"/>{" "}
                                                            Tisk
                                                        </Button>
                                                    }
                                                />
                                                {!edit && (
                                                    <Fragment>
                                                        <Button
                                                            variant="outline-info"
                                                            type="button"
                                                            style={{
                                                            marginLeft: "1rem",
                                                            }}
                                                            onClick={() => handleGetDocument()}
                                                        >
                                                            <FontAwesomeIcon icon={faFileExcel} title="XLSX" />{" "}
                                                            XLSX
                                                        </Button>
                                                        <Button
                                                            variant="outline-secondary"
                                                            style={{
                                                                marginLeft: "1rem",
                                                            }}
                                                            key={
                                                                "CarDetail_" + acousticsProtocol?.car.carId
                                                            }
                                                            as={Link}
                                                            to={`/car/${acousticsProtocol?.car.carId}`}
                                                        >
                                                            Detail vozu
                                                        </Button>
                                                        <Button
                                                            variant="outline-secondary"
                                                            style={{
                                                                marginLeft: "1rem",
                                                            }}
                                                            key={
                                                                "CarTest_" +
                                                                acousticsProtocol?.carTest.carTestId
                                                            }
                                                            as={Link}
                                                            to={`/carTest/${acousticsProtocol?.carTest.carTestId}`}
                                                        >
                                                            Detail zkoušky
                                                        </Button>
                                                    </Fragment>
                                                )}
                                            </div>
                                        </div>

                                        <AlertMessage
                                            type="danger"
                                            heading="Nastala chyba při ukládání protokolu"
                                            show={showSubmitError}
                                            setShow={setShowSubmitError}
                                            messageList={errors}
                                        />

                                        <div className="pagecontent">
                                            <ProtocolHeaderCarInfo
                                                car={acousticsProtocol.car}
                                                carTest={acousticsProtocol.carTest}
                                                measurementDate={undefined}
                                            />
                                            <SubHeader
                                                additionalInfoArea={acousticsProtocol?.additionalInfoArea}
                                                isEdit={edit}
                                                protocolCodeTableValues={protocolCodeTableValues}
                                            />
                                            <MeasuredCarArea
                                                measuredCarArea={acousticsProtocol.measuredCarArea}
                                                protocolCodeTableValues={protocolCodeTableValues}
                                                isEdit={edit}
                                            />
                                            <ExecutedTestArea
                                                executedTestArea={acousticsProtocol?.executedTestArea}
                                                protocolCodeTableValues={protocolCodeTableValues}
                                                isEdit={edit}
                                            />
                                            <AuxiliaryAggregatesArea
                                                isEdit={edit}
                                                auxiliaryAggregatesArea={acousticsProtocol?.auxiliaryAggregatesArea}
                                                form={form}
                                            />
                                            <AverageScore isEdit={edit} />
                                            <div className="protocol">
                                                {/*Tabulka zavad*/}
                                                {!isLoading &&
                                                    acousticsProtocol &&
                                                    acousticsProtocol?.defectsArea && (
                                                        <DefectsArea
                                                            isEdit={edit}
                                                            isDisabled={
                                                                values.defectsArea?.notPerform || false
                                                            }
                                                        />
                                                    )}

                                                {/*Poznamka k celkovemu protokolu*/}
                                                <div style={{paddingTop: "1em"}}>
                                                    <h2>Poznámka</h2>
                                                    <Field<string>
                                                        name="note"
                                                        component={TextAreaInput}
                                                        disabled={!edit}
                                                        rows={5}
                                                    />
                                                </div>
                                                <DefectsCount/>
                                                <Table style={{marginTop: "50px"}}>
                                                    <tbody>
                                                    <tr>
                                                        <td style={{border: "0"}}>
                                                            <Form.Group controlId={"defectsCount"}>
                                                                <Row>
                                                                    <Col xs={3}>
                                                                        <Form.Label>
                                                                            Odpovědná osoba:
                                                                        </Form.Label>
                                                                    </Col>
                                                                    <Col
                                                                        xs={5}
                                                                        style={{
                                                                            textAlign: "center",
                                                                            borderBottom: "1px solid lightgray",
                                                                        }}
                                                                    >
                                                                        <Form.Label>
                                                                            {
                                                                                acousticsProtocol.workersExecutingTest
                                                                            }
                                                                        </Form.Label>
                                                                    </Col>
                                                                </Row>
                                                            </Form.Group>
                                                        </td>
                                                    </tr>
                                                    </tbody>
                                                </Table>
                                            </div>
                                        </div>
                                    </Form>
                                )}
                            />
                        </div>
                    </td>
                </tr>
                </tbody>

                <tfoot>
                <tr>
                    <td>
                        <div className="page-footer-space"></div>
                    </td>
                </tr>
                </tfoot>
            </table>
        </div>
    );
};

export default observer(AcousticsProtocol);
