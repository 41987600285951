import React, { Fragment } from "react";
import { Button } from "react-bootstrap";
import { Field, Form as FinalForm } from "react-final-form";
import CheckboxInput from "../../../app/common/form/CheckboxInput";
import TextInput from "../../../app/common/form/TextInput";

interface IProps {
  handleEditItem: (form: any) => void;
  id: number;
  name: string;
  code: string;
  isActive: boolean;
  editedItemId: string | undefined;
  setEditedItemId: (id: string | undefined) => void;
  enumName: string;
  carModelId?: number;
  emissionColumn?: boolean;
  emissionFree?: boolean;
  vdsCodeColumn?: boolean;
  vdsCode?: string;
  codeColumn?: boolean;
  prProductColumn?: boolean;
  prProduct?: string;
}

const EditableEnumTableRow: React.FC<IProps> = ({
  handleEditItem,
  id,
  name,
  code,
  isActive,
  emissionFree,
  emissionColumn,
  vdsCodeColumn,
  vdsCode,
  editedItemId,
  setEditedItemId,
  carModelId,
  enumName,
  codeColumn,
  prProductColumn,
  prProduct
}) => {
  const itemId = `${enumName}-${id}`;
  codeColumn = codeColumn ?? true;
  return (
    <tr
      key={id}
      className={
        editedItemId === itemId
          ? "active show-btn-on-hover"
          : "show-btn-on-hover"
      }
    >
      {editedItemId === itemId && (
        <FinalForm
          onSubmit={handleEditItem}
          validate={(values) => {
            const errors = {} as any;
            if (!values.name) {
              errors.name = "Název nesmí být prázdný";
            }
            return errors;
          }}
          render={({ handleSubmit }) => (
            <Fragment>
              {codeColumn && <td>{code}</td>}             
              <td>
                <Field<number>
                  name={`id`}
                  initialValue={id}
                  component="input"
                  hidden
                  disabled
                />
                {carModelId && (
                  <Field<number>
                    name={`carModelId`}
                    initialValue={carModelId}
                    component="input"
                    hidden
                    disabled
                  />
                )}
                <Field<string>
                  name={`code`}
                  initialValue={code}
                  component="input"
                  hidden
                  disabled
                />
                <Field
                  defaultValue={name}
                  name="name"
                  size="sm"
                  component={TextInput}
                />
              </td>
              {vdsCodeColumn && (
                <td>
                  <Field
                    defaultValue={vdsCode}
                    name="modelVdsCode"
                    size="sm"
                    component={TextInput}
                  />
                </td>
              )}
              {prProductColumn && (
                <td>
                  <Field
                    defaultValue={prProduct}
                    name="prProduct"
                    size="sm"
                    component={TextInput}
                  />
                </td>
              )}
              {emissionColumn && (
                <td>
                  <Field
                    defaultValue={emissionFree}
                    name="emissionFree"
                    type="checkbox"
                    size="sm"
                    component={CheckboxInput}
                  />
                </td>
              )}
              <td>
                <Field
                  defaultValue={isActive}
                  name="isActive"
                  type="checkbox"
                  size="sm"
                  component={CheckboxInput}
                />
              </td>

              <td className="td-fixed-width">
                <Button
                  type="submit"
                  size="sm"
                  onClick={handleSubmit}
                  style={{ marginRight: "0.1rem" }}
                >
                  {"Ulož"}
                </Button>
                <Button
                  type="button"
                  size="sm"
                  onClick={() => setEditedItemId(undefined)}
                  variant="outline-secondary"
                >
                  {"Zpět"}
                </Button>
              </td>
            </Fragment>
          )}
        />
      )}
      {editedItemId !== itemId && (
        <Fragment>
          {codeColumn && <td>{code}</td>}
          <td>{name}</td>
          {vdsCodeColumn &&<td>{vdsCode}</td>}
          {prProductColumn &&<td>{prProduct}</td>}
          {emissionColumn &&<td>{emissionFree ? "Ano" : "Ne"}</td>}
          <td>{isActive ? "Ano" : "Ne"}</td>

          <td className="td-fixed-width">
            <Button
              className="btn-show-on-hover"
              onClick={() => setEditedItemId(itemId)}
              type="button"
              size="sm"
              variant="outline-primary"
            >
              {"Upravit"}
            </Button>
          </td>
        </Fragment>
      )}
    </tr>
  );
};

export default EditableEnumTableRow;
