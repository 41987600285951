import React from "react";
import { FieldRenderProps } from "react-final-form";
import "./ToggleSwitchInput.scss";
import { Row, Col, Form } from "react-bootstrap";

type Props = FieldRenderProps<any, any>;
const ToggleSwitchInput: React.FC<Props> = ({
  input,
  title,
  disabled,
}: any) => {
  return (
    <Row style={{ marginBottom: "1rem" }}>
      <Col sm={4} className="u-text-right">
        {title && <Form.Label>{title}</Form.Label>}
      </Col>
      <Col sm={8}>
        <div className="toggle-switch">
          <input
            {...input}
            type="checkbox"
            className="toggle-switch-checkbox"
            name={input.name}
            id={input.name}
            disabled={disabled}
          />
          <label className="toggle-switch-label" htmlFor={input.name}>
            <span
              className={
                disabled
                  ? "toggle-switch-inner toggle-switch-disabled"
                  : "toggle-switch-inner"
              }
              data-yes="Ano"
              data-no="Ne"
            />
            <span
              className={
                disabled
                  ? "toggle-switch-switch toggle-switch-disabled"
                  : "toggle-switch-switch"
              }
            />
          </label>
        </div>
      </Col>
    </Row>
  );
};

export default ToggleSwitchInput;
