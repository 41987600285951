import rules from "./rbac-rules";
import React from "react";
import { userRoles } from "../app/models/auth";
// role based access control based on this article https://auth0.com/blog/role-based-access-control-rbac-and-react-apps/

interface IProps {
  yes: JSX.Element;
  no?: JSX.Element;
  roleId?: number;
  perform: string;
  data?: any;
}
const check = (rules: any, roleId: any, action: string, data: any) => {
  if (!roleId) return false;
  const role = userRoles[roleId];
  if (!role) return false;

  const permissions = rules[role];
  if (!permissions) {
    // role is not present in the rules
    return false;
  }

  const staticPermissions = permissions.static;

  if (staticPermissions && staticPermissions.includes(action)) {
    // static rule not provided for action
    return true;
  }

  const dynamicPermissions = permissions.dynamic;

  if (dynamicPermissions) {
    const permissionCondition = dynamicPermissions[action];
    if (!permissionCondition) {
      // dynamic rule not provided for action
      return false;
    }

    return permissionCondition(data);
  }
  return false;
};

export const checkAuthRule = (roleId: any, action: string, data?: any):boolean =>{
  return check(rules, roleId, action, data);
}

const Can: React.FC<IProps> = (props) => {
    return check(rules, props.roleId, props.perform, props.data)
    ? props.yes
    : props.no || null;
};

export default Can;
