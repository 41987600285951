import React, { useContext, useEffect, useState } from "react";
import { Form, Button, Row, Col } from "react-bootstrap";
import { Field, Form as FinalForm } from "react-final-form";
import TextInput from "../../../../app/common/form/TextInput";
import TextAreaInput from "../../../../app/common/form/TextAreaInput";
import SelectInput from "../../../../app/common/form/SelectInput";
import { RootStoreContext } from "../../../../app/stores/rootStore";
import { IDefect, ICarBodyProtocol } from "../../../../app/models/protocol"
import { IEnums } from "../../../../app/models/enum";
import { observer } from "mobx-react-lite";

interface IProps { 
    defect?: IDefect,
    rowNumber?: number,
    enums: IEnums | undefined;
    isEdit: boolean;
    values?: ICarBodyProtocol;
    handleDefectForm: (defect: IDefect, values?: ICarBodyProtocol) => void,
}
const DefectForm: React.FC<IProps> = ({defect, enums, isEdit, values, handleDefectForm}) => {
  const rootStore = useContext(RootStoreContext);
  const { closeModal } = rootStore.modalStore;
  const [ editDefect ] = useState(defect);

  const handleFinalFormSubmit = (form: IDefect) => {
    handleDefectForm(form, values);
    closeModal();
  };

  return (
    <div>
    <FinalForm
        onSubmit={handleFinalFormSubmit}
        initialValues={editDefect}
        render={({ handleSubmit }) => (
        <Form onSubmit={handleSubmit}>
            {editDefect?.protocolRuleId && (
              <Row>
                <Col sm={2} className="u-text-right">Název části</Col>
                <Col sm={10}>
                <Field<string>
                  name={`partName`}
                  initialValue={editDefect?.partName}
                  component={TextInput}
                  disabled={true}
                />
                </Col>
              </Row>
            )}
            <Row>
              <Col sm={2} className="u-text-right">Popis závady</Col>
              <Col sm={10}>
                <Field<string>
                  name={`description`}
                  initialValue={editDefect?.description}
                  component={TextAreaInput}
                  disabled={!isEdit}
                />
              </Col> 
            </Row>
            <Row>
              <Col sm={2} className="u-text-right">Analýza</Col>
              <Col sm={10}>
              <Field<string>
                name={`analysis`}
                initialValue={editDefect?.analysis}
                component={TextAreaInput}
                disabled={!isEdit}
              />
              </Col> 
            </Row>
            <Row>
              <Col sm={2} className="u-text-right">Opatření</Col>
              <Col sm={10}>
              <Field<string>
                name={`remedy`}
                initialValue={editDefect?.remedy}
                component={TextInput}
                disabled={!isEdit}
              />
              </Col> 
            </Row>
            <Row>
              <Col sm={2} className="u-text-right">Známka</Col>
              <Col sm={4}>
              <Form.Group>
                <Field
                  name={`signDefect`}
                  options={[
                    { key: null, value: "" },
                    { key: 1, value: "1 - A1" },
                    { key: 2, value: "2 - A" },
                    { key: 3, value: "3 - A" },
                    { key: 4, value: "4 - B1" },
                    { key: 5, value: "5 - B" },
                    { key: 6, value: "6 - C1" },
                    { key: 7, value: "7 - C" },
                    { key: 8, value: "8 - C" },
                    { key: 9, value: "9 - C" },
                    { key: 10, value: "10 - C" },
                  ]}
                  component={SelectInput}
                  disabled={!isEdit}
                />
              </Form.Group>
              </Col> 
              <Col sm={2} className="u-text-right">Termín</Col>
              <Col sm={4}>
                <Form.Group>
                  <Field
                    name={`deadline`}
                    options={enums?.calendarWeeks}
                    component={SelectInput}
                    isEmptyOption={true}
                    disabled={!isEdit}
                  />
                </Form.Group>
              </Col> 
            </Row>
            <Row>
              <Col sm={2} className="u-text-right">Zodpovídá</Col>
              <Col sm={4}>
                <Field<string>
                  name={`responsible`}
                  initialValue={editDefect?.responsible}
                  component={TextInput}
                  disabled={!isEdit}
                />
              </Col> 
              <Col sm={2} className="u-text-right">Status</Col>
              <Col sm={4}>
                <Field<number>
                  name={`state`}
                  options={enums?.defectStates}
                  parse={(value) => value && parseInt(value)}
                  component={SelectInput}
                  isEmptyOption={true}
                  disabled={!isEdit}
                />
              </Col> 
            </Row>
            <hr />
            <div className="flex-row flex-center">
            {isEdit && (
              <Button
                  type="submit"
                  className="btn-modal"
              >
                {`Vložit`}
              </Button>
            )}
            <Button
                type="button"
                onClick={closeModal}
                className="btn-modal"
                variant="outline-secondary"
            >
                Zrušit
            </Button>
            </div>
        </Form>
        )}
    />
    </div>
  );
};

export default observer(DefectForm);
