import React, { Fragment } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { FieldRenderProps } from "react-final-form";
import Select from "react-select";

type Props = FieldRenderProps<IOption[], any>;

interface IOption {
  value: number;
  label: string;
}

interface IWorker {
  userId: number;
  login: string;
  rownumber?: number;
  shortcut: string;
  titleBefore?: string;
  firstName: string;
  surname: string;
  titleAfter?: string;
  fullName: string;
  workTimeStart: string;
  workTimeEnd: string;
  isDeleted: boolean;
  isActive: boolean;
  performTests: boolean;
  isAvailableToTest: boolean;
  isFixed: boolean;
}

const WorkersMultiSelectInput: React.FC<Props> = ({
  input,
  meta,
  title,
  options,
  placeholder,
  isEmptyOption,
  maxSelectedOptions,
  disabled,
  closeMenuOnSelect,
  ...rest
}: Props) => {
  //console.log(options);

  // see https://react-select.com
  const errorMessage = (meta.error || meta.submitError) && meta.touched && (
    <div className="multiselectinput-invalid-feedback">
      {meta.error || meta.submitError}
    </div>
  );
  const colourStyles = {
    control: (styles: any, { isDisabled, isFocused, isSelected }: any) => ({
      ...styles,
      backgroundColor: isDisabled ? "#e9ecef" : "white",
      borderColor: isFocused ? "#92db7b" : "#ced4da",
    }),
    option: (styles: any, { data, isDisabled, isFocused, isSelected }: any) => {
      return {
        ...styles,
        backgroundColor: data.isAvailableToTest
          ? isFocused
            ? "#c8f3d1"
            : "#e8faec"
          : isFocused
          ? "#f8c2c7"
          : "#fce5e7",

        color: isDisabled
          ? "#ccc"
          : data.isAvailableToTest
          ? "#28a745"
          : "#e62336",
        cursor: isDisabled ? "not-allowed" : "default",

        ":active": {
          ...styles[":active"],
          backgroundColor:
            !isDisabled && (data.isAvailableToTest ? "#92db7b" : "#f6b0b6"),
        },
      };
    },
    multiValue: (styles: any, { data }: any) => {
      return {
        ...styles,
        backgroundColor: data.isFixed ? "lightgrey" : "#e6f4fb",
      };
    },
    multiValueLabel: (styles: any, { data }: any) => ({
      ...styles,
      color: "#333333",
    }),
    //   multiValueRemove: (styles:any, { data }:any) => ({
    //     ...styles,
    //     color: data.color,
    //     ':hover': {
    //       backgroundColor: data.color,
    //       color: 'white',
    //     },
    //   }),

    multiValueRemove: (base: any, state: any) => {
      return state.data.isFixed ? { ...base, display: "none" } : base;
    },
  };

  const inputField = (
    <Fragment>
      <Select
        isMulti
        isDisabled={disabled}
        isClearable={
          !options.some((option: any) => option.isFixed) &&
          (!input.value || !input.value.some((value: any) => value.isFixed))
        }
        placeholder={placeholder}
        closeMenuOnSelect={closeMenuOnSelect}
        className={"multiselect"}
        classNamePrefix="select"
        noOptionsMessage={(inputValue) => "Žádné další možnosti"}
        {...input}
        {...rest}
        onChange={(e: any, option: any) => {
          if (option.removedValue && option.removedValue.isFixed) return;
          input.onChange(e);
        }}
        options={
          input.value && input.value.length === maxSelectedOptions
            ? []
            : options
        }
        styles={colourStyles}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary: "#92db7b",
            neutral0: "#ffffff",
            neutral5: "#e9ecef",
            neutral10: "#ced4da",
            neutral20:
              (meta.error || meta.submitError) && meta.touched
                ? "#e62336"
                : "#ced4da",
            neutral30:
              (meta.error || meta.submitError) && meta.touched
                ? "#e62336"
                : "#ced4da",
            neutral40: "#495057",
            neutral50: "#7f8489",
            neutral60: "#495057",
          },
        })}
      />
    </Fragment>
  );

  return (
    <Fragment>
      {title && (
        <Form.Group controlId={input.name}>
          <Row style={{ marginBottom: "1rem" }}>
            <Col xl={4} lg={4} md={4} sm={4} xs={4} className="u-text-right">
              {title && <Form.Label>{title}</Form.Label>}
            </Col>
            <Col xl={8} lg={8} md={8} sm={8} xs={8}>
              {inputField}
              {errorMessage}
            </Col>
          </Row>
        </Form.Group>
      )}
      {!title && (
        <Form.Group controlId={input.name} style={{ marginBottom: "1rem" }}>
          {inputField}
          {errorMessage}
        </Form.Group>
      )}
    </Fragment>
  );
};

export default WorkersMultiSelectInput;
