import React from "react";
import { Field } from "react-final-form";
import CheckboxInput from "../../../../app/common/form/CheckboxInput";
import { IProtocolArea } from "../../../../app/models/protocol";

interface IProps {
  protocolArea: IProtocolArea;
  areaIndex: number;
  isEdit: boolean;
  isDisabled: boolean;
}

const ProtocolArea: React.FC<IProps> = ({
  protocolArea,
  areaIndex,
  isEdit,
  isDisabled,
}) => {
  return (
    <tr
      className={`protocol-area no-print ${
        isDisabled ? "protocol-area--disabled" : ""
      }`}
    >
      <td className="print-only"></td>
      <td colSpan={3}>
        <h2>{protocolArea.name}</h2>
      </td>
      <td>
        {protocolArea.enableDoorProtect && (
          <Field
          name={`areas[${areaIndex}].doorProtect`}
          title="Door protect"
          options={[
            { key: 1, value: "ano" },
            { key: 0, value: " ne " },
          ]}
          format={(v) => (v ? 1 : 0)}
          parse={(v) => (v ? true : false)}
          type={"checkbox"}
          initialValue={protocolArea.doorProtect || false}
          component={CheckboxInput}
          disabled={!isEdit}
          labelWidth={8}
          valueWidth={4}
        />
        )}
      </td>
      <td className="no-print">
        <Field
          name={`areas[${areaIndex}].notPerform`}
          title="Neprovádět"
          options={[
            { key: 1, value: "ano" },
            { key: 0, value: " ne " },
          ]}
          format={(v) => (v ? 1 : 0)}
          parse={(v) => (v ? true : false)}
          // inputOnChange={(v: any) => {
          //   setDisabled(v.target.checked);
          // }}
          type={"checkbox"}
          initialValue={protocolArea.notPerform || false}
          component={CheckboxInput}
          disabled={!isEdit}
          labelWidth={8}
          valueWidth={4}
        />
      </td>
    </tr>
  );
};

export default ProtocolArea;
