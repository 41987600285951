import React from "react";
import {Field} from "react-final-form";
import {Col, Container, Form, Row} from "react-bootstrap";
import TextInput from "../../../../app/common/form/TextInput";
import SwitchInput from "../../../../app/common/form/SwitchInput";
import './AdditionalInfoArea.scss'
import {IAdditionalInfoArea} from "../../../../app/models/protocol";

interface IProps {
    isEdit: boolean;
    additionalInfo: IAdditionalInfoArea;
}

const AdditionalInfoArea: React.FC<IProps> = ({isEdit, additionalInfo}) => {
    return (
        <div
            className='additionalInfoArea'>
            <Container style={{paddingLeft: 0, paddingRight: 0,}}>
                <Row noGutters>
                    <Col sm={2}></Col>
                    <Col sm={3}>
                        <small className="text-muted">
                            Před zkouškou
                        </small>
                    </Col>
                    <Col sm={3}>
                        <small className="text-muted">
                            Po zkoušce
                        </small>
                    </Col>
                    <Col></Col>
                </Row>
                <Row noGutters>
                    <Col xs={2}>
                        <Form.Label>
                            Stav tachometru (km)
                        </Form.Label>
                    </Col>
                    <Col xs={3}>
                        <Field
                            name="additionalInfoArea.additionalInfoValue.speedometerValueBefore"
                            component={TextInput}
                            disabled={!isEdit}
                            fieldWidth={8}
                        />
                    </Col>
                    <Col xs={3}>
                        <Field
                            name="additionalInfoArea.additionalInfoValue.speedometerValueAfter"
                            component={TextInput}
                            disabled={!isEdit}
                            fieldWidth={8}
                        />
                    </Col>
                    <Col>
                        <Field
                            name="additionalInfoArea.additionalInfoValue.importanceofCar"
                            title="Důležitost"
                            initialValue={additionalInfo?.additionalInfoValue?.importanceofCar ?? "neuvedeno"}
                            component={TextInput}
                            disabled={true}
                        />
                    </Col>
                </Row>
                <Row noGutters>
                    <Col>
                        <Field
                            name="additionalInfoArea.additionalInfoValue.engineNumber"
                            title="Číslo motoru"
                            initialValue={additionalInfo?.additionalInfoValue?.engineNumber}
                            component={TextInput}
                            disabled={!isEdit}
                        />
                    </Col>
                    <Col>
                        <Field
                            name="additionalInfoArea.additionalInfoValue.vipCar"
                            title="VIP vůz"
                            options={[
                            { key: 1, value: "ano" },
                            { key: 0, value: " ne " },
                            ]}
                            format={(v) => (v ? 1 : 0)}
                            parse={(v) => (v ? true : false)}
                            component={SwitchInput}
                            disabled={true}
                        />
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default AdditionalInfoArea;
