import React, { useState, useContext, useEffect } from "react";
import { Form, Col, Row, Tabs, Tab, Container } from "react-bootstrap";
import { Field, Form as FinalForm, FormSpy } from "react-final-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import TextInput from "../../app/common/form/TextInput";
import NumberInput from "../../app/common/form/NumberInput";
import ColorPicker from "../../app/common/form/ColorPicker";
import { RootStoreContext } from "../../app/stores/rootStore";
import { observer } from "mobx-react-lite";
import { RouteComponentProps, Link, Prompt } from "react-router-dom";
import DateInput from "../../app/common/form/DateInput";
import AlertMessage from "../alert/AlertMessage";
import {
  IExpertGroupDetail,
  IWorker,
  IAddedWorker,
  convertIExpertGroupToIExpertGroupForm,
  convertIWorkerToIWorkerForm,
  convertIAddedWorkerToIWorkerForm,
  convertIUserToIAddedWorker,
  IWorkerForm,
} from "../../app/models/expertGroup";

import { IUser } from "../../app/models/user";
import moment from "moment";
import Can from "../../authorization/Can";
import EditSaveResetButtons from "../ButtonGroups/EditSaveResetButtons";
import { toast } from "react-toastify";
import { FormApi } from "final-form";
import WorkersTab from "./WorkersTab";
import BlockingEventsTab from "./BlockingEventsTab";

interface DetailParams {
  id: string;
}

const ExpertGroupDetailPage: React.FC<RouteComponentProps<DetailParams>> = ({
  match,
  history,
}) => {
  const rootStore = useContext(RootStoreContext);
  const { openDialog } = rootStore.modalStore;
  const { enums, loadEnums } = rootStore.enumStore;
  const {
    loadExpertGroup,
    expertGroupDetailedRegistry,
    loadingInitial,
    updateExpertGroup,
    isSaving
  } = rootStore.expertGroupStore;
  const { user } = rootStore.authStore;

  const [key, setKey] = useState("workers");

  const [edit, setEdit] = useState(false);
  const [changed, setChanged] = useState(false);

  const [errors, setErrors] = useState([]);
  const [showSubmitError, setShowSubmitError] = useState(false);

  const [addWorkerRownumber, setAddWorkerRownumber] = useState(0);

  const [expertGroup, setExpertGroup] = useState<
    IExpertGroupDetail | undefined
  >(undefined);

  // array with workers loaded from database
  const [loadedWorkers, setLoadedWorkers] = useState<IWorker[]>([]);

  // array with workers added to group in the application
  const [addedWorkers, setAddedWorkers] = useState<IAddedWorker[]>([]);

  const expertGroupId = Number(match.params.id);
  useEffect(() => {
    const expertGroupId = Number(match.params.id);
    if (!expertGroupId && match.params.id) history.push("/notfound");
    loadEnums();

    if (!expertGroupId) {
      setEdit(true);
    }

    if (expertGroupId) {
      setEdit(false);
      loadExpertGroup(expertGroupId).then(() => {
        const expertGroupL = expertGroupDetailedRegistry.get(expertGroupId);
        setExpertGroup(expertGroupL);
        setLoadedWorkers(
          expertGroupL?.workers.map((w, index: number) => {
            w.rownumber = index;
            return w;
          }) || []
        );
      });
    }
  }, [
    loadEnums,
    loadExpertGroup,
    match.params.id,
    expertGroupDetailedRegistry,
    history,
  ]);

  const handleFinalFormSubmit = (
    values: any,
    form: FormApi<IExpertGroupDetail>
  ) => {
    const expertGroupForm = convertIExpertGroupToIExpertGroupForm(values);

    setErrors([]);
    setShowSubmitError(false);

    // convert added workers to required form
    const rowNumbers = addedWorkers.map((a) => a.rownumber);
    const addedWorkersForm = values.addedWorkers
      ? values.addedWorkers
          .filter((worker: IAddedWorker, index: number) =>
            rowNumbers.includes(index)
          )
          .map((w: IAddedWorker) => convertIAddedWorkerToIWorkerForm(w))
      : [];

    // convert already saved workers to required form
    const loadedWorkersForm = loadedWorkers
      ? loadedWorkers.map((worker) => {
          let workerForm = convertIWorkerToIWorkerForm(worker);
          const workerValue = values.workers.find(
            (w: IWorkerForm) => w.userId === worker.userId
          );
          workerForm.workTimeStart = workerValue.workTimeStart;
          workerForm.workTimeEnd = workerValue.workTimeEnd;
          return workerForm;
        })
      : [];

    // join all types of workers to a single array
    expertGroupForm.workers = [...loadedWorkersForm, ...addedWorkersForm];

    // send request to backend and save changes
    updateExpertGroup(expertGroupForm)
      .then((response) => {
        // nacti kolize z response
        const collisions = response.carTestConflicts;

        //pokud pole s konflikty nebude prazdne -> dialog s potvrzenim ulozeni
        if (!!collisions?.length && !values.isConfirmedSave) {
          toast.warn(`Zkouška zatím nebyla uložena`);

          openDialog(
            <div>
              <ul>
                {collisions.map((col, index) => (
                  <li key={index}>
                    {col.message}{" "}
                    {col.carTestId && (
                      <Link to={`/carTest/${col.carTestId}`} target="_blank">
                        <FontAwesomeIcon
                          title="Otevři detail zkoušky"
                          icon={faExternalLinkAlt}
                        />
                      </Link>
                    )}
                  </li>
                ))}
              </ul>
            </div>,
            {
              title: "Uložením změn v odborné skupině vzniknou tyto kolize",
              confirmButtonLabel: "Pokračovat a uložit",
              cancelButtonLabel: "Zpět",
              onConfirm: () => {
                form.change("isConfirmedSave", true);
                form.submit();
              },
              onCancel: () => {
                form.change("isConfirmedSave", false);
              },
            }
          );
        }
        // pokud ulozenim nevznikly zadne kolize, nebo je uzivatel potvrdil, finalne uloz
        if (!collisions?.length || values.isConfirmedSave) {
          setEdit(false);
          setChanged(false);
          // setAddedAbsences([]);
          setAddedWorkers([]);
          setExpertGroup(response);
       //   setLoadedAbsences(response.blockingEvents || []);
          setLoadedWorkers(
            response.workers.map((w, index: number) => ({
              ...w,
              rownumber: index,
            })) || []
          );
          // // pouze pro nove vytvorenou skupinu
          if (!match.params.id)
            history.push(`/expertGroup/${response.expertGroupId}`);
          toast.success(`Odborná skupina ${expertGroup?.name} byla uložena`);
        }
      })
      .catch((error) => {
        setErrors(Object.values(error.response.data.errors));
        setShowSubmitError(true);
      });
  };

  // Reset all form values to initial values
  const handleReset = (form: any) => {
    setEdit(false);
    setChanged(false);
    form.reset();
    // remove all added workers
    setAddedWorkers([]);
    // reset all deleted flags
    setLoadedWorkers(loadedWorkers.map((w) => ({ ...w, isDeleted: false })));
  };

  const addWorkers = (users: IUser[]) => {
    let rowNumber = addWorkerRownumber;

    const workers = users.map((user) =>
      convertIUserToIAddedWorker(user, rowNumber++)
    );

    setAddWorkerRownumber(rowNumber);
    setAddedWorkers([...addedWorkers, ...workers]);

    setChanged(true);
  };

  const removeWorker = (userId: number) => {
    setAddedWorkers(addedWorkers.filter((w) => w.userId !== userId));
    const loadedWorker = loadedWorkers.find((w) => w.userId === userId);
    if (loadedWorker) loadedWorker.isDeleted = true;
    setChanged(true);
  };


  const validationRules = (values: IExpertGroupDetail) => {
    const errors = {} as any;
    errors.workers = [];
    errors.addedWorkers = [];
    if (!values.name) {
      errors.name = "Název skupiny musí být vyplněn";
    }
    if (!values.code) {
      errors.code = "Zkratka musí být vyplněna";
    }
    if (!values.workerCountPerTest) {
      errors.workerCountPerTest = "Počet pracovníků musí být vyplněn";
    }
    if (!values.workplacesCount) {
      errors.workplacesCount = "Počet pracovních pozic musí být vyplněn";
    }
    if (values.workerCountPerTest && values.workerCountPerTest < 1) {
      errors.workerCountPerTest = "Počet pracovníků musí být větší než nula";
    }
    if (values.workplacesCount && values.workplacesCount < 1) {
      errors.workplacesCount = "Počet pracovních pozic musí být větší než nula";
    }
    if (values.code && values.code.length > 10) {
      errors.code = "Zkratka nesmí mít víc než 10 znaků";
    }
    if (
      values.dayOfExtraTimeEnd ||
      values.dayOfExtraTimeStart ||
      values.workTimeEnd ||
      values.workTimeStart
    ) {
      if (!values.dayOfExtraTimeEnd) {
        errors.dayOfExtraTimeEnd =
          "Datum do musí být vyplněno, nebo musí být smazány všechny ostatní pole pro navýšení pracovní doby";
      }
      if (!values.dayOfExtraTimeStart) {
        errors.dayOfExtraTimeStart =
          "Datum od musí být vyplněno, nebo musí být smazány všechny ostatní pole pro navýšení pracovní doby";
      }
      if (!values.workTimeEnd) {
        errors.workTimeEnd =
          "Začátek pracovní doby musí být vyplněn, nebo musí být smazány všechny ostatní pole pro navýšení pracovní doby";
      }
      if (!values.workTimeStart) {
        errors.workTimeStart =
          "Konec pracovní doby musí být vyplněn, nebo musí být smazány všechny ostatní pole pro navýšení pracovní doby";
      }
      if (
        new Date(values.dayOfExtraTimeStart) >
        new Date(values.dayOfExtraTimeEnd)
      ) {
        errors.dayOfExtraTimeEnd = "Datum do musí být větší než datum od";
        errors.dayOfExtraTimeStart = "Datum od musí být menší než datum do";
      }
      if (values.workTimeStart > values.workTimeEnd) {
        errors.workTimeStart = "Začátek pracovní doby musí být menší než konec";
        errors.workTimeEnd = "Konec pracovní doby musí být větší než začátek";
      }
    }

    if (values.workers) {
      values.workers.forEach((w) => {
        errors.workers[w.rownumber!] = {};
        if (loadedWorkers.find((l) => l.userId === w.userId)?.isDeleted) return;
        if (!w.workTimeStart)
          errors.workers.splice(w.rownumber, 1, {
            ...errors.workers[w.rownumber!],
            workTimeStart: "Začátek pracovní doby musí být vyplněn",
          });
        if (!w.workTimeEnd)
          errors.workers.splice(w.rownumber, 1, {
            ...errors.workers[w.rownumber!],
            workTimeEnd: "Konec pracovní doby musí být vyplněn",
          });

        if (w.workTimeStart > w.workTimeEnd) {
          errors.workers.splice(w.rownumber!, 1, {
            workTimeStart: "Začátek pracovní doby musí být menší než konec",
            workTimeEnd: "Konec pracovní doby musí být větší než začátek",
          });
        }
      });
    }

    if ((values as any).addedWorkers) {
      (values as any).addedWorkers.forEach((w: IAddedWorker) => {
        errors.addedWorkers[w.rownumber] = {};
        if (!addedWorkers.find((a) => a.userId === w.userId)) return;
        if (!w.workTimeStart)
          errors.addedWorkers.splice(w.rownumber, 1, {
            ...errors.addedWorkers[w.rownumber],
            workTimeStart: "Začátek pracovní doby musí být vyplněn",
          });
        if (!w.workTimeEnd)
          errors.addedWorkers.splice(w.rownumber, 1, {
            ...errors.addedWorkers[w.rownumber],
            workTimeEnd: "Konec pracovní doby musí být vyplněn",
          });

        if (w.workTimeStart > w.workTimeEnd) {
          errors.addedWorkers.splice(w.rownumber, 1, {
            workTimeStart: "Začátek pracovní doby musí být menší než konec",
            workTimeEnd: "Konec pracovní doby musí být větší než začátek",
          });
        }
      });
    }

    return errors;
  };

  return (
    <Container>
      <Prompt
        when={edit && changed}
        message="Na stránce byly provedeny změny, které nejsou uloženy. Opravdu chcete odejít?"
      />

      <div>
        {!loadingInitial && enums && (
          <FinalForm
            onSubmit={handleFinalFormSubmit}
            initialValues={expertGroup}
            validate={(values) => validationRules(values)}
            render={({ handleSubmit, form }) => (
              <Form onSubmit={handleSubmit}>
                <div className="pageheader">
                  <div className="pageheader__left">
                    {!expertGroup ? (
                      <h1>Založení nové odborné skupiny</h1>
                    ) : (
                      <h1>Detail skupiny {expertGroup.name} </h1>
                    )}
                  </div>
                  <div className="pageheader__right">
                    <Can
                      roleId={user?.userRoleId}
                      perform="expert-group-detail-page:edit"
                      data={{
                        userExpertGroupId: user?.expertGroupId,
                        expertGroupId: expertGroup?.expertGroupId,
                        managedExpertGroups: user?.managedGroups,
                      }}
                      yes={
                        <EditSaveResetButtons
                          edit={edit}
                          setEdit={setEdit}
                          handleReset={handleReset}
                          form={form}
                          isSaving={isSaving} 
                        />
                      }
                    />
                  </div>
                </div>
                <AlertMessage
                  type="danger"
                  heading="Nastala chyba při ukládání"
                  show={showSubmitError}
                  setShow={setShowSubmitError}
                  messageList={errors}
                />
                <Row>
                  <Col md={6}>
                    <h2>Základní informace</h2>
                    <Field<string>
                      name="name"
                      title="Název skupiny"
                      component={TextInput}
                      disabled={!edit}
                      required
                    />
                    <Field<string>
                      name="code"
                      title="Zkratka"
                      component={TextInput}
                      disabled={!edit}
                      required
                    />

                    {enums && (
                      <Field
                        name="colorId"
                        title="Barva"
                        initialValue={1}
                        options={enums.colors}
                        component={ColorPicker}
                        disabled={!edit}
                      />
                    )}

                    <Field
                      name="workerCountPerTest"
                      title="Počet pracovníků pro provedení zkoušky"
                      component={NumberInput}
                      min={0}
                      parse={(value) => value && parseInt(value)}
                      disabled={!edit}
                      required
                    />

                    <Field
                      name="workplacesCount"
                      title="Počet pracovních pozic"
                      component={NumberInput}
                      min={0}
                      disabled={!edit}
                      parse={(value) => value && parseInt(value)}
                      required
                    />
                  </Col>
                  <Col md={6}>
                    <h2>Zadání navýšené pracovní doby</h2>
                    <Field
                      name="dayOfExtraTimeStart"
                      title="Datum od"
                      disabled={!edit}
                      component={DateInput}
                      format={(val) => (val ? moment(val).toDate() : undefined)}
                      parse={(val) => (val ? moment(val).toDate() : undefined)}
                    />
                    <Field
                      name="dayOfExtraTimeEnd"
                      title="Datum do"
                      component={DateInput}
                      disabled={!edit}
                      format={(val) => (val ? moment(val).toDate() : undefined)}
                      parse={(val) => (val ? moment(val).toDate() : undefined)}
                    />
                    <Field<string>
                      name="workTimeStart"
                      title="Začátek pracovní doby"
                      type="time"
                      component={TextInput}
                      disabled={!edit}
                    />
                    <Field<string>
                      name="workTimeEnd"
                      title="Konec pracovní doby"
                      type="time"
                      component={TextInput}
                      disabled={!edit}
                    />
                  </Col>
                </Row>

                <Tabs
                  id="controlled-tab-expertgroup"
                  className="tabs"
                  activeKey={key}
                  onSelect={(k: string) => setKey(k)}
                >
                  <Tab
                    eventKey="workers"
                    title="Pracovníci odborné skupiny"
                    className="tabs__tab"
                  >
                    <WorkersTab
                      expertGroupId = {expertGroupId}
                      loadedWorkers={loadedWorkers}
                      edit={edit}
                      removeWorker={removeWorker}
                      addedWorkers={addedWorkers}
                      addWorkers={addWorkers}
                    />
                  </Tab>

                  <Tab eventKey="blocks" title="Absence a blokace">
                    <BlockingEventsTab
                      editExpertGroup={edit}
                      expertGroupId={expertGroupId}
                      loadedWorkers={loadedWorkers}
                      addedWorkers={addedWorkers}
                    />
                  </Tab>
                </Tabs>
                <FormSpy
                  subscription={{ pristine: true, values: true }}
                  onChange={(state) => {
                    setChanged(changed || !state.pristine);
                  }}
                />
              </Form>
            )}
          />
        )}
      </div>
    </Container>
  );
};

export default observer(ExpertGroupDetailPage);
