import React from "react";
import { Container } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCarCrash, faQuestion } from "@fortawesome/free-solid-svg-icons";

const NotFound = () => {
  return (
    <div>
      <Container style={{ marginTop: "2rem" }}>
        <div>
          <div style={{position:"absolute", left:"10%", top:"20%", fontSize:"10rem"}}>404</div>
          <div>
            <div style={{ position: "relative", left: "50%" }}>
              <FontAwesomeIcon size={"7x"} icon={faQuestion} />
            </div>
            <div style={{ position: "relative", left: "30%" }}>
              <FontAwesomeIcon size={"9x"} icon={faCarCrash} color={"green"} />
            </div>
          </div>
          <div style={{ marginTop: "2rem" }}>
            <h1 className={"display-4"}>A jéje. Vypadá to, že jste se ztratil...</h1>
            <p className={"lead"}>
              Omlouváme se, ale stránka, kterou hledáte, neexistuje. Je možné že
              byla odstraněna, přesunuta nebo také nemusela nikdy existovat.
              Zkontrolujte si, prosím, zadanou adresu, zda neobsahuje překlep.
            </p>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default NotFound;
