import "./ScheduleCarDetail.scss";
import React from "react";
import { Row, Col } from "react-bootstrap";
import LabelValuePair from "../../../app/common/utils/LabelValuePair";
import { ICar } from "../../../app/models/car";
import "moment/locale/cs";
import moment from "moment";
import { DATE_TIME_FORMAT } from "../../../app/common/utils/constants";
import CarColor from "../../icons/CarColor";
import VipCar from "../../icons/VipCar";

interface IProps {
  car: ICar;
}

const ScheduleCarDetail: React.FC<IProps> = ({ car }) => {
  const labelWidth = 5;
  const valueWidth = 7;

  return (
    <Row>
      <Col xs={5}>
        {(car.vipCar || !!car.importantCarDesc) && (
          <LabelValuePair
            label="Důležitost"
            value={
              <span>
                {car.vipCar && (
                  <span className="flex-row">
                    <VipCar id={car.carId} /> VIP vůz
                  </span>
                )}
                {car.importantCarDesc}
              </span>
            }
            labelColSize={labelWidth}
            valueColSize={valueWidth}
          />
        )}
        <LabelValuePair
          label="KNR kód"
          value={car.prodIdentificationNum}
          labelColSize={labelWidth}
          valueColSize={valueWidth}
        />
        <LabelValuePair
          label="Typ vozu"
          value={car.testDestinationCode}
          labelColSize={labelWidth}
          valueColSize={valueWidth}
        />
        <LabelValuePair
          label="Model"
          value={car.modelName}
          labelColSize={labelWidth}
          valueColSize={valueWidth}
        />
        <LabelValuePair
          label="Karoserie"
          value={car.bodyTypeName}
          labelColSize={labelWidth}
          valueColSize={valueWidth}
        />
        <LabelValuePair
          label="Výbava"
          value={car.equipmentName}
          labelColSize={labelWidth}
          valueColSize={valueWidth}
        />
        <LabelValuePair
          label="Motorizace"
          value={car.engineName}
          labelColSize={labelWidth}
          valueColSize={valueWidth}
        />
        <LabelValuePair
          label="Barva"
          value={
            <CarColor
              code={car.colorCode}
              name={car.colorName}
              color={car.colorValue}
              id={car.carId}
            />
          }
          labelColSize={labelWidth}
          valueColSize={valueWidth}
        />
        {/* <LabelValuePair
          label="Převodovka"
          value={car.transmissionName}
          labelColSize={labelWidth}
          valueColSize={valueWidth}
        /> */}
        <LabelValuePair
          label="Převodovka"
          value={car.transmissionCode}
          labelColSize={labelWidth}
          valueColSize={valueWidth}
        />
        {!car.emissionFree && (
          <LabelValuePair
            label="Emisní norma"
            value={car.emissionStandard}
            labelColSize={labelWidth}
            valueColSize={valueWidth}
          />
        )}
        <LabelValuePair
          label="Typ řízení"
          value={car.typeOfControl}
          labelColSize={labelWidth}
          valueColSize={valueWidth}
        />
        <LabelValuePair
          label="Odběratel"
          value={car.customerCode}
          labelColSize={labelWidth}
          valueColSize={valueWidth}
        />
      </Col>
      <Col xs={7}>
        <LabelValuePair
          label="Číslo vozu"
          value={(car.vin && car.vin.slice(9)) || ""}
          labelColSize={labelWidth}
          valueColSize={valueWidth}
        />
        <LabelValuePair
          label="COPS VDS číslo"
          value={car.copsVDSNumber}
          labelColSize={labelWidth}
          valueColSize={valueWidth}
        />
        <LabelValuePair
          label="Logistické číslo"
          value={car.logisticNum}
          labelColSize={labelWidth}
          valueColSize={valueWidth}
        />
        <LabelValuePair
          label="Typ zkoušky"
          value={car.testTypeName}
          labelColSize={labelWidth}
          valueColSize={valueWidth}
        />
        <LabelValuePair
          label="Podurčení zkoušky"
          value={car.testPredestinationName}
          labelColSize={labelWidth}
          valueColSize={valueWidth}
        />

        <LabelValuePair
          label="Začátek produkce"
          value={car.startOfProduction}
          labelColSize={labelWidth}
          valueColSize={valueWidth}
        />
        <LabelValuePair
          label="Modelový rok"
          value={car.modelYear}
          labelColSize={labelWidth}
          valueColSize={valueWidth}
        />
        <LabelValuePair
          label="Dispozice od"
          value={
            car.availableFrom &&
            moment(car.availableFrom).format(DATE_TIME_FORMAT)
          }
          labelColSize={labelWidth}
          valueColSize={valueWidth}
        />
        <LabelValuePair
          label="Dispozice do"
          value={
            car.availableTo && moment(car.availableTo).format(DATE_TIME_FORMAT)
          }
          labelColSize={labelWidth}
          valueColSize={valueWidth}
        />
        <LabelValuePair
          label="Plánováno od"
          value={
            car.plannedFrom && moment(car.plannedFrom).format(DATE_TIME_FORMAT)
          }
          labelColSize={labelWidth}
          valueColSize={valueWidth}
        />
        <LabelValuePair
          label="Plánováno do"
          value={
            car.plannedTo && moment(car.plannedTo).format(DATE_TIME_FORMAT)
          }
          labelColSize={labelWidth}
          valueColSize={valueWidth}
        />
      </Col>
    </Row>
  );
};

export default ScheduleCarDetail;
