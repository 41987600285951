import React from 'react';
import ExpertGroupSortableItem from './ExpertGroupSortableItem';
import "./PlanningConditionsTab.scss";
import { IExpertGroupSequence } from "../../../app/models/planningCondition";
import { SortableContainer } from 'react-sortable-hoc';

interface IProps {
    expertGroupSequence: IExpertGroupSequence[]
}

const ExpertGroupSortableList: React.FC<IProps> = ({expertGroupSequence}) => {
  return (
    <ul className="list-sequence">
      {expertGroupSequence.map((value, index) => (
        <ExpertGroupSortableItem key={`item-${index}`} index={index} group={value} />
      ))}
    </ul>
  );
};

export default SortableContainer(ExpertGroupSortableList);