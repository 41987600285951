export interface IPlanCarsForm {
  PlanFrom?: Date;
  cars: IPlanCar[];
}

export class PlanCarsValues implements IPlanCarsForm {
  PlanFrom?: Date = roundMinutes(new Date());
  cars: IPlanCar[] = [];
  constructor(init?: IPlanCarsForm) {
    if (init?.PlanFrom) init.PlanFrom = roundMinutes(init.PlanFrom);
    Object.assign(this, init);
  }
}

// round time to the nearest hour - eg. 6:55-> 7:00, 18:05-> 19:00, 15:00->15:00
const roundMinutes = (date: Date) => {
  date.setHours(date.getHours() + (!!date.getMinutes() ? 1 : 0));
  date.setMinutes(0, 0, 0); // Resets also seconds and milliseconds

  return date;
};

export interface IPlanCar {
  carId: number;
  vipCar:boolean;
  priority?: number;
}
