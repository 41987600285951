import {MutableState, Tools} from "final-form";
import {IDrivingCharacteristicsProtocol} from "../../../../app/models/protocol";

export const drivingCharacteristicsMutators = {
    handleChangeExecutedTest: (args: any , state: MutableState<IDrivingCharacteristicsProtocol>, utils: Tools<IDrivingCharacteristicsProtocol>) => {
        const fieldValue: boolean = args[0].target.checked;
        const fieldName = args[0].currentTarget.name;
        var name: string = "";
        if (fieldValue === true) {
          var ruleIndex: number = -1;
          fieldName
            .split(".")
            .map((objName: string, i: number) => {
              if (objName.includes("partRules")) {
                ruleIndex = Number.parseInt(
                  objName
                    .replace("partRules", "")
                    .replace("[", "")
                    .replace("]", "") || "-1"
                );
              }
              name = objName;
            });

          if (name == "isOK") {
            utils.changeValue(
              state,
              `executedTestArea.partRules[${ruleIndex}].isDefect`,
              () => false
            );
            utils.changeValue(
              state,
              `executedTestArea.partRules[${ruleIndex}].notPerform`,
              () => false
            );
          }
          if (name == "isDefect") {
            utils.changeValue(
              state,
              `executedTestArea.partRules[${ruleIndex}].isOK`,
              () => false
            );
            utils.changeValue(
              state,
              `executedTestArea.partRules[${ruleIndex}].notPerform`,
              () => false
            );
          }
          if (name == "notPerform") {
            utils.changeValue(
              state,
              `executedTestArea.partRules[${ruleIndex}].isOK`,
              () => false
            );
            utils.changeValue(
              state,
              `executedTestArea.partRules[${ruleIndex}].isDefect`,
              () => false
            );
          }
        }
      },
};
