import React, { useContext, useEffect, useState } from "react";
import { Form, Button, Row, Col } from "react-bootstrap";
import { Field, Form as FinalForm } from "react-final-form";
import AlertMessage from "../../alert/AlertMessage";
import { RootStoreContext } from "../../../app/stores/rootStore";
import { IProtocolCopyRulesDTO } from "../../../app/models/protocol";
import { IEnums } from "../../../app/models/enum";
import { observer } from "mobx-react-lite";

interface IProps { 
    protocolCopyRules: IProtocolCopyRulesDTO,
    enums: IEnums | undefined;
    handleSuccessfullyCopyRules: () => void,
}

const ProtocolCopyRulesForm: React.FC<IProps> = ({protocolCopyRules, enums, handleSuccessfullyCopyRules}) => {
  const rootStore = useContext(RootStoreContext);
  const {
    copyProtocolRulesSetting
  } = rootStore.protocolStore;
  const { closeModal } = rootStore.modalStore;
  const [ copyRules ] = useState(protocolCopyRules);
  const [errors, setErrors] = useState([]);
  const [showSubmitError, setShowSubmitError] = useState(false);
  const [selectedNewCarModelId, setSelectedNewCarModelId] = useState<number>();
  const [selectedNewCarBodyTypeId, setSelectedNewCarBodyTypelId] = useState<number>();
  
  const handleFinalFormSubmit = (form: IProtocolCopyRulesDTO) => {
    form.newCarModelId = selectedNewCarModelId
    form.newCarBodyTypeId = selectedNewCarBodyTypeId;

    //console.log(form);

    setErrors([]);
    setShowSubmitError(false);
    copyProtocolRulesSetting(form)
      .then(() => {
        closeModal();
        handleSuccessfullyCopyRules();
      })
      .catch((error: any) => {
        //console.log(error);

        setErrors(
          error.response?.data?.errors &&
            Object.values(error.response?.data?.errors)
        );
        setShowSubmitError(true);
      });
  };


  return (
    <div>
    
    <FinalForm
        onSubmit={handleFinalFormSubmit}
        initialValues={copyRules}
        render={({ handleSubmit, form }) => (
        <Form onSubmit={handleSubmit}>
            <div className="pageheader">
                <h3>Vytvoření kopie pravidel protokolu pro model vozu</h3>
            </div>
            <AlertMessage
              type="danger"
              heading="Nastala chyba při ukládání"
              show={showSubmitError}
              setShow={setShowSubmitError}
              messageList={errors}
            />
            <Row style={{ paddingBottom: "10px" }}>
                <Col sm={3} className="u-text-right">
                  <Form.Label>Model vozu</Form.Label>
                </Col>
                <Col sm={9}>
                  {enums && (
                    <Form.Control
                      as="select"
                      style={{ width: "30rem" }}
                      name={"newCarModelId"}
                      value={copyRules.newCarModelId?.toString()}
                      onChange={(e: any) => {
                        setSelectedNewCarModelId(Number.parseInt(e.target.value))
                        setSelectedNewCarBodyTypelId(undefined);
                      }}
                      >

                      <option></option>
                      {enums?.carModels
                        .slice()
                        .sort((a, b) => (a.name < b.name ? -1 : 1))
                        .map((option) => (
                          <option key={option.id} value={option.id}>
                            {(option.modelVdsCode !== null ? option.modelVdsCode + ' - ' : "" ) + option.name}
                          </option>
                        ))}
                    </Form.Control>
                  )}
                </Col>
            </Row>
            <Row>
                <Col sm={3} className="u-text-right">
                  <Form.Label>Karoserie vozu</Form.Label>
                </Col>
                <Col sm={9}>
                  {enums && (
                    <Form.Control
                      as="select"
                      style={{ width: "30rem" }}
                      name={"newCarBodyTypeId"}
                      value={copyRules.newCarBodyTypeId?.toString()}
                      onChange={(e: any) => {
                        setSelectedNewCarBodyTypelId(Number.parseInt(e.target.value));
                      }}
                      >
                      <option></option>
                      {enums?.carBodyTypes
                        .filter(x => x.carModelId == selectedNewCarModelId)
                        .slice()
                        .sort((a, b) => (a.name < b.name ? -1 : 1))
                        .map((option) => (
                          <option key={option.id} value={option.id}>
                            {(option.modelVdsCode !== null ? option.modelVdsCode + ' - ' : "" ) + option.name}
                          </option>
                        ))}
                    </Form.Control>
                  )}
                </Col>
            </Row>
            <hr />
            <div className="flex-row flex-center">
              <Button
                  type="submit"
                  className="btn-modal"
              >
                Vytvořit kopii
              </Button>
            
              <Button
                type="button"
                onClick={closeModal}
                className="btn-modal"
                variant="outline-secondary"
              >
                Zrušit
              </Button>
            </div>
        </Form>
        )}
    />
    </div>
  );
};

export default observer(ProtocolCopyRulesForm);
