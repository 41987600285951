import React, {Fragment, useContext} from "react";
import {Button, Form, OverlayTrigger, Table, Tooltip} from "react-bootstrap";
import {Field, Form as FinalForm} from "react-final-form";
import {RootStoreContext} from "../../../app/stores/rootStore";
import {observer} from "mobx-react-lite";
import TextInput from "../../../app/common/form/TextInput";
import SwitchInput from "../../../app/common/form/SwitchInput";
import {AreaEnumDTO, GroupEnumDTO, IPartSettingDTO} from "../../../app/models/protocol";
import ProtocolPartForm from "../../settings/protocols/ProtocolPartForm"
import NumberInput from "../../../app/common/form/NumberInput";

import Loader from "../../loader/Loader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPen, faPlus} from "@fortawesome/free-solid-svg-icons";
import {useProtocolPartSettingsQuery} from "../../../app/api/client";

interface IProps {
  protocolType?: number | undefined;
  areaEnums?: AreaEnumDTO[] | undefined;
  groupEnums?:  GroupEnumDTO[] | undefined;
}

const ProtocolPart: React.FC<IProps> = ({ protocolType, areaEnums, groupEnums }) => {
  const rootStore = useContext(RootStoreContext);
  const { openModal } = rootStore.modalStore;
   const {data: protocolPartSetting, isFetching: isLoading} = useProtocolPartSettingsQuery(protocolType || 0);
   const protocolParts = protocolPartSetting?.partsSetting;

   if (!protocolPartSetting) return <Loader/>

  const handleOpenModelPartForm = (protocolPartId: number) => {
    let part: IPartSettingDTO | undefined = protocolParts?.find(x => x.protocolPartId === protocolPartId);
    if(part && protocolType){
      part.protocolTypeId = protocolType;
      openModal(
        <ProtocolPartForm protocolPart={part} areasEnum={areaEnums}  groupsEnum={groupEnums} />,
        {
            preventClosing: true,
            title: "Úprava dílu / funkce",
        })
    }
  }

  const handleAddPartManually = () => {
    var maxOrder: number = 0;
    if(protocolParts && protocolParts.length > 0)
    {
      var localMaxOrder = Math.max.apply(Math, protocolParts.map(function(o) { return o.protocolPartOrder; }));
      if (isFinite(localMaxOrder)) {
        maxOrder = localMaxOrder;
      }
    }
    var newPart: IPartSettingDTO = {
      protocolAreaId: undefined,
      protocolAreaName: "",
      protocolPartGroupId: undefined,
      protocolPartGroupName: "",
      protocolPartId: 0,
      protocolPartName: "",
      protocolTypeId: protocolType,
      isActive: true,
      protocolPartOrder: maxOrder + 10
    };
    openModal(
      <ProtocolPartForm protocolPart={newPart} areasEnum={areaEnums}  groupsEnum={groupEnums} />,
      {
          preventClosing: true,
          title: "Přidání dílu / funkce",
      })
  };

  return (
    <div>

      <FinalForm
        onSubmit={() => {}}
        initialValues={protocolPartSetting}
        render={({ handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <div className="pageheader">
              <div className="pageheader__left">
                <h3>Konfigurace oblastí na protokolech</h3>
              </div>
              <div className="pageheader__right">
                <Fragment>
                  <OverlayTrigger
                    overlay={
                      <Tooltip id={`tooltip-addbutton`}>Přidat díl</Tooltip>
                      }
                      >
                      <Button
                        style={{ marginRight: "1rem" }}
                        variant="primary"
                        onClick={() => handleAddPartManually()}
                        className="footer__btn-add"
                      >
                        <FontAwesomeIcon icon={faPlus} />
                      </Button>
                    </OverlayTrigger>
                </Fragment>
              </div>
            </div>
            {isLoading && <Loader />}
            <div className="protocol-table">
              {!isLoading && protocolParts && protocolParts.length > 0 && (

              <Table>
                <thead>
                  <tr>
                    <th>Název oblasti</th>
                    <th>Název skupiny</th>
                    <th>Název dílu</th>
                    <th>Pořadí</th>
                    <th>Aktivní</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {protocolParts.map((part, index) => (
                    <tr key={part.protocolPartId}>
                      <td>
                        <Field
                          name={`partsSetting[${index}].protocolAreaId`}
                          initialValue={part.protocolAreaId}
                          component="input"
                          hidden
                          disabled
                        />
                        <Field
                          name={`partsSetting[${index}].protocolAreaName`}
                          initialValue={part.protocolAreaName}
                          component={TextInput}
                          disabled={true}
                          size={"sm"}
                        />
                      </td>
                      <td>
                        <Field
                          name={`partsSetting[${index}].protocolPartGroupId`}
                          initialValue={part.protocolPartGroupId}
                          component="input"
                          hidden
                          disabled
                        />
                        <Field
                          name={`partsSetting[${index}].protocolPartGroupName`}
                          initialValue={part.protocolPartGroupName}
                          component={TextInput}
                          disabled={true}
                          size={"sm"}
                        />
                      </td>
                      <td>
                        <Field
                          name={`partsSetting[${index}].protocolPartId`}
                          initialValue={part.protocolPartId}
                          component="input"
                          hidden
                          disabled
                        />
                        <Field
                          name={`partsSetting[${index}].protocolPartName`}
                          initialValue={part.protocolPartName}
                          component={TextInput}
                          disabled={true}
                          size={"sm"}
                        />
                      </td>
                      <td className="protocol-table--order-column">
                        <Field
                          name={`partsSetting[${index}].protocolPartOrder`}
                          initialValue={part.protocolPartOrder}
                          component={NumberInput}
                          disabled={true}
                          size={"sm"}
                        />
                      </td>
                      <td className="protocol-table--order-column">
                          <Field
                            name={`partsSetting[${index}].isActive`}
                            options={[
                              { key: 1, value: "ano" },
                              { key: 0, value: " ne " },
                            ]}
                            format={(v) => (v ? 1 : 0)}
                            parse={(v) => (!!v)}
                            component={SwitchInput}
                            disabled={true}
                          />
                        </td>
                      <td style={{paddingBottom: "0.75rem"}}>
                        <Button
                          size="sm"
                          style={{ width: "3em" }}
                          variant="primary"
                          title="Editace dílu"
                          onClick={() => handleOpenModelPartForm(part.protocolPartId)}
                        >
                          <FontAwesomeIcon icon={faPen} />
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              )}
            </div>
            <div className="pageheader">
              <div className="pageheader__left">
              </div>
              <div className="pageheader__right">
                <Fragment>
                  <OverlayTrigger
                    overlay={
                      <Tooltip id={`tooltip-addbutton`}>Přidat díl</Tooltip>
                      }
                      >
                      <Button
                        style={{ marginRight: "1rem" }}
                        variant="primary"
                        onClick={() => handleAddPartManually()}
                        className="footer__btn-add"
                      >
                        <FontAwesomeIcon icon={faPlus} />
                      </Button>
                    </OverlayTrigger>
                </Fragment>
              </div>
            </div>
          </Form>
        )}
      />
    </div>
  );
};

export default observer(ProtocolPart);
