import "./SwitchInput.scss";
import React, { Fragment } from "react";
import { Form, Row, Col, ButtonGroup, Button } from "react-bootstrap";
import { FieldRenderProps } from "react-final-form";
import { IEnumItem } from "../../models/enum";

type Props = FieldRenderProps<number, any>;

function findValueByKey(arr: IEnumItem[], keyToFind: number) {
  const filter = arr.find(value => value.key === keyToFind);
  return filter?.value;
}
const SwitchInput: React.FC<Props> = ({
  input,
  options,
  inputOnChange,
  title,
  disabled,
  size
}: Props) => {



  const buttonGroup = (
    <ButtonGroup >
      {options.map((option: IEnumItem) => (
        <Button
          key={`${option.value}-${option.key}`}
          disabled={disabled}
          size={size}
          onClick={(e: any) =>{ input.onChange((e.target.value = option.key)); inputOnChange && inputOnChange(e.target.value)}}
          variant={input.value === option.key ? "primary" : "outline-primary"}
        >
          {option.value}
        </Button>
      ))}
    </ButtonGroup>
  );

  return (
    <Fragment>
      {title && (
        <Row>
          <Col sm={4} className="u-text-right">
            {title && <Form.Label>{title}</Form.Label>}
          </Col>
          <Col sm={8}>
            <label className='print-value'>{findValueByKey(options, input.value)}</label>
            {buttonGroup}
          </Col>
        </Row>
      )}
      {!title && buttonGroup}
    </Fragment>
  );
};

export default SwitchInput;
